import styled from "@emotion/styled";
import { SocialMediaShareButtons } from "design-system/molecules/social-media/social-media-share-buttons";

import { FC, useEffect, useState } from "react";
import { Advert, AdvertProps } from "./advert";
import { TableOfContent } from "./table-of-content";
import { BlogPost, ContentSection } from "../shared/pillars-and-blog-types";
import { useIsMediaDesktop } from "utils/component-utils";
import { Category } from "utils/categories-utils";
import { TOP_NAV_HEIGHT } from "design-system/organisms/nav-menu/nav-styles";
import { getAdvert } from "../api/advert-client";
import { useUserContext } from "context/user-context";

type SideBarProps = {
  sections: ContentSection[];
  showAdvert: boolean;
  title: string;
  categories: Category[];
  showSocialShareOnMobile: boolean;
  blogAdvert?: BlogPost["blogAdvert"];
};

export const Sidebar: FC<Partial<SideBarProps>> = ({
  sections,
  title,
  categories,
  showSocialShareOnMobile = true,
  blogAdvert,
}) => {
  const [shareUrl, setShareUrl] = useState("");

  const [advert, setAdvert] = useState<AdvertProps | null>(null);

  const { loggedIn } = useUserContext();

  useEffect(() => {
    if (loggedIn) return;
    void getAdvert().then((response) => {
      if (!response.worked) {
        return;
      }
      const { advert } = response;
      setAdvert(advert);
    });
  }, [loggedIn]);

  useEffect(() => {
    setShareUrl(window.location.href);
  }, []);

  const isDesktop = useIsMediaDesktop();

  return (
    <SidebarWrapper>
      <FloatingElements>
        {(!showSocialShareOnMobile ? shareUrl && isDesktop : shareUrl) && (
          <SocialMediaShareButtons shareUrl={shareUrl} title={title} />
        )}
        {(sections || categories) && (
          <TableOfContent
            sections={sections}
            title={title}
            categories={categories}
          />
        )}
        {/* This is slightly hacky because it requires the UI content to be built a certain way where it only contains one
        block and that is an Advert block. */}
        {blogAdvert &&
        blogAdvert.content?.blocks[0]?.__typename === "AdvertRecord" ? (
          <Advert {...(blogAdvert.content?.blocks[0] as AdvertProps)} />
        ) : (
          advert && <Advert {...advert} />
        )}
      </FloatingElements>
    </SidebarWrapper>
  );
};

const FloatingElements = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.spacing[80]};
  width: 100%;
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    overflow-y: scroll;
    height: 100vh;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    padding-bottom: 20vh;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0.5;
  min-width: 300px;
  position: relative;
  top: ${TOP_NAV_HEIGHT}px;
  left: 0;
  right: 0;
  z-index: 99;
  position: sticky;
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    display: block;
    padding: 0 ${({ theme }) => theme.spacing[36]};
  }
`;
