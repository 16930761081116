import { atom } from "jotai";
import { MusicStatus } from "shared/user.types";

type SidePanelView = "intentions" | "chat" | "people" | "sounds" | "timer" | "";

export const isSidePanelOpenAtom = atom(false);

export const openUserProfileSidePanelExternalIdAtom = atom("");

export const forcedActiveTabAtom = atom<SidePanelView>("intentions");

export const musicStatusAtom = atom<MusicStatus>();

export const isPlayingMusicAtom = atom(false);

const MUSIC_VOLUME_ADJUSTMENT = 5 / 1000; // The volume prop on the player is between 0 and 1

export const musicVolumeAtom = atom(35);

export const musicVolumeAtomWithAdjustment = atom(
  (get) => get(musicVolumeAtom) * MUSIC_VOLUME_ADJUSTMENT
);

export const isMusicControlledByHostAtom = atom(false);
export const isListeningToBroadcastAtom = atom(true);
