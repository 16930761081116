import { useUserContext } from "context/user-context";
import { analytics, catchAnalyticsError } from "features/analytics";
import { useToast } from "design-system/components";
import { useEffect, useState } from "react";
import { postToCreateFollowUser, postToUnfollowUser } from "../profile-client";
import { FollowUser } from "shared/user.types";

type UseFollowUnfollowProps = {
  displayName?: string;
  isFollowing?: boolean;
  followers?: FollowUser[];
  userExternalId?: string;
};

/**
 * This hook is used to follow/unfollow an individual user
 */
export const useFollowUnfollow = ({
  displayName = "",
  isFollowing = false,
  followers = [],
  userExternalId = "",
}: UseFollowUnfollowProps) => {
  const { toast, Toast } = useToast();
  const {
    firstName,
    lastName,
    displayName: viewerDisplayName,
    avatarUrl,
    externalId,
    email,
  } = useUserContext();
  const [isFollowingState, setIsFollowingState] = useState(isFollowing);
  const [followersState, setFollowersState] = useState([...followers]);

  // Sync state on navigation
  useEffect(() => {
    setFollowersState([...followers]);
    // Use .length to avoid having an object reference in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followers.length]);

  useEffect(() => {
    setIsFollowingState(isFollowing);
  }, [isFollowing]);

  const onFollow = async () => {
    const response = await postToCreateFollowUser({
      followingId: userExternalId,
    });
    if (!response.worked) {
      if (response.error === "self-follow-error") {
        toast("error", "You can't follow yourself");
        return;
      } else if (response.error === "duplicate-follow-error") {
        toast("error", `You already follow ${displayName}`);
        return;
      }
      toast("error", response.error);
      return;
    }

    setIsFollowingState(true);
    setFollowersState((prev) => [
      ...prev,
      {
        displayName: viewerDisplayName,
        firstName,
        lastName,
        avatarUrl,
        externalId: externalId || "",
        email: email || "",
      },
    ]);
    analytics
      .track("Profile Followed", {
        followId: externalId || "",
        source: "profile",
      })
      .catch(catchAnalyticsError);
  };

  const onUnfollow = async () => {
    const response = await postToUnfollowUser({
      followingId: userExternalId || "",
    });
    if (!response.worked) {
      if (response.error === "failed-to-unfollow-user") {
        toast("error", `Could not unfollow ${displayName}`);
        return;
      }
      toast("error", response.error);
      return;
    }
    setFollowersState((prev) =>
      prev.filter((follower) => follower.externalId !== externalId)
    );
    setIsFollowingState(false);
    analytics
      .track("Profile Unfollowed", {
        followId: externalId || "",
        source: "profile",
      })
      .catch(catchAnalyticsError);
  };

  return {
    isFollowingState,
    followersState,
    onFollow,
    onUnfollow,
    Toast,
  };
};
