import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * https://day.js.org/docs/en/display/from-now#list-of-breakdown-range
 * Defaults here: https://github.com/iamkun/dayjs/blob/dev/src/plugin/relativeTime/index.js#L27
 */
const relativeTimeCustomThresholds = [
  { l: "s", r: 1 },
  { l: "m", r: 1 },
  { l: "mm", r: 59, d: "minute" },
  { l: "h", r: 1 },
  { l: "hh", r: 23, d: "hour" },
  { l: "d", r: 1 },
  { l: "dd", r: 29, d: "day" },
  { l: "M", r: 1 },
  { l: "MM", r: 11, d: "month" },
  { l: "y", r: 1 },
  { l: "yy", d: "year" },
];
dayjs.extend(relativeTime, {
  thresholds: relativeTimeCustomThresholds,
});
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(LocalizedFormat);
dayjs.extend(weekOfYear);

export const WEEKDAYS = ["mon", "tue", "wed", "thu", "fri"] as const;
export const ALL_DAYS = ["sun", ...WEEKDAYS, "sat"];
export type Weekdays = typeof WEEKDAYS[number];
export type AllDays = typeof ALL_DAYS[number];

export type ValidDate = dayjs.ConfigType;

export const getWeekNumber = (date: ValidDate) => dayjs(date).week();

export const getWeekday = (date: ValidDate): Weekdays =>
  dayjs(date).format("ddd").toLowerCase() as Weekdays;

export const getFormattedWeekDay = (dayString: string) => {
  const date = dayjs(dayString);
  if (date.isToday()) {
    return "Today";
  }
  if (date.isTomorrow()) {
    return "Tomorrow";
  }
  return date.format("ddd");
};

export const getFormattedDay = (date: ValidDate) => {
  const validDate = dayjs(date);
  if (validDate.isToday()) {
    return "Today";
  }
  if (validDate.isTomorrow()) {
    return "Tomorrow";
  }
  return validDate.format("ddd Do MMMM");
};

export const getFormattedDayTime = (date: ValidDate) => {
  const validDate = dayjs(date);
  if (validDate.isToday()) {
    return `Today at ${validDate.format("h:mma")}`;
  }
  if (validDate.isTomorrow()) {
    return `Tomorrow at ${validDate.format("h:mma")}`;
  }
  return `${validDate.format("ddd, Do MMMM")} at ${validDate.format("h:mma")}`;
};

export const getFormattedTime = (date: ValidDate) =>
  dayjs(date).format("h:mma");

export const formatToShortISO = (date: dayjs.Dayjs) =>
  date.format("YYYY-MM-DD");

export const formatToLocalShortISO = (
  date: dayjs.Dayjs,
  timezone = "Europe/London"
) => date.tz(timezone).format("YYYY-MM-DD");

export const getUserTimezone = () => dayjs.tz.guess() || "Europe/London";

export const formatForTimezones = (date: ValidDate, delimiter = " / ") => {
  const validDate = dayjs(date);
  const ukTime = validDate.tz("Europe/London").format("h:mma");
  const estTime = validDate.tz("America/New_York").format("h:mma");
  const pstTime = validDate.tz("America/Los_Angeles").format("h:mma");
  return `${ukTime} UK${delimiter}${estTime} EST${delimiter}${pstTime} PST`;
};

export const getUkIsoDate = (date: ValidDate) =>
  dayjs(date).tz("Europe/London").toISOString();

export const formatShortDay = (date: ValidDate) =>
  dayjs(date).format("ddd Do MMM");

type NextFlockSession = {
  isoDate: string;
  nextSessionMessage: string;
};

export const getNextFlockSession = (
  startTime: string,
  todayInLondon = dayjs().tz("Europe/London")
): NextFlockSession => {
  const [hour, minute] = startTime.split(":").map(parseFloat);
  const currentWeekday = todayInLondon.day();
  if (isWeekend(currentWeekday)) {
    const isSaturday = currentWeekday === 6;
    const daysToAdd = isSaturday ? 2 : 1;
    const nextMonday = todayInLondon.add(daysToAdd, "day");
    return {
      nextSessionMessage: `Mon, ${startTime} UK`,
      isoDate: nextMonday.format("YYYY-MM-DD"),
    };
  }
  const flockTimeInLondon = dayjs(todayInLondon)
    .set("hour", hour)
    .set("minute", minute)
    .set("second", 0);

  const hasFlockHappened = flockTimeInLondon.isBefore(todayInLondon);
  if (hasFlockHappened) {
    const isFriday = currentWeekday === 5;
    if (isFriday) {
      // Next day is always the next Monday so we add 3 days
      const nextMonday = todayInLondon.add(3, "day");
      return {
        nextSessionMessage: `Mon, ${startTime} UK`,
        isoDate: nextMonday.format("YYYY-MM-DD"),
      };
    }
    const nextDay = todayInLondon.add(1, "day");
    return {
      nextSessionMessage: `${nextDay.format("ddd")}, ${startTime} UK`,
      isoDate: nextDay.format("YYYY-MM-DD"),
    };
  } else {
    return {
      nextSessionMessage: `Today, ${startTime} UK`,
      isoDate: todayInLondon.format("YYYY-MM-DD"),
    };
  }
};

export const generateWorkingCalendarDays = (
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs = dayjs(startDate).add(30, "day")
) => {
  const generatedCalendar: dayjs.Dayjs[] = [];
  const daysToGenerate = Math.ceil(endDate.diff(startDate, "d", true));
  for (let i = 0; i <= daysToGenerate; i++) {
    const weekday = startDate.add(i, "day");
    if (!isWeekend(weekday.day())) {
      generatedCalendar.push(weekday);
    }
  }
  return generatedCalendar;
};

export const getStartingMonday = (date: dayjs.Dayjs) => {
  const weekday = date.day();
  return weekday > 1 ? date.subtract(weekday - 1, "day") : date;
};

export const getEndingFriday = (date: dayjs.Dayjs) => {
  const weekday = date.day();
  return weekday < 6 ? date.add(6 - weekday, "day") : date;
};

export const isWeekend = (weekday: number): boolean => {
  const isSaturday = weekday === 6;
  const isSunday = weekday === 0;
  return isSaturday || isSunday;
};

export const isUserInFlocksTimezone = () => {
  const offset = new Date().getTimezoneOffset();
  return !(offset >= 180 || offset <= -420);
};

type DateRange = {
  startDate?: string;
  endDate?: string;
};

export const isWithinDateRange = (
  dateRange: DateRange,
  today: dayjs.Dayjs | string = dayjs()
) => {
  const todayIso = getUkIsoDate(today);
  if (dateRange.startDate && dateRange.endDate) {
    const startDate = getUkIsoDate(dateRange.startDate);
    const endDate = getUkIsoDate(dateRange.endDate);
    return endDate > todayIso && startDate < todayIso;
  }
  if (dateRange.startDate) {
    const startDate = getUkIsoDate(dateRange.startDate);
    return startDate < todayIso;
  }
  if (dateRange.endDate) {
    const endDate = getUkIsoDate(dateRange.endDate);
    return endDate > todayIso;
  }
  return true;
};

// TODO: These are currently used in Dato, we should refactor this to avoid confusion with
// Manage my week; we should use what is in the dayjs output so we can work with both
export const DAYS_OF_THE_WEEK = [
  "Sun",
  "Mon",
  "Tues",
  "Weds",
  "Thurs",
  "Fri",
  "Sat",
] as const;

export type DayOfTheWeek = typeof DAYS_OF_THE_WEEK[number];

export const getTimeToStart = (startTime: string | undefined, now = dayjs()) =>
  now.to(dayjs(startTime), true);

type HaveXDaysElapsedSinceDateArgs = {
  date: ValidDate;
  daysElapsed: number;
  now?: dayjs.Dayjs;
};

export const haveXDaysElapsedSinceDate = ({
  date,
  daysElapsed,
  now = dayjs(),
}: HaveXDaysElapsedSinceDateArgs) => {
  const dateAfterElapsedTime = dayjs(date).add(daysElapsed, "day");
  return dateAfterElapsedTime.isBefore(now);
};

/**
 * This function takes a date and rounds it up to the nearest minute interval
 */
export const roundToNearestMinutes = (
  date: ValidDate = dayjs(),
  minuteInterval: number = 15
) => {
  const dayJsDate = dayjs(date);
  const remainder = dayJsDate.minute() % minuteInterval;
  const minutesToAdd = minuteInterval - remainder;
  const roundedDate = dayJsDate.add(minutesToAdd, "minute").startOf("minute");
  return roundedDate;
};

export const convertDateToYearAndWeek = (date: string) => {
  const weekNumber = getWeekNumber(date);
  const year = dayjs(date).year();

  // Week number is a single digit if below 10, so we need to add a 0 to the start
  // for string comparisons to be more reliable
  const week = weekNumber < 10 ? `0${weekNumber}` : weekNumber;
  return `${year}-${week}`;
};
