import { Box, Button } from "design-system/components";
import { Icon } from "design-system/components";
import { SessionCardCTA } from "./ctas-shared";
import styled from "@emotion/styled";
import { SESSION_SOURCE } from "utils/constants";
import { getIsEventJoinable, getSessionUrlWithSource } from "utils/event-utils";
import { FlownEvent } from "shared/event.types";
import { css } from "styled-system/css";
import { getIsZoomFlock } from "utils/flocks-utils";
import { useState } from "react";

export const JoinSessionButton = () => {
  return (
    <Box
      className={`flex gap-4 ${css({ zIndex: 49 })}`}
      title="Join this session"
    >
      <SessionCardCTALive>
        <JoinIcon icon="video-camera" size={20} />
      </SessionCardCTALive>
    </Box>
  );
};

export const JoinSessionButtonDirect = ({ event }: { event: FlownEvent }) => {
  const [isJoining, setIsJoining] = useState(false);
  const isZoomFlock = getIsZoomFlock(event);

  return (
    <Button
      size="small"
      as="a"
      href={getSessionUrlWithSource(
        event.sessionUrl,
        SESSION_SOURCE.EVENT_CALENDAR
      )}
      target={isZoomFlock ? "_blank" : "_self"}
      variant={getIsEventJoinable(event) ? "primary" : "ghost"}
      onClick={(e) => {
        e.stopPropagation();
        if (!isZoomFlock) {
          setIsJoining(true);
        }
      }}
      isLoading={isJoining}
    >
      Join
    </Button>
  );
};

const SessionCardCTALive = styled(SessionCardCTA)(({ theme }) => ({
  "&:hover": {
    background: theme.colors.focusDark,
    color: theme.colors.white,
  },
}));

const JoinIcon = styled(Icon)(({ theme }) => ({
  animation: "pulsing-color 1s linear infinite alternate",
  "&:hover": {
    color: theme.colors.white,
    animation: "none",
  },

  "@keyframes pulsing-color": {
    "0%": {
      color: theme.colors.focusDark,
    },
    "100%": {
      color: theme.colors.focusMid,
    },
  },
}));
