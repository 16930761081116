import { SwitchSelector } from "design-system/molecules/switch-selector";
import { useCalendarFilters } from "./calendar-filters-context";
import {
  BoxCol,
  BoxFlex,
  Icon,
  Row,
  Text,
  TextButton,
} from "design-system/components";
import { CalendarAdvancedSettings } from "./calendar-advanced-settings";
import { useMemo, useState } from "react";
import {
  CALENDAR_FILTERS,
  CalendarFilterKey,
} from "../../../shared/calendar-filters";
import { styled } from "styled-system/jsx";
import { token } from "styled-system/tokens";

export const CalendarFilters = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { clearAllFilters, selectedFilters, onFilterUpdate } =
    useCalendarFilters();

  const showAdvancedOptionsRow = useMemo(() => {
    return Object.keys(selectedFilters).some(
      (filterKey) =>
        filterKey !== "category" &&
        !!selectedFilters[filterKey as CalendarFilterKey]
    );
  }, [selectedFilters]);

  return (
    <BoxCol className="gap-16">
      <MainFiltersWrapper>
        <SwitchSelector
          id="calendar-session-selector"
          style={{ width: "fit-content" }}
          onChangeSelected={(selected) => {
            onFilterUpdate("category", selected);
          }}
          selectedOptionId={selectedFilters.category}
          options={[
            {
              label: (
                <BoxFlex
                  className="justify-center"
                  id="calendar-filter-all-sessions"
                >
                  All
                </BoxFlex>
              ),
              id: "all-sessions",
            },
            {
              label: (
                <BoxFlex
                  className="justify-center gap-4"
                  id="calendar-filter-focus-sessions"
                >
                  <Icon
                    size={20}
                    icon="focus"
                    color={token("colors.focusDark")}
                  />
                  Focus
                </BoxFlex>
              ),
              id: "focus",
            },
            {
              label: (
                <BoxFlex
                  className="justify-center gap-4"
                  id="calendar-filter-recharge-sessions"
                >
                  <Icon
                    size={20}
                    icon="recharge"
                    color={token("colors.rechargeDark")}
                  />
                  Recharge
                </BoxFlex>
              ),
              id: "recharge",
            },
          ]}
        />
        <AdvancedFiltersWrapper>
          <Text
            className="flex align-center gap-4 h-full"
            as={"button"}
            onClick={() => setShowAdvanced(true)}
            id="calendar-filter-advanced-btn"
            fontSize="sm"
            fontWeight={500}
          >
            Advanced <Icon icon="filter" size={16} />
          </Text>
        </AdvancedFiltersWrapper>
        <CalendarAdvancedSettings
          isOpen={showAdvanced}
          onClose={() => setShowAdvanced(false)}
        />
      </MainFiltersWrapper>
      {showAdvancedOptionsRow && (
        <Row className="pad-x-8 justify-between">
          {/* Min height set to avoid layout shift when a filter is selected and we show a selected filter pill */}
          <BoxFlex style={{ minHeight: "38px" }}>
            <Text fontSize="sm" fontWeight={600}>
              Advanced options:{" "}
            </Text>
            {Object.keys(selectedFilters)
              .filter((filterKey) => filterKey !== "category")
              .map((filterKey) => {
                const filterValue =
                  selectedFilters[filterKey as CalendarFilterKey];
                if (filterValue) {
                  return (
                    <BoxFlex
                      key={filterKey}
                      className="pad-8 b-radius-46 border-grey"
                    >
                      <Text fontSize="sm" fontWeight={500}>
                        {CALENDAR_FILTERS[filterKey as CalendarFilterKey]}:{" "}
                        {filterValue}
                      </Text>
                      <Icon
                        icon="x-circle"
                        size={18}
                        onClick={() =>
                          onFilterUpdate(filterKey as CalendarFilterKey, "")
                        }
                      />
                    </BoxFlex>
                  );
                }
                return null;
              })}
          </BoxFlex>
          <TextButton onClick={clearAllFilters}>Clear all</TextButton>
        </Row>
      )}
    </BoxCol>
  );
};

const MainFiltersWrapper = styled("div", {
  base: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "8",
    height: "92px", // Agenda nav height
    justifyContent: "center",

    "bp-desktop-xs": {
      alignContent: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
});

const AdvancedFiltersWrapper = styled("div", {
  base: {
    alignItems: "center",
    borderLeft: "1px solid {colors.grey100}",
    display: "flex",
    flexWrap: "wrap",
    gap: "8",
    height: "40%",
    paddingLeft: "16",
    "bp-desktop-xs": {
      flexWrap: "nowrap",
    },
  },
});
