import dayjs, { Dayjs } from "dayjs";

import { formatToLocalShortISO } from "utils/date";
import { FlownEvent, FlownEvents } from "shared/event.types";
import { DayEvents } from "./event-types";

/**
 * Group events by day and make the day the key for each day
 * @param events
 * @returns Transformed events to DayEveny format
 */
export const groupEventsByDay = (
  events: FlownEvents,
  skeletonEventsByDay: DayEvents,
  timezone = "Europe/London",
  startDate: Dayjs = dayjs()
): DayEvents =>
  events.reduce((allDays, event) => {
    const startTime = dayjs(event.startTime);
    const isoDayStart = formatToLocalShortISO(startTime, timezone);

    function addEventToDay(dayKey: string, event: FlownEvent) {
      // If there is already a key for a given day
      // We add the event to the array
      if (allDays[dayKey]) {
        allDays[dayKey].events.push(event);
      } else {
        allDays[dayKey] = {
          events: [event],
        };
      }
    }

    // This is to make sure a calendar specifically set to start on a set day does - even
    // if events span over two days (e.g. dropins).  In this case we add them to the next day
    // This was causing an issue with a calendar starting on a monday actually starting on a sunday
    if (startTime >= startDate.startOf("day")) {
      addEventToDay(isoDayStart, event);
    }

    // It may be possible that due to timezone differences, some flocks will overlap to the weekend
    return allDays;
  }, skeletonEventsByDay);

/**
 * This function takes all the events and creates a skeleton of all the days so that we get all days
 * appearing, even if after filtering, there are no events for that day
 */
export const getCalendarSkeleton = (
  boundedEvents: FlownEvents,
  timezone: string
) =>
  boundedEvents.reduce((eventsByDay, event) => {
    const startTime = dayjs(event.startTime);
    const isoDay = formatToLocalShortISO(startTime, timezone);

    eventsByDay[isoDay] = eventsByDay[isoDay] || {
      events: [],
    };
    return eventsByDay;
  }, {} as DayEvents);
