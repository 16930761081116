import styled from "@emotion/styled";
import dayjs from "dayjs";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BoxCol, BoxFlex, Icon } from "design-system/components";
import { FlownEvent } from "shared/event.types";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { Box, Text } from "design-system/components";
import { getFormattedWeekDay } from "utils/date";
import { Carousel } from "design-system/molecules/carousel";
import { NavigationButton } from "design-system/molecules/carousel/carousel";
import { CALENDAR_MAX_WIDTH } from "../../shared";
import { css } from "styled-system/css";
import { CalendarLoadingState } from "./calendar-loading-state";
import { token } from "styled-system/tokens/index.mjs";
import { SessionsWrapper } from "./sessions-wrapper";
import { QUERY_PARAMS, getQueryParam } from "utils/url-query-utils";
import { useRouter } from "next/router";
import { atom, useAtom } from "jotai";
import { useIsMediaDesktop } from "utils/component-utils";

const selectedDateAtom = atom<string>(dayjs().format("YYYY-MM-DD"));
export const useSelectedDate = () => useAtom(selectedDateAtom);

export const SESSIONS_DAY_BLOCK_PREFIX = "sessions-day-block";

export type AgendaViewCalendarDayEvent = {
  day: string;
  events: FlownEvent[];
};

type AgendaViewCalendarProps = DSComponentPropsProvider & {
  attendedEventsIds?: string[];
  bookedEventIds: string[];
  dates: string[];
  eventsByDayList: AgendaViewCalendarDayEvent[];
  isFree?: boolean;
  onEventBooked: (event: FlownEvent) => Promise<void>;
  onEventBookingCancelled: (event: FlownEvent) => Promise<void>;
  onEventClick: (event: FlownEvent) => void;
  onlyEventSummary?: boolean;
  previewInSidePanel?: boolean;
  refreshDay: number;
  selectedEventId?: string;
  showHourSeparators?: boolean;
  status?: "pending" | "rejected" | "resolved" | "idle";
};

export const AgendaViewCalendar: React.FC<AgendaViewCalendarProps> = ({
  attendedEventsIds,
  bookedEventIds,
  className = "",
  dates,
  eventsByDayList,
  id,
  onEventBooked,
  onEventBookingCancelled,
  onEventClick,
  previewInSidePanel = false,
  refreshDay,
  selectedEventId = "",
  showHourSeparators = true,
  status,
}) => {
  const router = useRouter();
  const isDesktop = useIsMediaDesktop();
  const eventsContainerRef = useRef<HTMLDivElement>(null);
  const [datesInView, setDatesInView] = useState<string[]>([]);
  const [, setSelectedDate] = useSelectedDate();

  const onIntersectingChange = useCallback((isIntersecting: boolean, date) => {
    if (isIntersecting) {
      setDatesInView((prev) => [...prev, date]);
    } else {
      setDatesInView((prev) => prev.filter((d) => d !== date));
    }
  }, []);

  useEffect(() => {
    setSelectedDate(datesInView[0]);
  }, [datesInView, setSelectedDate]);

  const scrollToDay = useCallback((day: string) => {
    const dayBlockElement = document.getElementById(
      `${SESSIONS_DAY_BLOCK_PREFIX}-${day}`
    );

    if (dayBlockElement) {
      eventsContainerRef.current?.scrollTo(0, dayBlockElement.offsetTop);
    }
  }, []);
  const datesJson = JSON.stringify(dates);

  useEffect(() => {
    // We only want to select the date if something is passed in the URL
    // Params

    const dateFromQuery = getQueryParam(
      router.query[QUERY_PARAMS.CALENDAR_DATE]
    );

    if (dateFromQuery && dates.some((date) => date === dateFromQuery)) {
      scrollToDay(dateFromQuery);
    }
    // Hack to avoid rerunning effect when the dates reference changes, but the values are the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesJson, router.query, scrollToDay]);

  const currentDatePickerSlide = useMemo(() => {
    return dates.findIndex((date) => date === datesInView[0]);
  }, [dates, datesInView]);

  const eventsByWeek: AgendaViewCalendarDayEvent[][] = useMemo(() => {
    const today = dayjs().startOf("day");

    const weekObject = eventsByDayList.reduce((acc, { day, events }) => {
      const dayDifference = dayjs(day).diff(today, "day");
      const relativeWeek = Math.floor(dayDifference / 7);
      if (!acc[relativeWeek]) {
        acc[relativeWeek] = [];
      }
      acc[relativeWeek].push({ day, events });
      return acc;
    }, {});

    return Object.keys(weekObject).map((key) => weekObject[key]);
  }, [eventsByDayList]);

  const [currentWeek, setCurrentWeek] = useState(0);

  const onWeekNavigate = useCallback(
    (step: number) => {
      setCurrentWeek((prev) => {
        const newWeek = prev + step;
        const firstDayOfCurrentWeek = eventsByWeek[currentWeek][0];
        scrollToDay(firstDayOfCurrentWeek.day);
        return newWeek;
      });
    },
    [currentWeek, eventsByWeek, scrollToDay]
  );

  useEffect(() => {
    if (!eventsByWeek[currentWeek] || !isDesktop) return;
    const foundEvent = eventsByWeek[currentWeek][0].events[0];
    // This effect handles week navigation and selecting the first event of the week
    // Because it has other dependencies, we need to check if the selected event is in the current week
    const isSelectedEventInCurrentWeek = eventsByWeek[currentWeek].some((day) =>
      day.events.some((event) => event.id === selectedEventId)
    );
    if (foundEvent && !isSelectedEventInCurrentWeek) {
      onEventClick(foundEvent);
    }
  }, [currentWeek, eventsByWeek, isDesktop, onEventClick, selectedEventId]);

  const isLastWeek = currentWeek === eventsByWeek.length - 1;

  return (
    <Box id={`agenda-calendar-${id}`} className={`pos-relative ${className}`}>
      <EventsBox
        className={
          previewInSidePanel
            ? "preview-in-side-panel"
            : "no-preview-in-side-panel"
        }
      >
        {eventsByDayList.length > 0 && (
          <Box
            className={css({
              display: "flex",
              flexDirection: "column",
              borderRadius: "16",
              maxWidth: "calc(100vw - 40px)",
              paddingBottom: "32px",
              "bp-desktop-xs": {
                maxHeight: "calc(100vh - 60px)",
                padding: "16",
              },
            })}
          >
            <header className="pos-relative">
              <Box>
                <CustomNavigationButton
                  className={`navigation-prev navigation-prev-agenda-view-week-days`}
                  style={{
                    top: "5px",
                  }}
                  onClick={() => {
                    if (currentWeek === 0) return;
                    onWeekNavigate(-1);
                  }}
                >
                  <Icon
                    className="cursor-pointer"
                    swiper-button-disabled
                    icon="chevron-left"
                  />
                </CustomNavigationButton>
              </Box>
              <Carousel
                id="agenda-view-week-days"
                slidesPerView={1}
                allowTouchMove={false}
                pagination={false}
                style={{ width: "85%", margin: "auto" }}
                hasExternalNavigation={true}
                centeredSlides={false}
                initialSlide={currentDatePickerSlide}
              >
                {eventsByWeek.map((week, index) => {
                  const lastDayOfCurrentWeek = eventsByWeek[currentWeek][6]
                    ? eventsByWeek[currentWeek][6].day
                    : eventsByWeek[currentWeek][
                        eventsByWeek[currentWeek].length - 1
                      ].day;
                  return (
                    <Fragment key={`date-picker-slide-${index}`}>
                      <BoxFlex className="hide-on-mobile">
                        {week.map(({ day }) => (
                          <BoxCol
                            className="align-center gap-12 cursor-pointer pad-8 b-radius-12"
                            style={{
                              minWidth: "74.5px",
                              backgroundColor: datesInView.includes(day)
                                ? token("colors.blueGrey100")
                                : "white",
                              transition: "background-color 0.3s ease",
                            }}
                            onClick={() => {
                              scrollToDay(day);
                            }}
                            key={`weekday-${day}-${index}`}
                          >
                            <Text
                              fontSize="sm"
                              color="grey400"
                              fontWeight={
                                getFormattedWeekDay(day) === "Today" ? 700 : 400
                              }
                            >
                              {dayjs(day).format("DD MMM")}
                            </Text>
                            <Text fontSize="sm" fontWeight={700}>
                              {getFormattedWeekDay(day)}
                            </Text>
                          </BoxCol>
                        ))}
                      </BoxFlex>
                      <BoxFlex
                        className={`pad-8 b-radius-12 justify-center ${css({
                          backgroundColor: "blueGrey100",
                          display: "flex",
                          "bp-tablet": {
                            display: "none",
                          },
                          transition: "background-color 0.3s ease",
                          margin: "0 auto",
                          width: "70%",
                        })}`}
                      >
                        <BoxCol>
                          <Text fontSize="sm" fontWeight={700}>
                            {getFormattedWeekDay(
                              eventsByWeek[currentWeek][0].day
                            )}{" "}
                          </Text>
                          <Text fontSize="sm" fontWeight={500}>
                            {dayjs(eventsByWeek[currentWeek][0].day).format(
                              "DD MMM"
                            )}
                          </Text>
                        </BoxCol>
                        <Text fontSize="3xl">-</Text>
                        <BoxCol className="align-center">
                          <Text fontSize="sm" fontWeight={700}>
                            {getFormattedWeekDay(lastDayOfCurrentWeek)}
                          </Text>
                          <Text fontSize="sm" fontWeight={500}>
                            {dayjs(lastDayOfCurrentWeek).format("DD MMM")}
                          </Text>
                        </BoxCol>
                      </BoxFlex>
                    </Fragment>
                  );
                })}
              </Carousel>
              <CustomNavigationButton
                className={`navigation-next navigation-next-agenda-view-week-days`}
                css={(theme) => ({
                  right: "-20px",
                  top: "5px",
                  [theme.media["bp-mobile-md"]]: {
                    right: "-10px",
                  },
                })}
                onClick={() => {
                  if (isLastWeek) return;
                  onWeekNavigate(1);
                }}
              >
                <Icon
                  className="cursor-pointer"
                  swiper-button-disabled
                  icon="chevron-left"
                  style={{ transform: "rotate(180deg)" }}
                />
              </CustomNavigationButton>
            </header>
            <EventCardsBoxWithScroll
              className="flex-col gap-8"
              ref={eventsContainerRef}
              data-cy="agenda-view-events-box"
            >
              {eventsByWeek[currentWeek].map(({ day, events }, index) => {
                return (
                  <SessionsWrapper
                    key={`${currentWeek}-${day}-${index}`}
                    day={day}
                    events={events}
                    refreshDay={refreshDay}
                    attendedEventsIds={attendedEventsIds ?? []}
                    registeredEventsIds={bookedEventIds}
                    onEventBooked={onEventBooked}
                    onEventBookingCancelled={onEventBookingCancelled}
                    previewInSidePanel={previewInSidePanel}
                    onEventClick={onEventClick}
                    onIntersectingChange={onIntersectingChange}
                    selectedEventId={selectedEventId}
                    showHourSeparators={showHourSeparators}
                    timeDisplayMode="time+duration"
                    view="agenda"
                  />
                );
              })}

              <NavigationButton
                className="flex justify-center navigation-next navigation-next-agenda-view-week-days"
                style={{ position: "static" }}
              >
                <Text
                  className="text-center decor-underline mar-top-8 flex align-center justify-center gap-4"
                  fontWeight={500}
                  onClick={() => {
                    if (isLastWeek) return;
                    onWeekNavigate(1);
                  }}
                >
                  Go to next week
                  <Icon icon="arrow-right" size={14} />
                </Text>
              </NavigationButton>
            </EventCardsBoxWithScroll>
          </Box>
        )}
      </EventsBox>
      {status === "pending" && <CalendarLoadingState />}
    </Box>
  );
};

const EventsBox = styled.section`
  &.preview-in-side-panel {
    max-width: ${CALENDAR_MAX_WIDTH};
    margin: 0 auto;
    margin-top: ${(props) => props.theme.spacing[24]};
  }
`;

const EventCardsBoxWithScroll = styled(Box)(({ theme }) => ({
  position: "relative",
  overflowY: "auto",
  paddingBottom: theme.spacing[16],

  // Secondary nav (60px) + Filters (63.5px) + day picker (108px) + padding bottom from container 32px
  [theme.media["bp-tablet"]]: {
    height: "calc(100dvh - 263.5px)",
  },

  [theme.media["bp-desktop-xs"]]: {
    height: "100%",
  },
}));

const CustomNavigationButton = styled(NavigationButton)`
  display: flex;
  background: ${({ theme }) => theme.colors.grey100};
  border-radius: ${({ theme }) => theme.radii["round"]};
  padding: ${({ theme }) => theme.spacing[8]};
`;
