import { postToFetchUserReferredByData } from "features/user";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { QUERY_PARAMS } from "utils/url-query-utils";

export const useReferredBy = () => {
  const { query } = useRouter();

  const [referredBy, setReferredBy] = useState("");
  useEffect(() => {
    const getReferralData = async () => {
      if (query[QUERY_PARAMS.REFERRAL]) {
        const response = await postToFetchUserReferredByData({
          referralCode: query[QUERY_PARAMS.REFERRAL] as string,
        });
        if (response.worked && response.firstName) {
          setReferredBy(response.firstName);
        }
      }
    };
    void getReferralData();
  }, [query]);

  return referredBy;
};
