"use client";
import { getEventsRegistered } from "../calendar-client";
import { useEffect, useMemo, useState } from "react";
import { clientLoggerForFile } from "utils/logger.client";

const logger = clientLoggerForFile(__filename);

type UseGetEventsRegisteredArs = {
  loggedIn?: boolean;
};

export const useGetEventsRegistered = ({
  loggedIn,
}: UseGetEventsRegisteredArs) => {
  const [registeredEventsIds, setRegisteredEventsIds] = useState<string[]>([]);

  /**
   * For logged in users we fetch registered events. We always fetch these client side
   */
  useEffect(() => {
    if (!loggedIn) return;

    const setEventsRegistered = async () => {
      const response = await getEventsRegistered();

      if (response.worked) {
        setRegisteredEventsIds(response.eventsRegistered);
      } else {
        logger.error("Issue with fetching registered events", response.error);
        setRegisteredEventsIds([]);
      }
    };

    if (registeredEventsIds.length === 0) {
      setEventsRegistered().catch(() => {
        logger.error("Issue with fetching registered events");
        setRegisteredEventsIds([]);
      });
    }
  }, [registeredEventsIds.length, loggedIn]);

  const value = useMemo(
    () => [registeredEventsIds, setRegisteredEventsIds] as const,
    [registeredEventsIds]
  );
  return value;
};
