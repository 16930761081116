import styled from "@emotion/styled";
import Link from "next/link";

import { analytics, catchAnalyticsError } from "features/analytics";
import { ClassNameProvider } from "utils/component-utils";
import PadlockIcon from "/public/assets/shared/padlock.svg";
import { ReactNode } from "react";
import { Icon } from "design-system/components";
import Image from "next/image";
import { Box, Text } from "design-system/components";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";

export type LinkCardProps = {
  children?: ReactNode;
  url: string;
  imgSrc: string;
  title?: string;
  category?: string;
  isTeaser?: boolean;
  duration?: string;
  showContentOnHover?: boolean;
};
export const LinkCard = ({
  children,
  url,
  imgSrc,
  title,
  category,
  isTeaser,
  duration,
  showContentOnHover = true,
}: LinkCardProps & ClassNameProvider) => {
  const recordClickEvent = () => {
    if (title && category) {
      analytics
        .track("Launchpad Product Card Clicked", {
          nfyid: title,
          product_category: category,
        })
        .catch(catchAnalyticsError);
    }
  };

  if (isTeaser) {
    return (
      <Link href={getSubscribePageLinkWithSubscribeSource("teaser-card")}>
        <CardTeaser>
          {imgSrc && (
            <Box className="pos-relative" style={{ height: "200px" }}>
              <CoverImg src={imgSrc} height={200} width={324} alt="" />
            </Box>
          )}
          <CardInfo>
            <div>
              <PadlockIconImg />
              <span>Upgrade to Access</span>
            </div>
          </CardInfo>
        </CardTeaser>
      </Link>
    );
  }

  const CardWrapper = url ? Link : "div";
  return (
    <CardWrapper href={url}>
      <Card onClick={recordClickEvent} className="pos-relative">
        {imgSrc && (
          <Box className="pos-relative" style={{ height: "200px" }}>
            <CoverImg src={imgSrc} height={200} width={324} alt="" />
          </Box>
        )}
        {duration && (
          <DurationDisplay>
            {duration}
            {duration.includes("read") ? (
              <Icon icon="read" color={"white"} size={18} />
            ) : (
              <Icon icon="movie" color={"white"} size={18} />
            )}
          </DurationDisplay>
        )}
        <CardInfo className={showContentOnHover ? "" : "always-visible"}>
          {children ? (
            children
          ) : (
            <Text fontSize="sm" fontWeight={600}>
              {title}
            </Text>
          )}
          <Icon icon="arrow-right" size={20} />
        </CardInfo>
      </Card>
    </CardWrapper>
  );
};

const PadlockIconImg = styled(PadlockIcon)`
  path {
    fill: white;
  }
  margin-right: 0.5rem;
  vertical-align: revert;
`;

const CardInfo = styled.div`
  align-items: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: absolute;
  transition: transform 0.2s ease-in-out;
  width: 100%;

  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    transform: translate3d(0, 100%, 0);

    &.always-visible {
      transform: none;
    }
  }
`;

const Card = styled.div`
  cursor: pointer;
  border: 1px solid var(--gray-e5);
  border-radius: ${({ theme }) => theme.radii[16]};
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    ${CardInfo} {
      transform: translate3d(0, 0, 0);
    }
  }

  .is-teaser-content {
    display: none;
  }

  &.is-teaser {
    display: relative;
    opacity: 0.2;
    .is-teaser-content {
      display: absolute;
      bottom: 0;
    }
  }
`;

const CoverImg = styled(Image)`
  border-radius: 6px 6px 0 0;
  height: 200px;
  object-fit: cover;
`;

const CardTeaser = styled.div`
  cursor: pointer;
  border: 1px solid var(--gray-e5);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  display: relative;
  ${CoverImg} {
    opacity: 0.2;
  }
  .is-teaser-content {
    display: absolute;
    bottom: 0;
  }
`;

const DurationDisplay = styled.span`
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 6px;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
