import styled from "@emotion/styled";
import { useUserContext } from "context/user-context";
import { Button, Icon } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import React, { useRef } from "react";
import { useProductFruitsApi } from "react-product-fruits";

type ProductFruitsNewsfeedEmbedProps = DSComponentPropsProvider;

export const ProductFruitsNewsfeedEmbed: React.FC<
  ProductFruitsNewsfeedEmbedProps
> = ({ className = "" }) => {
  const { externalId } = useUserContext();
  const [unreadCount, setUnreadCount] = React.useState(0);
  const containerEl = useRef<HTMLDivElement>(null);

  useProductFruitsApi(
    (api) => {
      if (externalId && containerEl.current) {
        api.announcementsV2.attachNewsWidgetToElement(containerEl.current);
        api.announcementsV2.listen(
          "newsfeed-unread-count-changed",
          (data: { count: number }) => {
            const unreadCount = data.count;
            setUnreadCount(unreadCount);
          }
        );
      }
    },
    [externalId]
  );

  return (
    <div className={className} ref={containerEl}>
      <Button
        className="pos-relative flex align-center gap-8"
        variant="secondary"
        size="small"
      >
        <Icon icon="announcement" color={"black"} size={16} />
        {unreadCount > 0 && (
          <UnreadCountWrapper>{unreadCount}</UnreadCountWrapper>
        )}
        News
      </Button>
    </div>
  );
};

const UnreadCountWrapper = styled.span`
  position: absolute;
  top: 2px;
  left: 22px;
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.radii[12]};
`;
