"use client";
import { Modal } from "design-system/organisms/modals";
import { useState } from "react";
import {
  Box,
  Button,
  Label,
  Select,
  Text,
  TextArea,
  useToast,
} from "design-system/components";
import { FlownEvent } from "shared/event.types";
import { postToDeleteRoom } from "../sessions-client";

type CancelModalEventProps = Pick<
  FlownEvent,
  "displayName" | "id" | "detailedName"
>;

type CancelModalProps = {
  open: boolean;
  onClose: () => void;
  event: CancelModalEventProps;
  onDeleteConfirmed: (event: CancelModalEventProps) => void;
};

export const CancelSessionModal = ({
  event,
  onClose,
  open,
  onDeleteConfirmed,
}: CancelModalProps) => {
  const MAX_CANCEL_REASON_LENGTH = 250;
  const OTHER_REASON_STR = "other";
  const [cancelReason, setCancelReason] = useState<string>("");
  const [otherCancelText, setOtherCancelText] = useState<string>("");
  const [cancelReasonError, setCancelReasonError] = useState<string>("");
  const [cancelOtherReasonError, setOtherCancelReasonError] =
    useState<string>("");
  const { toast, Toast } = useToast();
  const [isDeletingRoom, setIsDeletingRoom] = useState(false);

  const onCancelConfirmed = async () => {
    if (!cancelReason) {
      setCancelReasonError("Please select a reason for cancelling");
      return;
    }
    if (cancelReason === OTHER_REASON_STR && !otherCancelText) {
      setOtherCancelReasonError("Please detail the reason for cancelling");
      return;
    }
    setIsDeletingRoom(true);

    const response = await postToDeleteRoom({
      roomName: event.detailedName,
      roomId: event.id,
      cancelReason,
      cancelText: otherCancelText,
    });

    setIsDeletingRoom(false);
    if (!response.worked) {
      toast("error", `Failed to delete session: ${response.error}`);
    } else {
      toast("success", `Session cancelled successfully`);
      onDeleteConfirmed(event);
      onClose();
    }
  };

  return (
    <>
      {Toast}
      <Modal
        showCloseBtn
        onClose={onClose}
        title={
          <Text className="pad-x-32 pad-y-16" fontSize="3xl" fontWeight={700}>
            Are you sure you want to cancel this session?
          </Text>
        }
        open={open}
        fullPopup
      >
        <Box className="flex-col pad-x-32 pad-y-16">
          <Text fontSize="2xl">
            You are about to cancel: <strong>{event.displayName}</strong>
          </Text>
          <Text fontSize="2xl">You cannot undo this action.</Text>

          <Box className="flex-col mar-top-16 gap-4 mar-bot-32">
            <Label htmlFor="cancel-reason-select">
              <Text fontSize="lg">
                Select a reason below and we'll share it with any registered
                participants
              </Text>
            </Label>
            <Select
              full
              name="cancel-reason-select"
              data-cy="cancel-reason-select"
              id="cancelReasonSelect"
              value={cancelReason}
              errorMessage={cancelReasonError}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                e.preventDefault();
                setCancelReason(e.target.value);
                setCancelReasonError("");
              }}
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Technical Difficulties">
                Technical Difficulties
              </option>
              <option value="Unforeseen Circumstances">
                Unforeseen Circumstances
              </option>
              <option value="Low Attendance">Low Attendance</option>
              <option value="other">Other - write a custom message</option>
            </Select>
            {cancelReason === OTHER_REASON_STR && (
              <TextArea
                maxLength={MAX_CANCEL_REASON_LENGTH}
                value={otherCancelText}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  e.preventDefault();
                  setOtherCancelText(e.target.value);
                }}
                placeholder="Please write a custom message here."
                errorMessage={cancelOtherReasonError}
              />
            )}
          </Box>
          <Box className="flex gap-8 justify-between">
            <Button className="min-w-100" onClick={onClose}>
              No
            </Button>
            <Button
              dataCy="cancel-session-confirmation-modal-confirm-btn"
              variant="secondary"
              className="min-w-100"
              onClick={onCancelConfirmed}
              isLoading={isDeletingRoom}
            >
              Yes, cancel session
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
