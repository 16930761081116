import { useUserContext } from "context/user-context";
import {
  BoxFlex,
  Button,
  Icon,
  ProgressBar,
  Text,
} from "design-system/components";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";
import { useMemo } from "react";
import { css } from "styled-system/css";
import { useNavigate } from "utils/component-utils";
import { pluralise } from "utils/string-utils";

export const FreeTrialRemainingDaysIndicator = () => {
  const navigate = useNavigate();
  const { freeTrialRemainingDays = 0 } = useUserContext();

  const indicatorColor = useMemo(() => {
    if (freeTrialRemainingDays >= 20) return "success";
    if (freeTrialRemainingDays >= 10) return "warning";
    return "error";
  }, [freeTrialRemainingDays]);

  return (
    <BoxFlex className={`b-radius-16 pad-8 bg-blueGrey100`}>
      <Text fontSize="sm" className="nowrap">
        <strong className="pad-right-4">Free trial</strong>{" "}
        {freeTrialRemainingDays} {pluralise(freeTrialRemainingDays, "day")} left
      </Text>
      <div
        className={css({
          display: "none",
          width: "122px",
          "bp-desktop-xs": {
            display: "block",
          },
        })}
      >
        <ProgressBar
          className={css({
            height: "8",
          })}
          currentProgress={(freeTrialRemainingDays / 30) * 100}
          indicatorColor={indicatorColor}
          wrapperBackgroundColor="grey100"
        />
      </div>
      <Button
        variant="gold"
        size="small"
        className="min-w-100"
        id="free-trial-remaining-days-indicator-subscribe-button"
        onClick={() =>
          navigate(
            getSubscribePageLinkWithSubscribeSource(
              "free-trial-remaining-days-indicator"
            )
          )
        }
      >
        Subscribe
        <Icon icon="arrow-right" size={18} />
      </Button>
    </BoxFlex>
  );
};
