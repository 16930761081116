import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  CreateUserActivityRequest,
  CreateUserActivityResponse,
} from "../server/create-user-activity-handler";
import {
  HasUserJoinedChallengeRequest,
  HasUserJoinedChallengeResponse,
} from "../server/has-user-joined-challenge-handler";
import { ProductType } from "shared/products.types";

export const postToAddUserActivity = createPostEndpoint<
  CreateUserActivityRequest,
  CreateUserActivityResponse
>(`/api/userActivities/createActivity`);

export const getUserLatestUserActivity = (
  userId: string,
  product?: ProductType
) =>
  createGetEndpoint<UserActivity>(
    `api/userActivities/${userId}${product ? "/" + product : ""}`
  )();

export const postToHasUserJoinedChallenge = createPostEndpoint<
  HasUserJoinedChallengeRequest,
  HasUserJoinedChallengeResponse
>(`/api/userActivities/hasUserJoinedChallenge`);

import {
  FetchUserHasJoinedSessionRequest,
  FetchUserHasJoinedSessionResponse,
} from "../server/fetch-has-user-joined-session-handler";
import { createGetEndpoint } from "utils/create-get-endpoint";
import { UserActivity } from "shared/user-activities.types";

export const postToFetchUserHasJoinedSession = createPostEndpoint<
  FetchUserHasJoinedSessionRequest,
  FetchUserHasJoinedSessionResponse
>(`/api/userActivities/hasJoinedSession`);
