import { datadogLogs } from "@datadog/browser-logs";

export const initDatadog = () => {
  if (process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      forwardErrorsToLogs: true,
      site: "datadoghq.eu",
      version: "1.0.0",
      service: "flown-app",
    });
  }
};
