import styled from "@emotion/styled";
import { MediaViewer } from "design-system/molecules/media-viewer";
import { Box, Heading } from "design-system/components";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { BoxoutProps } from "../shared/pillars-and-blog-types";
import { TwirlAdornment } from "./adornments";

export const Boxout: FC<BoxoutProps> = ({
  title,
  content,
  media,
  thumbnail,
  backgroundColor,
  twirl,
  mediaAlt,
}) => {
  return (
    <BoxoutContainer backgroundColor={backgroundColor}>
      <Box className="pos-relative">
        <HeadingText as="h3">
          <ReactMarkdown>{title}</ReactMarkdown>
        </HeadingText>
        {twirl && <TwirlAdornment />}
      </Box>
      {media ? (
        <MediaContent
          url={media}
          thumbnailUrl={thumbnail}
          showVideoControls
          alt={mediaAlt || ""}
        />
      ) : null}
      <ContentContainer>
        <ReactMarkdown>{content}</ReactMarkdown>
      </ContentContainer>
    </BoxoutContainer>
  );
};

const BoxoutContainer = styled.div<{ backgroundColor?: string }>`
  max-width: 800px;
  background-color: ${(props) => `${props.backgroundColor}`};
  padding: ${({ theme }) => theme.spacing[32]};
  border-radius: ${({ theme }) => theme.radii[46]};
`;

const HeadingText = styled(Heading)(({ theme }) => ({
  fontSize: theme.fontSizes["4xl"],
  [theme.media["bp-tablet"]]: {
    width: `calc(100% - ${theme.spacing[84]})`,
  },
}));

const ContentContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  line-height: ${({ theme }) => theme.spacing[24]};
  && li {
    list-style-image: url("/assets/shared/spark.svg");
    margin: 0 ${({ theme }) => theme.spacing[20]};
    padding: 0 ${({ theme }) => theme.spacing[12]};
  }
`;

const MediaContent = styled(MediaViewer)(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  padding: `${theme.spacing[32]} 0`,
  borderRadius: "4rem",
}));
