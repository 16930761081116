"use client";
import { useEffect, useState } from "react";

/**
 * Refresh the calendar on a periodic basis so the Join icon appears or hides
 * depending how close we are to the event.
 */
export const useRefreshDay = () => {
  const [refreshDay, setRefreshDay] = useState(0);
  useEffect(() => {
    const refreshDayInterval = setInterval(() => {
      setRefreshDay((t) => t + 1);
    }, 60000);

    return () => {
      clearInterval(refreshDayInterval);
    };
  }, []);
  return refreshDay;
};
