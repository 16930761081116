/**
 * Documentation on Truspilot widgets: https://www.notion.so/flown/Trustpilot-Dato-block-widget-TP-Assets-f02217259c85471fb40b76fb4cb546a1?pvs=4
 */

// see the widget here: https://businessapp.b2b.trustpilot.com/trustbox/5419b732fbfb950b10de65e5
export const TRUSTPILOT_MICRO_STAR_ID = "5419b732fbfb950b10de65e5";

// see the widget here: https://businessapp.b2b.trustpilot.com/trustbox/53aa8807dec7e10d38f59f32
export const TRUSTPILOT_MINI_ID = "53aa8807dec7e10d38f59f32";

export const TRUSTPILOT_MICRO_COMBO_ID = "5419b6ffb0d04a076446a9af";
