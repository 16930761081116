import { styled } from "styled-system/jsx";
import { ReactionsUserList } from "./reactions-user-list";
import { Box, Popover, Row, Text } from "design-system/components";

type IntentionReactionCountProps = {
  boosts: string[];
  claps: string[];
  isCompleted: boolean;
};

export const IntentionReactionCount = ({
  boosts,
  claps,
  isCompleted,
}: IntentionReactionCountProps) => {
  return (
    <Row>
      {isCompleted
        ? claps.length + boosts.length > 0 && (
            <Popover
              triggerElement={
                <span>
                  <Text className="cursor-pointer" fontSize="xs">
                    👏💪{" "}
                    <span className="hov-decor-underline">
                      {`${claps.length + boosts.length}`}
                    </span>
                  </Text>
                </span>
              }
            >
              <ReactionUserListContainer className="flex-col gap-16">
                {!!boosts.length && (
                  <Box className="flex-col gap-8">
                    <Text fontWeight={600}>Encouragements</Text>
                    <ReactionsUserList externalIds={boosts} />
                  </Box>
                )}
                {!!claps.length && (
                  <Box className="flex-col gap-8">
                    <Text fontWeight={600}>Claps</Text>
                    <ReactionsUserList externalIds={claps} />
                  </Box>
                )}
              </ReactionUserListContainer>
            </Popover>
          )
        : boosts.length > 0 && (
            <Popover
              triggerElement={
                <span>
                  <Text className="cursor-pointer" fontSize="xs">
                    💪{" "}
                    <span className="hov-decor-underline">
                      {`${boosts.length}`}
                    </span>
                  </Text>
                </span>
              }
            >
              <ReactionUserListContainer className="gap-8">
                <Text fontWeight={600}>Encouragements</Text>
                <ReactionsUserList externalIds={boosts} />
              </ReactionUserListContainer>
            </Popover>
          )}
    </Row>
  );
};

const ReactionUserListContainer = styled(Box, {
  base: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: "450px",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "{colors.blueGrey100}",
    },
  },
});
