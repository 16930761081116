import { useEffect, useState } from "react";
import { Box } from "./box";
import { Text } from "./text";
import Image from "next/image";
import { Spinner } from "./spinner";

type EmptyStateProps = {
  text: string;
  imgSrc?: string;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  text,
  imgSrc = "/assets/shared/eagle.svg",
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <Box className="flex-col justify-center align-center pad-y-16">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Image
            className="mar-y-16"
            src={imgSrc}
            height={73}
            width={157}
            alt=""
          />
          <Text fontSize="xl" className="mar-y-16">
            {text}
          </Text>
        </>
      )}
    </Box>
  );
};
