import { VercelRequest } from "@vercel/node";
import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  GetUserReferredByRequest,
  GetUserReferredByResponse,
  GetUserStatsHandlerRequest,
  GetUserListByExternalIdRequest,
  GetUserListByExternalIdResponse,
  SetUserPreferencesRequest,
  SetUserPreferencesResponse,
  SetUserAdditionalDataRequest,
  SetUserAdditionalDataResponse,
  UserActivityResponse,
  GetUserPreferencesResponse,
  UpdateUserAppStatusRequest,
  UpdateUserAppStatusResponse,
} from "../shared/types";
import { GetUserInitialDataHandlerResponse } from "../server/get-user-initial-data-handler";
import { GetUserStatsHandlerResponse } from "../server/getUserStatsHandler";
import type { User } from "database/collections/users";
import { createGetEndpoint } from "utils/create-get-endpoint";
import { ResultOrError } from "shared/response.types";

export const postToFetchUserInitialData = createPostEndpoint<
  VercelRequest,
  GetUserInitialDataHandlerResponse
>("/api/user");

export const postToFetchUserReferredByData = createPostEndpoint<
  GetUserReferredByRequest,
  GetUserReferredByResponse
>("/api/user/referred-by");

export const postToGetUserStatsData = createPostEndpoint<
  GetUserStatsHandlerRequest,
  GetUserStatsHandlerResponse
>("/api/user/get-user-stats");

export const postToGetUserListByExternalId = createPostEndpoint<
  GetUserListByExternalIdRequest,
  GetUserListByExternalIdResponse
>("/api/user/get-by-external-id");

export const getEmailDigestContent = (externalId: string) =>
  createGetEndpoint<UserActivityResponse>(
    `/api/user/${externalId}/email-digest-content`
  );

export const postToSetUserPreferences = createPostEndpoint<
  SetUserPreferencesRequest,
  SetUserPreferencesResponse
>("/api/user/set-user-preferences");

export const getUserPreferences = createGetEndpoint<GetUserPreferencesResponse>(
  "/api/user/get-user-preferences"
);

export const postToSetUserAdditionalData = createPostEndpoint<
  SetUserAdditionalDataRequest,
  SetUserAdditionalDataResponse
>("/api/user/set-user-additional-data");

export const postToUpdateUserOnlineStatus = createPostEndpoint<
  UpdateUserAppStatusRequest,
  UpdateUserAppStatusResponse
>("/api/user/update-online-status");

export const getUserData = <T extends keyof User>(projection: T[]) =>
  // @Miguel can we infer the exact return type based on the projection?
  createGetEndpoint<ResultOrError<Pick<User, T>>>(
    `/api/user/data?projection=${projection.join(",")}`
  )();
