import { SessionCardBox, EventInfoBox } from "./session-card-shared";

export const SkeletonLoadingSessionCard = () => {
  return (
    <SessionCardBox className="column">
      <div className="skeleton" style={{ height: "70px", width: "55px" }} />
      <EventInfoBox>
        <div className="skeleton" style={{ height: "12px", width: "90px" }} />
        <div className="skeleton" style={{ height: "12px", width: "110px" }} />
        <div className="skeleton" style={{ height: "12px", width: "80px" }} />
      </EventInfoBox>
    </SessionCardBox>
  );
};
