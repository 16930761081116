import { getCalendarSkeleton, groupEventsByDay } from "./events-by-day";
import { getUniqueCalendarFilters } from "./calendar-filters";
import {
  EventsSliceArgs,
  getBoundedCalendar,
  getFilteredEvents,
} from "./event-filters";
import { FlownEvents } from "shared/event.types";
import dayjs from "dayjs";
import { sortEventsByPriority } from "utils/event-utils";

/**
 * This function processes an array of events and returns data that can be used to render the calendar.
 * It runs as follows:
 * 1. Bound the calendar
 * 2. Create a skeleton for all the days in the calendar
 * 3. Filter the events based on the provided calendar slice
 * 4. Compute all unique filters based on the filtered events
 * 5. Group filtered events by day based on the original skeleton
 *
 * These filters can be used on the backend and on the client side when the events are fetched there.
 *
 * @param allEvents - the cached, populated events fetched from the API
 * @param sliceArgs - the first layer of filters for the events
 * - @param numberOfDays - the number of days to show in the calendar
 * - @param startDate - the start date of the calendar
 * - @param timezone - the timezone to use for the calendar
 * - @param filter - the backend filters to apply to the events
 *  - @param categories - the categories to filter by
 *  - @param tags - the tags to filter by
 *  - @param facilitatorName - the facilitator name to filter by
 */
export const getCalendarProperties = (
  allEvents: FlownEvents,
  {
    numberOfDays = 28,
    skipSorting = false,
    // Default startDate should be early in the morning otherwise we risk missing events that have started.
    startDate = dayjs().set("hours", 0).set("minute", 0),
    timezone = "Europe/London",
    filter = {},
  }: Partial<EventsSliceArgs>
) => {
  const typeSafeDate = dayjs(startDate);
  // There were issues in some timezones wehre even though the date from the server
  // was correct the ordering broke somewhere. We always want dates sorted in ascending order
  // so ensure that here.
  if (!skipSorting) {
    allEvents.sort(sortEventsByPriority);
  }

  const boundedFlownEvents = getBoundedCalendar(allEvents, {
    startDate: typeSafeDate,
    numberOfDays,
  });

  const allEventsByDaySkeleton = getCalendarSkeleton(
    boundedFlownEvents,
    timezone
  );

  const filteredEvents = getFilteredEvents(boundedFlownEvents, filter);
  const uniqueFilters = getUniqueCalendarFilters(filteredEvents);
  const eventsByDay = groupEventsByDay(
    filteredEvents,
    allEventsByDaySkeleton,
    timezone,
    typeSafeDate
  );

  return {
    filteredEvents,
    uniqueFilters,
    eventsByDay,
  };
};
