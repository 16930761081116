import { BoxoutBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { Boxout } from "features/pillars-and-blog";

export const BoxoutView = (
  props: BoxoutBlockFragment & { className: string }
) => {
  const title = props.boxoutTitle || "";
  const content = props.boxoutContent || "";

  return (
    <Boxout
      title={title}
      content={content}
      media={props.boxoutMedia?.url}
      thumbnail={props.boxoutThumbnail?.url}
      backgroundColor={props.boxoutBackgroundColor?.hex}
      twirl={props.boxoutTwirl}
    />
  );
};

export const BoxoutRenderer = renderer({
  typeName: "BoxoutRecord",
  view: BoxoutView,
});
