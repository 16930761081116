import { useParsedUserAgent } from "./parsed-user-agent-context";
import { useIsPWA } from "./pwa-context";

export const useIsPWAInstallable = () => {
  const { isPWA, isPWAInstalled, addToHomeEvent } = useIsPWA();
  const userAgentPWAEvent = useParsedUserAgent();

  const isInstallable =
    !isPWA &&
    !isPWAInstalled &&
    userAgentPWAEvent?.parsedUserAgent.supportsPWAPrompt;

  const onPWAInstallClicked = async () => {
    await addToHomeEvent?.prompt();
  };
  return [isInstallable, onPWAInstallClicked] as const;
};
