import { Box } from "design-system/components";
import { Icon } from "design-system/components";
import { SessionCardCTA } from "./ctas-shared";
import {
  SocialMediaShareButtonsPopover,
  SocialMediaShareButtonsProps,
} from "design-system/molecules/social-media/social-media-share-buttons";
import { css } from "styled-system/css";

type BringAFriendButtonProps = Pick<
  SocialMediaShareButtonsProps,
  "title" | "shareUrl"
>;

export const BringAFriendButton = (props: BringAFriendButtonProps) => {
  return (
    <Box
      className={`flex gap-4 ${css({ top: "12", right: "8" })}`}
      title={props.title}
    >
      <SocialMediaShareButtonsPopover
        {...props}
        trigger={
          <SessionCardCTA>
            <Icon icon="friends" size={20} />
          </SessionCardCTA>
        }
      />
    </Box>
  );
};
