"use client";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import { checkIsPWA } from "utils/platform-utils";
import { analytics, catchAnalyticsError } from "features/analytics";
import {
  PWA_INSTALLED_COOKIE_KEY,
  PWA_CONTEXT_COOKIE_KEY,
} from "utils/constants";
import { clientLoggerForFile } from "utils/logger.client";

const logger = clientLoggerForFile(__filename);

const isPWAInstalledCheck = async (
  beforeInstallEvent: BeforeInstallPromptEvent | null
) => {
  const installFallback = Cookies.get(PWA_INSTALLED_COOKIE_KEY) === "true";
  if ("getInstalledRelatedApps" in navigator) {
    // We first check the related apps, if not we check for the presence of the beforeinstallprompt event
    // If the event is not present, the app is installed. We fallback to the cookie method otherwise
    try {
      const relatedApps = await (navigator as any).getInstalledRelatedApps();
      return relatedApps.length > 0 || !beforeInstallEvent || installFallback;
    } catch (error) {
      logger.error("Error checking if PWA is installed", error);
      return installFallback;
    }
  }
  return installFallback;
};

type BeforeInstallPromptEvent = Event & {
  prompt(): Promise<void>;
  userChoice: Promise<{
    outcome: "accepted" | "dismissed";
  }>;
};

type PWAContextValue = {
  isPWA: boolean;
  addToHomeEvent: BeforeInstallPromptEvent | null;
  isPWAInstalled: boolean;
};

const PWAContext = createContext<PWAContextValue>({
  isPWA: false,
  isPWAInstalled: false,
  addToHomeEvent: null,
});

export const PWAProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isPWA, setIsPWA] = useState(false);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);
  const [addToHomeEvent, setAddToHomeEvent] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    if (addToHomeEvent) return;
    window.addEventListener("beforeinstallprompt", (e) => {
      const event = e as BeforeInstallPromptEvent;
      event.preventDefault();
      setAddToHomeEvent(event);
      void event.userChoice.then((choice) => {
        // User installs the PWA successfully
        if (choice.outcome === "accepted") {
          Cookies.set(PWA_INSTALLED_COOKIE_KEY, "true");
          analytics
            .track("App Add To Home Installed", {})
            .catch(catchAnalyticsError);
        }
      });
    });
  }, [addToHomeEvent]);

  useEffect(() => {
    window
      ?.matchMedia("(display-mode: standalone)")
      .addEventListener("change", (evt) => {
        if (evt.matches) {
          setIsPWA(true);
          Cookies.set(PWA_CONTEXT_COOKIE_KEY, "true");
        } else {
          Cookies.remove(PWA_CONTEXT_COOKIE_KEY);
        }
      });
    if (checkIsPWA()) {
      setIsPWA(true);
      Cookies.set(PWA_CONTEXT_COOKIE_KEY, "true");
    } else {
      Cookies.remove(PWA_CONTEXT_COOKIE_KEY);
    }
  }, []);

  useEffect(() => {
    const checkPWAInstallation = async () => {
      const isInstalled = await isPWAInstalledCheck(addToHomeEvent);
      setIsPWAInstalled(isInstalled);
    };
    void checkPWAInstallation();
  }, [addToHomeEvent]);

  const value = useMemo(
    () =>
      ({
        isPWA,
        isPWAInstalled,
        addToHomeEvent,
      } as const),
    [isPWA, isPWAInstalled, addToHomeEvent]
  );

  return <PWAContext.Provider value={value}>{children}</PWAContext.Provider>;
};

export const useIsPWA = () => useContext(PWAContext);
