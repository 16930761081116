import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  ValidateCouponRequest,
  ValidateCouponResponse,
} from "../server/api/validate-coupon-handler";

import {
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
} from "../server/api/create-stripe-subscription-handler";

import {
  UpdateSubscriptionRequest,
  UpdateSubscriptionResponse,
  UpgradeSubscriptionResponse,
  UpgradeSubscriptionRequest,
  AbortSubscriptionRequest,
  AbortSubscriptionResponse,
  StripePortalResponse,
  ProductPricesResponse,
} from "../shared/types";

import { SetFlownFreeMembershipResponse } from "../server/api/set-flown-free-membership-handler";
import { VercelRequest } from "@vercel/node";
import { UserLocalCurrencyResponse } from "../server/api/get-user-local-currency-handler";
import { createGetEndpoint } from "utils/create-get-endpoint";

export const postToValidateCoupon = createPostEndpoint<
  ValidateCouponRequest,
  ValidateCouponResponse
>("/api/subscription/stripe/validateCoupon");

export const postToCreateSubscription = createPostEndpoint<
  CreateSubscriptionRequest,
  CreateSubscriptionResponse
>("/api/subscription/stripe/create");

export const postToAbortSubscription = createPostEndpoint<
  AbortSubscriptionRequest,
  AbortSubscriptionResponse
>("/api/subscription/stripe/abort");

export const postToUpdateUserSubscription = createPostEndpoint<
  UpdateSubscriptionRequest,
  UpdateSubscriptionResponse
>("/api/subscription/update-user-subscription");

export const postToUpgradeUserSubscription = createPostEndpoint<
  UpgradeSubscriptionRequest,
  UpgradeSubscriptionResponse
>("/api/subscription/upgrade-user-subscription");

export const getUserPortalUrl = createGetEndpoint<StripePortalResponse>(
  "/api/subscription/stripe/manage"
);

export const getCancelPortalUrl = createGetEndpoint<StripePortalResponse>(
  "/api/subscription/stripe/cancel"
);

export const postToSetFlownFreeMembership = createPostEndpoint<
  VercelRequest,
  SetFlownFreeMembershipResponse
>("/api/set-flown-free-membership");

export const getUserLocalCurrency = createPostEndpoint<
  VercelRequest,
  UserLocalCurrencyResponse
>("/api/subscription/stripe/userLocalCurrency");

export const getProductPrices = createGetEndpoint<ProductPricesResponse>(
  "/api/subscription/stripe/productPrices"
);
