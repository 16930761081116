import React, { CSSProperties, Children, ReactNode } from "react";
import {
  Arrow,
  Content,
  Item,
  Label,
  Root,
  Trigger,
  Portal,
  SubTrigger,
} from "@radix-ui/react-dropdown-menu";

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from "design-system/animations/radix-animations";
import { styled } from "styled-system/jsx";

type DropdownMenuProps = {
  children?: ReactNode;
  content?: ReactNode;
  triggerAsChild?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  trigger?: ReactNode;
  triggerStyle?: CSSProperties;
  side?: "top" | "right" | "bottom" | "left";
};

export const DropdownMenu = ({
  triggerAsChild,
  content,
  children,
  onOpenChange,
  open,
  trigger,
  side = "bottom",
  triggerStyle,
}: DropdownMenuProps) => {
  return (
    <Root open={open} onOpenChange={onOpenChange}>
      {trigger && (
        <Trigger
          asChild={triggerAsChild}
          style={triggerStyle ?? {}}
          onDoubleClick={(e) => e.preventDefault()}
        >
          {trigger}
        </Trigger>
      )}
      <Portal>
        <DropdownMenuContent sideOffset={5} side={side}>
          {children
            ? Children.map(
                children as JSX.Element[],
                (child: JSX.Element, index: number) => (
                  <DropdownMenuItem
                    key={`${child.key}-${index}`}
                    disabled={child.props.disabled}
                  >
                    {child}
                  </DropdownMenuItem>
                )
              )
            : content}
          <Arrow
            style={{
              fill: "white",
            }}
          />
        </DropdownMenuContent>
      </Portal>
    </Root>
  );
};

const DropdownMenuContent = styled(Content, {
  base: {
    minWidth: "200px",
    backgroundColor: "white",
    borderRadius: "12",
    padding: "8",
    boxShadow:
      "0px 10px 38px -10px rgba(22, 23, 24, 0.35),\n    0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    zIndex: 300,
    "&[data-side='top']": {
      animationName: slideDownAndFade,
    },
    "&[data-side='right']": {
      animationName: slideLeftAndFade,
    },
    "&[data-side='bottom']": {
      animationName: slideUpAndFade,
    },
    "&[data-side='left']": {
      animationName: slideRightAndFade,
    },
  },
});

const dropDownMenuItemStyle = {
  display: "flex",
  alignItems: "center",
  borderRadius: "12",
  padding: "0 5px",
  position: "relative",
  userSelect: "none",
  outline: "none",
  cursor: "pointer",
  "&[data-disabled]": {
    pointerEvents: "none",
  },
  "&:hover": {
    backgroundColor: "grey100",
  },
};

export const DropdownMenuItem = styled(Item, {
  base: dropDownMenuItemStyle,
});

export const DropdownMenuSubTrigger = styled(SubTrigger, {
  base: dropDownMenuItemStyle,
});

export const DropdownMenuLabel = styled(Label, {
  base: {
    fontSize: "sm",
    fontWeight: 500,
    color: "grey600",
    padding: "5px",
  },
});
