import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  CreateRoomRequest,
  GetRoomsResponse,
  CreateRoomResponse,
  EditRoomRequest,
  DeleteRoomRequest,
  DeleteRoomResponse,
  GetUsersInRoomResponse,
  GetCachedUsersInRoomResponse,
  RoomResponse,
  TagRoomRequest,
  SwitchHostRequest,
  ClaimHostRequest,
  AddCoHostRequest,
  UpdateRoomRequest,
  ReportBlockParticipantRequest,
  ReportBlockParticipantResponse,
  GetUsersInWaitingRoomHandlerResponse,
  AcceptOrDeclineParticipantRequest,
  AcceptOrDeclineParticipantResponse,
  ReportBlockParticipantFeedbackRequest,
  ReportBlockParticipantFeedbackResponse,
  ToggleIsFreeSessionRequest,
} from "../shared/sessions-types";
import { createGetEndpoint } from "utils/create-get-endpoint";
import {
  LockRoomRequest,
  CreateUserEventRequest,
  CreateUserEventResponse,
  GetClaimedSpotsInSessionResponse,
  GetUserHostedSessionsResponse,
  SendSessionInviteRequest,
  SendSessionInviteResponse,
  SessionInvitesResponse,
  AgendaTemplatesResponse,
  GetRoomLockStatusResponse,
  UpdateFocusRoomRequest,
} from "../shared/sessions-types";
import { fetchRetryClient } from "utils/fetch-retry-client";
import { GetUserCreatedSessionsResponse } from "../shared/sessions-types";
import { WorkedOrError } from "shared/response.types";
import {
  GetDailyRoomUsersRequest,
  GetDailyRoomUsersResponse,
  GetOrCreateDailyRoomRequest,
  GetOrCreateDailyRoomResponse,
} from "../shared/daily-types";
export const postToGetOrCreateDailyRoom = createPostEndpoint<
  GetOrCreateDailyRoomRequest,
  GetOrCreateDailyRoomResponse
>(`/api/sessions/get-or-create-daily-room`);

export const postToGetDailyRoomUsers = createPostEndpoint<
  GetDailyRoomUsersRequest,
  GetDailyRoomUsersResponse
>(`/api/sessions/users`);

export const getUsersInRoomWithConnectionsAndTags = (roomName: string) =>
  createGetEndpoint<GetUsersInRoomResponse>(
    `/api/sessions/presence/${roomName}/with-connections-and-tags`
  )();

export const postToCreateDailyRoom = createPostEndpoint<
  CreateRoomRequest,
  CreateRoomResponse
>(`/api/sessions/create-room`);

export const getRooms = (organisationSlug?: string) =>
  createGetEndpoint<GetRoomsResponse>(
    `/api/sessions/rooms${
      organisationSlug ? `?organisationSlug=${organisationSlug}` : ""
    }`
  )();

export const postToEditRoom = createPostEndpoint<EditRoomRequest, RoomResponse>(
  "/api/sessions/edit-room"
);

export const postToUpdateFocusRoom = createPostEndpoint<
  UpdateFocusRoomRequest,
  RoomResponse
>("/api/sessions/focus-room/update");

export const postToSwitchHost = createPostEndpoint<
  SwitchHostRequest,
  RoomResponse
>("/api/sessions/switch-host");

export const postToAddCoHost = createPostEndpoint<
  AddCoHostRequest,
  RoomResponse
>("/api/sessions/add-co-host");

export const postToRemoveCoHost = createPostEndpoint<
  AddCoHostRequest,
  RoomResponse
>("/api/sessions/remove-co-host");

// For now this is just the session url but can be extended to update indivicdual fields
// the edit endpoint takes more params and doesn't update all things
export const postToUpdateRoom = createPostEndpoint<
  UpdateRoomRequest,
  RoomResponse
>("/api/sessions/update-room");

export const postToClaimHost = createPostEndpoint<
  ClaimHostRequest,
  RoomResponse
>("/api/sessions/claim-host");

export const postToAddTagToRoom = createPostEndpoint<
  TagRoomRequest,
  RoomResponse
>("/api/sessions/add-tag-to-room");

export const postToToggleIsFreeSession = createPostEndpoint<
  ToggleIsFreeSessionRequest,
  RoomResponse
>("/api/sessions/toggle-is-free-session");

export const postToRemoveTagFromRoom = createPostEndpoint<
  TagRoomRequest,
  RoomResponse
>("/api/sessions/remove-tag-from-room");

export const postToDeleteRoom = createPostEndpoint<
  DeleteRoomRequest,
  DeleteRoomResponse
>("/api/sessions/delete-room");

export const postToLockRoom = createPostEndpoint<
  LockRoomRequest,
  WorkedOrError
>("/api/sessions/lock-room");

export const postToInviteToSession = createPostEndpoint<
  SendSessionInviteRequest,
  SendSessionInviteResponse
>(`/api/sessions/invite`);

export const getInvitesToSession = (eventId: string) =>
  createGetEndpoint<SessionInvitesResponse>(
    `/api/sessions/find-invites/${eventId}`
  );

export const postToCreateUserEvent = createPostEndpoint<
  CreateUserEventRequest,
  CreateUserEventResponse
>("/api/user-events/create");

export const getCachedUsersInRoom = (
  roomName: string
): Promise<GetCachedUsersInRoomResponse> =>
  fetchRetryClient(`/api/sessions/presence/${roomName}`).then((response) =>
    response.json()
  );

export const getCachedUserCreatedSessions = (
  userExternalId: string
): Promise<GetUserCreatedSessionsResponse> =>
  fetchRetryClient(`/api/sessions/created/${userExternalId}`).then((response) =>
    response.json()
  );

export const getHostedSessions = (externalId: string) =>
  createGetEndpoint<GetUserHostedSessionsResponse>(
    `/api/sessions/hosted/${externalId}`
  );

export const getClaimedSpotsInSession = (eventId: string) =>
  createGetEndpoint<GetClaimedSpotsInSessionResponse>(
    `/api/sessions/spots/${eventId}`
  );

export const getAgendaTemplates = (tags: string[] = []) =>
  createGetEndpoint<AgendaTemplatesResponse>(
    `/api/sessions/templates${tags.length ? `/${tags.join(",")}` : ""}`
  )();

export const getRoomLockStatus = (eventId: string) =>
  createGetEndpoint<GetRoomLockStatusResponse>(
    `/api/sessions/room-lock/${eventId}`
  );

export const getUsersInWaitingRoom = (roomId: string) =>
  createGetEndpoint<GetUsersInWaitingRoomHandlerResponse>(
    `/api/sessions/users-in-waiting-room/${roomId}`
  )();

export const postToAcceptRoomParticipant = createPostEndpoint<
  AcceptOrDeclineParticipantRequest,
  AcceptOrDeclineParticipantResponse
>("/api/sessions/users-in-waiting-room/accept");

export const postToDeclineRoomParticipant = createPostEndpoint<
  AcceptOrDeclineParticipantRequest,
  AcceptOrDeclineParticipantResponse
>("/api/sessions/users-in-waiting-room/decline");

export const getUserIsUserAcceptedInRoom = (roomId: string) =>
  createGetEndpoint<{ isAccepted: boolean; isDeclined: boolean }>(
    `/api/sessions/is-user-accepted/${roomId}`
  )();

export { getRecurringSessionsDates } from "../shared/recurring-sessions";

export const postToReportBlockParticipant = createPostEndpoint<
  ReportBlockParticipantRequest,
  ReportBlockParticipantResponse
>("/api/sessions/report-block-participant");

export const postToReportBlockParticipantFeedback = createPostEndpoint<
  ReportBlockParticipantFeedbackRequest,
  ReportBlockParticipantFeedbackResponse
>("/api/sessions/report-block-participant-feedback");
