import { Box, Button, Icon, ProgressBar, Text } from "design-system/components";
import React from "react";
import { useCheckValidCreditsForUser } from "../hooks/use-check-valid-credits-for-user";
import { useUserContext } from "context/user-context";
import { pluralise } from "utils/string-utils";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { useNavigate } from "utils/component-utils";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";

type PremiumUpgradeProgressBarProps = DSComponentPropsProvider;

export const PremiumUpgradeProgressBar: React.FC<
  PremiumUpgradeProgressBarProps
> = ({ className = "", style = {} }) => {
  const navigate = useNavigate();
  const { externalId, isFreeCreditsRole } = useUserContext();
  const { currentCredit, hasLoadedCredits } = useCheckValidCreditsForUser({
    externalId,
    isFreeCreditsRole,
  });

  return hasLoadedCredits ? (
    <Box
      className={`flex align-center pad-x-12 pad-y-8 gap-16 b-radius-16 ${
        isFreeCreditsRole ? "bg-blueGrey100" : ""
      } ${className}`}
      style={{ maxWidth: "350px", ...style }}
      data-component="PremiumUpgradeProgressBar"
    >
      {currentCredit ? (
        <Box className="flex-col" style={{ minWidth: "60px" }}>
          <Text fontSize="sm" className="nowrap">
            {currentCredit.sessionsLeft}{" "}
            {pluralise(currentCredit.sessionsLeft, "credit")} left
          </Text>
          <ProgressBar
            indicatorColor={"success"}
            wrapperBackgroundColor="grey200"
            totalProgress={currentCredit.initialAmount}
            currentProgress={currentCredit.sessionsLeft}
          ></ProgressBar>
        </Box>
      ) : (
        isFreeCreditsRole && (
          <Text fontSize="sm" className="nowrap">
            No credits left...
          </Text>
        )
      )}
      <div
        id="premium-upgrade-progress-bar-subscribe"
        style={{
          minWidth: "350px",
        }}
      >
        <Button
          variant="gold"
          size="small"
          className="min-w-100 nowrap"
          onClick={() =>
            navigate(
              getSubscribePageLinkWithSubscribeSource(
                "premium-upgrade-progress-bar"
              )
            )
          }
        >
          Commit to focus. Upgrade
          <Icon icon="arrow-right" size={18} />
        </Button>
      </div>
    </Box>
  ) : null;
};

PremiumUpgradeProgressBar.displayName = "PremiumUpgradeProgressBar";
