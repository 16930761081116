import { Button, Text } from "design-system/components";
import { FC } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "utils/component-utils";

export type AdvertProps = {
  title: string;
  descriptionText: string;
  /**
   * @property {string} ctaLink - The link to the page the advert will link to.
   */
  ctaLink: string;
  /**
   * @property {string} ctaLabel - The label for the link to the page the advert will link to. To be used in conjunction with
   * ctaLink.
   */
  ctaLabel: string;
};

export const Advert: FC<AdvertProps> = ({
  title,
  descriptionText,
  ctaLink,
  ctaLabel,
}) => {
  const navigate = useNavigate();

  return (
    <AdvertWrapper>
      <Text className="mar-y-16" fontWeight={700} fontSize={"xl"} color="white">
        {title}
      </Text>
      <Text className="mar-y-16" color={"white"}>
        {descriptionText}
      </Text>
      {ctaLink && (
        <AdvertCta variant="secondary" onClick={() => navigate(ctaLink)}>
          {ctaLabel}
        </AdvertCta>
      )}
    </AdvertWrapper>
  );
};

export const AdvertWrapper = styled.aside`
  padding: ${({ theme }) => theme.spacing["16"]};
  background-color: ${({ theme }) => theme.colors["black"]};
  border-radius: ${({ theme }) => theme.radii["24"]};
  max-width: fit-content;
  text-align: center;
  display: none;
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
  }
`;

const AdvertCta = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing["24"]};
  margin-bottom: ${({ theme }) => theme.spacing["16"]};
`;
