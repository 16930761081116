import {
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";

export const useHasJoinedCall = () => {
  const localSessionId = useLocalSessionId();
  const hasJoinedCall = !!useParticipantProperty(localSessionId, "joined_at");
  return hasJoinedCall;
};
