import { Icon } from "design-system/components";
import { StripeCurrency } from "models/subscription";
import { Popover, Row, Text } from "design-system/components";
import { useEffect, useState } from "react";
import { getUserLocalCurrency } from "../subscription-client";

export const useCurrencySelector = (initialCurrency?: string) => {
  const currencies: StripeCurrency[] = ["gbp", "usd", "eur"];

  useEffect(() => {
    const getLocalCurrency = async () => {
      const response = await getUserLocalCurrency();
      if (response.worked) {
        setSelectedCurrency(response.currency);
      }
    };
    if (!initialCurrency) {
      void getLocalCurrency();
    }
  }, [initialCurrency]);

  const [selectedCurrency, setSelectedCurrency] = useState<StripeCurrency>(
    currencies.find((currency) => currency === initialCurrency)
      ? (initialCurrency as StripeCurrency)
      : currencies[0]
  );

  type CurrencySelectionProps = {
    alignEnd?: boolean;
    style?: React.CSSProperties;
  };

  const CurrencySelector = ({
    alignEnd = false,
    style = {},
  }: CurrencySelectionProps) => {
    return (
      <Popover
        triggerStyle={alignEnd ? { alignSelf: "flex-end" } : {}}
        triggerElement={
          <div
            className={`flex align-center gap-8 cursor-pointer`}
            style={style}
          >
            {selectedCurrency.toUpperCase()}
            <Icon icon="info" />
          </div>
        }
      >
        <Row className="gap-16">
          {currencies.map((currency) => (
            <Text
              className="cursor-pointer hov-opacity-6"
              fontSize="sm"
              fontWeight={700}
              onClick={() => setSelectedCurrency(currency)}
              key={currency}
            >
              {currency.toUpperCase()}
            </Text>
          ))}
        </Row>
      </Popover>
    );
  };

  return { selectedCurrency, CurrencySelector };
};
