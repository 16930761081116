import {
  RoomTemplatesDocument,
  RoomTemplatesQuery,
  SessionToolsDataDocument,
  SessionToolsDataQuery,
} from "generated/graphql-typed";
import dayjs from "dayjs";
import { assertFieldsNotNull } from "utils/assertions";
import { slugify } from "utils/string-utils";
import {
  ActivityCategory,
  Agenda,
  AgendaActivity,
  AgendaTemplate,
  RoomFormState,
} from "./sessions-types";
import { computeSha256Hex } from "services/crypto-utils";
import { UserProfileFromActivity } from "shared/user.types";
import { Database } from "database";
import { getRoomParticipants } from "../server/daily.service";
import { isProductionMode } from "utils/environment";
import { clientLoggerForFile } from "utils/logger.client";
import { UserRole } from "services/roles/user-roles";
import { DAILY_PREBUILT_QUERY_PARAMS } from "utils/url-query-utils";
import { P2PEvent } from "shared/rooms.types";
import { graphqlQuery } from "services/graphql";
import { P2PEventDb } from "database/collections/rooms";
import { PopulatedHost } from "shared/event.types";
import { FOCUS_CATEGORY_SLUG } from "shared/products.types";

const logger = clientLoggerForFile(__filename);

export const DAILY_DOMAIN =
  process.env.NEXT_PUBLIC_DAILY_DOMAIN || "https://preview.daily.flown.com/";

export const DAILY_CALLOBJECT_DOMAIN =
  process.env.NEXT_PUBLIC_DAILY_CALLOBJECT_DOMAIN ||
  "https://flown-preview.daily.co/";

/**
 * This is the daily link that we add in the calendar and not the link of the room itself
 * Use https://flown.daily.co/ for production and https://flown-preview.daily.co/ for preview
 */
export const isDailyLink = (link: string) => link.includes(".daily.co/");

export const ROOM_NAME_CHAR_LIMIT = 41;
export const ROOM_DESCRIPTION_CHAR_LIMIT = 250;
export const ROOM_DISPLAY_NAME_CHAR_LIMIT = 30;
export const ROOM_EJECT_GRACE_PERIOD_MIN = 15;
export const SUGGESTED_MAX_PARTICIPANTS = 50;
export const MIN_PARTICIPANTS_BREAKOUT_ROOMS = 10;
export const FIXED_FORMAT_MAX_PARTICIPANTS = 15;
export const ONE_HOUR_TEMPLATE_SLUG = "short-focus-session";
export const WAITLIST_TRIGGER_TIME = 3; // minutes

/**
 * Time in minutes after which we consider that a user has "completed" a session
 * when in the drop-in
 */
export const SESSION_COMPLETION_TIME = parseInt(
  process.env.NEXT_PUBLIC_DROP_IN_COMPLETION_TIME || "20"
); // minutes

/**
 * https://docs.daily.co/reference/rest-api/rooms/config#max_participants
 */
export const MIN_SESSION_PARTICIPANTS = 2;
export const MIN_SESSIONS_TO_CREATE_ROOM =
  process.env.NEXT_PUBLIC_SESSIONS_TO_CREATE_ROOM || isProductionMode()
    ? 30
    : 1;

export const MIN_SESSIONS_HOSTED_TO_GET_FLOWN_FULL = 8;

export const MIN_SESSION_DURATION =
  (process.env.NEXT_PUBLIC_MIN_SESSION_DURATION &&
    parseInt(process.env.NEXT_PUBLIC_MIN_SESSION_DURATION)) ||
  1000 * 60 * 10; // 10minutes

/**
 * Default values for the room state
 */
export const DEFAULT_ROOM_STATE: Pick<
  RoomFormState,
  | "maxParticipants"
  | "enableChat"
  | "privacy"
  | "screenShare"
  | "enableMic"
  | "forceWaitingRoom"
  | "roomTags"
  | "tags"
  | "iconUrl"
  | "category"
  | "breakoutRooms"
> = {
  maxParticipants: SUGGESTED_MAX_PARTICIPANTS,
  breakoutRooms: true,
  enableChat: false,
  privacy: "private",
  screenShare: true,
  enableMic: true,
  forceWaitingRoom: false,
  roomTags: [],
  tags: [],
  iconUrl: "",
  category: {
    displayName: "Focus",
    slug: FOCUS_CATEGORY_SLUG,
  },
};

type FormatToValidRoomSlugArgs = {
  roomName: string;
  startTime: string;
  externalId: string;
};

/**
 * We want to allow special charaters such as ' or @ to be in the room name
 * This function will replace any special characters with an underscore and then
 * slugify the result. We also add the first 7 characters of the user's externalId
 * and the date it was set to ensure uniqueness.
 *
 * 12/02/2025 - before we only had 5 characters in the hash but we saw at least
 * one instance of conflict and multiple sessions had the same detailedName
 * Increasing the length of the hash to 7 characters should reduce the likelihood
 * of conflicts but still look okay for the URL. e.g there are two sessions for
 * a974e-1-hour-focus-session
 */
export const formatToValidRoomSlug = ({
  roomName,
  externalId,
  startTime,
}: FormatToValidRoomSlugArgs) => {
  const uniqueHashLength = 7;
  const formattedTodayDate = dayjs().format("MMDDHHmmss");
  const formattedStartDate = dayjs(startTime).format("MMDDHHmmss");
  const formattedExternalId = externalId.slice(0, uniqueHashLength);
  const formattedName = roomName.replace(ROOM_NAME_REGEX, "");
  const hash = computeSha256Hex({
    stringToHash:
      formattedTodayDate +
      formattedStartDate +
      formattedExternalId +
      formattedName,
    secret: "community",
  }).substring(0, uniqueHashLength);
  const formattedSlug = `${hash}-${slugify(formattedName)}`;
  return formattedSlug;
};

export const ROOM_NAME_REGEX = /[^a-zA-Z0-9-\s]/g;

export const getAgendaDuration = (agenda: Agenda) =>
  agenda.reduce((acc, curr) => acc + curr.duration, 0);

export const getEndTimeFromAgenda = (agenda: Agenda, startTime: string) => {
  const duration = getAgendaDuration(agenda);
  return dayjs(startTime).add(duration, "minute").toISOString();
};

export const getAgendaCategory = (agenda: Agenda) => {
  const categoriesDict = agenda.reduce((dict, activity) => {
    if (dict[activity.category.slug]) {
      dict[activity.category.slug] += activity.duration;
    } else {
      dict[activity.category.slug] = activity.duration;
    }
    return dict;
  }, {} as Record<string, number>);

  const categoriesArray = Object.entries(categoriesDict).map(
    ([slug, duration]) => ({
      slug,
      duration,
    })
  );

  return categoriesArray.sort((a, b) => b.duration - a.duration)[0].slug;
};

const AGENDA_ITEM_TYPE = {
  FOCUS: "focus",
  RECHARGE: "recharge",
  INTENTION_SETTING: "Intention setting",
  SOCIAL: "social",
};

export type AgendaItemType =
  typeof AGENDA_ITEM_TYPE[keyof typeof AGENDA_ITEM_TYPE];

export const transformSoundEntry = (
  sound: SessionToolsDataQuery["allSessionSounds"][number]
) => {
  const { displayName, icon, source } = assertFieldsNotNull(sound, [
    "displayName",
    "icon",
    "source",
  ]);

  return {
    displayName,
    iconUrl: icon.url,
    sourceUrl: source.url,
  };
};

export const transformTrackSuggestionEntry = (
  trackSuggestion: SessionToolsDataQuery["allSessionTrackSuggestions"][number]
) => {
  const { description, url, title } = assertFieldsNotNull(trackSuggestion, [
    "description",
    "url",
    "title",
  ]);

  return {
    description,
    url,
    title,
  };
};

export const transformActivityCategory = (
  category: RoomTemplatesQuery["allActivityCategories"][number]
): ActivityCategory => {
  const { color, name, slug } = assertFieldsNotNull(category, [
    "color",
    "name",
    "slug",
  ]);
  return { colorHex: color.hex, name, slug };
};

export const transformAgendaActivity = (
  activity: RoomTemplatesQuery["allAgendaActivities"][number]
): AgendaActivity => {
  const { category, description, displayName, duration, slug } =
    assertFieldsNotNull(activity, [
      "category",
      "description",
      "displayName",
      "duration",
      "slug",
    ]);
  const assertedCategory = transformActivityCategory(category);
  return {
    category: assertedCategory,
    description,
    displayName,
    displayNameInSession: activity.displayNameInSession || "",
    duration,
    slug,
    // The type maps to the specified values validation on Dato
    ...(activity.suggestedAction
      ? {
          suggestedAction:
            activity.suggestedAction as AgendaActivity["suggestedAction"],
        }
      : {}),
  };
};

export const transformTemplate = (
  template: RoomTemplatesQuery["allAgendaTemplates"][number]
) => {
  const { agenda, autoFeature, description, name, slug, tags } =
    assertFieldsNotNull(template, [
      "agenda",
      "autoFeature",
      "description",
      "name",
      "slug",
      "tags",
    ]);

  return {
    shortDescription: description,
    autoFeature,
    name,
    slug,
    tags,
    agenda: agenda.map((activityBlock) => {
      const { activity } = assertFieldsNotNull(activityBlock, ["activity"]);
      const durationOverride = activityBlock.duration;

      return transformAgendaActivity({
        ...activity,
        duration: durationOverride ? durationOverride : activity.duration,
      });
    }),
  };
};

export const sortByAvatarAndFirstName = (
  a: UserProfileFromActivity,
  b: UserProfileFromActivity
) => {
  // If both have avatarURL or both don't, sort by firstName
  if ((a.avatarUrl && b.avatarUrl) || (!a.avatarUrl && !b.avatarUrl)) {
    return a.firstName.localeCompare(b.firstName);
  }
  // If only a has avatarURL, a should come before b
  if (a.avatarUrl && !b.avatarUrl) {
    return -1;
  }
  // If only b has avatarURL, b should come before a
  return 1;
};

export const FLOWNOGRAM_ID = "flownogram";
export const FLOWNOGRAM_IMAGE_PREVIEW_FOLDER = "session-previews";

export const getUsersInRoom = async (roomName: string, db: Database) => {
  try {
    const dailyUsers = await getRoomParticipants({ name: roomName });
    const userIds = dailyUsers.map((user) => user.userId);

    const usersInDb = await db.users.findManyByExternalId(userIds, [
      "email",
      "firstName",
      "displayName",
      "lastName",
      "avatarUrl",
      "location",
      "oneLiner",
      "_id",
      "externalId",
      "organisation",
    ]);

    return usersInDb
      .reduce((acc: UserProfileFromActivity[], user) => {
        const dailyUser = dailyUsers.find((u) => u.userId === user.externalId);
        acc.push({
          _id: user._id,
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          displayName: user.displayName || "",
          avatarUrl: user.avatarUrl || "",
          oneLiner: user.oneLiner || "",
          location: user.location || "",
          externalId: user.externalId,
          sessionDuration: dailyUser?.duration || 0,
          organisation: user.organisation,
        });

        return acc;
      }, [])
      .sort(sortByAvatarAndFirstName);
  } catch (error) {
    logger.error("Error getting users in room", { error });
    return [];
  }
};

type GetRoomUrlArgs = {
  userRole: UserRole;
  isOwner: boolean;
  allowMic: boolean;
  roomName: string;
  disablePrejoinUi?: boolean;
};

export const getRoomUrl = ({
  userRole,
  roomName,
  isOwner,
  allowMic,
  disablePrejoinUi = false,
}: GetRoomUrlArgs) => {
  const params = new URLSearchParams();
  params.append(DAILY_PREBUILT_QUERY_PARAMS.USER_ROLE, userRole);

  if (!isOwner && !allowMic) {
    params.append(DAILY_PREBUILT_QUERY_PARAMS.AUDIO, "false");
  }

  if (process.env.NEXT_PUBLIC_DEBUG_PREBUILT === "true") {
    params.append(DAILY_PREBUILT_QUERY_PARAMS.DEBUG, "true");
  }

  if (disablePrejoinUi) {
    params.append(DAILY_PREBUILT_QUERY_PARAMS.DISABLE_PREJOIN_UI, "true");
  }

  return `${DAILY_DOMAIN}${roomName}?${params.toString()}`;
};

export const transformRoomAnalyticsPayload = (room: P2PEvent) => {
  return {
    ...room,
    eventProfileTags: room.eventProfileTags?.map((tag) => tag.title) ?? [],
  };
};

export const fetchSoundsAndMusic = async () => {
  const data = await graphqlQuery(SessionToolsDataDocument);
  const sessionSounds = data.allSessionSounds.map(transformSoundEntry);
  const trackSuggestions = data.allSessionTrackSuggestions.map(
    transformTrackSuggestionEntry
  );
  return { sessionSounds, trackSuggestions };
};

const EXCLUSIVE_TAGS = ["agenda-block"];

export const getRoomTemplatesData = async (filterTags: string[] = []) => {
  const data = await graphqlQuery(RoomTemplatesDocument);
  const { allAgendaActivities, allAgendaTemplates, allActivityCategories } =
    data;

  const assertedActivities = allAgendaActivities.map(transformAgendaActivity);
  const assertedTemplates = allAgendaTemplates.reduce((acc, template) => {
    if (
      !filterTags.length &&
      template.tags.every((tag) => !EXCLUSIVE_TAGS.includes(tag?.slug || ""))
    ) {
      acc.push(transformTemplate(template));
    } else if (
      template.tags.some((tag) => filterTags.includes(tag?.slug || ""))
    ) {
      acc.push(transformTemplate(template));
    }
    return acc;
  }, [] as AgendaTemplate[]);
  const assertedActivityCategories = allActivityCategories.map(
    transformActivityCategory
  );

  return {
    activityCategories: assertedActivityCategories,
    agendaActivities: assertedActivities,
    templates: assertedTemplates,
  };
};

export const transformFocusRoomToP2PEvent = (
  focusRoom: P2PEventDb,
  user: PopulatedHost & { focusRoom?: P2PEventDb }
) => {
  // This is to avoid having the own focus room nest within itself under the host
  // I'm pretty sure there must be a smarter way to do this though
  const populatedHost = user;
  delete populatedHost.focusRoom;

  return {
    ...focusRoom,
    acceptedUserIds: focusRoom.acceptedUserIds ?? [],
    isOriginalHost: true,
    flags: {},
    // We override the populatedHost and populatedClaimedHost to remove the focusRoom property
    populatedHost: { ...user, focusRoom: null },
    populatedClaimedHost: { ...user, focusRoom: null },
    populatedCohosts: [],
  };
};

type GetScreenSettingsArgs = {
  room: P2PEvent | P2PEventDb;
  isOwner: boolean;
  isFlownie: boolean;
};

export const getScreenSettings = ({
  room,
  isOwner,
  isFlownie,
}: GetScreenSettingsArgs) => ({
  // Recording only enabled in private sessions for a FLOWN host
  enableRecording: room.privacy === "private" && isOwner && isFlownie,
  enableScreenshare: room.privacy === "private" || isOwner,
});
