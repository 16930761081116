import { Modal } from "design-system/organisms/modals";
import { Box, Button, Text } from "design-system/components";
import { useUserContext } from "context/user-context";
import { FlownEvent } from "shared/event.types";
import { useCheckValidCreditsForUser } from "../hooks/use-check-valid-credits-for-user";
import dayjs from "dayjs";
import { pluralise } from "utils/string-utils";
import { getIsDropInEvent } from "utils/event-utils";
import { SESSION_COMPLETION_TIME } from "features/sessions/shared";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";

type CheckCreditsModalProps = {
  open: boolean;
  event: Pick<FlownEvent, "displayName" | "id" | "slug"> | null;
  onClose: () => void;
  onCancel?: () => void;
  onJoinConfirmed: () => void;
};

export const CheckCreditsModal = ({
  onClose,
  open,
  event,
  onJoinConfirmed,
  onCancel,
}: CheckCreditsModalProps) => {
  const { hasValidCredits, isFreeCreditsRole, externalId } = useUserContext();

  const { currentCredit } = useCheckValidCreditsForUser({
    externalId,
    isFreeCreditsRole,
    shouldTriggerCheck: open,
  });

  const hasConsumedCreditForSession = currentCredit?.sessionHistory.some(
    (activity) => activity.eventId === event?.id
  );

  const isDropIn = event ? getIsDropInEvent(event) : false;

  return (
    <>
      <Modal
        showCloseBtn
        onClose={onClose}
        title={
          event && (
            <Text className="pad-x-32 pad-y-16" fontSize="2xl" fontWeight={700}>
              {hasValidCredits && currentCredit
                ? `Join ${event.displayName}?`
                : `You can't join ${event.displayName}`}
            </Text>
          )
        }
        open={open}
        fullPopup
      >
        <Box className="flex-col pad-x-32 pad-y-16">
          {hasValidCredits && currentCredit ? (
            <Box className="flex-col gap-8">
              {!hasConsumedCreditForSession || isDropIn ? (
                <>
                  {isDropIn && hasConsumedCreditForSession && (
                    <Text>
                      Your credit for {SESSION_COMPLETION_TIME}mins in the
                      Drop-in has been used up
                    </Text>
                  )}
                  <Text>
                    You are about to join this session. You have{" "}
                    {currentCredit.sessionsLeft}{" "}
                    {pluralise(currentCredit.sessionsLeft, "session")} left
                    until{" "}
                    <strong>
                      {dayjs(currentCredit.endDate).format("DD MMM YYYY")}.
                    </strong>
                  </Text>
                  <Text>
                    Joining will use up one of your sessions. You will have{" "}
                    <strong>
                      {currentCredit.sessionsLeft - 1}{" "}
                      {pluralise(currentCredit.sessionsLeft - 1, "session")}{" "}
                      left.
                    </strong>
                  </Text>
                </>
              ) : (
                <Text>
                  You are rejoining this session. This will not come out of your
                  allowance.
                </Text>
              )}
            </Box>
          ) : (
            `You have run out of sessions for this month. Subscribe to get unlimited sessions.`
          )}
          <Box className="flex justify-end mar-y-16 gap-16">
            <Button
              variant="ghost"
              onClick={() => {
                onCancel?.();
                onClose();
              }}
            >
              Cancel
            </Button>
            {hasValidCredits && currentCredit ? (
              <Button
                variant="primary"
                onClick={() => {
                  onClose();
                  onJoinConfirmed();
                }}
                autoFocus
              >
                Join session
              </Button>
            ) : (
              <Button
                as="a"
                variant="primary"
                autoFocus
                href={getSubscribePageLinkWithSubscribeSource("credits-modal")}
              >
                Subscribe
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
