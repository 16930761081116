import React, { ComponentProps, ReactNode } from "react";
import {
  Root,
  Trigger,
  Content,
  Arrow,
  Portal,
  Close,
  PopoverProps as RadixPopoverProps,
} from "@radix-ui/react-popover";
import { commonStyles } from "./shared/tooltip-and-popover";
import styled from "@emotion/styled";
import { Icon } from ".";

type PopoverProps = {
  closeElement?: ReactNode;
  contentProps?: ComponentProps<typeof Content>;
  contentStyle?: React.CSSProperties;
  children: ReactNode;
  /**
   * @param triggerElement Must be an HTML tag, React components will not render the popover correctly. If needed, wrap the component in a div or a span.
   */
  triggerElement: ReactNode;
  triggerStyle?: React.CSSProperties;
  showClose?: boolean;
  onInteractOutside?: () => void;
  onOpenAutoFocus?: (event: Event) => void;
};

export const Popover = ({
  contentStyle,
  contentProps,
  children,
  triggerElement,
  triggerStyle,
  showClose = false,
  onInteractOutside,
  onOpenAutoFocus,
  ...props
}: PopoverProps & RadixPopoverProps) => (
  <Root {...props}>
    <Trigger asChild style={triggerStyle ?? {}}>
      {triggerElement}
    </Trigger>
    <Portal>
      <StyledContent
        onOpenAutoFocus={onOpenAutoFocus}
        onInteractOutside={onInteractOutside}
        sideOffset={5}
        style={contentStyle}
        {...contentProps}
      >
        {showClose && (
          <Close
            className="pad-x-8 no-focus-outline"
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
          >
            <Icon icon="close" />
          </Close>
        )}
        {children}
        <Arrow
          style={{
            fill: "white",
          }}
        />
      </StyledContent>
    </Portal>
  </Root>
);

const StyledContent = styled(Content)(({ theme }) => ({
  ...commonStyles(theme),
  position: "relative",
}));
