import styled from "@emotion/styled";
import { TimelineBlockFragment } from "generated/graphql-typed";
import { getImageUrl, HasChildren } from "utils/component-utils";
import { renderer } from "./renderer";
import { ModularContent } from "./modular-content";
import { Children, Fragment } from "react";
import Image from "next/image";
import { Icon } from "design-system/components";

const TimelineGroupWrapper = (props: HasChildren & { className: string }) => (
  <HistoryStepsGrid
    totalItems={Children.count(props.children)}
    className={props.className}
  >
    {props.children}
  </HistoryStepsGrid>
);
const TimelineView = (
  props: TimelineBlockFragment & { indexInGroup: number; className: string }
) => {
  const area = props.indexInGroup + 1;
  const Container = isEven(props.indexInGroup)
    ? MissionTextContainerLeft
    : MissionTextContainerRight;
  const Img = isEven(props.indexInGroup)
    ? MissionImgContainerRight
    : MissionImgContainerLeft;

  return (
    <Fragment key={props.indexInGroup}>
      {props.content && (
        <Container area={area} className={props.className}>
          <ModularContent
            data={props.content}
            paragraphSize={props.paragraphSize}
          />
        </Container>
      )}
      {props.image && (
        <Img
          height={222}
          width={295}
          src={getImageUrl({ url: props.image.url, width: 295, height: 222 })}
          area={area}
          alt={props.image.alt || ""}
        />
      )}
      {props.content && <ProgressSmall gridArea={1} />}
    </Fragment>
  );
};

export const TimelineRenderer = renderer({
  typeName: "TimelineRecord",
  view: TimelineView,
  groupComponent: TimelineGroupWrapper,
});

const isEven = (num: number) => num % 2 === 0;

type ProgressContainerProps = {
  gridArea: number;
};

const ProgressContainer: React.FC<ProgressContainerProps> = () => (
  <StoryProgressContainer>
    <StoryLine />
    <StoryProgressSparkle icon="sparkle-filled" />
  </StoryProgressContainer>
);

const ProgressSmall = styled(ProgressContainer)`
  grid-area: ${(props: { gridArea: number }) => `progressSm${props.gridArea}`};
`;

const HistoryStepsGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 120px auto 125px auto;
  max-width: 1200px;
  align-items: center;
  overflow-y: hidden;
  @media only screen and (min-width: 640px) {
    display: grid;
    align-items: unset;
    grid-row-gap: 64px;
    grid-column-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 0.5fr 1fr;
    grid-template-areas: ${(props: { totalItems: number }) => {
      let result = "";
      for (let i = 1; i <= props.totalItems; i++) {
        result += isEven(i)
          ? `"missionImg${i} progressSm${i} missionText${i}"\n`
          : `"missionText${i} progressSm${i} missionImg${i}"\n`;
      }
      return result;
    }};
  }
`;
const MissionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MissionTextContainerLeft = styled(MissionTextContainer)`
  grid-area: ${(props: { area: number }) => `missionText${props.area}`};
  margin: 0 20px;
  @media only screen and (min-width: 640px) {
    text-align: right;
  }
`;

const MissionTextContainerRight = styled(MissionTextContainer)`
  margin: 0 20px;
  grid-area: ${(props: { area: number }) => `missionText${props.area}`};
  @media only screen and (min-width: 640px) {
    text-align: left;
  }
`;

const StoryProgressContainer = styled.div`
  display: none;
  position: relative;
  @media only screen and (min-width: 640px) {
    display: block;
  }
`;

const StoryLine = styled.div`
  position: absolute;
  left: 50%;
  top: -75px;
  bottom: -70px;
  background: black;
  width: 1px;
`;

const StoryProgressSparkle = styled(Icon)`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 9px);
`;

const MissionImgContainerRight = styled(Image)`
  margin: 50px 0;
  border-radius: 8px;
  grid-area: ${(props: { area: number }) => `missionImg${props.area}`};
  @media only screen and (min-width: 640px) {
    margin: 0;
  }
`;

const MissionImgContainerLeft = styled(Image)`
  grid-area: ${(props: { area: number }) => `missionImg${props.area}`};
  justify-self: center;
  margin: 50px 0;
  border-radius: 8px;
  @media only screen and (min-width: 640px) {
    justify-self: end;
    margin: 0;
  }
`;
