import { MetaHeader } from "design-system/components";
import { renderer } from "./renderer";
import { MetaHeaderBlockFragment } from "generated/graphql-typed";

const MetaHeaderView = ({
  metaTitle,
  metaDescription,
  metaImage,
  metaKeywords,
  metaAuthor,
}: MetaHeaderBlockFragment & { className: string }) => {
  return (
    <MetaHeader
      title={metaTitle || ""}
      description={metaDescription}
      imageUrlParam={metaImage?.url}
      keywords={metaKeywords}
      author={metaAuthor}
    />
  );
};

export const MetaHeaderRenderer = renderer({
  typeName: "MetaHeaderRecord",
  view: MetaHeaderView,
});
