import { SpacerBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { styled } from "styled-system/jsx";
import { useIsMediaTabletOrLower } from "utils/component-utils";

export const SpacerView = ({
  verticalSpacing,
  verticalSpacingMobile,
}: SpacerBlockFragment & { className: string }) => {
  const isLegacy = verticalSpacing === "" && verticalSpacingMobile === "";
  const isMobile = useIsMediaTabletOrLower();

  return isLegacy ? (
    // In order to avoid regression with the legacy usage of this component
    // we render these nested divs with a blank space to reproduce the old behavior if no props are passed
    <div>
      <div> </div>
    </div>
  ) : (
    <styled.div
      data-component="Spacer"
      style={{
        margin: 0,
        marginTop: isMobile
          ? `${verticalSpacingMobile}px`
          : `${verticalSpacing}px`,
      }}
    />
  );
};

export const SpacerRenderer = renderer({
  typeName: "SpacerRecord",
  view: SpacerView,
});
