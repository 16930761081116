import { getUiContent } from "features/modular-content/client";
import {
  ReusableContentBlockFragment,
  UiContentQuery,
} from "generated/graphql-typed";
import { Box, Spinner } from "design-system/components";
import { useEffect, useState } from "react";
import { ModularContent } from "./modular-content";
import { renderer } from "./renderer";

const ReusableContentView = (
  props: ReusableContentBlockFragment & { className: string }
) => {
  if (props.uiContent?.slug) {
    return <UiContentBlock uiContentSlug={props.uiContent.slug} />;
  }
  return <></>;
};

export const UiContentBlock = ({
  uiContentSlug,
}: {
  uiContentSlug: string;
}) => {
  const [data, setData] = useState<UiContentQuery["uiContent"] | null>(null);
  useEffect(() => {
    const fetchUiConntent = async (uiContentSlug: string) => {
      const result = await getUiContent(uiContentSlug)();

      if (result.worked) {
        setData(result.uiContent);
      }
    };

    void fetchUiConntent(uiContentSlug);
  }, [uiContentSlug]);

  return (
    <>
      {data ? (
        <ModularContent
          data={data.content}
          paragraphSize={data?.paragraphSize}
        />
      ) : (
        <Box className="flex justify-center">
          <Spinner color="black" />
        </Box>
      )}
    </>
  );
};

export const ReusableContentRenderer = renderer({
  typeName: "ReusableContentRecord",
  view: ReusableContentView,
});
