import { useEffect, useState } from "react";
import { renderer } from "./renderer";
import { analytics, catchAnalyticsError } from "features/analytics";
import { UpdateBannerBlockFragment } from "generated/graphql-typed";

import { Banner } from "design-system/molecules/banner/banner";

const UpdateBannerView = ({
  className,
  id,
  link,
  update,
}: UpdateBannerBlockFragment & { className: string }) => {
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  useEffect(() => {
    const updateBannerKey = `updateBannerHidden-${id}`;
    if (localStorage.getItem(updateBannerKey)) return;

    setShouldShowBanner(true);
  }, [id]);

  const onCloseBannerClick = () => {
    setShouldShowBanner(false);
    localStorage.setItem(`updateBannerHidden-${id}`, "1");
  };

  const recordClickEvent = () => {
    analytics
      .track("Launchpad Update Banner Clicked", {
        bid: id,
      })
      .catch(catchAnalyticsError);
  };

  if (!shouldShowBanner) {
    return <></>;
  }

  return (
    <Banner
      className={className}
      href={link ? link : ""}
      text={update || ""}
      onLinkClick={recordClickEvent}
      onCloseBannerClick={onCloseBannerClick}
    />
  );
};

export const UpdateBannerRenderer = renderer({
  typeName: "UpdateBannerRecord",
  view: UpdateBannerView,
});
