import styled from "@emotion/styled";
import { Box, Button, Heading, Text } from "design-system/components";
import { capitalizeFirstLetter } from "utils/string-utils";
import { EnhancedProductPrice } from "../../shared";
import { useUserContext } from "context/user-context";
import Link from "next/link";
import {
  REGISTER_URL,
  SUBSCRIBE_URL,
  SUBSCRIPTION_UPGRADE_URL,
} from "utils/urls";
import { ClassNameProvider } from "utils/component-utils";
import { QUERY_PARAMS } from "utils/url-query-utils";

type PriceCardProps = Omit<EnhancedProductPrice, "priceId"> &
  ClassNameProvider & {
    isUpgrade?: boolean;
  };

export const PriceCard = ({
  billingFrequency,
  className,
  currencySymbol,
  currency,
  isUpgrade,
  paymentLink,
  pricePerMonth,
  type,
  discountPercent = 0,
  promoCode,
}: PriceCardProps) => {
  const { loggedIn } = useUserContext();

  const linkURL =
    type === "lifetime"
      ? paymentLink
      : loggedIn
      ? isUpgrade
        ? SUBSCRIPTION_UPGRADE_URL
        : `${SUBSCRIBE_URL}/`
      : REGISTER_URL;

  const discountPrice: number =
    Number(pricePerMonth) - (Number(pricePerMonth) * discountPercent) / 100;

  return (
    <PriceCardContainer className={className || ""}>
      <Heading as="h3" className="text-bold">
        {capitalizeFirstLetter(type)}
      </Heading>
      <Box className="flex-col gap-8">
        <Heading as="h3">
          {currencySymbol}

          {discountPercent > 0 ? (
            <>
              <s>{pricePerMonth}</s> {currencySymbol}
              {discountPrice.toFixed(2)}
            </>
          ) : (
            pricePerMonth
          )}
          {type !== "lifetime" && (
            <Text as="span" fontSize="md">
              /month
            </Text>
          )}
        </Heading>
        <Text>Billed {billingFrequency} </Text>
      </Box>
      <Link
        href={`${linkURL}${
          !isUpgrade && type !== "lifetime"
            ? `?${QUERY_PARAMS.SUBSCRIBE_SOURCE}=price-card-${type}&${
                QUERY_PARAMS.PRICE_TYPE
              }=${type}&${QUERY_PARAMS.CURRENCY}=${currency}${
                promoCode ? "&" + QUERY_PARAMS.PROMO_CODE + "=" + promoCode : ""
              }`
            : ""
        }`}
      >
        <Button variant="secondary" full dataCy={`choose-plan-${type}`}>
          {loggedIn
            ? isUpgrade
              ? `Upgrade to ${type}`
              : `Choose ${type}`
            : "Try for free"}
        </Button>
      </Link>
    </PriceCardContainer>
  );
};

const PriceCardContainer = styled.div(({ theme }) => ({
  alignContent: "center",
  backgroundColor: theme.colors.black,
  borderRadius: theme.radii[16],
  color: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  gridGap: theme.spacing[16],
  textAlign: "center",
  padding: theme.spacing[16],
  width: "100%",

  [theme.media["bp-desktop-xs"]]: {
    gridGap: theme.spacing[24],
    padding: theme.spacing[24],
  },

  [theme.media["bp-desktop-sm"]]: {
    gridGap: theme.spacing[32],
    padding: theme.spacing[32],
  },

  "&.highlighted": {
    borderRadius: theme.radii[20],
    border: `solid 6px ${theme.colors.grassLight}`,
  },
}));
