import { IdOrString } from "database";
import { AnalyticsData, CorporateData } from "database/collections/users";
import { CustomOrgParentTag } from "features/organisations-management/shared";
import { Dispatch, SetStateAction } from "react";
import { UserRole, UserRoleProps } from "services/roles/user-roles";
import { WorkedOrError } from "shared/response.types";
import { FollowUser, UserPreferences } from "shared/user.types";
import { OPTIONAL_NAV_MENU_ITEMS } from "utils/constants";

type MenuItems = Array<
  typeof OPTIONAL_NAV_MENU_ITEMS[keyof typeof OPTIONAL_NAV_MENU_ITEMS]
>;

export type UserOrganisationWithName = Omit<CorporateData, "id"> & {
  id: IdOrString;
  name: string;
  shortName?: string;
  logoUrl?: string;
  menuItems?: MenuItems;
  customTags: CustomOrgParentTag[];
};

export type UserState = UserRoleProps & {
  loggedIn?: boolean;
  oneLiner?: string;
  headline?: string;
  vanityUrl?: string;
  freeTrialRemainingDays?: number;
  freeTrialExpiry: string;
  email?: string;
  externalId?: string;
  isVerified: boolean;
  referralCode?: string;
  userRole: UserRole;
  firstName: string;
  lastName: string;
  createTime: string;
  displayName?: string;
  avatarUrl?: string;
  profileTags?: string[];
  followers: FollowUser[];
  following: FollowUser[];
  canHostSessions: boolean;
  preferences?: UserPreferences;
  organisation?: UserOrganisationWithName;
  isPremiumOrganisation?: boolean;
  hasValidCredits?: boolean;
  analyticsData?: AnalyticsData;
};

export type UserContextValue = UserState & {
  focusRoomUrl?: string;
  onFollow: (user: FollowUser) => Promise<WorkedOrError>;
  onUnfollow: (user: FollowUser) => Promise<WorkedOrError>;
  registeredEventsIds: string[];
  setRegisteredEventsIds: Dispatch<SetStateAction<string[]>>;
};

export const markFollowState =
  (following: FollowUser[], viewerExternalId?: string) =>
  (user: FollowUser): FollowUser => ({
    ...user,
    isFollowed: !!following.find(
      (followingUser) => followingUser.externalId === user.externalId
    ),
    isSelf: user.externalId === viewerExternalId,
  });
