import styled from "@emotion/styled";
import {
  EmptyNavMenu,
  MarketingNavMenu,
  NavMenu,
  TOP_NAV_HEIGHT,
} from "design-system/organisms/nav-menu";
import { desktopQuery } from "../variables";
import React, { useEffect, useRef } from "react";
import { isStaticPageWithHybridNavigation } from "utils/urls";
import { getPageLayout } from "./page-layouts";
import { useUserContext } from "context/user-context";
import { useIsMediaDesktop } from "utils/component-utils";
import { PageWrapperRefContextProvider } from "context/page-wrapper-ref-context";
import { useSidePanel } from "context/side-panel-context";
import { NORMAL_SIZE, SidePanel } from "design-system/organisms/side-panel";
import { token } from "styled-system/tokens";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";

type AppWrapperProps = {
  loggedIn: boolean;
  asPath: string;
  pathname: string;
};

export const AppWrapper: React.FC<
  { children: React.ReactNode } & AppWrapperProps
> = ({ pathname, children, asPath }) => {
  const { sidePanelState, closeSidePanel, navigateBack, sidePanelHistory } =
    useSidePanel();
  const pageContentRef = useRef<HTMLDivElement>(null);
  const { loggedIn } = useUserContext();
  const isDesktop = useIsMediaDesktop();

  // on page change, scroll to top
  useEffect(() => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTo(0, 0);
    }
  }, [asPath]);

  useEffect(() => {
    // close side panel on page change
    closeSidePanel();

    // we only want to run this on page change - adding in closeSidePanel
    // caused an update whenever the sidepanel provider was called
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  const { wrapperClassName, web, mobile, configOptions } = getPageLayout({
    pathname,
    asPath,
  });

  /**
   * Hybrid navigation pages (side bar for logged in and top bar for logged out)
   * should only be render after the userdata is fetched,
   * which in the case of static generated pages is done in the client side,
   * and for SS rendered pages the data comes preloaded
   */

  return !isStaticPageWithHybridNavigation(pathname) ||
    loggedIn !== undefined ? (
    <Wrapper
      className={[loggedIn ? "logged-in" : "", wrapperClassName]
        .filter(Boolean)
        .join(" ")}
      mobileTemplate={mobile}
      webTemplate={web}
    >
      {wrapperClassName === "empty-nav" && configOptions ? (
        <EmptyNavMenu {...configOptions} />
      ) : wrapperClassName === "register-empty-nav" ? (
        <EmptyNavMenu
          logoColor="white"
          logoAlignment="center"
          isLogoClickable={true}
        />
      ) : loggedIn ? (
        wrapperClassName === "no-nav" ? null : wrapperClassName ===
          "marketing-nav" ? (
          <MarketingNavMenu />
        ) : (
          <NavMenu showSecondary={wrapperClassName !== "primary-nav-only"} />
        )
      ) : wrapperClassName === "no-nav" ? null : (
        <MarketingNavMenu />
      )}
      <PageWrapper
        id="page-wrapper"
        ref={pageContentRef}
        mobileTemplate={mobile}
        webTemplate={web}
        className={[loggedIn ? "logged-in" : "", wrapperClassName]
          .filter(Boolean)
          .join(" ")}
      >
        <PageWrapperRefContextProvider value={pageContentRef}>
          {children}
          <SidePanel
            topPosition={isDesktop && wrapperClassName !== "no-nav" ? 60 : 0}
            open={sidePanelState.isOpen}
            onClose={() => {
              closeSidePanel();
            }}
            sidePanelHistory={sidePanelHistory}
            onNavigateBack={navigateBack}
            title={sidePanelState.title}
            size={sidePanelState.size || NORMAL_SIZE}
            preventDismissOnOverlayClick={
              sidePanelState.preventDismissOnOverlayClick
            }
          >
            {sidePanelState.content}
          </SidePanel>
        </PageWrapperRefContextProvider>
      </PageWrapper>
    </Wrapper>
  ) : (
    <></>
  );
};

type WrapperProps = {
  mobileTemplate: string;
  webTemplate: string;
};

const Wrapper = styled.div<WrapperProps>`
  &.register-empty-nav,
  &.login-nav {
    height: 100%;

    nav {
      position: static;
    }
  }

  &:has(.hide-header) {
    [data-component="MarketingNavMenu"] {
      display: none;
    }
  }

  &.logged-in:not(.marketing-nav) {
    display: grid;
    grid-template-columns: 1fr;
    ${(props) => props.mobileTemplate}
    &.no-nav {
      width: 100%;
    }
    &.secondary-nav-only {
      grid-template-rows: ${token("sizes.secondaryNavHeight")} calc(
          100vh - ${token("sizes.secondaryNavHeight")}
        );
    }

    &.empty-nav {
      display: block;
    }

    @media only screen and (min-width: ${THEME_BREAKPOINTS.tablet}px) {
      ${(props) => props.webTemplate};
      &.primary-nav-only,
      &.full-nav {
        grid-template-columns: ${token("sizes.primaryNavWidth")} 1fr;
      }
      grid-template-rows: ${token("sizes.secondaryNavHeight")} calc(
          100vh - ${token("sizes.secondaryNavHeight")}
        );
    }
  }
`;

const PageWrapper = styled.div<WrapperProps>`
  position: relative;
  scroll-behavior: smooth;
  grid-area: page;
  &.logged-in:not(.marketing-nav) {
    overflow-y: auto;
    &.show-profile {
      padding-top: 0;
      overflow-y: unset;
    }

    &.show-profile {
      @media ${desktopQuery} {
        margin-top: -${token("sizes.secondaryNavHeight")};
      }
    }

    &.no-nav {
      padding: 0;
    }

    &.empty-nav {
      padding: 0;
    }
  }
  &.login-nav {
    min-height: calc(100vh - ${TOP_NAV_HEIGHT}px);
  }
  &.register-empty-nav {
    min-height: 100vh;
    margin-top: -${TOP_NAV_HEIGHT}px;
  }
`;
