import {
  BlogPost,
  DatoBlogPost,
  RelatedBlogPost,
} from "./pillars-and-blog-types";
import { assertFieldsNotNull } from "utils/assertions";
import { getTypedTags } from "utils/tag-utils";
import { BlogPostRecord } from "generated/graphql-typed";
import dayjs from "dayjs";

export const sortByOriginalPublishedAt = (a: BlogPost, b: BlogPost) => {
  const aDate = a?.publishedAt;
  const bDate = b?.publishedAt;
  return aDate > bDate ? -1 : 1;
};

type PossibleDatoBlogTypes = DatoBlogPost | RelatedBlogPost;

export const transformToBlog = (post: PossibleDatoBlogTypes): BlogPost => {
  if (!post) throw new Error("Dato blog post should always be defined here");
  const postRecord = post as BlogPostRecord;
  const { category, title, author, slug } = assertFieldsNotNull(postRecord, [
    "title",
    "author",
    "slug",
    "category",
  ]);
  return {
    title,
    slug,
    author,
    tags: getTypedTags(post.tags),
    publishedAt:
      post.originalPublishedAt || post._publishedAt || dayjs().toISOString(),
    mediaThumbnail: post.mediaThumbnail,
    category,
    content: postRecord.content || null,
    introContent: postRecord.introContent || null,
    previewDescription: postRecord.previewDescription || null,
    metadata: postRecord.metadata || null,
    template: postRecord.template || null,
    blogAdvert: postRecord.blogAdvert || null,
  };
};

export const transformAndSortBlogPosts = (
  datoPosts: RelatedBlogPost[] | DatoBlogPost[]
) => datoPosts.map(transformToBlog).sort(sortByOriginalPublishedAt);
