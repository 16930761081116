import { Box, Text } from "design-system/components";
import { SubtaskCheckbox } from "./common";
import { IntentionSubtask } from "../../../shared/types";

type IntentionSubtaskView = () => {
  subtask: IntentionSubtask;
};

export const IntentionSubtaskView = ({ subtask }) => {
  return (
    <Box className="flex gap-16 align-center">
      <SubtaskCheckbox
        disabled
        type="checkbox"
        checked={subtask.completed}
        aria-label={`Toggle complete status for subtask: ${subtask.text}`}
      />
      <Text
        style={{
          textDecoration: subtask.completed ? "line-through" : "none",
        }}
        fontSize="sm"
      >
        {subtask.text}
      </Text>
    </Box>
  );
};

IntentionSubtaskView.displayName = "IntentionSubtaskView";
