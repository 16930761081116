import { useUserContext } from "context/user-context";
import { SearchUsersInput, UserFollowCard } from "features/profile/client";
import { Box, BoxCol } from "design-system/components";
import { UserProfileFromActivityNoId } from "shared/user.types";
import { useFollowUnfollowList } from "utils/hooks/use-list-follow-unfollow";

export const UserSearchAndFollowSuggestions = ({
  initialSuggestions,
}: {
  initialSuggestions: UserProfileFromActivityNoId[];
}) => {
  const { following, organisation } = useUserContext();

  const { followList, onFollow, onUnfollow } = useFollowUnfollowList({
    initialList: initialSuggestions,
  });

  return (
    <Box className="flex-col gap-16">
      <SearchUsersInput
        following={following}
        organisationId={organisation?.id.toString()}
        blankState={
          <BoxCol className="mar-y-16">
            {followList?.map((user) => (
              <UserFollowCard
                key={`${user.externalId}`}
                user={user}
                onFollow={(user) => onFollow(user)}
                onUnfollow={(user) => onUnfollow(user)}
              />
            ))}
          </BoxCol>
        }
      />
    </Box>
  );
};
