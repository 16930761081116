import { getUserDisplayName } from "features/profile/shared";
import { postToGetUserListByExternalId } from "features/user";
import { UserCardData } from "features/user/shared";
import { Box, TextError } from "design-system/components";
import { UserCard } from "design-system/molecules/cards/user-card";
import { useEffect, useState } from "react";

type ReactionsUserListProps = {
  externalIds: string[];
};

export const ReactionsUserList = ({ externalIds }: ReactionsUserListProps) => {
  const [userList, setUserList] = useState<UserCardData[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await postToGetUserListByExternalId({ externalIds });
        if (response.worked) {
          setUserList(response.userList);
        }
        setErrorMessage("");
      } catch {
        setErrorMessage("Failed to load user list");
      } finally {
        setLoading(false);
      }
    };
    void fetchUsers();
  }, [externalIds]);
  return loading ? (
    <SkeletonLoadingUserList externalIds={externalIds} />
  ) : errorMessage ? (
    <TextError>{errorMessage}</TextError>
  ) : (
    <Box className="flex-col gap-12">
      {userList.map((user) => (
        <UserCard
          key={user.externalId}
          externalId={user.externalId}
          avatarUrl={user.avatarUrl}
          oneLiner={user.oneLiner}
          location={user.location}
          displayName={getUserDisplayName(user)}
          size="small"
        />
      ))}
    </Box>
  );
};

const SkeletonLoadingUserList = ({
  externalIds,
}: {
  externalIds: string[];
}) => (
  <div className="flex-col gap-16">
    {externalIds.map((id) => (
      <div key={id} className="flex gap-12">
        <div
          className="skeleton border-radius-round"
          style={{ height: "32px", width: "32px" }}
        />
        <div className="skeleton" style={{ height: "16px", width: "140px" }} />
      </div>
    ))}
  </div>
);
