import styled from "@emotion/styled";

import { RoundedButton } from "./button";

export const PrimaryButton = styled(RoundedButton)`
  background: black;
  color: white;
  &:disabled {
    cursor: not-allowed;
    background-color: grey;
  }
  &:not(:disabled) {
    &:hover {
      background: ${({ theme }) => theme.colors.focusMid};
    }
  }
`;

export const PrimaryButtonFullWidth = styled(PrimaryButton)`
  width: 100%;
`;

export const PrimaryButtonBlock = styled(PrimaryButton)`
  display: block;
  margin: 0 auto;
`;
