"use client";
import { SessionRecord } from "shared/session.types";
import { getSessionsAttendedEndpoint } from "../calendar-client";
import { useEffect, useState } from "react";
import { useUserContext } from "context/user-context";

export const useGetSessionsAttended = () => {
  const [sessionsAttended, setSessionsAttended] = useState<SessionRecord[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { loggedIn } = useUserContext();

  useEffect(() => {
    const setSessionsAttendedByUser = async () => {
      if (!loggedIn) return;
      setIsLoading(true);
      const response = await getSessionsAttendedEndpoint();

      if (response.worked) {
        setSessionsAttended(response.sessionsAttended);
      } else {
        setError(`Could not fetch sessions attended ${response.error}`);
      }
      setIsLoading(false);
    };

    setSessionsAttendedByUser().catch((err) =>
      setError(`Could not fetch sessions attended ${err}`)
    );
  }, [loggedIn]);

  return { sessionsAttended, error, isLoading };
};
