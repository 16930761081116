import styled from "@emotion/styled";
import { EmotionTheme } from "design-system/emotion.theme";

export type InputSizes = "x-small" | "small" | "medium";

export const inputStyles = (theme: EmotionTheme) => ({
  border: `solid 2px ${theme.colors.grey100}`,
  borderRadius: theme.radii[24],
  fontSize: theme.fontSizes.sm,
  height: "fit-content",
  padding: theme.spacing[12],
  transition: "all 0.2s ease-in-out",

  "&:disabled, &.disabled": {
    backgroundColor: theme.colors.grey100,
    border: `solid 2px ${theme.colors.grey100}`,
    color: theme.colors.grey300,
  },

  "&.small": {
    padding: theme.spacing[8],
  },

  "&.x-small": {
    fontSize: theme.fontSizes.xs,
    padding: theme.spacing[4],
  },

  "&.full": {
    width: "100%",
  },

  "&:placeholder": {
    color: theme.colors.grey100,
  },

  "&:hover": {
    backgroundColor: theme.colors.grey100,
  },
  "&:focus": {
    border: `solid 2px ${theme.colors.focusMid}`,
    boxShadow: "none",
    outline: "none",
  },

  "&.has-error": {
    borderColor: theme.colors.error,
  },
});

export const Label = styled.label(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.grey300,
  display: "flex",
  fontSize: theme.fontSizes.sm,
  fontWeight: 400,
  gap: theme.spacing[4],
}));

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  width: 100%;
`;
