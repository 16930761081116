import { useEffect } from "react";

type IntercomParams = {
  externalId: string;
  intercomVerificationHash: string;
  loggedIn: boolean;
};

export const useIntercom = ({
  externalId,
  intercomVerificationHash,
  loggedIn,
}: IntercomParams) => {
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
        custom_launcher_selector: "#intercom-launcher",
        ...(loggedIn
          ? {
              user_id: externalId,
              user_hash: intercomVerificationHash,
            }
          : {}),
      });
    }
  }, [externalId, intercomVerificationHash, loggedIn]);
};
