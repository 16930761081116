import React from "react";
import { HasChildren } from "utils/component-utils";

type View = React.FC<any> & {
  groupComponent?: React.FC<HasChildren & { className: string }>;
};

export type Renderer = {
  typeName: string;
  view: View;
};

export type RendererParams<
  T extends string,
  P extends { __typename: T; className: string }
> = {
  typeName: T;
  view: React.FC<P>;
  groupComponent?: React.FC<HasChildren & { className: string }>;
};

export const renderer = <
  T extends string,
  P extends { __typename: T; className: string }
>(
  params: RendererParams<T, P>
): Renderer => {
  const view: View = params.view;
  view.groupComponent = params.groupComponent;
  return { view, typeName: params.typeName };
};

export const getGroupComponent = (view: View | null | undefined) =>
  view?.groupComponent || null;
