"use client";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";
import { Agenda, getAgendaDuration } from "features/sessions/shared";
import { clientLoggerForFile } from "utils/logger.client";
import { getFormattedMinutesAndSeconds } from "utils/time";
import { getEventHasStarted } from "utils/event-utils";
import { getCurrentActivityIndex } from "../components/flownogram/agenda-utils";

const logger = clientLoggerForFile(__filename);

type AgendaProgressContextData = {
  currentActivity: Agenda[number] | null;
  currentActivityIndex: number | null;
  currentActivityRemainingSeconds: number;
  currentProgress: number;
  elapsedMinutes: number;
  elapsedTimeDisplayValue: string;
  isAgendaBlock?: boolean;
  timerDisplayValue: string;
  timeToStart: string | null;
};

export type Tabs = "people" | "intentions" | "chat";

const AgendaProgressContext = createContext({} as AgendaProgressContextData);

type AgendaProgressContextProviderProps = {
  agenda: Agenda;
  children: ReactNode;
  isAgendaBlock?: boolean;
  startTime?: string;
};

export const AgendaProgressContextProvider = ({
  agenda,
  children,
  isAgendaBlock = false,
  startTime,
}: AgendaProgressContextProviderProps) => {
  const [timeToStart, setTimeToStart] = useState<string | null>(null);
  const totalDuration = getAgendaDuration(agenda);
  const hasEventStarted = startTime ? getEventHasStarted(startTime) : false;
  const [elapsedMinutes, setElapsedMinutes] = useState<number>(0);
  const [remainingSeconds, setRemainingSeconds] = useState(
    (totalDuration - elapsedMinutes) * 60
  );

  const [elapsedTimeDisplayValue, setElapsedTimeDisplayValue] =
    useState<string>(getFormattedMinutesAndSeconds(elapsedMinutes * 60));

  const currentActivityIndex = useMemo(
    () => getCurrentActivityIndex(agenda, elapsedMinutes),
    [agenda, elapsedMinutes]
  );
  const currentActivity =
    currentActivityIndex !== null ? agenda[currentActivityIndex] : null;

  const [currentActivityRemainingSeconds, setCurrentActivityRemainingSeconds] =
    useState<number>(0);

  useEffect(() => {
    if (!startTime) return;

    const updateSessionProgress = () => {
      if (hasEventStarted) {
        const elapsedSeconds = dayjs().diff(dayjs(startTime), "seconds");
        // TODO double check if total duration is in seconds
        const remainingSeconds = totalDuration - elapsedSeconds;
        setElapsedMinutes(elapsedSeconds / 60);
        setRemainingSeconds(remainingSeconds);
        if (currentActivity && currentActivityIndex !== null) {
          setCurrentActivityRemainingSeconds(() => {
            if (currentActivityIndex === 0) {
              return currentActivity.duration * 60 - elapsedSeconds;
            } else {
              const previousActivitiesDuration = agenda
                .slice(0, currentActivityIndex)
                .reduce((acc, activity) => acc + activity.duration, 0);
              return (
                currentActivity.duration * 60 -
                (elapsedSeconds - previousActivitiesDuration * 60)
              );
            }
          });
        }
        setTimeToStart(null);
        setElapsedTimeDisplayValue(
          getFormattedMinutesAndSeconds(elapsedMinutes * 60)
        );
      } else {
        const timeToStart = dayjs().diff(dayjs(startTime));
        if (timeToStart >= 0) {
          setTimeToStart(null);
          return;
        }

        const durationToStart = dayjs.duration(Math.abs(timeToStart));
        const minutes = String(durationToStart.minutes()).padStart(2, "0");
        const seconds = String(durationToStart.seconds()).padStart(2, "0");
        const timeToStartDisplay = `${minutes}:${seconds}`;
        setTimeToStart(timeToStartDisplay);
      }
    };

    updateSessionProgress();

    const intervalTimer = setInterval(async () => {
      updateSessionProgress();
    }, 1000);
    return () => {
      clearInterval(intervalTimer);
    };
  }, [
    hasEventStarted,
    remainingSeconds,
    startTime,
    totalDuration,
    currentActivity,
    currentActivityIndex,
    agenda,
    elapsedMinutes,
  ]);

  const currentProgress = useMemo(() => {
    if (currentActivityRemainingSeconds <= 0 || !currentActivity?.duration)
      return 0;

    const currentActivityEllapsedSeconds =
      currentActivity.duration * 60 - currentActivityRemainingSeconds;
    return (
      (currentActivityEllapsedSeconds / (currentActivity.duration * 60)) * 100
    );
  }, [currentActivity?.duration, currentActivityRemainingSeconds]);

  const timerDisplayValue = useMemo(
    () => getFormattedMinutesAndSeconds(currentActivityRemainingSeconds),
    [currentActivityRemainingSeconds]
  );

  return (
    <AgendaProgressContext.Provider
      value={{
        currentActivity,
        currentActivityIndex,
        currentActivityRemainingSeconds,
        currentProgress,
        isAgendaBlock,
        elapsedMinutes,
        elapsedTimeDisplayValue,
        timerDisplayValue,
        timeToStart,
      }}
    >
      {children}
    </AgendaProgressContext.Provider>
  );
};

export const useAgendaProgress = () => {
  const providerValue = useContext(AgendaProgressContext);
  if (Object.keys(providerValue).length === 0) {
    logger.warn(
      "useAgendaProgress must be used within a AgendaProgressContextProvider"
    );
  }
  return providerValue;
};
