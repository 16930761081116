import { Box, Spinner } from "design-system/components";
import React from "react";

export const CalendarLoadingState = () => {
  return (
    <Box className="flex-col justify-center align-center pad-y-16">
      <span data-cy="calendar-loading-state" className="mar-y-16">
        Fetching events for you...
      </span>
      <Spinner color="black" />
    </Box>
  );
};
