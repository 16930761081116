"use client";
import { createContext, useContext, ReactNode, useState } from "react";
import { analytics, catchAnalyticsError } from "features/analytics";
import { ProfileSessionsView, ProfileView } from "./content/profile-side-panel";
import { IntentionsView } from "./content/intentions";
import { FlownEvent } from "shared/event.types";
import { EventSummaryOnlyCalendar } from "./content/event-summary-only-calendar";
import { SidePanelEventTypes } from "shared/analytics.types";
import { UserProfileFromActivityNoId } from "shared/user.types";
import {
  LARGE_SIZE,
  NORMAL_SIZE,
  SidePanelSizes,
} from "design-system/organisms/side-panel";
import {
  FollowersView,
  FollowingView,
  UserFollowersView,
  UserFollowingView,
} from "./content/follow-unfollow-side-panel";
import { InviteMembersSidePanel } from "./content/invite-members-side-panel";
import { UserCreatedSessionsSidePanel } from "./content/user-created-sessions";
import { P2PEvent } from "shared/rooms.types";
import { UserHostedSessionsSidePanel } from "./content/hosted-sessions-side-panel";
import { UserSearchAndFollowSuggestions } from "./content/follow-suggestions-and-user-search";

export type SidePanelConfig = {
  title?: ReactNode;
  isOpen: boolean;
  content: ReactNode;
  size?: SidePanelSizes;
  preventDismissOnOverlayClick?: boolean;
};

const CLOSED_SIDE_PANEL: SidePanelConfig = {
  isOpen: false,
  title: null,
  content: null,
  size: NORMAL_SIZE,
};

type DynamicSidePanelConfig = Omit<SidePanelConfig, "isOpen">;

type SidePanelData = {
  sidePanelState: SidePanelConfig;
  sidePanelHistory: SidePanelConfig[];
  closeSidePanel: () => void;
  navigateBack: () => void;
  openDynamicSidePanel: (props: DynamicSidePanelConfig) => void;
  showMyIntentions: () => void;
  showAllIntentions: () => void;
  showFollowSuggestionsAndUserSearch: (
    initialSuggestions: UserProfileFromActivityNoId[]
  ) => void;
  showOrganisationIntentions: () => void;
  showSendInvites: (event: FlownEvent) => void;
  showMyFollowers: (orgOnly?: boolean) => void;
  showUserFollowers: (externalId: string) => void;
  showUserFollowing: (externalId: string) => void;
  showMyFollowing: (orgOnly?: boolean) => void;
  showMyProfileWithSessions: (externalId?: string) => void;
  showProfileForUserId: (userId: string, size?: SidePanelSizes) => void;
  showEventCardSidePanel: (
    event: FlownEvent,
    onEventCancelled?: (canceledEvent: FlownEvent) => void
  ) => void;
  showHostedSessions: (userId: string, displayName: string) => void;
  showUserCreatedSessions: (userRoomsHistory?: P2PEvent[]) => void;
};

export const SidePanelContext = createContext({} as SidePanelData);

export const SidePanelProvider = (props: { children: ReactNode }) => {
  const [sidePanelState, setSidePanelState] = useState(CLOSED_SIDE_PANEL);
  const [sidePanelHistory, setSidePanelHistory] = useState<SidePanelConfig[]>(
    []
  );
  const pushToHistory = (state: SidePanelConfig) => {
    setSidePanelHistory((prev) => [...prev, state]);
  };
  const popFromHistory = (): SidePanelConfig | undefined => {
    let currentState = sidePanelHistory[sidePanelHistory.length - 1];
    setSidePanelHistory((prev) => {
      const newHistory = [...prev];
      newHistory.pop();
      currentState = newHistory[newHistory.length - 1];
      return newHistory;
    });
    return currentState;
  };
  const trackAnalytics = (type: SidePanelEventTypes) => {
    analytics
      .track("Sidepanel Events", {
        type,
        location: window.location.pathname,
      })
      .catch(catchAnalyticsError);
  };

  const closeSidePanel = () => {
    if (sidePanelState.isOpen) {
      trackAnalytics("Close");
      setSidePanelHistory([]);
      setSidePanelState(CLOSED_SIDE_PANEL);
    }
  };
  const navigateTo = (state: SidePanelConfig) => {
    pushToHistory(state);
    setSidePanelState(state);
  };
  const navigateBack = () => {
    const previousState = popFromHistory();
    if (previousState) {
      setSidePanelState(previousState);
    } else {
      closeSidePanel();
    }
  };
  const openDynamicSidePanel = ({
    title,
    content,
    size = NORMAL_SIZE,
  }: DynamicSidePanelConfig) => {
    trackAnalytics(`Open Dynamic ${title || "Sidepanel"}`);
    navigateTo({
      isOpen: true,
      size,
      title,
      content,
    });
  };

  const showMyFollowers = (orgOnly?: boolean) => {
    trackAnalytics("Open My Followers");
    navigateTo({
      isOpen: true,
      title: "Followers",
      content: <FollowersView orgOnly={orgOnly} />,
      size: NORMAL_SIZE,
    });
  };

  const showMyFollowing = (orgOnly?: boolean) => {
    trackAnalytics("Open My Following");
    navigateTo({
      isOpen: true,
      title: "Following",
      content: <FollowingView orgOnly={orgOnly} />,
      size: NORMAL_SIZE,
    });
  };

  const showUserFollowers = () => {
    trackAnalytics("Open User Followers");
    navigateTo({
      isOpen: true,
      title: "Followers",
      content: <UserFollowersView />,
      size: NORMAL_SIZE,
    });
  };

  const showUserFollowing = () => {
    trackAnalytics("Open User Following");
    navigateTo({
      isOpen: true,
      title: "Following",
      content: <UserFollowingView />,
      size: NORMAL_SIZE,
    });
  };

  const showMyIntentions = () => {
    trackAnalytics("Open My Intentions");
    navigateTo({
      isOpen: true,
      title: "Intentions",
      content: <IntentionsView type="my" />,
      size: NORMAL_SIZE,
    });
  };

  const showAllIntentions = () => {
    trackAnalytics("Open All Intentions");
    navigateTo({
      isOpen: true,
      title: "Intentions",
      content: <IntentionsView type="all" />,
      size: NORMAL_SIZE,
    });
  };

  const showOrganisationIntentions = () => {
    trackAnalytics("Open Organisation Intentions");
    navigateTo({
      isOpen: true,
      title: "Intentions",
      content: <IntentionsView type="org" />,
      size: NORMAL_SIZE,
    });
  };

  const showSendInvites = (event: FlownEvent) => {
    trackAnalytics("Open Send Invites");
    navigateTo({
      isOpen: true,
      title: "Invite people to your session",
      content: <InviteMembersSidePanel event={event} />,
      size: NORMAL_SIZE,
    });
  };

  const showMyProfileWithSessions = (externalId?: string) => {
    trackAnalytics("Open My Profile");
    navigateTo({
      isOpen: true,
      title: "My sessions",
      content: <ProfileSessionsView externalId={externalId} />,
      size: NORMAL_SIZE,
    });
  };

  const showProfileForUserId = (userId: string, size?: SidePanelSizes) => {
    trackAnalytics(`Open Profile for ${userId}`);
    navigateTo({
      isOpen: true,
      title: null,
      content: <ProfileView userId={userId} />,
      size: size || NORMAL_SIZE,
    });
  };

  const showEventCardSidePanel = (
    event: FlownEvent,
    onEventCancelled?: (canceledEvent: FlownEvent) => void
  ) => {
    trackAnalytics(`Open Event Card ${event.id}`);
    navigateTo({
      isOpen: true,
      title: null,
      content: (
        <EventSummaryOnlyCalendar
          event={event}
          onEventCancelled={onEventCancelled}
        />
      ),
      size: LARGE_SIZE,
    });
  };

  const showUserCreatedSessions = (userRoomsHistory?: P2PEvent[]) => {
    trackAnalytics("Open Created Sessions History");
    setSidePanelState({
      isOpen: true,
      title: null,
      content: (
        <UserCreatedSessionsSidePanel roomsFromServer={userRoomsHistory} />
      ),
      size: LARGE_SIZE,
    });
  };

  const showFollowSuggestionsAndUserSearch = (initialSuggestions) => {
    setSidePanelState({
      isOpen: true,
      title: "Find people to follow",
      content: (
        <UserSearchAndFollowSuggestions
          initialSuggestions={initialSuggestions}
        />
      ),
      size: NORMAL_SIZE,
    });
  };

  const showHostedSessions = (userId: string, displayName: string) => {
    setSidePanelState({
      isOpen: true,
      title: `Sessions with ${displayName}`,
      content: <UserHostedSessionsSidePanel externalId={userId} />,
      size: NORMAL_SIZE,
    });
  };

  return (
    <SidePanelContext.Provider
      value={{
        sidePanelState,
        sidePanelHistory,
        showAllIntentions,
        showOrganisationIntentions,
        showMyIntentions,
        openDynamicSidePanel,
        showMyProfileWithSessions,
        showProfileForUserId,
        closeSidePanel,
        showEventCardSidePanel,
        showSendInvites,
        showMyFollowers,
        showMyFollowing,
        showUserFollowers,
        showUserFollowing,
        showUserCreatedSessions,
        showFollowSuggestionsAndUserSearch,
        showHostedSessions,
        navigateBack,
      }}
      {...props}
    />
  );
};

export function useSidePanel() {
  const context = useContext(SidePanelContext);
  if (!context) {
    throw new Error(
      "useSidePanel must be used within a SidePanelContextProvider"
    );
  }
  return context;
}
