import { CalendarWrapper } from "features/calendar/client";
import { Box } from "design-system/components";
import { FlownEvent } from "shared/event.types";

type EventSummaryOnlyCalendarProps = {
  event: FlownEvent;
  /**
   * There are instances where we pass a onEventCancelled method to the CalendarWrapper
   * component but it does not go all the way into the side panel - this allows the binding
   * to work.
   * e.g. canceling a session from the community page via the side panel, should modify the
   * state of the community page to remove the session from the list of sessions.
   */
  onEventCancelled?: (canceledEvent: FlownEvent) => void;
};

export const EventSummaryOnlyCalendar: React.FC<
  EventSummaryOnlyCalendarProps
> = ({ event, onEventCancelled }) => {
  return (
    <Box className="grid gap-8" style={{ gridTemplateRows: "auto 1fr" }}>
      <CalendarWrapper
        id="event-summary-side-panel"
        events={[event]}
        calendarSliceArgs={{ startDate: event.startTime }}
        forceView="only-event-summary"
        analyticsContext="side-panel"
        onEventCancelled={onEventCancelled}
      />
    </Box>
  );
};
