import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from "design-system/animations/radix-animations";
import { EmotionTheme } from "design-system/emotion.theme";

export const commonStyles = (theme: EmotionTheme) => ({
  padding: `${theme.spacing[16]} ${theme.spacing[12]}`,
  backgroundColor: theme.colors.blueGrey100,
  borderRadius: theme.radii[12],
  zIndex: 300,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': {
        animation: `${slideDownAndFade} 0.4s ease`,
      },
      '&[data-side="right"]': {
        animation: `${slideLeftAndFade} 0.4s ease`,
      },
      '&[data-side="bottom"]': {
        animation: `${slideUpAndFade} 0.4s ease`,
      },
      '&[data-side="left"]': {
        animation: `${slideRightAndFade} 0.4s ease`,
      },
    },
  },
});
