import React, { ErrorInfo } from "react";
import { Text } from "./text";
import { Button } from "./button";
import Image from "next/image";
import { styled } from "styled-system/jsx";
import { clientLoggerForFile } from "utils/logger.client";

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

const logger = clientLoggerForFile(__filename);

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: error, errorInfo: errorInfo });
    logger.error(
      `[ERROR BOUNDARY] Uncaught error: ${error}. Error info: ${JSON.stringify(
        errorInfo
      )}`
    );
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <ErrorBoundaryWrapper className="flex-col align-center gap-32 ">
          <Image
            src="/assets/shared/eagle.svg"
            height={128}
            width={270}
            alt="bird flying with open wings"
          />
          <Text className="xl">Oh no! Something went wrong...</Text>

          <Text className="text-center" fontSize="lg">
            Try refreshing the page and contact support if the problem persists.
          </Text>
          <Button
            variant="ghost"
            onClick={() => {
              this.setState({ hasError: false, error: null, errorInfo: null });
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </ErrorBoundaryWrapper>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper = styled("div", {
  base: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "12",
    padding: "32",
  },
});
