import { Box, Text, VerticalPill } from "design-system/components";
import { Agenda } from "../../../shared";

type AgendaDetailProps = {
  agenda: Agenda;
};

export const AgendaDetail: React.FC<AgendaDetailProps> = ({ agenda }) => {
  return (
    <Box className="flex-col gap-8 pad-y-8">
      {agenda.map((item, i) => (
        <Box
          key={`${item.description}-${i}`}
          className="flex align-center gap-4"
        >
          <VerticalPill style={{ backgroundColor: item.category.colorHex }} />
          <Text fontSize="sm">
            {item.duration} min • {item.displayName}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
