import styled from "@emotion/styled";
import { ContentContainer } from "legacy-design-system";
import { IllustrationBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";

const IllustrationView = (
  props: IllustrationBlockFragment & { className: string }
) => (
  <>
    {props.media?.url && props.alignment && (
      <IllustrationContainer
        className={props.alignment + " " + props.className}
      >
        <Illustration src={props.media.url} className={props.alignment} />
      </IllustrationContainer>
    )}
  </>
);
export const IllustrationRenderer = renderer({
  typeName: "IllustrationRecord",
  view: IllustrationView,
});

const IllustrationContainer = styled(ContentContainer)`
  display: flex;
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
`;

const Illustration = styled.img`
  max-width: 100%;
  height: auto;
  &.right {
    margin-right: 20px;
  }
  &.left {
    margin-left: 20px;
  }
`;
