"use client";

import { PillarAndBlogPage } from "features/pillars-and-blog";
import { ModularContentPageProps } from "../server/modular-content-server";
import { ModularContent } from "./modular-content";
import { useUserContext } from "context/user-context";
import { useRouter } from "next/router";
import { LAUNCHPAD_URL, LOGGED_IN_NAVIGATE } from "utils/urls";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { Footer } from "design-system/organisms/footer";

export type { ModularContentPageProps };

export const ModularContentPage = ({ data }: ModularContentPageProps) => {
  const router = useRouter();

  const { loggedIn } = useUserContext();

  useEffect(() => {
    if (
      loggedIn &&
      (router.pathname === "/" || router.asPath.includes("/home")) &&
      !router.asPath.includes(LOGGED_IN_NAVIGATE)
    ) {
      void router.push(LAUNCHPAD_URL);
    }
  }, [loggedIn, router]);

  const shouldHideFooter =
    data.template === "hide-footer" ||
    data.template === "hide-header-and-footer";

  const shouldHideHeader =
    data.template === "hide-header" ||
    data.template === "hide-header-and-footer";

  return (
    <PageContainer
      className={
        shouldHideHeader
          ? "hide-header"
          : data.template
          ? `is-${data.template}`
          : ""
      }
    >
      {data.template === "pillar-page" ? (
        <PillarAndBlogPage
          mainPageContent={data.content}
          title={data.title || ""}
        />
      ) : (
        <ModularContent
          data={data.content}
          paragraphSize={data.paragraphSize}
          contentContainer
        />
      )}
      {!shouldHideFooter && <Footer />}
    </PageContainer>
  );
};

export const PageContainer = styled.div`
  &.is-3-card-width {
    --content-container-width: 1200px;
  }
`;
