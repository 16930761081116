import styled from "@emotion/styled";
import { ModularContent } from "features/modular-content";
import { AutoScrollCarouselBlockFragment } from "generated/graphql-typed";
import { ClassNameProvider } from "utils/component-utils";
import { Carousel } from "./carousel";

type SubscriptionEquivalentPricingCarouselProps = {
  cardSize?: number;
  /**
   * @property Delay in between autoscroll in milliseconds
   */
  delay?: number;
} & AutoScrollCarouselBlockFragment &
  ClassNameProvider;

export const AutoScrollCarousel = ({
  id,
  cardSize,
  carouselType,
  carouselItems,
}: SubscriptionEquivalentPricingCarouselProps) => {
  // TODO ID
  return (
    <CarouselWrapper className={carouselType || ""}>
      <Carousel
        id={id}
        autoplay={{ delay: 5000 }}
        cardSize={cardSize}
        pagination={{ clickable: true }}
      >
        {carouselItems.map((entry, index) => (
          <EntryCard key={index} className={carouselType || ""}>
            {entry.image && <img src={entry.image?.url} alt="" />}
            <div>
              <ModularContent
                data={entry.content}
                paragraphSize={entry.paragraphSize}
              />
            </div>
          </EntryCard>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  /* Overriding ModularContentStyle default margin */
  margin: 1rem 0 !important;

  .carousel .control-dots {
    bottom: 0.75rem;
    display: flex;
    justify-content: center;
    margin: 0;
    text-align: left;
  }

  &.small {
    .carousel .control-dots .dot {
      height: 5px;
      width: 5px;
      margin: 0 3px;
    }
  }
`;

const EntryCard = styled.div`
  border: solid 1px #cbd5e1;
  border-radius: 24px;
  display: flex;
  grid-gap: 1rem;
  height: 100%;
  padding: ${({ theme }) => theme.spacing[8]};
  margin-right: 0.5rem;
  min-height: 130px;
  text-align: left;

  &.small {
    align-items: center;

    img {
      height: fit-content;
      margin-bottom: 0.75rem;
      max-width: 4rem;
    }
  }
`;
