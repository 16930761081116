import { AnalyticsData } from "database/collections/users";
import {
  SubscriptionMembershipType,
  SUBSCRIPTION_TYPES,
} from "shared/subscription.types";

import { UserRole, USER_ROLES } from "services/roles/user-roles";

export const LIFECYCLE_OPTIONS = {
  ANONYMOUS_VISITOR: "Anonymous Visitor",
  TRIAL_MEMBER: "Trial Member",
  FREE_MEMBER: "Free Member",
  EXPIRED_TRIAL_MEMBER: "Expired Trial Member",
  PAYING_MEMBER: "Paying Member",
  USER: "User",
  B2B_ACTIVE: "B2B Active",
  B2B_INACTIVE: "B2B Inactive",
  B2B_SUSPENDED: "B2B Suspended",
  B2B_EXPIRED: "B2B Expired",
  DELETED: "Deleted",
  BLOCKED: "Blocked",
} as const;

export type AnalyticsLifecycleStage =
  typeof LIFECYCLE_OPTIONS[keyof typeof LIFECYCLE_OPTIONS];

export const ANALYTICS_MEMBERSHIP_OPTIONS = {
  NONE: "None",
  FREE: "Free",
  FULL: "Full",
} as const;

export type AnalyticsMembershipType =
  typeof ANALYTICS_MEMBERSHIP_OPTIONS[keyof typeof ANALYTICS_MEMBERSHIP_OPTIONS];

const getPaidMemberData = (
  userRole: UserRole,
  subscriptionType: SubscriptionMembershipType
) => ({
  lifecycleStage: LIFECYCLE_OPTIONS.PAYING_MEMBER,
  membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
  subscriptionType,
  userRole,
});

export const USER_ROLES_LIFECYCLE_MAP: Record<UserRole, AnalyticsData> = {
  [USER_ROLES.ANONYMOUS]: {
    lifecycleStage: LIFECYCLE_OPTIONS.ANONYMOUS_VISITOR,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.ANONYMOUS,
  },
  [USER_ROLES.EXPIRED]: {
    lifecycleStage: LIFECYCLE_OPTIONS.USER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.EXPIRED,
  },
  [USER_ROLES.FREE_TRIAL]: {
    lifecycleStage: LIFECYCLE_OPTIONS.TRIAL_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.FREE_TRIAL,
  },
  [USER_ROLES.MONTHLY_FREE_TRIAL]: {
    lifecycleStage: LIFECYCLE_OPTIONS.TRIAL_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.MONTHLY_FREE_TRIAL,
  },
  [USER_ROLES.YEARLY_FREE_TRIAL]: {
    lifecycleStage: LIFECYCLE_OPTIONS.TRIAL_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.YEARLY_FREE_TRIAL,
  },
  // Leaving quarterly still here in case it comes back
  [USER_ROLES.QUARTERLY_FREE_TRIAL]: {
    lifecycleStage: LIFECYCLE_OPTIONS.TRIAL_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.QUARTERLY_FREE_TRIAL,
  },
  [USER_ROLES.FREE_TRIAL_EXPIRED]: {
    lifecycleStage: LIFECYCLE_OPTIONS.EXPIRED_TRIAL_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.FREE_TRIAL_EXPIRED,
  },
  [USER_ROLES.FREE]: {
    lifecycleStage: LIFECYCLE_OPTIONS.FREE_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FREE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.FREE,
  },
  [USER_ROLES.FREE_HOST]: {
    lifecycleStage: LIFECYCLE_OPTIONS.FREE_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.FREE_HOST,
  },
  [USER_ROLES.FREE_CREDITS]: {
    lifecycleStage: LIFECYCLE_OPTIONS.FREE_MEMBER,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FREE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.FREE_CREDITS,
  },
  [USER_ROLES.MONTHLY]: getPaidMemberData(
    USER_ROLES.MONTHLY,
    SUBSCRIPTION_TYPES.MONTHLY
  ),
  [USER_ROLES.QUARTERLY]: getPaidMemberData(
    USER_ROLES.QUARTERLY,
    SUBSCRIPTION_TYPES.QUARTERLY
  ),
  [USER_ROLES.YEARLY]: getPaidMemberData(
    USER_ROLES.YEARLY,
    SUBSCRIPTION_TYPES.YEARLY
  ),
  [USER_ROLES.LIFETIME]: getPaidMemberData(
    USER_ROLES.LIFETIME,
    SUBSCRIPTION_TYPES.LIFETIME
  ),
  [USER_ROLES.COMPLIMENTARY]: getPaidMemberData(
    USER_ROLES.COMPLIMENTARY,
    SUBSCRIPTION_TYPES.COMPLIMENTARY
  ),
  [USER_ROLES.FACILITATOR]: getPaidMemberData(
    USER_ROLES.FACILITATOR,
    SUBSCRIPTION_TYPES.FACILITATOR
  ),
  [USER_ROLES.ACCESS_TO_WORK]: getPaidMemberData(
    USER_ROLES.ACCESS_TO_WORK,
    SUBSCRIPTION_TYPES.ACCESS_TO_WORK
  ),
  [USER_ROLES.FLOWN]: getPaidMemberData(
    USER_ROLES.FLOWN,
    SUBSCRIPTION_TYPES.FLOWN
  ),
  /**
   * @deprecated
   */
  [USER_ROLES.CORPORATE]: getPaidMemberData(
    USER_ROLES.CORPORATE,
    SUBSCRIPTION_TYPES.CORPORATE
  ),
  [USER_ROLES.CORPORATE_ADMIN]: {
    lifecycleStage: LIFECYCLE_OPTIONS.B2B_ACTIVE,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.CORPORATE_ADMIN,
  },
  [USER_ROLES.CORPORATE_USER]: {
    lifecycleStage: LIFECYCLE_OPTIONS.B2B_ACTIVE,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.CORPORATE_USER,
  },
  [USER_ROLES.CORPORATE_INACTIVE]: {
    lifecycleStage: LIFECYCLE_OPTIONS.B2B_INACTIVE,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.CORPORATE_INACTIVE,
  },
  [USER_ROLES.CORPORATE_SUSPENDED]: {
    lifecycleStage: LIFECYCLE_OPTIONS.B2B_SUSPENDED,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.CORPORATE_SUSPENDED,
  },
  // Leaving corporate expired still here in case we decide to bring it back
  [USER_ROLES.CORPORATE_EXPIRED]: {
    lifecycleStage: LIFECYCLE_OPTIONS.B2B_EXPIRED,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.CORPORATE_EXPIRED,
  },
  [USER_ROLES.DELETED]: {
    lifecycleStage: LIFECYCLE_OPTIONS.DELETED,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.NONE,
    userRole: USER_ROLES.DELETED,
  },
  [USER_ROLES.BLOCKED]: {
    lifecycleStage: LIFECYCLE_OPTIONS.BLOCKED,
    membershipType: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
    subscriptionType: SUBSCRIPTION_TYPES.BLOCKED,
    userRole: USER_ROLES.BLOCKED,
  },
};
