import styled from "@emotion/styled";
import { Box, PillFromCategory } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import Image from "next/image";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "utils/component-utils";
import { Category } from "shared/products.types";
import { Text } from "../../components/text";
import { css } from "styled-system/css";

export type ColumnCardProps = DSComponentPropsProvider & {
  author?: string;
  caption?: string;
  categorySlug?: string;
  fontColor?: string;
  title: string;
  layout: "1-1" | "1-2" | "fill-available/auto";
  linkUrl?: string;
  mediaUrl: string;
  hoverMediaUrl?: string;
  description?: string | null;
  cardWidth: "small" | "medium" | "large";
  cardHeight: "fit-content" | "small" | "medium" | "large";
};

const sizeDict = {
  small: 260,
  medium: 320,
  large: 500,
};

/**
 * @description The Column component is a card that displays a piece of content.
 * This is currently used for the blog and pillar pages.
 */
export const ColumnCard = ({
  author,
  cardHeight,
  cardWidth,
  caption,
  categorySlug,
  fontColor = "inherit",
  title,
  mediaUrl,
  hoverMediaUrl,
  layout,
  linkUrl,
  description,
}: ColumnCardProps) => {
  const navigate = useNavigate();

  return (
    <CardWrapper
      onClick={() => (linkUrl ? navigate(linkUrl) : null)}
      style={{
        color: fontColor,
        cursor: linkUrl ? "pointer" : "inherit",
        gridTemplateRows:
          layout === "1-1"
            ? "1fr 1fr"
            : layout === "1-2"
            ? "1fr 2fr"
            : "1fr auto",
        height:
          cardHeight === "fit-content" ? "100%" : `${sizeDict[cardHeight]}px`,
        width: `${sizeDict[cardWidth]}px`,
      }}
    >
      <ImageContainer>
        <Image
          className={`${
            hoverMediaUrl ? "main-image" : ""
          } fit-cover b-radius-24`}
          src={mediaUrl}
          fill
          alt="Main card image"
        />
        {hoverMediaUrl && (
          <Image
            className={`${
              hoverMediaUrl ? "hover-image" : ""
            } fit-cover b-radius-24`}
            src={hoverMediaUrl}
            fill
            alt="Hover card image"
          />
        )}
        {categorySlug && (
          <PillFromCategory
            category={categorySlug as Category}
            className={css({
              position: "absolute",
              top: "8",
              left: "8",
            })}
          />
        )}
      </ImageContainer>
      <Box className="mar-x-16 mar-top-16 flex-col gap-8">
        <Text fontSize="xl" fontWeight={700}>
          {title}
        </Text>
        {description && (
          <Text as="div" fontSize="md" fontWeight={400}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Text>
        )}
        {(author || caption) && (
          <Box className="mar-y-8">
            {author && (
              <Text fontSize="sm" fontWeight={700}>
                {author}
              </Text>
            )}
            {caption && (
              <Text fontSize="sm" color="grey300" fontWeight={400}>
                {caption}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  border-radius: 1rem;
  display: grid;
  height: 100%;
  text-align: start;

  .main-image {
    transition: opacity 0.1s ease;
  }

  .hover-image {
    transition: opacity 0.1s ease;
    opacity: 0;
  }

  &:hover {
    .main-image {
      opacity: 0;
    }
    .hover-image {
      opacity: 1;
    }
  }
  margin: ${({ theme }) => `0 ${theme.spacing[16]}`};
`;

const ImageContainer = styled.div`
  border-radius: ${({ theme }) => theme.radii[24]};
  height: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  position: relative;
`;
