import styled from "@emotion/styled";
import { ContentContainer } from "legacy-design-system";
import { IllustrationHeaderBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { ModularContent } from "./modular-content";
import { MediaViewer } from "design-system/molecules/media-viewer";
import { Heading } from "design-system/components";

const IllustrationHeaderView = (
  props: IllustrationHeaderBlockFragment & { className: string }
) => (
  <ContentContainer className={props.className}>
    <IllustrationHeaderContainer className="mar-0-auto">
      {props.heading && (
        <Heading className="mar-0-auto text-center" as="h3">
          {props.heading}
        </Heading>
      )}
      {props.illustration && (
        <IllustrationHeaderMediaViewer
          url={props.illustration.url}
          alt={props.illustration.alt || "Illustration header"}
          showVideoControls={props.showVideoControls}
          thumbnailUrl={props.thumbnail?.url}
          forceAutoPlay={props.videoAutoPlay || false}
        />
      )}
      <ModularContent
        data={props.paragraph}
        paragraphSize={props.paragraphSize}
        wrapper={IllustrationHeaderParagraph}
      />
    </IllustrationHeaderContainer>
  </ContentContainer>
);

export const IllustrationHeaderRenderer = renderer({
  typeName: "IllustrationHeaderRecord",
  view: IllustrationHeaderView,
});

const IllustrationHeaderContainer = styled.div`
  display: grid;
  justify-items: center;
  max-width: 800px;
`;

const IllustrationHeaderMediaViewer = styled(MediaViewer)`
  margin: ${({ theme }) => theme.spacing[16]} auto;
  width: 100%;
  height: auto;
`;

const IllustrationHeaderParagraph = styled.div`
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  margin: ${({ theme }) => theme.spacing[16]} auto 0;
  text-align: center;
`;
