import { AutoScrollCarouselBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { AutoScrollCarousel } from "design-system/molecules/carousel";

const AutoScrollCarouselView = (
  props: AutoScrollCarouselBlockFragment & { className: string }
) => <AutoScrollCarousel {...props} />;

export const AutoScrollCarouselRenderer = renderer({
  typeName: "AutoScrollCarouselRecord",
  view: AutoScrollCarouselView,
});
