import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import dynamic from "next/dynamic";
import { ComponentType, memo, SVGProps } from "react";

export type IconName =
  | "academy"
  | "add-to-calendar"
  | "added-to-calendar"
  | "advanced-user"
  | "announcement"
  | "anytime"
  | "apple"
  | "arrow-ccw"
  | "arrow-left"
  | "arrow-right"
  | "away"
  | "badge-padlock"
  | "badge-plus"
  | "bell"
  | "breakout"
  | "burger-menu"
  | "calendar"
  | "camera"
  | "card"
  | "chat"
  | "check"
  | "check-success"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "clock"
  | "clock-animated"
  | "close"
  | "cloud-rain"
  | "community"
  | "copy"
  | "download"
  | "eagle"
  | "edit"
  | "eye"
  | "eye-off"
  | "external-link"
  | "fast-forward"
  | "filter"
  | "focus"
  | "friends"
  | "gcal"
  | "gear"
  | "gift"
  | "globe"
  | "google"
  | "home"
  | "image"
  | "info"
  | "instagram"
  | "intentions"
  | "intercom"
  | "learn"
  | "link"
  | "linkedin"
  | "list"
  | "live"
  | "live-now"
  | "logout"
  | "loop"
  | "mail"
  | "map-pin"
  | "menu-collapse"
  | "menu-expand"
  | "mic-off"
  | "minus"
  | "more-vertical"
  | "movie"
  | "music"
  | "organisation"
  | "outlook"
  | "padlock"
  | "padlock-unlocked"
  | "pause"
  | "pause-circle"
  | "people"
  | "play"
  | "play-circle"
  | "profile"
  | "question-mark"
  | "read"
  | "recharge"
  | "remove-from-calendar"
  | "rewind"
  | "search"
  | "share"
  | "share-audio"
  | "sliders"
  | "social"
  | "sound"
  | "sparkle"
  | "sparkle-filled"
  | "sparkle-hollow"
  | "spotlight"
  | "spotify"
  | "square"
  | "stop"
  | "sun"
  | "tag"
  | "tomato"
  | "trash"
  | "twitter"
  | "users"
  | "video-camera"
  | "volume+"
  | "volumex"
  | "x-circle"
  | "x-octagon"
  | "youtube";

type IconProps = DSComponentPropsProvider & {
  icon: IconName;
  color?: string;
  fill?: string;
  size?: number;
  className?: string;
  label?: string;
  style?: React.CSSProperties;
};

const IconImpl: React.FC<IconProps> = ({
  icon,
  size,
  color,
  fill,
  label,
  className = "",
  style,
  onClick,
}) => {
  const DynamicIcon: ComponentType<SVGProps<SVGSVGElement>> = dynamic(
    () => import(`/public/assets/icons/${icon}.svg`)
  );
  const dimensions = size
    ? {
        width: `${size}`,
        height: `${size}`,
      }
    : {};
  return (
    <AccessibleIcon.Root label={label || icon}>
      <DynamicIcon
        {...dimensions}
        className={`${className}`}
        style={{
          color: color || "inherit",
          minWidth: size,
          cursor: onClick ? "pointer" : "inherit",
          ...(fill ? { fill } : {}),
          ...style,
        }}
        onClick={onClick}
      />
    </AccessibleIcon.Root>
  );
};

export const Icon = memo(IconImpl);
