type EnvironmentName = "dev" | "fledgling" | "production" | "test";

let environment: EnvironmentName | null = null;

export const getEnvironment = (): EnvironmentName => {
  if (!environment) {
    const envVar = process.env.NEXT_PUBLIC_ENVIRONMENT;
    if (
      envVar === "dev" ||
      envVar === "fledgling" ||
      envVar === "production" ||
      envVar === "test"
    ) {
      environment = envVar;
    } else {
      throw new Error(`Invalid or missing NEXT_PUBLIC_ENVIRONMENT: ${envVar}`);
    }
  }
  return environment;
};

export const isDevelopmentMode = () => getEnvironment() === "dev";

export const isPreviewMode = () => getEnvironment() === "fledgling";

export const isProductionMode = () => getEnvironment() === "production";

export const isUnitTestMode = () => getEnvironment() === "test";

export const isCypress = () => !!global.window.Cypress;
