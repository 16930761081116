import { Flownogram, FlownogramProps } from ".";
import { AgendaProgressContextProvider } from "../../contexts/agenda-progress-context-provider";

export const WrappedFlownogram = (props: FlownogramProps) => (
  <AgendaProgressContextProvider
    agenda={props.agenda}
    startTime={props.startTime}
  >
    <Flownogram {...props} />
  </AgendaProgressContextProvider>
);
