import { useEffect } from "react";
import { clientLoggerForFile } from "utils/logger.client";

const logger = clientLoggerForFile(__filename);

type UseHeartbeatRequestProps = {
  fn: () => Promise<unknown>;
  interval?: number;
  enabled?: boolean;
};
export const useHeartbeatRequest = ({
  fn,
  interval = 5000,
  enabled = true,
}: UseHeartbeatRequestProps) => {
  useEffect(() => {
    let intervalTimer: NodeJS.Timeout;
    if (!enabled) return;
    fn()
      .then(() => {
        intervalTimer = setInterval(async () => {
          await fn();
        }, interval);
      })
      .catch((err) => {
        logger.error(err);
      });
    return () => {
      clearInterval(intervalTimer);
    };
  }, [fn, interval, enabled]);
};
