import styled from "@emotion/styled";
import * as Tabs from "@radix-ui/react-tabs";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import isPropValid from "@emotion/is-prop-valid";
import { useEffect, useState } from "react";

export type Tab = {
  label: React.ReactNode;
  value: string;
  content?: React.ReactNode;
};

type TabSwitcherProps = {
  tabs: Tab[];
  tabListLabel: string;
  initialActiveIndex?: number;
  forcedActiveTab?: string;
  tabListStyle?: React.CSSProperties;
  onTabChanged?: (tab: string) => void;
} & DSComponentPropsProvider;

export const TabSwitcher = ({
  tabs,
  tabListLabel,
  initialActiveIndex = 0,
  tabListStyle,
  onTabChanged,
  forcedActiveTab,
  ...props
}: TabSwitcherProps) => {
  const [activeTab, setActiveTab] = useState(tabs[initialActiveIndex].value);
  useEffect(() => {
    if (forcedActiveTab && tabs.some((tab) => tab.value === forcedActiveTab)) {
      setActiveTab(forcedActiveTab);
    }
  }, [forcedActiveTab, tabs]);
  return (
    <Tabs.Root
      className="flex-col h-full"
      onValueChange={(value) => {
        setActiveTab(value);
        onTabChanged?.(value);
      }}
      value={activeTab}
      {...props}
    >
      <TabList
        tabsLength={tabs.length}
        aria-label={tabListLabel}
        style={tabListStyle}
      >
        {tabs.map((tab, index) => (
          <Trigger
            key={`trigger-${tab.value}-${index}`}
            data-cy={`trigger-${tab.value}-${index}`}
            className="trigger pad-x-8"
            value={tab.value}
          >
            {tab.label}
          </Trigger>
        ))}
      </TabList>
      {tabs.map((tab, index) => (
        <Tabs.Content
          key={`content-${tab.value}-${index}`}
          className="h-full pad-top-8"
          value={tab.value}
        >
          {tab.content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
};

const TabList = styled(Tabs.List, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ tabsLength: number }>`
  display: grid;
  grid-template-columns: repeat(${({ tabsLength }) => tabsLength}, 1fr);
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  position: relative;
`;

const Trigger = styled(Tabs.Trigger)`
  height: 30px;
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  position: relative;
  transition: all 0.2s ease-in-out;

  &[data-state="active"] {
    // font-weight: 700;
    border-bottom: solid 2px black;
  }
  &:focus {
    box-shadow: none;
  }
`;
