import { useAppMessage } from "@daily-co/daily-react";
import { useCallback } from "react";
import { OnAppMessageEvent } from "./app-message.types";
import { useHasJoinedCall } from "../../hooks/useHasJoinedCall";

type SendAppMessageArgs = {
  appMessage: OnAppMessageEvent["data"];
  participantId?: string;
};

type SendAppMessageToAllArgs = {
  appMessage: OnAppMessageEvent["data"];
  hostId?: string;
};

/**
 * A type-safe version of the sendAppMessage function from
 * @daily-co/daily-react. It should be used within a DailyProvider
 * This includes all components inside the video client (FLOWNSpace).

 * @param appMessage - The type safe app message to send
 * @param participantId - The participant id to send the message to. This can
 * be a localSessionId (local participant or host) or "*""
 * Note that the "*" participant means "all participants except the host"
 */
export const useSafeSendAppMessage = () => {
  const sendAppMessage = useAppMessage();
  const hasJoinedCall = useHasJoinedCall();

  /**
   * Send an app message to a specific participant (daily id), including the host
   * or all participants, excluding the host.
   */
  const sendAppMessageToParticipant = useCallback(
    ({ appMessage, participantId }: SendAppMessageArgs) => {
      if (!hasJoinedCall) return;
      sendAppMessage(appMessage, participantId);
    },
    [sendAppMessage, hasJoinedCall]
  );

  /**
   * Send an app message to the host and all participants
   * this is for actions that are triggered by the host, such as toggling the spotlight
   */
  const sendAppMessageToParticipantsAndHost = useCallback(
    ({ appMessage, hostId }: SendAppMessageToAllArgs) => {
      if (!hasJoinedCall) return;
      sendAppMessage(appMessage, hostId);
      sendAppMessage(appMessage, "*");
    },
    [sendAppMessage, hasJoinedCall]
  );

  return { sendAppMessageToParticipant, sendAppMessageToParticipantsAndHost };
};
