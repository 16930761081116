import { Box } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { FlownEvent } from "shared/event.types";
import { SessionWrapper } from "./session-wrapper";
import dayjs from "dayjs";
import { useTimeSeparators } from "../hooks/use-time-separators";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { CalendarView } from "../hooks/use-view-calendar";
import { SESSIONS_DAY_BLOCK_PREFIX } from "./agenda-view-calendar";

/**
 * time+duration looks like 4:00pm (1hour)
 */
export type TimeDisplayMode = "time+duration" | "startingIn";

export type SessionsWrapperProps = DSComponentPropsProvider & {
  day?: string;
  events: FlownEvent[];
  attendedEventsIds: string[];
  registeredEventsIds: string[];
  onEventBooked?: (event: FlownEvent) => Promise<void>;
  onEventBookingCancelled?: (event: FlownEvent) => Promise<void>;
  refreshDay: number;
  onEventClick?: (event: FlownEvent) => void;
  onEventCancelled?: (canceledEvent: FlownEvent) => void;
  onIntersectingChange?: (isIntersecting: boolean, day: string) => void;
  previewInSidePanel: boolean;
  selectedEventId?: string;
  showDaySeparators?: boolean;
  showHourSeparators?: boolean;
  timeDisplayMode?: TimeDisplayMode;
  view?: CalendarView;
};

export const SessionsWrapper: React.FC<SessionsWrapperProps> = ({
  day,
  events,
  attendedEventsIds = [],
  registeredEventsIds = [],
  onEventBookingCancelled,
  onEventCancelled,
  onEventBooked,
  refreshDay,
  onEventClick,
  onIntersectingChange,
  previewInSidePanel = false,
  selectedEventId = "",
  showDaySeparators = true,
  showHourSeparators = false,
  timeDisplayMode,
  view = "list",
}) => {
  const sessionsListRef = useRef(null);
  const observer = useMemo(() => {
    if (!day || !onIntersectingChange) return;

    return new IntersectionObserver(([entry]) => {
      onIntersectingChange(entry.isIntersecting, day);
    });
  }, [day, onIntersectingChange]);

  const { getHourSeparator, getDaySeparator, getEmptySeparator } =
    useTimeSeparators();

  useEffect(() => {
    if (!observer) return;

    if (sessionsListRef.current) {
      observer.observe(sessionsListRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return (
    <Box
      className="flex-col gap-16"
      ref={sessionsListRef}
      id={`${SESSIONS_DAY_BLOCK_PREFIX}-${day}`}
    >
      {events.map((event: FlownEvent, index: number) => {
        return (
          <Fragment key={`agenda-${event.id}-${index}-container`}>
            {showDaySeparators && getDaySeparator(event)}
            {showHourSeparators && getHourSeparator(event)}
            {getEmptySeparator(index)}
            <Box className="flex-col gap-16">
              <SessionWrapper
                view={view}
                key={`agenda-${event.id}-${index}`}
                isAttended={attendedEventsIds.includes(event.id)}
                isRegistered={registeredEventsIds.includes(event.id)}
                isHighlighted={selectedEventId === event.id}
                event={event}
                timeDisplayMode={
                  timeDisplayMode ??
                  (dayjs(event.startTime).diff(dayjs(), "minute") < 60
                    ? "startingIn"
                    : "time+duration")
                }
                onEventClick={() => {
                  onEventClick?.(event);
                }}
                onEventBooked={async (event) => await onEventBooked?.(event)}
                onEventBookingCancelled={async (event) =>
                  await onEventBookingCancelled?.(event)
                }
                refreshDay={refreshDay}
                previewInSidePanel={previewInSidePanel}
                onEventCancelled={onEventCancelled}
              />
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};
