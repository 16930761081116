export const getFormattedMinutesAndSeconds = (totalSeconds: number) => {
  if (totalSeconds < 0) {
    return "00:00";
  }
  const minutes = Math.floor(totalSeconds / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(totalSeconds % 60)
    .toString()
    .padStart(2, "0");
  return `${minutes}:${seconds}`;
};

export const convertMinutesToHoursAndMinutes = (duration: number) => {
  const hours = Math.trunc(duration / 60);
  const minutes = Math.round(duration - hours * 60);

  if (hours === 0) {
    return `${minutes} mins`;
  }
  if (hours === 1) {
    if (minutes === 0) {
      return `${hours} hour`;
    }
    return `1 hour, ${minutes} mins`;
  }
  if (minutes === 0) {
    return `${hours} hours`;
  }
  return `${hours} hours, ${minutes} mins`;
};
