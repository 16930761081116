import { renderer } from "./renderer";
import { assertFieldsNotNull } from "utils/assertions";
import { useUserContext } from "context/user-context";
import { Box, Button, ButtonVariant } from "design-system/components";
import { StripeBookButtonFragment } from "generated/graphql-typed";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "utils/component-utils";
import { isProductionMode } from "utils/environment";
import { getCouponCount } from "features/stripe/client";
import Link from "next/link";

export const StripeBookButtonView = (
  props: StripeBookButtonFragment & { className: string }
) => {
  const {
    buttonVariant = "primary",
    stripePaymentLink,
    stripePromoCode,
    ctaSpacesAvailable,
    ctaNoSpacesAvailable,
  } = assertFieldsNotNull(props, [
    "buttonVariant",
    "stripePromoCode",
    "stripePaymentLink",
    "ctaSpacesAvailable",
    "ctaNoSpacesAvailable",
  ]);

  const { email, externalId } = useUserContext();
  const navigate = useNavigate();

  const [couponCount, setCouponCount] = useState<{
    maxRedemptions: number;
    timesRedeemed: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const stripeButtonViewBox = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const stripeButtonViewBoxEl = stripeButtonViewBox.current;
    if (!stripeButtonViewBoxEl) return;

    const loadCouponCount = async () => {
      // If the coupon code is already loaded, don't load it again
      if (couponCount) return;
      setIsLoading(true);
      const response = await getCouponCount(stripePromoCode)();
      if (response.worked && response.maxRedemptions) {
        setCouponCount({
          maxRedemptions: response.maxRedemptions,
          timesRedeemed: response.timesRedeemed,
        });
      } else {
        setCouponCount({ maxRedemptions: 0, timesRedeemed: 0 });
      }
      setIsLoading(false);
    };

    /**
     * This button is used in certain pages e.g. /accountability-groups-fleets-booking-launcher
     * That use the LogicTimezoneView to show and hide the button based on the timezone.
     * However, because this is done way higher up in the DOM, this component will still load
     * and make the HTTP call. See Dato page here: https://aviary.flown.com/editor/item_types/793160/items/149040139/edit
     *
     * e.g. 1 Fleet in 3 timezones with 2 buttons makes 6 HTTP calls to Stripe.
     *
     * This is hacky but mitigates calling 6 times per each fleet and will only call the ones
     * in view. This means that likely only the first fleet will be called as the lower elements
     * will be hidden.
     */
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          void loadCouponCount();
          observer.disconnect(); // Stop observing once we've triggered the load
        }
      });
    });

    observer.observe(stripeButtonViewBoxEl);

    return () => {
      observer.disconnect(); // Cleanup on unmount
    };
  }, [setCouponCount, stripePromoCode, couponCount]);

  const hasSpacesAvailable =
    couponCount &&
    couponCount.maxRedemptions &&
    couponCount.timesRedeemed < couponCount.maxRedemptions;

  const buttonText = useMemo(() => {
    if (isLoading) return "Checking available spaces...";

    if (hasSpacesAvailable) {
      return ctaSpacesAvailable;
    } else {
      return ctaNoSpacesAvailable;
    }
  }, [hasSpacesAvailable, ctaNoSpacesAvailable, ctaSpacesAvailable, isLoading]);

  const navigateToPaymentLink = useCallback(() => {
    navigate(
      createStripePaymentLink({
        stripePaymentLink,
        stripePromoCode,
        email,
        externalId,
      })
    );
  }, [stripePaymentLink, email, stripePromoCode, navigate, externalId]);

  return (
    <Box
      className={`flex-col align-left gap-16 pad-y-16 no-margin ${
        props.className ? props.className : ""
      }`}
      ref={stripeButtonViewBox}
    >
      {/* in preview the coupon always fails - we always show the book button here.
      This is because we have not created the coupons on Stripe in the test env */}
      {!isProductionMode() ? (
        <Button
          onClick={navigateToPaymentLink}
          variant={buttonVariant as ButtonVariant}
        >
          {ctaSpacesAvailable}
        </Button>
      ) : (
        <>
          <Button
            onClick={navigateToPaymentLink}
            disabled={!hasSpacesAvailable}
            variant={buttonVariant as ButtonVariant}
          >
            {buttonText}
          </Button>
          {!hasSpacesAvailable && props.soldOutLink && props.soldOutLinkText && (
            <Box className="mar-x-8">
              <Link
                className="decor-underline pad-y-8"
                href={props.soldOutLink}
              >
                {props.soldOutLinkText}
              </Link>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

type StripePaymentLinkType = {
  stripePaymentLink: string;
  email?: string;
  externalId?: string;
  stripePromoCode?: string;
};

const createStripePaymentLink = ({
  stripePaymentLink,
  email,
  externalId,
  stripePromoCode,
}: StripePaymentLinkType) => {
  const params = new URLSearchParams();

  /**
   * These are fields we can populate on the Payment link in Stripe
   * https://docs.stripe.com/payment-links/url-parameters#streamline-reconciliation-with-a-url-parameter
   */
  if (email && externalId) {
    params.append("prefilled_email", email);
    params.append("client_reference_id", externalId);
  }

  if (stripePromoCode) {
    params.append("prefilled_promo_code", stripePromoCode);
  }

  const queryString = params.toString();
  return queryString
    ? `${stripePaymentLink}?${queryString}`
    : stripePaymentLink;
};

export const StripeBookButtonRenderer = renderer({
  typeName: "StripeBookButtonRecord",
  view: StripeBookButtonView,
});
