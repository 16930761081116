export const capitalizeFirstLetter = (str: string) => {
  // we do checks here - at runtime this can be called with null or undefined
  if (!str) return "";
  if (str.length === 0) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lowercaseTrim = (str: string) => str.toLowerCase().trim();

export const getUserInitials = (userFullName: string) => {
  const [firstName, lastName] = userFullName.split(" ");
  return `${firstName.charAt(0).toUpperCase()} ${
    lastName ? lastName.charAt(0).toUpperCase() : ""
  }`;
};
export const pluralise = (count: number, item: string) => {
  if (item === "person") {
    return count === 1 ? "person" : `people`;
  }
  if (item === "has") {
    return count === 1 ? "has" : "have";
  }
  return `${item}${count === 1 ? "" : "s"}`;
};
export const slugify = (str: string) => str.toLowerCase().replace(/\s/g, "-");

export function escapeRegExp(string: string) {
  // This function escapes special characters that may interfere with regex operations
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
