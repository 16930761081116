/**
 * https://stackoverflow.com/questions/68240884/error-object-inside-catch-is-of-type-unkown
 */
type NormalizedError = {
  originalError: unknown;
  message: string;
  name?: string;
  error?: Error;
  stack?: Error["stack"];
};

export const normalizeError = (error: unknown): Readonly<NormalizedError> => {
  const normalizedError: NormalizedError = {
    originalError: error,
    message: "",
  };
  if (error instanceof Error) {
    normalizedError.message = error.message;
    normalizedError.error = error;
    normalizedError.stack = error.stack;
    normalizedError.name = error.name;
  } else if (typeof error === "string") {
    const errorInstance = new Error(error);
    normalizedError.error = errorInstance;
    normalizedError.message = error;
    normalizedError.stack = errorInstance.stack;
    normalizedError.name = errorInstance.name;
  } else {
    const anyError = error as any;
    if (typeof error === "object") {
      normalizedError.message = anyError?.message
        ? anyError.message
        : String(anyError);
    } else {
      normalizedError.message = String(`[${typeof error}] ${anyError}`);
    }
  }
  return normalizedError;
};

export type AppError = {
  code: string;
  message: string;
  messageFn?: (message: string) => string;
};
