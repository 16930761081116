import dayjs from "dayjs";
import { BoxFlex, Text } from "design-system/components";
import { FlownEvent } from "shared/event.types";
import { css } from "styled-system/css";
import { getFormattedDay } from "utils/date";
import {
  getEventHasStarted,
  getEventInProgress,
  getIsDropInEvent,
} from "utils/event-utils";

export const useTimeSeparators = () => {
  const hourSections: number[] = [];
  let currentDay = "";

  const getHourSeparator = (event: FlownEvent) => {
    // We do not want to show this if the events are in progress
    // so we check here and return null if the event has started
    if (getEventHasStarted(event.startTime)) return null;

    const eventHour = dayjs(event.startTime).hour();

    // Add new separator if the event is not a drop-in event and the hour is not already in the list
    if (
      !getIsDropInEvent(event) &&
      (hourSections.length === 0 ||
        eventHour > hourSections[hourSections.length - 1])
    ) {
      hourSections.push(eventHour);
      return (
        <HourSeparator
          eventHour={eventHour}
          hourText={dayjs()
            .set("hour", eventHour)
            .set("minute", 0)
            .format("h:mma")}
        />
      );
    }
  };

  const getDaySeparator = (event: FlownEvent) => {
    const dayString = getFormattedDay(event.startTime);

    if (dayString !== currentDay) {
      currentDay = dayString;
      return (
        <>
          <Text
            className="mar-top-8"
            key={`day-separator-${dayString}`}
            fontSize="md"
            fontWeight={600}
          >
            {dayString}
          </Text>
          {/* Hack: if event.startTime is Today and the event is in progress, we show Happening now underneath for 
        the collapsed live sessions */}
          {dayjs(event.startTime).isToday() &&
            getEventInProgress(event.startTime, event.endTime) && (
              <HourSeparator
                eventHour={100} // Random number
                hourText={"🟢 Live now"}
              />
            )}
        </>
      );
    }
    currentDay = dayString;
  };

  const getEmptySeparator = (index: number) => (
    <div
      id={`session-card-${index}`}
      // Negative vertical margin to compensate for the gap between the session cards
      className={css({
        margin: "-0.45rem 0",
        _first: { margin: "0" },
      })}
    />
  );

  return { getHourSeparator, getDaySeparator, getEmptySeparator };
};

const HourSeparator = ({
  eventHour,
  hourText,
}: {
  eventHour: number;
  hourText: string;
}) => (
  <BoxFlex className="w-full" key={`hour-separator-${eventHour}`}>
    <Text style={{ whiteSpace: "nowrap" }} fontSize="sm" fontWeight={400}>
      {hourText}
    </Text>
    <span
      className={css({
        borderTop: "solid 1px {colors.grey}",
        height: 0,
        width: "100%",
      })}
    />
  </BoxFlex>
);
