"use client";
import { Global } from "@emotion/react";
import { EMOTION_THEME } from "design-system/emotion.theme";

const theme = EMOTION_THEME;

const utilityClasses = {
  // Display
  ".page-block": {
    maxWidth: theme.maxWidth.content,
    margin: "0 auto",
  },
  ".inline": {
    display: "inline",
  },
  ".inline-block": {
    display: "inline-block",
  },
  ".flex": { display: "flex" },
  ".flex-wrap": {
    display: "flex",
    flexWrap: "wrap",
  },
  ".flex-col": {
    display: "flex",
    flexDirection: "column",
  },
  ".flex-reverse": {
    display: "flex",
    flexDirection: "row-reverse",
  },
  ".none": { display: "none" },
  ".grid": { display: "grid" },
  ".grid-1-1": { display: "grid", gridTemplateColumns: "1fr 1fr" },
  ".grid-3-2": { display: "grid", gridTemplateColumns: "3fr 2fr" },
  ".m-md\\:flex-row": {
    [theme.media["bp-mobile-md"]]: {
      flexDirection: "row",
    },
  },
  ".d-sm\\:flex-row": {
    [theme.media["bp-desktop-sm"]]: {
      flexDirection: "row",
    },
  },
  ".d-sm\\:flex-col": {
    [theme.media["bp-desktop-sm"]]: {
      flexDirection: "column",
    },
  },
  ".d-xs\\:flex-row": {
    [theme.media["bp-desktop-xs"]]: {
      flexDirection: "row",
    },
  },
  ".d-xs\\:flex-col": {
    [theme.media["bp-desktop-xs"]]: {
      flexDirection: "column",
    },
  },
  ".t\\:flex-row": {
    [theme.media["bp-tablet"]]: {
      flexDirection: "row",
    },
  },
  ".flex-1": {
    flex: 1,
  },
  ".hide-on-desktop-sm": {
    [theme.media["bp-desktop-sm"]]: {
      display: "none",
    },
  },
  ".hide-on-desktop-xs": {
    [theme.media["bp-desktop-xs"]]: {
      display: "none",
    },
  },
  ".hide-on-tablet": {
    [theme.media["bp-tablet"]]: {
      display: "none",
    },
  },
  ".hide-on-mobile": {
    [theme.media["bp-mobile-only"]]: {
      display: "none",
    },
  },
  ".hide-below-mobile-md": {
    display: "none",
    [theme.media["bp-mobile-md"]]: {
      display: "block",
    },
  },
  ".show-above-mobile-md": {
    display: "none",
    [theme.media["bp-mobile-md"]]: {
      display: "inherit",
    },
  },
  ".hide-above-mobile-md": {
    display: "inherit",
    [theme.media["bp-mobile-md"]]: {
      display: "none",
    },
  },
  ".show-mobile-only": {
    display: "none",
    [theme.media["bp-mobile-only"]]: {
      display: "inherit",
    },
  },
  ".show-on-desktop-xs": {
    display: "none",
    [theme.media["bp-desktop-xs"]]: {
      display: "initial",
    },
  },
  ".show-on-tablet": {
    display: "none",
    [theme.media["bp-tablet"]]: {
      display: "initial",
    },
  },
  ".show-on-desktop-sm": {
    display: "none",
    [theme.media["bp-desktop-sm"]]: {
      display: "initial",
    },
  },
  ".fit-cover": {
    objectFit: "cover",
  },
  ".fit-contain": {
    objectFit: "contain",
  },
  ".vis-hidden": {
    visibility: "hidden",
  },
  ".vis-visible": {
    visibility: "visible",
  },
  ".op-0": {
    opacity: 0,
  },
  // Alignments
  ".align-center": { alignItems: "center" },
  ".align-end": { alignItems: "flex-end" },
  ".align-baseline": { alignItems: "baseline" },
  ".align-start": { alignItems: "flex-start" },
  ".justify-center": { justifyContent: "center" },
  ".justify-between": { justifyContent: "space-between" },
  ".justify-around": { justifyContent: "space-around" },
  ".justify-end": { justifyContent: "flex-end" },
  ".justify-start": { justifyContent: "flex-start" },
  ".space-evenly": { justifyContent: "space-evenly" },
  ".text-center": { textAlign: "center" },
  ".text-capitalize": { textTransform: "capitalize" },
  ".text-left": { textAlign: "left" },
  ".text-right": { textAlign: "right" },
  // Position
  ".pos-absolute": { position: "absolute" },
  ".inset-0": { top: 0, right: 0, bottom: 0, left: 0 },
  ".inset-x-0": { right: 0, left: 0 },
  ".inset-y-0": { top: 0, bottom: 0 },
  ".pos-relative": { position: "relative" },
  ".top-0": { top: 0 },
  ".bot-0": { bottom: 0 },
  ".left-0": { left: 0 },
  ".right-0": { right: 0 },
  // Backgrounds
  ".bg-white": { backgroundColor: theme.colors.white },
  ".bg-black": { backgroundColor: theme.colors.black },
  ".bg-green": { backgroundColor: theme.colors.focusMid },
  ".bg-successLight": { backgroundColor: theme.colors.successLight },
  ".bg-salmon": { backgroundColor: theme.colors.rechargeLight },
  ".bg-grey100": { backgroundColor: theme.colors.grey100 },
  ".bg-grey500": { backgroundColor: theme.colors.grey500 },
  ".bg-blueGrey100": { backgroundColor: theme.colors.blueGrey100 },
  ".bg-grey20": { backgroundColor: theme.colors.grey20 },
  ".bg-infoLight": { backgroundColor: theme.colors.infoLight },
  // Font colors
  ".text-white": { color: theme.colors.white },
  // Radii
  ".b-radius-6": { borderRadius: theme.radii[6] },
  ".b-radius-8": { borderRadius: theme.radii[8] },
  ".b-radius-12": { borderRadius: theme.radii[12] },
  ".b-radius-16": { borderRadius: theme.radii[16] },
  ".b-radius-20": { borderRadius: theme.radii[20] },
  ".b-radius-24": { borderRadius: theme.radii[24] },
  ".b-radius-46": { borderRadius: theme.radii[46] },
  ".b-radius-round": { borderRadius: theme.radii.round },
  // Borders
  ".border-none": {
    border: "none",
  },
  ".border-black": {
    border: "solid 1px black",
  },
  ".border-focus": { border: `solid 1px ${theme.colors.focusDark}` },
  ".border-grey": {
    border: `solid 1px ${theme.colors.grey}`,
  },
  ".border-grey100": {
    border: `solid 1px ${theme.colors.grey100}`,
  },
  ".border-bot-grey100": {
    borderBottom: `solid 1px ${theme.colors.grey100}`,
  },
  ".border-top-grey100": {
    borderTop: `solid 1px ${theme.colors.grey100}`,
  },
  ".border-grey200": {
    border: `solid 1px ${theme.colors.grey200}`,
  },
  ".border-grey300": {
    border: `solid 1px ${theme.colors.grey300}`,
  },
  ".border-grey400": {
    border: `solid 1px ${theme.colors.grey400}`,
  },
  ".round-card": {
    border: `solid 1px ${theme.colors.grey100}`,
    borderRadius: theme.radii[16],
    padding: theme.spacing[16],
    "&.selected": {
      border: `solid 1px ${theme.colors.focusMid}`,
    },
  },
  // Gap
  ".gap-4": { gridGap: theme.spacing[4] },
  ".gap-8": { gridGap: theme.spacing[8] },
  ".gap-12": { gridGap: theme.spacing[12] },
  ".gap-16": { gridGap: theme.spacing[16] },
  ".gap-20": { gridGap: theme.spacing[20] },
  ".gap-24": { gridGap: theme.spacing[24] },
  ".gap-32": { gridGap: theme.spacing[32] },
  ".gap-48": { gridGap: theme.spacing[48] },
  ".gap-64": { gridGap: theme.spacing[64] },
  // Padding
  ".pad-4": { padding: theme.spacing[4] },
  ".pad-y-4": {
    paddingTop: theme.spacing[4],
    paddingBottom: theme.spacing[4],
  },
  ".pad-x-4": {
    paddingLeft: theme.spacing[4],
    paddingRight: theme.spacing[4],
  },
  ".pad-right-4": {
    paddingRight: theme.spacing[4],
  },
  ".pad-bot-4": {
    paddingBottom: theme.spacing[4],
  },
  ".pad-8": { padding: theme.spacing[8] },
  ".pad-y-8": {
    paddingTop: theme.spacing[8],
    paddingBottom: theme.spacing[8],
  },
  ".pad-bot-8": {
    paddingBottom: theme.spacing[8],
  },
  ".pad-right-8": {
    paddingRight: theme.spacing[8],
  },
  ".pad-top-8": {
    paddingTop: theme.spacing[8],
  },
  ".pad-x-8": {
    paddingLeft: theme.spacing[8],
    paddingRight: theme.spacing[8],
  },
  ".pad-12": { padding: theme.spacing[12] },
  ".pad-y-12": {
    paddingTop: theme.spacing[12],
    paddingBottom: theme.spacing[12],
  },
  ".pad-x-12": {
    paddingLeft: theme.spacing[12],
    paddingRight: theme.spacing[12],
  },
  ".pad-16": { padding: theme.spacing[16] },
  ".pad-y-16": {
    paddingTop: theme.spacing[16],
    paddingBottom: theme.spacing[16],
  },

  ".pad-x-16": {
    paddingLeft: theme.spacing[16],
    paddingRight: theme.spacing[16],
  },
  ".pad-top-16": {
    paddingTop: theme.spacing[16],
  },
  ".pad-bot-16": {
    paddingBottom: theme.spacing[16],
  },
  ".pad-x-20": {
    paddingLeft: theme.spacing[20],
    paddingRight: theme.spacing[20],
  },
  ".pad-24": { padding: theme.spacing[24] },
  ".pad-y-24": {
    paddingTop: theme.spacing[24],
    paddingBottom: theme.spacing[24],
  },
  ".pad-top-24": {
    paddingTop: theme.spacing[24],
  },
  ".pad-bot-24": {
    paddingBottom: theme.spacing[24],
  },
  ".pad-x-24": {
    paddingLeft: theme.spacing[24],
    paddingRight: theme.spacing[24],
  },
  ".pad-28": { padding: theme.spacing[28] },
  ".pad-x-28": {
    paddingLeft: theme.spacing[24],
    paddingRight: theme.spacing[24],
  },
  ".pad-32": { padding: theme.spacing[32] },
  ".pad-top-32": {
    paddingTop: theme.spacing[32],
  },
  ".pad-bot-32": {
    paddingBottom: theme.spacing[32],
  },
  ".pad-y-32": {
    paddingTop: theme.spacing[32],
    paddingBottom: theme.spacing[32],
  },
  ".pad-x-32": {
    paddingLeft: theme.spacing[32],
    paddingRight: theme.spacing[32],
  },
  ".pad-40": { padding: theme.spacing[40] },
  ".pad-y-40": {
    paddingTop: theme.spacing[40],
    paddingBottom: theme.spacing[40],
  },
  ".pad-48": { padding: theme.spacing[48] },
  ".pad-y-48": {
    paddingTop: theme.spacing[48],
    paddingBottom: theme.spacing[48],
  },
  ".pad-x-48": {
    paddingLeft: theme.spacing[48],
    paddingRight: theme.spacing[48],
  },
  ".pad-64": { padding: theme.spacing[64] },
  ".pad-y-64": {
    paddingTop: theme.spacing[64],
    paddingBottom: theme.spacing[64],
  },
  ".pad-x-64": {
    paddingLeft: theme.spacing[64],
    paddingRight: theme.spacing[64],
  },
  // Margin
  ".mar-auto": { margin: "auto" },
  ".mar-0-auto": { margin: "0 auto" },
  ".mar-x-auto": { marginLeft: "auto", marginRight: "auto" },
  ".mar-left-auto": { marginLeft: "auto" },
  ".mar-right-auto": { marginRight: "auto" },

  ".mar-top-auto": { marginTop: "auto" },
  ".mar-bot-auto": { marginBottom: "auto" },
  ".mar-top-4": { marginTop: theme.spacing[4] },
  ".mar-left-4": { marginLeft: theme.spacing[4] },
  ".mar-right-4": { marginRight: theme.spacing[4] },
  ".mar-y-4": { marginTop: theme.spacing[4], marginBottom: theme.spacing[4] },
  ".mar-8": { margin: theme.spacing[8] },
  ".mar-top-8": { marginTop: theme.spacing[8] },
  ".mar-bot-8": { marginBottom: theme.spacing[8] },
  ".mar-y-8": {
    marginTop: theme.spacing[8],
    marginBottom: theme.spacing[8],
  },
  ".mar-x-8": {
    marginLeft: theme.spacing[8],
    marginRight: theme.spacing[8],
  },

  ".mar-right-8": { marginRight: theme.spacing[8] },
  ".mar-left-8": { marginLeft: theme.spacing[8] },
  ".mar-bot-12": { marginBottom: theme.spacing[12] },
  ".mar-16": { margin: theme.spacing[16] },
  ".mar-bot-16": { marginBottom: theme.spacing[16] },
  ".mar-top-16": { marginTop: theme.spacing[16] },
  ".mar-right-16": { marginRight: theme.spacing[16] },
  ".mar-left-16": { marginLeft: theme.spacing[16] },
  ".mar-y-16": {
    marginTop: theme.spacing[16],
    marginBottom: theme.spacing[16],
  },
  ".mar-x-16": {
    marginLeft: theme.spacing[16],
    marginRight: theme.spacing[16],
  },
  ".mar-left-20": { marginLeft: theme.spacing[20] },
  ".mar-24": { margin: theme.spacing[24] },
  ".mar-bot-24": { marginBottom: theme.spacing[24] },
  ".mar-top-24": { marginTop: theme.spacing[24] },
  ".mar-left-24": { marginLeft: theme.spacing[24] },
  ".mar-y-24": {
    marginTop: theme.spacing[24],
    marginBottom: theme.spacing[24],
  },
  ".mar-x-24": {
    marginLeft: theme.spacing[24],
    marginRight: theme.spacing[24],
  },
  ".mar-32": { margin: theme.spacing[32] },
  ".mar-y-32": {
    marginTop: theme.spacing[32],
    marginBottom: theme.spacing[32],
  },
  ".mar-bot-32": {
    marginBottom: theme.spacing[32],
  },
  ".mar-top-32": {
    marginTop: theme.spacing[32],
  },
  ".mar-right-32": {
    marginRight: theme.spacing[32],
  },
  ".mar-left-32": {
    marginLeft: theme.spacing[32],
  },
  ".mar-x-32": {
    marginLeft: theme.spacing[32],
    marginRight: theme.spacing[32],
  },
  ".mar-top-40": {
    marginTop: theme.spacing[40],
  },
  ".mar-top-64": { marginTop: theme.spacing[64] },
  ".mar-bot-64": { marginBottom: theme.spacing[64] },
  // Sizings
  ".h-full": { height: "100%" },
  ".h-fit": { height: "fit-content" },
  ".min-h-100vh": { minHeight: "100vh" },
  ".w-full": { width: "100%" },
  ".w-fit": { width: "fit-content" },
  ".min-w-100": { minWidth: "100px" },
  // Text
  ".ff-facundo": { fontFamily: theme.fontFamilies.facundo },
  ".ff-biennale": { fontFamily: theme.fontFamilies.biennale },
  ".fs-xs": { fontSize: theme.fontSizes.xs },
  ".fs-sm": { fontSize: theme.fontSizes.sm },
  ".fw-300": { fontWeight: 300 },
  ".fw-600": { fontWeight: 600 },
  ".fw-700": { fontWeight: 700 },
  ".nowrap": { whiteSpace: "nowrap" },
  ".text-overflow-ellipsis": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  ".decor-underline": { textDecoration: "underline" },
  ".decor-line": { textDecoration: "line-through" },
  ".text-italic": { fontStyle: "italic" },
  ".uppercase": { textTransform: "uppercase" },
  ".hov-decor-underline": {
    ":hover": {
      textDecoration: "underline",
    },
  },
  // z-indexes
  ".z-10": { zIndex: 10 },
  ".z-100": { zIndex: 100 },
  ".z-200": { zIndex: 200 },
  // Other
  ".overflow-auto": { overflow: "auto" },
  ".overflow-scroll": { overflow: "scroll" },
  ".overflow-hidden": { overflow: "hidden" },
  ".absolute-centralized": {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  ".flex-centralized": {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  ".cursor-default": { cursor: "default" },
  ".cursor-pointer": { cursor: "pointer" },
  ".cursor-grab": { cursor: "grab" },
  ".cursor-not-allowed": { cursor: "not-allowed" },
  // Animations
  ".op-05": { opacity: 0.5 },
  ".rot-90": { transform: "rotate(90deg)" },
  ".rot-180": { transform: "rotate(180deg)" },
  ".trans-all-03": { transition: "all 0.3s ease" },
  ".hov-opacity": {
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.7,
    },
  },
  ".hov-box-shadow": {
    transition: "all 0.2s ease",
    "&:hover": {
      boxShadow: "0px 0px 8px black",
    },
  },
  ".hov-opacity-6": {
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 0.6,
    },
  },
  ".show-on-hover": {
    display: "none",

    "&:hover": {
      display: "block",
    },
  },
  ".skeleton": {
    animation: "skeleton-loading 1s linear infinite alternate",
    borderRadius: theme.radii[12],
    minHeight: theme.fontSizes.sm,
    opacity: 0.8,
  },
  "@keyframes skeleton-loading": {
    "0%": {
      backgroundColor: theme.colors.grey100,
    },
    "100%": {
      backgroundColor: theme.colors.blueGrey100,
    },
  },
  "@keyframes rotating": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },

  ".rotating": {
    animation: "rotating 2s linear infinite",
  },

  ".navigation-element": {
    "&:target": {
      scrollMarginTop: "100px",
    },
  },
  ".no-focus-outline": {
    "&:focus": {
      backgroundColor: "inherit",
      boxShadow: "none",
    },
  },

  // https://codepen.io/zachleat/pen/oNvprpX
  // ".custom-scrollbar": {
  //   "overflow-scrolling": "touch",
  //   "scrollbar-width": "thin", // Firefox only
  //   "scrollbar-color": "rgba(0,0,0,.2)",
  //   "&::-webkit-scrollbar": {
  //     width: "0.5rem",
  //   },
  // },
} as const;

export const globalUtilityClasses = <Global styles={utilityClasses} />;

type UtilityClass = keyof typeof utilityClasses;

export const cn = (...classes: UtilityClass[]) =>
  classes.map((classWithDot) => classWithDot.substring(1)).join(" ");
