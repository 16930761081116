import styled from "@emotion/styled";
import {
  Box,
  BoxFlex,
  Button,
  Heading,
  Icon,
  Label,
  Row,
  SwitchToggle,
  Text,
  useToast,
} from "design-system/components";
import { analytics, catchAnalyticsError } from "features/analytics";
import { CalendarFilterKey } from "../../../shared/calendar-filters";
import { Select } from "design-system/components";
import { useRouter } from "next/router";
import { ChangeEvent, useEffect, useState } from "react";
import { useCalendarFilters } from "./calendar-filters-context";
import { SidePanel } from "design-system/organisms/side-panel";
import { MANAGE_MY_WEEK_URL } from "utils/urls";
import { cn } from "design-system/styles/utility-classes";
import { useUserTimezone } from "utils/component-utils";
import { useUserContext } from "context/user-context";
import { postToSetUserPreferences } from "features/user";
import { QUERY_PARAMS, getQueryParam } from "utils/url-query-utils";

type CalendarAdvancedSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CalendarAdvancedSettings = ({
  isOpen,
  onClose,
}: CalendarAdvancedSettingsProps) => {
  const router = useRouter();
  const { formattedUserTimezone } = useUserTimezone();
  const { preferences, loggedIn } = useUserContext();

  const currentFilter = getQueryParam(
    router.query[QUERY_PARAMS.CALENDAR_FILTER]
  );

  useEffect(() => {
    setSendEmailInvitesState(preferences?.sendEmailInvites);
  }, [preferences]);

  const [sendEmailInvitesState, setSendEmailInvitesState] = useState(
    preferences?.sendEmailInvites
  );

  const { toast, Toast } = useToast();
  const {
    calendarId,
    onFilterUpdate,
    selectedFilters,
    sessionTypeOptions,
    facilitators,
  } = useCalendarFilters();

  const onFilterValueSet = (
    filterKey: CalendarFilterKey,
    filterValue: string
  ) => {
    analytics
      .track("Calendar Filter Changed", {
        location: router.pathname,
        filterKey,
        selectedValue: filterValue,
      })
      .catch(catchAnalyticsError);
    onFilterUpdate(filterKey, filterValue);
  };

  const onToggleIcsInvites = async (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setSendEmailInvitesState(checked);
    const response = await postToSetUserPreferences({
      sendEmailInvites: checked,
    });

    if (!response.worked) {
      toast(
        "error",
        "Unable to update your preferenced, please try again later"
      );
      return;
    }

    analytics
      .track("Calendar Invites Preference Toggled", {
        value: checked,
      })
      .catch(catchAnalyticsError);

    toast(
      "success",
      `Invite settings updated successfully. ${
        checked
          ? "You will receive email invites."
          : "You will not receive email invites."
      }`
    );
  };

  return (
    <>
      {Toast}
      <SidePanel
        open={isOpen}
        onClose={onClose}
        topPosition={0}
        title="Advanced calendar filters"
        size="normal"
      >
        <CalendarAdvancedSettingsWrapper>
          <Box
            className={cn(
              ".bg-blueGrey100",
              ".pad-12",
              ".b-radius-46",
              ".w-fit"
            )}
          >
            <BoxFlex>
              <Icon icon="map-pin" size={16} />
              <Text fontSize="sm">
                Timezone: <strong>{formattedUserTimezone}</strong>
              </Text>
            </BoxFlex>
          </Box>

          <Heading as="h4">Filters</Heading>
          {sessionTypeOptions.length > 0 && (
            <Box className="flex-col gap-8">
              <Row className="justify-between">
                <Label htmlFor="sesstionType-select">Session type</Label>
                {selectedFilters.sessionType && (
                  <CloseIcon
                    icon="close"
                    onClick={() => onFilterUpdate("sessionType", "")}
                  />
                )}
              </Row>
              <SelectSmall
                id={`sesstionType-select-${calendarId}`}
                value={selectedFilters.sessionType}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  onFilterValueSet("sessionType", e.target.value)
                }
                disabled={sessionTypeOptions.length <= 1}
              >
                <option value={""}>-</option>
                {sessionTypeOptions.map((sessionType) => (
                  <option key={sessionType.name} value={sessionType.name}>
                    {sessionType.name}
                  </option>
                ))}
              </SelectSmall>
            </Box>
          )}
          {currentFilter !== "community" && (
            <Box className="flex-col gap-8">
              <Row className="justify-between">
                <Label htmlFor="facilitator-select">Facilitator</Label>
                {selectedFilters.facilitator && (
                  <CloseIcon
                    icon="close"
                    onClick={() => onFilterUpdate("facilitator", "")}
                  />
                )}
              </Row>
              <SelectSmall
                id={`facilitator-select-${calendarId}`}
                value={selectedFilters.facilitator}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  onFilterValueSet("facilitator", e.target.value)
                }
              >
                <option value={""}>-</option>
                {facilitators.map((facilitator) => (
                  <option key={facilitator.name} value={facilitator.name}>
                    {facilitator.name}
                  </option>
                ))}
              </SelectSmall>
            </Box>
          )}
          {/* <Box className="flex-col gap-8">
          <Row className="justify-between">
            <Label htmlFor="facilitator-select">Tag</Label>
            {selectedFilters.tag && (
              <CloseIcon
                icon="close"
                onClick={() => onFilterUpdate("tag", "")}
              />
            )}
          </Row>
          <SelectSmall
            id={`tag-select-${calendarId}`}
            value={selectedFilters.tag}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              onFilterValueSet("tag", e.target.value)
            }
          >
            <option value={""}>-</option>
            {sessionTagOptions.map((tag) => (
              <option key={tag.slug} value={tag.slug as string}>
                {tag.displayName}
              </option>
            ))}
          </SelectSmall>
        </Box> */}

          <Box className="flex-col gap-8">
            <Heading as="h4">Manage my week</Heading>
            <Text>Design your personalised iCal feed of FLOWN sessions</Text>
            <Button
              className="flex gap-4"
              as="a"
              href={MANAGE_MY_WEEK_URL}
              variant="ghost"
              full
            >
              <Icon icon="calendar" size={16} /> Take me to manage my week
            </Button>
          </Box>
          {loggedIn && (
            <Box className="flex-col gap-8">
              <Heading as="h4">Invite settings</Heading>
              <BoxFlex className="cursor-pointer">
                <SwitchToggle
                  id={`toggle-invite-settings`}
                  isChecked={!!sendEmailInvitesState}
                  onToggle={onToggleIcsInvites}
                />
                <Text fontSize="sm">Add sessions to my calendar</Text>
              </BoxFlex>
            </Box>
          )}
        </CalendarAdvancedSettingsWrapper>
      </SidePanel>
    </>
  );
};

const SelectSmall = styled(Select)(({ theme }) => ({
  padding: `${theme.spacing[8]} ${theme.spacing[12]}}`,
}));

const CalendarAdvancedSettingsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.spacing[16]};
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  width: 0.75rem;
`;
