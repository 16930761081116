"use client";
import { postToFetchUserInitialData } from "features/user";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  UserRole,
  getIsUserRoleProps,
  USER_ROLES,
  getIsPremiumOrganisation,
} from "services/roles/user-roles";
import {
  UserContextValue,
  UserOrganisationWithName,
  UserState,
  markFollowState,
} from "./user.state";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FollowUser, UserPreferences } from "shared/user.types";
import {
  postToCreateFollowUser,
  postToUnfollowUser,
} from "features/profile/client";
import { CIO_ACTIONS } from "utils/constants";
import { analytics, catchAnalyticsError } from "features/analytics";
import { getCachedEventClient } from "features/events/client";
import { CioEvent } from "features/analytics";
import { convertFlownEventToSessionEvent } from "utils/event-utils";
import { PopupButton } from "@typeform/embed-react";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { postToUpdateUserOnlineStatus } from "features/user";
import { useGetEventsRegistered } from "features/calendar/client";
import { clientLoggerForFile } from "utils/logger.client";
import { getUserFocusRoomUrl } from "utils/urls";

const logger = clientLoggerForFile(__filename);

const UserContext = createContext({} as UserContextValue);

type TypeFormHiddenFields = {
  email: string;
  external_id: string;
  date: string;
  facilitator: string;
  event_slug: string;
};

type UserPropsFromServer = {
  freeTrialRemainingDays: number;
  freeTrialExpiry: string;
  loggedIn: boolean;
  userRole: UserRole;
  email: string;
  externalId: string;
  createTime: string;
  isVerified: boolean;
  referralCode: string;
  firstName: string;
  lastName: string;
  displayName: string;
  referredBy?: string;
  avatarUrl?: string;
  oneLiner?: string;
  headline?: string;
  vanityUrl?: string;
  followers?: FollowUser[];
  following?: FollowUser[];
  preferences: UserPreferences;
  organisation?: UserOrganisationWithName;
  hasValidCredits?: boolean;
};

type UserContextProviderType = {
  children: ReactNode;
  userProps?: Partial<UserPropsFromServer>;
};

export const UserContextProvider = ({
  children,
  userProps = {},
}: UserContextProviderType) => {
  const router = useRouter();
  const [userState, setUserState] = useState<UserState>({
    loggedIn: userProps.loggedIn,
    isVerified: !!userProps.isVerified,
    email: userProps.email,
    externalId: userProps.externalId,
    userRole: userProps.userRole || USER_ROLES.ANONYMOUS,
    avatarUrl: userProps.avatarUrl || "",
    firstName: userProps.firstName || "",
    lastName: userProps.lastName || "",
    displayName: userProps.displayName || "",
    oneLiner: userProps.oneLiner || "",
    vanityUrl: userProps.vanityUrl || "",
    headline: userProps.headline || "",
    freeTrialExpiry: userProps.freeTrialExpiry || "",
    createTime: userProps.createTime || "",
    profileTags: [],
    referralCode: "",
    isAuthorizedRole: false,
    isExpiredRole: false,
    isYearlyRole: false,
    isQuarterlyRole: false,
    isMonthlyRole: false,
    isFreeTrialRole: false,
    isFreeRole: false,
    isFreeHostRole: false,
    isFreeCreditsRole: false,
    isFreeTrialExpiredRole: false,
    isRegisteredRole: false,
    isFlownRole: false,
    isCorporateAdminRole: false,
    isCorporateUserRole: false,
    isCorporateRole: false,
    isPremiumOrganisation: false,
    isFacilitatorRole: false,
    isLifetimeRole: false,
    isPaidRole: false,
    analyticsData: undefined,
    freeTrialRemainingDays: userProps.freeTrialRemainingDays || 0,
    followers: userProps.followers || [],
    following: userProps.following || [],
    canHostSessions: false,
    preferences: userProps.preferences,
    organisation: userProps.organisation,
    hasValidCredits: userProps.hasValidCredits,
  });

  const [registeredEventsIds, setRegisteredEventsIds] = useGetEventsRegistered({
    loggedIn: userState.loggedIn,
  });

  const typeformPopUpRef = useRef<any>(); // Need a type from typeform here
  const [typeformId, setTypeformId] = useState<string | undefined>(undefined);
  const [typeformHiddenFields, setTypeformHiddenFields] = useState<
    TypeFormHiddenFields | undefined
  >(undefined);

  useEffect(() => {
    const identifyUser = () => {
      try {
        const _cio = (window as any)._cio;
        // check to see if the cio script is loaded.  If not we keep checking until it is
        if (_cio && !_cio._version) {
          return setTimeout(identifyUser, 100);
        } else {
          _cio.identify({
            id: userProps.externalId,
            email: userProps.email,
          });

          _cio.on("in-app:message-action", async (event: CioEvent) => {
            // The rate session param takes two fields - the session id and an optional typeform id
            // The session id is used to get the session details from the flown api then trigger analytics on which option was chosen.
            // If the typeform id is included after sending the analytics we open up the typeform
            // with the appropriate hidden fields
            if (event.detail.action.includes(CIO_ACTIONS.rateSession)) {
              event.detail.message.dismiss();

              const [sessionId, eventTypeFormId] = event.detail.action
                .replace(CIO_ACTIONS.rateSession, "")
                .trim()
                .split(" ");

              if (!sessionId) {
                return;
              }

              const response = await getCachedEventClient(sessionId);
              if (response.worked) {
                const flownEvent = response.event;
                analytics
                  .track("Session Rated", {
                    sessionId,
                    event: convertFlownEventToSessionEvent(flownEvent),
                    rating: event.detail.actionName,
                  })
                  .catch(catchAnalyticsError);

                if (eventTypeFormId) {
                  setTypeformHiddenFields({
                    email: userProps.email || "",
                    external_id: userProps.externalId || "",
                    date: dayjs(flownEvent?.startTime).format(
                      "dddd D MMM YYYY"
                    ),
                    facilitator: flownEvent.facilitator?.name || "",
                    event_slug: flownEvent.id,
                  });

                  setTypeformId(eventTypeFormId);

                  // send the open command to the end of the event loop
                  setTimeout(() => {
                    typeformPopUpRef.current?.open();
                  }, 0);
                }
              }
            }
          });
        }
      } catch (error) {
        logger.info(`Error initializing cio ${error}`);
      }
    };

    const identifyUserTimeout = identifyUser();
    return () => {
      try {
        // tidy up the timout
        if (identifyUserTimeout) {
          clearTimeout(identifyUserTimeout);
        }
        // tidy up the cio listener
        const _cio = (window as any)._cio;
        _cio.off("in-app:message-action");
      } catch (error) {
        logger.info("Failed to cleanup cio");
      }
    };
  }, [userProps.externalId, userProps.email]);

  const fetchUserData = useCallback(async () => {
    const userDataRes = await postToFetchUserInitialData();

    if (userDataRes.worked && userDataRes.userData) {
      const { userData } = userDataRes;
      if (userProps.userRole !== "anonymous") {
        const userRoleProps = getIsUserRoleProps(userData.userRole);

        setUserState((prevState) => ({
          ...prevState,
          email: userData.email,
          externalId: userData.externalId,
          isVerified: !!userData.isVerified,
          userRole: userData.userRole,
          firstName: userData.firstName,
          lastName: userData.lastName,
          displayName: userData.displayName,
          createTime: userData.createTime,
          avatarUrl: userData.avatarUrl,
          oneLiner: userData.oneLiner,
          headline: userData.headline,
          vanityUrl: userData.vanityUrl,
          referralCode: userData.referralCode,
          freeTrialRemainingDays: userData.freeTrialRemainingDays || 0,
          freeTrialExpiry: userData.freeTrialExpiry || "",
          loggedIn: true,
          profileTags: userData.profileTags,
          analyticsData: userData.analyticsData,
          focusRoomUrl: getUserFocusRoomUrl(userData),
          followers:
            userData.followers.map(markFollowState(userData.following)) || [],
          following:
            userData.following.map((following) => ({
              ...following,
              isFollowed: true,
            })) || [],
          canHostSessions: userData.canHostSessions,
          preferences: userData.preferences,
          organisation: userData.organisation,
          isPremiumOrganisation: getIsPremiumOrganisation(
            userData.organisation
          ),
          ...getIsUserRoleProps(userData.userRole),
          hasValidCredits: userData.hasValidCredits,
          ...userRoleProps,
        }));
      }
    } else {
      setUserState((prevState) => ({
        ...prevState,
        loggedIn: false,
      }));
    }
  }, [userProps.userRole]);

  useEffect(() => {
    void fetchUserData();
  }, [fetchUserData]);

  const onFollow = useCallback(async (followedUser: FollowUser) => {
    const response = await postToCreateFollowUser({
      followingId: followedUser.externalId,
    });
    setUserState((prevState) => ({
      ...prevState,
      followers: prevState.followers.map((follower) => {
        if (follower.externalId === followedUser.externalId) {
          return { ...follower, isFollowed: true };
        }
        return follower;
      }),
      following: prevState.following.map((following) => {
        if (following.externalId === followedUser.externalId) {
          return { ...following, isFollowed: true };
        }
        return following;
      }),
    }));
    return response;
  }, []);

  const onUnfollow = useCallback(async (unfollowedUser: FollowUser) => {
    const response = await postToUnfollowUser({
      followingId: unfollowedUser.externalId,
    });
    setUserState((prevState) => ({
      ...prevState,
      followers: prevState.followers.map((follower) => {
        if (follower.externalId === unfollowedUser.externalId) {
          return { ...follower, isFollowed: false };
        }
        return follower;
      }),
      following: prevState.following.map((following) => {
        if (following.externalId === unfollowedUser.externalId) {
          return { ...following, isFollowed: false };
        }
        return following;
      }),
    }));
    return response;
  }, []);

  useEffect(() => {
    const pingOnlineStatus = async () => {
      if (process.env.NEXT_PUBLIC_DISABLE_PING === "true") {
        return;
      }

      await postToUpdateUserOnlineStatus({
        currentPage: router.asPath ?? "",
        timezone: dayjs.tz.guess(),
      });
    };
    if (!userState.loggedIn) {
      return;
    }

    void pingOnlineStatus();
    const interval = setInterval(pingOnlineStatus, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, [router.asPath, userState.loggedIn]);

  const googleClientId =
    process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID ||
    "529661479254-h0726aqr0ge8h5t49ah3si348ticn3cl.apps.googleusercontent.com";
  const value = useMemo(
    () => ({
      ...userState,
      registeredEventsIds,
      setRegisteredEventsIds,
      onFollow,
      onUnfollow,
    }),
    [
      userState,
      registeredEventsIds,
      setRegisteredEventsIds,
      onFollow,
      onUnfollow,
    ]
  );
  return (
    <UserContext.Provider value={value}>
      <GoogleOAuthProvider clientId={googleClientId}>
        {typeformId && (
          <PopupButton
            id={typeformId}
            size={60}
            transitiveSearchParams
            hidden={typeformHiddenFields}
            ref={typeformPopUpRef}
            style={{ display: "none" }}
          ></PopupButton>
        )}
        {children}
      </GoogleOAuthProvider>
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
