import { styled } from "styled-system/jsx";

export const SubtaskCheckbox = styled("input", {
  base: {
    height: "{spacing.20}",
    width: "{spacing.20}",
    accentColor: "{colors.black}",
    transition: "transform 0.2s ease-in-out",

    "&:checked": {
      color: "{colors.white}",
      animation: `spring 0.3s ease-in-out`,
    },

    "&:hover": {
      transform: "scale(1.1)",
    },
  },
});

export const MAX_INTENTION_DESCRIPTION_LENGTH = 150;
export const MAX_SUBTASKS_PER_INTENTION = 3;
