import { isPreviewMode, isProductionMode } from "utils/environment";
import { FREE_TRIAL_DAYS } from "utils/shared-constants";

export type ConfigTypes = {
  DATABASE_URL: string;
  SENDGRID_API_KEY: string;
  ALLOWED_EMAIL_TO_SUFFIX: string;
  ALLOWED_EMAILS: string; // comma separated list
  EMAIL_FROM_ADDRESS: string;
  SITE_BASE_URL: string;
  APP_SECRET: string;
  ADMIN_API_KEY: string;
  INTERCOM_VERIFICATION_SECRET: string;
  DATOCMS_API_KEY: string;
  SEGMENT_API_KEY: string;
  DATOCMS_API_URL: string;
  DANGEROUS_TODAY_FLOCKS_SCHEDULE_OVERRIDE_DATE_ENABLED: boolean;
  ZAPDOS_SLACK_APP_SIGNING_SECRET: string;
  SEGMENT_CONFIG_API_TOKEN: string;
  STRIPE_API_KEY: string;
  STRIPE_FREE_TRIAL_PERIOD: number;
  STRIPE_WEBHOOK_SECRET: string;
  STRIPE_B2B_COMPANY_UPDATE_WEBHOOK_SECRET: string;
  STRIPE_B2B_COMPANY_SUBSCRIPTION_UPDATE_WEBHOOK_SECRET: string;
  STRIPE_B2B_COMPANY_VAT_UPDATE_WEBHOOK_SECRET: string;
  SUBSCRIPTION_GRACE_PERIOD: number;
  B2B_STRIPE_API_KEY: string;
  STRIPE_B2B_FIXED_PRICE_PRODUCT_ID: string;
  STRIPE_B2B_VARIABLE_PRICE_PRODUCT_ID: string;
  GOOGLE_API_CLIENT_ID: string;
  GOOGLE_API_CLIENT_SECRET: string;
  GOOGLE_API_ACCESS_TOKEN: string;
  GOOGLE_API_REFRESH_TOKEN: string;
  STRIPE_MEMBERSHIP_GBP_MONTHLY_PRICE: string;
  STRIPE_MEMBERSHIP_GBP_YEARLY_PRICE: string;
  STRIPE_MEMBERSHIP_EUR_MONTHLY_PRICE: string;
  STRIPE_MEMBERSHIP_EUR_YEARLY_PRICE: string;
  STRIPE_MEMBERSHIP_USD_MONTHLY_PRICE: string;
  STRIPE_MEMBERSHIP_USD_YEARLY_PRICE: string;
  STRIPE_MEMBERSHIP_PRODUCT_ID: string;
  STRIPE_LIFETIME_MEMBERSHIP_PRODUCT_ID: string;
  STRIPE_MEMBERSHIP_GBP_LIFETIME_PRICE_ID: string;
  STRIPE_MEMBERSHIP_EUR_LIFETIME_PRICE_ID: string;
  STRIPE_MEMBERSHIP_USD_LIFETIME_PRICE_ID: string;
  CACHE_TTL_OVERRIDE: number;
  DAILY_API_KEY: string;
  MAX_DAILY_ROOM_CLOSE_WINDOW: number;
  MIN_DAILY_ROOM_OPEN_WINDOW: number;
  ANALYTICS_USER_ID: string;
  NEXT_PUBLIC_SESSIONS_TO_CREATE_ROOM: number;
  SENDBIRD_API_TOKEN: string;
  NEXT_PUBLIC_SENDBIRD_APP_ID: string;
  ADDON_PRODUCT_IDS: string;
  CACHED_NUMBER_OF_DAYS: number;
  SLACK_SIGNING_SECRET: string;
  NEXT_PUBLIC_SLACK_CLIENT_ID: string;
  SLACK_CLIENT_SECRET: string;
  ONE_SIGNAL_APP_ID: string;
  ONE_SIGNAL_API_KEY: string;
  SESSION_REMINDER_WINDOW_MINS: number;
  ONE_SIGNAL_SESSION_REMINDER_TEMPLATE_ID: string;
  ONE_SIGNAL_SESSION_ACTIVITY_JOINED_TEMPLATE_ID: string;
  MEMBER_LIMIT_FOR_DROP_IN_CHAT_REMOVAL: number;
  HOST_NOTIFICATIONS_FREQUENCY_HOURS: number;
  CUSTOMER_IO_WEBHOOK_KEY: string;
};

export type ConfigKey = keyof ConfigTypes;

const defaults: { [K in keyof ConfigTypes]: ConfigTypes[K] | null } = {
  DATABASE_URL: null,
  SENDGRID_API_KEY: null,
  ALLOWED_EMAIL_TO_SUFFIX: isProductionMode() ? "*" : "@flown.com",
  ALLOWED_EMAILS: "",
  EMAIL_FROM_ADDRESS: "FLOWN <hello@flown.com>",
  SITE_BASE_URL: isProductionMode()
    ? "https://flown.com/"
    : isPreviewMode()
    ? "https://{VERCEL_URL}/"
    : null,
  APP_SECRET: null,
  ADMIN_API_KEY: null,
  INTERCOM_VERIFICATION_SECRET: null,
  DATOCMS_API_KEY: null,
  SEGMENT_API_KEY: "",
  DATOCMS_API_URL: "https://graphql.datocms.com/",
  DANGEROUS_TODAY_FLOCKS_SCHEDULE_OVERRIDE_DATE_ENABLED: false,
  ZAPDOS_SLACK_APP_SIGNING_SECRET: "",
  SEGMENT_CONFIG_API_TOKEN:
    "TrYgHpqPRrdxpZmkLpbxY9-awLR-RN8Qhz9bqiEwW64.BWXjoNYZEWZjy05IwBIcoEuC1_GnjUyqDH9qxeIRQRg",
  STRIPE_API_KEY: null,
  STRIPE_FREE_TRIAL_PERIOD: FREE_TRIAL_DAYS,
  NEXT_PUBLIC_SESSIONS_TO_CREATE_ROOM: isProductionMode() ? 30 : 1,
  STRIPE_WEBHOOK_SECRET: "",
  STRIPE_B2B_COMPANY_UPDATE_WEBHOOK_SECRET: "",
  STRIPE_B2B_COMPANY_SUBSCRIPTION_UPDATE_WEBHOOK_SECRET: "",
  STRIPE_B2B_COMPANY_VAT_UPDATE_WEBHOOK_SECRET: "",
  SUBSCRIPTION_GRACE_PERIOD: 1,
  B2B_STRIPE_API_KEY: null,
  STRIPE_B2B_FIXED_PRICE_PRODUCT_ID: isProductionMode()
    ? "prod_LcvbnOgIk8Hn2r"
    : "prod_LS5PLuZK28AvwQ",
  STRIPE_B2B_VARIABLE_PRICE_PRODUCT_ID: isProductionMode()
    ? "prod_LcvdXSoLkNThKa"
    : "prod_LS5QvsrSOww6hO",
  GOOGLE_API_CLIENT_ID:
    "841010243713-54upvqmatq583iv0tc4m80pnebo7fu85.apps.googleusercontent.com",
  GOOGLE_API_CLIENT_SECRET: null,
  GOOGLE_API_ACCESS_TOKEN:
    "ya29.A0ARrdaM-Kig_eG6C5Hp9BMV5v766C0-64R5dQwISnvSwi-MfeCNUMjn5bAW60f6Q2WeKwO5s7UpgKvK25tfOcMqBg6fO76nAYA7oPxitv78cDr5LvjjM3gwCD_DoRQaTfrscVVY67Y6XhurmNY7hhG0bVCVMe",
  GOOGLE_API_REFRESH_TOKEN:
    "1//036VcmRM4A8L_CgYIARAAGAMSNwF-L9IrV3Z27kx5hj4arE-gNsq52jHByewQIAaUFdviiTKbAfDjUOoNVu8yHm01RP4nUWvFrpI",
  STRIPE_MEMBERSHIP_GBP_MONTHLY_PRICE: isProductionMode()
    ? "price_1KcBpxEW8G1jFiyVMaDqOcWi"
    : "price_1LG0D4EW8G1jFiyVPNrp9acU",
  STRIPE_MEMBERSHIP_GBP_YEARLY_PRICE: isProductionMode()
    ? "price_1K8jZTEW8G1jFiyVTChpXghC"
    : "price_1LG0DqEW8G1jFiyVs7Bdx3sY",
  STRIPE_MEMBERSHIP_EUR_MONTHLY_PRICE: isProductionMode()
    ? "price_1LL7SiEW8G1jFiyVHtOj6x6k"
    : "price_1LG09yEW8G1jFiyVYnu03JpU",
  STRIPE_MEMBERSHIP_EUR_YEARLY_PRICE: isProductionMode()
    ? "price_1LL7QhEW8G1jFiyV5cH7jsgB"
    : "price_1LG0ByEW8G1jFiyVLN9Jqx2k",
  STRIPE_MEMBERSHIP_USD_MONTHLY_PRICE: isProductionMode()
    ? "price_1L8Ma3EW8G1jFiyVvfP0aYqe"
    : "price_1LG0EPEW8G1jFiyV8k56bdBA",
  STRIPE_MEMBERSHIP_USD_YEARLY_PRICE: isProductionMode()
    ? "price_1L8MZTEW8G1jFiyVEgNoRap5"
    : "price_1LZtlJEW8G1jFiyVYFZaoIL4",
  STRIPE_MEMBERSHIP_PRODUCT_ID: isProductionMode()
    ? "prod_KoMIcA7xHvq9ot"
    : "prod_Ln0uR4dbOHkjBI",
  // note update the lifetime links in getLifetimePaymentLink
  // method if updating these - they are hardcoded
  STRIPE_LIFETIME_MEMBERSHIP_PRODUCT_ID: isProductionMode()
    ? "prod_NZGyIFqH20FiZW"
    : "prod_NZDX9XEj6rIpqd",
  STRIPE_MEMBERSHIP_GBP_LIFETIME_PRICE_ID: isProductionMode()
    ? "price_1O0h5zEW8G1jFiyVX9kA7cVN"
    : "price_1OCKy2EW8G1jFiyVPUrlbrJs",
  STRIPE_MEMBERSHIP_EUR_LIFETIME_PRICE_ID: isProductionMode()
    ? "price_1O0h6pEW8G1jFiyVuL9nobFs"
    : "price_1Mo6AlEW8G1jFiyVSpOwPYMv",
  STRIPE_MEMBERSHIP_USD_LIFETIME_PRICE_ID: isProductionMode()
    ? "price_1O0h6NEW8G1jFiyVHweo5o4J"
    : "price_1Mo6AHEW8G1jFiyVdWCqdywE",
  CACHE_TTL_OVERRIDE: 1,
  DAILY_API_KEY: "",
  SENDBIRD_API_TOKEN: "",
  NEXT_PUBLIC_SENDBIRD_APP_ID: "",
  MAX_DAILY_ROOM_CLOSE_WINDOW: 120,
  MIN_DAILY_ROOM_OPEN_WINDOW: 15,
  ANALYTICS_USER_ID: isProductionMode()
    ? // userID for dev+analytics@flown.com
      "645a4d423707070ebbc07902"
    : // userID for analytics+testuser@flown.com
      "645cfe5b9a007f65bf3aa283",
  // We can have multiple addons - we filter for the correct one by using meta data.
  // each price should have an addon metada which we use to find the correct price
  ADDON_PRODUCT_IDS: isProductionMode()
    ? "prod_P33rBHQ8GDfaf4"
    : "prod_Oyx3OByRtBPptr",
  CACHED_NUMBER_OF_DAYS: isProductionMode() ? 30 : 1,
  SLACK_SIGNING_SECRET: null,
  NEXT_PUBLIC_SLACK_CLIENT_ID: null,
  SLACK_CLIENT_SECRET: null,
  ONE_SIGNAL_APP_ID: "",
  ONE_SIGNAL_API_KEY: "",
  SESSION_REMINDER_WINDOW_MINS: isProductionMode() ? 15 : 600, // window in which to send session reminders. In dev we want a wide window to test
  ONE_SIGNAL_SESSION_REMINDER_TEMPLATE_ID: isProductionMode()
    ? "cff18299-f4f2-4e53-b617-90ca44cef7b0"
    : "f2613b7d-5d8b-42ca-a2a1-307ddae20527",
  ONE_SIGNAL_SESSION_ACTIVITY_JOINED_TEMPLATE_ID: isProductionMode()
    ? "161f5ee9-128f-4705-92ee-30a3e0978b5d"
    : "98aac0af-eefc-44ce-8919-4d4cf7471eab",
  MEMBER_LIMIT_FOR_DROP_IN_CHAT_REMOVAL: isProductionMode() ? 50 : 1,
  HOST_NOTIFICATIONS_FREQUENCY_HOURS: isProductionMode() ? 24 : 1,
  CUSTOMER_IO_WEBHOOK_KEY: null,
};

const _cache: any = {};

export const getConfig = <K extends ConfigKey>(key: K): ConfigTypes[K] => {
  if (typeof _cache[key] !== "undefined") {
    return _cache[key];
  }
  let value: any = process.env[key];
  if (typeof value === "undefined") {
    if (defaults[key] === null) {
      throw new Error(`Environment variable ${key} not set`);
    } else {
      value = defaults[key];
    }
  } else if (typeof defaults[key] === "boolean") {
    value = !!value && !/^(false|0|no)$/i.test(value);
  }
  return (_cache[key] = value);
};

/**
 * Always returns the correct base URL, regardless of environment
 * Note that it ends with a "/" character at the end
 * e.g. const url = `${getSiteBaseUrl()}your-route-without-prefixed-slash`
 */
export const getSiteBaseUrl = () => {
  if (process.env.VERCEL_GIT_COMMIT_REF === "preview") {
    return "https://preview.flown.com/";
  }

  return `${getConfig("SITE_BASE_URL").replace(
    "{VERCEL_URL}",
    `fledgling-git-${
      process.env.VERCEL_GIT_COMMIT_REF?.replace(/\//g, "-") || ""
    }-flown.vercel.app`
  )}`;
};

export const getSiteBaseUrlNoTrailingSlash = () =>
  getSiteBaseUrl().slice(0, -1);
