import styled from "@emotion/styled";
import { NavigationConfigOptions } from "legacy-design-system/layout/page-layouts";
import Link from "next/link";
import { HOME_URL } from "utils/urls";
import { NavMenuContainer } from "./nav-styles";

export const EmptyNavMenu = ({
  logoAlignment,
  logoColor,
  isLogoClickable,
  backgroundColor,
}: NavigationConfigOptions) => {
  return (
    <EmptyNavMenuContainer
      className={`align-${logoAlignment || "left"}`}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
      }}
    >
      <Link href={HOME_URL}>
        <LogoLink
          color={logoColor}
          css={{
            cursor: isLogoClickable ? "pointer" : "default",
            pointerEvents: isLogoClickable ? "auto" : "none",
            "&:hover": {
              filter: "contrast(0.5)",
            },
          }}
        />
      </Link>
    </EmptyNavMenuContainer>
  );
};

const EmptyNavMenuContainer = styled(NavMenuContainer)`
  padding: 0 4rem;
  border-bottom: none;

  &.align-center {
    justify-content: center;
  }
`;

const LogoLink = styled.div<{ color: string }>`
  align-items: center;
  background: url(/assets/shared/flown-logo-${(props) => props.color}.svg)
    no-repeat center;
  background-position: left;
  display: grid;
  height: 100%;
  width: 115px;
`;
