import { Box, Input, Spinner } from "design-system/components";
import React, { useState, useCallback } from "react";
import { debounce } from "utils/generic-utils";
import { postToSearchUsers } from "../profile-client";
import { FollowUser, UserProfileFromActivityNoId } from "shared/user.types";
import { UserFollowCard } from "./user-follow-card";
import { useUserContext } from "context/user-context";
import { analytics, catchAnalyticsError } from "features/analytics";

type SearchUsersInputProps = {
  blankState?: React.ReactNode;
  following: FollowUser[];
  organisationId?: string;
};

export const SearchUsersInput: React.FC<SearchUsersInputProps> = ({
  blankState = <></>,
  following,
  organisationId,
}) => {
  const { externalId, onFollow, onUnfollow } = useUserContext();
  const [query, setQuery] = useState("");
  const [searchError, setSearchError] = useState<string>("");
  const [searchResults, setSearchResults] = useState<
    UserProfileFromActivityNoId[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const inputValue = event.target.value;
    setQuery(inputValue);
    debouncedSearch(inputValue);
  };

  // Dependencies are correct, React can't identify them because they're in the inner function's closure
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((inputValue: string) => {
      postToSearchUsers({ query: inputValue, organisationId })
        .then((response) => {
          if (!response.worked) {
            setSearchError(
              `Failed to search for members. Error: ${response.error}`
            );
            setSearchResults([]);
            return;
          }
          const { users } = response;
          const usersWithFollowData = users.map((user) => {
            if (
              following.find((follow) => follow.externalId === user.externalId)
            ) {
              return {
                ...user,
                isFollowed: true,
              };
            }
            if (user.externalId === externalId) {
              return { ...user, isSelf: true };
            }

            return user;
          });

          setSearchResults(usersWithFollowData);
        })
        .catch((err) => {
          setSearchError(`Failed to search for members. Error: ${err}`);
          setSearchResults([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500),
    [externalId, following]
  );

  const _onFollow = async (user: UserProfileFromActivityNoId) => {
    setSearchResults((prev) =>
      prev.map((matchedUser) => {
        if (matchedUser.externalId === user.externalId) {
          return { ...matchedUser, isFollowed: true };
        }
        return matchedUser;
      })
    );
    analytics
      .track("Profile Followed", {
        followId: user.externalId,
        source: "search",
      })
      .catch(catchAnalyticsError);
    return await onFollow(user);
  };

  const _onUnfollow = async (user: UserProfileFromActivityNoId) => {
    setSearchResults((prev) =>
      prev.map((matchedUser) => {
        if (matchedUser.externalId === user.externalId) {
          return { ...matchedUser, isFollowed: false };
        }
        return matchedUser;
      })
    );
    analytics
      .track("Profile Unfollowed", {
        followId: user.externalId,
        source: "search",
      })
      .catch(catchAnalyticsError);
    return await onUnfollow(user);
  };

  return (
    <Box className="search-users-wrapper">
      <Input
        className="w-full"
        type="text"
        value={query}
        placeholder="Search by name"
        onChange={handleInputChange}
      />
      {query.length ? (
        <Box className="mar-y-16">
          {isLoading ? (
            <div className="flex-col align-center gap-8 mar-y-24">
              Searching for members... <Spinner color="black" />
            </div>
          ) : searchResults.length === 0 ? (
            <Box className="pad-y-8">
              {searchError ? searchError : `No members found`}
            </Box>
          ) : (
            searchResults.map((user, index) => (
              <UserFollowCard
                key={`${user.externalId}-${index}`}
                user={user}
                onFollow={_onFollow}
                onUnfollow={_onUnfollow}
              />
            ))
          )}
        </Box>
      ) : (
        blankState
      )}
    </Box>
  );
};
