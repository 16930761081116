import styled from "@emotion/styled";
import { Icon, Pill, Tooltip } from "design-system/components";
import { THEME_COLORS } from "design-system/emotion.theme";
import { ReactNode } from "react";
import { ClassNameProvider } from "utils/component-utils";

export const SessionCardBox = styled.div(({ theme }) => ({
  alignItems: "center",
  border: `solid 1px ${theme.colors.grey100}`,
  borderRadius: theme.radii[12],
  container: "session-card / inline-size",
  display: "flex",
  gridTemplateRows: "100%",
  gap: theme.spacing[4],
  padding: theme.spacing[8],
  position: "relative",
  width: "100%",

  "&.flex": {
    display: "flex",
  },

  "&.column": {
    [`${EventInfoBox}`]: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
  },

  "&.highlighted": {
    border: `solid 1px ${theme.colors.success}`,
  },

  "&.past-event": {
    filter: "grayscale(100%)",
    opacity: 0.5,
  },

  "&.booked": {
    backgroundColor: theme.colors.successLight,
    border: `solid 1px ${theme.colors.success}`,
  },

  "&.clickable": {
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: theme.colors.grey20,
      border: `solid 1px ${theme.colors.black}`,

      [`${StyledTimeSpan}`]: {
        backgroundColor: theme.colors.white,
      },

      ".session-card-community-pill": {
        backgroundColor: theme.colors.white,
      },

      ".session-card__flownogram": {
        display: "block",
      },

      ".session-card__view-session": {
        display: "flex",
      },
      "&.list-card": {
        ".session-card__view-session": {
          display: "none",
        },
      },
      ".session-card__partnership-content, .session-card__live-span": {
        display: "none",
      },

      "@container session-card (max-width: 535px)": {
        ".session-card__flownogram": {
          display: "none",
        },
      },

      "@container session-card (max-width: 500px)": {
        ".session-card__view-session": {
          display: "none",
        },
      },
    },
    // Hover end
  },

  "@container session-card (width < 180px)": {
    ".session-card__avatar": {
      display: "none",
    },
  },

  "@container session-card (width < 400px)": {
    ".session-card__capacity": {
      display: "none",
    },
  },

  "@container session-card (width < 500px)": {
    ".session-card__session-type-pill, .session-card__in-partnership, .session-card__only-for":
      {
        display: "none",
      },
  },
  "@container session-card (width < 360px)": {
    ".session-card__partnership-content": {
      display: "none",
    },
  },

  "@container session-card (width > 500px)": {
    ".session-card__session-cancelled-icon": {
      display: "none",
    },
  },

  "@container session-card (width < 570px)": {
    ".session-card__bottom-grid": {
      gridTemplateColumns: "65% 35%",
    },
  },

  ".session-card__flownogram, .session-card__view-session": {
    display: "none",
  },
}));

export const EventInfoBox = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gridGap: theme.spacing[4],
  overflow: "hidden",
  padding: `${theme.spacing[4]}`,
  width: "100%",
  [theme.media["bp-desktop-xs"]]: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "90px auto 1fr",
  },
}));

const StyledTimeSpan = styled.span(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.radii[6],
  border: `solid 1px ${theme.colors.grey}`,
  display: "flex",
  fontSize: theme.fontSizes.xs,
  gap: theme.spacing[4],
  padding: `${theme.spacing[4]}`,

  "&.in-progress": {
    border: "none",
    animation: "in-progress-border 1s linear infinite alternate",
  },

  "@keyframes in-progress-border": {
    "0%": {
      boxShadow: `${theme.colors.success} 0 0 0 1.5px`,
    },
    "100%": {
      boxShadow: `${theme.colors.success}77 0 0 0 1.5px`,
    },
  },

  "&.joined": {
    backgroundColor: theme.colors.successLight,
  },

  "@container (width < 242px)": {
    // One off exception for the calendar view cards to prevent text breaking
    fontSize: "13px",

    ".time-span-clock-icon": {
      display: "none",
    },
  },
}));

type TimeSpanProps = ClassNameProvider & {
  children: ReactNode;
  isAttended?: boolean;
  isAboutToStartOrHasStarted?: boolean;
};

export const TimeSpan = ({
  className,
  children,
  isAttended = false,
  isAboutToStartOrHasStarted = false,
}: TimeSpanProps) => (
  <StyledTimeSpan
    className={`${isAttended ? "joined" : ""} ${className ? className : ""}`}
  >
    {isAboutToStartOrHasStarted ? (
      "⏰"
    ) : (
      <Icon className="time-span-clock-icon" icon="clock" size={16} />
    )}{" "}
    {children}
  </StyledTimeSpan>
);

export const CancelledSessionDisplayStatus = () => {
  return (
    <>
      <Pill
        backgroundColor="errorLight"
        className=" session-card__session-type-pill"
        fontColor="error"
      >
        Cancelled
      </Pill>
      <Tooltip
        triggerElement={
          <Icon
            icon="x-circle"
            className="session-card__session-cancelled-icon"
            color={THEME_COLORS.error}
            size={20}
          />
        }
      >
        Cancelled session
      </Tooltip>
    </>
  );
};
