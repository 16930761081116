"use client";
import dayjs from "dayjs";
import {
  BoxFlex,
  ExpandablePill,
  Icon,
  Pill,
  Popover,
  Row,
  Tooltip,
} from "design-system/components";
import { Box, Text } from "design-system/components";
import { CATEGORY_THEME } from "design-system/emotion.theme";
import Image from "next/image";
import { ReactNode, useMemo } from "react";
import {
  getEventInProgress,
  getIsCommunityEvent,
  getIsEventJoinable,
  getIsDropInEvent,
  getEventCapacityStatus,
  checkIsHostless,
  isDeepDive,
  isPowerHour,
  getSessionDisplayName,
  getEventHasEnded,
  SESSION_FULL_COPY,
  getIsPublicFixedFormatSession,
  getSessionHost,
  getIsEventAboutToStart,
  getHasEventJustStarted,
  getEventHasStarted,
  getIsFlownHostedEvent,
} from "utils/event-utils";
import { NoSSR, useIsMediaDesktop } from "utils/component-utils";
import { pluralise } from "utils/string-utils";
import {
  CancelledSessionDisplayStatus,
  EventInfoBox,
  SessionCardBox,
  TimeSpan,
} from "./session-card-shared";
import { getFormattedTime } from "utils/date";
import { Avatar } from "design-system/molecules/avatar";
import { getUserDisplayName } from "features/profile/shared";
import { cn } from "design-system/styles/utility-classes";
import { useUsersInDropIn } from "context/users-in-dropin-context";
import {
  WrappedFlownogram as Flownogram,
  SessionAttendance,
} from "features/sessions/client";
import { useUserContext } from "context/user-context";
import { useRouter } from "next/router";
import { analytics, catchAnalyticsError } from "features/analytics";
import { css } from "styled-system/css";
import { CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL } from "utils/urls";
import { SessionCardProps } from "./session-card.types";
import { getIsInternalHost } from "services/roles/user-roles";

type FacilitatorSessionCardProps = SessionCardProps & {
  showFullEventTitle?: boolean;
  partnershipContent?: ReactNode;
  isAttended?: boolean;
  isCancelled?: boolean;
  showEventCapacity?: boolean;
};

export const FacilitatorSessionCard = ({
  className = "",
  ctas,
  event,
  grayOutPastEvents,
  isHighlighted,
  showInProgress,
  onClick,
  timeDisplayMode = "time+duration",
  showFullEventTitle = false,
  partnershipContent,
  isAttended,
  isCancelled = false,
  showEventCapacity = true,
  ...props
}: FacilitatorSessionCardProps) => {
  const isDesktop = useIsMediaDesktop();
  const isDropIn = getIsDropInEvent(event);
  const router = useRouter();
  const { isFreeRole, loggedIn, externalId, canHostSessions } =
    useUserContext();
  const { users: usersInDropIn } = useUsersInDropIn();
  const startTimeFormatted = getFormattedTime(event.startTime);
  const hasEventStarted = getEventHasStarted(event.startTime);
  const isPastEvent = hasEventStarted && !getIsEventJoinable(event);
  const isEventInProgress = getEventInProgress(event.startTime, event.endTime);
  const hasEnded = getEventHasEnded(event.endTime);
  const isCommunityEvent = getIsCommunityEvent(event);

  const isCommunityPage = router.asPath.includes("community");
  const facilitator = event.facilitator;

  // Show the claimed host if there is one
  const host = getSessionHost(event);
  const hostName = host ? getUserDisplayName(host) : "";
  const isHost = event.host === externalId;

  const hasEventJustStartedStarted = getHasEventJustStarted(event);
  const isEventAboutToStart = getIsEventAboutToStart(event);

  const sessionCardBoxClasses = useMemo(
    () =>
      [
        "column",
        onClick ? "clickable" : "",
        isHighlighted ? "highlighted" : "",
        !isHost && grayOutPastEvents && isPastEvent ? "past-event" : "",
        isAttended ? "attended-event" : "",
        hasEventJustStartedStarted ? "just-started" : "",
        isEventAboutToStart ? "starting-soon" : "",
        className,
      ]
        .filter(Boolean)
        .join(" "),
    [
      onClick,
      isHost,
      isHighlighted,
      grayOutPastEvents,
      isPastEvent,
      isAttended,
      hasEventJustStartedStarted,
      isEventAboutToStart,
      className,
    ]
  );

  const getEventStartingIn = () => {
    const now = dayjs();
    const eventStartTime = dayjs(event.startTime);

    if (eventStartTime.diff(now, "minutes") > 119) {
      return `${eventStartTime.diff(now, "hours")} ${pluralise(
        eventStartTime.diff(now, "hours"),
        "hour"
      )}`;
    }
    const diffInMins = eventStartTime.diff(now, "minutes");
    return `${diffInMins} ${pluralise(diffInMins, "minute")}`;
  };

  // If the users free and the session is not free or we're not the host
  const isFreeUserAndNonFreeSession =
    isFreeRole && !(event.isFreeSession || host?.externalId === externalId);

  const _isDeepDive = isDeepDive(event);
  const _isPowerHour = isPowerHour(event);

  const hostText = facilitator
    ? `${_isDeepDive ? "Deep Dive" : _isPowerHour ? "Power Hour" : ""} with ${
        facilitator.name
      }`
    : host &&
      `${checkIsHostless({ host: host.externalId }) ? "" : "with"} ${hostName}`;

  const eventDisplayName = getSessionDisplayName(event);
  const eventCapacityStatus = useMemo(
    () => getEventCapacityStatus(event, isHost),
    [event, isHost]
  );
  const isFixedFormat = getIsPublicFixedFormatSession(event);
  const isFlownHosted = getIsFlownHostedEvent(event);

  return (
    <SessionCardBox
      className={sessionCardBoxClasses}
      onClick={onClick}
      {...props}
    >
      <Box className="pos-relative session-card__avatar">
        {isFreeUserAndNonFreeSession && (
          <Image
            className={cn(".absolute-centralized", ".z-200")}
            src={"/assets/shared/padlock.svg"}
            height={24}
            width={24}
            alt="Padlock icon"
            style={{ filter: "brightness(1.4)" }}
          />
        )}
        {facilitator ? (
          <Image
            className="fit-cover facilitator-image b-radius-6"
            height={77}
            width={55}
            style={isFreeUserAndNonFreeSession ? { filter: "blur(2px)" } : {}}
            src={
              facilitator.eventCardPhotoUrl ||
              "/assets/fallbacks/facilitator-team.png"
            }
            alt={`Photo of ${event.facilitator?.name}`}
          />
        ) : host ? (
          <Avatar
            className="cursor-pointer"
            imageUrl={host.avatarUrl}
            style={isFreeUserAndNonFreeSession ? { filter: "blur(2px)" } : {}}
            dimensions={50}
            userFullName={
              host.displayName || `${host.firstName} ${host.lastName}`
            }
          />
        ) : (
          <Image
            className="fit-cover facilitator-image"
            height={77}
            width={55}
            style={isFreeUserAndNonFreeSession ? { filter: "blur(2px)" } : {}}
            src={"/assets/fallbacks/facilitator-team.png"}
            alt={`Photo of the FLOWN team`}
          />
        )}
      </Box>
      <EventInfoBox className="gap-4">
        <Row
          className="justify-between"
          style={{ width: isCancelled ? "100%" : "calc(100% - 32px)" }}
        >
          <Box className="flex gap-4 align-center w-full">
            {isCommunityEvent ? (
              <Box
                className="flex b-radius-6"
                style={{
                  backgroundColor: CATEGORY_THEME[event.category.slug],
                  padding: "2px",
                }}
              >
                <Icon icon={event.category.slug} color="white" size={16} />
              </Box>
            ) : (
              <Image
                height={18}
                width={18}
                src={event.icon.url}
                alt="Session card booked icon"
              />
            )}
            {showFullEventTitle ? (
              <Text fontWeight={600}>
                {event.isP2P && event.emoji ? event.emoji : ""}{" "}
                {eventDisplayName}
              </Text>
            ) : (
              <Text
                className="text-overflow-ellipsis pad-right-4"
                fontWeight={600}
              >
                {event.isP2P && event.emoji ? event.emoji : ""}{" "}
                {eventDisplayName}
              </Text>
            )}
          </Box>
          {isCommunityEvent &&
          !isCancelled &&
          !isCommunityPage &&
          !getIsInternalHost(event.populatedHost?.userRole) ? (
            <BoxFlex className="session-card__session-type-pill">
              <ExpandablePill
                backgroundColor="grey20"
                fontSize="xs"
                visibleContent={<Icon icon="friends" size={14} />}
                hiddenContent={<>&nbsp;Community</>}
              />
            </BoxFlex>
          ) : isCancelled ? (
            <BoxFlex>
              <CancelledSessionDisplayStatus />
            </BoxFlex>
          ) : (
            <BoxFlex className="session-card__session-type-pill">
              {isFlownHosted && (
                <ExpandablePill
                  backgroundColor="grey600"
                  fontColor="white"
                  fontSize="xs"
                  visibleContent={<Icon icon="sparkle-filled" size={14} />}
                  hiddenContent={<>&nbsp;FLOWN</>}
                />
              )}
            </BoxFlex>
          )}
          {isPastEvent && !isAttended && isHost && hasEnded && (
            <Pill
              backgroundColor="errorLight"
              fontColor="error"
              fontSize="xs"
              className="nowrap flex gap-4"
            >
              No show
            </Pill>
          )}
        </Row>
        {facilitator || host ? (
          isDesktop ? (
            <Text
              className="cursor-pointer w-full text-left"
              fontSize="sm"
              color="grey300"
            >
              {event.isP2P && event.privacy === "private" && (
                <>
                  <Image
                    className={cn()}
                    src={"/assets/shared/padlock.svg"}
                    height={16}
                    width={16}
                    alt="Padlock icon"
                    style={{ verticalAlign: "text-top" }}
                  />{" "}
                  Private session -{" "}
                </>
              )}
              {hostText}
            </Text>
          ) : (
            <Text className="text-left w-full" fontSize="sm" color="grey300">
              {hostText}
            </Text>
          )
        ) : isDropIn ? (
          <Tooltip
            triggerElement={
              <SessionAttendance
                users={usersInDropIn}
                commonTags={[]}
                connections={[]}
                isReducedView
              />
            }
          >
            <Text fontWeight={700}>Currently in this session</Text>
          </Tooltip>
        ) : (
          <Text fontSize="sm">&nbsp;</Text>
        )}
        <Box
          className={`justify-between w-full session-card__bottom-grid ${css({
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            "bp-tablet": {
              gridTemplateColumns: "70% 30%",
            },
          })}`}
        >
          <Box className="w-fit">
            <NoSSR>
              {isDropIn && isEventInProgress ? (
                <TimeSpan className={showInProgress ? "in-progress" : ""}>
                  Join at any time
                </TimeSpan>
              ) : timeDisplayMode === "time+duration" &&
                !getEventHasStarted(event.startTime) ? (
                <TimeSpan isAboutToStartOrHasStarted={isEventAboutToStart}>
                  {!isDropIn && <time>{startTimeFormatted}</time>}
                  {event.durationDescription && (
                    <span>({event.durationDescription})</span>
                  )}
                  {isEventAboutToStart ? " - Starting soon " : null}
                  {!!event.agenda?.length && (
                    <Flownogram
                      agenda={event.agenda}
                      startTime={event.startTime}
                      className="session-card__flownogram"
                      height={8}
                      style={{ width: "200px" }}
                      hideTooltips
                    />
                  )}
                </TimeSpan>
              ) : timeDisplayMode === "startingIn" &&
                dayjs(event.startTime).diff(dayjs(), "minutes") > 0 ? (
                <TimeSpan isAboutToStartOrHasStarted={isEventAboutToStart}>
                  {isEventAboutToStart ? (
                    <time> {startTimeFormatted} - Starting soon </time>
                  ) : (
                    `in ${getEventStartingIn()}`
                  )}
                </TimeSpan>
              ) : (
                <TimeSpan
                  isAttended={isAttended}
                  className={
                    isEventInProgress && showInProgress ? "in-progress" : ""
                  }
                  isAboutToStartOrHasStarted={hasEventJustStartedStarted}
                >
                  {isAttended
                    ? `${isHost ? "Hosted " : "Joined "} ${dayjs().to(
                        event.startTime
                      )}`
                    : hasEnded
                    ? `${dayjs().to(event.endTime)}`
                    : hasEventJustStartedStarted
                    ? `${startTimeFormatted} - Just started`
                    : `${startTimeFormatted} - ${dayjs(event.endTime).toNow(
                        true
                      )} left`}
                  {!!event.agenda?.length && (
                    <Flownogram
                      agenda={event.agenda}
                      startTime={event.startTime}
                      className={`session-card__flownogram`}
                      height={8}
                      style={{ width: "200px" }}
                      hideTooltips
                    />
                  )}
                </TimeSpan>
              )}
            </NoSSR>
          </Box>
          <Box
            className={css({
              display: "grid",
              gridAutoFlow: "column",
              gridGap: "4",
            })}
          >
            {showEventCapacity && eventCapacityStatus && (
              <Box
                className={`flex gap-4 align-center fs-xs session-card__capacity`}
              >
                {eventCapacityStatus}{" "}
                {isFixedFormat &&
                  canHostSessions &&
                  eventCapacityStatus === SESSION_FULL_COPY && (
                    <Popover
                      triggerElement={
                        <span className="flex">
                          <Icon icon="info" />
                        </span>
                      }
                    >
                      <Text
                        as={"button"}
                        className="flex align-center gap-4 decor-underline cursor-pointer"
                        fontSize="sm"
                        fontWeight={600}
                        onClick={() => {
                          analytics
                            .track("Clone Fully Booked Session Click", {
                              eventId: event.id,
                            })
                            .catch(catchAnalyticsError);
                          void router.push(
                            `${CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL}`
                          );
                        }}
                      >
                        Host a session just like this one{" "}
                        <Icon icon="arrow-right" size={16} />
                      </Text>
                    </Popover>
                  )}
              </Box>
            )}
            <BoxFlex style={{ justifySelf: "flex-end" }}>
              {partnershipContent && (
                <div
                  className="session-card__partnership-content"
                  style={{ right: "0.5rem" }}
                >
                  {partnershipContent}
                </div>
              )}
              <Text
                as="span"
                className={`flex align-center gap-4 session-card__view-session`}
                fontSize="xs"
                fontWeight={500}
              >
                {loggedIn ? "View" : "Login/Register"}{" "}
                <Icon icon="arrow-right" size={16} />
              </Text>
            </BoxFlex>
          </Box>
        </Box>
      </EventInfoBox>
      {!isPastEvent && ctas && !isCancelled && (
        <Box
          className="flex-col gap-4 pos-absolute"
          style={{ top: "12px", right: "8px" }}
        >
          {ctas}
        </Box>
      )}
      {isHost && !isEventInProgress && (
        <Tooltip
          triggerWrapperStyle={{
            alignSelf: "flex-start",
            paddingTop: "0.25rem",
          }}
          triggerElement={
            <BoxFlex
              className={`${cn(
                ".bg-black",
                ".b-radius-round",
                ".pos-absolute"
              )} ${css({ right: "8", top: "12" })}`}
            >
              <Icon icon="profile" size={26} color="white" />
            </BoxFlex>
          }
        >
          <Text fontSize="sm" fontWeight={500}>
            You're the host
          </Text>
        </Tooltip>
      )}
    </SessionCardBox>
  );
};
