import styled from "@emotion/styled";

import { RoundedButton, ButtonProps } from "./button";

const UnderlineButtonStyled = styled(RoundedButton)`
  padding: 0;
  font-size: inherit;
  text-decoration: underline;
  outline: none;
  border-radius: 0;
  height: auto;
  @media only screen and (min-width: 1000px) {
    padding: 0;
    font-size: 16px;
    height: auto;
  }
  &:hover {
    color: #6cb9b7;
  }
`;

type UnderlineButtonProps = Pick<
  ButtonProps,
  "href" | "onClick" | "type" | "className" | "linkId" | "target" | "dataCy"
>;

export const UnderlineButton: React.FC<
  { children: React.ReactNode } & UnderlineButtonProps
> = ({
  type,
  onClick,
  className,
  children,
  href,
  linkId,
  dataCy,
  ...otherProps
}) => (
  <UnderlineButtonStyled
    href={href}
    linkId={linkId}
    className={className}
    type={type}
    onClick={onClick}
    dataCy={dataCy}
    {...otherProps}
  >
    {children}
  </UnderlineButtonStyled>
);
