import styled from "@emotion/styled";
import { ImageLinkRowBlockFragment } from "generated/graphql-typed";
import { HasChildren } from "utils/component-utils";
import { renderer } from "./renderer";
import Image from "next/image";

const DEFAULT_IMAGE_HEIGHT = 224;

const ImageLinkRowViewGroupWrapper = (
  props: HasChildren & { className: string }
) => (
  <ImgRow className={props.className}>
    <ImgColumns>{props.children}</ImgColumns>
  </ImgRow>
);
const ImgRow = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 0px;
  @media only screen and (min-width: 1000px) {
    margin-bottom: 50px;
  }
`;

const ImageLinkRowView = (
  props: ImageLinkRowBlockFragment & { className: string }
) => (
  <ImgColumn
    height={props.imageHeight || DEFAULT_IMAGE_HEIGHT}
    className={props.className}
  >
    {props.media &&
      (props.url ? (
        <PictureLink href={props?.url}>
          <InnerImg
            width="224"
            height={props.imageHeight || DEFAULT_IMAGE_HEIGHT}
            src={props.media.url}
            alt={props.media.alt || ""}
          />
        </PictureLink>
      ) : (
        <InnerImg
          width="224"
          height={props.imageHeight || DEFAULT_IMAGE_HEIGHT}
          src={props.media.url}
          alt={props.media.alt || ""}
        />
      ))}
  </ImgColumn>
);

export const ImageLinkRowRenderer = renderer({
  typeName: "ImageLinkRowRecord",
  view: ImageLinkRowView,
  groupComponent: ImageLinkRowViewGroupWrapper,
});

const ImgColumns = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ImgColumn = styled.div<{ height: number }>`
  position: relative;
  width: 167px;
  margin: 0 5px;
  height: ${(props) => props.height}px;
  flex-grow: 0;
  flex-shrink: 0;
  @media only screen and (min-width: 1000px) {
    width: 224px;
    margin: 0 13px;
    margin-bottom: 20px;
  }
`;

const PictureLink = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
`;

const InnerImg = styled(Image)`
  width: 167px;
  height: auto;
  border-radius: 8px;
  @media only screen and (min-width: 1000px) {
    width: 224px;
  }
`;
