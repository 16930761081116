"use client";
import { useSidePanel } from "context/side-panel-context";
import {
  Box,
  BoxFlex,
  Button,
  CollapsableWrapper,
  Icon,
  Pill,
  Row,
  Text,
  VerticalPill,
} from "design-system/components";
import { cn } from "design-system/styles/utility-classes";
import { useRouter } from "next/router";
import { useState } from "react";
import { P2PEvent } from "shared/rooms.types";
import { getFormattedDayTime } from "utils/date";
import {
  CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL,
  CREATE_COMMUNITY_ROOM_URL,
} from "utils/urls";
import { Flownogram } from "./flownogram";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { getIsPublicFixedFormatSession } from "utils/event-utils";
import { QUERY_PARAMS } from "utils/url-query-utils";

type CreatedRoomHistoryCardProps = DSComponentPropsProvider & {
  room: P2PEvent;
};

export const CreatedSessionCard = ({
  room,
  dataCy,
  className = "",
}: CreatedRoomHistoryCardProps) => {
  const router = useRouter();
  const { closeSidePanel } = useSidePanel();
  const [showEventSummary, setShowEventSummary] = useState(false);

  return (
    <Box className={`round-card flex-col gap-16 ${className}`} data-cy={dataCy}>
      <Row className="justify-between">
        <Text fontWeight={600}>{room.displayName}</Text>
        <Box className="flex-col gap-4">
          <Pill backgroundColor="grey50" className="mar-left-auto">
            {room.durationDescription}
          </Pill>
          <Text color="grey400" fontSize="sm">
            {getFormattedDayTime(room.startTime)}
          </Text>
        </Box>
      </Row>
      <Flownogram agenda={room.agenda || []} />
      <Row className="justify-between">
        <BoxFlex>
          <Button
            size="small"
            variant="ghost"
            onClick={() => setShowEventSummary((prev) => !prev)}
            minWidth={100}
          >
            {showEventSummary ? "Close" : "Show details"}
          </Button>
          <Button
            size="small"
            onClick={() => {
              if (getIsPublicFixedFormatSession(room)) {
                void router.push(
                  `${CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL}?${QUERY_PARAMS.FIXED_FORMAT_RECUR_ID}=${room.id}`
                );
                return;
              } else {
                void router.push(
                  `${CREATE_COMMUNITY_ROOM_URL}?${QUERY_PARAMS.CLONE}=${room.id}`
                );
              }
              closeSidePanel();
            }}
          >
            Recreate session
          </Button>
          {getIsPublicFixedFormatSession(room) && (
            <Pill backgroundColor="grey600" fontColor="white" className="gap-4">
              <Icon icon="home" color="white" size={12} /> FLOWN format
            </Pill>
          )}
        </BoxFlex>
        {room.deleted && (
          <Pill backgroundColor="errorLight" fontColor="error">
            Cancelled
          </Pill>
        )}
      </Row>
      <CollapsableWrapper
        className="flex-col gap-16"
        isShowing={showEventSummary}
      >
        <Box className="flex-col gap-4">
          <Text color="grey400">{room.shortDescription}</Text>
        </Box>
        <Box className="flex-col gap-8">
          {room.agenda && (
            <Box className="grid-1-1 gap-16">
              <Box className="flex-col gap-8 pad-y-8">
                {room.agenda.map((item, i) => (
                  <Box
                    key={`${item.description}-${i}`}
                    className="flex align-center gap-4"
                  >
                    <VerticalPill
                      style={{ backgroundColor: item.category.colorHex }}
                    />
                    <Text fontSize="sm">
                      {item.duration} min • {item.displayName}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Box className="mar-top-4">
                <Text
                  fontSize="sm"
                  fontWeight={500}
                  style={{ lineHeight: "1.75rem" }}
                >
                  {room.privacy === "private" && (
                    <>
                      🔒 Invite only session <br />
                    </>
                  )}
                  🗣{" "}
                  {room.config.max_participants === 2
                    ? "1-1 Session"
                    : "Group session"}{" "}
                  <br />
                  🎤 Microphones {room.config.allowMic
                    ? "enabled"
                    : "disabled"}{" "}
                  <br />
                  📷 Camera recommended <br />
                  💻 Runs in your browser
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </CollapsableWrapper>
    </Box>
  );
};

export const CreatedSessionCardSkeleton = () => (
  <Box className={cn(".round-card", ".flex-col", ".gap-16")}>
    <Row className="justify-between">
      <div
        className={cn(".skeleton")}
        style={{ height: "12px", width: "200px" }}
      />
      <Box className="flex-col gap-4 align-end">
        <div
          className={cn(".skeleton")}
          style={{ height: "12px", width: "32px" }}
        />
        <div
          className={cn(".skeleton")}
          style={{ height: "12px", width: "100px" }}
        />
      </Box>
    </Row>
    <div
      className={cn(".skeleton")}
      style={{ height: "10px", width: "100%" }}
    />
    <Row>
      <div
        className={cn(".skeleton")}
        style={{ height: "32px", width: "122px" }}
      />
      <div
        className={cn(".skeleton")}
        style={{ height: "32px", width: "122px" }}
      />
    </Row>
    <div />
  </Box>
);
