import dayjs from "dayjs";
import pino, { Logger } from "pino";
import { takeLastTwoPathParts } from "shared/logging.types";

const makeClientLogger = (name: string): Logger => {
  return pino({
    browser: {
      asObject: true,
    },
    name,
    formatters: {
      level(level) {
        return { level };
      },
    },
    timestamp: () => `${dayjs().toISOString()}"`,
    hooks: {
      logMethod(args: any[], method: any) {
        if (typeof args[0] === "string" && typeof args[1] === "object") {
          // pino requires object before message, we optionally allow message first
          const message = args[0];
          args[0] = args[1];
          args[1] = message;
        }
        method.apply(this, args);
      },
    },
  });
};

let globalClientLogger: Logger | null = null;

const getClientLogger = (filename: string): Logger => {
  if (globalClientLogger === null) {
    globalClientLogger = makeClientLogger(filename);
  }
  return globalClientLogger.child({ filename });
};

export const clientLoggerForFile = (filename: string) =>
  getClientLogger(takeLastTwoPathParts(filename)).child({ filename });
