import { useUserContext } from "context/user-context";
import { AccountabilityWallContainer } from "features/accountability/client";
import { Box } from "design-system/components";
import { memo } from "react";
import { useSidePanel } from "..";

export const IntentionsView = memo(
  ({ type = "all" }: { type: "all" | "my" | "org" }) => {
    const { externalId, organisation } = useUserContext();
    const { showProfileForUserId } = useSidePanel();

    return (
      <Box className="grid gap-8" style={{ gridTemplateRows: "auto 1fr" }}>
        <AccountabilityWallContainer
          className="h-full"
          userExternalId={type === "my" ? externalId : undefined}
          withPrivateIntentions={type === "my"}
          organisationId={
            type === "org" ? organisation?.id.toString() : undefined
          }
          onUserIntentionCardClick={(userExternalId) =>
            showProfileForUserId(userExternalId)
          }
        />
      </Box>
    );
  }
);
