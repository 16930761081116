import { z } from "zod";

export const RECHARGE_CATEGORY_SLUG = "recharge";
export const FOCUS_CATEGORY_SLUG = "focus";
export const LEARN_CATEGORY_SLUG = "learn";
export const SOCIAL_CATEGORY_SLUG = "social";
export const INTENTIONS_CATEGORY_SLUG = "intentions";

export const isFocusCategory = (category: string) =>
  category === FOCUS_CATEGORY_SLUG;

export const CATEGORIES = [
  RECHARGE_CATEGORY_SLUG,
  FOCUS_CATEGORY_SLUG,
  LEARN_CATEGORY_SLUG,
  SOCIAL_CATEGORY_SLUG,
  INTENTIONS_CATEGORY_SLUG,
] as const;

export const ZOD_CATEGORY = z.enum([...CATEGORIES]);

export type Category = typeof CATEGORIES[number];

export type CategoryObject = {
  displayName: string;
  slug: Category;
};

export function isValidCategorySlug(category: string): category is Category {
  return CATEGORIES.includes(category as Category);
}

export function isValidProductSlug(product: string): product is ProductType {
  return Object.values(PRODUCTS).includes(product as ProductType);
}

export const PRODUCTS = {
  ALMANAC: "almanac",
  FLOCKS: "flocks",
  GREEN_SCENES: "green-scenes",
  QUESTS: "quests",
  PORTHOLES: "portholes",
  BODY_BREATH: "body-and-breath",
  ACADEMY: "academy",
  CHALLENGE: "challenge",
  LIVE_RECHARGE: "live-recharge",
  MEMBERSHIP: "membership",
  MUSIC: "music",
  SESSION_REGISTERED: "session-registered",
  // FreeCredit users will still be sent an email but we won't catch the event everywherre
  SESSION_REGISTERED_FREE_CREDITS: "session-registered-free-credits",
  COMMUNITY: "community",
  COMMUNITY_SESSION: "community-session",
  COMMUNITY_SESSION_INVITE_SENT: "community-session-invite-sent",
  COMMUNITY_SESSION_ENTERED: "community-session-entered", // Users will fire this as soon as the join the session; will be used for the "claim" spot logic
  DROP_IN_SESSION_COMPLETED: "drop-in-session-completed",
  COMMUNITY_HOST_CLAIMED: "community-host-claimed",
  FOCUS_ROOM_ENTERED: "focus-room-entered",
  FOCUS_ROOM_TIME_BLOCK_COMPLETED: "focus-room-time-block-completed",
  USER_REPORTED: "user-reported",
} as const;

export type Product = {
  category: {
    slug: Category;
    displayName: string;
    icon: string;
  };
  logoUrl: string;
  mediaUrl: string;
  description: string;
  longDescription?: string;
  displayName: string;
  tags: string[];
  slug?: ProductType;
};

export type ProductType = typeof PRODUCTS[keyof typeof PRODUCTS];

export const ProductEnum = z.nativeEnum(PRODUCTS);

type ProductCategories = Record<Category, ProductType[]>;

export const isProductInCategory = (category: Category, product: ProductType) =>
  PRODUCT_CATEGORY_MAP[category].includes(product);
// TODO I think this is not accurate, "flocks" is not a product on Dato, but the specific flock types are
export const PRODUCT_CATEGORY_MAP: ProductCategories = {
  focus: [PRODUCTS.FLOCKS, PRODUCTS.PORTHOLES],
  recharge: [
    PRODUCTS.QUESTS,
    PRODUCTS.ALMANAC,
    PRODUCTS.BODY_BREATH,
    PRODUCTS.GREEN_SCENES,
    PRODUCTS.LIVE_RECHARGE,
  ],
  learn: [PRODUCTS.ACADEMY],
  social: [],
  intentions: [],
};
