import styled from "@emotion/styled";
import { Heading, Box } from "design-system/components";
import { ContentCard } from "design-system/molecules/cards/content-card";
import ReactMarkdown from "react-markdown";
import { assertFieldsNotNull } from "utils/assertions";
import { useNavigate } from "utils/component-utils";
import { getTagsDisplayNameArray } from "utils/tag-utils";
import { BLOG_URL } from "utils/urls";
import { RelatedPostsPageProps } from "../shared/pillars-and-blog-types";

export const PostList: React.FC<RelatedPostsPageProps> = ({
  title,
  description,
  relatedPosts,
}) => {
  const navigate = useNavigate();
  return (
    <PostListWrapper>
      <Heading as="h2">{title}</Heading>
      {description && <ReactMarkdown>{description}</ReactMarkdown>}
      <RelatedPostsContainer>
        {relatedPosts.map((post, index) => {
          const { author, mediaThumbnail, tags, title, slug, category } =
            assertFieldsNotNull(post, [
              "author",
              "mediaThumbnail",
              "tags",
              "title",
              "slug",
              "category",
            ]);

          const typedAuthor = assertFieldsNotNull(author, ["name", "slug"]);
          const tagDisplayNames = getTagsDisplayNameArray(tags);

          return (
            <ContentCard
              key={index}
              title={title}
              tags={tagDisplayNames}
              author={typedAuthor}
              onCardClick={() =>
                navigate(`${BLOG_URL}/${category?.slug}/${slug}`)
              }
              mediaUrl={mediaThumbnail.url}
              publishDate={post.publishedAt}
              previewDescription={post.previewDescription}
            />
          );
        })}
      </RelatedPostsContainer>
    </PostListWrapper>
  );
};

const PostListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
`;

export const RelatedPostsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[32]};
  margin-top: ${({ theme }) => theme.spacing[32]};
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    justify-content: start;
    margin-left: -10px;
  }
`;
