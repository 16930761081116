export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const randomSort = () => Math.random() - 0.5;

// Random number between 0 < max (not including max)
export const randomNumber = (max: number) => Math.floor(Math.random() * max);

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number = 500
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

type GroupByResult<T> = { [key: string]: T[] };

export function groupBy<T, K extends keyof T>(
  items: T[],
  key: K
): GroupByResult<T> {
  return items.reduce((result, currentItem) => {
    const groupKey = currentItem[key] as unknown as string; // Cast key to string to use as object property name
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentItem);
    return result;
  }, {} as GroupByResult<T>);
}
