import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Box, Heading, Text } from "design-system/components";
import { PriceCard } from "./price-card";
import { EnhancedProductPrice, ProductPrice } from "../../shared";
import { useUserContext } from "context/user-context";
import { useCurrencySelector } from "../hooks/use-currency-selector";
import { getProductPrices } from "../subscription-client";
import { useCheckValidCreditsForUser } from "features/credits/client";
import dayjs from "dayjs";

type PricingContainerProps = {
  prices?: ProductPrice[];
  isPricingView?: boolean; // We always want to show the FLOWN Free offer in the pricing view (Dato driven)
};

const PRICING_CONTAINER_WIDTH = 1082;

const sortByYearly = (a: ProductPrice, b: ProductPrice) => {
  if (a.type === "lifetime" && b.type === "lifetime") {
    return 0;
  }
  if (a.type === "lifetime") {
    return 1;
  }
  if (b.type === "lifetime") {
    return -1;
  }
  return a.type.localeCompare(b.type);
};

export const PricingContainer = ({
  prices,
  isPricingView = false,
}: PricingContainerProps) => {
  const {
    externalId,
    isFreeRole,
    isFreeCreditsRole,
    isFreeTrialRole,
    isPaidRole,
  } = useUserContext();

  const { currentCredit } = useCheckValidCreditsForUser({
    externalId,
    isFreeCreditsRole,
    shouldTriggerCheck: false,
  });

  const showFlownFreeOffer =
    isPricingView ||
    (!isFreeRole && !isFreeCreditsRole && !isFreeTrialRole && !isPaidRole);
  const [productPrices, setProductPrices] = useState<EnhancedProductPrice[]>(
    prices ? prices.sort(sortByYearly) : []
  );

  const [isLoading, setIsLoading] = useState(!prices?.length);

  const { selectedCurrency, CurrencySelector } = useCurrencySelector();

  // Update pricePerMonth if the selected currency changes
  useEffect(() => {
    const fetchProductPrices = async () => {
      const results = await getProductPrices();

      if (results.worked) {
        const sortedPrices = results.prices.sort(sortByYearly);
        setProductPrices(sortedPrices);
        setIsLoading(false);
      }
    };

    if (productPrices.length === 0) {
      void fetchProductPrices();
    }
  }, [productPrices.length]);

  if (isLoading) {
    return <SkeletonLoadingPriceCard />;
  }

  return (
    <PricingContainerWrapper>
      <Box className="flex justify-end pad-16">
        <CurrencySelector />
      </Box>
      <Box className="flex-col gap-64 align-center">
        <PriceCardsWrapper>
          {productPrices
            .filter((price) => price.currency === selectedCurrency)
            .map((price) => (
              <PriceCardWrapper
                key={price.priceId}
                className={price.type === "yearly" ? "highlighted" : ""}
              >
                <PriceCard {...price} />
              </PriceCardWrapper>
            ))}
        </PriceCardsWrapper>
        {showFlownFreeOffer ? (
          <Box
            className="pad-32 bg-blueGrey100 b-radius-20 flex-col gap-24 align-center"
            style={{ maxWidth: "620px" }}
          >
            <Heading as="h3">...or choose FLOWN Free</Heading>
            <Text>
              Keep FLOWN in your life as a limited experience:
              <br />
              <br />
              📼 Attend a limited selection of Community sessions.
              <br />
              🙌 Host your own Community sessions.
              <br />
              🦉 Access any sessions on Fridays.
              <br />
              <br />
              For a complete, premium experience, upgrade to one of our FLOWN
              Full paid memberships to unlock everything FLOWN has to offer.
            </Text>
          </Box>
        ) : (
          isFreeCreditsRole && (
            <Box
              className="pad-32 bg-blueGrey100 b-radius-20 flex-col gap-24 align-center"
              style={{ maxWidth: "620px" }}
            >
              <Heading as="h3">or keep using FLOWN for free</Heading>
              <Text>
                You can use FLOWN without a paid membership and still get:
                <br />
                <br />
                ✨ 5 credits to attend sessions during the week.
                <br />
                📼 Full access to on-demand focus tools.
              </Text>
              {currentCredit && (
                <Text fontWeight={600} fontSize="lg">
                  You have{" "}
                  <span className="decor-underline">
                    {" "}
                    {currentCredit.sessionsLeft} credits to use
                  </span>
                  . And you will get a top up at{" "}
                  {dayjs(currentCredit.endDate).format("D MMM YYYY")}{" "}
                </Text>
              )}
            </Box>
          )
        )}
      </Box>
    </PricingContainerWrapper>
  );
};

const PricingContainerWrapper = styled.div`
  margin: 0 auto;
  max-width: ${PRICING_CONTAINER_WIDTH};
`;

const PriceCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
  ${({ theme }) => theme.media["bp-tablet"]} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[16]};
    padding: 0 ${({ theme }) => theme.spacing[8]}px;
  }
`;

const PriceCardWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  width: 330px;

  ${({ theme }) => theme.media["bp-tablet"]} {
    width: 200px;
  }

  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    width: 280px;
  }

  ${({ theme }) => theme.media["bp-desktop-sm"]} {
    width: 350px;
  }

  &.highlighted {
    border-radius: ${({ theme }) => theme.radii[20]};
    border: solid 6px ${({ theme }) => theme.colors.grassLight};

    ${({ theme }) => theme.media["bp-tablet"]} {
      transform: scale(1.2);
    }
  }
`;

const SkeletonLoadingPriceCard = () => (
  <Box className="flex-col gap-64 align-center">
    <PriceCardsWrapper>
      <Box className="skeleton" style={{ height: "300px", width: "350px" }} />
      <Box className="skeleton" style={{ height: "300px", width: "350px" }} />
      <Box className="skeleton" style={{ height: "300px", width: "350px" }} />
    </PriceCardsWrapper>
  </Box>
);
