import { CalendarEvents } from "shared/event.types";
import { fetchRetryClient } from "utils/fetch-retry-client";
import {
  FlownEventResponse,
  FlownEventsResponse,
} from "../shared/event-handler.types";

export const getAllCachedEventsClient = (): Promise<FlownEventsResponse> =>
  fetchRetryClient(`/api/events`).then((response) => response.json());

export const getCachedEventClient = (
  eventId: string
): Promise<FlownEventResponse> =>
  fetchRetryClient(`/api/events/${eventId}`).then((response) =>
    response.json()
  );

export const fetchUpcomingXEventsClient = (
  numberOfEvents: number,
  onlyNotStarted?: boolean,
  tag?: string
): Promise<CalendarEvents> =>
  fetchRetryClient(
    `/api/events/upcoming/${numberOfEvents}${
      onlyNotStarted ? "?onlyNotStarted=true" : ""
    }${tag ? `&tag=${tag}` : ""}`
  ).then((response) => response.json());

export { AllSessionsListPage } from "./all-sessions-list";
