import { ReferralBlock } from "features/referral";
import { ReferralBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";

export const ReferralBlockView = (
  props: ReferralBlockFragment & { className: string }
) => (
  <ReferralBlock
    showStats={props.showStats}
    referralUrlBase={props.referralUrlBase}
    referralsFromDate={props.referralsFromDate}
  />
);

export const ReferralBlockRenderer = renderer({
  typeName: "ReferralBlockRecord",
  view: ReferralBlockView,
});
