import { renderer } from "./renderer";
import { CampaignSubscriptionBlockFragment } from "generated/graphql-typed";
import { CampaignSubscriptionBlock } from "design-system/molecules/blocks/campaign-subscription";
import { Box } from "design-system/components";
import { FOOTER_MARGIN_TOP } from "design-system/organisms/footer";

const CampaignSubscriptionView = ({
  title,
  campaignName,
  fontColor,
  blendInWithFooter,
  ...props
}: CampaignSubscriptionBlockFragment & { className: string }) => {
  const color = fontColor?.hex || "black";
  return (
    <Box
      style={
        blendInWithFooter
          ? {
              backgroundColor: "black",
              color: "white",
              marginBottom: `-${FOOTER_MARGIN_TOP}px`,
            }
          : { color }
      }
    >
      <CampaignSubscriptionBlock
        {...props}
        title={title || ""}
        campaignName={campaignName || "newsletter-default"}
      />
    </Box>
  );
};

export const CampaignSubscriptionRenderer = renderer({
  typeName: "CampaignSubscriptionRecord",
  view: CampaignSubscriptionView,
});
