import { NumberedListBlockFragment } from "generated/graphql-typed";
import { NumberedList } from "design-system/molecules/numbered-list";
import { assertFieldsNotNull } from "utils/assertions";
import { ModularContent } from "../components/modular-content";
import { renderer } from "./renderer";

export const NumberedListView = (
  props: NumberedListBlockFragment & { className: string }
) => {
  const { numberedListTitle, numberedListItems } = assertFieldsNotNull(props, [
    "numberedListTitle",
    "numberedListItems",
  ]);
  return (
    <NumberedList
      title={numberedListTitle}
      description={props.numberedListDescription}
      items={numberedListItems?.blocks.map((block) => (
        <ModularContent
          key={block.id}
          data={block.content}
          paragraphSize={undefined}
        />
      ))}
      ctaLink={props.ctaLink}
      ctaLabel={props.ctaLabel}
    />
  );
};

export const NumberedListRenderer = renderer({
  typeName: "NumberedListRecord",
  view: NumberedListView,
});
