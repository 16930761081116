import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import React from "react";
import { Icon } from "design-system/components";
import { Box, Text } from "design-system/components";
import { useUserContext } from "context/user-context";
import { ReferralStats } from "models/user";
import { postToGetUserStatsData } from "features/user";
import Link from "next/link";
import { LOGIN_URL, REGISTER_URL } from "utils/urls";
import { useCopyToClipboard } from "utils/hooks/use-copy-to-clipboard";
import dayjs from "dayjs";

type ReferralBlockProps = {
  referralUrlBase?: string | null;
  showStats?: boolean;
  referralStats?: ReferralStats;
  referralsFromDate: string;
};

export const ReferralBlock: FC<ReferralBlockProps> = ({
  referralUrlBase = REGISTER_URL,
  showStats = false,
  referralStats = {
    total: 0,
    freeTrialReferrals: 0,
    firstSessionAttendedReferrals: 0,
    memberReferrals: 0,
  },
  referralsFromDate,
}) => {
  const { referralCode, loggedIn } = useUserContext();

  const createFullReferralLink = useCallback(
    (rc: string | undefined) => {
      return `${
        window?.location.origin || ""
      }${referralUrlBase}?referral=${rc}`;
    },
    [referralUrlBase]
  );

  const [userStats, setUserStats] = useState(referralStats);
  const [usedReferralLink, setUsedReferralLink] = useState("");
  const [, copyToClipboard, CopyToClipboardToast] = useCopyToClipboard();

  useEffect(() => {
    setUsedReferralLink(createFullReferralLink(referralCode));
  }, [referralCode, createFullReferralLink]);

  const onLinkCopied = () => {
    if (usedReferralLink) {
      copyToClipboard(usedReferralLink);
    }
  };

  const getUserStats = useCallback(async () => {
    const payload = referralsFromDate ? { referralsFromDate } : {};
    const response = await postToGetUserStatsData(payload);
    if (response.worked) {
      setUserStats(response.userStats);
    }
  }, [referralsFromDate]);

  useEffect(() => {
    if (showStats) {
      void getUserStats();
    }
  }, [getUserStats, showStats]);

  return (
    <>
      {!loggedIn ? (
        <Box className="text-center">
          <Text>
            <Link style={{ textDecoration: "underline" }} href={`${LOGIN_URL}`}>
              Sign in
            </Link>{" "}
            to see your referral code
          </Text>
        </Box>
      ) : (
        <>
          {referralCode && referralCode !== "" && (
            <Box className="text-center">
              {CopyToClipboardToast}
              <ReferralLinkSection>
                <Text fontWeight={700}>
                  Your referral link - click to copy then share:
                </Text>
                <ReferralLink
                  className="pad-8 mar-8 cursor-pointer"
                  onClick={onLinkCopied}
                >
                  {usedReferralLink} <Icon icon="copy" />
                </ReferralLink>
              </ReferralLinkSection>
              {showStats && (
                <Box className="mar-y-32">
                  {(userStats.total || 0) > 0 && (
                    <>
                      <Text>
                        You have referred{" "}
                        <strong>{userStats?.total} people</strong> to FLOWN
                        {referralsFromDate &&
                          ` since ${dayjs(referralsFromDate).format(
                            "DD MMM YYYY"
                          )}`}
                        .
                      </Text>

                      <Text>
                        Joined 1+ sessions:{" "}
                        <strong>
                          {userStats?.firstSessionAttendedReferrals}
                        </strong>
                      </Text>
                      <Text>
                        Became full FLOWN members:{" "}
                        <strong>{userStats?.memberReferrals}</strong>
                      </Text>
                    </>
                  )}
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

const ReferralLinkSection = styled.section`
  margin-top: 1.5rem;
  border: solid 1px black;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  display: inline-block;
`;

const ReferralLink = styled.p`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  background: ${({ theme }) => theme.colors.grey100};
`;
