"use client";
import { useEffect, useState } from "react";
import { normalizeError } from "services/normalize-error";
import { PopulatedSessionActivities } from "../../shared/event-types";
import { getPopulatedSessionsJoined } from "../calendar-client";

export type PopulatedSessionsJoinedState = {
  sessionsAttended: PopulatedSessionActivities;
  status: "idle" | "loading" | "rejected" | "resolved";
  error: string;
};

export const useGetPopulatedSessionsAttended = (userExternalId?: string) => {
  const [sessionsJoinedState, setSessionsJoinedState] =
    useState<PopulatedSessionsJoinedState>({
      sessionsAttended: [],
      status: "idle",
      error: "",
    });

  useEffect(() => {
    const fetchPopulatedSessionsAttended = async () => {
      try {
        setSessionsJoinedState((prev) => ({ ...prev, status: "loading" }));
        const response = await getPopulatedSessionsJoined(userExternalId)();
        if (!response.worked) {
          setSessionsJoinedState({
            sessionsAttended: [],
            status: "rejected",
            error: response.error,
          });
          return;
        }
        const { sessionsAttended } = response;

        setSessionsJoinedState({
          sessionsAttended,
          status: "resolved",
          error: "",
        });
      } catch (error) {
        const normalizedError = normalizeError(error);
        setSessionsJoinedState({
          sessionsAttended: [],
          status: "rejected",
          error: normalizedError.message,
        });
      }
    };
    if (sessionsJoinedState.status === "idle") {
      void fetchPopulatedSessionsAttended();
    }
  }, [sessionsJoinedState.status, userExternalId]);

  return sessionsJoinedState;
};
