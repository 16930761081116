import { getCachedUsersInRoom } from "features/sessions/client";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { UserProfileFromActivity } from "shared/user.types";
import { DROP_IN_ID } from "utils/constants";

import { useHeartbeatRequest } from "utils/hooks/use-heartbeat-request";
import {
  CALENDAR_PREFIX,
  LAUNCHPAD_URL,
  SPRINGBOARD_URL_PREFIX,
} from "utils/urls";

type UsersInDropInValue = {
  users: UserProfileFromActivity[];
};

const UsersInDropInContext = createContext<UsersInDropInValue>({
  users: [],
});
const ALLOWED_URLS = [LAUNCHPAD_URL, CALENDAR_PREFIX, SPRINGBOARD_URL_PREFIX];
export const UsersInDropInProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [users, setUsers] = useState<UserProfileFromActivity[]>([]);

  const fetchUsers = useCallback(async () => {
    if (ALLOWED_URLS.some((url) => window.location.pathname.includes(url))) {
      const response = await getCachedUsersInRoom(DROP_IN_ID);
      if (response?.worked) {
        setUsers(response.users);
      }
    }
  }, []);

  useHeartbeatRequest({ fn: fetchUsers, interval: 30000 });

  const value = useMemo(
    () => ({
      users,
    }),
    [users]
  );

  return (
    <UsersInDropInContext.Provider value={value}>
      {children}
    </UsersInDropInContext.Provider>
  );
};

export const useUsersInDropIn = () => useContext(UsersInDropInContext);
