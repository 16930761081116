import styled from "@emotion/styled";
import { AccountabilityWallContainer } from "features/accountability/client";
import {
  CalendarWrapper,
  getPopulatedSessionsRegistered,
} from "features/calendar/client";
import { PopulatedSessionActivities } from "features/calendar/shared";
import {
  ProfileSummary,
  SessionTabs,
  useGetUserProfileById,
} from "features/profile/client";
import { getUserDisplayName } from "features/profile/shared";
import {
  HostedSessionsWrapper,
  useGetHostedSessions,
} from "features/sessions/client";
import { Box, Text } from "design-system/components";
import { memo, useEffect, useState } from "react";

export const ProfileView = memo(({ userId }: { userId: string }) => (
  <ProfileSidePanel userId={userId} />
));

export const ProfileSessionsView = memo(
  ({ externalId }: { externalId?: string }) => (
    <Box className="mar-y-32">
      <SessionTabs userExternalId={externalId} />
    </Box>
  )
);

/**
 * Profile preview with user's intentions and sessions
 */
const ProfileSidePanel = ({ userId }: { userId: string }) => {
  const [userEventsRegistered, setUserEventsRegistered] =
    useState<PopulatedSessionActivities>([]);

  useEffect(() => {
    const fetchSelectedUserRegisteredEvents = async () => {
      const response = await getPopulatedSessionsRegistered(userId)();
      if (response.worked) {
        const { sessionsRegistered } = response;
        setUserEventsRegistered(sessionsRegistered);
      }
    };
    if (userId) {
      void fetchSelectedUserRegisteredEvents();
    }
  }, [userId]);

  const [hostedSessionsState] = useGetHostedSessions({
    externalId: userId,
  });

  const hostProfile = hostedSessionsState.hostedSessions[0]?.populatedHost;

  const { profileData } = useGetUserProfileById(userId);

  return (
    <>
      <ProfileSummarySection>
        <ProfileSummary profileExternalId={userId} showProfileLink />
      </ProfileSummarySection>
      <Box className="flex-col gap-16 mar-y-24">
        {hostedSessionsState.isHost &&
        hostedSessionsState.hostedSessions.length ? (
          <>
            {hostProfile && (
              <Text as="h3" fontSize="lg" fontWeight={600}>
                {`Sessions with ${getUserDisplayName(hostProfile)}`}
              </Text>
            )}
            <HostedSessionsWrapper
              externalId={userId}
              hostedSessionsFromServer={hostedSessionsState.hostedSessions}
              isHostFromServer
              maxSessionsInView={3}
            />
          </>
        ) : null}
        {profileData?.user && userEventsRegistered.length > 0 && (
          <>
            <Text as="h3" fontSize="lg" fontWeight={600}>
              {`Join sessions with ${getUserDisplayName(profileData?.user)}`}
            </Text>
            <CalendarWrapper
              id="hosted-sessions-wrapper"
              events={userEventsRegistered.slice(0, 3)}
              calendarSliceArgs={{}}
              forceView="list"
              showEventFilters={false}
              analyticsContext={"side-panel"}
              previewInSidePanel
            />
          </>
        )}
        <Text as="h3" fontSize="lg" fontWeight={600}>
          Intentions
        </Text>
        <AccountabilityWallContainer
          userExternalId={userId}
          style={{
            overflowY: "visible",
          }}
        />
      </Box>
    </>
  );
};

const ProfileSummarySection = styled.section`
  padding-right: 0;
`;
