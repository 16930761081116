import { useState } from "react";
import { VideoJsPlayer } from "video.js";

export type ValidPlayer = VideoJsPlayer | HTMLVideoElement | null;

type UseTrackVideoProgressArgs = {
  video25PercentCallback: () => void;
  video50PercentCallback: () => void;
  videoCompletedCallback: () => Promise<void>;
};

export const useTrackVideoProgress = ({
  video25PercentCallback,
  video50PercentCallback,
  videoCompletedCallback,
}: UseTrackVideoProgressArgs) => {
  const [isVideoCompleted, setIsVideoCompleted] = useState(false);
  const [isVideo25PercentCompleted, setIsVideo25PercentCompleted] =
    useState(false);
  const [isVideo50PercentCompleted, setIsVideo50PercentCompleted] =
    useState(false);

  const onVideoProgress = (player: ValidPlayer) => {
    if (!player) return;
    const duration =
      typeof player.duration === "function"
        ? player.duration()
        : player.duration;
    const currentTime =
      typeof player.currentTime === "function"
        ? player.currentTime()
        : player.currentTime;
    const videoProgress = (currentTime / duration) * 100;

    if (
      videoProgress > 25 &&
      videoProgress < 50 &&
      !isVideo25PercentCompleted
    ) {
      setIsVideo25PercentCompleted((prevState) => {
        if (!prevState) {
          video25PercentCallback();
        }
        return true;
      });
    }
    if (
      videoProgress > 50 &&
      videoProgress < 75 &&
      !isVideo50PercentCompleted
    ) {
      setIsVideo50PercentCompleted((prevState) => {
        if (!prevState) {
          video50PercentCallback();
        }
        return true;
      });
    }
    if (videoProgress > 75 && !isVideoCompleted) {
      setIsVideoCompleted((prevState) => {
        if (!prevState) {
          void videoCompletedCallback();
        }
        return true;
      });
    }
  };

  return { onVideoProgress };
};
