import { useCallback, useState } from "react";
import { useUserContext } from "../../context/user-context";
import { getUserConnectionsData } from "features/profile/client";
import { FollowUser, UserOnlineStatus } from "shared/user.types";
import { useHeartbeatRequest } from "utils/hooks/use-heartbeat-request";
import { Text, TextLink } from "design-system/components";
import dayjs from "dayjs";
import { isProductionMode } from "utils/environment";
import { escapeRegExp } from "utils/string-utils";
import {
  COMMUNITY_SPRINGBOARD_SHORT_URL,
  ROOMS_URL,
  SESSIONS_GO_BASE_URL,
} from "utils/urls";

// This hook just fetches to update the user's connections every 30 seconds
export const useOnlineConnections = () => {
  const { externalId } = useUserContext();
  const [following, setFollowing] = useState<FollowUser[]>([]);
  const [followers, setFollowers] = useState<FollowUser[]>([]);

  const updateConnectionsOnline = useCallback(async () => {
    if (!externalId) return;
    const result = await getUserConnectionsData(externalId);

    if (result.worked) {
      const { following: newFollowing, followers: newFollowers } = result;
      setFollowing(newFollowing);
      setFollowers(newFollowers);
    }
  }, [externalId]);
  useHeartbeatRequest({ fn: updateConnectionsOnline, interval: 1000 * 30 });

  return { following, followers };
};

const OFFLINE_THRESHOLD = isProductionMode() ? 3 : 60 * 24 * 60; // in dev go back a long time for testing

export const getIsOnline = (onlineStatus?: { lastPing?: string }) => {
  if (!onlineStatus) return false;

  return dayjs()
    .subtract(OFFLINE_THRESHOLD, "minutes")
    .isBefore(dayjs(onlineStatus?.lastPing), "minute");
};

export const onlinePageText = (onlineStatus?: UserOnlineStatus) => {
  if (!onlineStatus || !onlineStatus?.currentPage) return "";

  /**
   * We are safely escaping the regex and passing known values
   */
  // eslint-disable-next-line security/detect-non-literal-regexp
  const onlineStatusPageRegex = new RegExp(
    `(?:^|[^\\w])(${escapeRegExp(SESSIONS_GO_BASE_URL)}|${escapeRegExp(
      ROOMS_URL
    )})(?:$|[^\\w])`,
    "i"
  );

  if (onlineStatusPageRegex.test(onlineStatus.currentPage)) {
    const sessionId = extractSessionId(onlineStatus.currentPage);

    return sessionId ? (
      <>
        <Text className="flex" fontSize="sm">
          In a {onlineStatus.currentPageDisplayName}
          {": "}
        </Text>
        <TextLink
          href={COMMUNITY_SPRINGBOARD_SHORT_URL + "/" + sessionId} // this works with both ids
          onClick={(e) => e.stopPropagation()}
          fontSize="sm"
        >
          Join them!
        </TextLink>
      </>
    ) : (
      <>Currently online</>
    );
  }
};

const extractSessionId = (url: string) => {
  const regexSession = /sessions\/go\/([^/]+)/;
  const matchSession = url.match(regexSession);
  if (matchSession) {
    return matchSession[1];
  }

  const regexRoomId = /rooms\/([^/]+)/;
  const matchRoom = url.match(regexRoomId);
  if (matchRoom) {
    return matchRoom[1];
  }
  return null;
};
