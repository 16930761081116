import { DSComponentPropsProvider } from "design-system/design-system.types";
import { ReactNode } from "react";
import { styled } from "styled-system/jsx";
import { capitalizeFirstLetter } from "utils/string-utils";

type SwitchSelectorOption = {
  id: string;
  label: string | ReactNode;
};

type SwitchSelectorProps = DSComponentPropsProvider & {
  onChangeSelected: (priceId: string) => void;
  options: SwitchSelectorOption[];
  selectedOptionId: string;
};

export const SwitchSelector = ({
  selectedOptionId,
  options,
  onChangeSelected,
  ...props
}: SwitchSelectorProps) => {
  return (
    <SwitchContainer {...props}>
      {options.map((option) => (
        <SwitchOption
          data-cy={`switch-option-${option.id.toLowerCase()}`}
          onClick={() => onChangeSelected(option.id)}
          key={option.id}
          variant={selectedOptionId === option.id ? "selected" : "unselected"}
        >
          {typeof option.label === "string"
            ? capitalizeFirstLetter(option.label)
            : option.label}
        </SwitchOption>
      ))}
    </SwitchContainer>
  );
};

const SwitchContainer = styled("div", {
  base: {
    alignContent: "center",
    backgroundColor: "white",
    display: "grid",
    gridAutoFlow: "column",
    justifyContent: "space-between",
    padding: "4",
    position: "relative",
  },
});

const SwitchOption = styled("span", {
  base: {
    cursor: "pointer",
    borderRadius: "16",
    display: "flex",
    fontSize: "sm",
    fontWeight: "500",
    justifyContent: "center",
    padding: "{spacing.8} {spacing.16}",
    textAlign: "center",
    transition: "color 0.4s ease",
    whiteSpace: "nowrap",
    zIndex: "11",
  },
  variants: {
    variant: {
      unselected: {},
      selected: {
        backgroundColor: "blueGrey100",
      },
    },
  },
  defaultVariants: {
    variant: "unselected",
  },
});
