import { PostList, RelatedBlogPost } from "features/pillars-and-blog";
import { transformAndSortBlogPosts } from "features/pillars-and-blog/shared";
import { PostListBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";

export const PostListView = (
  props: PostListBlockFragment & { className: string }
) => {
  const title = props.postBlockTitle || "";
  const description = props.postBlockDescription || "";
  const relatedPostList: Array<RelatedBlogPost> = props.posts;
  const sortedPosts = transformAndSortBlogPosts(relatedPostList);
  return (
    <PostList
      title={title}
      description={description}
      relatedPosts={sortedPosts}
    />
  );
};

export const PostListRenderer = renderer({
  typeName: "PostListRecord",
  view: PostListView,
});
