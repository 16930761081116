import styled from "@emotion/styled";
import { renderer } from "./renderer";
import { QuoteblockBlockFragment } from "generated/graphql-typed";

type QuoteBlockViewProps = QuoteblockBlockFragment & { className: string };
const QuoteblockView: React.FC<QuoteBlockViewProps> = ({
  className,
  author,
  quote,
  backgroundColor,
}) => {
  return (
    <QuoteblockContainerDiv
      className={className}
      backgroundColor={backgroundColor?.hex || "#000000"}
    >
      <div>{quote}</div>
      <div>- {author}</div>
    </QuoteblockContainerDiv>
  );
};

export const QuoteblockRenderer = renderer({
  typeName: "QuoteblockRecord",
  view: QuoteblockView,
});

const QuoteblockContainerDiv = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => `${props.backgroundColor}`};
  padding: 1rem;
  border-radius: 8px;
`;
