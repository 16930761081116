import styled from "@emotion/styled";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { TextareaHTMLAttributes } from "react";
import { inputStyles } from "./shared/form-styles";
import { Box } from "./box";
import { Text, TextError } from "./text";

type TextAreaProps = TextareaHTMLAttributes<HTMLElement> &
  DSComponentPropsProvider & {
    full?: boolean;
    maxLength?: number;
    errorMessage?: string;
  };

export const TextArea = ({
  className = "",
  maxLength,
  full,
  errorMessage,
  ...props
}: TextAreaProps) => {
  return (
    <Box className="flex-col w-full gap-8">
      <StyledTextArea
        className={`${className} ${full ? "full" : ""} ${
          errorMessage ? "has-error" : ""
        }`}
        maxLength={maxLength}
        {...props}
      />
      <Box className="flex justify-between align-center">
        {errorMessage ? (
          <TextError fontWeight={400}>{errorMessage}</TextError>
        ) : (
          <div></div>
        )}
        {maxLength && (
          <Box className="mar-x-8" style={{ alignSelf: "flex-end" }}>
            <Text fontSize="xs" color="grey300">
              {typeof props.value === "string"
                ? `${props.value.length}/${maxLength}`
                : `0/${maxLength}`}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const StyledTextArea = styled.textarea(({ theme }) => ({
  ...inputStyles(theme),
  resize: "none",
  borderRadius: theme.radii[16],
}));
