"use client";
import styled from "@emotion/styled";
import Link, { LinkProps as NextLinkProps } from "next/link";
import { useState } from "react";
import { Spinner } from "design-system/components/spinner";

/**
 * https://emotion.sh/docs/styled
 * Styled can style any component as long as it accepts a className prop.
 */
type ButtonCommonProps = React.ButtonHTMLAttributes<HTMLElement> & {
  loading: boolean;
  className: string;
  onClick: (event: React.MouseEvent) => void;
  setLoadingOnClick?: boolean;
  spinnerColor?: string;
  type?: "submit" | "button";
  href: NextLinkProps["href"];
  linkId?: string; // for analytics
  dataCy?: string;
  asSpan?: boolean;
  target?: string;
};

export type ButtonProps = Partial<ButtonCommonProps>;

const ButtonCommon: React.FC<Partial<ButtonCommonProps>> = ({
  loading,
  spinnerColor,
  className,
  children,
  onClick,
  setLoadingOnClick,
  type,
  href,
  linkId,
  dataCy,
  asSpan = false,
  ...otherProps
}) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const buttonClicked = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
    setHasBeenClicked(true);
  };

  const showLoading = loading || (setLoadingOnClick && hasBeenClicked);

  const ButtonCore = (
    <ButtonBase
      data-link-id={linkId}
      data-cy={dataCy}
      type={type}
      className={className}
      onClick={buttonClicked}
      as={asSpan ? "span" : "button"}
      {...otherProps}
    >
      {showLoading && (
        <SpinnerContainer>
          <Spinner color={spinnerColor} margin={0} size={16} />
        </SpinnerContainer>
      )}
      {!showLoading && <ChildrenContainer>{children}</ChildrenContainer>}
    </ButtonBase>
  );

  return href ? (
    <Link href={showLoading ? "" : href}>{ButtonCore}</Link>
  ) : (
    ButtonCore
  );
};

const ButtonBase = styled.button`
  display: inline-block;
`;

export const RoundedButton = styled(ButtonCommon)`
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  height: 40px;
  transition: all 0.2s ease;
  @media only screen and (min-width: 1000px) {
    height: 48px;
    font-size: 16px;
    padding: 13px 32px;
  }
`;

export const SmallButtonStyles = `
  @media only screen and (min-width: 1000px) {
    height: 44px;
    padding: 10px 24px;
  }
`;

const SpinnerContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChildrenContainer = styled.span`
  &.invisible {
    visibility: hidden;
  }
`;
