import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "../server/auth-server";
import { analytics, catchAnalyticsError } from "features/analytics";
import {
  SendVerificationEmailRequest,
  SendVerificationEmailResponse,
} from "../server/api/send-verification-email-handler";
import { LogoutResponse } from "../server/api/logout-handler";
import { postToAddUserActivity } from "features/user-activities/client";
import { PRODUCTS } from "shared/products.types";
import { SUBSCRIPTION_TYPES } from "shared/subscription.types";
import { VercelRequest } from "@vercel/node";
import { USER_ROLES } from "services/roles/user-roles";
import {
  ANALYTICS_MEMBERSHIP_OPTIONS,
  LIFECYCLE_OPTIONS,
} from "utils/analytics-constants";
import {
  CheckEmailVerificationRequest,
  CheckEmailVerificationResponse,
  GoogleAuthRequest,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  SignUpSource,
  StarterQuestion,
} from "../shared/types";
import { googleLogout } from "@react-oauth/google";
import { CreateMagicLinkResponse } from "../shared/types";
import { HOME_URL, SSO_LOGOUT_URL } from "utils/urls";
import { QUERY_PARAMS } from "utils/url-query-utils";
import dayjs from "dayjs";
import { getConfig } from "services/config";
import { isProductionMode } from "utils/environment";

export const postToForgotPassword = createPostEndpoint<
  ForgotPasswordRequest,
  ForgotPasswordResponse
>("/api/forgotPassword");

const postToLogin = createPostEndpoint<LoginRequest, LoginResponse>(
  "/api/login"
);

export const postToGoogleLogin = createPostEndpoint<
  GoogleAuthRequest,
  LoginResponse
>("/api/auth/google/login");

export const logIn = async (request: LoginRequest) => {
  const res = await postToLogin(request);
  if (res.worked) {
    const { externalId, intercomVerificationHash, firstName, lastName } = res;
    analytics.identifyClient(
      externalId,
      {
        firstName,
        lastName,
      },
      intercomVerificationHash
    );
    analytics.track("Logged In", {}).catch(catchAnalyticsError);
  }
  return res;
};

const postToRegister = createPostEndpoint<RegisterRequest, RegisterResponse>(
  "/api/register"
);

type RegisterAnalyticsArgs = {
  response: RegisterResponse;
  lastPageVisited?: string;
  redirectAfterRegister?: string;
  signUpSource?: SignUpSource;
};

export async function triggerRegisterAnalytics({
  response,
  lastPageVisited,
  redirectAfterRegister,
  signUpSource = "",
}: RegisterAnalyticsArgs) {
  if (response.worked) {
    const {
      firstName,
      lastName,
      email,
      externalId,
      createTime,
      referralCode,
      referredBy,
      registerDiscountCodes,
    } = response.user;
    const { intercomVerificationHash, organisationSlug, starterQuestion } =
      response;

    const corporateDataForIdentify = organisationSlug
      ? {
          "Company slug": organisationSlug,
        }
      : {};

    const lifecycleStage = organisationSlug
      ? LIFECYCLE_OPTIONS.B2B_ACTIVE
      : LIFECYCLE_OPTIONS.TRIAL_MEMBER;
    const membershipType = ANALYTICS_MEMBERSHIP_OPTIONS.FULL;
    const subscriptionType = SUBSCRIPTION_TYPES.NONE;
    const userRole = organisationSlug
      ? USER_ROLES.CORPORATE_USER
      : USER_ROLES.FREE_TRIAL;

    /**
     * When the user signs up we send both their anonymousId and their
     * externalId and Segment will merge the two identities and attribute
     * the anonymous events to the correct user
     */
    const starterQuestionData = starterQuestion
      ? {
          option: starterQuestion.value,
          text: starterQuestion.question,
        }
      : undefined;
    const mergeIdentities = true;

    const freeTrialPeriod = getConfig("STRIPE_FREE_TRIAL_PERIOD");
    const freeTrialExpiryDate = dayjs()
      .add(freeTrialPeriod, "day")
      .toISOString();
    const freeTrialRemainingDays = dayjs().diff(dayjs(createTime), "day");

    await analytics
      .identify(
        {
          firstName,
          lastName,
          email,
          createdAt: createTime,
          "Is verified": false,
          "Referral code": referralCode,
          "Referred by": referredBy,
          "Lifecycle stage": lifecycleStage,
          "Membership type": membershipType,
          "Subscription type": subscriptionType,
          "Free trial expiry date": freeTrialExpiryDate,
          "Free trial remaining days": freeTrialRemainingDays,
          "User role": userRole,
          "Signup source": signUpSource,
          "Get Started Option Selected": starterQuestionData,
          "Register discount data": registerDiscountCodes,
          ...corporateDataForIdentify,
        },
        {
          All: true,
          "Actions Google Analytics 4": false,
        },
        mergeIdentities
      )
      .catch(catchAnalyticsError);

    // Fire and forget the next calls - the main one to await
    // is the identify above as it needs to come before
    await analytics
      .track(
        "Account Created",
        {
          lastPageVisited,
          redirectAfterRegister,
          signUpSource,
          analyticsSource: "GA4",
          getStartedOption: starterQuestionData,
          registerDiscountData: registerDiscountCodes,
        },
        {
          All: true,
          "Actions Google Analytics 4": true,
          "Google Ads Conversions": false,
        }
      )
      .catch(catchAnalyticsError);
    await analytics
      .track(
        "Account Created",
        {
          lastPageVisited,
          redirectAfterRegister,
          signUpSource,
          analyticsSource: "Google Ads Conversions",
          getStartedOption: starterQuestionData,
          registerDiscountData: registerDiscountCodes,
        },
        {
          All: false,
          "Google Ads Conversions": true,
        }
      )
      .catch(catchAnalyticsError);

    await analytics.trackClient(
      "Account Created",
      {
        signUpSource,
        email,
        redirectAfterRegister,
        analyticsSource: "Google Tag Manager",
        getStartedOption: starterQuestionData,
        registerDiscountData: registerDiscountCodes,
      },
      {
        All: false,
        "Google Tag Manager": true,
      }
    );

    if (!organisationSlug) {
      await analytics.track("Trial Started", {}).catch(catchAnalyticsError);
      // TODO: the combination of these calls is a good candidate for a FE user model
      analytics
        .track("Membership Changed", {
          old_membership: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
          new_membership: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
        })
        .catch(catchAnalyticsError);
      await postToAddUserActivity({
        payload: {
          oldMembership: ANALYTICS_MEMBERSHIP_OPTIONS.NONE,
          newMembership: ANALYTICS_MEMBERSHIP_OPTIONS.FULL,
        },
        product: PRODUCTS.MEMBERSHIP,
      });
    }

    analytics.identifyClient(
      externalId,
      {
        firstName,
        lastName,
        createdAt: createTime,
      },
      intercomVerificationHash
    );
  }
}

export const register = async (request: RegisterRequest) => {
  const response = await postToRegister(request);
  await triggerRegisterAnalytics({
    response,
    lastPageVisited: request.lastPageVisited,
    redirectAfterRegister: request.redirectAfterRegister,
  });
  return response;
};

const postToGoogleRegister = createPostEndpoint<
  GoogleAuthRequest & {
    lastPageVisited?: string;
    redirectAfterRegister?: string;
    starterQuestion?: StarterQuestion;
  },
  RegisterResponse
>("/api/auth/google/register");

export const registerWithGoogle = async (
  request: GoogleAuthRequest & {
    lastPageVisited?: string;
    redirectAfterRegister?: string;
    starterQuestion?: StarterQuestion;
  }
) => {
  const response = await postToGoogleRegister(request);
  if (response.worked) {
    await triggerRegisterAnalytics({
      response: {
        ...response,
        starterQuestion: request.starterQuestion || null,
      },
      lastPageVisited: request.lastPageVisited,
      signUpSource: "google",
    });
  }
  return response;
};

const postToLogout = createPostEndpoint<VercelRequest, LogoutResponse>(
  "/api/logout"
);

export const logOut = async () => {
  await postToLogout();
  // Redirect to SSO URL which then redirects to launchpad
  // Only clean logout SSO in product to avoid redirecting to preview.flown.com
  // in preview environments
  if (isProductionMode()) {
    window.location.href = SSO_LOGOUT_URL;
  } else {
    window.location.href = `${window.location.origin}${HOME_URL}`;
  }
  analytics.resetClient();
  googleLogout();
};

export const postToResetPassword = createPostEndpoint<
  ResetPasswordRequest,
  ResetPasswordResponse
>("/api/resetPassword");

export const postToSendVerificationEmail = createPostEndpoint<
  SendVerificationEmailRequest,
  SendVerificationEmailResponse
>("/api/send-verification-email");

export const postToChangePassword = createPostEndpoint<
  ChangePasswordRequest,
  ChangePasswordResponse
>("/api/change-password");

export const postToCheckEmailVerification = createPostEndpoint<
  CheckEmailVerificationRequest,
  CheckEmailVerificationResponse
>("/api/auth/check-email-verification");

export const postToCreateMagicLink = (sendEmail = false) =>
  createPostEndpoint<VercelRequest, CreateMagicLinkResponse>(
    `/api/magic-link/create${
      sendEmail ? `?${QUERY_PARAMS.MAGIC_LINK_SEND_EMAIL}=true` : ""
    }`
  )();
