import Link from "next/link";
import { styled } from "styled-system/jsx";

export const TOP_NAV_HEIGHT = 60;

export const NavMenuContainer = styled("nav", {
  base: {
    display: "flex",
    alignItems: "center",
    position: "sticky",
    height: "secondaryNavHeight",
    top: "0",
    left: "0",
    right: "0",
    zIndex: "100",
    borderBottom: "1px solid #e5e1e6",
    background: "white",
    padding: "0 {spacing.16}",
  },
});

export const SecondaryNavWrapper = styled("nav", {
  base: {
    alignItems: "flex-end",
    backgroundColor: "white",
    borderBottom: `1px solid {colors.grey100}`,
    display: "flex",
    height: "auto",
    flexDirection: "column",
    gap: "8",
    padding: "8",

    "bp-tablet": {
      alignItems: "center",
      flexDirection: "row",
      height: `{sizes.secondaryNavHeight}`,
      display: "flex",
      justifyContent: "center",
      padding: `{spacing.4} {spacing.24}`,
    },
  },
});

export const TabsContainer = styled("div", {
  base: {
    display: "flex",
    borderRadius: "round",
    padding: "4",
    gap: "8",
    backgroundColor: "grey100",
    width: "100%",

    "bp-tablet": {
      width: "auto",
    },
  },
});

export const Selector = styled(Link, {
  base: {
    borderRadius: "round",
    color: "grey500",
    display: "flex",
    justifyContent: "center",
    padding: "{spacing.8} {spacing.16}",
    transition: "all 0.2s ease-in-out",
    width: "100%",

    "&:hover": {
      backgroundColor: "blueGrey100",
    },
    "&.active": {
      backgroundColor: "white",
      color: "black",
      fontWeight: 700,
    },
    "bp-desktop-xs": {
      width: "160px",
    },
  },
});
