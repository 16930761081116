import { ProfileSummaryData } from "features/profile/shared";
import { useEffect, useState } from "react";
import { getUserProfileData } from "../profile-client";
import { ProfileTag } from "shared/user.types";
import { ProfileData } from "../../shared/profile.types";

type ViewerData = ProfileSummaryData["viewerData"];

const sortTagsPerCommonOccurrence =
  (viewerTags: string[]) => (tagA: ProfileTag, tagB: ProfileTag) =>
    viewerTags.includes(tagA.slug)
      ? viewerTags.includes(tagB.slug)
        ? tagA.slug.localeCompare(tagB.slug)
        : 1
      : tagA.slug.localeCompare(tagB.slug);

export const useGetUserProfileById = (id?: string) => {
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [viewerData, setViewerData] = useState<ViewerData | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        if (!id) {
          setErrorMessage("Failed to load user");
          return;
        }
        const result = await getUserProfileData(id)();
        if (result.worked) {
          const profileDataSortedTags = {
            ...result.profileData,
            formattedTags: result.profileData.formattedTags.sort(
              sortTagsPerCommonOccurrence(result.viewerData.tags)
            ),
          };

          setProfileData(profileDataSortedTags);
          setViewerData(result.viewerData);
        } else {
          setErrorMessage("Failed to load user");
        }
      } catch {
        setErrorMessage("Failed to load user");
      } finally {
        setIsLoading(false);
      }
    };

    void fetchUser();
  }, [id]);

  return {
    profileData,
    viewerData,
    errorMessage,
    isLoading,
  };
};
