import { ScheduledSessionListBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { assertFieldsNotNull } from "utils/assertions";
import styled from "@emotion/styled";
import { ContentContainer, contentContainerStyles } from "legacy-design-system";
import Image from "next/image";
import { Tab, TabSwitcher } from "design-system/molecules/tab-switcher";
import { Accordion, AccordionItem, Box, Icon } from "design-system/components";
import { UiContentBlock } from "./reusable-content-view";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { THEME_COLORS } from "design-system/emotion.theme";
import { token } from "styled-system/tokens";

type EventSession = {
  slug: string;
  icon: {
    url: string;
  };
  description: string;
  displayName: string;
  longDescription: string;
};

type EventSessionListViewProps = {
  scheduledSessions?: Array<EventSession>;
  showBackButton?: boolean;
  backgroundColor?: string;
  className: string;
};

export const EventSessionList: React.FC<EventSessionListViewProps> = ({
  scheduledSessions,
  backgroundColor = THEME_COLORS.white,
  showBackButton = false,
}) => {
  const router = useRouter();

  const items: AccordionItem[] = useMemo(() => {
    return (
      (scheduledSessions?.length &&
        scheduledSessions?.map((event, index) => {
          return {
            title: (
              <Box className="flex align-center">
                {event.icon.url && (
                  <Image
                    src={event.icon.url}
                    height="40"
                    width="40"
                    alt={`${event.displayName} icon`}
                  />
                )}
                <span className="pad-x-8">{event.displayName}</span>
              </Box>
            ),
            content: (
              <div key={index} className="b-radius-12 pad-16">
                <UiContentBlock uiContentSlug={event.slug} />
              </div>
            ),
          };
        })) ||
      []
    );
  }, [scheduledSessions]);

  const tabs: Tab[] = useMemo(() => {
    return (
      (scheduledSessions?.length &&
        scheduledSessions?.map((event, index) => {
          return {
            value: event.displayName,
            label: (
              <Box className="flex align-center">
                {event.icon.url && (
                  <Image
                    src={event.icon.url}
                    height="40"
                    width="40"
                    alt={`${event.displayName} icon`}
                  />
                )}
                <span className="pad-x-8">{event.displayName}</span>
              </Box>
            ),
            content: (
              <div key={index} className="pad-16">
                <UiContentBlock uiContentSlug={event.slug} />
              </div>
            ),
          };
        })) ||
      []
    );
  }, [scheduledSessions]);

  return (
    <Box>
      <Box className="show-mobile-only">
        <Accordion type="single" items={items} />
      </Box>
      <Box className="hide-on-mobile">
        {showBackButton && (
          <ContentContainer className="flex pad-bot-16">
            <span
              className="flex align-center cursor-pointer"
              onClick={() => router.back()}
            >
              <Icon icon="chevron-left" /> Back
            </span>
          </ContentContainer>
        )}
        <Box className="flex justify-center" style={{ minWidth: "100%" }}>
          <Tabs
            initialActiveIndex={0}
            tabListLabel="Connections tabs"
            tabListStyle={{
              margin: "0 auto",
              width: "100%",
              display: "flex",
              justifyContent: "space-around ",
              background: `${backgroundColor}`,
              outline: `1px solid ${backgroundColor}`,
              position: "sticky",
              top: token("sizes.secondaryNavHeight"),
              zIndex: 99,
            }}
            tabs={tabs}
          />
        </Box>
      </Box>
    </Box>
  );
};

const Tabs = styled(TabSwitcher)`
  ${contentContainerStyles}
  .trigger {
    height: 50px;
  }
`;

export const ScheduledSessionListView = (
  props: ScheduledSessionListBlockFragment & { className: string }
) => {
  const scheduledSessions: Array<EventSession> = props.scheduledSessions.map(
    (scheduledSession) =>
      assertFieldsNotNull(scheduledSession, [
        "slug",
        "icon",
        "description",
        "displayName",
        "longDescription",
      ])
  );
  return (
    <EventSessionList
      scheduledSessions={scheduledSessions}
      className={props.className}
      backgroundColor={props.backgroundColor?.hex}
    />
  );
};

export const ScheduledSessionListRenderer = renderer({
  typeName: "ScheduledSessionListRecord",
  view: ScheduledSessionListView,
});
