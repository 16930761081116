import Image from "next/image";
import { NewHeroBlockFragment } from "generated/graphql-typed";
import { Box, FocusPill, RechargePill, Text } from "design-system/components";
import { renderer } from "./renderer";
import { ModularContent } from "./modular-content";
import { PrimaryButton, SecondaryButton } from "legacy-design-system";
import { THEME_COLORS } from "design-system/emotion.theme";
import { MediaViewer, isVideo } from "design-system/molecules/media-viewer";
import { styled } from "styled-system/jsx";
import { token } from "styled-system/tokens";

const NewHeroContentView = (
  props: NewHeroBlockFragment & { className: string }
) => {
  const backgroundColor = props.backgroundColor?.hex || THEME_COLORS.black;
  const textColor = props.textColor?.hex || THEME_COLORS.white;
  const videoUrl = props.video?.video?.mp4High;
  const videoOrThumbnailUrl = videoUrl ?? props.videoThumbnail?.url;
  const maxImageWidth = props.maxImageWidth || 700;
  const autoPlay = props.videoAutoPlay || false;

  return (
    <NewHomeHeroWrapper
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
      }}
    >
      {props.backgroundImage && !isVideo(props.backgroundImage.url) && (
        <Image
          src={props.backgroundImage?.url || ""}
          fill
          priority
          style={{ objectFit: "cover" }}
          alt={props.backgroundImage?.alt || "Hero image"}
        />
      )}
      <ContentWrapper data-component={"ContentWrapper"}>
        <LeftContentWrapper data-component={"LeftContentWrapper"}>
          <div>
            {props.topText && (
              <SubText className="mar-y-16">{props.topText}</SubText>
            )}
            {props.pillType && (
              <Box className="mar-y-16">
                {props.pillType === "focus" ? <FocusPill /> : <RechargePill />}
              </Box>
            )}
            <Box style={{ lineHeight: props.lineHeight }}>
              <ModularContent
                data={props.mainHeading}
                paragraphSize={16}
                wrapper={HomeCardHeading}
                customClass="heading-adjust"
              />
            </Box>
            {props.paragraph && (
              <Box className="mar-y-16">
                <ModularContent
                  customClass="no-border"
                  data={props.paragraph}
                  paragraphSize={16}
                />
              </Box>
            )}
            <ButtonWrapper data-component={"ButtonWrapper"}>
              {props.buttonCta && props.buttonCtaLink && (
                <PrimaryButton
                  className="mar-right-8 w-full"
                  href={props.buttonCtaLink}
                >
                  {props.buttonCta}
                </PrimaryButton>
              )}
              {props.secondaryButtonCta && props.secondaryButtonCtaLink && (
                <SecondaryButton
                  className="w-full"
                  href={props.secondaryButtonCtaLink}
                >
                  {props.secondaryButtonCta}
                </SecondaryButton>
              )}
            </ButtonWrapper>
          </div>
        </LeftContentWrapper>
        <Box className="pos-relative">
          <VideoContainer
            data-component="VideoContainer"
            showCursor={!!videoUrl}
            style={{ maxWidth: maxImageWidth }}
          >
            {videoOrThumbnailUrl && (
              <VideoViewer
                alt={props.videoThumbnail?.alt || "Home video content content"}
                url={videoOrThumbnailUrl}
                showVideoControls={true}
                thumbnailUrl={props.videoThumbnail?.url}
                forceAutoPlay={autoPlay}
              />
            )}
          </VideoContainer>
        </Box>
      </ContentWrapper>
    </NewHomeHeroWrapper>
  );
};

const ButtonWrapper = styled("div", {
  base: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "16",
    "bp-tablet": {
      justifyContent: "left",
      flexDirection: "row",
    },
  },
});

const NewHomeHeroWrapper = styled("section", {
  base: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
  },
});

const ContentWrapper = styled("div", {
  base: {
    maxWidth: token("sizes.maxWidth.content"),
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    gap: "16",
    width: "100%",
    overflow: "hidden",
    margin: "0 auto",
    padding: "{spacing.32} 0",
    textAlign: "center",
    "bp-desktop-xs": {
      gridTemplateColumns: "1fr 1.2fr",
      gridTemplateRows: "1fr",
      gap: "32",
      textAlign: "left",
    },
  },
});

const LeftContentWrapper = styled("div", {
  base: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    zIndex: 3,
    padding: "0",
    maxWidth: "85vw",
    margin: "0 auto",
    "bp-desktop-xs": {
      justifySelf: "end",
      gap: "32",
      paddingLeft: "2.5rem",
      paddingBottom: "2.5rem",
      paddingRight: 0,
    },
  },
});

const VideoViewer = styled(MediaViewer, {
  base: {
    width: "100%",
    objectFit: "scale-down",
    borderRadius: "15px",
    maxHeight: "100%",
    justifyContent: "center",
  },
});

const VideoContainer = styled("div", {
  base: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    height: "100%",
    padding: "0",
    "bp-tablet": {
      padding: "16",
    },
    "& .vjs-poster": {
      backgroundColor: "transparent",
    },
    "& .video-js .vjs-tech": {
      backgroundColor: "transparent",
    },
  },
  variants: {
    showCursor: {
      true: {
        cursor: "pointer",
      },
    },
  },
});

const HomeCardHeading = styled(Box, {
  base: {
    "& p": {
      fontSize: "2xl",
      fontWeight: "bold",
      lineHeight: "1.0",
      "& em": {
        fontFamily: "centuryOldStyleStd",
      },
    },
    "bp-mobile": {
      "& p": {
        fontSize: "4xl",
      },
    },
    "bp-desktop-xs": {
      "& p": {
        fontSize: "6xl",
        "& em": {
          lineHeight: "0.7",
          fontSize: "4.5rem",
        },
      },
    },
    "bp-desktop-md": {
      "& p": {
        fontSize: "4.25rem",
        "& em": {
          lineHeight: "0.6",
          fontSize: "6rem",
        },
      },
    },
  },
});

const SubText = styled(Text, {
  base: {
    fontSize: "md",
    "bp-tablet": {
      fontSize: "xl",
    },
  },
});

export const NewHeroContentRenderer = renderer({
  typeName: "NewHeroBlockRecord",
  view: NewHeroContentView,
});
