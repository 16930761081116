import dayjs from "dayjs";
import { FlownEvent, FlownEvents } from "shared/event.types";
import { DROP_IN_ID } from "utils/constants";

const SECOND_TO_ADD = 1;

// We were sometimes double amending dates for drop-ins when we
// opened them in a sidepanel. When we first amend we add 1s to
// the start of the day and use this to check if we have already altered the time
const isEventAmended = (event: FlownEvent) =>
  dayjs(event.startTime).second() === SECOND_TO_ADD;

const amendDropInDate = (event: FlownEvent) => {
  if (event.id.includes(DROP_IN_ID) && !isEventAmended(event)) {
    // We need to add 2 hours to the start time of the event in case of daylight savings
    // we just care what day in the UK the event is scheduled for and then use that day
    // to set the start and end day in the users timezone
    const dayDropInIsScheduled = dayjs
      .tz(event.startTime, "Europe/London")
      .add(2, "hours")
      .format("YYYY-MM-DD");

    return {
      ...event,
      startTime: dayjs(dayDropInIsScheduled)
        .startOf("day")
        .add(SECOND_TO_ADD, "second")
        .toISOString(),
      endTime: dayjs(event.endTime).toISOString(),
    };
  } else {
    return event;
  }
};

const sortEvents =
  (userTimezone: string = "Europe/London") =>
  (a: FlownEvent, b: FlownEvent) => {
    const aStartTime = dayjs(a.startTime, userTimezone);
    const bStartTime = dayjs(b.startTime, userTimezone);

    const aEndTime = dayjs(a.endTime, userTimezone);
    const bEndTime = dayjs(b.endTime, userTimezone);

    const timeDiff = aStartTime.diff(bStartTime, "minutes");
    if (timeDiff !== 0) {
      return timeDiff;
    }

    // When start times are the same, sort by duration
    const aDuration = aEndTime.diff(aStartTime, "minutes");
    const bDuration = bEndTime.diff(bStartTime, "minutes");

    // Note: this will put longer events first
    return bDuration - aDuration;
  };

export const amendDropInDateAndSortEvents = (
  events: FlownEvents,
  userTimezone?: string
) => events.map(amendDropInDate).sort(sortEvents(userTimezone)) as FlownEvents;
