import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  AddCreditRequest,
  AddCreditResponse,
  CheckCreditsForUserRequest,
  CheckCreditsForUserResponse,
  ConsumeCreditForUserRequest,
  ConsumeCreditForUserResponse,
  RemoveCreditResponse,
} from "../shared/credits-types";

export const postToAddCreditToUser = createPostEndpoint<
  AddCreditRequest,
  AddCreditResponse
>(`/api/credits/add`);

export const postToRemoveCredit = (creditId: string) =>
  createPostEndpoint<null, RemoveCreditResponse>(
    `/api/credits/remove/${creditId}`
  );

export const postToCheckValidCreditsForUser = createPostEndpoint<
  CheckCreditsForUserRequest,
  CheckCreditsForUserResponse
>("/api/credits/check");

export const postToConsumeCreditForUser = createPostEndpoint<
  ConsumeCreditForUserRequest,
  ConsumeCreditForUserResponse
>("/api/credits/consume");
