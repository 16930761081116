import { TrustpilotBlockFragment } from "generated/graphql-typed";
import React from "react";
import { renderer } from "./renderer";
import { Trustpilot } from "features/trustpilot/client";
import { assertFieldsNotNull } from "utils/assertions";

// For data structure and list of template IDs,
// see https://businessapp.b2b.trustpilot.com/trustbox/
const TrustpilotView = (
  props: TrustpilotBlockFragment & { className: string }
) => {
  const { templateId, height, widgetWidth, theme } = assertFieldsNotNull(
    props,
    ["templateId", "height", "widgetWidth", "theme"]
  );
  return (
    <Trustpilot
      templateId={templateId}
      height={height}
      widgetWidth={widgetWidth}
      theme={theme}
      className={props.className}
    />
  );
};
export const TrustpilotBlockRenderer = renderer({
  typeName: "TrustpilotBlockRecord",
  view: TrustpilotView,
});
