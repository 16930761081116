"use client";
import styled from "@emotion/styled";
import { Icon } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { THEME_COLORS } from "design-system/emotion.theme";
import { forwardRef, InputHTMLAttributes, useState } from "react";
import { Box } from "./box";
import { InputSizes, inputStyles } from "./shared/form-styles";
import { css } from "styled-system/css";
import { MAX_PASSWORD_LENGTH } from "utils/assertions";

export type InputProps = InputHTMLAttributes<HTMLElement> &
  DSComponentPropsProvider & {
    full?: boolean;
    inputSize?: InputSizes;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className = "", full, inputSize = "medium", ...props }, ref) => {
    return (
      <StyledInput
        ref={ref}
        className={`${className} ${full ? "full" : ""} ${
          inputSize ? inputSize : ""
        }`}
        {...props}
      />
    );
  }
);

export const PasswordInput = (props: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Box className="pos-relative flex align-center">
      <Input
        {...props}
        type={isPasswordVisible ? "text" : "password"}
        placeholder="********"
        maxLength={MAX_PASSWORD_LENGTH}
      />
      <Icon
        className={`cursor-pointer ${css({
          position: "absolute",
          right: "1rem",
        })}`}
        icon="eye-off"
        color={isPasswordVisible ? THEME_COLORS.grey200 : "black"}
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
      />
    </Box>
  );
};

export const PlainInput = styled(Input)(() => ({
  border: "none",
  outline: "none",
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:focus": {
    border: `none`,
    boxShadow: "none",
    outline: "none",
  },
  "&:active": {
    outline: "none",
    border: `none`,
  },
}));

const StyledInput = styled.input(({ theme }) => ({
  ...inputStyles(theme),
}));
