import { MediaViewer } from "design-system/molecules/media-viewer";
import { ColumnLayoutBlockFragment } from "generated/graphql-typed";
import { ModularContent } from "./modular-content";
import { renderer } from "./renderer";
import Link from "next/link";
import { styled } from "styled-system/jsx";

const ColumnLayoutView = (
  props: ColumnLayoutBlockFragment & { className: string }
) => {
  const directionClass = props.direction || "left-to-right";
  const layout = props.layout || "1-1";
  const alignColumn = "justify-" + (props.alignColumnItems || "center");

  const FirstColumnMediaViewer = props.firstColumnMedia ? (
    <ColumnMediaViewer
      url={props.firstColumnMedia.video?.mp4Low || props.firstColumnMedia.url}
      alt={props.firstColumnMedia.alt || "First column media"}
      showVideoControls={props.firstColumnShowVideoControls}
      thumbnailUrl={props.firstColumnThumbnail?.url}
      forceAutoPlay={props.forceAutoPlay}
      className={props.alignColumnItems ? `flex ${alignColumn}` : undefined}
    />
  ) : null;

  const SecondColumnMediaViewer = props.secondColumnMedia ? (
    <ColumnMediaViewer
      url={props.secondColumnMedia.video?.mp4Low || props.secondColumnMedia.url}
      alt={props.secondColumnMedia.alt || "Second column media"}
      showVideoControls={props.secondColumnShowVideoControls}
      thumbnailUrl={props.secondColumnThumbnail?.url}
      forceAutoPlay={props.forceAutoPlay}
      className={props.alignColumnItems ? `flex ${alignColumn}` : undefined}
    />
  ) : null;

  const firstColumnBackgroundStyles = props.firstColumnBackground
    ? {
        background: props.firstColumnBackground.hex,
        padding: "1rem",
      }
    : {};

  const secondColumnBackgroundStyles = props.secondColumnBackground
    ? {
        background: props.secondColumnBackground.hex,
        padding: "1rem",
      }
    : {};

  return (
    <Columns
      data-component="Columns"
      className={[props.className, directionClass, `layout-${layout}`].join(
        " "
      )}
      style={{
        color: props.textColor?.hex || "black",
        gap: props.columnGap,
      }}
    >
      <Column
        data-component="Column-first"
        className={`${directionClass} ${alignColumn} ${
          props.firstColumnHasRoundedBorder ? "b-radius-16" : ""
        }`}
        style={firstColumnBackgroundStyles}
      >
        {props.firstColumnMediaClickUrl ? (
          <Link
            href={props.firstColumnMediaClickUrl}
            className={
              props.alignColumnItems
                ? `flex-col align-${props.alignColumnItems || "center"}`
                : undefined
            }
          >
            {FirstColumnMediaViewer}
          </Link>
        ) : (
          FirstColumnMediaViewer
        )}
        <ModularContent
          data={props.firstColumnText}
          paragraphSize={props.paragraphSize}
          wrapper={TextContainer}
          wrapperClassName={
            props.firstColumnMedia ? "is-below-media" : undefined
          }
          alignmentOfText={props.alignColumnItems}
        />
      </Column>
      <Column
        className={`${directionClass} ${alignColumn} ${
          props.secondColumnHasRoundedBorder ? "b-radius-16" : ""
        }`}
        data-component="Column-second"
        style={secondColumnBackgroundStyles}
      >
        {props.secondColumnMediaClickUrl ? (
          <Link href={props.secondColumnMediaClickUrl}>
            {SecondColumnMediaViewer}
          </Link>
        ) : (
          SecondColumnMediaViewer
        )}
        <ModularContent
          data={props.secondColumnText}
          paragraphSize={props.paragraphSize}
          wrapper={TextContainer}
          wrapperClassName={
            props.secondColumnMedia ? "is-below-media" : undefined
          }
          alignmentOfText={props.alignColumnItems}
        />
      </Column>
    </Columns>
  );
};

export const ColumnLayoutRenderer = renderer({
  typeName: "ColumnLayoutRecord",
  view: ColumnLayoutView,
});

const Column = styled("div", {
  base: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Columns = styled("section", {
  base: {
    display: "grid",
    position: "relative",
    width: "100%",
    maxWidth: "var(--content-container-width, 1100px)",
    padding: "0 {spacing.16}",
    gap: "16",
    "bp-tablet": {
      margin: "0 auto",
      gridTemplateColumns: "1fr 1fr",
      justifyItems: "center",

      "&.layout-2-1": {
        gridTemplateColumns: "2fr 1fr",
      },
      "&.layout-1-2": {
        gridTemplateColumns: "1fr 2fr",
      },
      "&.right-to-left": {
        gridAutoFlow: "dense",
        "& div:nth-of-type(1)": {
          order: 1,
        },
      },
    },
  },
});

const ColumnMediaViewer = styled(MediaViewer, {
  base: {
    flex: "0",
    objectFit: "cover",
    width: "100%", // https://stackoverflow.com/questions/60192601/object-fit-cover-not-work,
    "bp-tablet": {
      maxWidth: "calc(100% - 40px)",
    },
  },
});

const TextContainer = styled("div", {
  base: {
    width: "100%",
    textAlign: "left",

    "&.is-below-media": {
      marginTop: "20px",
    },
  },
});
