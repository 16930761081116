import React, { CSSProperties, ReactNode } from "react";
import {
  Provider,
  Root,
  Trigger,
  Portal,
  Content,
  Arrow,
  TooltipProps as RadixTooltipProps,
} from "@radix-ui/react-tooltip";
import styled from "@emotion/styled";
import { commonStyles } from "./shared/tooltip-and-popover";
import { css } from "styled-system/css";

type TooltipProps = {
  children: ReactNode;
  delayDuration?: number;
  /**
   * @param triggerElement Must be an HTML tag, React components will not render the popover correctly. If needed, wrap the component in a div or a span.
   */
  triggerElement: ReactNode;
  triggerWrapperStyle?: CSSProperties;
  triggerAsChild?: boolean; // Use this if you do not want to wrap the triggerElement in a button - e.g. when using motion.div directly
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
};

export const Tooltip = ({
  children,
  delayDuration = 200,
  triggerElement,
  triggerWrapperStyle,
  triggerAsChild = false,
  side = "top",
  sideOffset = 5,
  ...props
}: TooltipProps & RadixTooltipProps) => {
  return (
    <Provider>
      <Root delayDuration={delayDuration} {...props}>
        <Trigger
          className={css({
            cursor: "default",
            "&:focus": { boxShadow: "none", outline: "none" },
          })}
          style={triggerWrapperStyle || {}}
          asChild={triggerAsChild}
        >
          {triggerElement}
        </Trigger>
        <Portal>
          <StyledContent sideOffset={sideOffset} side={side}>
            {children}
            <Arrow
              style={{
                fill: "white",
              }}
            />
          </StyledContent>
        </Portal>
      </Root>
    </Provider>
  );
};

const StyledContent = styled(Content)(({ theme }) => ({
  ...commonStyles(theme),
}));
