import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import { Category } from "utils/categories-utils";
import { useNavigate } from "utils/component-utils";
import { BLOG_URL } from "utils/urls";
import { ContentSection } from "../shared/pillars-and-blog-types";

type TableOfContentProps = {
  title?: string;
  sections?: ContentSection[];
  categories?: Category[];
};

export const TableOfContent: FC<TableOfContentProps> = ({
  sections,
  categories,
  title,
}) => {
  const navigate = useNavigate();
  const { asPath } = useRouter();

  const [activeSection, setActiveSection] = useState<string>(
    sections && sections.length > 0 ? sections[0].id : ""
  );
  const [visibleList, setVisibleList] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string>();

  useEffect(() => {
    const hash = asPath.split("#")[1];

    if (hash) setActiveSection(hash);
    setActiveCategory(asPath.split("/").pop());
  }, [asPath]);

  useEffect(() => {
    const domElements = document.getElementsByClassName("navigation-element");

    if (domElements.length > 1) {
      const timer = setInterval(() => {
        const allWithClass = Array.from(domElements);

        const activeHeading = allWithClass.reduce((prev, curr) =>
          Math.abs(curr.getBoundingClientRect().y) <
          Math.abs(prev.getBoundingClientRect().y)
            ? curr
            : prev
        );

        if (activeHeading?.id) setActiveSection(`#${activeHeading?.id}`);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, []);

  const handleSegmentClick = (id: string) => {
    setActiveSection(id);
    window.location.hash = id;
  };

  const visibleListHandler = () => {
    setVisibleList(!visibleList);
  };

  return (
    <TableOfContentWrapper>
      <Title
        className={visibleList ? "open" : "closed"}
        onClick={() => visibleListHandler()}
      >
        {!title && categories ? "Categories" : title}
      </Title>
      <List className={visibleList ? "" : "hidden"}>
        {sections &&
          sections.map((section) => (
            <TableOfContentLink
              key={section.id}
              className={activeSection === section.id ? "active" : ""}
              onClick={() => handleSegmentClick(section.id)}
            >
              {section.title}
            </TableOfContentLink>
          ))}
        {categories &&
          categories.map((category) => (
            <TableOfContentLink
              key={category.slug}
              className={activeCategory === category.slug ? "active" : ""}
              onClick={() => {
                navigate(`${BLOG_URL}/${category.slug}`);
                visibleListHandler();
              }}
            >
              {category.categoryName}
            </TableOfContentLink>
          ))}
      </List>
    </TableOfContentWrapper>
  );
};

const TableOfContentWrapper = styled.div`
  width: 100vw;
  flex-grow: unset;
  align-items: start;
  background-color: ${({ theme }) => theme.colors["grey100"]};
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    background-color: unset;
    width: 100%;
  }
`;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  font-weight: ${({ theme }) => theme.fontWeights["bold"]};
  padding: ${({ theme }) => theme.spacing["16"]};
  text-align: start;
  &.open {
    :after {
      content: "";
      float: right;
      margin-right: 18px;
      margin-top: 12px;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      height: 8px;
      width: 8px;
      transform: rotate(-135deg);
    }
  }
  &.closed {
    :after {
      content: "";
      float: right;
      margin-right: 18px;
      margin-top: 6px;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      height: 8px;
      width: 8px;
      transform: rotate(45deg);
    }
  }

  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    margin-top: ${({ theme }) => theme.spacing["52"]};
    padding: 0;
    :after {
      display: none;
    }
  }
`;

const TableOfContentLink = styled.li`
  list-style: none;
  margin: ${({ theme }) => theme.spacing["32"]};
  cursor: pointer;
  &.active {
    font-weight: ${({ theme }) => theme.fontWeights["bold"]};
    border-left: 6px solid ${({ theme }) => theme.colors["focusDark"]};
    padding-left: ${({ theme }) => theme.spacing["8"]};
    margin-left: ${({ theme }) => theme.spacing["16"]};
  }
  &:hover {
    color: ${({ theme }) => theme.colors["focusDark"]};
  }
  text-transform: capitalize;
  text-align: start;
`;

const List = styled.ul`
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors["grey100"]};
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    position: relative;
    background-color: unset;
  }
  &.hidden {
    display: none;
    ${({ theme }) => theme.media["bp-desktop-xs"]} {
      display: block;
    }
  }
`;
