import { ContentContainer } from "legacy-design-system";
import { ToggleBlockFragment } from "generated/graphql-typed";
import React, { useState } from "react";
import { renderer } from "./renderer";
import styled from "@emotion/styled";
import { ModularContent } from "./modular-content";
import { HasChildren } from "utils/component-utils";

const ToggleViewGroupWrapper = (props: HasChildren & { className: string }) => (
  <ToggleGroup className={props.className}>{props.children}</ToggleGroup>
);
const ToggleGroup = styled.div`
  margin-top: 20px;
`;

const ToggleView = (props: ToggleBlockFragment & { className: string }) => {
  const [showContent, setShowContent] = useState(false);

  return (
    <ToggleContainer>
      <ToggleButton
        onClick={() => {
          setShowContent(!showContent);
        }}
      >
        <ToggleIcon
          src={"/assets/toggle/arrow-icon.svg"}
          className={showContent ? "show" : undefined}
        />
        {props.title}
      </ToggleButton>
      <ToggleContent className={showContent ? "show" : undefined}>
        <ModularContent
          data={props.content}
          paragraphSize={props.paragraphSize}
        />
      </ToggleContent>
    </ToggleContainer>
  );
};

export const ToggleRenderer = renderer({
  typeName: "ToggleRecord",
  view: ToggleView,
  groupComponent: ToggleViewGroupWrapper,
});

const ToggleButton = styled.button`
  outline: none;
  font-weight: bold;
`;

const ToggleIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 20px;
  transition: transform 0.4s;
  &.show {
    transform: rotateZ(90deg);
  }
`;

const ToggleContent = styled.div`
  display: none;
  &.show {
    margin-top: 10px;
    padding-left: 30px;
    display: block;
  }
`;

const ToggleContainer = styled(ContentContainer)`
  margin-bottom: 20px;
`;
