import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  UserAgent,
  UserAgentProvider,
  parseUserAgent,
} from "utils/platform-utils";

type ParsedUserAgentContextValue = UserAgentProvider;

const ParsedUserAgentContext =
  createContext<ParsedUserAgentContextValue | null>(null);

// TODO create a "ChildrenProvider" or "WithChildren" type abstraction
export const ParsedUserAgentProvider: React.FC<
  { children: React.ReactNode } & UserAgentProvider
> = ({ children, parsedUserAgent }) => {
  const [parsedUserAgentState, setParsedUserAgentState] =
    useState<UserAgent>(parsedUserAgent);

  useEffect(() => {
    if (!parsedUserAgentState && window) {
      setParsedUserAgentState(parseUserAgent(window.navigator.userAgent));
    }
  }, [parsedUserAgentState]);

  const value = useMemo(
    () => ({
      parsedUserAgent: parsedUserAgentState,
    }),
    [parsedUserAgentState]
  ) as ParsedUserAgentContextValue;
  return (
    <ParsedUserAgentContext.Provider value={value}>
      {children}
    </ParsedUserAgentContext.Provider>
  );
};

export const useParsedUserAgent = () => useContext(ParsedUserAgentContext);
