import styled from "@emotion/styled";
import Link from "next/link";

export type BreadcrumbProps = {
  name: string;
  href: string;
};

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbProps[];
};

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <BreadcrumbItems aria-label="breadcrumb">
      {breadcrumbs.map(({ name, href }, index) => {
        const isLastChild = index === breadcrumbs.length - 1;

        return (
          <li key={`${name}-${index}`}>
            <Link href={href}>
              <Item
                className={isLastChild ? "current" : ""}
                aria-current={isLastChild ? "page" : "false"}
              >
                {name}
                {!isLastChild && <span className="mar-x-8">/</span>}
              </Item>
            </Link>
          </li>
        );
      })}
    </BreadcrumbItems>
  );
};

const BreadcrumbItems = styled.ol`
  display: flex;
  list-style: none;
`;

const Item = styled.div(({ theme }) => ({
  color: theme.colors["grey300"],
  "&.current": {
    cursor: "default",
    color: theme.colors["black"],
  },
  "&:last-child": {
    display: "none",
    [theme.media["bp-desktop-xs"]]: {
      display: "block",
    },
  },
}));
