import styled from "@emotion/styled";
import { Box, Heading, MetaHeader } from "design-system/components";
import { ContentCard } from "design-system/molecules/cards/content-card";
import { FC, useCallback } from "react";
import { assertFieldsNotNull } from "utils/assertions";
import { useNavigate } from "utils/component-utils";
import { slugify } from "utils/string-utils";
import { getTagsDisplayNameArray } from "utils/tag-utils";
import { MainContent } from "../components/main-content";
import { Sidebar } from "../components/sidebar";
import {
  BlogIntroContent,
  BlogPostMetadata,
  BlogPostMediaThumbnail,
  PillarAndBlogContentProps,
  BlogPost,
} from "../shared/pillars-and-blog-types";
import { BreadcrumbsFromRoute } from "design-system/molecules/breadcrumbs/breadcrumbs-from-route";
import { ModularContent } from "features/modular-content";
import { BLOG_URL } from "utils/urls";
import Link from "next/link";
import { Icon } from "design-system/components";
import { css } from "styled-system/css";

type PillarAndBlogPageProps = {
  title: string;
  mainPageContent: PillarAndBlogContentProps;
  relatedContent?: BlogPost[];
  introContent?: BlogIntroContent;
  mediaThumbnail?: BlogPostMediaThumbnail;
  metadata?: BlogPostMetadata;
  blogAdvert?: BlogPost["blogAdvert"];
};

type ChildProps = {
  type: string;
  level: number;
  children: { value: string }[];
};

export const PillarAndBlogPage: FC<PillarAndBlogPageProps> = ({
  mainPageContent,
  mediaThumbnail,
  metadata,
  relatedContent,
  introContent,
  title: currentPostTitle,
  blogAdvert,
}) => {
  const navigate = useNavigate();

  const getSectionsFromContent = useCallback(() => {
    if (!mainPageContent) return [];
    const sections = mainPageContent.value?.document?.children
      ?.flatMap((child: ChildProps) => {
        if (
          child.type === "heading" &&
          child.level === 2 &&
          child.children[0].value
        ) {
          return {
            title: child.children[0].value,
            id: `#${slugify(child.children[0].value)}`,
          };
        }
      })
      .filter((section: ChildProps) => section !== undefined);
    return sections;
  }, [mainPageContent]);

  const nextBlogPost = relatedContent?.find(
    (post) => post.title !== currentPostTitle
  );

  return (
    <>
      {metadata ? (
        <ModularContent data={metadata} paragraphSize={null} />
      ) : (
        <MetaHeader
          title={currentPostTitle}
          imageUrlParam={mediaThumbnail?.url}
          author={introContent?.author?.name}
        />
      )}
      <PillarAndBlogPageWrapper>
        <Box
          className="flex justify-between"
          style={{
            alignItems: "baseline",
          }}
        >
          <Box
            className={css({
              margin: "16",
              "bp-desktop-xs": {
                marginTop: 0,
                marginX: "36",
                marginBottom: "36",
              },
            })}
          >
            <BreadcrumbsFromRoute />
          </Box>
          {nextBlogPost && (
            <Link
              href={`${BLOG_URL}/${nextBlogPost.category.slug}/${nextBlogPost.slug}`}
              className="flex align-center"
            >
              Next post : {nextBlogPost.title} <Icon icon="chevron-right" />
            </Link>
          )}
        </Box>

        <ContentWrapper>
          <Sidebar
            title={currentPostTitle}
            sections={getSectionsFromContent()}
            showSocialShareOnMobile={false}
            blogAdvert={blogAdvert}
          />
          <MainContent
            mainPageContent={mainPageContent}
            introContent={introContent}
          />
        </ContentWrapper>
        {relatedContent && relatedContent.length > 0 && (
          <RelatedPostsWrapper>
            <Header as="h3">You might like these too</Header>
            <RelatedPagesContainer>
              {relatedContent.map((blog, index) => {
                const { author, mediaThumbnail, tags, title, slug, category } =
                  assertFieldsNotNull(blog, [
                    "author",
                    "mediaThumbnail",
                    "tags",
                    "title",
                    "slug",
                    "category",
                  ]);

                const typedAuthor = assertFieldsNotNull(author, [
                  "name",
                  "slug",
                ]);
                const { slug: categorySlug } = assertFieldsNotNull(category, [
                  "slug",
                ]);
                if (title === currentPostTitle) return null;

                return (
                  <ContentCard
                    key={index}
                    title={title}
                    tags={getTagsDisplayNameArray(tags)}
                    author={typedAuthor}
                    onCardClick={() => {
                      navigate(`${BLOG_URL}/${categorySlug}/${slug}`);
                    }}
                    mediaUrl={mediaThumbnail.url}
                    publishDate={blog.publishedAt}
                    previewDescription={blog.previewDescription}
                  />
                );
              })}
            </RelatedPagesContainer>
          </RelatedPostsWrapper>
        )}
      </PillarAndBlogPageWrapper>
    </>
  );
};

const PillarAndBlogPageWrapper = styled.div(({ theme }) => ({
  [theme.media["bp-desktop-xs"]]: {
    padding: theme.spacing[24],
    paddingTop: theme.spacing[32],
  },
}));

const ContentWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.media["bp-desktop-xs"]]: {
    flexDirection: `row`,
    gap: theme.spacing[36],
  },
}));

const RelatedPostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[16]};
  align-content: center;
  justify-content: center;
  text-align: center;
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    padding: ${({ theme }) => theme.spacing[80]};
  }
`;

const RelatedPagesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[32]};
  margin-top: ${({ theme }) => theme.spacing[32]};
`;

const Header = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes["4xl"]};
  text-align: start;
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    text-align: center;
  }
`;
