import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FollowUser } from "shared/user.types";
import { useUserContext } from "./user-context";
import { getUserConnectionsData } from "features/profile/client";
import { WorkedOrError } from "shared/response.types";
import { markFollowState } from "./user-context/user.state";

type ConnectionsContextState = {
  followers: FollowUser[];
  following: FollowUser[];
};

type ConnectionsContextValue = ConnectionsContextState & {
  onFollow: (user: FollowUser) => Promise<WorkedOrError>;
  onUnfollow: (user: FollowUser) => Promise<WorkedOrError>;
  setExternalId: (externalId: string) => void;
};
const ConnectionsContext = createContext({} as ConnectionsContextValue);

type ConnectionsContextProviderType = {
  children: ReactNode;
};

export const ConnectionsContextProvider = ({
  children,
}: ConnectionsContextProviderType) => {
  const [connectionsState, setConnectionsState] =
    useState<ConnectionsContextState>({
      followers: [],
      following: [],
    });
  const {
    onFollow,
    onUnfollow,
    externalId: viewerExternalId,
    following: viewerFollowing,
  } = useUserContext();
  const [externalIdState, setExternalIdState] = useState<string>("");
  const fetchConnections = useCallback(async () => {
    if (!externalIdState) return;
    const userConnectionsRes = await getUserConnectionsData(externalIdState);
    if (userConnectionsRes.worked) {
      setConnectionsState({
        followers: userConnectionsRes.followers.map(
          markFollowState(viewerFollowing, viewerExternalId)
        ),
        following: userConnectionsRes.following.map(
          markFollowState(viewerFollowing, viewerExternalId)
        ),
      });
    }
    // Only run this when externalIdState changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalIdState, viewerFollowing]);

  const setExternalId = useCallback((externalId: string) => {
    setExternalIdState(externalId);
  }, []);

  useEffect(() => {
    void fetchConnections();
  }, [fetchConnections]);

  const onFollowUser = useCallback(
    async (followedUser: FollowUser) => {
      setConnectionsState((prevState) => ({
        followers: prevState.followers.map((follower) => ({
          ...follower,
          isFollowed:
            follower.externalId === followedUser.externalId
              ? true
              : follower.isFollowed,
        })),
        following: prevState.following.map((following) => ({
          ...following,
          isFollowed:
            following.externalId === followedUser.externalId
              ? true
              : following.isFollowed,
        })),
      }));
      return onFollow(followedUser);
    },
    [onFollow]
  );

  const onUnfollowUser = useCallback(
    async (unfollowedUser: FollowUser) => {
      setConnectionsState((prevState) => ({
        followers: prevState.followers.map((follower) => ({
          ...follower,
          isFollowed:
            follower.externalId === unfollowedUser.externalId
              ? false
              : follower.isFollowed,
        })),
        following: prevState.following.map((following) => ({
          ...following,
          isFollowed:
            following.externalId === unfollowedUser.externalId
              ? false
              : following.isFollowed,
        })),
      }));
      return onUnfollow(unfollowedUser);
    },
    [onUnfollow]
  );

  const value = useMemo(
    () => ({
      ...connectionsState,
      onFollow: onFollowUser,
      onUnfollow: onUnfollowUser,
      setExternalId,
    }),
    [connectionsState, onFollowUser, onUnfollowUser, setExternalId]
  );
  return (
    <ConnectionsContext.Provider value={value}>
      {children}
    </ConnectionsContext.Provider>
  );
};

export const useConnectionsContext = () => useContext(ConnectionsContext);
