import { Box } from "design-system/components";
import Link from "next/link";
import { CALENDAR_FEATURED_URL } from "utils/urls";
import { MilestoneBadge } from "../../shared/badge-types";
import { Badge } from "./badge";

type BadgeListProps = {
  badges: MilestoneBadge[];
  badgeDimensions?: number;
  showBadgeMetadata?: boolean;
};

export const BadgeList: React.FC<BadgeListProps> = ({
  badges,
  badgeDimensions,
  showBadgeMetadata = false,
}) => {
  return (
    <Box className="flex-row gap-24 flex-wrap">
      {badges.length === 0 ? (
        <div>
          You currently have no badges.{" "}
          <Link href={CALENDAR_FEATURED_URL}>
            <span className={`decor-underline cursor-pointer`}>
              Join your first flock to get one!
            </span>
          </Link>
        </div>
      ) : (
        <>
          {badges.map((badge, index) => (
            <Badge
              key={`${badge.title}-${index}`}
              badge={badge}
              dimensions={badgeDimensions}
              showMetadata={showBadgeMetadata}
            />
          ))}
        </>
      )}
    </Box>
  );
};
