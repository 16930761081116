import styled from "@emotion/styled";
import React from "react";
import { useSidePanel } from "context/side-panel-context";
import { Badge } from "features/badges/client";
import { MilestoneBadge } from "features/badges/shared";
import { Box, Text, Button, Row } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { Avatar } from "../avatar";
import { getUserDisplayName } from "features/profile/shared";
import {
  UserOnlineStatus,
  UserProfileFromActivityNoId,
} from "shared/user.types";
import {
  getIsOnline,
  onlinePageText,
} from "design-system/hooks/use-online-connections";

type UserCardProps = {
  avatarUrl?: string;
  displayName: string;
  onlineStatus?: UserOnlineStatus;
  externalId: string;
  latestBadge?: MilestoneBadge | null;
  oneLiner?: string;
  location?: string;
  size?: "small" | "medium";
  onClick?: () => void; // Pass onClick to override default behavior
} & DSComponentPropsProvider;

export const UserCard: React.FC<UserCardProps> = ({
  avatarUrl,
  className = "",
  displayName,
  onlineStatus,
  externalId,
  latestBadge,
  oneLiner,
  location,
  size,
  onClick,
  ...props
}) => {
  const { showProfileForUserId } = useSidePanel();
  const isSmall = size === "small";

  const onCardWrapperClicked = () => {
    if (onClick) {
      onClick();
      return;
    }
    showProfileForUserId(externalId);
  };
  return (
    <UserCardWrapper
      className={`clickable ${isSmall ? "gap-8" : "gap-16"} ${className}`}
      onClick={onCardWrapperClicked}
      {...props}
    >
      <Avatar
        key={externalId}
        userFullName={displayName}
        imageUrl={avatarUrl}
        dimensions={isSmall ? 24 : undefined}
        onlineStatus={onlineStatus}
      />
      <Box className="flex-1">
        <Row>
          <Text className="text-left" fontSize={isSmall ? "sm" : "md"}>
            {displayName}
          </Text>
        </Row>
        <Text fontSize="xs" color="grey400">
          {getIsOnline(onlineStatus) &&
          onlineStatus?.currentPageDisplayName !== ""
            ? onlinePageText(onlineStatus)
            : [oneLiner, location].filter(Boolean).join(" • ")}
        </Text>
      </Box>
      {latestBadge && (
        <Badge badge={latestBadge} dimensions={isSmall ? 24 : undefined} />
      )}
    </UserCardWrapper>
  );
};

const UserCardWrapper = styled.div`
  border-radius: ${({ theme }) => theme.radii[12]};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]};

  &.clickable:hover {
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    background: ${({ theme }) => theme.colors.blueGrey100};
  }
`;

type UserInviteCardProps = {
  user: UserProfileFromActivityNoId;
  onCtaClick: (user: UserProfileFromActivityNoId) => void;
  ctaText: string;
};

export const UserCardWithCta: React.FC<UserInviteCardProps> = ({
  user,
  onCtaClick,
  ctaText,
}) => {
  return (
    <Box className="flex align-center border-bot-grey100 pad-y-8 w-full">
      <UserCard
        className="flex-1"
        displayName={getUserDisplayName(user)}
        oneLiner={user.oneLiner}
        {...user}
      />
      <Button
        variant="secondary"
        size="small"
        onClick={() => onCtaClick(user)}
        className="mar-x-8"
      >
        {ctaText}
      </Button>
    </Box>
  );
};
