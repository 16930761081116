import styled from "@emotion/styled";
import dayjs from "dayjs";
import { Pill } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { FC } from "react";
import { Text } from "../../components/text";

export type ContentCardProps = DSComponentPropsProvider & {
  title: string;
  onCardClick: () => void;
  mediaUrl: string;
  publishDate?: string;
  tags?: string[];
  author?: {
    name: string;
    slug: string;
  };
  previewDescription?: string | null;
  imageHeightOverride?: string;
};

/**
 * @description The ContentCard component is a card that displays a piece of content.
 * This is currently used for the blog and pillar pages.
 */
export const ContentCard: FC<ContentCardProps> = ({
  title,
  tags,
  author,
  mediaUrl,
  publishDate,
  previewDescription,
  onCardClick,
  imageHeightOverride,
  className = "",
}) => {
  return (
    <ContentCardContainer className={className} onClick={onCardClick}>
      <ImageContainer imageHeight={imageHeightOverride}>
        <PosterImage src={mediaUrl} />
      </ImageContainer>
      <div className="mar-16">
        {tags && (
          <div className="flex gap-16" style={{ flexWrap: "wrap" }}>
            {tags.map((tag, index) => (
              <Pill key={`${tag}-${index}`} backgroundColor={"blueGrey100"}>
                {tag}
              </Pill>
            ))}
          </div>
        )}
        <Text className="mar-y-16" fontSize="md" color="black" fontWeight={700}>
          {title}
        </Text>
        {previewDescription && (
          <Text
            className="mar-y-16"
            fontSize="md"
            color="grey300"
            fontWeight={400}
          >
            {previewDescription}
          </Text>
        )}
        <div className="flex align-center gap-16">
          {author && (
            <>
              <Text fontSize="sm" color="grey300" fontWeight={700}>
                <em>By {author.name}</em>
              </Text>
              {" • "}
            </>
          )}
          {publishDate && (
            <Text fontSize="sm" color="grey300" fontWeight={700}>
              {dayjs(publishDate || dayjs()).format("MMM DD, YYYY")}
            </Text>
          )}
        </div>
      </div>
    </ContentCardContainer>
  );
};

const ContentCardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["grey100"]};
  box-sizing: border-box;
  border-radius: 1rem;
  margin: 10px;
  cursor: pointer;
  width: 21.375rem;
  text-align: start;
  height: fit-content;
  max-width: 300px;
  :hover {
    border: 1px solid ${({ theme }) => theme.colors["focusDark"]};
  }
  ${({ theme }) => theme.media["bp-tablet"]} {
    width: 24.375rem;
    max-width: unset;
  }
`;

const ImageContainer = styled.div<{ imageHeight?: string }>`
  width: 100%;
  height: ${(props) => (props.imageHeight ? props.imageHeight : "16.25rem")};
  position: relative;
`;

const PosterImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => `${theme.radii[16]} ${theme.radii[16]} 0 0`};
  cursor: pointer;
  object-fit: cover;
`;
