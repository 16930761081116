"use client";
import styled from "@emotion/styled";
import { useUserContext } from "context/user-context";
import { Text } from "design-system/components";
import { Avatar } from "design-system/molecules/avatar";
import { getUserDisplayName } from "features/profile/shared";
import { useAtom } from "jotai";
import { AccountSidePanel, accountSidePanelAtom } from "./account-side-panel";

export const ProfilePopupMenu: React.FC = () => {
  const { firstName, lastName, avatarUrl, displayName } = useUserContext();
  const [isAccountSidePanelOpen, setIsAccountSidePanelOpen] =
    useAtom(accountSidePanelAtom);

  return (
    <>
      <ProfilePopupMenuTrigger
        className={`flex align-center gap-8${
          isAccountSidePanelOpen ? " open" : ""
        }`}
        data-cy="profile-nav-menu"
        onClick={() => setIsAccountSidePanelOpen(true)}
      >
        <Avatar
          imageUrl={avatarUrl}
          dimensions={45}
          userFullName={`${firstName} ${lastName}`}
        />
        <Text fontWeight={700} fontSize="sm" color="black">
          {getUserDisplayName({ displayName }) || firstName}
        </Text>
      </ProfilePopupMenuTrigger>
      <AccountSidePanel />
    </>
  );
};

const ProfilePopupMenuTrigger = styled.button`
  background-color: ${({ theme }) => theme.colors.blueGrey100};
  border-radius: ${({ theme }) => theme.radii[32]};
  & > p {
    display: none;
  }
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    padding-right: ${({ theme }) => theme.spacing[16]};
    & > p {
      display: block;
    }
  }
  &.open {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;
