"use client";
import { useToast } from "design-system/components";
import { analytics } from "features/analytics";
import { useCallback, useState } from "react";
import { isCypress } from "utils/environment";

type ClipboardCopyReturn = [boolean, (text: string) => void, JSX.Element];

export const useCopyToClipboard = (): ClipboardCopyReturn => {
  const [hasCopied, setHasCopied] = useState(false);
  const { toast, Toast } = useToast();
  const copyToClipboard = useCallback(
    (text: string) => {
      try {
        // Copy to clipboard causes bugs in E2E tests
        if (!isCypress()) {
          void navigator.clipboard.writeText(text);
        }
        setHasCopied(true);
        toast("success", "Copied to clipboard!");

        void analytics.track("Copy To Clipboard Clicked", {
          text,
        });
        /**
         * Revert the hasCopied after 5seconds
         */
        setTimeout(() => {
          setHasCopied(false);
        }, 5000);
      } catch (error) {
        toast("error", "Could not copy to clipboard");
      }
    },
    [toast]
  );

  return [hasCopied, copyToClipboard, Toast];
};
