import styled from "@emotion/styled";
import { Box, BoxCol, Text } from "../components";
import { Avatar } from "./avatar";

type HostContentProps = {
  name: string;
  oneLinerDescription?: string;
  pill?: React.ReactNode;
  photoUrl?: string;
  onClick?: () => void;
};

export const HostContent: React.FC<HostContentProps> = ({
  name,
  photoUrl,
  oneLinerDescription,
  onClick,
  pill,
}) => {
  return (
    <Box className="flex-col gap-8 justify-center" onClick={() => onClick?.()}>
      <FacilitatorWrapper>
        <FacilitatorImageContainer>
          <Avatar
            key={photoUrl}
            className="cursor-pointer"
            imageUrl={photoUrl}
            dimensions={50}
            userFullName={name}
          />
        </FacilitatorImageContainer>
        <BoxCol>
          <Text
            className="hov-decor-underline cursor-pointer"
            fontSize="sm"
            fontWeight={700}
          >
            {name}
          </Text>
          <Text fontSize="sm">{oneLinerDescription}</Text>
          {pill}
        </BoxCol>
      </FacilitatorWrapper>
    </Box>
  );
};

const FacilitatorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  &:focus {
    outline: "none";
    box-shadow: "none";
  }
`;

const FacilitatorImageContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii["round"]};
  background: ${({ theme }) => theme.colors["white"]};
`;
