const tokens = {
  "colors.black": {
    "value": "#18181D",
    "variable": "var(--colors-black)"
  },
  "colors.blueGrey100": {
    "value": "#F1F5F9",
    "variable": "var(--colors-blue-grey100)"
  },
  "colors.focusDark": {
    "value": "#4CBAB8",
    "variable": "var(--colors-focus-dark)"
  },
  "colors.focusMid": {
    "value": "#9ADEDC",
    "variable": "var(--colors-focus-mid)"
  },
  "colors.rechargeLight": {
    "value": "#F7C9B9",
    "variable": "var(--colors-recharge-light)"
  },
  "colors.rechargeDark": {
    "value": "#F89E81",
    "variable": "var(--colors-recharge-dark)"
  },
  "colors.info": {
    "value": "#3F6DE4",
    "variable": "var(--colors-info)"
  },
  "colors.infoMid": {
    "value": "color-mix(in srgb, #3F6DE4, transparent 50%)",
    "variable": "var(--colors-info-mid)"
  },
  "colors.infoLight": {
    "value": "#E9EFFF",
    "variable": "var(--colors-info-light)"
  },
  "colors.success": {
    "value": "#2F835C",
    "variable": "var(--colors-success)"
  },
  "colors.successLight": {
    "value": "#D7F9E9",
    "variable": "var(--colors-success-light)"
  },
  "colors.warning": {
    "value": "#EE9541",
    "variable": "var(--colors-warning)"
  },
  "colors.warningLight": {
    "value": "#FFF2E2",
    "variable": "var(--colors-warning-light)"
  },
  "colors.error": {
    "value": "#D71D1D",
    "variable": "var(--colors-error)"
  },
  "colors.errorLight": {
    "value": "#FFF0F0",
    "variable": "var(--colors-error-light)"
  },
  "colors.disabled": {
    "value": "#EBF2F5",
    "variable": "var(--colors-disabled)"
  },
  "colors.gold": {
    "value": "#FFDB5E",
    "variable": "var(--colors-gold)"
  },
  "colors.grey700": {
    "value": "#343434",
    "variable": "var(--colors-grey700)"
  },
  "colors.grey600": {
    "value": "#424242",
    "variable": "var(--colors-grey600)"
  },
  "colors.grey500": {
    "value": "#616161",
    "variable": "var(--colors-grey500)"
  },
  "colors.grey400": {
    "value": "#616B74",
    "variable": "var(--colors-grey400)"
  },
  "colors.grey300": {
    "value": "#6E757B",
    "variable": "var(--colors-grey300)"
  },
  "colors.grey200": {
    "value": "#ACB4B8",
    "variable": "var(--colors-grey200)"
  },
  "colors.grey100": {
    "value": "#E9EDF2",
    "variable": "var(--colors-grey100)"
  },
  "colors.grey50": {
    "value": "#F8FAFC",
    "variable": "var(--colors-grey50)"
  },
  "colors.grey20": {
    "value": "#F5F5F6",
    "variable": "var(--colors-grey20)"
  },
  "colors.grey": {
    "value": "#DEE4E9",
    "variable": "var(--colors-grey)"
  },
  "colors.pinkDark": {
    "value": "#FFA9A9",
    "variable": "var(--colors-pink-dark)"
  },
  "colors.pinkLight": {
    "value": "#FFB8B8",
    "variable": "var(--colors-pink-light)"
  },
  "colors.orangeDark": {
    "value": "#FFB053",
    "variable": "var(--colors-orange-dark)"
  },
  "colors.orangeLight": {
    "value": "#FFC683",
    "variable": "var(--colors-orange-light)"
  },
  "colors.pistachioDark": {
    "value": "#B3D171",
    "variable": "var(--colors-pistachio-dark)"
  },
  "colors.pistachioLight": {
    "value": "#C1DC89",
    "variable": "var(--colors-pistachio-light)"
  },
  "colors.grassDark": {
    "value": "#70D19C",
    "variable": "var(--colors-grass-dark)"
  },
  "colors.grassLight": {
    "value": "#8ADFB1",
    "variable": "var(--colors-grass-light)"
  },
  "colors.skyDark": {
    "value": "#AAD3ED",
    "variable": "var(--colors-sky-dark)"
  },
  "colors.skyLight": {
    "value": "#BBE5FF",
    "variable": "var(--colors-sky-light)"
  },
  "colors.berryDark": {
    "value": "#698CE7",
    "variable": "var(--colors-berry-dark)"
  },
  "colors.berryLight": {
    "value": "#90A8E8",
    "variable": "var(--colors-berry-light)"
  },
  "colors.purpleDark": {
    "value": "#A278E7",
    "variable": "var(--colors-purple-dark)"
  },
  "colors.purpleLight": {
    "value": "#B292E7",
    "variable": "var(--colors-purple-light)"
  },
  "colors.wrapped": {
    "value": "#7991e3",
    "variable": "var(--colors-wrapped)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "sizes.primaryNavHeight": {
    "value": "72px",
    "variable": "var(--sizes-primary-nav-height)"
  },
  "sizes.primaryNavWidth": {
    "value": "110px",
    "variable": "var(--sizes-primary-nav-width)"
  },
  "sizes.secondaryNavHeight": {
    "value": "60px",
    "variable": "var(--sizes-secondary-nav-height)"
  },
  "sizes.sessionSidePanelWidth": {
    "value": "400px",
    "variable": "var(--sizes-session-side-panel-width)"
  },
  "sizes.sidePanelWidth": {
    "value": "400px",
    "variable": "var(--sizes-side-panel-width)"
  },
  "sizes.sidePanelWidthLarge": {
    "value": "600px",
    "variable": "var(--sizes-side-panel-width-large)"
  },
  "sizes.maxWidth.content": {
    "value": "1512px",
    "variable": "var(--sizes-max-width-content)"
  },
  "sizes.maxWidth.launchpad": {
    "value": "1440px",
    "variable": "var(--sizes-max-width-launchpad)"
  },
  "sizes.breakpoint-sm": {
    "value": "640px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1536px",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "sizes.breakpoint-bp-mobile-only": {
    "value": "(max-width: 768px)",
    "variable": "var(--sizes-breakpoint-bp-mobile-only)"
  },
  "sizes.breakpoint-bp-tablet-or-lower": {
    "value": "(max-width: 1024px)",
    "variable": "var(--sizes-breakpoint-bp-tablet-or-lower)"
  },
  "sizes.breakpoint-bp-desktop-xs-or-lower": {
    "value": "(max-width: 1280px)",
    "variable": "var(--sizes-breakpoint-bp-desktop-xs-or-lower)"
  },
  "sizes.breakpoint-bp-desktop-xs-height": {
    "value": "(min-height: 768px)",
    "variable": "var(--sizes-breakpoint-bp-desktop-xs-height)"
  },
  "sizes.breakpoint-bp-mobile": {
    "value": "375px",
    "variable": "var(--sizes-breakpoint-bp-mobile)"
  },
  "sizes.breakpoint-bp-mobile-md": {
    "value": "480px",
    "variable": "var(--sizes-breakpoint-bp-mobile-md)"
  },
  "sizes.breakpoint-bp-tablet": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-bp-tablet)"
  },
  "sizes.breakpoint-bp-tablet-md": {
    "value": "810px",
    "variable": "var(--sizes-breakpoint-bp-tablet-md)"
  },
  "sizes.breakpoint-bp-desktop-xs": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-bp-desktop-xs)"
  },
  "sizes.breakpoint-bp-desktop-sm": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-bp-desktop-sm)"
  },
  "sizes.breakpoint-bp-desktop-md": {
    "value": "1440px",
    "variable": "var(--sizes-breakpoint-bp-desktop-md)"
  },
  "sizes.breakpoint-bp-desktop-lg": {
    "value": "1920px",
    "variable": "var(--sizes-breakpoint-bp-desktop-lg)"
  },
  "sizes.breakpoint-bp-side-panel": {
    "value": "640px",
    "variable": "var(--sizes-breakpoint-bp-side-panel)"
  },
  "spacing.4": {
    "value": "0.25rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.8": {
    "value": "0.5rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.12": {
    "value": "0.75rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.16": {
    "value": "1rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "1.25rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "1.5rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.28": {
    "value": "1.75rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "2rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "2.25rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "2.5rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "2.75rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "3rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.52": {
    "value": "3.25rem",
    "variable": "var(--spacing-52)"
  },
  "spacing.56": {
    "value": "3.5rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.60": {
    "value": "3.75rem",
    "variable": "var(--spacing-60)"
  },
  "spacing.64": {
    "value": "4rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.80": {
    "value": "5rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.84": {
    "value": "5.25rem",
    "variable": "var(--spacing-84)"
  },
  "spacing.128": {
    "value": "8rem",
    "variable": "var(--spacing-128)"
  },
  "spacing.primaryNavWidth": {
    "value": "110px",
    "variable": "var(--spacing-primary-nav-width)"
  },
  "fontSizes.2xs": {
    "value": "0.625rem",
    "variable": "var(--font-sizes-2xs)"
  },
  "fontSizes.xs": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "1rem",
    "variable": "var(--font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "1.25rem",
    "variable": "var(--font-sizes-xl)"
  },
  "fontSizes.2xl": {
    "value": "1.5rem",
    "variable": "var(--font-sizes-2xl)"
  },
  "fontSizes.3xl": {
    "value": "2rem",
    "variable": "var(--font-sizes-3xl)"
  },
  "fontSizes.4xl": {
    "value": "2.5rem",
    "variable": "var(--font-sizes-4xl)"
  },
  "fontSizes.5xl": {
    "value": "3rem",
    "variable": "var(--font-sizes-5xl)"
  },
  "fontSizes.5.5xl": {
    "value": "3.5rem",
    "variable": "var(--font-sizes-5\\.5xl)"
  },
  "fontSizes.6xl": {
    "value": "4rem",
    "variable": "var(--font-sizes-6xl)"
  },
  "fontSizes.7xl": {
    "value": "4.5rem",
    "variable": "var(--font-sizes-7xl)"
  },
  "fontSizes.8xl:": {
    "value": "5rem",
    "variable": "var(--font-sizes-8xl\\:)"
  },
  "fontSizes.9xl": {
    "value": "6rem",
    "variable": "var(--font-sizes-9xl)"
  },
  "fonts.facundo": {
    "value": "Facundo, sans-serif",
    "variable": "var(--fonts-facundo)"
  },
  "fonts.biennale": {
    "value": "Biennale, sans-serif",
    "variable": "var(--fonts-biennale)"
  },
  "fonts.centuryOldStyleStd": {
    "value": "Century Old",
    "variable": "var(--fonts-century-old-style-std)"
  },
  "radii.2": {
    "value": "2px",
    "variable": "var(--radii-2)"
  },
  "radii.4": {
    "value": "4px",
    "variable": "var(--radii-4)"
  },
  "radii.6": {
    "value": "6px",
    "variable": "var(--radii-6)"
  },
  "radii.8": {
    "value": "8px",
    "variable": "var(--radii-8)"
  },
  "radii.12": {
    "value": "12px",
    "variable": "var(--radii-12)"
  },
  "radii.16": {
    "value": "16px",
    "variable": "var(--radii-16)"
  },
  "radii.20": {
    "value": "20px",
    "variable": "var(--radii-20)"
  },
  "radii.24": {
    "value": "24px",
    "variable": "var(--radii-24)"
  },
  "radii.32": {
    "value": "32px",
    "variable": "var(--radii-32)"
  },
  "radii.46": {
    "value": "46px",
    "variable": "var(--radii-46)"
  },
  "radii.80": {
    "value": "80px",
    "variable": "var(--radii-80)"
  },
  "radii.round": {
    "value": "9999px",
    "variable": "var(--radii-round)"
  },
  "breakpoints.sm": {
    "value": "640px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "1536px",
    "variable": "var(--breakpoints-2xl)"
  },
  "breakpoints.bp-mobile-only": {
    "value": "(max-width: 768px)",
    "variable": "var(--breakpoints-bp-mobile-only)"
  },
  "breakpoints.bp-tablet-or-lower": {
    "value": "(max-width: 1024px)",
    "variable": "var(--breakpoints-bp-tablet-or-lower)"
  },
  "breakpoints.bp-desktop-xs-or-lower": {
    "value": "(max-width: 1280px)",
    "variable": "var(--breakpoints-bp-desktop-xs-or-lower)"
  },
  "breakpoints.bp-desktop-xs-height": {
    "value": "(min-height: 768px)",
    "variable": "var(--breakpoints-bp-desktop-xs-height)"
  },
  "breakpoints.bp-mobile": {
    "value": "375px",
    "variable": "var(--breakpoints-bp-mobile)"
  },
  "breakpoints.bp-mobile-md": {
    "value": "480px",
    "variable": "var(--breakpoints-bp-mobile-md)"
  },
  "breakpoints.bp-tablet": {
    "value": "768px",
    "variable": "var(--breakpoints-bp-tablet)"
  },
  "breakpoints.bp-tablet-md": {
    "value": "810px",
    "variable": "var(--breakpoints-bp-tablet-md)"
  },
  "breakpoints.bp-desktop-xs": {
    "value": "1024px",
    "variable": "var(--breakpoints-bp-desktop-xs)"
  },
  "breakpoints.bp-desktop-sm": {
    "value": "1280px",
    "variable": "var(--breakpoints-bp-desktop-sm)"
  },
  "breakpoints.bp-desktop-md": {
    "value": "1440px",
    "variable": "var(--breakpoints-bp-desktop-md)"
  },
  "breakpoints.bp-desktop-lg": {
    "value": "1920px",
    "variable": "var(--breakpoints-bp-desktop-lg)"
  },
  "breakpoints.bp-side-panel": {
    "value": "640px",
    "variable": "var(--breakpoints-bp-side-panel)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-52": {
    "value": "calc(var(--spacing-52) * -1)",
    "variable": "var(--spacing-52)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-60": {
    "value": "calc(var(--spacing-60) * -1)",
    "variable": "var(--spacing-60)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-84": {
    "value": "calc(var(--spacing-84) * -1)",
    "variable": "var(--spacing-84)"
  },
  "spacing.-128": {
    "value": "calc(var(--spacing-128) * -1)",
    "variable": "var(--spacing-128)"
  },
  "spacing.-primaryNavWidth": {
    "value": "calc(var(--spacing-primary-nav-width) * -1)",
    "variable": "var(--spacing-primary-nav-width)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar