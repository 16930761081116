import styled from "@emotion/styled";

export const maxContentWidthPixels = 1400;

export const contentContainerStyles = `
  position: relative;
  width: 100%;
  max-width: var(--content-container-width,${maxContentWidthPixels}px);
  margin-left: auto;
  margin-right: auto;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
`;

export const ContentContainer = styled.div`
  ${contentContainerStyles}
  /* TODO: get rid of the 20px border above */
  &.no-border {
    border: none;
  }
`;

export const CenteredContentContainer = styled(ContentContainer)`
  display: flex;
  justify-content: center;
`;
