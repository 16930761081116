"use client";
import ReactJSPopup from "reactjs-popup";
import { NoSSR } from "utils/component-utils";

type ReactJSPopupType = Parameters<typeof ReactJSPopup>[0];

export const Popup = (props: ReactJSPopupType) => {
  return (
    // reactjs-popup is not compatible with SSR so on SSR just render the trigger,
    // then instantiate the popup on the client.
    <NoSSR onSSR={props.trigger as any}>
      <ReactJSPopup {...props} />
    </NoSSR>
  );
};
