import styled from "@emotion/styled";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { SelectHTMLAttributes } from "react";
import { InputSizes, inputStyles } from "./shared/form-styles";
import { TextError } from "./text";

type SelectProps = Partial<
  SelectHTMLAttributes<HTMLElement> & { placeholder?: string }
> &
  DSComponentPropsProvider & {
    full?: boolean;
    inputSize?: InputSizes;
    errorMessage?: string;
  };

export const Select = ({
  className = "",
  full,
  inputSize = "medium",
  errorMessage,
  ...props
}: SelectProps) => {
  return (
    <>
      <StyledSelect
        className={`${className} ${full ? "full" : ""} ${
          inputSize ? inputSize : ""
        } ${errorMessage ? "has-error" : ""}`}
        {...props}
      />

      {errorMessage ? (
        <TextError fontWeight={400}>{errorMessage}</TextError>
      ) : undefined}
    </>
  );
};

const StyledSelect = styled.select(({ theme }) => ({
  ...inputStyles(theme),
  appearance: "none",
  backgroundColor: "white",
  backgroundImage:
    "url(/assets/shared/arrow.svg), linear-gradient(to bottom, #ffffff 0%, white 100%)",
  backgroundRepeat: "no-repeat, repeat",
  backgroundPosition: "right 0.7em top 50%, 0 0",
  backgroundSize: "0.65em auto, 100%",
  padding: `${theme.spacing[12]} ${theme.spacing[24]} ${theme.spacing[12]} ${theme.spacing[12]}`,

  ".no-value": {
    color: theme.colors.grey200,
  },
}));
