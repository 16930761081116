import { type IconName } from "design-system/components";
import {
  LAUNCHPAD_URL,
  REFERRAL_URL,
  SEND_VERIFICATION_EMAIL_URL,
  MANAGE_SUBSCRIPTION_URL,
  PORTHOLES_URL,
  EAGLE_URL,
  USER_MANAGEMENT_URL,
  PROFILE_URL,
  ACADEMY_URL,
  SPRINGBOARD_URL_PREFIX,
  ANYTIME_URL_PREFIX,
  EAGLE_SESSIONS_URL,
  RECHARGE_BROWSE_URL,
  ORGANISATION_URL_PREFIX,
  COMMUNITY_SPRINGBOARD_SHORT_URL,
  FLOWN_PLUS_SESSIONS_URL,
  COMMUNITY_URL,
  COACHING_URL,
  FLEETS_URL,
  CALENDAR_FEATURED_URL,
  ACCOUNT_SETTINGS_URL_PREFIX,
  CALENDAR_MY_SESSIONS,
} from "utils/urls";
import {
  getIsAuthorizedRole,
  getIsCorporateAdminRole,
  getIsCorporateUserRole,
  getIsExpiredRole,
  getIsFacilitatorRole,
  getIsFlownRole,
  getIsFreeTrialExpiredRole,
  getIsFreeRole,
  getIsUnsubscribedRole,
  UserRole,
} from "services/roles/user-roles";
import { UserOrganisationWithName } from "context/user-context/user.state";
import { OPTIONAL_NAV_MENU_ITEMS } from "utils/constants";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";

type NavItem = {
  name: string;
  url?: string;
  icon?: IconName;
  customIconUrl?: string;
  pathPattern?: RegExp;
  children?: SecondaryNavItem[];
  hidden?: boolean;
  hideOnMobile?: boolean;
};

type SecondaryNavItem = {
  name: string;
  icon?: IconName;
  url: string;
  pathPattern: RegExp;
  hideOnMobile?: boolean;
};

export const getPrimaryNavItems = (
  organisation?: UserOrganisationWithName
): NavItem[] => [
  {
    name: "Launchpad",
    icon: "sparkle-hollow",
    pathPattern: /\/launchpad/,
    url: LAUNCHPAD_URL,
  },
  {
    name: "Calendar",
    icon: "calendar",
    url: CALENDAR_FEATURED_URL,
    pathPattern: /^\/calendar/,
  },
  {
    name: "Community",
    icon: "community" as IconName,
    pathPattern: /^\/community/,
    url: COMMUNITY_URL,
    hidden: organisation
      ? !organisation.menuItems?.includes(OPTIONAL_NAV_MENU_ITEMS.community)
      : false,
    hideOnMobile: true,
  },
  {
    name: organisation?.name || "Organisation",
    customIconUrl: organisation?.logoUrl || "",
    icon: "eagle" as IconName,
    pathPattern: /^\/organisation/,
    url: `${ORGANISATION_URL_PREFIX}/${organisation?.slug}`,
    hidden: organisation
      ? !organisation.menuItems?.includes(OPTIONAL_NAV_MENU_ITEMS.organisation)
      : true,
    hideOnMobile: true,
  },
  {
    name: "FLOWN+",
    icon: "badge-plus" as IconName,
    pathPattern: /\/flown-plus/,
    url: FLOWN_PLUS_SESSIONS_URL,
    hidden: organisation
      ? !organisation.menuItems?.includes(OPTIONAL_NAV_MENU_ITEMS.flown_plus)
      : false,
    hideOnMobile: true,
    children: [
      {
        name: "Home",
        url: FLOWN_PLUS_SESSIONS_URL,
        pathPattern: /^\/flown-plus$/,
      },
      {
        name: "Coaching",
        url: COACHING_URL,
        pathPattern: /^\/flown-plus\/coaching/,
      },
      {
        name: "Fleets",
        url: FLEETS_URL,
        pathPattern: /^\/flown-plus\/fleets/,
      },
    ],
  },

  {
    name: "Anytime",
    icon: "anytime",
    pathPattern: /^\/anytime/,
    children: [
      {
        name: "Home",
        url: ANYTIME_URL_PREFIX,
        pathPattern: /^\/anytime$/,
      },
      {
        name: "Focus",
        url: PORTHOLES_URL,
        pathPattern: /^\/anytime\/focus/,
      },
      {
        name: "Recharge",
        url: RECHARGE_BROWSE_URL,
        pathPattern: /^\/anytime\/recharge/,
      },
      {
        name: "Learn",
        url: ACADEMY_URL,
        pathPattern: /^\/anytime\/academy/,
      },
    ],
  },
  {
    name: "Account",
    icon: "profile" as IconName,
    url: ACCOUNT_SETTINGS_URL_PREFIX,
    hideOnMobile: false,
  },
  {
    name: "Springboard",
    pathPattern: /^\/springboard/,
    url: SPRINGBOARD_URL_PREFIX,
    hidden: true,
  },
  {
    name: "Community springboard",
    pathPattern: /^\/sc/,
    url: COMMUNITY_SPRINGBOARD_SHORT_URL,
    hidden: true,
  },
  {
    name: "Eagle",
    pathPattern: /^\/eagle/,
    url: "/eagle",
    hidden: true,
  },
  {
    name: "User",
    pathPattern: /^\/user/,
    url: "/user",
    hidden: true,
  },
];

type SecondaryOnlyNavItems = NavItem;

const getSecondaryAccountSettingsChildren = (userRole: UserRole) => {
  const children: SecondaryNavItem[] = [
    {
      name: "Account",
      url: ACCOUNT_SETTINGS_URL_PREFIX,
      pathPattern: /^\/account-settings$/,
      hideOnMobile: false,
    },
    {
      name: "Profile",
      url: PROFILE_URL,
      pathPattern: /^\/account-settings\/(profile|user-settings)/,
    },
    {
      name: "Subscription",
      url: MANAGE_SUBSCRIPTION_URL,
      pathPattern: /^\/account-settings\/manage-subscription/,
    },
    {
      name: "Referrals",
      url: REFERRAL_URL,
      pathPattern: /^\/account-settings\/referral/,
      hideOnMobile: true,
    },
  ];

  if (getIsCorporateAdminRole(userRole)) {
    const corporateAdminChildren = [
      {
        name: "User Management",
        url: USER_MANAGEMENT_URL,
        pathPattern: /^\/account-settings\/user-management/,
      },
      ...children,
    ];
    return corporateAdminChildren;
  }

  return children;
};

export const getSecondaryOnlyChildren = (
  userRole: UserRole
): SecondaryOnlyNavItems[] => {
  return [
    {
      name: "Account Settings",
      icon: "sparkle",
      pathPattern: /^\/account-settings/,
      children: getSecondaryAccountSettingsChildren(userRole),
    },
    {
      name: "Contact",
      url: "/contact",
      pathPattern: /^\/contact/,
    },
    {
      name: "Subscribe",
      pathPattern: /^\/subscribe/,
      url: getSubscribePageLinkWithSubscribeSource("nav-menu"),
    },
    {
      name: "Email verification",
      pathPattern: /^\/send-verification-email/,
      url: SEND_VERIFICATION_EMAIL_URL,
    },
    {
      name: "Refer a friend",
      pathPattern: /^\/referral/,
      url: REFERRAL_URL,
    },
  ];
};

export const isActiveItem = (
  item: NavItem | SecondaryOnlyNavItems,
  pathname: string
) => {
  if (item.pathPattern) return !!pathname.match(item.pathPattern);
  else if (item.children) {
    return item.children.some((child) => {
      if (pathname.match(child.pathPattern)) return true;
    });
  }
  return false;
};

type ProfileMenuItem = {
  item: string;
  url: string;
  dataCy: string;
};

export const getFlownProfileMenuItems = (
  userRole: UserRole
): ProfileMenuItem[] => {
  return [
    ...(getIsFlownRole(userRole)
      ? [
          {
            item: "Eagle",
            url: EAGLE_URL,
            dataCy: "eagle-profile-nav-button",
          },
        ]
      : []),
    ...(getIsFacilitatorRole(userRole)
      ? [
          {
            item: "Eagle: Sessions Dashboard",
            url: EAGLE_SESSIONS_URL,
            dataCy: "sessions-profile-nav-button",
          },
        ]
      : []),
  ];
};

export const getLoggedInProfileMenuItems = (
  userRole: UserRole
): ProfileMenuItem[] => {
  const isCorporateRole =
    getIsCorporateAdminRole(userRole) || getIsCorporateUserRole(userRole);

  const isExpiredOrFreeTrialExpired =
    getIsExpiredRole(userRole) || getIsFreeTrialExpiredRole(userRole);

  const shouldSeeManageSubscription =
    !isCorporateRole && !isExpiredOrFreeTrialExpired;

  const memberProfileItems = [
    {
      item: "Launchpad",
      url: LAUNCHPAD_URL,
      dataCy: "account-settings-launchpad",
    },
    {
      item: "My profile",
      url: PROFILE_URL,
      dataCy: "my-profile-profile-nav-button",
    },
    {
      item: "My sessions",
      url: CALENDAR_MY_SESSIONS,
      dataCy: "calendar-my-sessions-profile-nav-button",
    },
    {
      item: "Account settings",
      url: getIsCorporateAdminRole(userRole)
        ? USER_MANAGEMENT_URL
        : ACCOUNT_SETTINGS_URL_PREFIX,
      dataCy: "account-settings-profile-nav-button",
    },
  ];

  if (shouldSeeManageSubscription) {
    memberProfileItems.push({
      item: "Manage subscription",
      url: MANAGE_SUBSCRIPTION_URL,
      dataCy: "manage-subscription-profile-nav-button",
    });
  }

  if (getIsUnsubscribedRole(userRole)) {
    memberProfileItems.push({
      item: "Subscribe",
      url: getSubscribePageLinkWithSubscribeSource("nav-menu"),
      dataCy: "subscribe-nav-button",
    });
  }

  // Free role is not part of authorized
  if (!getIsAuthorizedRole(userRole) && !getIsFreeRole(userRole)) {
    return memberProfileItems;
  }

  return [
    ...memberProfileItems,
    {
      item: "Member benefits",
      url: "/flown-friends",
      dataCy: "flown-friends",
    },
  ];
};
