import { normalizeError } from "services/normalize-error";
import { getLocalStorageWithExpiry } from "utils/local-storage-utils";
import { createPostEndpoint } from "utils/create-post-endpoint";
import { checkIsPWA } from "utils/platform-utils";
import { AnalyticsEventName } from "../analytics-tracking-plan";
import {
  ClientEventProperties,
  RecordEventRequest,
  RecordEventResponse,
  SegmentIntegrations,
} from "./analytics-utils";
import { clientLoggerForFile } from "utils/logger.client";
import { getDeviceBucket } from "utils/component-utils";

const postToRecordEventHandler = createPostEndpoint<
  RecordEventRequest,
  RecordEventResponse
>("/api/record");

const logger = clientLoggerForFile(__filename);

const maxDelay = 2 * 60 * 1000;

export const catchAnalyticsError = (err: unknown) => {
  const error = normalizeError(err);
  logger.error("[ERROR] Tracking failure", error.message);
};

const retryRequest = async (
  request: ReturnType<typeof record>,
  delay = 2000
) => {
  try {
    await request();
  } catch (error) {
    if (delay >= maxDelay) throw error;
    setTimeout(() => retryRequest(request, delay * 2), delay);
  }
};

const DEFAULT_INTEGRATIONS: SegmentIntegrations = {
  All: true,
  "Facebook Conversions API": true,
  "Actions Google Analytics 4": false,
};

const record = (event: RecordEventRequest) => async () =>
  await postToRecordEventHandler({
    ...event,
  });

const getClientAnonymousId = (): string =>
  window && window.analytics && window.analytics.user
    ? window.analytics.user().anonymousId()
    : "";

const getContext = (
  integrations: SegmentIntegrations = DEFAULT_INTEGRATIONS,
  mergeIdentities = false
): ClientEventProperties => ({
  path: document.location.pathname,
  search: document.location.search,
  title: document.title,
  url: document.location.href,
  campaign: getLocalStorageWithExpiry("utm-data") || {},
  anonymousId: getClientAnonymousId(),
  integrations,
  mergeIdentities,
});

const page = async <T extends AnalyticsEventName>(
  name: T,
  properties: RecordEventRequest<T>["properties"],
  integrations = DEFAULT_INTEGRATIONS
) => {
  await retryRequest(
    record({
      eventName: name,
      eventType: "page",
      properties,
      ...getContext(integrations),
    })
  );
};

const track = async <T extends AnalyticsEventName>(
  name: T,
  properties: RecordEventRequest<T>["properties"],
  integrations = DEFAULT_INTEGRATIONS
) => {
  await retryRequest(
    record({
      eventName: name,
      eventType: "track",
      properties,
      ...getContext(integrations),
    })
  );
};

const identify = async (
  userTraits: RecordEventRequest<"Identify">["properties"],
  integrations: SegmentIntegrations = DEFAULT_INTEGRATIONS,
  mergeIdentities = false
) => {
  await retryRequest(
    record({
      eventName: "Identify",
      eventType: "identify",
      properties: userTraits,
      ...getContext(integrations, mergeIdentities),
    })
  );
};

const pageClient = (
  properties: RecordEventRequest<"Page Viewed">["properties"]
) => {
  const analyticsClient = window.analytics || null;
  if (window && analyticsClient) {
    const { category, name } = properties;
    analyticsClient.page(
      category,
      name,
      {
        isPWA: checkIsPWA(),
        pageWidth: window.innerWidth,
        pageHeight: window.innerHeight,
        device: getDeviceBucket(window.innerWidth),
        userAgent: window.navigator.userAgent,
      },
      {
        integrations: {
          Intercom: {
            hideDefaultLauncher: true,
            custom_launcher_selector: "#intercom-launcher",
          },
        },
      }
    );
  }
};

const trackClient = <T extends AnalyticsEventName>(
  name: T,
  properties: RecordEventRequest<T>["properties"],
  integrations: SegmentIntegrations = {
    All: true,
    Intercom: {
      hideDefaultLauncher: true,
      custom_launcher_selector: "#intercom-launcher",
    },
  }
) => {
  const analyticsClient = window.analytics || null;
  if (analyticsClient) {
    analyticsClient.track(
      name,
      { ...properties, isPWA: checkIsPWA() },
      {
        integrations,
      }
    );
  }
};

const identifyClient = (
  userId: string,
  traits: Pick<
    RecordEventRequest<"Identify">["properties"],
    "firstName" | "lastName" | "createdAt"
  >,
  intercomVerificationHash: string
) => {
  const analyticsClient = window.analytics || null;
  if (analyticsClient) {
    analyticsClient.identify(userId, traits, {
      integrations: {
        Intercom: {
          user_hash: intercomVerificationHash,
          hideDefaultLauncher: true,
          custom_launcher_selector: "#intercom-launcher",
        },
      },
    });
  }
};

const resetClient = () => {
  const analyticsClient = window.analytics || null;
  if (analyticsClient) {
    analyticsClient.reset();
  }
};

export const analytics = {
  page,
  track,
  identify,
  pageClient,
  identifyClient,
  trackClient,
  resetClient,
};

export type AnalyticsWrapper = typeof analytics;
