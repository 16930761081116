import { styled } from "styled-system/jsx";
import { Icon } from "design-system/components";
import { ThemeColor, ThemeFontSize } from "design-system/emotion.theme";
import { ReactNode } from "react";
import {
  Category,
  FOCUS_CATEGORY_SLUG,
  INTENTIONS_CATEGORY_SLUG,
  LEARN_CATEGORY_SLUG,
  RECHARGE_CATEGORY_SLUG,
  SOCIAL_CATEGORY_SLUG,
} from "shared/products.types";
import { DSComponentPropsProvider } from "../design-system.types";
import { css } from "styled-system/css";
import { token } from "styled-system/tokens";

export type PillProps = DSComponentPropsProvider & {
  backgroundColor?: ThemeColor;
  children?: ReactNode;
  fontColor?: ThemeColor;
  fontSize?: ThemeFontSize;
};

type PillFromCategoryProps = DSComponentPropsProvider &
  Pick<PillProps, "fontSize"> & { showIcon?: boolean };

export const Pill = ({
  children,
  className = "",
  onClick,
  dataCy,
  backgroundColor = "white",
  fontColor = "black",
  fontSize = "sm",
  style,
  ...props
}: PillProps) => {
  return (
    <StyledPill
      {...props}
      data-cy={dataCy}
      data-component="Pill"
      className={`${className} ${onClick ? "cursor-pointer" : ""}`}
      style={{
        backgroundColor: token(`colors.${backgroundColor}`),
        color: token(`colors.${fontColor}`),
        fontSize: token(`fontSizes.${fontSize}`),
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </StyledPill>
  );
};

const StyledPill = styled("div", {
  base: {
    borderRadius: "round",
    fontWeight: 600,
    lineHeight: "1.125rem",
    height: "fit-content",
    padding: "{spacing.4} {spacing.12}",
    textAlign: "center",
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export const PillFromCategory = ({
  category,
  ...props
}: {
  category: Category;
} & PillFromCategoryProps) => {
  const pillFromCategoryMap = {
    [RECHARGE_CATEGORY_SLUG]: RechargePill,
    [FOCUS_CATEGORY_SLUG]: FocusPill,
    [LEARN_CATEGORY_SLUG]: LearnPill,
    [SOCIAL_CATEGORY_SLUG]: SocialPill,
    [INTENTIONS_CATEGORY_SLUG]: IntentionsPill,
  };
  return pillFromCategoryMap[category](props);
};

export const FocusPill = ({
  fontSize = "md",
  showIcon = true,
  ...props
}: PillFromCategoryProps) => (
  <Pill
    className="flex gap-8"
    backgroundColor="focusDark"
    fontColor="white"
    fontSize={fontSize}
    {...props}
  >
    {showIcon && <Icon icon="focus" color="white" size={16} />}Focus
  </Pill>
);

export const RechargePill = ({
  fontSize = "md",
  showIcon = true,
  ...props
}: PillFromCategoryProps) => (
  <Pill
    className="flex gap-8"
    backgroundColor="rechargeDark"
    fontColor="white"
    fontSize={fontSize}
    {...props}
  >
    {showIcon && <Icon icon="recharge" color="white" size={16} />}
    Recharge
  </Pill>
);

const LearnPill = ({
  fontSize = "md",
  showIcon = true,
  ...props
}: PillFromCategoryProps) => (
  <Pill
    className="flex gap-8"
    backgroundColor="grey600"
    fontColor="white"
    fontSize={fontSize}
    {...props}
  >
    {showIcon && <Icon icon="learn" color="white" size={16} />}
    Learn
  </Pill>
);

const SocialPill = ({
  fontSize = "md",
  showIcon = true,
  ...props
}: PillFromCategoryProps) => (
  <Pill
    className="flex gap-8"
    backgroundColor="pistachioDark"
    fontColor="white"
    fontSize={fontSize}
    {...props}
  >
    {showIcon && <Icon icon="social" color="white" size={16} />}
    Social
  </Pill>
);

const IntentionsPill = ({
  fontSize = "md",
  showIcon = true,
  ...props
}: PillFromCategoryProps) => (
  <Pill
    className="flex gap-8"
    backgroundColor="skyDark"
    fontColor="white"
    fontSize={fontSize}
    {...props}
  >
    {showIcon && <Icon icon="intentions" color="white" size={16} />}
    Accountability
  </Pill>
);

export const VerticalPill = styled(Pill, {
  base: {
    transform: "rotate(90deg)",
  },
});

export const FlownPill = ({
  className,
  ...otherProps
}: DSComponentPropsProvider) => (
  <Pill
    className={`flex gap-8 ${className}`}
    backgroundColor="grey600"
    fontColor="white"
    {...otherProps}
  >
    <Icon icon="sparkle-filled" size={14} />
    FLOWN
  </Pill>
);

export const CommunityHostPill = ({
  className,
  ...otherProps
}: DSComponentPropsProvider) => (
  <Pill
    className={`flex gap-8 ${className}`}
    backgroundColor="grey100"
    {...otherProps}
  >
    <Icon icon="people" size={14} />
    Community host
  </Pill>
);

export const GreatForNewbiesPill = () => (
  <Pill backgroundColor="successLight" fontSize="2xs">
    🐣 Great for newbies
  </Pill>
);

type ExpandablePillProps = PillProps & {
  hiddenContent: ReactNode;
  visibleContent: ReactNode;
};

export const ExpandablePill = ({
  className,
  hiddenContent,
  visibleContent,
  ...props
}: ExpandablePillProps) => (
  <Pill className={`${expandablePillStyles} ${className}`} {...props}>
    {visibleContent}
    <span className="pill-expandable-content">{hiddenContent}</span>
  </Pill>
);

const expandablePillStyles = css({
  alignItems: "center",
  display: "flex",
  overflow: "hidden",
  transition: "all 0.3s linear",

  "& .pill-expandable-content": {
    maxWidth: "0",
    opacity: "0",
    transition: "all 0.3s linear",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
  },
  "&:hover": {
    "& .pill-expandable-content": {
      // We need a set value for the animation, 300px should be more than enough for the width of a Pill
      maxWidth: "300px",
      opacity: "1",
    },
  },
});
