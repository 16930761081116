import { z } from "zod";

export type DiscountData = {
  couponCode?: string;
  promoCode?: string;
  promoCodeId?: string;
};

export type UserSubscription = {
  stripeSubscriptionId?: string;
  creationDate?: string;
  renewalDate?: string;
  type?: SubscriptionMembershipType;
  isCancelled?: boolean;
  paymentFailed?: boolean;
} & DiscountData;

export const SUBSCRIPTION_TYPES = {
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
  LIFETIME: "lifetime",
  COMPLIMENTARY: "complimentary",
  FACILITATOR: "facilitator",
  ACCESS_TO_WORK: "access-to-work",
  FLOWN: "flown",
  CORPORATE: "corporate",
  NONE: "none", // Free trial expired users go here
  FREE: "free",
  FREE_CREDITS: "free-credits",
  BLOCKED: "blocked",
} as const;

export type SubscriptionMembershipType =
  typeof SUBSCRIPTION_TYPES[keyof typeof SUBSCRIPTION_TYPES];

export const SUBSCRIPTION_LTV: Record<SubscriptionMembershipType, number> = {
  monthly: 78,
  yearly: 213,
  // Set just as defaults for now
  quarterly: 213,
  "access-to-work": 213,
  lifetime: 213,
  complimentary: 0,
  facilitator: 0,
  flown: 0,
  corporate: 0,
  none: 0,
  free: 0,
  "free-credits": 0,
  blocked: 0,
};

export const SubscriptionTypeEnum = z.nativeEnum(SUBSCRIPTION_TYPES);

export type PricingType = z.infer<typeof SubscriptionTypeEnum>;
