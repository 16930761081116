import {
  CenteredContentContainer,
  PrimaryButton,
  SecondaryButton,
} from "legacy-design-system";
import { CtaButtonBlockFragment } from "generated/graphql-typed";
import { notEmpty } from "utils/assertions";
import { renderer } from "./renderer";
import { useUserContext } from "context/user-context";

const CTAButtonView = (
  props: CtaButtonBlockFragment & { className: string }
) => {
  const { isAuthorizedRole } = useUserContext();

  const useLoggedInContent =
    isAuthorizedRole &&
    props.ctaButtonTextLoggedIn &&
    props.ctaButtonLinkLoggedIn;

  const text =
    (useLoggedInContent ? props.ctaButtonTextLoggedIn : props.text) || "";
  const href = useLoggedInContent ? props.ctaButtonLinkLoggedIn : props.href;

  return notEmpty(props.href) ? (
    <CenteredContentContainer className={props.className}>
      {props.style === "primary" ? (
        <PrimaryButton href={href || props.href}>
          {text || props.text}
        </PrimaryButton>
      ) : (
        <SecondaryButton href={href || props.href}>
          {text || props.text}
        </SecondaryButton>
      )}
    </CenteredContentContainer>
  ) : null;
};

export const CTAButtonRenderer = renderer({
  typeName: "CtaButtonRecord",
  view: CTAButtonView,
});
