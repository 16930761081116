"use client";
import { analytics, catchAnalyticsError } from "features/analytics";
import { useRef } from "react";
import styled from "@emotion/styled";
import { useTrackVideoProgress } from "utils/hooks/use-track-video-progress";

const supportedFormats = [".mp4", ".ogg", ".webm"];

export const isVideo = (url: string) =>
  supportedFormats.some((format) => url.includes(format));

type MediaViewerProps = {
  url: string;
  alt: string;
  showVideoControls: boolean;
  className?: string;
  thumbnailUrl?: string;
  forceAutoPlay?: boolean;
};

export const MediaViewer = ({
  url,
  showVideoControls,
  alt,
  className,
  thumbnailUrl,
  forceAutoPlay = false,
}: MediaViewerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { onVideoProgress } = useTrackVideoProgress({
    video25PercentCallback: () => {
      analytics
        .track(
          "Marketing Video Progress 25",
          {
            slug: url,
          },
          {
            All: true,
            "Actions Google Analytics 4": true,
          }
        )
        .catch(catchAnalyticsError);
    },
    video50PercentCallback: () => {
      analytics
        .track(
          "Marketing Video Progress 50",
          {
            slug: url,
          },
          {
            All: true,
            "Actions Google Analytics 4": true,
          }
        )
        .catch(catchAnalyticsError);
    },
    videoCompletedCallback: async () => {
      analytics
        .track(
          "Marketing Video Watched",
          {
            slug: url,
          },
          {
            All: true,
            "Actions Google Analytics 4": true,
          }
        )
        .catch(catchAnalyticsError);
    },
  });

  return isVideo(url) ? (
    <video
      ref={videoRef}
      src={url}
      autoPlay={forceAutoPlay || !showVideoControls}
      muted={forceAutoPlay || !showVideoControls}
      loop={!showVideoControls}
      controlsList="nodownload"
      disablePictureInPicture
      controls={showVideoControls}
      className={className}
      poster={thumbnailUrl}
      onTimeUpdate={() => onVideoProgress(videoRef.current)}
    />
  ) : (
    <ImageContainer className={className}>
      <img src={url} className={className} alt={alt} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  max-height: 600px;
  max-width: 600px;
`;
