import { musicVolumeAtomWithAdjustment } from "features/sessions/client";
import { useAtomValue } from "jotai";
import ReactPlayer from "react-player";
import { Track } from "./music-context";
import { analytics, catchAnalyticsError } from "features/analytics";

type ReactMusicPlayerProps = React.ComponentProps<typeof ReactPlayer> & {
  reactPlayerRef: React.RefObject<ReactPlayer>;
  loadedUrl: string;
  onSeek: (progress: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  play: () => void;
  pause: () => void;
  isPlaying: boolean;
  currentTrack: Track | null;
  loadTrack: (track: Track) => void;
  isMuted: boolean;
  playerEventType: "Host Player Event" | "Player Event";
};

export const ReactMusicPlayer: React.FC<ReactMusicPlayerProps> = ({
  reactPlayerRef,
  loadedUrl,
  setIsLoading,
  play,
  pause,
  isPlaying,
  currentTrack,
  loadTrack,
  isMuted,
  playerEventType,
}) => {
  const finalVolume = useAtomValue(musicVolumeAtomWithAdjustment);

  return (
    <ReactPlayer
      style={{ display: "none" }}
      ref={reactPlayerRef}
      url={loadedUrl}
      onReady={(ready) => {
        setIsLoading(false);
        const loadedPlayer = ready.getInternalPlayer();
        const duration = loadedPlayer.getDuration();
        const track = {
          isLive: duration === 0 || duration > 60 * 60 * 10, // 10 hours
          title: loadedPlayer.videoTitle,
          duration: duration,
          url: loadedUrl,
        };
        loadTrack(track);
        analytics
          .track(playerEventType, {
            action: "Load",
            ...track,
          })
          .catch(catchAnalyticsError);
      }}
      volume={isMuted ? 0 : finalVolume}
      playing={isPlaying}
      height={0}
      width={0}
      onPlay={play}
      onPause={pause}
      onEnded={() => {
        pause();
        if (currentTrack) {
          void analytics.track(playerEventType, {
            action: "End",
            title: currentTrack.title,
            url: currentTrack.url,
            duration: currentTrack.duration,
          });
        }
      }}
    />
  );
};
