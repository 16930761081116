"use client";
import { getAllCachedEventsClient } from "features/events/client";
import dayjs from "dayjs";
import { BoxCol, CollapseToggle, EmptyState } from "design-system/components";
import { CalendarFilters } from "./filters/calendar-filters";
import { Box, Button, useToast } from "design-system/components";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIsMediaDesktop, useUserTimezone } from "utils/component-utils";
import { FEATURED_TAG, IS_HOST_TAG, SessionSource } from "utils/constants";
import {
  getIsCommunityEvent,
  getIsEventJoinable,
  getSessionUrlWithSource,
  getIsDropInEvent,
  getShouldShowInOrgCalendar,
  getP2PSessionUrl,
  isEventRegistered,
  getIsFeaturedCommunityEvent,
  getEventHasStarted,
} from "utils/event-utils";
import { lowercaseTrim } from "utils/string-utils";
import { getTagsSlugArray } from "utils/tag-utils";
import { EventsSliceArgs } from "../../shared/event-filters";
import { FlownEvent, FlownEvents } from "shared/event.types";
import { DayEvents } from "../../shared/event-types";
import { getCalendarProperties } from "../../shared/get-calendar-props";
import {
  AgendaViewCalendar,
  AgendaViewCalendarDayEvent,
} from "./agenda-view-calendar";
import { CalendarFilterOptions } from "../../shared/calendar-filters";
import { CalendarView, CALENDAR_VIEWS } from "../hooks/use-view-calendar";
import { useRefreshDay } from "../hooks/use-refresh-day";
import { useUserContext } from "context/user-context";
import { DROP_IN_ID, SESSION_SOURCE } from "utils/constants";
import { isWeekend } from "utils/date";
import {
  getRegisterUrlWithEmbeddedRedirects,
  SPRINGBOARD_BY_SLUG_URL_PREFIX,
} from "utils/urls";
import { BookSuccessToastContent, useGetSessionsAttended } from "../../client";
import { TimePickerModal } from "design-system/organisms/time-picker-modal";
import { getRooms, postToCreateUserEvent } from "features/sessions/client";
import { SessionsWrapper } from "./sessions-wrapper";
import { amendDropInDateAndSortEvents } from "../../shared/drop-in-utils";
import { bookEventInvite, cancelEventInvite } from "./event-invite-utils";
import {
  CalendarFiltersContextProvider,
  useCalendarFilters,
} from "./filters/calendar-filters-context";
import { CalendarAnalyticsContext } from "../../shared/calendar-types";
import { CheckCreditsModal } from "features/credits/client";
import { useSidePanel } from "context/side-panel-context";
import { getEventsBookedByOrgUsers } from "features/org/client";
import { getIsZoomFlock } from "utils/flocks-utils";
import { CalendarLoadingState } from "./calendar-loading-state";
import { EventSummaryWrapper } from "./event-summary-wrapper";
import { clientLoggerForFile } from "utils/logger.client";
import { styled } from "styled-system/jsx";
import { Modal } from "design-system/organisms/modals";
import { css } from "styled-system/css";
import {
  QUERY_PARAMS,
  QUERY_VALUES,
  getQueryParam,
} from "utils/url-query-utils";
import { token } from "styled-system/tokens";
import {
  CALENDAR_TAB_FILTERS,
  CalendarTabFilterValue,
} from "./filters/calendar-tab-filters";
import { useGetAdditionalEventTagsMap } from "../hooks/use-get-additional-event-tags-map";

const logger = clientLoggerForFile(__filename);

type CalendarWrapperProps = {
  id?: string;
  daysToShow?: number;
  attendedEventsIds?: string[];
  calendarSliceArgs: Partial<EventsSliceArgs>;
  tabFilter?: CalendarTabFilterValue;
  events: FlownEvents;
  calendarFilterOptions?: CalendarFilterOptions;
  organisationSlug?: string;
  /**
   * @param skipSorting some components may pre sort the events so we don't need to sort them again
   * for instance the `MyDayWidget`
   */
  skipSorting?: boolean;
  showOnlyBookedByOthers?: boolean;
  showEventFilters?: boolean;
  showHourSeparators?: boolean;
  showOnlyCommunitySessions?: boolean;
  onEventBooked?: (event: FlownEvent) => void;
  onEventJoined?: (events: FlownEvent) => void;
  onEventCancelled?: (event: FlownEvent) => void;
  scrollCalendar?: () => void;
  sortOrderForList?: "asc" | "desc";
  /**
   * @param analyticsContext use this to specify the context
   * in which analytics is firing for this calendar
   */
  analyticsContext: CalendarAnalyticsContext;
  forceView?: CalendarView;
  shouldFetchIfNoEvents?: boolean;
  previewInSidePanel?: boolean; // This toggles whether events show in the sidepanel
  /**
   *
   * @param showPreviewAndSidePanel This toggle works with the previewInSidePanel.
   * This determines that when preview in side panel is true whether we keep the
   * standard layout of the calendar with the preview on the right or we just show a
   * list of the calendar events.
   * ***/
  showPreviewAndSidePanel?: boolean; // If events are shown in the side panel this
};

const Calendar: React.FC<CalendarWrapperProps> = ({
  attendedEventsIds,
  calendarSliceArgs,
  daysToShow,
  events,
  id,
  showEventFilters = true,
  showHourSeparators = false,
  analyticsContext,
  onEventBooked,
  onEventJoined,
  onEventCancelled,
  organisationSlug,
  previewInSidePanel = false,
  showPreviewAndSidePanel = false,
  showOnlyCommunitySessions = false,
  sortOrderForList = "asc",
  showOnlyBookedByOthers = false,
  shouldFetchIfNoEvents = true,
  skipSorting = false,
}) => {
  const router = useRouter();
  const currentFilter = getQueryParam(
    router.query[QUERY_PARAMS.CALENDAR_FILTER]
  );

  const {
    loggedIn,
    email,
    externalId,
    isFreeRole,
    isFreeCreditsRole,
    registeredEventsIds,
    setRegisteredEventsIds,
    isFlownRole,
  } = useUserContext();
  const { userTimezone } = useUserTimezone();
  const refreshDay = useRefreshDay();

  const { sessionsAttended } = useGetSessionsAttended();
  const [isTimePickerModalOpen, setIsTimePickerModalOpen] = useState(false);
  const [timePickerEvent, setTimePickerEvent] = useState<FlownEvent | null>(
    null
  );
  const [isCreditCheckModalOpen, setIsCreditCheckModalOpen] = useState(false);
  const [creditCheckEvent, setCreditCheckEvent] = useState<FlownEvent | null>(
    null
  );
  const [showEventSummaryModal, setShowEventSummaryModal] = useState(false);

  const { closeSidePanel } = useSidePanel();
  const { toast, Toast } = useToast();
  const {
    tabFilter,
    resetFilterOptions,
    selectedFilters,
    setSessionTypeOptions,
    view,
  } = useCalendarFilters();

  const [viewableEventsList, setViewableEventsList] = useState<FlownEvents>([]);

  /**
   * Events by day are always computed on the client so they are generated while taking
   * the user's current timezone into account.
   */
  const [allEventsByDay, setAllEventsByDay] = useState<DayEvents>({});

  const additionalEventTagsMap = useGetAdditionalEventTagsMap();

  const applyCalendarFilters = useCallback(
    (
      eventList: FlownEvents,
      { onlyFutureAndJoinableEvents } = { onlyFutureAndJoinableEvents: true }
    ) => {
      const { category, sessionType, tag, facilitator } = selectedFilters;

      const applyTagFilters = (event: FlownEvent) => {
        if (!tag) return true;
        if (event.partnership) {
          return tag === event.partnership.slug;
        } else if (event.tags) {
          return getTagsSlugArray(event.tags).includes(tag);
        }
        return true;
      };

      // add featured tag to events that are registered so they show on featured tab
      // use this function to add other tags if needed
      const eventListDecorated = eventList.map((event) => {
        const decoratedEvent = { ...event };
        const eventTags = decoratedEvent.tags || [];
        if (registeredEventsIds.includes(event.id)) {
          eventTags.push(FEATURED_TAG);
        }
        if (additionalEventTagsMap) {
          const additionalTags = additionalEventTagsMap[event.id];
          if (additionalTags) {
            eventTags.push(...additionalTags);
          }
        }

        // if you are the host and you are a free role treat it as a free session
        // so you can see the event in the calendar
        if (isFreeRole && event.host === externalId) {
          decoratedEvent.isFreeSession = true;
          eventTags.push(IS_HOST_TAG);
        }

        if (eventTags.length > 0) {
          decoratedEvent.tags = [...eventTags];
        }

        return decoratedEvent;
      });

      const filteredEventListDecorated = eventListDecorated.filter(
        (event) =>
          (tabFilter === CALENDAR_TAB_FILTERS.ATTENDING.value
            ? // Flag check is better performant. Second check is for updates on the client
              event.flags?.isUserRegistered ||
              registeredEventsIds.includes(event.id)
            : tabFilter === CALENDAR_TAB_FILTERS.HOSTING.value
            ? event.populatedHost?.externalId === externalId
            : true) &&
          (currentFilter === QUERY_VALUES.CALENDAR_COMMUNITY
            ? getIsCommunityEvent(event)
            : true) &&
          (currentFilter === QUERY_VALUES.CALENDAR_FEATURED
            ? !getIsCommunityEvent(event) || getIsFeaturedCommunityEvent(event)
            : true) &&
          (category !== "all-sessions"
            ? lowercaseTrim(event.category.displayName) ===
              lowercaseTrim(category)
            : true) &&
          (sessionType ? event.displayName === sessionType : true) &&
          applyTagFilters(event) &&
          // Account for drop-ins having facilitatorName = ""
          (facilitator
            ? event.facilitator && event.facilitator.name
              ? facilitator === event.facilitator.name
              : false
            : true) &&
          !event.deleted &&
          (onlyFutureAndJoinableEvents
            ? !getEventHasStarted(event.startTime) || getIsEventJoinable(event)
            : true) &&
          /**
           * Hide private unless the user is the host or they are booked in
           * This is hiding other user's private sessions
           * from being viewed by admins on Eagle and profiles
           * so we bypass this filter for flownies as it helps debugging
           */
          (isFlownRole
            ? true
            : event.privacy === "private"
            ? event.host === externalId ||
              event.flags?.isUserRegistered ||
              registeredEventsIds.includes(event.id)
            : true)
      );

      return filteredEventListDecorated;
    },
    // Omiting `registeredEventsIds` bc we don't need to have that updating the filtered events when changed
    // We get the instant feedback on the calendar Icon, and if we need to update the filtered events
    // that means the user has navigated to a different page, such as "My sessions - Attending"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // registeredEventsIds,
      selectedFilters,
      tabFilter,
      externalId,
      showEventFilters,
      currentFilter,
      additionalEventTagsMap,
    ]
  );

  useEffect(() => {
    resetFilterOptions(allEventsByDay);
    // Only run this when events or filters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allEventsByDay,
    selectedFilters.category,
    selectedFilters.sessionType,
    selectedFilters.facilitator,
    selectedFilters.tag,
  ]);

  // TODO: BUG - calendar keeps loading when there are no events found because
  // Of the filters
  const [status, setStatus] = useState<
    "idle" | "pending" | "resolved" | "rejected"
  >(() => {
    if (events === undefined || events.length === 0) {
      return "pending";
    }
    return "idle";
  });
  const amendDropInDateAndSortEventsCallback = useCallback(
    (events: FlownEvents) => amendDropInDateAndSortEvents(events, userTimezone),
    [userTimezone]
  );

  useEffect(() => {
    const getAllEvents = async () => {
      if (shouldFetchIfNoEvents && (!events || events.length === 0)) {
        if (organisationSlug && showOnlyBookedByOthers) {
          const bookingsRes = await getEventsBookedByOrgUsers(
            organisationSlug
          )();
          if (bookingsRes.worked) {
            const uniqueBookings = bookingsRes.events;
            setStatus("resolved");
            return uniqueBookings;
          }
        }
        if (showOnlyCommunitySessions) {
          try {
            if (organisationSlug) {
              const [roomsRes, eventsRes] = await Promise.all([
                getRooms(organisationSlug),
                getAllCachedEventsClient(),
              ]);
              if (roomsRes.worked && eventsRes.worked) {
                const rooms = roomsRes.rooms.filter((room) =>
                  getIsCommunityEvent(room)
                );
                const events = amendDropInDateAndSortEventsCallback(
                  eventsRes.events.filter((event) =>
                    getShouldShowInOrgCalendar(event)
                  )
                );

                setStatus("resolved");
                return [...rooms, ...events];
              }
            } else {
              const response = await getRooms();
              if (!response.worked) {
                setStatus("rejected");
                logger.error("Failed to get community rooms");
                return;
              }
              setStatus("resolved");
              return response.rooms.filter((room) => getIsCommunityEvent(room));
            }
          } catch (e) {
            setStatus("rejected");
          }
        } else {
          try {
            const response = await getAllCachedEventsClient();

            if (!response.worked) {
              setStatus("rejected");
              return;
            } else {
              const events = response.events;
              setStatus("resolved");
              return amendDropInDateAndSortEventsCallback(events);
            }
          } catch (e) {
            setStatus("rejected");
          }
        }
      }
      return amendDropInDateAndSortEventsCallback(events);
    };
    getAllEvents()
      .then((events) => {
        if (events) {
          if (
            attendedEventsIds &&
            attendedEventsIds.length > 0 &&
            !calendarSliceArgs.startDate &&
            view === "list"
          ) {
            //  niche case where we're looking at attended events in list view
            //  we need to set that we can show past events and then don't go through
            //  the calendar filters.  This needs to be refactored ideally moving everything
            //  to specific filters.  If you change this check the past sessions tab in the calendar
            const eventsUserCanView = applyCalendarFilters(events, {
              onlyFutureAndJoinableEvents: false,
            });
            setViewableEventsList(eventsUserCanView);
          } else {
            const eventsUserCanView = applyCalendarFilters(events);

            const { eventsByDay, uniqueFilters, filteredEvents } =
              getCalendarProperties(eventsUserCanView, {
                ...calendarSliceArgs,
                timezone: userTimezone,
                skipSorting,
              });
            setViewableEventsList(filteredEvents);
            setSessionTypeOptions(uniqueFilters.sessionTypes);
            setAllEventsByDay(eventsByDay);
          }
        }
      })
      .catch((err) => {
        logger.error(`Error getting events: ${err}`);
      });
  }, [
    amendDropInDateAndSortEventsCallback,
    applyCalendarFilters,
    attendedEventsIds,
    calendarSliceArgs,
    events,
    organisationSlug,
    setSessionTypeOptions,
    skipSorting,
    shouldFetchIfNoEvents,
    showOnlyBookedByOthers,
    showOnlyCommunitySessions,
    userTimezone,
    view,
  ]);

  const filterDaysToShow = (agendaDay: AgendaViewCalendarDayEvent) => {
    const date = dayjs(agendaDay.day);
    if (isWeekend(date.day()) && agendaDay.events.length === 0) {
      return false;
    }
    return date.toISOString() >= dayjs().toISOString() || date.isToday();
  };

  const eventsByDayList = useMemo(() => {
    // We skip any further filtering if a start date slice arg has been provided. We assume that all events starting then should be displayed
    if (calendarSliceArgs.startDate) {
      return Object.keys(allEventsByDay).map((dayKey) => ({
        day: dayKey,
        events: allEventsByDay[dayKey].events,
      }));
    }
    return Object.keys(allEventsByDay)
      .map((dayKey) => ({
        day: dayKey,
        events: allEventsByDay[dayKey].events,
      }))
      .filter(filterDaysToShow);
  }, [calendarSliceArgs.startDate, allEventsByDay]);

  const eventSource = useMemo(
    () =>
      analyticsContext === "calendar"
        ? (`${SESSION_SOURCE.CALENDAR_PAGE}-${currentFilter}` as SessionSource)
        : SESSION_SOURCE.AGENDA_VIEW_SPRINGBOARD,
    [analyticsContext, currentFilter]
  );

  const bookEvent = useCallback(
    async (event: FlownEvent) => {
      if (!loggedIn) {
        void router.push(
          getRegisterUrlWithEmbeddedRedirects(event.springBoardUrl)
        );
        return;
      }

      await bookEventInvite({
        event,
        analyticsData: {
          eventSource,
          context: "calendar",
        },
        onEmailSent: (emailSent) => {
          toast(
            "success",
            <BookSuccessToastContent email={email} emailSent={emailSent} />
          );
          setRegisteredEventsIds((prev) => [...prev, event.id]);
          onEventBooked?.(event);
        },
        onEmailFailed: (errorMessage: string) => {
          toast("error", errorMessage);
        },
      });
    },
    [
      setRegisteredEventsIds,
      onEventBooked,
      eventSource,
      router,
      email,
      toast,
      loggedIn,
    ]
  );

  const onEventBookingCancelled = useCallback(
    async (event: FlownEvent) => {
      await cancelEventInvite({
        event,
        analyticsData: {
          eventSource,
          context: "calendar",
        },
        onEmailSent: () => {
          toast(
            "info",
            `You have cancelled your ${event.displayName} booking `
          );
          setRegisteredEventsIds((prev) =>
            prev.filter((id) => id !== event.id)
          );
        },
        onEmailFailed: (errorMessage: string) => {
          toast("error", errorMessage);
        },
      });
    },
    [setRegisteredEventsIds, eventSource, toast]
  );

  const _onEventBook = useCallback(
    async (event: FlownEvent) => {
      if (getIsDropInEvent(event) && loggedIn) {
        setTimePickerEvent(event);
        setIsTimePickerModalOpen(true);
        return;
      }
      await bookEvent(event);
    },
    [bookEvent, loggedIn]
  );

  const onDropInBooked = useCallback(
    async (time: { startTime: string; endTime: string }) => {
      if (timePickerEvent) {
        const response = await postToCreateUserEvent({
          ...timePickerEvent,
          startTime: time.startTime,
          endTime: time.endTime,
          springBoardUrl: `${SPRINGBOARD_BY_SLUG_URL_PREFIX}/${DROP_IN_ID}`,
        });
        if (!response.worked) {
          toast(
            "error",
            `Your booking could not be made. Please try again. The following error occurred: ${response.error}`
          );
          return;
        }
        const { userEvent } = response;
        await bookEvent(userEvent as FlownEvent);
      }
    },
    [bookEvent, timePickerEvent, toast]
  );

  const onEventJoin = (event: FlownEvent) => {
    if (!loggedIn) {
      void router.push(
        getRegisterUrlWithEmbeddedRedirects(event.springBoardUrl)
      );
    } else {
      if (
        !event.isFreeSession &&
        isFreeCreditsRole &&
        !creditCheckEvent &&
        !isCreditCheckModalOpen
      ) {
        setIsCreditCheckModalOpen(true);
        setCreditCheckEvent(event);
        return;
      }
      const isDropIn = getIsDropInEvent(event);

      if (event.isP2P) {
        const sessionUrl = getP2PSessionUrl(event, eventSource);
        void router.push(sessionUrl);
      } else {
        // If the event is not a P2P session or a drop-in, it will
        // have a zoom link and open in a new tab
        if (getIsZoomFlock(event) && !isDropIn) {
          window.open(
            getSessionUrlWithSource(event.sessionUrl, eventSource),
            "_blank"
          );
        } else {
          void router.push(event.sessionUrl);
        }
      }
      onEventJoined?.(event);
    }
  };

  const isDesktop = useIsMediaDesktop();
  const [listViewSlice, setListViewSlice] = useState(10);

  const [selectedEvent, setSelectedEvent] = useState<FlownEvent | null>(
    viewableEventsList[0]
  );

  useEffect(() => {
    if (view === "only-event-summary") {
      setSelectedEvent(events[0]);
    }
  }, [view, events]);

  const selectEvent = useCallback(
    (event: FlownEvent) => {
      setSelectedEvent(event);
      if (!isDesktop) {
        setShowEventSummaryModal(true);
      }
    },
    [isDesktop]
  );

  const _onEventCancelled = useCallback(
    (cancelledEvent: FlownEvent) => {
      if (onEventCancelled) {
        onEventCancelled(cancelledEvent);
        closeSidePanel();
      }
    },
    [closeSidePanel, onEventCancelled]
  );

  /**
   * Always ensure that the first event in the chain is selected if
   * there was none previously; this should fire as filters are applied
   * or removed
   */

  const EventSummaryWrapperInstance = selectedEvent ? (
    <EventSummaryWrapper
      selectedEvent={selectedEvent}
      isSelectedEventBooked={isEventRegistered(
        registeredEventsIds,
        selectedEvent
      )}
      showPreviewOnlySidePanel={previewInSidePanel && !showPreviewAndSidePanel}
      sessionsAttended={sessionsAttended}
      onCancelBooking={() => onEventBookingCancelled(selectedEvent)}
      onSendEmailInvite={() => _onEventBook(selectedEvent)}
      onEventJoined={onEventJoin}
      openHowItWorks={isDesktop}
      onEventCancelled={_onEventCancelled}
    />
  ) : null;

  return (
    <>
      {Toast}
      <CheckCreditsModal
        open={isCreditCheckModalOpen}
        event={creditCheckEvent}
        onClose={() => {
          setIsCreditCheckModalOpen(false);
          setCreditCheckEvent(null);
          // Close any open side panels
          closeSidePanel();
        }}
        onJoinConfirmed={() =>
          creditCheckEvent && onEventJoin(creditCheckEvent)
        }
      />
      <TimePickerModal
        initialStartTime={timePickerEvent?.startTime}
        onJoinClicked={() => {
          if (timePickerEvent) {
            onEventJoin(timePickerEvent);
          }
        }}
        open={isTimePickerModalOpen}
        onClose={() => setIsTimePickerModalOpen(false)}
        onTimeSubmit={(time) => onDropInBooked(time)}
      />

      <CalendarContainerSection id={id}>
        {view === CALENDAR_VIEWS.ONLY_EVENT_SUMMARY ? (
          EventSummaryWrapperInstance
        ) : (
          <Box
            className={css({
              "bp-desktop-xs": {
                display: "grid",
                gridGap: "32",
                gridTemplateColumns: previewInSidePanel
                  ? "100%"
                  : "minmax(0, 1.2fr) 1fr",
              },
            })}
          >
            {view === CALENDAR_VIEWS.LIST ? (
              viewableEventsList.length > 0 ? (
                <BoxCol>
                  {showEventFilters && <CalendarFilters />}
                  <SessionsWrapper
                    events={
                      skipSorting
                        ? viewableEventsList.slice(0, listViewSlice)
                        : viewableEventsList
                            .slice(0, listViewSlice)
                            .sort((a, b) => {
                              const initialOrder =
                                dayjs(a.startTime).unix() -
                                  dayjs(b.startTime).unix() >
                                0
                                  ? 1
                                  : -1;
                              if (sortOrderForList === "asc") {
                                return initialOrder;
                              } else {
                                return initialOrder * -1;
                              }
                            })
                    }
                    refreshDay={refreshDay}
                    attendedEventsIds={attendedEventsIds ?? []}
                    registeredEventsIds={registeredEventsIds}
                    onEventBooked={_onEventBook}
                    onEventBookingCancelled={onEventBookingCancelled}
                    previewInSidePanel={previewInSidePanel}
                    onEventClick={selectEvent}
                    showHourSeparators={showHourSeparators}
                    showDaySeparators={!daysToShow || daysToShow > 1}
                    onEventCancelled={_onEventCancelled}
                  />
                  {viewableEventsList.length > listViewSlice && (
                    <Box className="flex justify-center margin-auto w-full mar-y-24">
                      <Button
                        onClick={() => setListViewSlice((prev) => prev + 10)}
                      >
                        Show more
                      </Button>
                    </Box>
                  )}
                </BoxCol>
              ) : status === "pending" ? (
                <CalendarLoadingState />
              ) : (
                // Empty div to preserv grid layout
                <div />
              )
            ) : (
              <>
                {showEventFilters && (
                  <CalendarCollapseToggle
                    CalendarCollapseToggle
                    title="Calendar filters"
                  >
                    <CalendarFilters />
                  </CalendarCollapseToggle>
                )}
                <AgendaViewCalendar
                  onEventBooked={_onEventBook}
                  onEventBookingCancelled={onEventBookingCancelled}
                  bookedEventIds={registeredEventsIds}
                  eventsByDayList={eventsByDayList}
                  dates={Object.keys(allEventsByDay)}
                  refreshDay={refreshDay}
                  id={id}
                  previewInSidePanel={previewInSidePanel}
                  status={status}
                  onEventClick={selectEvent}
                  selectedEventId={selectedEvent?.id}
                />
              </>
            )}
            <BoxCol>
              {view === "agenda" && (
                <Box
                  className={css({
                    alignItems: "center",
                    justifyContent: "flex-end",
                    display: "none",
                    marginBottom: showEventFilters ? "16" : "0",
                    marginTop: tabFilter ? "-70px" : "0",
                    "bp-desktop-xs": {
                      display: showEventFilters ? "flex" : "none",
                      height: showEventFilters ? "auto" : "0",
                    },
                  })}
                >
                  <CalendarFilters />
                </Box>
              )}

              {!isDesktop ? (
                <Modal
                  open={showEventSummaryModal}
                  title=""
                  onClose={() => setShowEventSummaryModal(false)}
                >
                  <Box className="pad-16">{EventSummaryWrapperInstance}</Box>
                </Modal>
              ) : status !== "pending" ? (
                !previewInSidePanel &&
                viewableEventsList.length > 0 && (
                  <Box
                    className={css({
                      "bp-desktop-sm": {
                        paddingTop: showEventFilters ? "0" : "28",
                      },
                    })}
                  >
                    {EventSummaryWrapperInstance}
                  </Box>
                )
              ) : null}
            </BoxCol>
            {viewableEventsList.length === 0 && status !== "pending" ? (
              <Box
                style={{
                  gridColumn: "1 / end",
                }}
              >
                <EmptyState text={"No sessions found"} />
              </Box>
            ) : null}
          </Box>
        )}
      </CalendarContainerSection>
    </>
  );
};

export const CalendarWrapper = ({
  forceView,
  ...props
}: CalendarWrapperProps) => (
  <CalendarFiltersContextProvider
    calendarId={props.id ?? "calendar"}
    forceView={forceView}
    tabFilter={props.tabFilter}
  >
    <Calendar {...props} />
  </CalendarFiltersContextProvider>
);

const CalendarContainerSection = styled("section", {
  base: {
    maxWidth: token("sizes.maxWidth.content"),
    marginLeft: 16,
    marginRight: 16,
    "bp-desktop-xs": {
      margin: "0 auto",
      width: "100%",
    },
  },
});

const CalendarCollapseToggle = styled(CollapseToggle, {
  base: {
    padding: "{spacing.16} {spacing.12}",
    display: "block",
    "bp-desktop-xs": {
      display: "none",
    },
  },
});
