import { ChangeEvent, useState } from "react";
import { Box, Button, Heading, Input, Text } from "design-system/components";
import { analytics, catchAnalyticsError } from "features/analytics";
import { useUserContext } from "context/user-context";
import styled from "@emotion/styled";

type CampaignSubscriptionBlockProps = {
  campaignDescription?: string | null;
  title: string | null;
  campaignName: string;
  ctaText?: string | null | undefined;
};

export const CampaignSubscriptionBlock = ({
  title,
  campaignDescription,
  campaignName,
  ctaText,
}: CampaignSubscriptionBlockProps) => {
  const [email, setEmail] = useState("");
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { email: loggedInUserEmail } = useUserContext();

  return (
    <CampaignSubscriptionWrapper>
      <Box>
        <Heading as="h3">{title}</Heading>
        <Text className="mar-y-16" style={{ opacity: 0.7 }}>
          {campaignDescription}
        </Text>
      </Box>
      <Box className="flex-col gap-16 d-sm:flex-row">
        {hasSubscribed ? (
          <Text>Thanks for Subscribing!</Text>
        ) : (
          <>
            <Input
              type={"email"}
              placeholder="Enter your email"
              value={loggedInUserEmail || email}
              disabled={!!loggedInUserEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <Button
              variant="primary"
              isLoading={isLoading}
              style={{ minWidth: "100px", alignSelf: "center" }}
              onClick={async () => {
                await analytics
                  .identify({
                    email: loggedInUserEmail || email,
                    campaignName,
                    createdAt: new Date().toISOString(),
                  })
                  .catch(catchAnalyticsError);
                analytics
                  .track(
                    "Email Submission",
                    { campaignName },
                    {
                      All: true,
                    }
                  )
                  .catch(catchAnalyticsError);

                setIsLoading(true);
                setTimeout(() => {
                  setIsLoading(false);
                  setHasSubscribed(true);
                }, 1500);
              }}
            >
              {ctaText || "Subscribe"}
            </Button>
          </>
        )}
      </Box>
    </CampaignSubscriptionWrapper>
  );
};

const CampaignSubscriptionWrapper = styled.div(({ theme }) => ({
  alignContent: "center",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing[16],
  justifyContent: "space-between",
  maxWidth: "500px",
  margin: "0px auto",
  ".background-change &": {
    margin: "-50px auto",
  },
  [theme.media["bp-mobile-only"]]: {
    padding: `${theme.spacing[16]}`,
  },
}));
