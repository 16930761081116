import styled from "@emotion/styled";
import {
  CONTACT_URL,
  FLOWN_IN_PRESS_URL,
  FLOWN_SCIENCE_URL,
  FLOWN_STORY_URL,
  INSTAGRAM_URL,
  JOBS_WORKABLE_URL,
  LINKEDIN_URL,
  PRIVACY_POLICY_URL,
  PRICING_URL,
  TEAM_URL,
  TERMS_OF_USE_URL,
  TESTIMONIALS_URL,
  TWITTER_URL,
  FLOWN_FOR_BUSINESS_URL,
  RESOURCES_LINKS,
  AFFILIATE_URL,
  FACILITATORS_URL,
  MEMBERSHIP_BENEFITS_URL,
  HOW_IT_WORKS_URL,
  FAQS_URL,
  FACEBOOK_URL,
  ALL_SESSIONS_URL,
} from "utils/urls";
import { ContentContainer } from "../../legacy-design-system/content-container";
import { SocialMediaLinks } from "design-system/molecules/social-media/social-media-links";
import Link from "next/link";
import dayjs from "dayjs";
import { Trustpilot } from "features/trustpilot/client";
import { TRUSTPILOT_MINI_ID } from "features/trustpilot/shared";

const ABOUT_US_LINKS = [
  { label: "The FLOWN story", href: FLOWN_STORY_URL },
  { label: "The science behind FLOWN", href: FLOWN_SCIENCE_URL },
  { label: "What the press says", href: FLOWN_IN_PRESS_URL },
  { label: "Our team", href: TEAM_URL },
  { label: "Our facilitators", href: FACILITATORS_URL },
  { label: "Jobs", href: JOBS_WORKABLE_URL },
  { label: "For business", href: FLOWN_FOR_BUSINESS_URL },
  { label: "Affiliate Program", href: AFFILIATE_URL },
  { label: "Contact us", href: CONTACT_URL },
];
const WHAT_WE_OFFER_LINKS = [
  { label: "Membership benefits", href: MEMBERSHIP_BENEFITS_URL },
  { label: "How focus sessions work", href: HOW_IT_WORKS_URL },
  { label: "What our members say", href: TESTIMONIALS_URL },
  { label: "All sessions", href: ALL_SESSIONS_URL },
  { label: "Pricing", href: PRICING_URL },
  { label: "FAQs", href: FAQS_URL },
];

export const Footer = () => (
  <FooterWrapper>
    <ContentContainer>
      <Columns>
        <LogoColumn>
          <Logo />
          <SocialMediaLinks
            color="white"
            size="large"
            links={{
              twitter: TWITTER_URL,
              linkedin: LINKEDIN_URL,
              instagram: INSTAGRAM_URL,
              facebook: FACEBOOK_URL,
            }}
          />
        </LogoColumn>
        <Column>
          <ColumnHeader>What we offer</ColumnHeader>
          {WHAT_WE_OFFER_LINKS.map((link, i) => (
            <Link key={i} href={link.href}>
              <FooterLink>{link.label}</FooterLink>
            </Link>
          ))}
        </Column>
        <Column>
          <ColumnHeader>Resources</ColumnHeader>
          {RESOURCES_LINKS.map((link, i) => (
            <Link key={i} href={link.href}>
              <FooterLink>{link.label}</FooterLink>
            </Link>
          ))}
        </Column>
        <Column>
          <ColumnHeader>About us</ColumnHeader>
          {ABOUT_US_LINKS.map((link, i) => (
            <Link key={i} href={link.href}>
              <FooterLink>{link.label}</FooterLink>
            </Link>
          ))}
        </Column>
        <Column>
          <Trustpilot
            templateId={TRUSTPILOT_MINI_ID}
            height={150}
            theme="dark"
          />
        </Column>
      </Columns>
      <PrivacyAndCopyright>
        <Link href={TERMS_OF_USE_URL}>
          <PrivacyLink>{`Terms of use`}</PrivacyLink>
        </Link>
        <Link href={PRIVACY_POLICY_URL}>
          <PrivacyLink>{`Privacy policy`}</PrivacyLink>
        </Link>
        <Copyright>Copyright FLOWN {dayjs().year()}.</Copyright>
      </PrivacyAndCopyright>
    </ContentContainer>
  </FooterWrapper>
);

export const FOOTER_MARGIN_TOP = 115;

const FooterWrapper = styled.div`
  margin-top: ${FOOTER_MARGIN_TOP}px;
  padding-top: 90px;
  padding-bottom: 40px;
  color: white;
  background: black;
  display: flex;
  font-size: 15px;

  & .trustpilot-widget {
    transform: scale(0.8);
  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media only screen and (min-width: 900px) {
    margin: 0 2rem;
    width: auto;
  }
`;
const LogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 999.9px) {
    width: 100vw;
    margin: 0 auto 40px 10px;
  }
`;

const PrivacyAndCopyright = styled.div`
  margin-top: auto;
  font-size: 14px;
  @media only screen and (max-width: 999.9px) {
    margin: 80px auto 0 auto;
    text-align: center;
  }
`;

const Copyright = styled.div`
  font-size: 12px;
`;

const ColumnHeader = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const FooterLink = styled.div`
  display: block;
  margin-bottom: 20px;
  text-decoration: none;
`;

const PrivacyLink = styled(FooterLink)`
  text-decoration: underline;
`;

const Logo = styled.div`
  height: 23px;
  width: 150px;
  background: url(/assets/footer/flown-logo-white.svg) no-repeat;
  margin-bottom: 30px;
`;
