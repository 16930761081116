import styled from "@emotion/styled";
import { Heading, Box, Button } from "design-system/components";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";
import { FC, ReactNode } from "react";
import { css } from "styled-system/css";

type NumberedListProps = {
  title: string | null;
  description?: string | null;
  items: ReactNode[];
  ctaLink?: string | null;
  ctaLabel?: string | null;
  color?: {
    hex: string;
  };
};

// TODO: this component seems to be dependent on modular contnet
// directly and should probabaly not be part of the DS
export const NumberedList: FC<NumberedListProps> = ({
  title,
  description,
  items,
  ctaLabel,
  ctaLink,
  color = { hex: "#fff" },
}) => {
  return (
    <Box
      className={`flex-col align-center mar-auto pad-x-32 ${css({
        maxWidth: THEME_BREAKPOINTS["desktop-xs"],
        zIndex: 10,
        position: "inherit",
        color: color.hex,
      })}`}
    >
      {/* This should be a new headign but we want to migrate across to new blocks */}
      {title && (
        <h2 className="mar-y-32" style={{ fontSize: "clamp(24px, 4vw, 36px)" }}>
          {title}
        </h2>
      )}
      <p className={css({ color: color.hex })}>{description}</p>
      <ListItemsWrapper className="flex-wrap gap-16 pad-y-32">
        {items &&
          items.map((step, i) => {
            return (
              <Box className="flex" key={i}>
                <Heading style={{ color: color.hex }} as="h3">
                  {i + 1}
                </Heading>
                <ListItemContent>
                  <PositionLine
                    style={{ borderBottom: `2px solid ${color.hex}` }}
                  />
                  {step}
                </ListItemContent>
              </Box>
            );
          })}
      </ListItemsWrapper>
      {ctaLink && (
        <Button
          variant="secondary"
          className="mar-auto mar-y-32"
          size="large"
          href={ctaLink}
          as="a"
        >
          {ctaLabel}
        </Button>
      )}
    </Box>
  );
};

const ListItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({ theme }) => theme.media["bp-tablet"]} {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`;

const ListItemContent = styled.div`
  margin-left: 0.625rem;
  margin-top: 2.5rem;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.md};
  p {
    color: white;
  }
`;

const PositionLine = styled.div`
  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing[12]};
`;
