import { useUserContext } from "context/user-context";
import dayjs from "dayjs";
import { PrimaryButton, SecondaryButton } from "legacy-design-system";
import { analytics } from "features/analytics";
import { useEffect, useState } from "react";
import { formatToShortISO } from "utils/date";
import {
  postToAddUserActivity,
  postToHasUserJoinedChallenge,
} from "../user-activities-client";

type ChallengeCTAButtonProps = {
  href: string;
  label: string;
  slug: string;
  style: string;
  hasJoinedChallengeText: string;
};

export const ChallengeCTAButton: React.FC<ChallengeCTAButtonProps> = ({
  label,
  slug,
  href,
  style,
  hasJoinedChallengeText,
}) => {
  const { loggedIn } = useUserContext();
  const [hasJoinedChallenge, setHasJoinedChallenge] = useState(false);

  useEffect(() => {
    if (!loggedIn) return;
    const checkChallengeCompletion = async () => {
      const response = await postToHasUserJoinedChallenge({
        slug,
      });
      if (response.worked) {
        const { hasJoinedChallenge } = response;
        setHasJoinedChallenge(hasJoinedChallenge);
      }
    };
    void checkChallengeCompletion();
  }, [slug, loggedIn]);

  const onChallengeJoined = async () => {
    if (!hasJoinedChallenge) {
      await analytics.track("Challenge Joined", {
        slug,
        dateJoined: formatToShortISO(dayjs()),
      });
      await postToAddUserActivity({
        product: "challenge",
        payload: {
          action: "joined",
          slug,
        },
      });
    }
  };
  return style === "primary" ? (
    <PrimaryButton onClick={onChallengeJoined} href={href}>
      {hasJoinedChallenge ? hasJoinedChallengeText : label}
    </PrimaryButton>
  ) : (
    <SecondaryButton onClick={onChallengeJoined} href={href}>
      {hasJoinedChallenge ? hasJoinedChallengeText : label}
    </SecondaryButton>
  );
};
