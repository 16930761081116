import {
  FacebookIcon,
  LinkedinIcon,
  XIcon,
  TwitterShareButton,
  WhatsappIcon,
} from "react-share";
import { Icon } from "design-system/components";
import { useCopyToClipboard } from "utils/hooks/use-copy-to-clipboard";
import { Box } from "../../components";
import { Popover } from "../../components";
import {
  getStyleProps,
  SocialMediaButton,
  SocialMediaCommonProps,
} from "./social-media-commons";
import { ReactNode } from "react";
import { css } from "styled-system/css";
import { ClassNameProvider } from "utils/component-utils";
import Link from "next/link";

export type SocialMediaShareButtonsProps = SocialMediaCommonProps &
  ClassNameProvider & {
    /**
     * @property {"[facebook" | "messenger" | "twitter" | "linkedin" | "whatsapp"]} - List of social medias not to be showed.
     */
    hide?: (
      | "facebook"
      | "messenger"
      | "twitter"
      | "linkedin"
      | "whatsapp"
      | "mail"
      | "copy"
    )[];
    title?: string;
    shareUrl: string;
  };

const shareWindowSize = {
  windowHeight: 600,
  windowWidth: 1000,
};

export const SocialMediaShareButtons = ({
  color = "black",
  className,
  hide = [],
  title = "",
  size = "medium",
  shareUrl,
}: SocialMediaShareButtonsProps) => {
  const [, copyToClipboard, CopyToClipboardToast] = useCopyToClipboard();

  const { buttonSizeStyles, iconStyles } = getStyleProps(size, color);

  return (
    <>
      {CopyToClipboardToast}
      <Box className={`flex gap-8 ${className ?? ""}`}>
        {!hide.includes("facebook") && (
          <Link
            href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
              shareUrl
            )}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FacebookIcon round {...iconStyles} />
          </Link>
        )}
        {!hide.includes("twitter") && (
          <TwitterShareButton
            url={shareUrl}
            title={title}
            {...buttonSizeStyles}
            {...shareWindowSize}
          >
            <XIcon round {...iconStyles} />
          </TwitterShareButton>
        )}
        {!hide.includes("linkedin") && (
          <Link
            href={`whatsapp://send?text=${encodeURI(`${title}: ${shareUrl}`)}`}
          >
            <WhatsappIcon round {...iconStyles} />
          </Link>
        )}
        {!hide?.includes("whatsapp") && (
          <Link
            href={`https://www.linkedin.com/?shareActive=true&text=${title}: ${shareUrl}`}
            target="_blank"
          >
            <LinkedinIcon round {...iconStyles} />
          </Link>
        )}

        {!hide.includes("mail") && (
          <SocialMediaButton
            color={color}
            as="a"
            href={`mailto:?subject=${title}&body=${shareUrl}`}
            {...buttonSizeStyles}
          >
            <Icon
              color={iconStyles.iconFillColor}
              icon="mail"
              size={iconStyles.size}
              className={css({
                padding: "6px",
              })}
            />
          </SocialMediaButton>
        )}
        {!hide.includes("copy") && (
          <SocialMediaButton
            color={color}
            as={"button"}
            onClick={() =>
              copyToClipboard(`${title ? title + ": " : ""}${shareUrl}`)
            }
            {...buttonSizeStyles}
          >
            <Icon
              color={iconStyles.iconFillColor}
              icon="copy"
              size={iconStyles.size}
              className={css({
                padding: "6px",
              })}
            />
          </SocialMediaButton>
        )}
      </Box>
    </>
  );
};

type SocialMediaShareButtonsPopoverProps = SocialMediaShareButtonsProps & {
  trigger: ReactNode;
};

export const SocialMediaShareButtonsPopover = ({
  trigger,
  ...props
}: SocialMediaShareButtonsPopoverProps) => {
  return (
    <Popover triggerElement={trigger}>
      <SocialMediaShareButtons {...props} />
    </Popover>
  );
};
