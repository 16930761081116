import styled from "@emotion/styled";
import { useSidePanel } from "context/side-panel-context";
import { useUserContext } from "context/user-context";
import { getUserDisplayName } from "features/profile/shared";
import { Box, BoxFlex, Icon, Text } from "design-system/components";
import { Avatars, MAX_AVATARS_TO_SHOW } from "design-system/molecules/avatars";
import { UserCard } from "design-system/molecules/cards/user-card";
import React, { useMemo } from "react";
import { ProfileTag, UserProfileFromActivity } from "shared/user.types";
import { pluralise } from "utils/string-utils";
import { PopulatedHost } from "shared/event.types";
import { getIsNewbie } from "services/roles/user-roles";

type SessionAttendanceProps = {
  users: UserProfileFromActivity[];
  connections: UserProfileFromActivity[];
  commonTags: ProfileTag[];
  hasEventStarted?: boolean;
  isInProgress?: boolean;
  isReducedView?: boolean;
  host?: PopulatedHost;
};

export const SessionAttendance: React.FC<SessionAttendanceProps> = ({
  users = [],
  connections = [],
  // commonTags = [],
  hasEventStarted = false,
  host,
  isInProgress = false,
  isReducedView = false,
}) => {
  const {
    // profileTags,
    analyticsData,
    organisation,
  } = useUserContext();
  const { openDynamicSidePanel } = useSidePanel();

  const isHostInSession = useMemo(
    () => host && users.find((user) => user.externalId === host.externalId),
    [host, users]
  );

  const organisationUsers = useMemo(
    () =>
      organisation
        ? users.filter(
            (user) =>
              user.organisation?.id === organisation.id &&
              user.externalId !== host?.externalId &&
              !connections.some(
                (connection) => connection.externalId === user.externalId
              )
          )
        : [],
    [connections, host?.externalId, organisation, users]
  );

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.externalId !== host?.externalId &&
          !organisationUsers.includes(user) &&
          !connections.find(
            (connection) => connection.externalId === user.externalId
          )
      ),
    [users, host?.externalId, organisationUsers, connections]
  );

  const newbieUsers = useMemo(
    () => users.filter((user) => getIsNewbie(user)),
    [users]
  );

  const excludeNewbies = (user: UserProfileFromActivity) => {
    if (isNewbie) {
      // Exclude newbies from the list of members as they will be shown above
      // We only do this if the user seeing the list is a newbie
      return !getIsNewbie(user);
    }
    return true;
  };

  const registrationsText = useMemo(() => {
    if (isReducedView) return;
    const connectionsCount = connections.length;
    const orgUsersCount = organisationUsers.length;
    const otherUsersCount = filteredUsers.length;

    const hasOrgOrOtherUsers = orgUsersCount || otherUsersCount;

    return (
      <>
        {connectionsCount > 0 && (
          <p>
            {connectionsCount} {pluralise(connectionsCount, "connection")}
            {hasOrgOrOtherUsers ? ", " : ""}
          </p>
        )}
        {orgUsersCount > 0 && (
          <p>
            {orgUsersCount} from {organisation?.name}{" "}
            {orgUsersCount ? ", " : ""}
          </p>
        )}
        {otherUsersCount > 0 && (
          <p>
            {otherUsersCount} other {pluralise(otherUsersCount, "member")}
          </p>
        )}
        <p>
          {users.length > 0 &&
            (isInProgress
              ? "in the session"
              : hasEventStarted
              ? "attended"
              : "attending")}
        </p>
      </>
    );
  }, [
    isReducedView,
    connections.length,
    organisationUsers.length,
    filteredUsers.length,
    organisation?.name,
    users.length,
    isInProgress,
    hasEventStarted,
  ]);

  const avatarList = useMemo(
    () =>
      users
        .filter(({ externalId }) => externalId !== host?.externalId)
        .slice(0, MAX_AVATARS_TO_SHOW)
        .map((user) => ({
          imageUrl: user.avatarUrl,
          userFullName: getUserDisplayName(user),
        })),
    [host?.externalId, users]
  );

  const isNewbie = getIsNewbie({ analyticsData });

  const filteredUsersWithNewbieFilter = filteredUsers.filter(excludeNewbies);

  return (
    <>
      {users.length > 0 ? (
        <Box
          className="flex align-center gap-12 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            openDynamicSidePanel({
              title: "In this session",
              content: (
                <>
                  <Box>
                    {host && isHostInSession && (
                      <SectionContainer>
                        <BoxFlex className="mar-bot-12 gap-4">
                          <Icon icon="profile" size={18} color="grey600" />
                          <Text fontSize="sm" fontWeight={600} color="grey600">
                            Host
                          </Text>
                        </BoxFlex>
                        <UserCard
                          displayName={getUserDisplayName(host)}
                          oneLiner={host.oneLiner}
                          externalId={host.externalId}
                          avatarUrl={host.avatarUrl}
                        />
                      </SectionContainer>
                    )}
                    {/* Are we getting rid of the common tags section?  */}
                    {/* {(commonTags.length > 0 ||
                      !profileTags ||
                      profileTags.length === 0) && (
                      <SectionContainer>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="grey400"
                          className="mar-bot-12"
                        >
                          Common interests
                        </Text>
                        {commonTags.length > 0 ? (
                          <TagsList tags={commonTags} />
                        ) : (
                          (!profileTags || profileTags.length === 0) && (
                            <>
                              <Text
                                className="mar-y-8"
                                color="grey400"
                                fontSize="xs"
                              >
                                Discover what you have in common with other
                                members.
                              </Text>
                              <Link href={PROFILE_URL}>
                                <Button variant="secondary" size="small">
                                  Add your first tag.
                                </Button>
                              </Link>
                            </>
                          )
                        )} 
                      </SectionContainer>
                    )}
                    */}
                    {connections.length > 0 && (
                      <SectionContainer>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="grey400"
                          className="mar-bot-12"
                        >
                          Connections
                        </Text>
                        {connections.map((user, index) => (
                          <UserCard
                            key={`${user.externalId}-${index}`}
                            displayName={getUserDisplayName(user)}
                            oneLiner={user.oneLiner}
                            location={user.location}
                            externalId={user.externalId}
                            avatarUrl={user.avatarUrl}
                          />
                        ))}
                      </SectionContainer>
                    )}
                    {organisationUsers.length > 0 && (
                      <SectionContainer>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="grey400"
                          className="mar-bot-12"
                        >
                          From {organisation?.name}
                        </Text>
                        {organisationUsers.map((user, index) => (
                          <UserCard
                            key={`${user.externalId}-${index}`}
                            displayName={getUserDisplayName(user)}
                            oneLiner={user.oneLiner}
                            location={user.location}
                            externalId={user.externalId}
                            avatarUrl={user.avatarUrl}
                          />
                        ))}
                      </SectionContainer>
                    )}
                    {isNewbie && newbieUsers.length > 0 && (
                      <SectionContainer>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="grey400"
                          className="mar-bot-12"
                        >
                          New members
                        </Text>
                        {newbieUsers.map((user, index) => (
                          <UserCard
                            key={`${user.externalId}-${index}`}
                            displayName={getUserDisplayName(user)}
                            oneLiner={user.oneLiner}
                            location={user.location}
                            externalId={user.externalId}
                            avatarUrl={user.avatarUrl}
                          />
                        ))}
                      </SectionContainer>
                    )}
                    {filteredUsersWithNewbieFilter.length > 0 && (
                      <SectionContainer>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="grey400"
                          className="mar-bot-12"
                        >
                          Members
                        </Text>
                        {filteredUsersWithNewbieFilter.map((user, index) => (
                          <UserCard
                            key={`${user.externalId}-${index}`}
                            displayName={getUserDisplayName(user)}
                            oneLiner={user.oneLiner}
                            location={user.location}
                            externalId={user.externalId}
                            avatarUrl={user.avatarUrl}
                          />
                        ))}
                      </SectionContainer>
                    )}
                  </Box>
                </>
              ),
            });
          }}
        >
          <Avatars
            avatars={avatarList}
            dimensions={isReducedView ? 25 : undefined}
          />
          <Text fontSize="xs">{registrationsText}</Text>
        </Box>
      ) : null}
    </>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii[20]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => theme.spacing[16]};
  box-shadow: 0px 1px 20px rgba(16, 24, 40, 0.05);
`;
