import { ReactNode } from "react";
import {
  Input,
  InputWrapper,
  InputProps,
  Label,
  TextError,
  RequiredAsterisk,
  Box,
  Text,
} from "../components";

type InputFieldProps = InputProps & {
  label: string | ReactNode;
  id: string;
  errorMessage?: string;
};

export const InputField: React.FC<InputFieldProps> = ({
  label,
  id,
  dataCy,
  errorMessage,
  required,
  onChange,
  maxLength,
  value,
  style,
  ...otherProps
}) => {
  return (
    <InputWrapper style={style}>
      <Label htmlFor={id}>
        {label} {required ? <RequiredAsterisk /> : ""}
      </Label>
      <Input
        id={id}
        className={errorMessage ? "has-error" : ""}
        onChange={onChange}
        maxLength={maxLength}
        value={value}
        {...otherProps}
        data-cy={dataCy}
      />
      <Box className="flex justify-between align-center">
        {errorMessage ? (
          <TextError fontWeight={400}>{errorMessage}</TextError>
        ) : (
          <div></div>
        )}
        {maxLength && (
          <Box className="mar-x-8">
            <Text fontSize="xs" color="grey300">
              {typeof value === "string"
                ? `${value.length}/${maxLength}`
                : `0/${maxLength}`}
            </Text>
          </Box>
        )}
      </Box>
    </InputWrapper>
  );
};
