import { IdOrString } from "database";
import { ResultOrError, WorkedOrError } from "shared/response.types";
import { z } from "zod";
import { MusicStatus } from "shared/user.types";

export type IntentionSubtask = {
  text: string;
  completed: boolean;
};

export type Intention = {
  _id: IdOrString;
  userId: IdOrString;
  createTime: string;
  description: string;
  isCompleted: boolean;
  boosts: string[];
  claps: string[];
  sessionId?: string;
  sessionIdHistory: string[];
  organisation?: IdOrString;
  visibility: Visibility;
  subtasks?: IntentionSubtask[];
};

export const VISIBILITY_OPTIONS = [
  "public",
  "session",
  "organisation",
  "private",
] as const;

export type Visibility = typeof VISIBILITY_OPTIONS[number];

export type PublicIntentionEntry = Pick<
  Intention,
  | "_id"
  | "boosts"
  | "claps"
  | "createTime"
  | "description"
  | "isCompleted"
  | "visibility"
  | "subtasks"
> & {
  user: {
    firstName: string;
    lastName: string;
    displayName: string;
    avatarUrl: string;
    externalId: string;
    oneLiner?: string;
    pronounciation?: string;
    location?: string;
    additionalData: {
      musicStatus: MusicStatus;
    };
  };
  organisationName?: string;
};

export type InteractionType = "boost" | "clap";

const intentionVisibilityEnum = z.enum([...VISIBILITY_OPTIONS]);

const subtasksSchema = z
  .array(z.object({ text: z.string(), completed: z.boolean() }))
  .optional();

export const createIntentionSchema = z.object({
  description: z.string(),
  playlist: z
    .object({
      name: z.string(),
      ownerName: z.string(),
      url: z.string(),
    })
    .optional(),
  sessionId: z.string().optional(),
  visibility: intentionVisibilityEnum,
  subtasks: subtasksSchema,
});

export type CreateIntentionRequest = z.infer<typeof createIntentionSchema>;
export type CreateIntentionResponse = ResultOrError<{
  // Added this `type` property to differentiate between this and the edit response,
  // which can be assigned to the same let variable in the client
  type: "create";
  result: {
    createdIntention: Intention;
    publicIntention: PublicIntentionEntry;
  };
}>;

export const editIntentionSchema = z.object({
  description: z.string().optional(),
  id: z.string(),
  sessionId: z.string().optional(),
  visibility: intentionVisibilityEnum.optional(),
  subtasks: subtasksSchema,
});

export type EditIntentionRequest = z.infer<typeof editIntentionSchema>;
export type EditIntentionResponse = WorkedOrError & {
  type: "edit";
  updatedIntention?: Intention;
};

export const setIsCompletedIntentionSchema = z.object({
  id: z.string(),
  isCompleted: z.boolean(),
});

export type SetIsCompletedIntentionRequest = z.infer<
  typeof setIsCompletedIntentionSchema
>;
export type SetIsCompletedIntentionResponse = WorkedOrError;

export const boostOrClapIntentionSchema = z.object({
  action: z.enum(["add", "remove"]),
  intentionId: z.string(),
});

export type BoostOrClapIntentionRequest = z.infer<
  typeof boostOrClapIntentionSchema
>;
export type BoostOrClapIntentionResponse = WorkedOrError;

export type GetPublicIntentionsResponse = ResultOrError<{
  intentions: PublicIntentionEntry[];
}>;

export const deleteIntentionSchema = z.object({
  intentionId: z.string(),
});

export type DeleteIntentionRequest = z.infer<typeof deleteIntentionSchema>;
export type DeleteIntentionResponse = WorkedOrError;
