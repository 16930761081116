import { AnchorLinkBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { css } from "styled-system/css";

const AnchorLinkView = (
  props: AnchorLinkBlockFragment & { className: string }
) => (
  <>
    {props.identifier && (
      <div
        id={props.identifier}
        className={css({
          margin: "0 !important",
          position: "absolute",
          scrollMarginTop: "calc({sizes.primaryNavHeight} + 20px)", // Add padding to account for typical element spacing
          "bp-desktop-xs": {
            scrollMarginTop: "0",
          },
        })}
      />
    )}
  </>
);

export const AnchorLinkRenderer = renderer({
  typeName: "AnchorLinkRecord",
  view: AnchorLinkView,
});
