"use client";
import { styled } from "styled-system/jsx";
import { css } from "styled-system/css";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { Icon } from "design-system/components";
import { useMemo } from "react";
import { DSComponentPropsProvider } from "design-system/design-system.types";

export type AccordionItem = {
  title: React.ReactNode;
  content?: React.ReactNode;
  openByDefault?: boolean;
};

type AccordionProps = {
  /**
   * @param type Determines whether one or multiple items can be opened at the same time.
   */
  type: "single" | "multiple";
  items: AccordionItem[];
  color?: string | null;
  fontSize?: number | null;
  highlightColour?: string | null;
} & DSComponentPropsProvider;

export const Accordion: React.FC<AccordionProps> = ({
  type,
  items,
  fontSize,
  color,
  highlightColour,
  className,
}) => {
  const defaultValue = useMemo(() => {
    const defaultIndex = items.findIndex((item) => item.openByDefault);
    if (defaultIndex === -1) return;
    return `accordion-item-${defaultIndex}`;
  }, [items]);

  const fontSizeStyle = fontSize ? { fontSize: `${fontSize}px` } : {};
  const fontColourStyle = color ? { color } : {};

  const content = items.map((item, index) => {
    const key = `accordion-item-${index}`;
    const hasContent = !!item.content;
    return (
      <StyledItem
        key={key}
        value={key}
        className={
          highlightColour
            ? css({
                '&[data-state="open"]': { backgroundColor: highlightColour },
              })
            : ""
        }
      >
        <AccordionPrimitive.Header className="flex">
          <StyledTrigger>
            <span
              className="text-left"
              style={{ ...fontSizeStyle, ...fontColourStyle }}
            >
              {item.title}
            </span>
            {hasContent && (
              <Icon
                className="accordion-chevron"
                icon="chevron-down"
                color={color || undefined}
                size={16}
              />
            )}
          </StyledTrigger>
        </AccordionPrimitive.Header>
        {hasContent && (
          <StyledContent style={{ ...fontSizeStyle, ...fontColourStyle }}>
            {item.content}
          </StyledContent>
        )}
      </StyledItem>
    );
  });

  if (type === "single") {
    return (
      <StyledAccordion
        defaultValue={defaultValue}
        type="single"
        collapsible={true}
        className={className}
      >
        {content}
      </StyledAccordion>
    );
  }
  return (
    <StyledAccordion className={className} type="multiple">
      {content}
    </StyledAccordion>
  );
};

const StyledAccordion = styled(AccordionPrimitive.Root, {
  base: {
    borderRadius: "{radii.6}",
    width: "100%",
  },
});

const StyledItem = styled(AccordionPrimitive.Item, {
  base: {
    overflow: "hidden",
    padding: "{spacing.16}",
    '&[data-state="open"] svg': {
      rotate: "180deg",
    },
    '&[data-state="open"]': {
      borderRadius: "{spacing.16}",
    },
  },
});

// background-color: ${({ highlightColour }) => highlightColour || "none"};

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  base: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    padding: "0 {spacing.12}",
    fontWeight: "bold",
    fontSize: "{fontSizes.xl}",
    _focus: { boxShadow: "none" },
  },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  base: {
    '&[data-state="open"]': {
      animation: "slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1)",
    },
    '&[data-state="closed"]': {
      animation: "slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1)",
    },
  },
});
