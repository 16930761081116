"use client";
import { useState } from "react";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { Box } from "design-system/components";
import { styled } from "styled-system/jsx";

type CollapseToggleProps = Omit<DSComponentPropsProvider, "title"> & {
  children: React.ReactNode;
  dataCy?: string;
  title: React.ReactNode;
  isOpen?: boolean;
};

export const CollapseToggle = ({
  children,
  dataCy,
  title,
  className = "",
  isOpen = false,
}: CollapseToggleProps) => {
  const [showContent, setShowContent] = useState(isOpen);

  return (
    <Box className={`${className || ""} w-full`}>
      <button
        type="button"
        className="mar-bot-8"
        data-cy={dataCy}
        onClick={(event) => {
          event.preventDefault();
          setShowContent(!showContent);
        }}
      >
        <Box className="flex align-center fw-700">
          <ToggleIcon
            src={"/assets/toggle/arrow-icon.svg"}
            className={showContent ? "show" : ""}
          />
          {title}
        </Box>
      </button>
      <AnimationWrapper className={showContent ? "showing" : ""}>
        <div className="overflow-hidden">{children}</div>
      </AnimationWrapper>
    </Box>
  );
};

const ToggleIcon = styled("img", {
  base: {
    width: "10px",
    height: "10px",
    marginRight: "20px",
    transition: "transform 0.4s",
    "&.show": {
      transform: "rotateZ(90deg)",
    },
  },
});

const AnimationWrapper = styled("div", {
  base: {
    /** Animating grid template rows to get a drawer effect
     * Claude suggested this approach and claimed it has better performance than animating `height`
     * I couldn't find any documentation with a comparison of the two approaches, testing it myself
     * the different was noticible
     * */
    display: "grid",
    gridTemplateRows: "0fr",
    position: "relative",
    transition: "grid-template-rows 0.2s linear",
    "&.showing": {
      gridTemplateRows: "1fr",
    },
  },
});
