import { ConfigProvider, DatePicker as AntdDatePicker } from "antd";
import { EMOTION_THEME, THEME_COLORS } from "design-system/emotion.theme";
import { ComponentProps, useRef } from "react";

type DatePickerProps = Omit<ComponentProps<typeof AntdDatePicker>, "picker"> & {
  showTime?: boolean;
  picker?: "date" | "month" | "year" | "week" | "quarter";
};

export const DatePicker = (props: DatePickerProps) => {
  const pickerContainerRef = useRef<HTMLDivElement>(null);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: THEME_COLORS.black,
          colorPrimaryBg: THEME_COLORS.focusMid,
          colorPrimaryBorder: THEME_COLORS.focusMid,
          colorPrimaryBgHover: THEME_COLORS.focusMid,
          borderRadius: 24,
        },
      }}
    >
      <div ref={pickerContainerRef}>
        <AntdDatePicker
          {...props}
          allowClear={false}
          getPopupContainer={() => pickerContainerRef.current as HTMLDivElement}
          style={{
            borderRadius: EMOTION_THEME.radii[24],
            height: "40px",
            fontFamily: EMOTION_THEME.fontFamilies.biennale,
          }}
          popupStyle={{
            fontFamily: EMOTION_THEME.fontFamilies.biennale,
          }}
          showTime={props.showTime ? { minuteStep: 15 } : false}
          changeOnBlur={true}
          format={
            props.format
              ? props.format
              : props.showTime
              ? "DD/MM/YYYY - HH:mm"
              : "DD/MM/YYYY"
          }
        />
      </div>
    </ConfigProvider>
  );
};
