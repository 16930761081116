"use client";
import { useEffect, useRef } from "react";

type TrustpilotProps = {
  templateId?: string;
  height?: number;
  widgetWidth?: number;
  theme?: string;
  className?: string;
};

// The default settings are for the trustplit widget with id "53aa8912dec7e10d38f59f36"
// see the widget here: https://businessapp.b2b.trustpilot.com/trustbox/53aa8912dec7e10d38f59f36
// you can use the component without any id and get the default one
export const Trustpilot = ({
  templateId = "53aa8912dec7e10d38f59f36",
  widgetWidth = 100,
  height = 20,
  theme = "light",
  className = "",
}: TrustpilotProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [ref]);

  return (
    <div
      className={`trustpilot-widget minimal-spacing ${className}}`}
      data-locale="en-US"
      data-template-id={templateId}
      data-businessunit-id="61b0c9c93f5e11737c6abe6d"
      data-style-height={`${height}px`}
      data-style-width={`${widgetWidth}%`}
      data-theme={theme}
      ref={ref}
    >
      <a
        title="Trustpilot"
        href="https://www.trustpilot.com/review/flown.com"
        target="_blank"
        rel="noreferrer noopener"
      ></a>
    </div>
  );
};
