"use client";
import { logOut } from "features/auth";
import { ProfileSummary } from "features/profile/client";
import { styled } from "styled-system/jsx";
import { useUserContext } from "context/user-context";
import {
  getFlownProfileMenuItems,
  getLoggedInProfileMenuItems,
} from "./nav-menu-utils";
import { Box, Button, Text, TextLink, Icon } from "design-system/components";
import {
  SIDE_PANEL_OFFSET_TOP,
  SidePanel,
} from "design-system/organisms/side-panel";
import Link from "next/link";
import { pluralise } from "utils/string-utils";
import dayjs from "dayjs";
import { useCheckValidCreditsForUser } from "features/credits/client";
import { FLOWN_PLUS_SESSIONS_URL, ORGANISATION_URL_PREFIX } from "utils/urls";
import { useIsPWAInstallable } from "context/is-pwa-installable";
import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useIsMediaTablet } from "utils/component-utils";
import Image from "next/image";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";

const PROFILE_POPUP_TOP_HEIGHT = 80;

export const accountSidePanelAtom = atom(false);

export const AccountSidePanel: React.FC = () => {
  const {
    userRole,
    externalId,
    isFacilitatorRole,
    isFlownRole,
    isFreeCreditsRole,
    organisation,
    profileTags,
  } = useUserContext();

  const [isOpen, setIsOpen] = useAtom(accountSidePanelAtom);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const onClose = () => setIsOpen(false);

  const onLogout = async () => {
    await logOut();
    onClose();
  };

  const [isPWAInstallable, onPWAInstallClicked] = useIsPWAInstallable();

  const { currentCredit, hasLoadedCredits } = useCheckValidCreditsForUser({
    externalId,
    isFreeCreditsRole,
    shouldTriggerCheck: isOpen,
  });

  const isTabletOrAbove = useIsMediaTablet();

  // Check if content is scrollable
  useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      setIsScrollable(scrollHeight > clientHeight);
    }
  }, [isOpen, currentCredit, hasLoadedCredits]);

  // Prevent body scroll when panel is open
  // This stops it happening on mobile where the side panel takes up the entire viewport
  useEffect(() => {
    if (isTabletOrAbove) {
      return;
    }
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen, isTabletOrAbove]);

  /**
   * If the user is in an org and has an incomplete profile, the profile summary
   * will be bigger by around 20px, so we subtract this from the height of the side panel
   * to avoid a double scrollbar.
   */
  const isIncompleteOrgProfile =
    organisation && (!profileTags || profileTags.length === 0);
  const heightAdjustment =
    PROFILE_POPUP_TOP_HEIGHT +
    (isIncompleteOrgProfile ? 20 : 0) +
    SIDE_PANEL_OFFSET_TOP +
    10;

  return (
    <SidePanel
      open={isOpen}
      onClose={onClose}
      size="normal"
      title={<></>}
      childrenContainerStyles={
        isScrollable
          ? {
              paddingRight: 0, // Push scrollbar to the edge
            }
          : undefined
      }
    >
      <Box
        ref={contentRef}
        style={{
          overflowY: "auto",
          height: `calc(100dvh - ${heightAdjustment}px)`,
          // Padding right on the inner content to cover
          paddingRight: "4px",
        }}
      >
        {externalId && (
          <ProfileSummary
            showProfileLink={false}
            hideTags
            hrefTarget="_self"
            additionalButton={
              isPWAInstallable ? (
                <Button
                  className="flex gap-8"
                  variant="secondary"
                  size="small"
                  onClick={onPWAInstallClicked}
                  dataCy="install-pwa-secondary-nav-btn"
                >
                  <Icon icon="download" color={"black"} size={16} />
                  Install FLOWN app
                </Button>
              ) : null
            }
          />
        )}
        <Box className="mar-top-16">
          {getLoggedInProfileMenuItems(userRole).map((menuItem, index) => (
            <NavigationLink
              onClick={onClose}
              className="flex"
              href={menuItem.url}
              data-cy={menuItem.dataCy}
              key={index}
            >
              <Text className="flex align-center justify-between w-full">
                {menuItem.item} <Icon icon="chevron-right" />
              </Text>
            </NavigationLink>
          ))}

          {organisation ? (
            <>
              <Text fontSize="xl" className="mar-y-16" fontWeight={700}>
                Community
              </Text>
              <NavigationLink
                onClick={onClose}
                className="flex"
                href={`${ORGANISATION_URL_PREFIX}/${organisation.slug}`}
                data-cy={"explore-flown-plus-link"}
              >
                <Text className="flex align-center justify-between w-full">
                  <span className="flex align-center gap-8">
                    {organisation.logoUrl && (
                      <Image
                        className="b-radius-round"
                        height={20}
                        width={20}
                        src={organisation.logoUrl}
                        alt={`${organisation.name} logo`}
                      />
                    )}
                    {organisation.name}
                  </span>
                  <Icon icon="chevron-right" />
                </Text>
              </NavigationLink>
            </>
          ) : null}
          <Text fontSize="xl" className="mar-y-16" fontWeight={700}>
            FLOWN+
          </Text>
          <NavigationLink
            onClick={onClose}
            className="flex"
            href={FLOWN_PLUS_SESSIONS_URL}
            data-cy={"explore-flown-plus-link"}
          >
            <Text className="flex align-center justify-between w-full">
              Explore FLOWN+ <Icon icon="chevron-right" />
            </Text>
          </NavigationLink>

          {(isFacilitatorRole || isFlownRole) && (
            <>
              <Text fontSize="xl" className="mar-y-16" fontWeight={700}>
                FLOWN links
              </Text>
              {getFlownProfileMenuItems(userRole).map((menuItem, index) => (
                <NavigationLink
                  onClick={onClose}
                  className="flex"
                  href={menuItem.url}
                  data-cy={menuItem.dataCy}
                  key={index}
                >
                  <Text className="flex align-center justify-between w-full">
                    {menuItem.item} <Icon icon="chevron-right" />
                  </Text>
                </NavigationLink>
              ))}
            </>
          )}
          {isFreeCreditsRole && hasLoadedCredits && (
            <>
              <Text fontSize="xl" className="mar-y-16" fontWeight={700}>
                FLOWN sessions
              </Text>

              {currentCredit ? (
                <Text className="flex align-center w-full">
                  You have {currentCredit.sessionsLeft}{" "}
                  {pluralise(currentCredit.sessionsLeft, "session")} left until{" "}
                  {dayjs(currentCredit.endDate).format("DD MMM YYYY")}.
                </Text>
              ) : (
                <div className="flex-wrap gap-4 align-center w-full">
                  You have no sessions left.{" "}
                  <TextLink
                    href={getSubscribePageLinkWithSubscribeSource(
                      "account-side-panel"
                    )}
                  >
                    Subscribe for unlimited sessions
                  </TextLink>
                </div>
              )}
            </>
          )}
        </Box>

        <Text
          dataCy={"account-side-panel-logout"}
          onClick={onLogout}
          className="cursor-pointer mar-top-32 pad-bot-16 flex align-center justify-between w-full"
        >
          Log out <Icon icon="logout" size={16} />
        </Text>
      </Box>
    </SidePanel>
  );
};

const NavigationLink = styled(Link, {
  base: {
    borderBottom: "1px solid {colors.blueGrey100}",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});
