import { Text } from "design-system/components";

type BookSuccessToastContentProps = {
  email?: string;
  emailSent?: boolean;
};

export const BookSuccessToastContent: React.FC<
  BookSuccessToastContentProps
> = ({ email, emailSent = true }) => {
  return (
    <Text>
      {!emailSent ? (
        `We've booked you onto the session.`
      ) : (
        <>
          We've sent a calendar invite and joining link to your email
          <strong> {email}</strong>
        </>
      )}
    </Text>
  );
};
