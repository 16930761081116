import { ConfigProvider, TimePicker as AntdTimePicker } from "antd";
import { EMOTION_THEME, THEME_COLORS } from "design-system/emotion.theme";
import { ComponentProps, useRef } from "react";

type TimePickerProps = ComponentProps<typeof AntdTimePicker> & {
  showTime?: boolean;
};

export const TimePicker = ({ ...props }: TimePickerProps) => {
  const pickerContainerRef = useRef<HTMLDivElement>(null);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: THEME_COLORS.black,
          colorPrimaryBg: THEME_COLORS.focusMid,
          colorPrimaryBorder: THEME_COLORS.focusMid,
          colorPrimaryBgHover: THEME_COLORS.focusMid,
          borderRadius: 24,
        },
      }}
    >
      <div ref={pickerContainerRef}>
        <AntdTimePicker
          {...props}
          allowClear={false}
          changeOnBlur={true}
          getPopupContainer={() => pickerContainerRef.current as HTMLDivElement}
          style={{
            borderRadius: EMOTION_THEME.radii[24],
            height: "40px",
            fontFamily: EMOTION_THEME.fontFamilies.biennale,
          }}
          popupStyle={{
            fontFamily: EMOTION_THEME.fontFamilies.biennale,
          }}
          placement={"topRight"}
          format={props.format ? props.format : "HH:mm"}
        />
      </div>
    </ConfigProvider>
  );
};
