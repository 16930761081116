import { OrgProfileTag } from "features/profile/shared";
import Image from "next/image";
import { Box, Icon } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { token } from "styled-system/tokens";
import { useMemo } from "react";
import dayjs from "dayjs";

export const OrgProfileTags: React.FC<
  {
    orgProfileTags: OrgProfileTag[];
    timezone?: string;
  } & DSComponentPropsProvider
> = ({ className, orgProfileTags = [], timezone }) => {
  const usersTime = useMemo(() => {
    try {
      return timezone && dayjs().tz(timezone).format("HH:mm");
    } catch (error) {
      return;
    }
  }, [timezone]);

  return (
    <Box className={className}>
      <Box className="flex align-center fs-xs gap-12">
        {orgProfileTags
          .sort((a, b) => a.parentTag.order - b.parentTag.order)
          .map((tag, index) => (
            <Box className="flex gap-8 align-center " key={tag.parentTag.name}>
              <Image
                src={tag.parentTag.iconUrl}
                alt={tag.parentTag.name}
                width={16}
                height={16}
              />
              {tag.childTag?.name}

              {(index < orgProfileTags.length - 1 || !!timezone) && (
                <span style={{ color: token("colors.grey400") }}> • </span>
              )}
            </Box>
          ))}
        {timezone && (
          <Box className="flex gap-8 align-center">
            <Icon icon="clock" size={16} />
            {`${usersTime} local time`}
          </Box>
        )}
      </Box>
    </Box>
  );
};
