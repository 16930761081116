import { WrapperBlockFragment } from "generated/graphql-typed";
import React from "react";
import { renderer } from "./renderer";
import { ModularContent } from "./modular-content";
import { CSS_CLASSES } from "design-system/styles/global-styles";
import { useUserContext } from "context/user-context";
import { SPACING } from "../shared/consts";
import { css } from "styled-system/css";

const STATES = {
  loggedIn: "loggedIn",
  loggedOut: "loggedOut",
  desktop: "desktop",
  mobile: "mobile",
};

// This is the shape of the data for the dato structured text element to just to it's thing.
// Key is the schema 'dast' which allows it to calculate the blocks
const convertListOfBlocksToContent = (
  blocks: WrapperBlockFragment["wrapperContent"]
) => {
  return {
    blocks,
    value: {
      schema: "dast",
      document: {
        type: "root",
        children: blocks.map((block) => {
          return { item: block.id, type: "block" };
        }),
      },
      __typename: "WrapperBlockRecord",
    },
  };
};

const WrapperView = (props: WrapperBlockFragment & { className: string }) => {
  const { loggedIn } = useUserContext();

  const spacingClass =
    SPACING[(props.spacing as keyof typeof SPACING) || "standard"];
  const orientationClass = props.displayAsRow ? CSS_CLASSES.DISPLAY_ROW : "";

  // Only hide if:
  // 1. Content is for logged-in users and user is logged out
  // 2. Content is for logged-out users and user is logged in
  const shouldHide =
    (props.visibleTo === STATES.loggedIn && !loggedIn) ||
    (props.visibleTo === STATES.loggedOut && loggedIn);

  const shouldShowOnDesktop = !shouldHide && props.device === STATES.desktop;
  const shouldShowOnMobile = !shouldHide && props.device === STATES.mobile;

  /**
   * Toggle hide/show on desktop/mobile with CSS
   */
  const wrapperStyles = css({
    "&.show-on-desktop": {
      display: "none",
      "bp-tablet": {
        display: "block",
      },
    },
    "&.show-on-mobile": {
      display: "block",
      "bp-tablet": {
        display: "none",
      },
    },
  });

  return (
    <ModularContent
      customClass={[spacingClass, orientationClass].filter(Boolean).join(" ")}
      wrapperClassName={[
        shouldShowOnDesktop ? "show-on-desktop" : "",
        shouldShowOnMobile ? "show-on-mobile" : "",
        wrapperStyles,
        CSS_CLASSES.NO_MARGIN,
      ]
        .filter(Boolean)
        .join(" ")}
      data={convertListOfBlocksToContent(props.wrapperContent)}
      paragraphSize={16}
    />
  );
};

export const WrapperBlockRenderer = renderer({
  typeName: "WrapperBlockRecord",
  view: WrapperView,
});
