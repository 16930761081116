import { HostedSessionsWrapper } from "features/sessions/client";

type HostedSessionsSidePanelProps = {
  externalId: string;
};

export const UserHostedSessionsSidePanel: React.FC<
  HostedSessionsSidePanelProps
> = ({ externalId }) => {
  return <HostedSessionsWrapper externalId={externalId} />;
};
