import { PressBlockFragment } from "generated/graphql-typed";
import { Press } from "design-system/molecules/press";
import { renderer } from "./renderer";

export const PressView = (
  props: PressBlockFragment & { className: string }
) => {
  return (
    <Press
      pressTitle={props.pressTextContent}
      pressLogos={props.pressLogos}
      pressCtaLabel={props.pressCtaLabel}
      pressCtaLink={props.pressCtaLink}
    />
  );
};

export const PressRenderer = renderer({
  typeName: "PressRecord",
  view: PressView,
});
