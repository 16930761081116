import { AccordionBlockFragment } from "generated/graphql-typed";
import { Accordion, Box } from "design-system/components";
import { ModularContent } from "./modular-content";
import { renderer } from "./renderer";
import { render as renderStructuredTextToPlainText } from "datocms-structured-text-to-plain-text";
import Head from "next/head";

export const AccordionView = (
  props: AccordionBlockFragment & { className: string }
) => {
  const items = props.accordionItems.map((item) => ({
    title: item.title,
    content: (
      <Box className="mar-16">
        <ModularContent
          data={item.content}
          paragraphSize={props.fontSize || 16}
        />
      </Box>
    ),
    openByDefault: item.openByDefault,
  }));

  const maxWidth = "800px";

  /**
   * https://developers.google.com/search/docs/appearance/structured-data/faqpage
   */
  const faqStructuredData = props.hasSeoFaq
    ? {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: props.accordionItems
          .filter((item) => item && item.content)
          .map((item) => ({
            "@type": "Question",
            name: item.title,
            acceptedAnswer: {
              "@type": "Answer",
              /**
               * Avoids having to go through the nested content which is in dast format
               * https://github.com/datocms/structured-text/tree/main/packages/to-plain-text
               * Assertion is safe here as we filter above
               */
              text: renderStructuredTextToPlainText(item.content!.value),
            },
          })),
      }
    : null;

  return (
    <>
      {props.hasSeoFaq && faqStructuredData && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(faqStructuredData),
            }}
          />
        </Head>
      )}
      <Box
        style={
          props.alignment
            ? { alignItems: props.alignment, maxWidth }
            : { maxWidth }
        }
        className="page-block flex-col pad-8"
      >
        {props.heading && <h3 className="mar-y-16"> {props.heading} </h3>}
        <Accordion
          type="multiple"
          items={items}
          fontSize={props.fontSize}
          color={props.fontColor?.hex}
          highlightColour={props.highlightColour?.hex}
        ></Accordion>
      </Box>
    </>
  );
};

export const AccordionRenderer = renderer({
  typeName: "AccordionRecord",
  view: AccordionView,
});
