import styled from "@emotion/styled";
import {
  DSComponentPropsProvider,
  ThemeColor,
} from "design-system/design-system.types";
import { ChangeEvent } from "react";

type WithActiveBackgroundColor = {
  activeBackgroundColor: ThemeColor;
};

type WithActiveToggleColor = {
  activeToggleColor: string;
};

type SwitchToggleProps = {
  disabled?: boolean;
  id: string;
  isChecked: boolean;
  onToggle: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: "small" | "medium";
} & Partial<WithActiveBackgroundColor & WithActiveToggleColor> &
  DSComponentPropsProvider;

export const SwitchToggle = ({
  activeBackgroundColor = "focusDark",
  activeToggleColor = "#FFF",
  isChecked,
  onToggle,
  size = "medium",
  id,
  className = "",
  dataCy,
  disabled,
}: SwitchToggleProps) => {
  return (
    <SwitchWrapper className={`${size} ${className}`}>
      <Switch
        className={isChecked ? "checked" : ""}
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
        activeBackgroundColor={activeBackgroundColor}
        activeToggleColor={activeToggleColor}
        disabled={disabled}
      />
      <SwitchLabel htmlFor={id} data-cy={dataCy} />
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled.div`
  position: relative;

  &.small {
    transform: scale(0.75);
  }
`;

const SwitchAfterStyles = `
  content: "";
  display: block;
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
`;

const SwitchLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.625rem;
  height: 1.5rem;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  transform: translate(-50%, -50%);
  &::after {
    ${SwitchAfterStyles}
    margin: 3px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const Switch = styled.input<WithActiveToggleColor & WithActiveBackgroundColor>`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 2.625rem;
  height: 1.5rem;
  &.checked + ${SwitchLabel} {
    background: ${({ theme, activeBackgroundColor }) =>
      theme.colors[activeBackgroundColor]};
    &::after {
      ${SwitchAfterStyles}
      background: ${(props) => props.activeToggleColor};
      margin-left: 21px;
      transition: 0.2s;
    }
  }
  &:disabled + ${SwitchLabel} {
    cursor: default;
    background: ${({ theme }) => theme.colors.focusMid};
    &::after {
      ${SwitchAfterStyles}
      background: ${({ theme }) => theme.colors.grey100};
      transition: 0.2s;
    }
  }
`;
