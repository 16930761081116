import { Box } from "design-system/components";
import { UserProfileParams } from "features/profile/shared";
import Image from "next/image";
import { getUserDisplayName } from "features/profile/shared";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import React from "react";

type UserDisplayNameWithLogoProps = {
  user: UserProfileParams;
} & DSComponentPropsProvider;

export const UserDisplayNameWithLogo: React.FC<
  UserDisplayNameWithLogoProps
> = ({ user, className }) => {
  if (user && user.organisationLogoUrl) {
    return (
      <Box className={`flex fw-600 mar-y-8 ${className}`}>
        {getUserDisplayName(user)}
        <Image
          style={{
            verticalAlign: "sub",
            marginLeft: "4px",
          }}
          className="b-radius-round"
          src={user.organisationLogoUrl}
          height={20}
          width={20}
          alt="Org logo"
        />
      </Box>
    );
  }
  return <Box className={className}>{getUserDisplayName(user)}</Box>;
};
