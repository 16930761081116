import { UAParser } from "ua-parser-js";

/**
 * Taken from https://github.com/tokuda109/next-useragent
 */
export type UserAgent = {
  readonly source: string; // original user agent string
  readonly deviceType: string | null;
  readonly deviceVendor: string | null;
  readonly os: string | undefined;
  readonly osVersion: number | null;
  readonly browser: string | undefined;
  readonly browserVersion: number | null;
  readonly engine: string | undefined;
  readonly engineVersion: number | null;
  readonly isIphone: boolean;
  readonly isIpad: boolean;
  readonly isMobile: boolean;
  readonly isTablet: boolean;
  readonly isDesktop: boolean;
  readonly isBot: boolean;
  readonly isChrome: boolean;
  readonly isFirefox: boolean;
  readonly isSafari: boolean;
  readonly isIE: boolean;
  readonly isEdge: boolean;
  readonly isOpera: boolean;
  readonly isMac: boolean;
  readonly isChromeOS: boolean;
  readonly isWindows: boolean;
  readonly isIos: boolean;
  readonly isAndroid: boolean;
  readonly supportsPWAPrompt: boolean;
};

export type UserAgentProvider = {
  parsedUserAgent: UserAgent;
};

const BOT_UA = [
  "\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/",
  "googlebot",
  "baiduspider",
  "gurujibot",
  "yandexbot",
  "slurp",
  "msnbot",
  "bingbot",
  "facebookexternalhit",
  "linkedinbot",
  "twitterbot",
  "slackbot",
  "telegrambot",
  "applebot",
  "pingdom",
  "tumblr",
];

/**
 * Get the information of an useragent string.
 * @returns parsed information.
 */
export function parseUserAgent(userAgentString: string): UserAgent {
  const result: UAParser.IResult = new UAParser(userAgentString).getResult();

  /**
   * Values are all set in advance
   */
  // eslint-disable-next-line security/detect-non-literal-regexp
  const regex = new RegExp(`(${BOT_UA.join("|")})`, "ig");
  const isBot = userAgentString
    ? regex.test(userAgentString.toLowerCase())
    : false;

  const browser = result.browser.name;
  const deviceType = result.device.type || null;
  const os = result.os.name;
  const engine = result.engine.name;
  const isMobile: boolean = deviceType === "mobile";
  const isTablet: boolean = deviceType === "tablet";
  const isIos: boolean = os === "iOS";

  const isSafari = browser === "Safari";
  const isFirefox = browser === "Firefox";

  const ua: UserAgent = Object.freeze({
    browser,
    deviceType,
    os,
    engine,
    isMobile,
    isTablet,
    isIos,
    source: userAgentString,
    deviceVendor: result.device.vendor || null,
    osVersion: result.os.version ? parseInt(result.os.version, 10) : null,
    browserVersion: result.browser.version
      ? parseFloat(result.browser.version)
      : null,
    engineVersion: result.engine.version
      ? parseFloat(result.engine.version)
      : null,
    isIphone: isMobile && isIos,
    isIpad: isTablet && isIos,
    isDesktop: !isMobile && !isTablet,
    isChrome: browser === "Chrome",
    isFirefox,
    isSafari,
    isIE: browser === "IE",
    isEdge: browser === "Edge",
    isOpera: browser === "Opera",
    isMac: os === "Mac OS",
    isChromeOS: os === "Chromium OS",
    isWindows: os === "Windows",
    isAndroid: os === "Android",
    isBot: isBot,
    supportsPWAPrompt: !(isSafari || isFirefox || isIos),
  });

  return ua;
}
export const checkIsPWA = () =>
  window?.matchMedia("(display-mode: standalone)").matches;
