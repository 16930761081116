import { Facilitators } from "shared/facilitator.types";
import { getTagsSlugArray, getTypedTags } from "utils/tag-utils";
import { FlownEvents, EventTag } from "shared/event.types";
import { Icon, TextFlex } from "design-system/components";

/**
 * Filters to apply as a second layer of filtering to the calendar events.
 * These are applied on the client side by the calendar components
 */
export type CalendarFilterOptions = {
  categories: string[];
  /**
   * We need the category here because it is a "pre-filter"
   * for the session type i.e. if the category is recharge,
   * session type will be filtered to only show 'take-off', 'airflow'
   * and 'awe walks
   */
  sessionTypes: { category: string; name: string }[];
  tags: EventTag[];
  facilitators: Facilitators;
};

export const CALENDAR_TAB_FILTERS = {
  ATTENDING: {
    label: (
      <TextFlex className="gap-8 justify-center" as={"span"}>
        <Icon size={14} icon="added-to-calendar" /> Attending
      </TextFlex>
    ),
    value: "attending",
  },
  HOSTING: {
    label: (
      <TextFlex className="gap-8 justify-center" as={"span"}>
        <Icon size={14} icon="users" /> Hosting
      </TextFlex>
    ),
    value: "hosting",
  },
  PAST: {
    label: (
      <TextFlex className="gap-8 justify-center" as={"span"}>
        <Icon size={14} icon="arrow-ccw" /> Past
      </TextFlex>
    ),
    value: "past",
  },
} as const;

export type CalendarTabFilter =
  typeof CALENDAR_TAB_FILTERS[keyof typeof CALENDAR_TAB_FILTERS];

/**
 * Type to represent a given selection of calendar filter options
 */

export const CALENDAR_FILTERS = {
  category: "Category",
  facilitator: "Facilitator",
  sessionType: "Session type",
  tag: "Tag",
} as const;

export type CalendarFilters = {
  category: string;
  facilitator: string;
  sessionType: string;
  tag: string;
};

export type CalendarFilterKey = keyof typeof CALENDAR_FILTERS;

export const getQueryFilterKeyByCalendarId = (
  filterKey: CalendarFilterKey,
  calendarId?: string
) => (calendarId ? `cid-${calendarId}-${filterKey}` : `${filterKey}`);

export const getEmptyCalendarFilterOptions = (): CalendarFilterOptions => ({
  categories: [],
  sessionTypes: [],
  tags: [],
  facilitators: [],
});

export const getIsSelectedFiltersEmpty = (
  selectedFilters: CalendarFilters
): boolean => {
  return Object.values(selectedFilters).every((value) => !value);
};

/**
 * Get all unique calendar filters for a given set of events
 */
export const getUniqueCalendarFilters = (
  allEvents: FlownEvents
): CalendarFilterOptions => {
  const uniqueFilters = getEmptyCalendarFilterOptions();
  for (const event of allEvents) {
    const eventDisplayName = event.displayName;
    const categoryDisplayName = event.category.displayName;
    const eventTags = event.tags;

    if (!uniqueFilters.categories.includes(categoryDisplayName)) {
      uniqueFilters.categories.push(categoryDisplayName);
    }
    if (
      !uniqueFilters.sessionTypes.some(
        (sessionType) => sessionType.name === eventDisplayName
      )
    ) {
      uniqueFilters.sessionTypes.push({
        category: categoryDisplayName,
        name: eventDisplayName,
      });
    }

    if (eventTags) {
      const newTagsValues = getTypedTags(eventTags).filter(
        (tag) => tag && !getTagsSlugArray(uniqueFilters.tags).includes(tag.slug)
      );
      uniqueFilters.tags.push(...newTagsValues);
    }
  }

  return uniqueFilters;
};
