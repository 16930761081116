import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { isProductionMode } from "./environment";
import { clientLoggerForFile } from "./logger.client";

const logger = clientLoggerForFile(__filename);

const intercomScript = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
const isIntercomEnabled = !!process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

const linkedinId = process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID;
const isLinkedinEnabled = !!linkedinId;
const stringifiedLinkedinId = JSON.stringify(linkedinId);
const linkedinScript = `_linkedin_partner_id = ${stringifiedLinkedinId};window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);\n(function(){var s=document.getElementsByTagName("script")[0];var b=document.createElement("script");b.type="text/javascript";b.async=true;b.src="https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})();`;

const segmentClientKey = process.env.NEXT_PUBLIC_CLIENT_SEGMENT_API_KEY;
const isSegmentClientEnabled = !!segmentClientKey;
const segmentAnalyticsJsScript = `!function(){
var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentClientKey}";analytics.SNIPPET_VERSION="4.13.2";
analytics.load("${segmentClientKey}");
}}();`;

/**
 * Key is site specific - https://app.cookieyes.com/site-settings
 */
const cookieYesId = process.env.NEXT_PUBLIC_COOKIE_YES_ID;
const isCookieYesEnabled = !!cookieYesId;

const googleOptimizeId = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID;
const isGoogleOptimizeEnabled = !!googleOptimizeId;

const CUSTOMER_IO_SITE_ID = process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID;

const customerIoScript = `
 var _cio = _cio || [];
    (function() {
        var a,b,c;a=function(f){return function(){_cio.push([f].
        concat(Array.prototype.slice.call(arguments,0)))}};b=["identify",
        "sidentify","on","off"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
        var t = document.createElement('script'),
            s = document.getElementsByTagName('script')[0];
        t.async = true;
        t.id    = 'cio-tracker';
        t.setAttribute('data-site-id', '${CUSTOMER_IO_SITE_ID}');
        t.setAttribute('data-use-array-params', 'true');
        
        //Enables in-app messaging
        t.setAttribute('data-use-in-app', 'true');
        
        // Remove page view tracking
        t.setAttribute('data-auto-track-page', 'false')
        
        //t.src = 'https://assets.customer.io/assets/track.js';
        //If your account is in the EU, use:
        t.src = 'https://assets.customer.io/assets/track-eu.js'
        s.parentNode.insertBefore(t, s);
    })();
`;

const twitterConversionTrackingScript = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','ocd25');`;

const refersionScript = `
! function(e, n, t, i, o, c, s, a) {
	e.TrackingSystemObject = "r", (s = n.createElement(t)).async = 1, s.src = "https://cdn.refersion.com/refersion.js", s.onload = function() {
		r.pubKey = "pub_257656ac66ff448592d4", r.settings.fp_off = !1;
		r.initializeXDLS().then(() => {
			r.launchDefault()
		})
	}, (a = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, a)
}(window, document, "script");`;

if (isProductionMode()) {
  if (!isLinkedinEnabled) {
    logger.warn(
      "Skipping linkedin conversion since NEXT_PUBLIC_LINKEDIN_PARTNER_ID is missing"
    );
  }

  if (!isSegmentClientEnabled) {
    logger.warn(
      "Skipping segment client tracking since NEXT_PUBLIC_CLIENT_SEGMENT_API_KEY is missing"
    );
  }
  if (!isIntercomEnabled) {
    logger.warn(
      "Skipping intercom boot since NEXT_PUBLIC_INTERCOM_APP_ID is missing"
    );
  }
  if (!isGoogleOptimizeEnabled) {
    logger.warn(
      "Skipping google optimize installation as GOOGLE_OPTIMIZE_ID is missing"
    );
  }
}

/**
 * Notion doc with analysis of scripts: https://www.notion.so/flown/head-Script-analysis-aca5f87cc10d4c69abdfd37aad73b7c2?pvs=4
 */
export const AppScripts: React.FC = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    // Use this pattern if a script needs to reload on any navigation change.  Have tried
    // many other methods trying to hook into the nextjs router, use effect, ids etc. and this
    // is the only one that reliable reloads the script on a navigation change.
    // https://stackoverflow.com/a/75229879
    const contentDiv = document.getElementById("content");
    if (contentDiv) {
      contentDiv.innerHTML = "";
    }
    const newScript = document.createElement("script");
    // acuity is used for scheduling and is used as part of copliot
    newScript.src =
      "https://embed.acuityscheduling.com/js/embed.js?test" +
      new Date().toISOString(); // without a unique src this doesn't update.
    newScript.id = "acuity-scheduling";
    newScript.async = true;
    contentDiv?.appendChild(newScript);
  }, [asPath]);

  return (
    <>
      {isIntercomEnabled && (
        <Script
          id="intercom"
          dangerouslySetInnerHTML={{ __html: intercomScript }}
          strategy="lazyOnload"
        />
      )}
      {isSegmentClientEnabled && (
        <Script
          id="segment"
          dangerouslySetInnerHTML={{ __html: segmentAnalyticsJsScript }}
        />
      )}
      {isLinkedinEnabled && (
        <Script
          dangerouslySetInnerHTML={{ __html: linkedinScript }}
          strategy="lazyOnload"
          id="linkedin"
        />
      )}
      {isCookieYesEnabled && (
        <Script
          id="cookieyes"
          type="text/javascript"
          src={`https://cdn-cookieyes.com/client_data/${cookieYesId}/script.js`}
          strategy="lazyOnload"
        ></Script>
      )}
      {isGoogleOptimizeEnabled && (
        <Script
          id="google-optimize"
          src={`https://www.googleoptimize.com/optimize.js?id=${googleOptimizeId}`}
          strategy="lazyOnload"
        ></Script>
      )}
      {isProductionMode() && (
        <Script
          id="twitter-conversion"
          dangerouslySetInnerHTML={{ __html: twitterConversionTrackingScript }}
          strategy="lazyOnload"
        />
      )}
      {isProductionMode() && (
        <Script
          id="refersion"
          dangerouslySetInnerHTML={{ __html: refersionScript }}
          strategy="lazyOnload"
        />
      )}
      {!!CUSTOMER_IO_SITE_ID && (
        <Script
          id="customer-io"
          dangerouslySetInnerHTML={{ __html: customerIoScript }}
        />
      )}
      <Script
        id="trustpilot"
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
      ></Script>
      <div id="content">
        {/* used to add any scripts that need reloading */}
      </div>
    </>
  );
};
