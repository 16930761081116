export const notEmpty = (value: string | null | undefined): value is string =>
  !!(value && value.trim());

/**
 * https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript/48800#48800
 */
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const VANITY_URL_REGEX = /^[a-zA-Z0-9\-_]+$/;

export const isValidVanityURL = (value: string) =>
  value.match(VANITY_URL_REGEX);

export const isValidEmail = (email: string) => email.match(EMAIL_REGEX);

export const INVALID_PASSWORD_ERROR =
  "Your password must have at least 8 characters and have at least one letter, one number and one special character";
export const MAX_PASSWORD_LENGTH = 75;

const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;

export const isValidPassword = (password: string) =>
  PASSWORD_REGEX.test(password);

/**
 * Validation functions for first and last name
 * \p{L} - Matches any kind of letter from any language (including Cyrillic, Chinese, Japanese, Korean, Arabic, etc.)
 * \p{M} - Matches combining marks (important for some languages that use diacritical marks)
 */
const NAME_REGEX = /^[\p{L}\p{M}\s'-]{1,50}$/u;
const containsUrl = (text: string): boolean => {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;
  return urlRegex.test(text);
};

export const isValidName = (name: string): boolean => {
  return NAME_REGEX.test(name) && !containsUrl(name);
};

export const uniqueOnly = <T>(array: T[]) =>
  array.filter((value, index, arr) => arr.indexOf(value) === index);

export const safeSerialize = (object: unknown) =>
  JSON.parse(JSON.stringify(object));

type PickNotNull<T, U extends keyof T> = {
  [K in U]-?: Exclude<T[K], null | undefined>;
};

type NotNull<T> = Exclude<T, null | undefined>;

export const assertFieldsNotNull = <T, U extends keyof T>(
  input: T,
  notNull: U[]
): PickNotNull<T, U> => {
  if (input == null) {
    throw new Error("Input is null");
  }

  const result: any = {};

  for (const prop of notNull) {
    const propValue = (input as any)[prop];
    if (propValue == null) {
      throw new Error(
        `Expected non-nullish value for input.${String(prop)}, got ${propValue}`
      );
    }
    result[prop] = propValue;
  }
  return result;
};

// Dato's Graphql API makes all fields nullable, even required ones. This is a
// handy utility for taking a graphql result, extracting a single child, and
// asserting that various subchildren are not null, all in a type-safe way

export const assertResultFieldsNotNull = <
  T,
  K extends keyof T,
  U extends keyof Exclude<T[K], null | undefined>
>(
  input: T,
  field: K,
  notNull: U[]
): PickNotNull<NotNull<T[K]>, U> => {
  const child = input[field];
  if (child == null) {
    throw new Error(
      `Expected non nullish value for result.${String(field)} got ${child}`
    );
  }

  const result: any = {};

  for (const prop of notNull) {
    const propValue = (child as any)[prop];
    if (propValue == null) {
      throw new Error(
        `Expected non-nullish value for result.${String(field)}.${String(
          prop
        )}, got ${propValue}`
      );
    }
    result[prop] = propValue;
  }
  return result;
};
