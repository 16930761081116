import { StructuredTextGraphQlResponse } from "react-datocms";
import { Node, hasChildren } from "datocms-structured-text-utils";

// If a new block type is added to DatoCMS and used in a page, but we haven't
// yet updated the GraphQL query, then it will cause runtime errors. This code
// removes those blocks.
// Removing this causes certain pages to break and have not worked out why just yet
// It works TM!
export const removeBlocksWithBrokenReferences = (untypedData: any) => {
  if (!untypedData) return null;
  const data: StructuredTextGraphQlResponse = {
    links: [],
    blocks: [],
    ...JSON.parse(JSON.stringify(untypedData)),
  };
  const removeInvalidChildren = (node: Node) => {
    if (hasChildren(node)) {
      for (let i = node.children.length - 1; i >= 0; i--) {
        const child = node.children[i];
        const isUnknownBlock =
          child.type === "block" &&
          !data?.blocks?.find((block: any) => block && block.id === child.item);
        const isUnknownLink =
          child.type === "itemLink" &&
          !data?.links?.find((link: any) => link && link.id === child.item);
        if (isUnknownBlock || isUnknownLink) {
          node.children.splice(i, 1);
        } else {
          removeInvalidChildren(child);
        }
      }
    }
  };
  removeInvalidChildren((data.value as any).document);
  return data;
};
