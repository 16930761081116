import { globalUtilityClasses } from "./utility-classes";

export const applyGlobalStyles = {
  globalUtilityClasses,
};

// These are classes applied to various modular content
// blocks to have control of spacing between blocks.  We
// need consts as the classes get used in multiple places
export const CSS_CLASSES = {
  NO_MARGIN: "no-margin",
  SMALL_SPACING: "minimal-spacing",
  MEDIUM_SPACING: "small-spacing",
  DISPLAY_ROW: "display-row",
};
