"use client";
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useRef,
  RefObject,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import ReactPlayer from "react-player";
import { analytics, catchAnalyticsError } from "features/analytics";
import { TrackSuggestion } from "../shared/types";
import { LS_PREF_VOLUME } from "utils/local-storage-utils";
import { postToAddUserActivity } from "features/user-activities/client";
import { getUserData, postToSetUserAdditionalData } from "features/user";
import { MusicStatus } from "shared/user.types";
import { debounce } from "utils/generic-utils";
import { clientLoggerForFile } from "utils/logger.client";
import { useToast } from "design-system/components";
import { useDaily, useParticipantIds } from "@daily-co/daily-react";
import { useAtom, useSetAtom } from "jotai";
import {
  musicStatusAtom,
  isPlayingMusicAtom,
  isMusicControlledByHostAtom,
  isListeningToBroadcastAtom,
  musicVolumeAtom,
  OnAppMessageEvent,
  useSafeSendAppMessage,
  useHasJoinedCall,
} from "features/sessions/client";
import { DailyEventObjectParticipant } from "@daily-co/daily-js";
import { ReactMusicPlayer } from "./react-music-player";

const logger = clientLoggerForFile(__filename);

export const LS_MOST_RECENT_TRACK = "most-recent-track";
export const LS_RECENT_TRACKS = "recent-tracks";

export type Track = {
  isLive?: boolean;
  title: string;
  duration: number;
  url: string;
};

type MusicContextData = {
  currentTrack: Track | null;
  isControlCenterOpen: boolean;
  isLoading: boolean;
  isMuted: boolean;
  loadTrack: (track: Track) => void;
  pause: () => void;
  play: () => void;
  onSeek: (progress: number) => void;
  reactPlayerRef: RefObject<ReactPlayer>;
  recentTracks: TrackSuggestion[];
  setRecentTracks: (tracks: TrackSuggestion[]) => void;
  seekPlay: (progress: number) => void;
  setIsControlCenterOpen: Dispatch<SetStateAction<boolean>>;
  setIsLoading: (isLoading: boolean) => void;
  loadUrl: (url: string) => void;
  toggleMute: () => void;
  toggleHideMusicStatus: () => void;
  trackSuggestions?: TrackSuggestion[];
  requestHostMasterTrack: () => void;
  seekMasterTrack: (progress: number) => void;
  pauseMasterTrack: (stopBroadcast?: boolean) => void;
  playMasterTrack: (participant?: string) => void;
  loadMasterTrack: (url: string, participant?: string) => void;
};

export const MusicContext = createContext({} as MusicContextData);

type MusicContextProviderProps = {
  children: ReactNode;
  hasHostPrivileges: boolean;
  trackSuggestions?: TrackSuggestion[];
  isRobotRoom?: boolean;
  isDropIn?: boolean;
  originalHostId: string;
};

export const MusicContextProvider = ({
  children,
  hasHostPrivileges,
  trackSuggestions,
  isRobotRoom = false,
  isDropIn = false,
  originalHostId,
}: MusicContextProviderProps) => {
  const { toast, Toast } = useToast();

  const [musicStatus, setMusicStatus] = useAtom(musicStatusAtom);
  const [isPlaying, setIsPlaying] = useAtom(isPlayingMusicAtom);
  const [isMusicControlledByHost, setIsMusicControlledByHost] = useAtom(
    isMusicControlledByHostAtom
  );

  const [isListeningToBroadcast] = useAtom(isListeningToBroadcastAtom);

  const setVolume = useSetAtom(musicVolumeAtom);

  const [isControlCenterOpen, setIsControlCenterOpen] = useState(false);
  const [loadedUrl, setLoadedUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recentTracks, setRecentTracks] = useState<TrackSuggestion[]>([]);
  const [currentTrack, setCurrentTrack] = useState<Track | null>(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const reactPlayerRef = useRef<ReactPlayer>(null);

  const { sendAppMessageToParticipant } = useSafeSendAppMessage();

  const playerEventType =
    isMusicControlledByHost && hasHostPrivileges
      ? "Host Player Event"
      : "Player Event";

  const loadUrl = useCallback((url: string) => {
    setLoadedUrl(url);
    if (!url) {
      setCurrentTrack(null);
    }
  }, []);

  const loadTrack = (track: Track) => {
    // Before loading the next track, update the recent tracks list if a new one was added to LS
    const storedRecentTracks = localStorage.getItem(LS_RECENT_TRACKS);
    if (storedRecentTracks) {
      setRecentTracks(JSON.parse(storedRecentTracks));
    }

    setCurrentTrack(track);
    // Do not save tracks loaded by the host on participant local storage
    if (isMusicControlledByHost && !hasHostPrivileges) return;

    const notAlreadyInRecentTracks = !recentTracks.some(
      (recent) => recent.url === track.url
    );

    if (notAlreadyInRecentTracks) {
      if (!isRobotRoom) {
        void postToAddUserActivity({
          product: "music",
          payload: {
            title: track.title,
            url: track.url,
            platform: "youtube",
          },
        });
      }
      localStorage.setItem(
        LS_RECENT_TRACKS,
        JSON.stringify([
          { title: track.title, url: track.url },
          ...recentTracks,
        ])
      );
    }
  };

  const callFrame = useDaily();
  const userHasJoined = useHasJoinedCall();

  const requestHostPlay = useCallback(() => {
    if (!userHasJoined) {
      return toast(
        "warning",
        "Failed to play broadcast track on the user side. You must join the session before executing host actions"
      );
    }
    // TODO: this is hacky for now, it could be the cohost that is brodcasting music
    // for now we are not handling that case and it may break when another co-host comes in
    // and they will not hear the music
    sendAppMessageToParticipant({
      appMessage: { action: "request-play-master-track" },
      participantId: originalHostId,
    });
  }, [originalHostId, toast, userHasJoined, sendAppMessageToParticipant]);

  const requestHostMasterTrack = useCallback(() => {
    if (!isMusicControlledByHost) return;
    sendAppMessageToParticipant({
      appMessage: { action: "request-load-master-track" },
      participantId: originalHostId,
    });
    setTimeout(() => {
      requestHostPlay();
    }, 2000);
  }, [
    isMusicControlledByHost,
    originalHostId,
    requestHostPlay,
    sendAppMessageToParticipant,
  ]);

  /**
   * When the user joins, this hook will run again.
   * If the music is playing, we load it for them and immediately
   * send a request to the host. This is then picked up on the host
   * side that sets the broadcast for the user that just joined
   */
  useEffect(() => {
    if (!currentTrack || !isMusicControlledByHost || !userHasJoined) return;
    requestHostPlay();
  }, [
    callFrame,
    currentTrack,
    isMusicControlledByHost,
    requestHostPlay,
    toast,
    userHasJoined,
  ]);

  const onSeek = useCallback((newProgress: number) => {
    if (reactPlayerRef.current) {
      reactPlayerRef.current.seekTo(newProgress / 100);
    }
  }, []);

  const play = useCallback(() => {
    setHasPlayed(true);
    setIsPlaying(true);
  }, [setIsPlaying]);

  const seekPlay = useCallback(
    (progress: number) => {
      onSeek(progress);
      play();
    },
    [play, onSeek]
  );

  const pause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRecordPlayPause = useCallback(
    debounce((_isPlaying: boolean) => {
      if (currentTrack && !isRobotRoom) {
        analytics
          .track(playerEventType, {
            action: _isPlaying ? "Play" : "Pause",
            title: currentTrack.title,
            url: currentTrack.url,
            duration: currentTrack.duration,
            isSharing: !!musicStatus && !musicStatus.hide,
          })
          .catch(catchAnalyticsError);
      }
    }, 1000),
    [currentTrack, playerEventType]
  );

  useEffect(() => {
    if (!hasPlayed) return;
    debouncedRecordPlayPause(isPlaying);
    // Disabling exhaustive-deps because a track should always be loaded in order to play/pause be enabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, debouncedRecordPlayPause]);

  const toggleMute = () => setIsMuted((prev) => !prev);

  useEffect(() => {
    if (loadedUrl && !(isMusicControlledByHost && !hasHostPrivileges)) {
      localStorage.setItem(LS_MOST_RECENT_TRACK, loadedUrl);
    }
  }, [isMusicControlledByHost, hasHostPrivileges, loadedUrl]);

  useEffect(() => {
    if (isMusicControlledByHost && isListeningToBroadcast) setVolume(17.5);
  }, [isMusicControlledByHost, isListeningToBroadcast, setVolume]);

  useEffect(() => {
    const prefVolume = localStorage.getItem(LS_PREF_VOLUME);
    if (prefVolume) {
      setVolume(parseInt(prefVolume));
    }
    const mostRecentTrack = localStorage.getItem(LS_MOST_RECENT_TRACK);
    if (mostRecentTrack) {
      setLoadedUrl(mostRecentTrack);
    }
    const storedRecentTracks = localStorage.getItem(LS_RECENT_TRACKS);

    if (storedRecentTracks) {
      setRecentTracks(JSON.parse(storedRecentTracks));
    }
  }, [setVolume]);

  const seekMasterTrack = useCallback(
    (progress: number) => {
      if (!userHasJoined) {
        return toast(
          "warning",
          "Failed to seek broadcast track. You must join the session before executing host actions"
        );
      }
      sendAppMessageToParticipant({
        appMessage: { action: "seek-master-track", progress },
        participantId: "*",
      });
    },
    [sendAppMessageToParticipant, toast, userHasJoined]
  );

  const pauseMasterTrack = useCallback(
    (stopBroadcast = false) => {
      if (!userHasJoined) {
        return toast(
          "warning",
          "Failed to pause broadcast track. You must join the session before executing host actions"
        );
      }
      sendAppMessageToParticipant({
        appMessage: { action: "pause-master-track", stopBroadcast },
        participantId: "*",
      });
    },
    [userHasJoined, sendAppMessageToParticipant, toast]
  );

  useEffect(() => {
    if (!(hasHostPrivileges && isMusicControlledByHost) || isPlaying) return;
    // Stop broadcast if host pauses music for longer than 30 seconds
    const timeout = setTimeout(() => {
      if (!isPlaying) {
        sendAppMessageToParticipant({
          appMessage: { action: "pause-master-track", stopBroadcast: true },
          participantId: "*",
        });
        setIsMusicControlledByHost(false);
      }
    }, 30 * 1000);

    return () => clearTimeout(timeout);
  }, [
    hasHostPrivileges,
    isMusicControlledByHost,
    isPlaying,
    sendAppMessageToParticipant,
    setIsMusicControlledByHost,
  ]);

  const loadMasterTrack = useCallback(
    (url: string, participant?: string) => {
      if (!userHasJoined) {
        return toast(
          "warning",
          "Failed to load broadcast track. You must join the session before executing host actions"
        );
      }
      sendAppMessageToParticipant({
        appMessage: { action: "load-master-track", url },
        participantId: participant ?? "*",
      });
    },
    [sendAppMessageToParticipant, toast, userHasJoined]
  );

  const playMasterTrack = useCallback(
    (participant?: string) => {
      if (!userHasJoined) {
        toast(
          "warning",
          "Failed to play broadcast track. You must join the session before executing host actions"
        );
        return;
      }
      const reactPlayerCurrent = reactPlayerRef.current;
      if (reactPlayerCurrent) {
        const currentTime = reactPlayerCurrent.getCurrentTime();
        const duration = reactPlayerCurrent.getDuration();

        const progress = (currentTime / duration) * 100;
        sendAppMessageToParticipant({
          appMessage: {
            action: "play-master-track",
            progress,
          },
          participantId: participant ?? "*",
        });
      }
    },
    [sendAppMessageToParticipant, toast, userHasJoined]
  );

  useEffect(() => {
    const fetchUserMusicStatus = async () => {
      const userData = await getUserData(["additionalData"]);

      if (userData.worked && userData.additionalData?.musicStatus) {
        const { musicStatus } = userData.additionalData;
        setMusicStatus(musicStatus);
      }
    };
    void fetchUserMusicStatus();
  }, [setMusicStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateMusicStatus = useCallback(
    debounce((musicStatusPayload: MusicStatus) => {
      setMusicStatus(musicStatusPayload);
      if (!isRobotRoom) {
        void postToSetUserAdditionalData({
          musicStatus: musicStatusPayload,
        });
      }
    }),
    [setMusicStatus]
  );

  const resetPlayer = useCallback(() => {
    const mostRecentTrack = localStorage.getItem(LS_MOST_RECENT_TRACK);
    loadUrl(mostRecentTrack ?? "");
  }, [loadUrl]);

  useEffect(() => {
    if (!callFrame || callFrame.isDestroyed()) return;

    const onAppMessage = (event: OnAppMessageEvent) => {
      if (!event || isDropIn) return;
      if (event.data.action === "load-master-track") {
        setIsMusicControlledByHost(true);

        if (isListeningToBroadcast) {
          loadUrl(event.data.url);
        }
      } else if (event.data.action === "play-master-track") {
        if (isListeningToBroadcast) {
          seekPlay(event.data.progress);
        }
      } else if (event.data.action === "seek-master-track") {
        if (isListeningToBroadcast) {
          onSeek(event.data.progress);
        }
      } else if (event.data.action === "pause-master-track") {
        if (isListeningToBroadcast) {
          pause();
          if (event.data.stopBroadcast) {
            setIsMusicControlledByHost(false);
            resetPlayer();
          }
        } else if (event.data.stopBroadcast) {
          setIsMusicControlledByHost(false);
        }
      } else if (
        event?.data.action === "request-play-master-track" &&
        isMusicControlledByHost &&
        isPlaying
      ) {
        // This event is received by the host only
        playMasterTrack(event.fromId);
      } else if (
        event?.data.action === "request-load-master-track" &&
        currentTrack?.url &&
        isMusicControlledByHost
      ) {
        // This event is received by the host only
        loadMasterTrack(currentTrack.url, event.fromId);
      }
    };

    callFrame.on("app-message", onAppMessage);
    return () => {
      try {
        callFrame.off("app-message", onAppMessage);
      } catch (error) {
        logger.info(
          "Failed to cleanup call frame listener due to call iframe undefined"
        );
      }
    };
  }, [
    callFrame,
    onSeek,
    pause,
    playMasterTrack,
    seekPlay,
    setIsMusicControlledByHost,
    loadUrl,
    userHasJoined,
    isPlaying,
    toast,
    isDropIn,
    isListeningToBroadcast,
    loadMasterTrack,
    currentTrack?.url,
    isMusicControlledByHost,
    resetPlayer,
  ]);

  useEffect(() => {
    // This effect handles the user opting in or out listening to the host broadcast
    if (!isListeningToBroadcast) {
      pause();
      resetPlayer();
    }
  }, [isListeningToBroadcast, pause, resetPlayer]);

  useEffect(() => {
    if (currentTrack) {
      updateMusicStatus({
        title: currentTrack.title,
        url: currentTrack.url,
        platform: "youtube" as const,
        hide: musicStatus?.hide ?? true,
        playing: isPlaying,
      });
    }
  }, [
    currentTrack,
    isPlaying,
    musicStatus?.hide,
    setMusicStatus,
    updateMusicStatus,
  ]);

  const toggleHideMusicStatus = () => {
    if (musicStatus) {
      debounce(() => {
        const musicStatusPayload = {
          ...musicStatus,
          hide: !musicStatus?.hide,
        };
        setMusicStatus(musicStatusPayload);

        if (!isRobotRoom) {
          void analytics
            .track("Player Event", {
              action: musicStatusPayload.hide
                ? "Hid Music Status"
                : "Shared Music Status",
              ...musicStatusPayload,
            })
            .catch(catchAnalyticsError);
        }
      }, 500)();
    }
  };

  useParticipantIds({
    onParticipantJoined: useCallback<
      (event: DailyEventObjectParticipant) => void
    >(
      (event) => {
        if (
          !hasHostPrivileges ||
          !currentTrack?.url ||
          !isMusicControlledByHost
        )
          return;

        /**
         * At this time, the a new user has joined the call and the
         * host is broadcasting music. This is from the host perspective as
         * isMusicControlledByHost is set by the host themselves.
         * The host calls load master track and this loads the track for the user
         * that just joined.
         */

        setTimeout(() => {
          loadMasterTrack(currentTrack.url, event?.participant.session_id);
        }, 2000);
      },
      [
        currentTrack?.url,
        hasHostPrivileges,
        isMusicControlledByHost,
        loadMasterTrack,
      ]
    ),
  });

  return (
    <MusicContext.Provider
      value={{
        loadTrack,
        currentTrack,
        isControlCenterOpen,
        isLoading,
        isMuted,
        toggleMute,
        pause,
        play,
        onSeek,
        reactPlayerRef,
        seekPlay,
        setIsControlCenterOpen,
        setIsLoading,
        loadUrl,
        recentTracks,
        setRecentTracks,
        toggleHideMusicStatus,
        trackSuggestions,
        requestHostMasterTrack,
        seekMasterTrack,
        pauseMasterTrack,
        loadMasterTrack,
        playMasterTrack,
      }}
    >
      {Toast}
      <ReactMusicPlayer
        reactPlayerRef={reactPlayerRef}
        loadedUrl={loadedUrl}
        onSeek={onSeek}
        setIsLoading={setIsLoading}
        play={play}
        pause={pause}
        isPlaying={isPlaying}
        currentTrack={currentTrack}
        loadTrack={loadTrack}
        isMuted={isMuted}
        playerEventType={playerEventType}
        loadMasterTrack={loadMasterTrack}
      />
      {children}
    </MusicContext.Provider>
  );
};

export const useMusic = () => {
  const providerValue = useContext(MusicContext);
  if (Object.keys(providerValue).length === 0) {
    logger.warn("useMusic must be used within MusicContextProvider");
  }
  return providerValue;
};
