import * as Dialog from "@radix-ui/react-dialog";
import { Icon } from "design-system/components";
import { Box } from "design-system/components";
import { useCallback } from "react";
import { SidePanelConfig } from "context/side-panel-context";
import { styled } from "styled-system/jsx";
import { CSSCustomProperties } from "design-system/design-system.types";

export const NORMAL_SIZE = "normal";
export const LARGE_SIZE = "large";
export const SIDE_PANEL_OFFSET_TOP = 60;

export type SidePanelSizes = typeof LARGE_SIZE | typeof NORMAL_SIZE;

export type SidePanelProps = React.ComponentProps<typeof Dialog.Root> & {
  children: React.ReactNode;
  open?: boolean;
  onClose: () => void;
  topPosition?: number;
  title: string | React.ReactNode;
  size: SidePanelSizes;
  sidePanelHistory?: SidePanelConfig[];
  preventDismissOnOverlayClick?: boolean;
  onNavigateBack?: () => void;
  // Allow overriding the children container styles for specific cases e.g. AccountSidePanel
  childrenContainerStyles?: React.CSSProperties;
};

export const SidePanel = ({
  children,
  open,
  onClose,
  onNavigateBack,
  topPosition = SIDE_PANEL_OFFSET_TOP,
  title,
  size,
  preventDismissOnOverlayClick = false,
  sidePanelHistory = [],
  childrenContainerStyles,
}: SidePanelProps) => {
  const onOpenChange = useCallback(
    (openState: boolean) => {
      if (!openState) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Dialog.Root open={open} modal={false} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <DialogContentStyled
          className={size}
          style={
            {
              "--top-position": `${topPosition}px`,
            } as CSSCustomProperties
          }
          onOpenAutoFocus={(event) => {
            // https://github.com/radix-ui/primitives/discussions/935
            event.preventDefault();
          }}
          onPointerDownOutside={(event) => {
            if (preventDismissOnOverlayClick) {
              event.preventDefault();
            }
          }}
          onInteractOutside={(event) => {
            if (preventDismissOnOverlayClick) {
              event.preventDefault();
            }
          }}
        >
          <Container data-component="SidePanelContent">
            {title ? (
              <Box className="flex justify-between align-center pad-x-20 pad-y-16">
                {onNavigateBack && sidePanelHistory.length > 1 && (
                  <Box onClick={onNavigateBack}>
                    <IconBox>
                      <Icon icon="arrow-left" />
                    </IconBox>
                  </Box>
                )}
                <DialogTitle>{title}</DialogTitle>
                <Dialog.Close asChild>
                  <IconBox data-cy="side-panel-close-btn">
                    <Icon icon="close" />
                  </IconBox>
                </Dialog.Close>
              </Box>
            ) : (
              <Box className="flex justify-end align-center pad-x-20 pad-top-16">
                {onNavigateBack && sidePanelHistory.length > 1 && (
                  <Box onClick={onNavigateBack} className="mar-right-auto">
                    <IconBox>
                      <Icon icon="arrow-left" />
                    </IconBox>
                  </Box>
                )}
                <Dialog.Close asChild onClick={onClose}>
                  <IconBox>
                    <Icon icon="close" />
                  </IconBox>
                </Dialog.Close>
              </Box>
            )}
            <ContentWrapper style={childrenContainerStyles}>
              {children}
            </ContentWrapper>
          </Container>
        </DialogContentStyled>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const DialogContentStyled = styled(Dialog.Content, {
  base: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    backgroundColor: "white",
    boxShadow:
      "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
    zIndex: 201,
    _focus: {
      outline: "none",
    },
    "bp-side-panel": {
      width: "{sizes.sidePanelWidth}",
      left: "unset",
      top: "var(--top-position)",
      "&.large": {
        width: "{sizes.sidePanelWidthLarge}",
      },
    },
  },
});

const Container = styled("div", {
  base: {
    animation: `fadeIn 1s cubic-bezier(0.32, 0.72, 0, 1)`,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxHeight: "100dvh",
    height: "100%",
  },
});

const IconBox = styled("div", {
  base: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "{radii.32}",
    backgroundColor: "{colors.blueGrey100}",
    width: "48px",
    height: "48px",
    "bp-side-panel": {
      backgroundColor: "transparent",
    },
  },
});

const ContentWrapper = styled(Box, {
  base: {
    padding: `{spacing.4} {spacing.20}`,
    overflowY: "auto",
    height: `calc(100vh - ${SIDE_PANEL_OFFSET_TOP}px)`,
  },
});

const DialogTitle = styled(Dialog.Title, {
  base: {
    fontSize: "{fontSizes.2xl}",
  },
});
