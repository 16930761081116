import { useRouter } from "next/router";
import { analytics, catchAnalyticsError } from "features/analytics";
import { renderMetaTags } from "react-datocms";
import { useEffect, useState } from "react";
import { useIntercom } from "services/intercom/intercom-utils";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "utils/local-storage-utils";
import { ModularContentPage } from "features/modular-content";

import "video.js/dist/video-js.css";
import "../styles/globals.css";

import {
  getPageCategoryBySlug,
  getPageAnalyticsTitle,
  HIDE_INTERCOM_URL_PREFIXES,
  PRODUCT_FRUITS_PREFIX,
  REGISTER_URL,
  getCanonicalUrlForPath,
} from "utils/urls";
import { AppWrapper } from "legacy-design-system/layout/app-wrapper";
import { UserContextProvider } from "context/user-context";
import { DesignSystemThemeProvider } from "design-system/emotion.theme";
import { Provider as ToastProvider } from "@radix-ui/react-toast";
import { ToastViewport, MetaHeader } from "design-system/components";
import { IntercomChatButton } from "services/intercom/intercom-chat-button";
import { AppProps } from "next/app";

import { AppScripts } from "utils/app-scripts-utils";
import { isDevelopmentMode, isProductionMode } from "utils/environment";
import { globalUtilityClasses } from "design-system/styles/utility-classes";
import { formatToShortISO } from "utils/date";
import dayjs from "dayjs";
import {
  postToAddUserActivity,
  postToHasUserJoinedChallenge,
} from "features/user-activities/client";
import { QUERY_PARAMS, getQueryParam } from "utils/url-query-utils";
import { PRODUCTS } from "shared/products.types";
import { fetchRetryClient } from "utils/fetch-retry-client";
import { initDatadog } from "utils/datadog-client";
import { SidePanelProvider } from "context/side-panel-context";
import { ConnectionsContextProvider } from "context/connections-context";
import { StatsigProvider } from "statsig-react";
import Cookies from "js-cookie";
import { ANALYTICS_CLIENT_COOKIE_KEY, EXTERNAL_ID } from "utils/constants";
import { UsersInDropInProvider } from "context/users-in-dropin-context";
import dynamic from "next/dynamic";
import { postToSetUserAdditionalData } from "features/user";
import { useIsMediaDesktop } from "utils/component-utils";
import { ParsedUserAgentProvider } from "context/parsed-user-agent-context";
import { PWAProvider } from "context/pwa-context";
import { useOneSignal } from "utils/onesignal-utils";
import { ErrorBoundary } from "design-system/components";
import { Provider as JotaiProvider } from "jotai";

const ProductFruits = dynamic(
  () => import("react-product-fruits").then((mod) => mod.ProductFruits),
  { ssr: false }
);

type MetaTag = {
  tag: string;
  content: string;
  attributes: Record<string, any>;
};

initDatadog();

// TODO: better types here for page and app props AppProps<StandardPageProps> - this needs a refactor and another PR but will make things
// More type safe
const MyApp = ({ Component, pageProps }: AppProps) => {
  const metaTags =
    pageProps?._seoMetaTags ||
    pageProps?.data?._seoMetaTags ||
    pageProps?.fallbackContent?._seoMetaTags ||
    [];

  const titleMetaTag = metaTags.find(
    (metaTag: MetaTag) => metaTag.tag === "title"
  )?.content;

  const title =
    titleMetaTag ||
    pageProps?.title ||
    pageProps?.data?.title ||
    pageProps?.fallbackContent?.title ||
    pageProps?.blogPost?.title;

  const descriptionMetaTag = metaTags.find(
    (metaTag: MetaTag) => metaTag.tag === "description"
  )?.content;
  const seoDescription = descriptionMetaTag || pageProps?.seoDescription;

  const imageMetaTag = metaTags.find(
    (metaTag: MetaTag) => metaTag.tag === "imageUrl"
  )?.content;
  const seoImageUrl =
    imageMetaTag || pageProps?.seoImageUrl || pageProps?.data?.seoImageUrl;

  const cmsId = pageProps?.id || pageProps?.data?.id;
  const {
    externalId,
    fallbackContent,
    pageName,
    freeTrialRemainingDays,
    intercomVerificationHash,
    userRole,
    loggedIn,
    email,
    createTime,
    firstName,
    lastName,
    isVerified,
    avatarUrl,
    oneLiner,
    headline,
    hasGclid,
    parsedUserAgent,
    organisation,
  } = pageProps;

  const [hasSetGclid, setHasSetGclid] = useState<boolean>(false);

  const { asPath, pathname, query, replace } = useRouter();

  const canonicalUrl = pageProps?.siteBaseUrl
    ? getCanonicalUrlForPath({ siteBaseUrl: pageProps.siteBaseUrl, asPath })
    : null;

  useEffect(() => {
    const handleGclidSetup = async () => {
      const gclid = getQueryParam(query.gclid);

      // Capture users that may already have a gclid in local storage
      if (!gclid && loggedIn && !hasGclid && !hasSetGclid) {
        const storedGclid = getLocalStorageWithExpiry("utm-data")?.gclid;
        // User had a previous gclid stored in local storage so we set it to that
        if (storedGclid) {
          await postToSetUserAdditionalData({
            gclid: storedGclid,
          });
        }
      } else if (gclid && loggedIn && !hasGclid && !hasSetGclid) {
        await postToSetUserAdditionalData({
          gclid,
        });
        setHasSetGclid(true);
      }
    };

    void handleGclidSetup();
  }, [asPath, query, hasSetGclid, hasGclid, loggedIn]);
  const isDesktop = useIsMediaDesktop();

  const [triggerAnonProductFruits, setTriggerAnonProductFruits] =
    useState<boolean>(false);

  // Set external Id cookie - this is for middleware to use
  useEffect(() => {
    if (externalId) {
      Cookies.set(EXTERNAL_ID, externalId);
    }
  }, [externalId]);

  const anonId = Cookies.get(ANALYTICS_CLIENT_COOKIE_KEY);

  const [statsigUser, setStatsigUser] = useState({
    userID: externalId || anonId,
    custom: { isDesktop },
  });

  useEffect(() => {
    const computeStatsigUser = {
      userID: externalId || anonId,
      custom: { isDesktop },
    };
    setStatsigUser(computeStatsigUser);
  }, [externalId, isDesktop, anonId]);

  const [hideIntercomChat, setHideIntercomChat] = useState(true);
  useEffect(() => {
    const handleRouteChange = async () => {
      const gclid = getQueryParam(query.gclid);

      const campaignData = {
        name: query.utm_campaign,
        medium: query.utm_medium,
        source: query.utm_source,
        term: query.utm_term,
        content: query.utm_content,
        gclid,
      };
      if (Object.values(campaignData).find(Boolean)) {
        // We want to set a long storage so that campaignData gets
        // added to all future events for 1 week - not just the next 10 mins.
        setLocalStorageWithExpiry("utm-data", campaignData, 6048000000);
      }

      const category = getPageCategoryBySlug(asPath, loggedIn);
      const analyticsTitle = getPageAnalyticsTitle(asPath);
      analytics
        .identify(
          {
            "UTM name": query.utm_campaign,
            "UTM medium": query.utm_medium,
            "UTM source": query.utm_source,
            "UTM term": query.utm_term,
            "UTM content": query.utm_content,
          },
          {
            All: true,
            "Actions Google Analytics 4": false,
            "Customer.io Actions": !!loggedIn,
          }
        )
        .catch(catchAnalyticsError);

      analytics.pageClient({
        category,
        name: pageName || analyticsTitle || "Untracked page",
      });

      const REDIRECT_QUERY = QUERY_PARAMS.REDIRECT_AFTER_REGISTER;
      if (query[REDIRECT_QUERY]) {
        localStorage.setItem(REDIRECT_QUERY, query[REDIRECT_QUERY] as string);
      }

      // Now we have cached pages we don't save query params in the session.  If
      // either of these params are present call off to a non cached page which
      // will save these in the session plus any other functionality that the server runs.
      if (query[QUERY_PARAMS.REFERRAL] || query[QUERY_PARAMS.PROMO_CODE]) {
        void fetchRetryClient(
          `/set-session-query-params${window.location.search}`
        );
      }

      const QUERY_JOIN_CHALLENGE = QUERY_PARAMS.JOIN_CHALLENGE;
      if (query[QUERY_JOIN_CHALLENGE] && loggedIn) {
        const slug = query[QUERY_JOIN_CHALLENGE] as string;
        const response = await postToHasUserJoinedChallenge({
          slug,
        });
        if (response.worked && !response.hasJoinedChallenge) {
          void analytics.track("Challenge Joined", {
            slug,
            dateJoined: formatToShortISO(dayjs()),
          });
          await postToAddUserActivity({
            product: PRODUCTS.CHALLENGE,
            payload: {
              action: "joined",
              slug,
            },
          });
        }
      }

      if (query[QUERY_PARAMS.RELOAD] === "true") {
        void replace(
          pathname + "?" + new URLSearchParams({ ...query, reload: "false" })
        );
      }
    };
    const hideIntercom =
      loggedIn &&
      !!HIDE_INTERCOM_URL_PREFIXES.find((prefix) => asPath.startsWith(prefix));
    setHideIntercomChat(hideIntercom);
    void handleRouteChange();
  }, [asPath, cmsId, title, query, loggedIn, pageName, pathname, replace]);

  useOneSignal();

  useIntercom({
    externalId,
    intercomVerificationHash,
    loggedIn,
  });

  useEffect(() => {
    if (
      global.window &&
      (window.location.pathname === REGISTER_URL ||
        window.location.pathname.indexOf(PRODUCT_FRUITS_PREFIX) === 0) &&
      !externalId
    ) {
      setTriggerAnonProductFruits(true);
    } else {
      setTriggerAnonProductFruits(false);
    }
  }, [externalId]);

  return (
    <>
      <MetaHeader
        title={title}
        description={seoDescription}
        imageUrlParam={seoImageUrl}
        canonicalUrl={canonicalUrl}
      >
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover"
        />
        <link rel="icon" type="image/png" href="/favicon.png" />
        {metaTags && renderMetaTags(metaTags)}
      </MetaHeader>
      <AppScripts />
      {global.window && externalId && email && (
        <ProductFruits
          workspaceCode={
            isProductionMode() ? "r5UDO0KyyVBJt6vE" : "U4ACm1ouRNAUUmBC"
          }
          debug={isDevelopmentMode()}
          language="en"
          user={{
            username: externalId,
            email,
            signUpAt: createTime,
          }}
        />
      )}
      {triggerAnonProductFruits && (
        // we want to trigger PF for anon users but just on the register page to avoid too much cost.
        // If we need PF elsewhere then we can allow on other pages - but mindful of MAU and cost
        <ProductFruits
          workspaceCode={
            isProductionMode() ? "r5UDO0KyyVBJt6vE" : "U4ACm1ouRNAUUmBC"
          }
          debug={isDevelopmentMode()}
          language="en"
          user={{
            username: anonId || "anon", //anon id sometimes gets set later so we initialise with anon
          }}
        />
      )}
      <StatsigProvider
        sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY!}
        waitForInitialization={false}
        options={{
          environment: {
            tier: isProductionMode() ? "production" : "staging",
          },
        }}
        user={statsigUser}
      >
        <JotaiProvider>
          <ParsedUserAgentProvider parsedUserAgent={parsedUserAgent}>
            <PWAProvider>
              <UserContextProvider
                userProps={{
                  loggedIn,
                  freeTrialRemainingDays,
                  userRole,
                  email,
                  externalId,
                  isVerified,
                  firstName,
                  lastName,
                  avatarUrl,
                  oneLiner,
                  headline,
                  createTime,
                  organisation,
                }}
              >
                <ConnectionsContextProvider>
                  <SidePanelProvider>
                    <DesignSystemThemeProvider>
                      {globalUtilityClasses}
                      <ToastProvider duration={3500}>
                        <ToastViewport />
                        <UsersInDropInProvider>
                          <AppWrapper
                            loggedIn={loggedIn}
                            asPath={asPath}
                            pathname={pathname}
                          >
                            {!hideIntercomChat && <IntercomChatButton />}
                            <ErrorBoundary key={asPath}>
                              {fallbackContent ? (
                                <ModularContentPage data={fallbackContent} />
                              ) : (
                                <Component {...pageProps} />
                              )}
                            </ErrorBoundary>
                          </AppWrapper>
                        </UsersInDropInProvider>
                      </ToastProvider>
                    </DesignSystemThemeProvider>
                  </SidePanelProvider>
                </ConnectionsContextProvider>
              </UserContextProvider>
            </PWAProvider>
          </ParsedUserAgentProvider>
        </JotaiProvider>
      </StatsigProvider>
    </>
  );
};

export default MyApp;
