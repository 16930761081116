import { useUserContext } from "context/user-context";
import dayjs from "dayjs";
import {
  BoostOrClapIntentionRequest,
  PublicIntentionEntry,
  Visibility,
} from "../../shared/types";
import { getUserDisplayName } from "features/profile/shared";
import {
  Box,
  BoxFlex,
  Button,
  Icon,
  Row,
  Text,
  TextSuccess,
  Tooltip,
  useToast,
} from "design-system/components";
import { Avatar } from "design-system/molecules/avatar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { USER_URL } from "utils/urls";
import {
  postToBoostIntention,
  postToClapIntention,
  postToEditIntention,
} from "../accountability-client";
import { IntentionReactionCount } from "./intention-reaction-count";
import { postToSetIsCompletedIntention } from "../accountability-client";
import { token } from "styled-system/tokens";
import { VisibilitySelect } from "./visibility-select";
import { styled } from "styled-system/jsx";
import { IntentionMusicStatus } from "./intention-music-status";
import { MAX_SUBTASKS_PER_INTENTION } from "./intention-subtasks/common";
import { IntentionSubtaskView } from "./intention-subtasks/intetion-subtask-view";
import { pluralise } from "utils/string-utils";
import { useDebounceCallback } from "utils/hooks/use-debounce-callback";

type UserIntentionCardProps = PublicIntentionEntry & {
  isLatestIntention?: boolean;
  isProfileCard?: boolean;
  onUserClick?: (userExternalId: string) => void;
  visibilityOptions: Visibility[];
};

export const UserIntentionCard = ({
  _id,
  createTime,
  description,
  isCompleted,
  isProfileCard,
  isLatestIntention,
  user,
  boosts = [],
  claps = [],
  visibility,
  visibilityOptions,
  subtasks,
  onUserClick = () => window.open(`${USER_URL}/${user.externalId}`, "_blank"),
}: UserIntentionCardProps) => {
  const { externalId, isFlownRole } = useUserContext();
  const { toast, Toast } = useToast();

  const isOwnIntention = useMemo(
    () => user.externalId === externalId,
    [externalId, user.externalId]
  );

  // This component handles boosting reaction on the client side until the updated intention is fetched from the server
  // This is done to avoid the need to refetch the entire list of intentions on every boost click

  /** Used to keep track of the user's reaction with the intention on the client side, updates to either 1 or -1 depending if intention was previously boosted by the user or not when the user clicks on the boost button  */
  const [userClientReactionBoostOffset, setUserClientReactionBoostOffset] =
    useState(0);
  const [userClientReactionClapOffset, setUserClientReactionClapOffset] =
    useState(0);
  const [isCompleteClient, setIsCompleteClient] = useState(isCompleted);
  const [isCompletingIntention, setIsCompletingIntention] = useState(false);

  useEffect(() => {
    setUserClientReactionBoostOffset(0);
  }, [boosts.length]);

  useEffect(() => {
    setUserClientReactionClapOffset(0);
  }, [claps.length]);

  const boostClientState = useMemo(() => {
    if (userClientReactionBoostOffset === 1) {
      return [...boosts, externalId || ""];
    } else if (userClientReactionBoostOffset === -1) {
      return boosts.filter((boost) => boost !== externalId);
    }
    return boosts;
  }, [boosts, externalId, userClientReactionBoostOffset]);

  const clapClientState = useMemo(() => {
    if (userClientReactionClapOffset === 1) {
      return [...claps, externalId || ""];
    } else if (userClientReactionClapOffset === -1) {
      return claps.filter((boost) => boost !== externalId);
    }
    return claps;
  }, [claps, externalId, userClientReactionClapOffset]);

  /** Checks if the intention received as prop is boosted by the user */
  const isIntentionBoostedByUser = useMemo(
    () =>
      boostClientState.some((boost) => boost === externalId) ||
      userClientReactionBoostOffset === 1,
    [boostClientState, externalId, userClientReactionBoostOffset]
  );

  const isIntentionClappedByUser = useMemo(
    () =>
      clapClientState.some((clap) => clap === externalId) ||
      userClientReactionClapOffset === 1,
    [clapClientState, externalId, userClientReactionClapOffset]
  );

  const postToBoostIntentionCallback = useCallback(
    async ({ action, intentionId }: BoostOrClapIntentionRequest) => {
      void postToBoostIntention({ action, intentionId });
    },
    []
  );

  const debouncedPostToBoostIntention = useDebounceCallback(
    postToBoostIntentionCallback,
    750
  );

  const postToClapIntentionCallback = useCallback(
    async ({ action, intentionId }: BoostOrClapIntentionRequest) => {
      void postToClapIntention({ action, intentionId });
    },
    []
  );

  const debouncedPostToClapIntention = useDebounceCallback(
    postToClapIntentionCallback,
    750
  );

  const onBoostClick = () => {
    setUserClientReactionBoostOffset((prev) =>
      prev === 1 || prev === -1 ? 0 : isIntentionBoostedByUser ? -1 : 1
    );
    if (externalId) {
      void debouncedPostToBoostIntention({
        action:
          isIntentionBoostedByUser || userClientReactionBoostOffset === 1
            ? "remove"
            : "add",
        intentionId: _id.toString(),
      });
    }
  };

  const onClapClick = () => {
    setUserClientReactionClapOffset((prev) =>
      prev === 1 || prev === -1 ? 0 : isIntentionClappedByUser ? -1 : 1
    );
    if (externalId) {
      void debouncedPostToClapIntention({
        action:
          isIntentionClappedByUser || userClientReactionClapOffset === 1
            ? "remove"
            : "add",
        intentionId: _id.toString(),
      });
    }
  };
  const { musicStatus } = user.additionalData ?? {};

  const completedTasks = subtasks?.filter((subtask) => subtask.completed);
  const totalSubstasks = subtasks?.length || MAX_SUBTASKS_PER_INTENTION;

  return (
    <>
      {Toast}
      <UserIntentionCardBox className={isProfileCard ? "one-col" : ""}>
        {!isProfileCard && (
          <Avatar
            imageUrl={user.avatarUrl}
            dimensions={45}
            userFullName={getUserDisplayName(user)}
            onClick={() => onUserClick(user.externalId)}
          />
        )}
        <Box className="flex-col gap-12 w-full">
          <Box className="flex-col">
            {!isProfileCard && (
              <>
                <Row className="justify-between pos-relative">
                  <Text
                    className="cursor-pointer hov-opacity-6"
                    fontWeight={700}
                    onClick={() => onUserClick(user.externalId)}
                  >
                    {getUserDisplayName(user)}
                  </Text>
                  <Text fontSize="sm" color="grey300">
                    {dayjs(createTime).fromNow(true)}
                  </Text>
                </Row>
                <Text
                  className="text-overflow-ellipsis"
                  charsPerLine={35}
                  fontSize="xs"
                  color="grey300"
                >
                  {user.oneLiner}
                  {user.oneLiner && user.location && " • "}
                  {user.location}
                </Text>
              </>
            )}
            {!isProfileCard && !musicStatus?.hide && musicStatus?.playing && (
              <IntentionMusicStatus musicStatus={musicStatus} />
            )}
          </Box>

          <BoxFlex className="align-start">
            <Text
              fontSize="sm"
              className="flex-1 w-full"
              style={{ wordBreak: "break-word" }}
            >
              {description}
            </Text>
            <Box className="flex align-center gap-4">
              {isOwnIntention && visibility && (
                <VisibilitySelect
                  intentionId={_id.toString()}
                  selectedVisibility={visibility}
                  onVisibilityChange={async (visibility) => {
                    const response = await postToEditIntention({
                      id: _id.toString(),
                      visibility,
                    });
                    if (response.worked) {
                      toast("success", "Intention updated successfully");
                    } else {
                      toast("error", "Failed to update intention visibility");
                    }
                  }}
                  options={visibilityOptions}
                />
              )}
            </Box>
          </BoxFlex>
        </Box>
        <IntentionDataContainer
          style={
            isProfileCard
              ? {}
              : {
                  gridColumnEnd: 3,
                }
          }
        >
          <Box className="flex align-center gap-8">
            {isCompleted ? (
              <TextSuccess className="flex gap-4 align-center" fontSize="xs">
                <Icon
                  icon="live-now"
                  size={10}
                  color={token("colors.success")}
                />
                Completed
              </TextSuccess>
            ) : (
              <Text
                className="flex gap-4 align-center"
                fontSize="xs"
                color="grey300"
              >
                <Icon
                  icon="live-now"
                  size={10}
                  color={token("colors.orangeLight")}
                />
                In progress
              </Text>
            )}
            {isFlownRole && subtasks && subtasks.length > 0 ? (
              <Tooltip
                triggerElement={
                  <Text
                    fontSize="xs"
                    color="grey400"
                    className="w-full cursor-pointer"
                  >
                    {`${completedTasks?.length}/${totalSubstasks} ${pluralise(
                      totalSubstasks,
                      "step"
                    )}`}
                  </Text>
                }
              >
                <Box className="flex-col gap-8">
                  {subtasks.map((subtask, index) => (
                    <IntentionSubtaskView subtask={subtask} key={index} />
                  ))}
                </Box>
              </Tooltip>
            ) : null}
          </Box>
          <Box className="flex gap-8">
            {isOwnIntention ? (
              <IntentionReactionCount
                boosts={boostClientState}
                claps={clapClientState}
                isCompleted={isCompleted}
              />
            ) : null}
            {!isOwnIntention &&
              (isCompleted ? (
                <Button
                  variant="secondary"
                  size="small"
                  minWidth={5}
                  style={
                    (isIntentionClappedByUser ||
                      userClientReactionClapOffset === 1) &&
                    userClientReactionClapOffset !== -1
                      ? { backgroundColor: token("colors.successLight") }
                      : {}
                  }
                  onClick={onClapClick}
                >
                  👏
                  {clapClientState.length + boostClientState.length > 0
                    ? "💪"
                    : ""}{" "}
                  {clapClientState.length + boostClientState.length}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  size="small"
                  minWidth={5}
                  style={
                    (isIntentionBoostedByUser ||
                      userClientReactionBoostOffset === 1) &&
                    userClientReactionBoostOffset !== -1
                      ? { backgroundColor: token("colors.successLight") }
                      : {}
                  }
                  onClick={onBoostClick}
                >
                  💪 {boostClientState.length}
                </Button>
              ))}
            {isOwnIntention &&
              !isLatestIntention &&
              !(isCompleteClient || isCompleted) && (
                <Button
                  size="small"
                  isLoading={isCompletingIntention}
                  onClick={async () => {
                    setIsCompletingIntention(true);
                    const result = await postToSetIsCompletedIntention({
                      id: _id.toString(),
                      isCompleted: true,
                    });
                    setIsCompletingIntention(false);
                    if (result.worked) {
                      toast("success", "Intention marked as completed");
                      setIsCompleteClient(true);
                    } else {
                      toast("error", "Failed to mark intention as completed");
                    }
                  }}
                >
                  Complete
                </Button>
              )}
          </Box>
        </IntentionDataContainer>
      </UserIntentionCardBox>
    </>
  );
};

const IntentionDataContainer = styled(Box, {
  base: {
    display: "flex",
    gap: "{spacing.8}",
    justifyContent: "space-between",
    flexDirection: "column",
    "@container user-intention-card (width > 330px)": {
      flexDirection: "row",
    },
  },
});

const UserIntentionCardBox = styled("div", {
  base: {
    container: "user-intention-card / inline-size",
    display: "grid",
    gridTemplateColumns: "45px 1fr",
    gap: "8",
    paddingBottom: "12",

    "&.one-col": {
      gridTemplateColumns: "1fr",
    },
  },
});
