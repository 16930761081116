import { useSidePanel } from "context/side-panel-context";
import { useUserContext } from "context/user-context";
import { usePathname } from "next/navigation";
import { useIsDesktop } from "utils/component-utils";
import {
  CALENDAR_FEATURED_URL,
  DATO_URL_PATHNAME,
  HOME_URL_FOR_ALL,
  LAUNCHPAD_URL,
  ORGANISATION_URL_PREFIX,
  REFERRAL_URL,
  COMMUNITY_URL,
} from "utils/urls";
import {
  getPrimaryNavItems,
  getSecondaryOnlyChildren,
  isActiveItem,
} from "./nav-menu-utils";
import Link from "next/link";
import Image from "next/image";
import { Button, Icon, Text } from "design-system/components";
import { ProductFruitsNewsfeedEmbed } from "features/launchpad";
import { ProfilePopupMenu } from "./profile-popup-menu";
import { slugify } from "utils/string-utils";
import { SecondaryNavWrapper, Selector, TabsContainer } from "./nav-styles";
import { styled } from "styled-system/jsx";
import { useIsPWAInstallable } from "context/is-pwa-installable";
import { CreateSessionButton } from "features/calendar/client";
import { PremiumUpgradeProgressBar } from "features/credits/client";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";
import { FreeTrialRemainingDaysIndicator } from "features/credits/client";
import { css } from "styled-system/css";

export const SecondaryNavMenu: React.FC = () => {
  const pathname = usePathname() ?? "/";
  const {
    organisation,
    userRole,
    isFreeCreditsRole,
    isFreeRole,
    freeTrialRemainingDays = 0,
  } = useUserContext();
  const [isPWAInstallable, onPWAInstallClicked] = useIsPWAInstallable();

  const isDesktop = useIsDesktop(THEME_BREAKPOINTS["tablet"]);
  const { showMyProfileWithSessions } = useSidePanel();
  const showReferralsBtn = pathname === LAUNCHPAD_URL && isDesktop;
  const showInstallAppBtn =
    pathname === LAUNCHPAD_URL && isDesktop && isPWAInstallable;
  const showFreeTrialRemainingDaysIndicator =
    freeTrialRemainingDays > 0 &&
    (pathname === LAUNCHPAD_URL || pathname === COMMUNITY_URL);
  const showUpgradeBtn =
    (isFreeCreditsRole || isFreeRole) &&
    !showFreeTrialRemainingDaysIndicator &&
    (pathname === LAUNCHPAD_URL || pathname === COMMUNITY_URL);

  const showNewsfeed = pathname === LAUNCHPAD_URL && isDesktop;
  const showMysessions = pathname !== LAUNCHPAD_URL && isDesktop;
  const shallowNavigate = pathname.includes(CALENDAR_FEATURED_URL);
  const isDatoPage = pathname.includes(DATO_URL_PATHNAME);
  const showCreateSessionButton =
    pathname.includes(ORGANISATION_URL_PREFIX) && isDesktop;

  return (
    <>
      {[
        ...getPrimaryNavItems(organisation),
        ...getSecondaryOnlyChildren(userRole),
      ].map((item, index) => {
        if (
          isActiveItem(item, isDatoPage ? window.location.pathname : pathname)
        ) {
          return (
            <SecondaryNavWrapper key={index}>
              {item.children && item.children.length > 0 && (
                <TabsContainer>
                  {item.children.map((child, index) => (
                    <Selector
                      shallow={shallowNavigate}
                      href={child.url}
                      key={index}
                      data-cy={`secondary-nav-child-${slugify(child.name)}`}
                      className={`${
                        pathname.match(child.pathPattern) ? "active" : ""
                      } flex align-center gap-8 ${
                        child.hideOnMobile ? "hide-on-mobile" : ""
                      }`}
                    >
                      {child.icon && <Icon icon={child.icon} size={16} />}
                      <Text fontSize="md">{child.name}</Text>
                    </Selector>
                  ))}
                </TabsContainer>
              )}
              {showUpgradeBtn && (
                <PremiumUpgradeProgressBar className="hide-on-mobile flex-1" />
              )}
              {showFreeTrialRemainingDaysIndicator && (
                <div className="show-on-tablet">
                  {" "}
                  <FreeTrialRemainingDaysIndicator />
                </div>
              )}
              <CTAs>
                <Link href={HOME_URL_FOR_ALL} className="hide-on-tablet">
                  <Image
                    src="/assets/shared/flown-logo-black.svg"
                    height={15}
                    width={120}
                    alt="FLOWN Logo"
                  />
                </Link>
                {/* We do not show this element on small mobile devices as there is no space in the top bar */}
                {showFreeTrialRemainingDaysIndicator && (
                  <div
                    className={css({
                      display: "none",
                      "bp-mobile-md": {
                        display: "block",
                      },
                      "bp-tablet": {
                        display: "none",
                      },
                    })}
                  >
                    <FreeTrialRemainingDaysIndicator />
                  </div>
                )}
                {showInstallAppBtn && (
                  <Button
                    className="flex gap-8"
                    variant="secondary"
                    size="small"
                    onClick={onPWAInstallClicked}
                    dataCy="install-pwa-secondary-nav-btn"
                  >
                    <Icon icon="download" color={"black"} size={16} />
                    Install FLOWN app
                  </Button>
                )}
                {showReferralsBtn && (
                  <Button variant="secondary" size="small">
                    <Link
                      className="flex align-center gap-8"
                      href={REFERRAL_URL}
                    >
                      <Icon icon="gift" color={"black"} size={16} />
                      Referrals
                    </Link>
                  </Button>
                )}
                {showNewsfeed && <ProductFruitsNewsfeedEmbed />}
                {showCreateSessionButton && <CreateSessionButton />}
                {showMysessions && (
                  <Button
                    variant="secondary"
                    size="small"
                    className="mar-x-16 flex justify-center gap-12 cursor-pointer pos-relative"
                    onClick={() => showMyProfileWithSessions()}
                  >
                    My sessions
                    <Icon icon="arrow-right" size={14} />
                  </Button>
                )}

                <ProfilePopupMenu />
              </CTAs>
            </SecondaryNavWrapper>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

const CTAs = styled("div", {
  base: {
    alignItems: "center",
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    width: "100%",
    "bp-tablet": {
      justifyContent: "flex-end",
    },
    "bp-desktop-xs": {
      justifyContent: "flex-end",
    },
  },
});
