import { CenteredContentContainer } from "legacy-design-system";
import { ChallengeCTAButton } from "features/user-activities/client";
import { ChallengeCtaButtonBlockFragment } from "generated/graphql-typed";
import { assertFieldsNotNull } from "utils/assertions";
import { isWithinDateRange } from "utils/date";
import { renderer } from "./renderer";

export const ChallengeCTAButtonView = (
  props: ChallengeCtaButtonBlockFragment & { className: string }
) => {
  const { ctaLabel, challenge, href, style } = assertFieldsNotNull(props, [
    "ctaLabel",
    "challenge",
    "href",
    "style",
  ]);
  const { slug, startDate, endDate } = assertFieldsNotNull(challenge, [
    "slug",
    "startDate",
    "endDate",
  ]);
  return isWithinDateRange({
    startDate,
    endDate,
  }) ? (
    props.className === "no-container" ? (
      <ChallengeCTAButton
        label={ctaLabel}
        style={style}
        hasJoinedChallengeText={
          props.ctaJoinedLabel || "You've already joined this challenge"
        }
        slug={slug}
        href={href}
      />
    ) : (
      <CenteredContentContainer className={props.className}>
        <ChallengeCTAButton
          label={ctaLabel}
          style={style}
          hasJoinedChallengeText={
            props.ctaJoinedLabel || "You've already joined this challenge"
          }
          slug={slug}
          href={href}
        />
      </CenteredContentContainer>
    )
  ) : null;
};

export const ChallengeCTAButtonRenderer = renderer({
  typeName: "ChallengeCtaButtonRecord",
  view: ChallengeCTAButtonView,
});
