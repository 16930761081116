import styled from "@emotion/styled";
import { useEffect, useState } from "react";

export type CurveProps = {
  showOnScroll?: boolean;
};

export const Curve = ({ showOnScroll }: CurveProps) => {
  const [show, setShow] = useState(!showOnScroll);
  useEffect(() => {
    const handleScroll = () => {
      setShow(window.scrollY > 50);
    };
    if (showOnScroll) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showOnScroll]);

  return (
    <CurveImage
      src="/assets/shared/curve-downwards-out-grey.svg"
      className={show ? "is-visible" : undefined}
    />
  );
};

const CurveImage = styled.img`
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  z-index: 1;
  margin-bottom: -1.5px;
  pointer-events: none;
  transition: transform 1s;
  transform: scaleY(0) rotateZ(0.01deg); // rotateZ fixes Firefox rendering bug
  transform-origin: bottom center;
  &.is-visible {
    transform: scaleY(1) rotateZ(0.01deg);
    @media only screen and (min-width: 2000px) {
      transform: scaleY(0.7) rotateZ(0.01deg);
    }
  }
`;
