"use client";
import styled from "@emotion/styled";
import { ThemeColor, ThemeFontFamily } from "design-system/emotion.theme";

type HeadingProps = {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: ThemeColor;
  fontFamily?: ThemeFontFamily;
};

export const Heading = styled.div<HeadingProps>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : "inherit")};
  font-family: ${({ theme, fontFamily }) =>
    fontFamily ? theme.fontFamilies[fontFamily] : theme.fontFamilies.biennale};
  font-weight: 700;
`;
