"use client";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import * as Dialog from "@radix-ui/react-dialog";
import { Icon } from "design-system/components";
import { useCallback, useState } from "react";

export type ModalProps = React.ComponentProps<typeof Dialog.Root> & {
  allowContentOverflow?: boolean;
  children: React.ReactNode;
  /**
   * @param {string | ReactNode} title Idealy an h2 or h3 element
   */
  title: string | React.ReactNode;
  open?: boolean;
  onClose: () => void;
  background?: string;
  showCloseBtn?: boolean;
  /**
   * @param {boolean} showCloseBtn Show cross icon in the top right corner; Default true
   */
  closeBtnIconColor?: string;
  /**
   * @param {boolean} desktopPopup Behave like a popup on desktop but full screen on mobile
   */
  desktopPopup?: boolean;
  fullPopup?: boolean;
  preventDismissOnOverlayClick?: boolean;
  footer?: React.ReactNode;
  /**
   * Make modifications to the content e.g. limiting the width of the content
   */
  contentStyle?: React.CSSProperties;
};

const SWIPE_DISTANCE = 150;

export const Modal = ({
  allowContentOverflow = false,
  children,
  open,
  title,
  onClose,
  showCloseBtn = true,
  background = "white",
  closeBtnIconColor = "black",
  desktopPopup = false,
  fullPopup = false,
  preventDismissOnOverlayClick = false,
  footer,
  contentStyle,
}: ModalProps) => {
  const [touchStart, setTouchStart] = useState(0);

  const onOpenChange = useCallback(
    (openState: boolean) => {
      if (!openState) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Overlay />
        <Content
          onTouchStart={(e) => setTouchStart(e.targetTouches[0].clientY)}
          onTouchEnd={(e) => {
            const touchEnd = e.changedTouches[0].clientY;
            const hasSwipedDown = touchEnd - touchStart > SWIPE_DISTANCE;
            if (hasSwipedDown) {
              onOpenChange(false);
            }
          }}
          onPointerDownOutside={(event) => {
            if (preventDismissOnOverlayClick) {
              event.preventDefault();
            }
          }}
          onInteractOutside={(event) => {
            if (preventDismissOnOverlayClick) {
              event.preventDefault();
            }
          }}
          className={[fullPopup ? "popup" : desktopPopup ? "desktop-popup" : ""]
            .filter(Boolean)
            .join(" ")}
          background={background}
          style={contentStyle}
        >
          {title}
          <ChildrenContainer
            data-overflow={allowContentOverflow ? "allow" : "auto"}
          >
            {children}
          </ChildrenContainer>
          {showCloseBtn && (
            <Dialog.Close asChild>
              <IconButton
                data-cy="close-modal-btn"
                style={{ color: closeBtnIconColor }}
                aria-label="Close"
              >
                <Icon icon="close" />
              </IconButton>
            </Dialog.Close>
          )}
          {footer && <Footer>{footer}</Footer>}
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const Overlay = styled(Dialog.Overlay)`
  display: grid;
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  place-items: center;
  overflow-y: auto;
  z-index: 101;
`;

const ModalTranslateIn = keyframes`
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
`;

const ModalTranslateOut = keyframes`
  0% {
    transform: translateY(0vh);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
`;

type ContentProps = React.ComponentProps<typeof Dialog.Content> & {
  background: string;
};

const PopupModalStyles = css`
  top: 0;
  margin: auto;
  height: fit-content;
  max-width: 700px;
  width: 90vw;
  max-height: 90vh;
`;

const Content = styled(Dialog.Content)<ContentProps>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ background }) => background};
  background-size: cover;
  border-radius: 24px 24px 0 0;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: ${ModalTranslateIn} 0.5s cubic-bezier(0.32, 0.72, 0, 1);
  &:focus {
    outline: none;
  }
  &[data-state="closed"] {
    animation: ${ModalTranslateOut} 0.5s cubic-bezier(0.32, 0.72, 0, 1);
  }
  z-index: 201;

  &.popup {
    ${PopupModalStyles}
    border-radius: ${({ theme }) => theme.radii[24]};
  }
  &.desktop-popup {
    // This is needed for when modals have context bigger than the full viewport
    max-height: 95vh;
    ${({ theme }) => theme.media["bp-tablet"]} {
      ${PopupModalStyles}
      border-radius: ${({ theme }) => theme.radii[24]};
    }
  }
`;

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
  ${({ theme }) => theme.media["bp-tablet"]} {
    height: auto;
    max-height: calc(90vh - 80px);
  }

  &[data-overflow="allow"] {
    overflow-y: visible;
  }
`;

const IconButton = styled.button`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.blueGrey100};
  }
  &:focus {
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.colors.blueGrey100}`};
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
`;
