import styled from "@emotion/styled";
import { renderer } from "./renderer";
import { ModalContentBlockFragment } from "generated/graphql-typed";

const ModalContentView = ({
  headerImage,
  headerText,
  contentText,
}: ModalContentBlockFragment & { className: string }) => {
  return (
    <ContentWrapper>
      {headerImage && (
        <img src={headerImage.url} alt={headerImage.alt || "Modal header"} />
      )}
      <Title>{headerText}</Title>
      <Text>{contentText}</Text>
    </ContentWrapper>
  );
};

export const ModalContentRenderer = renderer({
  typeName: "ModalContentRecord",
  view: ModalContentView,
});

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
`;
const Text = styled.p``;
