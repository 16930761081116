"use client";
import { getAgendaDuration } from "features/sessions/shared";
import { Text, Tooltip, Row } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { useCallback, useEffect, useState } from "react";
import { getEventHasStarted } from "utils/event-utils";
import { Agenda } from "../../../shared/sessions-types";
import { css } from "styled-system/css";
import { styled } from "styled-system/jsx";
import { getFormattedMinutesAndSeconds } from "utils/time";
import { useAgendaProgress } from "../../contexts/agenda-progress-context-provider";

export type FlownogramProps = DSComponentPropsProvider & {
  agenda: Agenda;
  height?: number;
  showTimeInfo?: boolean;
  startTime?: string;
  hideTooltips?: boolean;
};

const SESSION_ENDING_SOON_THRESHOLD = 5;

export const Flownogram = ({
  agenda,
  className,
  height,
  showTimeInfo,
  startTime,
  hideTooltips = false,
  ...props
}: FlownogramProps) => {
  const totalDuration = getAgendaDuration(agenda);
  const hasEventStarted = startTime ? getEventHasStarted(startTime) : false;

  const [isLastItemTooltipOpen, setIsLastItemTooltipOpen] = useState(false);
  const [wasLastItemTooltipOpen, setWasLastItemTooltipOpen] = useState(false);

  const {
    currentActivity,
    currentActivityIndex,
    currentProgress,
    elapsedMinutes,
  } = useAgendaProgress();

  useEffect(() => {
    if (wasLastItemTooltipOpen) return;
    if (
      currentActivityIndex === agenda.length - 1 &&
      totalDuration - elapsedMinutes <= SESSION_ENDING_SOON_THRESHOLD
    ) {
      setWasLastItemTooltipOpen(true);
      setIsLastItemTooltipOpen(true);
      setTimeout(() => {
        setIsLastItemTooltipOpen(false);
      }, 5000);
    }
  }, [
    agenda.length,
    currentActivityIndex,
    elapsedMinutes,
    totalDuration,
    wasLastItemTooltipOpen,
  ]);

  const currentActivityBackgroundGradient = `linear-gradient(to right, ${currentActivity?.category.colorHex}93 ${currentProgress}%, ${currentActivity?.category.colorHex} ${currentProgress}%)`;

  const FlownogramItem = useCallback(
    (item: Agenda[number], index: number) => {
      const isFirstItem = index === 0;
      const isLastItem = index === agenda.length - 1;
      const isCurrentItem = index === currentActivityIndex;
      return (
        <div
          key={`${startTime}-${item.displayName}-${index}`}
          style={{
            borderRadius:
              isFirstItem && isLastItem
                ? "15px"
                : isFirstItem
                ? "15px 0 0 15px"
                : isLastItem
                ? "0 15px 15px 0"
                : "initial",
            background: isCurrentItem
              ? currentActivityBackgroundGradient
              : item.category.colorHex,
            borderRight: isLastItem ? "none" : "1px solid #fff",
            cursor: "default",
            width: `${(item.duration / totalDuration) * 100}%`,
            transition: "width 0.3s ease",
            opacity:
              hasEventStarted && elapsedMinutes >= 0
                ? isCurrentItem
                  ? 1
                  : 0.5
                : 1,
          }}
        />
      );
    },
    [
      currentActivityBackgroundGradient,
      totalDuration,
      agenda,
      currentActivityIndex,
      hasEventStarted,
      elapsedMinutes,
      startTime,
    ]
  );
  return (
    <div
      className={`${flownogramOutterWrapperStyles} ${className} ${
        showTimeInfo ? "showing-time-info" : ""
      }`}
      {...props}
    >
      <FlownogramWrapper style={height ? { height } : {}}>
        {agenda.map((item, index) => {
          const isLastItem = index === agenda.length - 1;
          const isCurrentItem = index === currentActivityIndex;

          return hideTooltips ? (
            FlownogramItem(item, index)
          ) : isLastItemTooltipOpen && isLastItem && isCurrentItem ? (
            <Tooltip
              key={`${item.displayName}-${index}`}
              open={isLastItemTooltipOpen}
              onOpenChange={(open) => setIsLastItemTooltipOpen(open)}
              sideOffset={12}
              triggerAsChild
              triggerElement={FlownogramItem(item, index)}
            >
              <Text fontWeight={500} fontSize="sm">
                Session ending soon
              </Text>
            </Tooltip>
          ) : (
            <Tooltip
              key={`${item.displayName}-${index}`}
              sideOffset={12}
              triggerElement={FlownogramItem(item, index)}
              triggerAsChild
            >
              <Text fontWeight={500} fontSize="sm">
                {item.displayName} [{item.duration} min]
              </Text>
            </Tooltip>
          );
        })}
        {hasEventStarted && elapsedMinutes < totalDuration && (
          <Text
            style={{
              // Using px values directly here bc it's not a regular text element, but an indicator
              fontSize: "20px",
              // The -2px is an offset to recenter the indicator
              left: `calc(${(elapsedMinutes / totalDuration) * 100}% - 2px)`,
              position: "absolute",
              top: "-10px",
              zIndex: 100,
            }}
          >
            |
          </Text>
        )}
      </FlownogramWrapper>
      {showTimeInfo && (
        <Row className="justify-between w-full time-info">
          <Text fontSize="xs" color="white" className={css({ width: "35px" })}>
            {getFormattedMinutesAndSeconds(elapsedMinutes * 60)}
          </Text>
          <Text
            fontSize="xs"
            color="white"
            className={css({ textAlign: "right", width: "35px" })}
          >
            {getFormattedMinutesAndSeconds(totalDuration * 60)}
          </Text>
        </Row>
      )}
    </div>
  );
};

const flownogramOutterWrapperStyles = css({
  alignItems: "center",
  gridGap: "32",
  width: "100%",

  "&.showing-time-info": {
    paddingTop: "12",
  },

  "& .time-info": {
    opacity: 0,
    transition: "opacity 0.2s ease",
  },
  _hover: {
    "& .time-info": {
      opacity: 1,
    },
  },
});

const FlownogramWrapper = styled("div", {
  base: {
    borderRadius: "{radii.12}",
    display: "flex",
    height: "10px",
    position: "relative",
    width: "100%",
  },
});
