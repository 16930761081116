import { Icon } from "design-system/components";
import { styled } from "styled-system/jsx";

export const IntercomChatButton = () => (
  <IntercomChatButtonWrapper
    id="intercom-launcher"
    aria-label="Open Intercom Messenger"
    aria-live="polite"
    role="button"
  >
    <IntercomChatButtonIcon>
      <Icon size={20} icon="intercom" color="white" />
    </IntercomChatButtonIcon>
  </IntercomChatButtonWrapper>
);

const IntercomChatButtonWrapper = styled("div", {
  base: {
    position: "fixed",
    bottom: "6rem",
    right: "2rem",
    zIndex: "200",
    width: "2.5rem",
    height: "2.5rem",
    backgroundColor: "#000",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#000",
    },
    "bp-tablet": {
      bottom: "2rem",
    },
  },
});

const IntercomChatButtonIcon = styled("div", {
  base: {
    width: "1.2rem",
    height: "1.2rem",
    borderRadius: "50%",
  },
});
