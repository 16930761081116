import { renderer } from "./renderer";
import { CarouselBlockFragment } from "generated/graphql-typed";
import { ModularContent } from "./modular-content";
import { LinkCard } from "design-system/molecules/cards/link-card";
import { Carousel } from "design-system/molecules/carousel";
import {
  ColumnCard,
  ColumnCardProps,
} from "design-system/molecules/cards/column-card";
import styled from "@emotion/styled";
import { Box, Button, Heading, Row, Text } from "design-system/components";
import { shuffleArray } from "utils/generic-utils";
import { useEffect, useState } from "react";
import Link from "next/link";

const cardSizeDict = {
  small: 260,
  medium: 320,
  large: 500,
};

type CardSizeKey = keyof typeof cardSizeDict;

export type CarouselViewProps = CarouselBlockFragment & { className: string };

export const CarouselView = ({
  autoscrollDelay,
  carouselItemList: carouselItemsServer,
  cardSize = "small",
  cardHeight = "fit-content",
  cardLayout = "fill-available/auto",
  centerItems = false,
  fontColor,
  title,
  subtitle,
  seeAllUrl,
  id,
  randomiseItems = false,
  showContentOnHover,
  showNavigation,
  showPagination,
  loop,
  slidesPerView,
}: CarouselViewProps) => {
  const [carouselItems, setCarouselItems] = useState(carouselItemsServer);

  useEffect(() => {
    if (randomiseItems) {
      setCarouselItems(shuffleArray(carouselItemsServer));
    }
  }, [carouselItemsServer, randomiseItems]);

  if (!carouselItemsServer || carouselItemsServer.length === 0) return null;

  return (
    <CarouselViewWrapper style={centerItems ? { margin: "auto" } : {}}>
      {(title || subtitle) && (
        <Box className="mar-bot-16">
          <Row className="justify-between">
            {title && (
              <Heading as="h3" className="mar-bot-8">
                {title}
              </Heading>
            )}
            {seeAllUrl && (
              <Link href={seeAllUrl}>
                <Button variant="ghost" size="small" href={seeAllUrl}>
                  See all
                </Button>
              </Link>
            )}
          </Row>
          {(subtitle || seeAllUrl) && (
            <Box>
              {subtitle && (
                <Text as="h5" color="grey300">
                  {subtitle}
                </Text>
              )}
            </Box>
          )}
        </Box>
      )}
      <Carousel
        id={id}
        autoplay={
          autoscrollDelay
            ? { delay: autoscrollDelay, disableOnInteraction: false }
            : false
        }
        cardSize={cardSizeDict[cardSize as CardSizeKey]}
        navigationArrowsColor={fontColor?.hex}
        showNavigation={showNavigation}
        spaceBetween={16}
        slidesPerView={slidesPerView || "auto"}
        loop={loop}
        centerInsufficientSlides={true}
        pagination={showPagination ? { type: "bullets" } : false}
      >
        {carouselItems.map((item, index) =>
          item.__typename === "CarouselItemRecord" ? (
            <LinkCard
              key={`${item?.content?.value}-${index}`}
              url={item.url || ""}
              imgSrc={item.image?.url || ""}
              category="learn"
              showContentOnHover={showContentOnHover}
            >
              <ModularContent
                paragraphSize={item?.paragraphSize}
                data={item?.content}
              />
            </LinkCard>
          ) : item.__typename === "ColumnCardRecord" ? (
            <ColumnCard
              author={item.author || ""}
              caption={item.caption || ""}
              categorySlug={item.categorySlug || ""}
              title={item.title || ""}
              description={item.description || ""}
              mediaUrl={item.image?.url || ""}
              hoverMediaUrl={item.hoverImage?.url || ""}
              cardWidth={(cardSize as ColumnCardProps["cardWidth"]) || "small"}
              cardHeight={
                (cardHeight as ColumnCardProps["cardHeight"]) || "small"
              }
              fontColor={fontColor?.hex}
              layout={cardLayout as ColumnCardProps["layout"]}
              linkUrl={item.linkUrl || ""}
              key={(item.title || "") + index}
            />
          ) : null
        )}
      </Carousel>
    </CarouselViewWrapper>
  );
};

export const CarouselViewWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing[16]}`,
  maxWidth: "fit-content",
  [theme.media["bp-desktop-sm"]]: {
    padding: `${theme.spacing[16]} ${theme.spacing[48]}`,
  },
}));

export const CarouselRenderer = renderer({
  typeName: "CarouselRecord",
  view: CarouselView,
});
