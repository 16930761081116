import {
  contentContainerStyles,
  maxContentWidthPixels,
} from "legacy-design-system";
import { textMediumMaxWidth } from "./text";
import { CSS_CLASSES } from "design-system/styles/global-styles";

export const modularContentStyle = `
  &.has-content-container {
    > p,
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > ol,
    > ul,
    > blockquote {
      ${contentContainerStyles};
      // we can't set max-width on these elements because the content container styles use it,
      // so
      padding-right: min(
        calc(100vw - ${textMediumMaxWidth}px),
        calc(${maxContentWidthPixels - textMediumMaxWidth}px)
      );
    }
  }

  > p,
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > ol,
  > ul,
  > blockquote,
  > hr {
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 800px;

    a {
      text-decoration: underline;
    }
  }

  > p {
    font-size: var(--paragraph-size, inherit);
  }

  white-space: pre-wrap;

  > ul {
    padding-left: 1.375rem;
    list-style: circle;
  }

  > ol {
    padding-left: 30px;
    list-style: decimal;
  }

  > ul p,
  > ol p {
    margin-top: 0;
    margin-bottom: 0;
  }

  > ul li,
  > ol li {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  > hr {
    border: none;
    border-bottom: solid 1px #e5e1e6;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  > h1 {
    margin-top: 50px;
    font-size: clamp(38px, 5vw, 60px);
  }

  > h2 {
    margin-top: 35px;
    font-size: clamp(30px, 4vw, 36px);
  }
  
  > h3 {
    margin-top: 25px;
    font-size: clamp(22px, 3vw, 24px);
  }
    
  > h3 + h2 {
    margin-top: 10px;
  }

  > h4 {
    margin-top: 25px;
    font-size: clamp(22px, 3vw, 24px);
    font-weight: inherit;
  }

  > h5 {
    margin-top: 25px;
    font-size: inherit;
    text-decoration: underline;
  }

  > h6 {
    margin-top: 25px;
    font-size: inherit;
    font-style: italic;
  }

  > div.first-element {
    margin-top: 0;
  }

  > div.last-element {
    margin-bottom: 0;
  }

  > div.${CSS_CLASSES.SMALL_SPACING} {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:not(.has-content-container) {
    > .first-element {
      margin-top: 0;
    }

    > .last-element {
      margin-bottom: 0;
    }

    > div.${CSS_CLASSES.NO_MARGIN} {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    > div.${CSS_CLASSES.SMALL_SPACING} {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    > div.${CSS_CLASSES.MEDIUM_SPACING} {
      margin-top: 40px;
      margin-bottom: 40px;
    }

  }

  > div:not(.first-element) {
    margin-top: 30px;
  }

  > div:not(.last-element) {
    margin-bottom: 30px;
  }

  @media (min-width: 500px) {
    > div:not(.first-element) {
      margin-top: 60px;
    }

    > div:not(.last-element) {
      margin-bottom: 60px;
    }
    
    > div.${CSS_CLASSES.NO_MARGIN} {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    > div.${CSS_CLASSES.SMALL_SPACING} {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    > div.${CSS_CLASSES.MEDIUM_SPACING} {
      margin-top: 40px;
      margin-bottom: 40px;
    }


    > div.${CSS_CLASSES.DISPLAY_ROW} {
      display: inline-block;
      width: auto;
    }
    
    > div.has-content-container.${CSS_CLASSES.DISPLAY_ROW} {
      display: block;
      text-align: center;
    }
    &.${CSS_CLASSES.DISPLAY_ROW} {
      display: block;
      width: 100%;
      text-align: center;
    }

  }

  > hr {
    ${contentContainerStyles};
    border: none;
    &:before {
      content: "";
      display: block;
      height: 1px;
      background-color: #e5e1e6;
    }
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
    > p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
