import { CalendarBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { assertFieldsNotNull } from "utils/assertions";
import { getTagsSlugArray } from "utils/tag-utils";
import { EventFilters } from "features/calendar/shared";
import { CalendarWrapper } from "features/calendar/client";
import { styled } from "styled-system/jsx";

const CalendarView = (props: CalendarBlockFragment & { className: string }) => {
  const {
    facilitator,
    showEventFilters,
    id,
    numberOfDays,
    sessionCategory,
    tagFilters,
    isMultiView,
    startDate,
    showOnlyCommunitySessions,
  } = props;
  const getInitialFiltersFromBlock = () => {
    const filters: Partial<EventFilters> = {
      categories: [],
      tags: [],
      facilitatorName: "",
    };
    if (sessionCategory) {
      const categories = sessionCategory.map((category) => {
        const { slug } = assertFieldsNotNull(category, ["slug"]);
        return slug;
      });
      filters.categories = [...categories];
    }
    if (tagFilters) {
      const tags = getTagsSlugArray(tagFilters);
      filters.tags = [...tags];
    }
    if (facilitator) {
      const { name } = assertFieldsNotNull(facilitator, ["name"]);
      filters.facilitatorName = name;
    }
    return filters;
  };

  const calendarSliceArgs = {
    startDate: startDate || undefined, // We need this as the start date was being passed as ''
    numberOfDays,
    filter: getInitialFiltersFromBlock(),
  };

  const isListView = !isMultiView;

  return (
    <CalendarViewContainer className={isListView ? "list-view" : "multi-view"}>
      <CalendarWrapper
        id={id}
        calendarSliceArgs={calendarSliceArgs}
        showEventFilters={showEventFilters}
        daysToShow={numberOfDays}
        analyticsContext="calendar"
        events={[]}
        forceView={isMultiView ? undefined : "list"}
        showOnlyCommunitySessions={showOnlyCommunitySessions}
        previewInSidePanel={!isMultiView}
      />
    </CalendarViewContainer>
  );
};

const CalendarViewContainer = styled("section", {
  base: {
    maxWidth: "1400px",
    marginLeft: "{spacing.16}",
    marginRight: "{spacing.16}",
    "bp-desktop-xs": {
      margin: `{spacing.16} auto`,
    },
    "&.list-view": {
      maxWidth: "420px",
    },
  },
});

export const CalendarRenderer = renderer({
  typeName: "CalendarRecord",
  view: CalendarView,
});
