import { useSidePanel } from "context/side-panel-context";
import { CalendarWrapper } from "features/calendar/client";
import { Box, Button, Icon } from "design-system/components";
import React from "react";
import { FlownEvents } from "shared/event.types";
import { useGetHostedSessions } from "../hooks/useGetHostedSessions";
import { SkeletonLoadingSessionCard } from "design-system/molecules/cards/session-cards/skeleton-loading-session-card";
import { getUserDisplayName } from "features/profile/shared";

type HostedSessionsWrapperProps = {
  externalId: string;
  hostedSessionsFromServer?: FlownEvents;
  isHostFromServer?: boolean;
  maxSessionsInView?: number;
};

export const MAX_HOSTED_SESSIONS_IN_VIEW = 5;

export const HostedSessionsWrapper: React.FC<HostedSessionsWrapperProps> = ({
  externalId,
  hostedSessionsFromServer,
  isHostFromServer,
  maxSessionsInView,
}) => {
  const [hostedSessionsState] = useGetHostedSessions({
    hostedSessionsFromServer,
    isHostFromServer,
    externalId,
  });
  const { showMyProfileWithSessions, showHostedSessions } = useSidePanel();
  const onEventBooked = async () => {
    showMyProfileWithSessions();
  };
  return hostedSessionsState.status === "loading" ? (
    <Box className="flex-col gap-8 mar-y-32">
      <SkeletonLoadingSessionCard />
      <SkeletonLoadingSessionCard />
      <SkeletonLoadingSessionCard />
    </Box>
  ) : hostedSessionsState.status === "resolved" &&
    hostedSessionsState.hostedSessions ? (
    <Box className="flex-col gap-8">
      <CalendarWrapper
        id="hosted-sessions-wrapper"
        events={hostedSessionsState.hostedSessions.slice(0, maxSessionsInView)}
        calendarSliceArgs={{}}
        forceView="list"
        showEventFilters={false}
        analyticsContext={"hosted-sessions-wrapper"}
        onEventBooked={onEventBooked}
        previewInSidePanel
      />
      {maxSessionsInView !== undefined &&
      hostedSessionsState.hostedSessions.length > maxSessionsInView ? (
        <Box className="flex-col gap-8">
          <Button
            size="small"
            variant="secondary"
            dataCy="see-all-hosted-sessions-btn"
            onClick={() => {
              const hostData =
                hostedSessionsState.hostedSessions[0].populatedHost;
              if (hostData) {
                const displayName = getUserDisplayName(hostData);
                showHostedSessions(hostData.externalId, displayName);
              }
            }}
            className="flex align-center gap-8"
          >
            See all hosted sessions <Icon icon="arrow-right" size={14} />
          </Button>
        </Box>
      ) : null}
    </Box>
  ) : null;
};
