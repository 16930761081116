import { useUserContext } from "context/user-context";
import { useState } from "react";
import { UserProfileFromActivityNoId } from "shared/user.types";

export const useFollowUnfollowList = ({
  initialList,
}: {
  initialList: UserProfileFromActivityNoId[];
}) => {
  const { onFollow: onFollowUser, onUnfollow: onUnfollowUser } =
    useUserContext();
  const [followList, setFollowList] =
    useState<UserProfileFromActivityNoId[]>(initialList);

  const onFollow = async (user: UserProfileFromActivityNoId) => {
    setFollowList((prev) =>
      prev.map((suggestion) => {
        if (suggestion.externalId === user.externalId) {
          return { ...suggestion, isFollowed: true };
        }
        return suggestion;
      })
    );
    return await onFollowUser(user);
  };

  const onUnfollow = async (user: UserProfileFromActivityNoId) => {
    setFollowList((prev) =>
      prev.map((suggestion) => {
        if (suggestion.externalId === user.externalId) {
          return { ...suggestion, isFollowed: false };
        }
        return suggestion;
      })
    );
    return await onUnfollowUser(user);
  };

  return { followList, onFollow, onUnfollow };
};
