import Head from "next/head";

export type MetaHeaderProps = {
  title: string;
  imageUrlParam?: string;
  description?: string | null;
  keywords?: string | null;
  author?: string | null;
  canonicalUrl?: string | null;
};

export const MetaHeader: React.FC<
  { children?: React.ReactNode } & MetaHeaderProps
> = ({
  children,
  title = "FLOWN",
  description = "Get more done and feel good with FLOWN, the ultimate virtual co-working platform. Body doubling sessions to help you improve focus and beat procrastination.",
  imageUrlParam = "/assets/shared/main-preview-image.png",
  keywords,
  author,
  canonicalUrl,
}) => {
  const keywordsSanitized = keywords?.replace(/\s/g, ", ");
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:site_name" content="FLOWN" />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="apple-mobile-web-app-title" content={title} />

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}

      {imageUrlParam && (
        <>
          (<meta property="og:image" content={imageUrlParam} />
          <meta property="og:image:url" content={imageUrlParam} />
          <meta property="og:image:secure_url" content={imageUrlParam} />
          <meta name="twitter:image" content={imageUrlParam} />
          <meta name="twitter:card" content={`summary_large_image`} />){" "}
        </>
      )}
      {keywords && (
        <>
          <meta name="keywords" content={keywordsSanitized} />
          <meta property="og:keywords" content={keywordsSanitized} />
          <meta name="twitter:keywords" content={keywordsSanitized} />
        </>
      )}
      {author && (
        <>
          <meta name="author" content={author} />
          <meta property="og:author" content={author} />
          <meta name="twitter:author" content={author} />
        </>
      )}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {children}
    </Head>
  );
};
