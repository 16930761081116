import React from "react";
import { Box, Text, SwitchToggle } from "design-system/components";

interface CancelledSessionsToggleProps {
  showCancelledSessions: boolean;
  id: string;
  className?: string;
  onToggleCancelledSessions: (checked: boolean) => void;
}

export const CancelledSessionsToggle: React.FC<
  CancelledSessionsToggleProps
> = ({
  showCancelledSessions,
  id,
  onToggleCancelledSessions,
  className = "",
}) => {
  return (
    <Box className={`flex gap-8 align-center ${className}`}>
      <SwitchToggle
        id={id}
        dataCy={id}
        isChecked={showCancelledSessions}
        onToggle={(event) => onToggleCancelledSessions(event.target.checked)}
      />
      <Text fontSize="sm">Show cancelled sessions</Text>
    </Box>
  );
};
