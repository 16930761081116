import { CalendarEventProps } from "./event-types";

export type CalendarPageProps = {
  calendarEventProps: CalendarEventProps;
  hideCommunityEvents: boolean;
};

export type CalendarAnalyticsContext =
  | "calendar"
  | "springboard"
  | "community-springboard-host-calendar"
  | "launchpad-free"
  | "my-day"
  | "community"
  | "hosted-sessions-profile"
  | "hosted-sessions-public-profile"
  | "hosted-sessions-wrapper"
  | "booked-sessions-tabs"
  | "side-panel"
  | "organisation";

export const CALENDAR_MAX_WIDTH = "800px";
export const PROD_CALENDAR_ID =
  "c_5u03hrv6jh68agl6o6sp1flduo@group.calendar.google.com";

export const TEST_CALENDAR_ID =
  "c_ju5db3vuiel5k5kj9opac2in90@group.calendar.google.com";
