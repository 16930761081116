import { Icon, Text, Tooltip } from "design-system/components";
import { analytics, catchAnalyticsError } from "features/analytics";
import { useNavigate } from "utils/component-utils";
import { CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL } from "utils/urls";

export const FullSessionCloneTooltip = ({ event }) => {
  const navigate = useNavigate();

  return (
    <Tooltip
      triggerElement={
        <span className="flex">
          <Icon icon="info" />
        </span>
      }
    >
      <Text
        as={"button"}
        className="flex align-center gap-4 decor-underline cursor-pointer"
        fontSize="sm"
        fontWeight={600}
        onClick={() => {
          navigate(`${CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL}`);
          analytics
            .track("Clone Fully Booked Session Click", {
              eventId: event.id,
            })
            .catch(catchAnalyticsError);
        }}
      >
        Host a session just like this one <Icon icon="arrow-right" size={16} />
      </Text>
    </Tooltip>
  );
};
