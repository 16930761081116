"use client";
import styled from "@emotion/styled";
import {
  ThemeColor,
  ThemeFontFamily,
  ThemeFontSize,
} from "design-system/emotion.theme";
import Link from "next/link";
import { ElementType, HTMLAttributeAnchorTarget } from "react";
import { ReactNode } from "react";
import { DSComponentPropsProvider } from "../design-system.types";

type TextProps = DSComponentPropsProvider & {
  as?: ElementType<any>;
  charsPerLine?: number;
  color?: ThemeColor;
  children: ReactNode;
  em?: boolean;
  fontFamily?: ThemeFontFamily;
  fontSize?: ThemeFontSize | "inherit";
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | "inherit";
  inheritStyle?: boolean;
  role?: string;
};

export const Text = ({
  as,
  charsPerLine,
  color,
  children,
  className,
  em = false,
  fontFamily,
  fontSize = "md",
  fontWeight = "inherit",
  role,
  style,
  dataCy,
  ...props
}: TextProps) => (
  <StyledText
    className={className ? className : ""}
    as={as}
    style={style}
    role={role}
    charsPerLine={charsPerLine}
    color={color}
    em={em}
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontWeight={fontWeight}
    data-cy={dataCy}
    {...props}
  >
    {children}
  </StyledText>
);

export const TextFlex = ({ className, ...props }: TextProps) => (
  <Text className={`flex align-center gap-4 ${className}`} {...props} />
);

export const TextButton = ({
  className,
  ...props
}: Omit<TextProps, "as"> & { disabled?: boolean }) => (
  <Text
    as="button"
    fontWeight={700}
    className={`flex gap-4 align-center decor-underline ${className}`}
    {...props}
  />
);

const StyledText = styled.p<TextProps>(
  ({
    theme,
    charsPerLine,
    color,
    fontFamily,
    fontSize = "md",
    fontWeight,
    em,
  }) => ({
    color: color ? theme.colors[color] : "inherit",
    fontFamily: fontFamily ? theme.fontFamilies[fontFamily] : "inherit",
    fontSize:
      fontSize === "inherit"
        ? "inherit"
        : em
        ? theme.fontSizesEm[fontSize]
        : theme.fontSizes[fontSize],
    fontWeight,
    maxWidth: charsPerLine ? `${charsPerLine}ch` : "unset",
    "&:disabled": {
      color: theme.colors.grey200,
      cursor: "default",
    },
  })
);

export const TextLink = ({
  className,
  href,
  style,
  target,
  ...props
}: TextProps & { href: string; target?: HTMLAttributeAnchorTarget }) => (
  <Link className={className || ""} href={href} target={target} style={style}>
    <Text
      className={`decor-underline ${className || ""}`}
      fontWeight={700}
      {...props}
    />
  </Link>
);

export const TextSuccess = (props: TextProps) => (
  <Text color="success" fontSize="sm" fontWeight={700} {...props} />
);

export const TextError = (props: TextProps) => (
  <Text color="error" fontSize="sm" role="alert" {...props} />
);

export const TextWarning = (props: TextProps) => (
  <Text color="warning" fontSize="sm" role="alert" {...props} />
);

export const TextItalics = (props: TextProps) => (
  <Text {...props} className={`${props.className} text-italic`} />
);
