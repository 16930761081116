"use client";
import styled from "@emotion/styled";
import { Spinner } from "design-system/components/spinner";
import { Box, Tooltip } from "design-system/components";
import { useTheme } from "@emotion/react";
import { SessionCardCTA } from "./ctas-shared";
import { Icon } from "design-system/components";
import { useState } from "react";

type EmailInviteButtonProps = {
  className?: string;
  spinnerColor?: string;
  showLabel?: boolean;
  onInviteSent: () => Promise<void>;
  onInviteCancelled: () => Promise<void>;
  inviteSent?: boolean;
};

export const EmailInviteButton: React.FC<EmailInviteButtonProps> = ({
  spinnerColor,
  inviteSent = false,
  onInviteSent,
  onInviteCancelled,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const buttonClicked = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    if (inviteSent) {
      await onInviteCancelled();
    } else {
      await onInviteSent();
    }
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip
        triggerElement={
          <EmailInviteCTA
            className={`${inviteSent ? "sent" : ""} ${
              isLoading ? "loading" : ""
            } flex gap-4`}
            onClick={(ev) => buttonClicked(ev)}
            title="Book this session"
          >
            {isLoading ? (
              <Box className="flex-centralized h-full pos-relative">
                <Spinner color={spinnerColor} size={12} />
              </Box>
            ) : inviteSent ? (
              <>
                <Icon
                  className="added-to-calendar"
                  color={theme.colors.success}
                  icon="added-to-calendar"
                  size={20}
                />
                <Icon
                  className="remove-from-calendar cursor-pointer"
                  icon="remove-from-calendar"
                  size={20}
                />
              </>
            ) : (
              <Icon icon="add-to-calendar" size={20} />
            )}
          </EmailInviteCTA>
        }
      >
        {inviteSent ? "Cancel booking" : "Book session"}
      </Tooltip>
    </>
  );
};

const EmailInviteCTA = styled(SessionCardCTA)(({ theme }) => ({
  "&.sent": {
    backgroundColor: theme.colors.successLight,
    ".remove-from-calendar": {
      display: "none",
    },

    "&:hover": {
      cursor: "pointer",
      ".added-to-calendar": {
        display: "none",
      },
      ".remove-from-calendar": {
        color: theme.colors.focusDark,
        display: "block",
      },
    },
    "&.loading": {
      backgroundColor: theme.colors.grey100,
    },
  },
}));
