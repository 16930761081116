import "swiper/css";
import { Children } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import {
  Swiper as SwiperCarousel,
  SwiperProps,
  SwiperSlide,
} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Box } from "../../components";
import { Icon } from "design-system/components";
import styled from "@emotion/styled";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";

type CarouselProps = SwiperProps & {
  /**
   * @property {string} id - to concatenate with the navigation arrow classes
   * important when there's more than one carousel on the page
   */
  id: string;
  /**
   * @property {boolean} hasExternalNavigation - set to true to hook up
   * navigation outside of the carousel component. Should be used with
   * showNavigation = false
   */
  hasExternalNavigation?: boolean;
  cardSize?: number;
  navigationArrowsColor?: string;
  showNavigation?: boolean;
};

export const Carousel: React.FC<CarouselProps> = ({
  id = "1",
  cardSize,
  children,
  navigationArrowsColor = "black",
  showNavigation = false,
  pagination = {
    type: "bullets",
  },
  hasExternalNavigation,
  style,
  grabCursor = true,
  breakpoints = {
    [THEME_BREAKPOINTS.tablet]: {
      centeredSlides: false,
    },
  },
  centeredSlides = true,
  slidesPerView = "auto",
  loop = false,
  ...props
}) => {
  return (
    <Box
      className={`pos-relative ${
        showNavigation ? "pad-x-28 overflow-hidden" : ""
      }`}
      style={{ ...style }}
    >
      {showNavigation && (
        <NavigationButton
          className={`inset-y-0 align-center mar-auto hide-on-mobile navigation-prev navigation-prev-${id}`}
          style={{ display: "flex", left: "-2px", margin: "auto" }}
        >
          <Icon
            className="cursor-pointer"
            swiper-button-disabled
            icon="arrow-left"
            color={navigationArrowsColor}
          />
        </NavigationButton>
      )}
      <StyledSwiperCarousel
        modules={[Autoplay, Navigation, Pagination]}
        className={[showNavigation ? "show-navigation" : "", loop ? "loop" : ""]
          .filter(Boolean)
          .join(" ")}
        navigation={
          showNavigation || hasExternalNavigation
            ? {
                prevEl: `.navigation-prev-${id}`,
                nextEl: `.navigation-next-${id}`,
              }
            : false
        }
        pagination={pagination}
        breakpoints={breakpoints}
        grabCursor={grabCursor}
        centeredSlides={centeredSlides}
        slidesPerView={slidesPerView}
        speed={loop ? 4000 : 300}
        {...props}
      >
        {children &&
          Children.map(
            children as JSX.Element[],
            (child: JSX.Element, index: number) => (
              <SwiperSlide
                key={index}
                style={{
                  height: "auto",
                  width: cardSize ? `${cardSize}px` : "auto",
                }}
              >
                {child}
              </SwiperSlide>
            )
          )}
      </StyledSwiperCarousel>
      {showNavigation && (
        <NavigationButton
          className={`inset-y-0 flex align-center mar-auto hide-on-mobile navigation-next navigation-next-${id}`}
          style={{ display: "flex", right: "-2px" }}
        >
          <Icon
            className="cursor-pointer"
            icon="arrow-right"
            color={navigationArrowsColor}
            size={24}
          />
        </NavigationButton>
      )}
    </Box>
  );
};

export const NavigationButton = styled.div(({ theme }) => ({
  cursor: "pointer",
  position: "absolute",
  zIndex: 100,
  "&.hide-on-mobile": {
    display: "none",
    [theme.media["bp-desktop-xs"]]: {
      display: "block",
    },
  },

  "&.swiper-button-disabled": {
    cursor: "default",
    opacity: 0.5,
  },
}));

const StyledSwiperCarousel = styled(SwiperCarousel)(({ theme }) => ({
  paddingBottom: theme.spacing[40],
  "&.show-navigation": {
    [theme.media["bp-desktop-xs"]]: {
      width: "100%",
    },
  },
  "&.loop .swiper-wrapper": {
    transitionTimingFunction: "linear",
  },
}));

type SkeletonLoadingCarouselProps = {
  cardWidth: number;
  cardHeight: number;
};

export const SkeletonLoadingCarousel = ({
  cardWidth,
  cardHeight,
}: SkeletonLoadingCarouselProps) => {
  return (
    <Carousel
      id="anytime-home-loading"
      cardSize={cardWidth}
      spaceBetween={16}
      slidesPerView={"auto"}
    >
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
      <Box className="skeleton" style={{ height: cardHeight }}></Box>
    </Carousel>
  );
};
