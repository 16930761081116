import dayjs from "dayjs";
import {
  postToSendSingleEventCancelEmail,
  postToSendSingleEventEmail,
} from "features/flocks";
import { FlownEvent } from "shared/event.types";
import {
  SessionInviteRequestedSource,
  analytics,
  catchAnalyticsError,
} from "features/analytics";
import { getUserDisplayName } from "features/profile/shared";
import { convertFlownEventToSessionEvent } from "utils/event-utils";

type EventInviteArgs = {
  event: FlownEvent;
  analyticsData: {
    eventSource: string;
    context: SessionInviteRequestedSource;
  };
  onEmailFailed?: (errorMessage: string) => void;
  onEmailSent?: (emailSent?: boolean) => void;
};

export const bookEventInvite = ({
  event,
  onEmailSent,
  onEmailFailed,
  analyticsData: { eventSource, context },
}: EventInviteArgs) => {
  return new Promise<void>((resolve) => {
    const sendInvite = async () => {
      try {
        const response = await postToSendSingleEventEmail({
          eventId: event.id,
          host: event.populatedHost
            ? {
                displayName: getUserDisplayName(event.populatedHost),
                email: event.populatedHost.email,
              }
            : undefined,
        });

        if (response.worked) {
          onEmailSent?.(!!response.emailSent);
        } else {
          onEmailFailed?.("An invite could not be sent. Please try again.");
        }
      } catch (error) {
        onEmailFailed?.(
          `An invite could not be sent. Please try again. ${error}`
        );
      }
      resolve();
    };

    const now = dayjs().tz("Europe/London");
    const hoursAway = dayjs(event.startTime)
      .tz("Europe/London")
      .diff(now, "hours");

    const analyticsData = {
      ...convertFlownEventToSessionEvent(event),
      hours_away: hoursAway,
      path: window.location.pathname,
      source: eventSource,
      context,
    } as const;

    analytics
      .track("Session Invite Requested", analyticsData, {
        All: true,
        "Actions Google Analytics 4": false,
        "Google Ads Conversions": false,
      })
      .catch(catchAnalyticsError);
    analytics
      .track("Session Invite Requested", analyticsData, {
        All: false,
        "Google Ads Conversions": true,
      })
      .catch(catchAnalyticsError);
    analytics
      .track("Session Invite Requested", analyticsData, {
        All: false,
        "Google AdWords New": true,
      })
      .catch(catchAnalyticsError);
    void sendInvite();
  });
};

export const cancelEventInvite = ({
  event,
  onEmailSent,
  onEmailFailed,
  analyticsData: { eventSource, context },
}: EventInviteArgs) => {
  return new Promise<void>((resolve) => {
    const cancelInvite = async () => {
      try {
        const response = await postToSendSingleEventCancelEmail({
          eventId: event.id,
        });

        if (response.worked) {
          onEmailSent?.();
        } else {
          onEmailFailed?.(
            "Your booking could not be cancelled. Please try again."
          );
        }
      } catch (error) {
        onEmailFailed?.(
          `Your booking could not be cancelled. Please try again.${error}`
        );
      }
      resolve();
    };

    const now = dayjs().tz("Europe/London");
    const hoursAway = dayjs(event.startTime)
      .tz("Europe/London")
      .diff(now, "hours");

    const analyticsData = {
      ...convertFlownEventToSessionEvent(event),
      hours_away: hoursAway,
      path: window.location.pathname,
      source: eventSource,
      context,
    } as const;

    analytics
      .track("Session Invite Cancelled", analyticsData, {
        All: true,
        "Actions Google Analytics 4": false,
        "Google Ads Conversions": false,
      })
      .catch(catchAnalyticsError);
    void cancelInvite();
  });
};
