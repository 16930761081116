export const createPostEndpoint = <P, R>(url: string) => {
  const endpoint = async (params?: P): Promise<R> => {
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to fetch "${url}" (${response.status}/${response.statusText})`
      );
    }
    return await response.json();
  };
  return endpoint;
};
