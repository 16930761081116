import { User } from "database/collections/users";
import { isProductionMode } from "./environment";
import { capitalizeFirstLetter } from "./string-utils";
import { QUERY_PARAMS, QUERY_VALUES } from "./url-query-utils";

export const HOME_URL = "/";
export const LOGGED_IN_NAVIGATE = "logged-in-navigate";
export const HOME_URL_FOR_ALL = `${HOME_URL}?${LOGGED_IN_NAVIGATE}`;
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const SSO_LOGIN_URL = "/api/auth/login";
export const SSO_LOGOUT_URL = "/api/auth/logout";
export const SSO_CALLBACK_URL = "/sso-callback";
export const SSO_ERROR_CALLBACK_URL = "/sso-error";
export const PRICING_URL = "/pricing";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD_URL = "/reset-password";
export const SEND_VERIFICATION_EMAIL_URL = "/send-verification-email";
export const VERIFY_EMAIL_URL = "/verify-email";
export const QUESTS_URL = "/quests";
export const ALMANAC_URL = "/almanac";
export const ANYTIME_URL_PREFIX = "/anytime";
export const ANYTIME_HOMEPAGE = ANYTIME_URL_PREFIX;
export const PORTHOLES_URL = `${ANYTIME_URL_PREFIX}/focus`;
export const ACADEMY_URL = `${ANYTIME_URL_PREFIX}/academy`;
export const RECHARGE_BROWSE_URL = `${ANYTIME_URL_PREFIX}/recharge`;
export const RECHARGE_PREVIEW_URL_PREFIX = `${RECHARGE_BROWSE_URL}/preview`;
export const FLOCKS_MARKETING_URL = "/flocks";
export const SESSIONS_GO_BASE_URL = "/sessions/go";
export const PORTHOLES_MARKETING_URL = "/portholes";

// EAGLE INTERNAL URLS
export const EAGLE_URL = "/eagle";
export const EAGLE_ORGANISATIONS_URL = `${EAGLE_URL}/organisations`;
export const EAGLE_USERS_URL = `${EAGLE_URL}/users`;
export const EAGLE_SESSIONS_URL = `${EAGLE_URL}/sessions`;
export const EAGLE_COMMUNITY_SESSIONS_URL = `${EAGLE_URL}/community-sessions`;
export const EAGLE_USER_ACTIVITIES_URL = `${EAGLE_URL}/user-activities`;
export const EAGLE_REFERRALS_DATA_URL = `${EAGLE_URL}/referrals`;
export const EAGLE_USER_CHAT_URL = `${EAGLE_URL}/community-chat`;
export const EAGLE_ALL_CHATS_URL = `${EAGLE_URL}/all-chats`;
export const EAGLE_FINANCE_URL = `${EAGLE_URL}/finance`;
export const EAGLE_ORGANISATIONS_CREATE_URL = `${EAGLE_ORGANISATIONS_URL}/new`;

export const ORGANISATION_URL_PREFIX = `/organisation`;
export const getOrganisationCreateSessionUrl = (slug: string) =>
  `${ORGANISATION_URL_PREFIX}/${slug}/create-session`;
export const getOrganisationEditSessionUrl = (slug: string, roomId: string) =>
  `${ORGANISATION_URL_PREFIX}/${slug}/edit-session/${roomId}`;
export const GREEN_SCENE_URL_PREFIX = "/green-scene";
export const USER_URL = "/user";
export const PUBLIC_PROFILE_URL = "/u";
export const getUserPublicProfileUrl = (vanityUrl: string) =>
  `${PUBLIC_PROFILE_URL}/${vanityUrl}`;
export const ACCOUNT_SETTINGS_URL_PREFIX = "/account-settings";
export const DELETE_USER_URL = `${ACCOUNT_SETTINGS_URL_PREFIX}/delete`;
export const MANAGE_SUBSCRIPTION_URL = `${ACCOUNT_SETTINGS_URL_PREFIX}/manage-subscription`;
export const PROFILE_URL = `${ACCOUNT_SETTINGS_URL_PREFIX}/profile`;
export const USER_MANAGEMENT_URL = `${ACCOUNT_SETTINGS_URL_PREFIX}/user-management`;
export const REFERRAL_URL = `${ACCOUNT_SETTINGS_URL_PREFIX}/referral`;
export const CONTACT_URL = "/contact";
export const LAUNCHPAD_URL = "/launchpad";
export const LAUNCHPAD_ONBOARDING_URL = `${LAUNCHPAD_URL}?pf_start_tour=${process.env.NEXT_PUBLIC_PF_ONBOARDING_TOUR_ID}`;
export const CALENDAR_PREFIX = "/calendar";
export const CALENDAR_ALL_URL = `${CALENDAR_PREFIX}?filter=${QUERY_VALUES.CALENDAR_ALL}`;
export const CALENDAR_FEATURED_URL = `${CALENDAR_PREFIX}?filter=${QUERY_VALUES.CALENDAR_FEATURED}`;
export const CALENDAR_COMMUNITY_URL = `${CALENDAR_PREFIX}?filter=${QUERY_VALUES.CALENDAR_COMMUNITY}`;
export const CALENDAR_MY_SESSIONS = `${CALENDAR_PREFIX}?filter=${QUERY_VALUES.CALENDAR_MY_SESSIONS}`;
export const CALENDAR_ORG_ONLY_URL = `${CALENDAR_PREFIX}?filter=${QUERY_VALUES.CALENDAR_ORG_ONLY}`;
export const CALENDAR_HOSTING_URL = `${CALENDAR_PREFIX}?filter=${QUERY_VALUES.CALENDAR_MY_SESSIONS}&sub-filter=${QUERY_VALUES.CALENDAR_HOSTING}`;
export const ALL_SESSIONS_URL = "/all-sessions";
export const MANAGE_MY_WEEK_URL = `${CALENDAR_PREFIX}/manage-my-week`;
export const SUBSCRIBE_URL = "/subscribe";
export const SUBSCRIPTION_UPGRADE_URL = "/subscription-upgrade";
export const FLOWN_STORY_URL = "/about/story-and-mission";
export const FLOWN_SCIENCE_URL = "/science-behind-flown";
export const TEAM_URL = "/about/team";
export const COMMUNITY_URL = "/community";
export const FOCUS_ROOM_URL_PREFIX = `/focus`;
export const COMMUNITY_SPRINGBOARD_URL = `${COMMUNITY_URL}/springboard/id`;
export const COMMUNITY_SPRINGBOARD_SHORT_URL = `/sc`;
export const ROOMS_URL = `${COMMUNITY_URL}/rooms`;
export const HOST_PANEL_SUFFIX = "/host-panel";
export const ROBOT_ROOM_URL = `${ROOMS_URL}/robots`;
export const CREATE_COMMUNITY_ROOM_URL = `${ROOMS_URL}/new`;
export const CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL = `${CREATE_COMMUNITY_ROOM_URL}/focus-session`;
export const CREATE_PRIVATE_COMMUNITY_ROOM_URL = `${ROOMS_URL}/new/private`;
export const CREATE_PRIVATE_ROOM_URL = `${CREATE_COMMUNITY_ROOM_URL}?${QUERY_PARAMS.SESSION_PRIVACY}=true`;
export const CREATE_PRIVATE_FIXED_FORMAT_ROOM_URL = `${CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL}?${QUERY_PARAMS.SESSION_PRIVACY}=true`;

export const LIFETIME_UPGRADE_SUBSCRIPTION_URL = `subscription-lifetime-upgrade`;
export const WRAPPED_URL_PREFIX = "/wrapped";

export const WAITING_ROOM_SUFFIX = "/waiting";

// Prefix to to be used for any page we want an anonymous to be registered
// with product fruits.
export const PRODUCT_FRUITS_PREFIX = "/pf/";

export const TESTIMONIALS_URL = "/testimonials";
export const AIRFLOW_URL = "/airflow";
export const RECHARGE_SESSION_URL_PREFIX = `${RECHARGE_BROWSE_URL}/session`;
export const SPRINGBOARD_URL_PREFIX = `/springboard`;
export const SPRINGBOARD_URL_NOT_FOUND = `${SPRINGBOARD_URL_PREFIX}/not-found`;
export const SPRINGBOARD_BY_ID_URL_PREFIX = `${SPRINGBOARD_URL_PREFIX}/id`;
export const SPRINGBOARD_BY_SLUG_URL_PREFIX = `${SPRINGBOARD_URL_PREFIX}/slug`;
export const BLOG_URL = "/blog";
export const BLOG_TAGS_URL = `${BLOG_URL}/tags`;
export const FACILITATORS_URL = "/facilitators";
export const FLOWN_IN_PRESS_URL = "/flown-in-the-press";
export const FLOWN_FOR_BUSINESS_URL = "/business";
export const ACADEMY_DEEP_WORK_URL =
  "/what-is-deep-work-your-easy-to-digest-guide";
export const ACADEMY_PROCRASTINATION_URL =
  "/procrastination-why-do-i-do-it-and-how-to-overcome-it";
export const SESSIONS_LIVE_URL = "/sessions/live";
export const AFFILIATE_URL = "https://flown.com/affiliate";
const ADHD_ACCESS_TO_WORK_URL =
  "/blog/adhd/government-grant-for-peope-with-adhd";
const ADHD_AT_WORK_URL = "/adhd-in-the-workplace";
const ACCESS_TO_WORK_START_URL = "/flown-access-to-work-start-here";
const UNIVERSITIES_URL = "/universities";
export const MEMBERSHIP_BENEFITS_URL = "/membership-benefits";
export const HOW_IT_WORKS_URL = "/how-it-works";
export const FAQS_URL = "/faqs";
export const FLOWN_PLUS_SESSIONS_URL = "/flown-plus";
export const FLOWN_PLUS_URL = "/flown-plus-start-here";
export const FLOWN_PLUS_TAGS_URL = `${FLOWN_PLUS_SESSIONS_URL}/tags`;
export const FLEETS_URL = `${FLOWN_PLUS_SESSIONS_URL}/fleets`;
export const COACHING_URL = `${FLOWN_PLUS_SESSIONS_URL}/coaching`;
export const FLEETS_BOOKING_URL =
  "/accountability-groups-fleets-booking-launcher";
export const COPILOT_URL = "/coaching-co-pilot";
export const COPILOT_ADHD_URL = "/adhd-coaching";
export const COPILOT_PRODUCTIVITY_URL = "/productivity-coaching";
export const COPILOT_MORE_URL = "/more-coaching-services";
export const DATO_URL_PATHNAME = "/[...pathParts]";
export const FLEET_CHAT_PATH = "/fleet-chat";
/**
 * URLs - external URLs that we link to from the app
 */
export const TWITTER_URL = "https://twitter.com/flown_space";
export const LINKEDIN_URL = "https://www.linkedin.com/company/flown/";
export const INSTAGRAM_URL = "https://www.instagram.com/flownspace/";
export const FACEBOOK_URL = "https://www.facebook.com/flownspace";
export const TIKTOK_URL = "https://www.tiktok.com/@flownspace";
export const TERMS_OF_USE_URL =
  "https://intercom.help/Flown-help-centre/en/articles/5020535-membership-agreement";
export const PRIVACY_POLICY_URL =
  "https://intercom.help/Flown-help-centre/en/articles/5030755-privacy-policy";
export const FLOWN_RADIO_URL =
  "https://open.spotify.com/user/m45h9hj67xtp7mhs9mfuu43f0?si=753f84d3b3704fff";
export const FLOWN_COMMUNITY_GUIDELINES_URL =
  "https://help.flown.com/en/articles/9680794-community-guidelines";
export const JOBS_WORKABLE_URL = "https://apply.workable.com/flown/";
const FEEL_GOOD_FOCUS_URL = "/feel-good-focus-modal";

export const getReloadUrl = (url: string) => url + "?reload=true";

export const getReloadUrlWithSelectedMembership = (url: string) =>
  getReloadUrl(url) + `&${QUERY_PARAMS.MEMBERSHIP_CHANGED}=true`;

export const FLOWN_FREE_URL_PREFIXES = [
  ACCOUNT_SETTINGS_URL_PREFIX,
  SUBSCRIBE_URL,
  REFERRAL_URL,
  GREEN_SCENE_URL_PREFIX,
  ACADEMY_URL,
  SPRINGBOARD_BY_ID_URL_PREFIX,
  SPRINGBOARD_BY_SLUG_URL_PREFIX,
  RECHARGE_BROWSE_URL,
  SESSIONS_LIVE_URL,
  COMMUNITY_URL,
];

//! Add all app feature URLs to this, it is used to hide Intercom on app pages
export const HIDE_INTERCOM_URL_PREFIXES = [
  ACADEMY_URL,
  GREEN_SCENE_URL_PREFIX,
  PORTHOLES_URL,
  RECHARGE_BROWSE_URL,
  EAGLE_URL,
  USER_MANAGEMENT_URL,
  PROFILE_URL,
  CREATE_COMMUNITY_ROOM_URL,
  CREATE_PRIVATE_COMMUNITY_ROOM_URL,
  FLEET_CHAT_PATH,
  WRAPPED_URL_PREFIX,
  SESSIONS_LIVE_URL,
  ROOMS_URL,
  FOCUS_ROOM_URL_PREFIX,
  WRAPPED_URL_PREFIX,
  FEEL_GOOD_FOCUS_URL,
];

// Links that show in header and footer
export const RESOURCES_LINKS = [
  { label: "What is deep work?", href: ACADEMY_DEEP_WORK_URL },
  { label: "Procrastination", href: ACADEMY_PROCRASTINATION_URL },
  { label: "FLOWN Radio", href: FLOWN_RADIO_URL },
  { label: "Access To Work hub", href: ACCESS_TO_WORK_START_URL },
  { label: "University hub", href: UNIVERSITIES_URL },
  { label: "ADHD at work", href: ADHD_AT_WORK_URL },
  {
    label: "UK ADHD Grant",
    href: ADHD_ACCESS_TO_WORK_URL,
  },
  {
    label: "Blog",
    href: BLOG_URL,
  },
];

/**
 * Hybrid navigation means a page will load the side menu bar for logged in users and
 * the top one (marketing) for logged out ones
 */
const staticPagesWithHybridNavigation = [ACADEMY_URL];
export const isStaticPageWithHybridNavigation = (currentPath: string) =>
  staticPagesWithHybridNavigation.includes(currentPath);

export const HOMEPAGE_SLUG = "specialpage-home";

export const getPageUrlFromSlug = (pageSlug: string) => {
  if (pageSlug === HOMEPAGE_SLUG) {
    return "/";
  }
  return `/${pageSlug}`;
};

/**
 * Return URL always needs to be relative to the website
 * so we make sure it starts with a slash or with the URL encoded
 * version: %2F
 */
export const isValidReturnUrl = (returnUrl: string) => {
  return returnUrl.startsWith("/") || returnUrl.startsWith("%2F");
};

export const getLoginUrlWithReturn = (ret: string) =>
  isValidReturnUrl(ret)
    ? LOGIN_URL + `?${QUERY_PARAMS.RETURN_URL}=` + encodeURIComponent(ret)
    : LOGIN_URL;

export const getRegisterUrlWithReturn = (ret: string, query?: string) =>
  `${REGISTER_URL}?${QUERY_PARAMS.REDIRECT_AFTER_REGISTER}=${
    isValidReturnUrl(ret) ? encodeURIComponent(ret) : ""
  }${query ? `${query}` : ""}`;

export const getRegisterUrlWithEmbeddedRedirects = (ret: string) => {
  const afterRegisterRedirect = `?${
    QUERY_PARAMS.REDIRECT_AFTER_REGISTER
  }=${encodeURIComponent(isValidReturnUrl(ret) ? ret : "")}`;

  const afterLoginRedirect = `&${
    QUERY_PARAMS.REDIRECT_AFTER_LOGIN
  }=${encodeURIComponent(getLoginUrlWithReturn(ret))}`;

  return REGISTER_URL + afterRegisterRedirect + afterLoginRedirect;
};

const PAGE_CATEGORY_MAP: Record<
  string,
  string | { loggedIn: string; loggedOut: string }
> = {
  [`${LAUNCHPAD_URL}`]: "Member Home",
  [`${RECHARGE_BROWSE_URL}`]: "Anytime Recharge",
  [`${PORTHOLES_URL}`]: "Anytime Recharge",
  [`${GREEN_SCENE_URL_PREFIX}`]: "Anytime Focus",
  [`${ACADEMY_URL}`]: "Academy",
  [`${LOGIN_URL}`]: "Member Ops",
  [`${REGISTER_URL}`]: "Member Ops",
  [`${MANAGE_SUBSCRIPTION_URL}`]: "Member Ops",
  [`${USER_MANAGEMENT_URL}`]: "B2B2C Ops",
  [`${RESET_PASSWORD_URL}`]: "Member Ops",
  [`${FORGOT_PASSWORD_URL}`]: "Member Ops",
  [`${SEND_VERIFICATION_EMAIL_URL}`]: "Member Ops",
  [`${VERIFY_EMAIL_URL}`]: "Member Ops",
  [`${SUBSCRIBE_URL}`]: "Member Ops",
  [`${REFERRAL_URL}`]: "Member Ops",
  [`${EAGLE_URL}`]: "FLOWN Admin Ops",
  [`${SPRINGBOARD_URL_PREFIX}`]: "Springboard",
};

export const getPageCategoryBySlug = (
  asPath: string,
  loggedIn: boolean
): string => {
  if (asPath === "/") return "Marketing";
  const PAGE_CATEGORIES = Object.keys(PAGE_CATEGORY_MAP);
  const pageCategorySlug = PAGE_CATEGORIES.find((category) =>
    asPath.includes(category)
  );
  if (!pageCategorySlug) return "Marketing";
  const pageCategory = PAGE_CATEGORY_MAP[pageCategorySlug];
  if (typeof pageCategory === "object") {
    return loggedIn ? pageCategory.loggedIn : pageCategory.loggedOut;
  }
  return pageCategory;
};

export const getPageAnalyticsTitle = (asPath: string) => {
  const pathWithNoParams = asPath.split("?")[0];
  const corePath = pathWithNoParams.replace(/[/-]/g, " ").trim();
  const analyticsTitle = capitalizeFirstLetter(corePath);
  return analyticsTitle;
};

export const getURLWithReferralCode = (url: string, referralCode?: string) =>
  `${url}${referralCode ? `?${QUERY_PARAMS.REFERRAL}=${referralCode}` : ""}`;

export const getSendVerificationEmailUrl = (email: string) =>
  `${SEND_VERIFICATION_EMAIL_URL}/${encodeURIComponent(email)}`;

export const getSprinboardCreateURLWithReferralCode = (
  url: string,
  referralCode?: string
) =>
  `${url}?${QUERY_PARAMS.SESSION_CREATED}=success${
    referralCode ? `&${QUERY_PARAMS.REFERRAL}=${referralCode}` : ""
  }`;

type TypeformURLUserActionArgs = {
  email: string;
  subscriptionType: string;
  action: "cancel" | "delete";
  cancelPortalUrl: string;
  updatePortalUrl: string;
  externalId: string;
};

/**
 * Related typeform:
 * https://admin.typeform.com/form/Ic745PBP/create?block=79d45bc5-965c-4f3c-8b78-2d502d6fe167
 */
export const getTypeformURLForUserActions = ({
  email,
  subscriptionType,
  action,
  cancelPortalUrl,
  updatePortalUrl,
  externalId,
}: TypeformURLUserActionArgs) => {
  /**
   * We cannot decode URLs in Typeform so we need to pass the portal session
   * path only. The match extracts the path
   * Example link: https://billing.stripe.com/p/session/test_YWNjdF8xSHRHT0tFVzhHMWpGaXlWLF9Ob3lBMXlBNWRrUVRUN3B0dXlaZlZIcENtaTdyS2hY0100D4bu8lLU/flow
   */
  const cancelSessionId = cancelPortalUrl
    ? cancelPortalUrl.match(/session\/(.*?)\/flow/)?.[1]
    : "";

  /**
   * Example link: https://billing.stripe.com/p/session/test_YWNjdF8xSHRHT0tFVzhHMWpGaXlWLF9OcE04SXBmek84QVhoZFN3eUVDMFAyZTZNSzVkbmRS0100UPrWQpI4
   */
  const updateSessionId = updatePortalUrl
    ? updatePortalUrl.split("https://billing.stripe.com/p/session/")?.[1]
    : "";

  if (isProductionMode()) {
    return `https://auxeqe9mt6v.typeform.com/cancel-flown?email=${email}&subscription_type=${subscriptionType}&request_type=${action}&portal_cancel_path=${cancelSessionId}&portal_update_path=${updateSessionId}&external_id=${externalId}`;
  } else {
    return `https://auxeqe9mt6v.typeform.com/to/zWIPq5ND?email=${email}&subscription_type=${subscriptionType}&request_type=${action}&portal_cancel_path=${cancelSessionId}&portal_update_path=${updateSessionId}&external_id=${externalId}`;
  }
};

/**
 * Use this when we want to open a link in the same tab in PWA but in a new
 * one in desktop
 */
export const getPWASafeUrlTarget = (isPWA: boolean) =>
  isPWA ? "_self" : "_blank";

export const addQueryParam = (url: string, key: string, value: string) => {
  // Check if the URL has any query parameters
  const hasQueryParams = url.includes("?");

  // Encode the key and value to make them URI-safe
  const encodedKey = encodeURIComponent(key);
  const encodedValue = encodeURIComponent(value);

  // Construct the query parameter string
  const queryParam = `${encodedKey}=${encodedValue}`;

  // Append the query parameter to the URL
  if (hasQueryParams) {
    // If the URL already has query parameters, append the new one
    return `${url}&${queryParam}`;
  } else {
    // If the URL has no query parameters, add the first one
    return `${url}?${queryParam}`;
  }
};

export const getCloneRoomUrl = (roomId: string) =>
  `${CREATE_COMMUNITY_ROOM_URL}?${QUERY_PARAMS.CLONE}=${roomId}`;

export const getUserFocusRoomUrl = (
  user: Pick<User, "vanityUrl" | "referralCode">
) => `${FOCUS_ROOM_URL_PREFIX}/${user.vanityUrl || user.referralCode}`;

type GetCanonicalUrlForPathArgs = {
  siteBaseUrl: string;
  asPath: string;
};

export const getCanonicalUrlForPath = ({
  siteBaseUrl,
  asPath,
}: GetCanonicalUrlForPathArgs) => {
  /**
   * Handle exception for /home that should be mapped to /
   */
  const finalPath = asPath === "/home" ? "/" : asPath;

  /**
   * asPath needs to be split to remove the query string
   * and any id (#) params as these should not be in the
   * canonical URL
   */
  return `${siteBaseUrl}${finalPath.split(/[?#]/)[0]}`;
};
