import styled from "@emotion/styled";
import { ContentContainer } from "legacy-design-system";
import { IframeBlockFragment } from "generated/graphql-typed";
import { notEmpty } from "utils/assertions";
import { useIsMediaDesktop } from "utils/component-utils";
import { renderer } from "./renderer";

const IframeView = (props: IframeBlockFragment & { className: string }) => {
  const isDesktop = useIsMediaDesktop();

  const iframeContent = notEmpty(props.src) ? (
    <IframeWrapper
      className={`${props.isResponsive ? "responsive" : ""} ${props.className}`}
    >
      <Iframe
        src={props.src}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        style={{ height: props.height || "100%" }}
      />
    </IframeWrapper>
  ) : null;

  return props.desktopOnly ? (isDesktop ? iframeContent : null) : iframeContent;
};

export const IframeRenderer = renderer({
  typeName: "IframeRecord",
  view: IframeView,
});

const Iframe = styled.iframe`
  margin: 32px auto 0;
  width: 100%;
  max-width: 1140px;
  height: 638px;
  @media only screen and (max-width: 999.9px) {
    height: 400px;
  }
`;

const IframeWrapper = styled(ContentContainer)`
  &.responsive {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
