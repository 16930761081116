import styled from "@emotion/styled";
import { MilestoneBadge } from "features/badges/shared";
import { Box, ProgressBar, Text } from "design-system/components";
import { Badge } from "./badge";

type BadgeProgressProps = {
  currentBadge: MilestoneBadge;
  nextBadge: MilestoneBadge;
  currentProgress: number;
  currentStreak?: number;
  sessionAttendedInCurrentWeek?: boolean;
};

export const BadgeProgress: React.FC<BadgeProgressProps> = ({
  currentBadge,
  nextBadge,
  currentProgress,
  currentStreak,
  sessionAttendedInCurrentWeek,
}) => {
  const progressOnCurrentMilestone =
    currentProgress - currentBadge.totalSessions;
  const totalMilestoneProgress =
    nextBadge.totalSessions - currentBadge.totalSessions;
  return (
    <BadgeProgressWrapper>
      <Box className="pos-relative flex justify-between">
        <Badge dimensions={60} badge={currentBadge} />
        <ProgressBarWrapper
          currentProgress={progressOnCurrentMilestone}
          totalProgress={totalMilestoneProgress}
          indicatorColor={"success"}
        />
        <Badge dimensions={60} badge={nextBadge} />
      </Box>
      <Box>
        <Text className="mar-y-8" color="grey400" fontSize="xs">
          {currentProgress}/{nextBadge.totalSessions} sessions
        </Text>
        {sessionAttendedInCurrentWeek && currentStreak && currentStreak > 1 && (
          <Text color="grey400" fontSize="xs">
            You've attended a live session in each of the past {currentStreak}{" "}
            weeks. Great work!
          </Text>
        )}
        {!sessionAttendedInCurrentWeek &&
          currentStreak !== undefined &&
          currentStreak > 0 && (
            <>
              <Text color="grey400" fontSize="xs">
                {currentStreak === 1
                  ? `You attended a live session last week - extend your streak to two weeks in a row by joining one this week.`
                  : `You've attended a live session for the past ${currentStreak} weeks in a row. Join a session this week to keep your streak going.`}
              </Text>
            </>
          )}
        {!sessionAttendedInCurrentWeek &&
          currentStreak !== undefined &&
          currentStreak == 0 && (
            <>
              <Text color="grey400" fontSize="xs">
                Kick start your streak and attend a session this week.{" "}
              </Text>
            </>
          )}
      </Box>
    </BadgeProgressWrapper>
  );
};

const BadgeProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProgressBarWrapper = styled(ProgressBar)`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 40px;
  right: 40px;
  width: auto;
`;
