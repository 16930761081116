import { Icon } from "design-system/components";
import { forwardRef } from "react";
import { css } from "styled-system/css";
import { token } from "styled-system/tokens";

export const LiveNowButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      title="live-now"
      type="button"
      className={css({
        borderRadius: "round",
        position: "absolute",
        bottom: 0,
        right: 0,
        display: "flex",
        border: "2px solid white",
      })}
    >
      <Icon icon="live-now" size={10} color={token("colors.success")} />
    </button>
  );
});

LiveNowButton.displayName = "LiveNowButton";
