import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { isCypress, isDevelopmentMode, isProductionMode } from "./environment";
import { getIsFlownRole, getIsPaidRole } from "services/roles/user-roles";
import { THEME_COLORS } from "design-system/emotion.theme";
import { clientLoggerForFile } from "./logger.client";
import {
  DAYS_INTO_TRIAL_TO_SHOW_NOTIFICATIONS,
  FREE_TRIAL_DAYS,
} from "./shared-constants";
import { useUserContext } from "context/user-context";
import { analytics } from "features/analytics";

const logger = clientLoggerForFile(__filename);

export const useOneSignal = () => {
  const { externalId, userRole, freeTrialRemainingDays } = useUserContext();
  const [initialized, setInitialized] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const subscribeToNotifications = async () => {
    if (initialized) {
      await OneSignal.Notifications.requestPermission();
      void analytics.track("Notifications Subscribe Button Clicked", {});
    }
  };

  useEffect(() => {
    if (initialized || isCypress()) return;

    const init = async () => {
      const ONE_SIGNAL_APP_ID = process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID;

      // only run if it's in production. For development, we can run locally or for one specific
      // domain (preview.flown.com).
      if (
        ONE_SIGNAL_APP_ID &&
        (isProductionMode() ||
          ["https://preview.flown.com", "http://localhost:3000"].includes(
            window.location.origin
          ))
      ) {
        if (process.env.NEXT_PUBLIC_ENABLE_ONESIGNAL_DEBUG === "true") {
          OneSignal.Debug.setLogLevel("trace");
        } else {
          OneSignal.Debug.setLogLevel("ERROR");
        }

        try {
          await OneSignal.init({
            appId: ONE_SIGNAL_APP_ID,
            allowLocalhostAsSecureOrigin: isDevelopmentMode(),
            path: `/assets/push/onesignal/`,
            serviceWorkerPath: `OneSignalSDKWorker.js`,
            serviceWorkerUpdaterPath: `OneSignalSDKWorker.js`,
            serviceWorkerParam: { scope: "/assets/push/onesignal/js/" },
            // autoResubscribe: true, // https://documentation.onesignal.com/docs/browser-behavior
            promptOptions: {
              slidedown: {
                enabled: true,
                autoPrompt: false,
                actionMessage:
                  "Make focus a habit – FLOWN notifications help you show up and stay accountable.",
                acceptButtonText: "Try it out",
                cancelButtonText: "No thanks",
              },
              customlink: {
                enabled: true /* Required to use the Custom Link */,
                style: "button" /* Has value of 'button' or 'link' */,
                size: "medium" /* One of 'small', 'medium', or 'large' */,
                color: {
                  button:
                    THEME_COLORS.focusMid /* Color of the button background if style = "button" */,
                  text: THEME_COLORS.black /* Color of the prompt's text */,
                },
                text: {
                  subscribe: "Subscribe to push notifications",
                  /* Prompt's text when not subscribed */
                  unsubscribe:
                    "Unsubscribe from push notifications" /* Prompt's text when subscribed */,
                  explanation:
                    "Get in app notifications for events and deep work suggestions." /* Optional text appearing before the prompt button */,
                },
                unsubscribeEnabled:
                  false /* Controls whether the prompt is visible after subscription */,
              },
            },
          });

          setInitialized(true);
        } catch (error) {
          logger.warn("OneSignal failed to initialize", error);
        }
        if (externalId) {
          await OneSignal.login(externalId);
          const optedIn = OneSignal.User.PushSubscription.optedIn;
          if (optedIn) {
            setIsSubscribed(true);
          }

          OneSignal.User.PushSubscription.addEventListener(
            "change",
            async (subscriptionEvent) => {
              const isSubscribed = subscriptionEvent.current.optedIn;
              setIsSubscribed(isSubscribed);
            }
          );
          if (
            userRole &&
            (getIsPaidRole(userRole) ||
              getIsFlownRole(userRole) ||
              FREE_TRIAL_DAYS - (freeTrialRemainingDays || 0) >
                DAYS_INTO_TRIAL_TO_SHOW_NOTIFICATIONS) // user has been on the app at least 3 days
          ) {
            // Test the slide down propt for flown users
            await OneSignal.Slidedown.promptPush();
          }
        }
      }
    };
    try {
      void init();
    } catch (error) {
      logger.warn(`OneSignal failed to initialize. ${error}`);
    }
  }, [externalId, userRole, initialized, freeTrialRemainingDays]);

  return {
    initialized,
    isSubscribed,
    subscribeToNotifications,
  };
};

/**
 * https://documentation.onesignal.com/docs/custom-link-prompt
 */
export const CUSTOM_LINK_PROMPT_CLASS = "onesignal-customlink-container";
