"use client";
import React from "react";
import styled from "@emotion/styled";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { isActiveItem, getPrimaryNavItems } from "./nav-menu-utils";
import { useIsDesktop, useIsMediaTablet } from "utils/component-utils";
import { HOME_URL_FOR_ALL } from "utils/urls";
import { useUserContext } from "context/user-context";
import { THEME_COLORS } from "design-system/emotion.theme";
import { Box, Text } from "design-system/components";
import Image from "next/image";
import { SecondaryNavMenu } from "./secondary-nav-menu";
import { token } from "styled-system/tokens";
import { css } from "styled-system/css";
import { ProductFruitsInAppCenter } from "features/productfruits/client";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";
import { useAtom } from "jotai";
import { accountSidePanelAtom } from "./account-side-panel";

export const NavMenu = ({
  showSecondary = true,
}: {
  showSecondary?: boolean;
}) => {
  const { isFreeRole } = useUserContext();
  const isDesktop = useIsDesktop(THEME_BREAKPOINTS.tablet);

  return (
    <>
      <PrimaryNavMenuContainer>
        <InnerContainer>
          <Box className="flex-col align-center mar-bot-32 hide-on-mobile">
            <LogoLink href={HOME_URL_FOR_ALL} />
            {isFreeRole && isDesktop && (
              <Text
                data-cy="flown-free-text"
                fontSize="sm"
                fontWeight={700}
                color="grey200"
              >
                FREE
              </Text>
            )}
          </Box>

          <PrimaryNavMenu />
          <ProductFruitsInAppCenter className="hide-on-mobile mar-top-auto bg-grey100" />
        </InnerContainer>
      </PrimaryNavMenuContainer>
      {showSecondary && (
        <SecondaryNavMenuContainer>
          <SecondaryNavMenu />
        </SecondaryNavMenuContainer>
      )}
    </>
  );
};

const PrimaryNavMenu = () => {
  const pathname = usePathname() ?? "/";
  const { organisation } = useUserContext();
  const isAboveTablet = useIsMediaTablet();
  const [isAccountSidePanelOpen, setIsAccountSidePanelOpen] =
    useAtom(accountSidePanelAtom);
  return (
    <>
      {getPrimaryNavItems(organisation).map((item, index) => {
        if (item.hidden) return;
        if (!isAboveTablet && item.hideOnMobile) return;

        const color = THEME_COLORS.black;
        const urlRef = item.url
          ? item.url
          : item.children
          ? item.children[0].url
          : "";

        const itemKey = `nav-${item.name.toLowerCase()}`;
        // customIconUrl is only set for organisations
        const { customIconUrl } = item;
        const size = isAboveTablet ? (customIconUrl ? 40 : 32) : 24;

        // Special handling for Account item as it does not have a Link
        if (item.name === "Account") {
          const isActive =
            isActiveItem(item, pathname) || isAccountSidePanelOpen;
          // We also do not show it above the tablet breakpoint
          return !isAboveTablet ? (
            <div
              key={`${item.name}-${index}`}
              className={`b-radius-16 w-full cursor-pointer pad-4 ${css({
                height: "100%",
                "bp-tablet": {
                  height: "auto",
                },
              })}`}
              data-cy={itemKey}
              onClick={() => setIsAccountSidePanelOpen(true)}
            >
              <MenuItem className={isActive ? "active" : ""}>
                <Image
                  src={`/assets/icons/${item.icon}.svg`}
                  height={size}
                  width={size}
                  alt={`${item.name} icon`}
                />
                <ItemName className={isActive ? "active" : ""} color={color}>
                  {item.name}
                </ItemName>
              </MenuItem>
            </div>
          ) : null;
        }

        return (
          <Link
            href={urlRef}
            key={`${item.name}-${index}`}
            scroll={false}
            className={`b-radius-16 w-full pad-4 ${css({
              height: "100%",
              "&:focus": {
                boxShadow: "none",
              },
              "bp-tablet": {
                height: "auto",
              },
            })}`}
            data-cy={itemKey}
          >
            <MenuItem className={isActiveItem(item, pathname) ? "active" : ""}>
              <Image
                className={customIconUrl ? "b-radius-round" : ""}
                src={customIconUrl ?? `/assets/icons/${item.icon}.svg`}
                height={size}
                width={size}
                alt={`${item.name} icon`}
              />
              <ItemName
                className={isActiveItem(item, pathname) ? "active" : ""}
                color={color}
              >
                {item.name}
              </ItemName>
            </MenuItem>
          </Link>
        );
      })}
    </>
  );
};

export const PRIMARY_NAV_MOBILE_HEIGHT = 72;

const SecondaryNavMenuContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  grid-area: banner;
  z-index: 50;
  &.profile-only {
    border-bottom: none;
    position: absolute;
    right: 3rem;
    width: 32px;
  }
`;

const PrimaryNavMenuContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  grid-area: primary;
  z-index: 50;
  height: ${PRIMARY_NAV_MOBILE_HEIGHT}px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #ffffff;

  @media only screen and (min-width: ${token("breakpoints.bp-tablet")}) {
    height: 100vh;
    width: ${token("sizes.primaryNavWidth")};
    border-right: 1px solid #e5e1e6;
    overflow: auto;
  }
`;

const InnerContainer = styled.div`
  height: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  ${({ theme }) => theme.media["bp-tablet"]} {
    flex-direction: column;
    justify-content: flex-start;
    padding: ${({ theme }) => theme.spacing[8]};
  }
`;

const MenuItem = styled.div(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing[4],
  justifyContent: "center",
  padding: theme.spacing[8],
  transition: "background-color 0.2s ease-in-out",
  width: "100%",
  height: "100%",
  borderRadius: theme.radii[16],
  "&.active, &:focus": {
    backgroundColor: theme.colors.grey100,
  },

  [theme.media["bp-tablet"]]: {
    height: "auto",
    gap: theme.spacing[8],
    padding: theme.spacing[12],
    "&:hover": {
      backgroundColor: theme.colors.grey50,
    },
  },
}));

const ItemName = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes["xs"]};
  text-align: center;

  @media only screen and (min-width: ${token("breakpoints.bp-tablet")}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

const LogoLink = styled(Link)`
  display: none;
  @media only screen and (min-width: ${token("breakpoints.bp-tablet")}) {
    border-radius: ${({ theme }) => theme.radii[16]};
    display: block;
    width: 76px;
    background: url(/assets/shared/flown-logo-black.svg) no-repeat center;
    /* background-size: 70px 0.6875rem; */
    filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff);
    padding: ${({ theme }) => `${theme.spacing[16]} 0`};
  }
`;
