import { postToGetPopulatedUsersInSession } from "features/calendar/client";
import { getUsersInRoomWithConnectionsAndTags } from "features/sessions/client";
import { useCallback, useState } from "react";
import { FlownEvent } from "shared/event.types";
import { ProfileTag, UserProfileFromActivity } from "shared/user.types";
import {
  getEventHasEnded,
  getEventHasStarted,
  getIsDropInEvent,
} from "utils/event-utils";
import { useHeartbeatRequest } from "utils/hooks/use-heartbeat-request";

type GetUsersInSessionProps = {
  loggedIn: boolean;
  event: Pick<
    FlownEvent,
    "slug" | "id" | "isP2P" | "detailedName" | "startTime" | "endTime"
  > | null;
  heartbeatInterval?: number;
};

export const useGetUsersInSession = ({
  loggedIn,
  event,
  heartbeatInterval = 15000,
}: GetUsersInSessionProps) => {
  const [usersInSession, setUsersInSession] = useState<
    UserProfileFromActivity[]
  >([]);
  const [connectionsInSession, setConnectionsInSession] = useState<
    UserProfileFromActivity[]
  >([]);
  const [commonTags, setCommonTags] = useState<ProfileTag[]>([]);
  const [hasCheckedForUsers, setHasCheckedForUsers] = useState(false);

  const fetchUsersInSessions = useCallback(async () => {
    if (!event || !loggedIn) return;

    /**
     *  We should only fetch users in sessions if the event has started
     *  This will prevent calling this endpoint too many times
     *  We also also return early if the event has ended
     *
     */
    const hasEventStarted = getEventHasStarted(event?.startTime);
    const hasEventEnded = getEventHasEnded(event?.endTime);
    if (!hasEventStarted || hasEventEnded) {
      setHasCheckedForUsers(true);
      return;
    }

    const isDropIn = getIsDropInEvent(event);

    if (isDropIn || event.isP2P) {
      // For drop-in this is the event.id, for p2p-sessions it is the detailed name
      const roomName = isDropIn ? event.id : event.detailedName;
      const response = await getUsersInRoomWithConnectionsAndTags(roomName);

      if (response.worked) {
        setUsersInSession(response.users);
        setCommonTags(response.commonTags);
        setConnectionsInSession(response.connections);
        setHasCheckedForUsers(true);
      }
    } else {
      const response = await postToGetPopulatedUsersInSession({
        eventId: event.id,
        excludeSelf: true,
      });
      if (response.worked) {
        setUsersInSession(response.usersInSession);
        setHasCheckedForUsers(true);
      }
    }
  }, [event, loggedIn]);

  /**
   * https://docs.daily.co/reference/rest-api/presence
   * It should be sufficient to query this endpoint no more frequently than once every 15 seconds to get a complete picture of all of the participants on your domain.
   */
  useHeartbeatRequest({
    fn: fetchUsersInSessions,
    interval: heartbeatInterval,
    enabled: loggedIn && !!event,
  });

  return {
    usersInSession,
    connectionsInSession,
    commonTags,
    hasCheckedForUsers,
  };
};
