import { getUserDisplayName } from "features/profile/shared";
import { Box, Button, useToast } from "design-system/components";
import { UserCard } from "design-system/molecules/cards/user-card";
import React from "react";
import { UserProfileFromActivityNoId } from "shared/user.types";
import { WorkedOrError } from "shared/response.types";
import { css } from "styled-system/css";

type UserFollowCardProps = {
  user: UserProfileFromActivityNoId;
  onFollow?: (
    followUser: UserProfileFromActivityNoId
  ) => Promise<WorkedOrError>;
  onUnfollow?: (
    followUser: UserProfileFromActivityNoId
  ) => Promise<WorkedOrError>;
};

export const UserFollowCard: React.FC<UserFollowCardProps> = ({
  user,
  onFollow,
  onUnfollow,
}) => {
  const { toast, Toast } = useToast();
  const [showUnfollow, setShowUnfollow] = React.useState(false);

  const onFollowUser = async (user: UserProfileFromActivityNoId) => {
    if (!onFollow) return;

    const response = await onFollow(user);

    if (!response.worked) {
      if (response.error === "duplicate-follow-error") {
        toast("error", `You already follow ${user.displayName}`);
        return;
      }
      toast("error", response.error);
      return;
    }
  };

  const onUnfollowSuggestion = async (user: UserProfileFromActivityNoId) => {
    if (!onUnfollow) return;
    const response = await onUnfollow(user);

    if (!response.worked) {
      if (response.error === "failed-to-unfollow-user") {
        toast("error", `Could not unfollow ${user.displayName}`);
        return;
      }
      toast("error", response.error);
      return;
    }
  };

  return (
    <>
      {Toast}
      <Box
        className={`flex align-center pad-y-8 w-full ${css({
          borderBottom: "1px solid {colors.grey100}",
          _lastOfType: {
            borderBottom: "none",
          },
        })}`}
      >
        <UserCard
          className="flex-1"
          displayName={getUserDisplayName(user)}
          onlineStatus={user.onlineStatus}
          oneLiner={user.oneLiner}
          {...user}
        />
        {!user.isSelf && !user.isPrivateProfile && onFollow && onUnfollow && (
          <Button
            variant="secondary"
            size="small"
            onClick={() => {
              if (user.isFollowed) {
                void onUnfollowSuggestion(user);
                user.isFollowed = false;
              } else {
                void onFollowUser(user);
                user.isFollowed = true;
              }
            }}
            onTouchStart={() => setShowUnfollow(true)}
            onTouchEnd={() => setShowUnfollow(false)}
            onMouseEnter={() => setShowUnfollow(true)}
            onMouseLeave={() => setShowUnfollow(false)}
          >
            {user.isFollowed
              ? showUnfollow
                ? "Unfollow"
                : "Following"
              : "Follow"}
          </Button>
        )}
      </Box>
    </>
  );
};
