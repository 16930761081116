import { FlockSessionList } from "features/flocks";
import { FlockSessionListBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";

const FlockSessionListView = (
  props: FlockSessionListBlockFragment & { className: string }
) => <FlockSessionList {...props} />;

export const FlockSessionListRenderer = renderer({
  typeName: "FlockSessionListRecord",
  view: FlockSessionListView,
});
