"use client";
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  CalendarFilterKey,
  CalendarFilterOptions,
  CalendarFilters,
  getEmptyCalendarFilterOptions,
  getQueryFilterKeyByCalendarId,
} from "../../../shared/calendar-filters";
import { getQueryParam, getStringifiedQuery } from "utils/url-query-utils";
import { useRouter } from "next/router";
import { getTagsSlugArray, getTypedTags } from "utils/tag-utils";
import { DayEvents } from "../../../shared";
import { CalendarView, useCalendarView } from "../../hooks/use-view-calendar";
import { getIsPublicFixedFormatSession } from "utils/event-utils";
import { CalendarTabFilterValue } from "./calendar-tab-filters";

type CalendarFiltersContextData = {
  calendarId: string;
  clearAllFilters: () => void;
  tabFilter?: CalendarTabFilterValue;
  facilitators: CalendarFilterOptions["facilitators"];
  forceView?: CalendarView;
  onFilterUpdate: (
    filterKey: CalendarFilterKey,
    filterValue: string | boolean
  ) => void;
  resetFilterOptions: (filteredEventsByDay: DayEvents) => void;
  selectedFilters: CalendarFilters;
  sessionTagOptions: CalendarFilterOptions["tags"];
  sessionTypeOptions: CalendarFilterOptions["sessionTypes"];
  setSessionTypeOptions: (
    sessionTypes: CalendarFilterOptions["sessionTypes"]
  ) => void;
  view: CalendarView;
};

export const CalendarFiltersContext = createContext(
  {} as CalendarFiltersContextData
);

type CalendarFiltersContextProviderProps = {
  children: ReactNode;
  calendarId: string;
  calendarFilterOptions?: CalendarFilterOptions;
  tabFilter?: CalendarTabFilterValue;
  forceView?: CalendarView;
};

export const CalendarFiltersContextProvider = ({
  calendarId,
  calendarFilterOptions = getEmptyCalendarFilterOptions(),
  children,
  tabFilter,
  forceView,
}: CalendarFiltersContextProviderProps) => {
  const router = useRouter();
  const { view } = useCalendarView({ forceView });

  /**
   * Check for any existing filters in the URL query params
   */
  const presetFilters = useMemo((): CalendarFilters => {
    const urlQuery = router.query;

    const categoryFromQuery = getQueryParam(
      urlQuery[getQueryFilterKeyByCalendarId("category", calendarId)]
    );
    const tagFromQuery = getQueryParam(
      urlQuery[getQueryFilterKeyByCalendarId("tag", calendarId)]
    );
    const sessionTypeFromQuery = getQueryParam(
      urlQuery[getQueryFilterKeyByCalendarId("sessionType", calendarId)]
    );

    const facilitatorFromQuery = getQueryParam(
      urlQuery[getQueryFilterKeyByCalendarId("facilitator", calendarId)]
    );

    return {
      category: categoryFromQuery || "all-sessions",
      tag: tagFromQuery || "",
      sessionType: sessionTypeFromQuery || "",
      facilitator: facilitatorFromQuery || "",
    } as CalendarFilters;
  }, [calendarId, router.query]);

  const [selectedFilters, setSelectedFilters] =
    useState<CalendarFilters>(presetFilters);

  const [sessionTypeOptions, setSessionTypeOptions] = useState(
    calendarFilterOptions.sessionTypes
  );
  const [sessionTagOptions, setSessionTagOptions] = useState(
    calendarFilterOptions.tags
  );

  const [facilitatorOptions, setFacilitatorOptions] = useState(
    calendarFilterOptions.facilitators
  );

  const onFilterUpdate = (
    filterKey: CalendarFilterKey,
    filterValue: string | string[] | boolean
  ) => {
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: filterValue,
    });

    if (filterValue) {
      router.query = {
        ...router.query,
        [getQueryFilterKeyByCalendarId(filterKey, calendarId)]:
          filterValue.toString(),
      };
      const queryString = getStringifiedQuery(router.query);

      window.history.replaceState(
        null,
        "",
        `${router.pathname}?${queryString}`
      );
    } else {
      delete router.query[getQueryFilterKeyByCalendarId(filterKey, calendarId)];
      // We can't have a useEffect with router.query as dependency bc it is not trigger when deleting a field
      const queryString = getStringifiedQuery(router.query);
      window.history.replaceState(
        null,
        "",
        `${router.pathname}?${queryString}`
      );
    }
  };

  const clearAllFilters = () => {
    setSelectedFilters({
      category: "",
      tag: "",
      sessionType: "",
      facilitator: "",
    });
    router.query = {};
    void router.replace({ query: { ...router.query } }, undefined, {
      shallow: true,
    });
  };

  // After filtering the events, the filter options are reset based on the previous selection of filters, and the result of the filtered events
  const resetFilterOptions = useCallback(
    (filteredEventsByDay: DayEvents) => {
      let resetTags: CalendarFilterOptions["tags"] = [];
      let resetSessionTypes: CalendarFilterOptions["sessionTypes"] = [];
      // ! If the filter is not selected reset the options completely

      if (selectedFilters.tag) {
        // * If a tag is selected, and no other filter is selected, keep all default options for tags
        if (!(selectedFilters.category || selectedFilters.sessionType)) {
          resetTags = calendarFilterOptions.tags;
        } else {
          // * If there are other filters selected, start the reset with the current selected tag
          const resetTagOption = calendarFilterOptions.tags.find(
            (tag) => tag.slug === selectedFilters.tag
          );
          if (resetTagOption) {
            resetTags.push(resetTagOption);
          }
        }
      }

      if (selectedFilters.sessionType) {
        // * If a SESSION TYPE is selected, and no other filter is selected, keep all default options for SESSION TYPES
        if (!(selectedFilters.category || selectedFilters.tag)) {
          resetSessionTypes = calendarFilterOptions.sessionTypes;
        } else {
          // * If there are other filters selected, start the reset with the current selected SESSION TYPE
          const resetSessionTypeOption =
            calendarFilterOptions.sessionTypes.find(
              (type) => type.name === selectedFilters.sessionType
            );
          if (resetSessionTypeOption) {
            resetSessionTypes.push(resetSessionTypeOption);
          }
        }
      }
      // ! After reseting the filter options, loop through the filtered events to push the options that should still be available to selection
      for (const day of Object.keys(filteredEventsByDay)) {
        filteredEventsByDay[day].events.map((event) => {
          // This modifies resetSessionTypes and resetTags
          if (
            (!event.isP2P || getIsPublicFixedFormatSession(event)) &&
            !resetSessionTypes.some(
              (sessionType) => sessionType.name === event.displayName
            )
          ) {
            resetSessionTypes.push({
              category: event.category.displayName,
              name: event.displayName,
            });
          }
          if (event.tags) {
            const newTagsValues = getTypedTags(
              event.tags.filter((tag) => !tag.hideForUsers)
            ).filter(
              (tag) => tag && !getTagsSlugArray(resetTags).includes(tag.slug)
            );
            resetTags.push(...newTagsValues);
            setSessionTagOptions(resetTags);
          }
          if (event.facilitator) {
            setFacilitatorOptions((prev) => {
              if (
                event.facilitator &&
                !prev.some(
                  (facilitator) => facilitator.slug === event.facilitator?.slug
                )
              ) {
                return [...prev, event.facilitator];
              }
              return prev;
            });
          }
        });
      }
      setSessionTypeOptions(resetSessionTypes);
    },
    [
      calendarFilterOptions.sessionTypes,
      calendarFilterOptions.tags,
      selectedFilters.category,
      selectedFilters.sessionType,
      selectedFilters.tag,
      setSessionTypeOptions,
    ]
  );

  return (
    <CalendarFiltersContext.Provider
      value={{
        calendarId,
        clearAllFilters,
        tabFilter,
        facilitators: facilitatorOptions,
        forceView,
        onFilterUpdate,
        resetFilterOptions,
        selectedFilters,
        sessionTagOptions,
        sessionTypeOptions,
        setSessionTypeOptions,
        view,
      }}
    >
      {children}
    </CalendarFiltersContext.Provider>
  );
};

export const useCalendarFilters = () => {
  return useContext(CalendarFiltersContext);
};
