import { createGetEndpoint } from "utils/create-get-endpoint";
import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  DeleteIntentionRequest,
  DeleteIntentionResponse,
  GetPublicIntentionsResponse,
} from "../shared/types";
import { GetLatestUserIntentionResponse } from "../server/fetch-user-latest-intention-handler";
import {
  BoostOrClapIntentionRequest,
  BoostOrClapIntentionResponse,
  CreateIntentionRequest,
  CreateIntentionResponse,
  EditIntentionRequest,
  EditIntentionResponse,
  SetIsCompletedIntentionRequest,
  SetIsCompletedIntentionResponse,
} from "../shared/types";

export const postToCreateIntention = createPostEndpoint<
  CreateIntentionRequest,
  CreateIntentionResponse
>("/api/intentions/create");

export const postToEditIntention = createPostEndpoint<
  EditIntentionRequest,
  EditIntentionResponse
>("/api/intentions/edit");

export const postToDeleteIntention = createPostEndpoint<
  DeleteIntentionRequest,
  DeleteIntentionResponse
>("/api/intentions/delete");

export const getUserLatestIntention = (sessionId?: string) =>
  createGetEndpoint<GetLatestUserIntentionResponse>(
    `/api/intentions/latest/${sessionId || ""}`
  );

export const getAllPublicIntentions = (todayOnly?: boolean) =>
  createGetEndpoint<GetPublicIntentionsResponse>(
    `/api/intentions/public${todayOnly ? "?todayOnly=true" : ""}`
  )();

export const getSessionIntentions = (
  sessionId: string,
  includePrivate: boolean,
  todayOnly?: boolean
) => {
  let queryString = "";

  // Start the query string if any parameter needs to be included
  if (includePrivate || todayOnly !== undefined) {
    queryString += "?";
    const queryParams: string[] = [];

    // Append 'includePrivate' to the query parameters if true
    if (includePrivate) {
      queryParams.push("includePrivate=true");
    }

    // Append 'todayOnly' to the query parameters if provided
    if (todayOnly !== undefined) {
      queryParams.push("todayOnly=true");
    }

    // Join all parameters with '&' and append to the queryString
    queryString += queryParams.join("&");
  }

  return createGetEndpoint<GetPublicIntentionsResponse>(
    `/api/intentions/public/session/${sessionId}${queryString}`
  );
};

type GetUserPublicIntentionsArgs = {
  userExternalId: string;
  sessionId?: string;
  includePrivate?: boolean;
};

export const getUserIntentions = ({
  userExternalId,
  sessionId,
  includePrivate,
}: GetUserPublicIntentionsArgs) =>
  createGetEndpoint<GetPublicIntentionsResponse>(
    `/api/intentions/public/user/${userExternalId}/${sessionId || ""}${
      includePrivate ? "?includePrivate=true" : ""
    }`
  );

export const getOrganisationPublicIntentions = (organisationId: string) =>
  createGetEndpoint<GetPublicIntentionsResponse>(
    `/api/intentions/public/organisation/${organisationId}`
  )();

export const getIntention = (intentionId: string) =>
  createGetEndpoint<GetLatestUserIntentionResponse>(
    `/api/intentions/${intentionId || ""}`
  );

export const postToSetIsCompletedIntention = createPostEndpoint<
  SetIsCompletedIntentionRequest,
  SetIsCompletedIntentionResponse
>("/api/intentions/set-is-completed");

export const postToBoostIntention = createPostEndpoint<
  BoostOrClapIntentionRequest,
  BoostOrClapIntentionResponse
>("/api/intentions/boost");

export const postToClapIntention = createPostEndpoint<
  BoostOrClapIntentionRequest,
  BoostOrClapIntentionResponse
>("/api/intentions/clap");
