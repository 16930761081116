import { BlogTagRecord, TagRecord } from "generated/graphql-typed";
import { assertFieldsNotNull } from "utils/assertions";
import { z } from "zod";
import { OPEN_SESSION_TAG, SHOW_IN_ORG_CALENDAR_TAG } from "./constants";

export type Tag = {
  displayName: string;
  slug: string;
  hideForUsers?: boolean;
};
export const tagsSchema = z.array(
  z.object({
    slug: z.string(),
    displayName: z.string(),
    hideForUsers: z.boolean().optional(),
  })
);

type PossibleDatoTags =
  | Pick<TagRecord, "slug" | "displayName" | "hideForUsers">[]
  | Pick<BlogTagRecord, "slug" | "displayName" | "hideForUsers">[];

export function getTypedTags(tags: PossibleDatoTags): Tag[] {
  return tags.map((tag) => {
    const typedTag = assertFieldsNotNull(tag, ["displayName", "slug"]);
    return typedTag;
  });
}

export const EXTRA_TAGS = [OPEN_SESSION_TAG, SHOW_IN_ORG_CALENDAR_TAG];

export function convertTagToEventTag(tags: PossibleDatoTags): Tag[] {
  return tags.map((tag) => {
    const typedTag = assertFieldsNotNull(tag, [
      "displayName",
      "slug",
      "hideForUsers",
    ]);
    return typedTag;
  });
}

export const getTagsSlugArray = (tags: PossibleDatoTags): string[] =>
  getTypedTags(tags).map((tag) => tag.slug);

export const getTagsDisplayNameArray = (tags: PossibleDatoTags): string[] =>
  getTypedTags(tags).map((tag) => tag.displayName);
