import { createGetEndpoint } from "utils/create-get-endpoint";
import { WorkedOrError } from "shared/response.types";
import { createPostEndpoint } from "utils/create-post-endpoint";
import { FollowUserRequest } from "../shared/follow.types";
import {
  GetProfileTagsHandlerResponse,
  UpdateMemberProfileRequest,
  UpdateMemberProfileResponse,
  GetUserProfileDataResponse,
  GetFollowSuggestionsResponse,
  SearchUserResponse,
  SearchUserRequest,
  GetUserConnectionsDataResponse,
  CheckVanityUrlAvailabilityRequest,
  CheckVanityUrlAvailabilityResponse,
} from "../shared/profile.types";

export const postToUpdateMemberProfile = createPostEndpoint<
  UpdateMemberProfileRequest,
  UpdateMemberProfileResponse
>(`/api/updateMemberProfile`);

export const getProfileTags =
  createGetEndpoint<GetProfileTagsHandlerResponse>("/api/profile/tags");

export const postToCreateFollowUser = createPostEndpoint<
  FollowUserRequest,
  WorkedOrError
>("/api/follow/create");

export const postToUnfollowUser = createPostEndpoint<
  FollowUserRequest,
  WorkedOrError
>("/api/follow/delete");

export const getUserProfileData = (externalId: string) =>
  createGetEndpoint<GetUserProfileDataResponse>(
    `/api/profile/${externalId || ""}`
  );

export const getFollowSuggestions = (organisationId?: string) =>
  createGetEndpoint<GetFollowSuggestionsResponse>(
    `/api/follow/suggestions${organisationId ? "/" + organisationId : ""}`
  )();

export const postToSearchUsers = createPostEndpoint<
  SearchUserRequest,
  SearchUserResponse
>(`/api/profile/search`);

export const getUserConnectionsData = (externalId: string) =>
  createGetEndpoint<GetUserConnectionsDataResponse>(
    `/api/profile/connections/${externalId || ""}`
  )();

export const postToCheckVanityUrlAvailability = createPostEndpoint<
  CheckVanityUrlAvailabilityRequest,
  CheckVanityUrlAvailabilityResponse
>("/api/profile/check-vanity-url-availability");
