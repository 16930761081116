import { Box, Text } from "design-system/components";
import React, { useEffect, useMemo } from "react";
import { UserIntentionCard } from "./user-intention-card";
import { PublicIntentionEntry } from "features/accountability/shared";
import styled from "@emotion/styled";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { DROP_IN_ID } from "utils/constants";
import { useUserContext } from "context/user-context";
import { VISIBILITY_OPTIONS } from "../../shared/types";
import { useVideoCall } from "features/sessions/client";

type AccountabilityWallProps = {
  intentions: PublicIntentionEntry[];
  isLoading: boolean;
  isInPrivateSession?: boolean;
  isInOrgSession?: boolean;
  errorMessage: string;
  onUserIntentionCardClick?: (userExternalId: string) => void;
  hideUsernameAndAvatarOnIntentionCard?: boolean;
  sessionId?: string;
} & Pick<DSComponentPropsProvider, "style" | "className">;

export const AccountabilityWall: React.FC<AccountabilityWallProps> = ({
  intentions,
  isLoading,
  isInPrivateSession,
  isInOrgSession,
  errorMessage,
  onUserIntentionCardClick,
  hideUsernameAndAvatarOnIntentionCard,
  sessionId,
  ...props
}) => {
  const { organisation } = useUserContext();
  const shouldShowSessionVisibility = !!(sessionId && sessionId !== DROP_IN_ID);
  const shouldShowPublicVisibility = !isInPrivateSession && !isInOrgSession;
  const shouldShowOrgVisibility = !!organisation && !isInPrivateSession;

  const visibilityOptions = useMemo(
    () =>
      VISIBILITY_OPTIONS.filter((visibility) => {
        if (visibility === "session") {
          return shouldShowSessionVisibility;
        }
        if (visibility === "public") {
          return shouldShowPublicVisibility;
        }
        if (visibility === "organisation") {
          return shouldShowOrgVisibility;
        }
        return true;
      }),
    [
      shouldShowOrgVisibility,
      shouldShowPublicVisibility,
      shouldShowSessionVisibility,
    ]
  );

  return (
    <AccountabilityWallWrapper {...props}>
      {isLoading ? (
        <Box className="flex-col gap-32">
          <div className="skeleton" style={{ width: "70%" }} />
          <SkeletonIntentionEntry />
          <SkeletonIntentionEntry />
          <SkeletonIntentionEntry />
          <SkeletonIntentionEntry />
        </Box>
      ) : (
        <>
          <PublicAccountabilityWall>
            {intentions.length ? (
              intentions.map((intention, index) => (
                <Box
                  key={intention._id.toString()}
                  borderBottom="solid 1px token(colors.grey100)"
                >
                  <UserIntentionCard
                    {...intention}
                    isLatestIntention={index === 0}
                    isProfileCard={hideUsernameAndAvatarOnIntentionCard}
                    onUserClick={onUserIntentionCardClick}
                    visibilityOptions={visibilityOptions}
                  />
                </Box>
              ))
            ) : (
              <Box className="flex-col gap-12">
                <Text>{errorMessage ? errorMessage : "No intentions set"}</Text>
              </Box>
            )}
          </PublicAccountabilityWall>
        </>
      )}
    </AccountabilityWallWrapper>
  );
};

const AccountabilityWallWrapper = styled.div`
  position: relative;
  overflow-y: auto;
`;

const PublicAccountabilityWall = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const SkeletonIntentionEntry = () => (
  <Box>
    <Box
      className="gap-12"
      style={{ display: "grid", gridTemplateColumns: "45px 1fr" }}
    >
      <div
        className="skeleton b-radius-round"
        style={{ height: "45px", width: "45px", borderRadius: "9999px" }}
      />
      <Box className="flex-col gap-8 w-full">
        <div className="skeleton" style={{ height: "16px", width: "100px" }} />
        <div className="skeleton" style={{ height: "16px", width: "150px" }} />
      </Box>
    </Box>
  </Box>
);

export const AccountabilityWallVideoCall: React.FC<AccountabilityWallProps> = (
  props
) => {
  const { propagatedIntentions = [], setPropagatedIntentions } = useVideoCall();

  useEffect(() => {
    if (propagatedIntentions.length > 0) {
      setPropagatedIntentions([]);
    }
    // We only want this to run if the array of intentions changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.intentions]);

  return (
    <AccountabilityWall
      {...props}
      intentions={[...propagatedIntentions, ...props.intentions]}
    />
  );
};
