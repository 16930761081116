"use client";
import { useUserContext } from "context/user-context";
import { useEffect, useState } from "react";
import { ProfileTag, UserProfileFromActivity } from "shared/user.types";
import { FlownEvent } from "shared/event.types";
import { postToGetUsersRegisteredForSession } from "../calendar-client";

type UseGetRegisteredUsersForSessionArgs = {
  selectedEvent: FlownEvent | null;
};

export const useGetRegisteredUsersForSession = ({
  selectedEvent,
}: UseGetRegisteredUsersForSessionArgs) => {
  const { loggedIn } = useUserContext();
  const [usersRegisteredForSession, setUsersRegisteredForSession] = useState<
    UserProfileFromActivity[]
  >([]);
  const [connectionsRegisteredForSession, setConnectionsRegisteredForSession] =
    useState<UserProfileFromActivity[]>([]);
  const [commonTags, setCommonTags] = useState<ProfileTag[]>([]);

  useEffect(() => {
    const fetchUsersRegisteredForSession = async () => {
      if (!selectedEvent) return null;
      const response = await postToGetUsersRegisteredForSession({
        eventId: selectedEvent.id,
        excludeSelf: true,
      });
      if (response.worked) {
        setUsersRegisteredForSession(response.usersRegistered);
        setConnectionsRegisteredForSession(response.connections);
        setCommonTags(response.commonTags);
      }
    };
    if (loggedIn) {
      void fetchUsersRegisteredForSession();
    }
  }, [selectedEvent, usersRegisteredForSession.length, loggedIn]);

  return {
    usersRegisteredForSession,
    connectionsRegisteredForSession,
    commonTags,
  };
};
