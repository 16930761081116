import { LogicTimezoneBlockFragment } from "generated/graphql-typed";
import { useEffect, useState } from "react";
import { useUserTimezone } from "utils/component-utils";
import { renderer } from "./renderer";
import styled from "@emotion/styled";

type Timezones = "America" | "UK" | "Europe";

export const LogicTimezoneView = (
  props: LogicTimezoneBlockFragment & { className: string }
) => {
  const { userTimezone } = useUserTimezone();
  const [showNextBlock, setShowNextBlock] = useState(props.timezone === "UK");

  const showOnTimezone = (props?.timezone || "UK") as Timezones;

  useEffect(() => {
    let shouldShowNextBlock = false;

    if (showOnTimezone === "UK" && userTimezone.includes("Europe/London")) {
      shouldShowNextBlock = true;
    } else if (
      userTimezone.includes("America") &&
      showOnTimezone === "America"
    ) {
      shouldShowNextBlock = true;
    } else if (
      userTimezone.includes("Europe") &&
      !userTimezone.includes("Europe/London") &&
      showOnTimezone === "Europe"
    ) {
      shouldShowNextBlock = true;
    } else if (
      // UK is fallback - so if we dont match either america or europe timezones
      // and we aren't showing either of those options then set to be true
      showOnTimezone === "UK" &&
      !userTimezone.includes("Europe") &&
      !userTimezone.includes("America")
    ) {
      shouldShowNextBlock = true;
    }
    setShowNextBlock(shouldShowNextBlock);
  }, [userTimezone, showOnTimezone]);

  return (
    <LogicTimezoneDiv
      className={showNextBlock ? "" : "hide-following-block"}
    ></LogicTimezoneDiv>
  );
};

export const LogicTimezoneRenderer = renderer({
  typeName: "LogicTimezoneRecord",
  view: LogicTimezoneView,
});

const LogicTimezoneDiv = styled.div`
  margin-top: 0 !important; // this is to override some global styles for blocks
  margin-bottom: 0 !important;
  &.hide-following-block {
    display: none;
  }
  &.hide-following-block + * {
    display: none;
  }
`;
