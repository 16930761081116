import { Box, Button, Text, useToast } from "design-system/components";
import { FlownEvent } from "shared/event.types";
import { useUserContext } from "context/user-context";
import { SearchUsersListInput } from "design-system/organisms/search-users-list-input";
import { InputField } from "design-system/molecules/input-field";
import { useMemo, useState } from "react";
import { isValidEmail } from "utils/assertions";
import { postToInviteToSession } from "features/sessions/client";
import { UserProfileFromActivityNoId } from "shared/user.types";
import { SocialMediaShareButtons } from "design-system/molecules/social-media/social-media-share-buttons";
import { getURLWithReferralCode } from "utils/urls";
import { useSessionInvites } from "features/sessions/client";
import { analytics, catchAnalyticsError } from "features/analytics";
import { SESSION_SOURCE } from "utils/constants";
import { getUserDisplayName } from "features/profile/shared";

type InviteMembersSidePanelProps = {
  event: FlownEvent;
};

export const InviteMembersSidePanel: React.FC<InviteMembersSidePanelProps> = ({
  event,
}) => {
  const { followers, referralCode, externalId, displayName } = useUserContext();
  const hostDisplayName = event.populatedHost
    ? getUserDisplayName(event.populatedHost)
    : "";

  const { toast, Toast } = useToast();
  const [emailState, setEmailState] = useState({
    email: "",
    error: "",
  });

  const [sessionInvitesState, setSessionInvitesState] = useSessionInvites(
    event.id
  );
  const { sessionInvites } = sessionInvitesState;

  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const onSendEmailInvite = async () => {
    const { email } = emailState;
    setIsSendingInvite(true);
    if (sessionInvites.find((invite) => invite.payload?.to === email)) {
      setEmailState({ email, error: "Invite already sent to this email" });
      setIsSendingInvite(false);
      return;
    }
    if (!email || !isValidEmail(email)) {
      setEmailState({ email, error: "Please enter a valid email" });
      setIsSendingInvite(false);
      return;
    }
    await postToInviteToSession({
      email,
      eventId: event.id,
      source: SESSION_SOURCE.COMMUNITY_EMAIL_INVITE,
      host: event.populatedHost
        ? { ...event.populatedHost, displayName: hostDisplayName }
        : undefined,
    });
    toast("success", `Invite sent successfully to ${email}`);
    setIsSendingInvite(false);
    // Re-fetch invites
    setSessionInvitesState((prev) => ({
      ...prev,
      status: "idle",
    }));
    void analytics
      .track("Session Invite Sent", {
        hostId: externalId,
        hostName: displayName,
        email,
        to: "external",
        eventId: event.id,
        source: SESSION_SOURCE.COMMUNITY_MEMBER_INVITE,
      })
      .catch(catchAnalyticsError);
  };

  const shareUrl = getURLWithReferralCode(
    event.springBoardGenericFullUrl,
    referralCode
  );

  const onSendMemberInvite = async (user: UserProfileFromActivityNoId) => {
    const { externalId: invitedExternalId } = user;
    await postToInviteToSession({
      externalId: invitedExternalId,
      eventId: event.id,
      source: SESSION_SOURCE.COMMUNITY_MEMBER_INVITE,
      host: event.populatedHost
        ? { ...event.populatedHost, displayName: hostDisplayName }
        : undefined,
    });
    toast("success", `Invite sent successfully to ${getUserDisplayName(user)}`);
    // Re-fetch invites
    setSessionInvitesState((prev) => ({
      ...prev,
      status: "idle",
    }));
    // Add referral code to url and do this on the description in the emails
    void analytics
      .track("Session Invite Sent", {
        hostId: externalId,
        hostName: displayName,
        email: invitedExternalId,
        to: "member",
        eventId: event.id,
        source: SESSION_SOURCE.COMMUNITY_MEMBER_INVITE,
      })
      .catch(catchAnalyticsError);
  };

  const followersWithInvites = useMemo(
    () =>
      followers.map((follower) => ({
        ...follower,
        isInvited: !!sessionInvites.find(
          (invite) => invite.payload?.toExternalId === follower.externalId
        ),
      })),
    [followers, sessionInvites]
  );

  return (
    <>
      {Toast}
      <Box className="flex-col gap-16">
        <Text fontSize="xl" fontWeight={700}>
          Share the link to your session:
        </Text>
        <SocialMediaShareButtons shareUrl={shareUrl} />
        <Text fontSize="xl" fontWeight={700}>
          Invite by email:
        </Text>
        <Box className="flex-col gap-8 align-end">
          <InputField
            className="flex-1"
            label="Email:"
            id="email-invite"
            placeholder={"e.g hello@flown.com"}
            value={emailState.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const email = event.target.value;
              setEmailState({
                error: "",
                email,
              });
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                void onSendEmailInvite();
              }
            }}
            errorMessage={emailState.error}
          />
          <Button
            className="min-w-100"
            size={"small"}
            isLoading={isSendingInvite}
            onClick={onSendEmailInvite}
          >
            Send invite
          </Button>
        </Box>
        {followersWithInvites.length > 0 ? (
          <Box className="flex-col gap-8">
            <Text fontSize="xl" fontWeight={700}>
              Invite your followers:
            </Text>
            <SearchUsersListInput
              showCta
              userList={followersWithInvites}
              onCtaClick={onSendMemberInvite}
              ctaTextClicked="Invited"
              ctaText="Invite"
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
};
