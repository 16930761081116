"use client";
import { Row, Spinner } from "design-system/components";
import React, { useEffect, useState } from "react";
import { postToComputeStreakBadges } from "../badges-client";
import { useMilestoneBadges } from "../hooks/useComputeUserBadges";
import { BadgeList } from "./badge-list";
import { BadgeProgress } from "./badge-progress";
import { StreakBadgeStats } from "../../shared";
import dayjs from "dayjs";

type MilestoneBadgesContainerProps = {
  /**
   * @param latestCompletedOnly If true, only the latest, completed badge will be shown.
   */
  latestCompletedOnly?: boolean;
  userId?: string;
  badgeDimensions?: number;
  type?: "list" | "progress";
  fetchCurrentStreak?: boolean;
  showTimeBoundBadges?: boolean;
};

/**
 * This component can be dropped in anywhere in the app and will be in charge of
 * loading the appropriate badges for the user and rendering them. It will also
 * handle errors.
 */
export const MilestoneBadgesContainer: React.FC<
  MilestoneBadgesContainerProps
> = ({
  latestCompletedOnly,
  userId,
  badgeDimensions,
  type = "list",
  fetchCurrentStreak = false,
  showTimeBoundBadges = false,
}) => {
  const [streak, setStreak] = useState<StreakBadgeStats>({
    liveStreak: 0,
    sessionAttendedInCurrentWeek: false,
    stats: {},
    maxStreak: 0,
  });
  const {
    status,
    latestCompletedBadge,
    badges,
    nextBadge,
    totalSessions,
    timeBoundBadges,
  } = useMilestoneBadges(userId);

  const activeChallenges = timeBoundBadges.filter(
    (badge) =>
      badge.toDate && dayjs(badge.toDate).diff(dayjs()) > 0 && !badge.completed
  );

  // Old badges filtered by completed, then sorted by date and number of sessions
  const timeBoundCompletedChallenges = timeBoundBadges
    .filter((badge) => badge.completed)
    // .filter((badge) => badge.toDate && dayjs(badge.toDate).diff(dayjs()) < 0)
    .sort((a, b) => a.totalSessions - b.totalSessions)
    .sort((a, b) =>
      a.toDate && b.toDate ? dayjs(a.toDate).diff(dayjs(b.toDate)) : 0
    );

  useEffect(() => {
    const fetchStreak = async () => {
      const response = await postToComputeStreakBadges();
      if (response.worked) {
        setStreak(response.userStreak);
      }
    };
    if (fetchCurrentStreak) {
      void fetchStreak();
    }
  }, [fetchCurrentStreak]);

  return (
    <>
      {status === "loading" ? (
        type === "progress" ? (
          <div className="flex-col gap-16">
            <Row>
              <div
                className="skeleton"
                style={{
                  borderRadius: "9999px",
                  height: "60px",
                  width: "60px",
                }}
              />
              <div
                className="skeleton b-radius-6"
                style={{ flexGrow: 1, height: "4px", minHeight: "unset" }}
              />
              <div
                className="skeleton"
                style={{
                  borderRadius: "9999px",
                  height: "60px",
                  width: "60px",
                }}
              />
            </Row>
            <div
              className="skeleton"
              style={{
                width: "80px",
              }}
            />
            <div
              className="skeleton"
              style={{
                width: "100%",
              }}
            />
          </div>
        ) : (
          <div>
            <Spinner color="black" />
          </div>
        )
      ) : status === "rejected" ? null : status === "resolved" ? (
        type === "list" ? (
          <>
            {showTimeBoundBadges ? (
              <>
                {activeChallenges.length > 0 && (
                  <>
                    Active challenges
                    <BadgeList
                      badgeDimensions={badgeDimensions}
                      badges={activeChallenges}
                      showBadgeMetadata
                    />
                  </>
                )}
                {timeBoundCompletedChallenges.length > 0 && (
                  <>
                    Completed challenges
                    <BadgeList
                      badgeDimensions={badgeDimensions}
                      badges={timeBoundCompletedChallenges}
                      showBadgeMetadata
                    />
                  </>
                )}
              </>
            ) : (
              <BadgeList
                badgeDimensions={badgeDimensions}
                badges={
                  latestCompletedOnly && latestCompletedBadge
                    ? [latestCompletedBadge]
                    : badges.filter((badge) => badge.completed)
                }
              />
            )}
          </>
        ) : (
          latestCompletedBadge &&
          nextBadge && (
            <BadgeProgress
              currentBadge={latestCompletedBadge}
              nextBadge={nextBadge}
              currentProgress={totalSessions}
              currentStreak={streak.liveStreak}
              sessionAttendedInCurrentWeek={streak.sessionAttendedInCurrentWeek}
            />
          )
        )
      ) : null}
    </>
  );
};
