export const createGetEndpoint = <R>(url: string, headers = {}) => {
  const endpoint = async (): Promise<R> => {
    const response = await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    });
    if (!response.ok) {
      throw new Error(
        `Failed to fetch "${url}" (${response.status}/${response.statusText})`
      );
    }
    return await response.json();
  };
  return endpoint;
};
