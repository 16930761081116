import styled from "@emotion/styled";
import { ModularContent } from "features/modular-content";
import { FC } from "react";
import { NewQuoteblockProps } from "../shared/pillars-and-blog-types";
import { ArrowAdornment, CurvedAdornment } from "./adornments";

export const NewQuoteblock: FC<NewQuoteblockProps> = ({
  quote,
  quoteSize,
  author,
  backgroundColor,
  squiggles,
}) => {
  return (
    <QuoteblockContainer backgroundColor={backgroundColor?.hex}>
      {squiggles && (
        <AdornmentContainer>
          <ArrowAdornment />
          <CurvedAdornment />
        </AdornmentContainer>
      )}
      <ModularContent data={quote} paragraphSize={quoteSize} />
      {author && <Author>- {author}</Author>}
    </QuoteblockContainer>
  );
};

const Author = styled.span``;

const AdornmentContainer = styled.div`
  max-width: 28px;
`;

const QuoteblockContainer = styled.div<{ backgroundColor?: string }>`
  position: relative;
  max-width: 800px;
  background-color: ${(props) => `${props.backgroundColor}`};
  padding: ${({ theme }) => theme.spacing[32]}
    ${({ theme }) => theme.spacing[64]};
  font-family: ${({ theme }) => theme.fontFamilies.centuryOldStyleStd};
`;
