import { getConfig } from "services/config";
import {
  SubscriptionMembershipType,
  SUBSCRIPTION_TYPES,
} from "shared/subscription.types";
import { isProductionMode } from "utils/environment";

export const REFERRAL_DISCOUNT_PERCENTAGE = 25;
export const STRIPE_REFERRAL_COUPON_ID = "REFERRAL_COUPON";

export const CURRENCY_SYMBOLS = {
  usd: "$",
  eur: "€",
  gbp: "£",
} as const;

/**
 * This is a map of add-ons to their product and coupon.
 * The coupon is the coupon that will be applied to the add-on and we will
 * charge the user immediately when used during the trial.
 *
 * This map is an essential part of how bundles and add-ons are powered on the platform.
 * List of products in use:
 * -  Feel Good Focus course
 *  - DEV: https://dashboard.stripe.com/test/products/prod_RsiAnziHu0F46b
 *  - PROD: https://dashboard.stripe.com/products/prod_RyGCaoJpizaFRW
 *
 * If users use the following coupons, we charge them immediately:
 *  - DEV: https://dashboard.stripe.com/test/coupons/GogV5nAr
 *  - PROD: https://dashboard.stripe.com/coupons/TODO
 */
export const ADD_ON_MAP = {
  "feel-good-focus": {
    product: isProductionMode() ? "prod_RyGCaoJpizaFRW" : "prod_RsiAnziHu0F46b",
    coupon: isProductionMode() ? "2Lynm4HL" : "Yjf9Hroi",
  },
} as const;

export const isFGFPromo = (coupon: string) => {
  return ADD_ON_MAP["feel-good-focus"].coupon === coupon;
};

export const isFirstWeekCoupon = (coupon: string) =>
  isProductionMode() ? coupon === "SizNw9P8" : coupon === "QbyWuXIj";

export type AddOnProductId = keyof typeof ADD_ON_MAP;

export type StripeCurrency = keyof typeof CURRENCY_SYMBOLS;

/**
 * Stripe products dashboard: https://dashboard.stripe.com/products
 */
export const SUBSCRIPTION_PRICE_IDS = {
  GBP_LIFETIME: getConfig("STRIPE_MEMBERSHIP_GBP_LIFETIME_PRICE_ID"),
  GBP_MONTHLY: getConfig("STRIPE_MEMBERSHIP_GBP_MONTHLY_PRICE"),
  GBP_YEARLY: getConfig("STRIPE_MEMBERSHIP_GBP_YEARLY_PRICE"),
  EUR_LIFETIME: getConfig("STRIPE_MEMBERSHIP_EUR_LIFETIME_PRICE_ID"),
  EUR_YEARLY: getConfig("STRIPE_MEMBERSHIP_EUR_YEARLY_PRICE"),
  EUR_MONTHLY: getConfig("STRIPE_MEMBERSHIP_EUR_MONTHLY_PRICE"),
  USD_LIFETIME: getConfig("STRIPE_MEMBERSHIP_USD_LIFETIME_PRICE_ID"),
  USD_YEARLY: getConfig("STRIPE_MEMBERSHIP_USD_YEARLY_PRICE"),
  USD_MONTHLY: getConfig("STRIPE_MEMBERSHIP_USD_MONTHLY_PRICE"),
  QUARTERLY: process.env.NEXT_PUBLIC_STRIPE_MEMBERSHIP_QUARTERLY || "",
} as const;

export type SubscriptionMembershipPriceId =
  typeof SUBSCRIPTION_PRICE_IDS[keyof typeof SUBSCRIPTION_PRICE_IDS];

export const PRICE_ID_MAP = {
  [SUBSCRIPTION_PRICE_IDS.GBP_MONTHLY]: {
    billingFrequency: "monthly",
    currency: "gbp",
    currencySymbol: CURRENCY_SYMBOLS["gbp"],
    type: SUBSCRIPTION_TYPES.MONTHLY,
    upgradeableTo: SUBSCRIPTION_PRICE_IDS.GBP_YEARLY,
    lineItemDescription: "1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.GBP_YEARLY]: {
    billingFrequency: "annually",
    type: SUBSCRIPTION_TYPES.YEARLY,
    currency: "gbp",
    currencySymbol: CURRENCY_SYMBOLS["gbp"],
    upgradeableTo: SUBSCRIPTION_PRICE_IDS.GBP_LIFETIME,
    lineItemDescription: "12 x 1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.GBP_LIFETIME]: {
    billingFrequency: "once",
    type: SUBSCRIPTION_TYPES.LIFETIME,
    currency: "gbp",
    currencySymbol: CURRENCY_SYMBOLS["gbp"],
    lineItemDescription: "Lifetime FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.QUARTERLY]: {
    billingFrequency: "quarterly",
    currency: "gbp",
    currencySymbol: CURRENCY_SYMBOLS["gbp"],
    type: SUBSCRIPTION_TYPES.QUARTERLY,
    lineItemDescription: "3 x 1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.EUR_MONTHLY]: {
    billingFrequency: "monthly",
    type: SUBSCRIPTION_TYPES.MONTHLY,
    currency: "eur",
    currencySymbol: CURRENCY_SYMBOLS["eur"],
    upgradeableTo: SUBSCRIPTION_PRICE_IDS.EUR_YEARLY,
    lineItemDescription: "1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.EUR_YEARLY]: {
    billingFrequency: "annually",
    type: SUBSCRIPTION_TYPES.YEARLY,
    currency: "eur",
    currencySymbol: CURRENCY_SYMBOLS["eur"],
    upgradeableTo: SUBSCRIPTION_PRICE_IDS.EUR_LIFETIME,
    lineItemDescription: "12 x 1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.EUR_LIFETIME]: {
    billingFrequency: "once",
    type: SUBSCRIPTION_TYPES.LIFETIME,
    currency: "eur",
    currencySymbol: CURRENCY_SYMBOLS["eur"],
    lineItemDescription: "Lifetime FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.USD_MONTHLY]: {
    billingFrequency: "monthly",
    type: SUBSCRIPTION_TYPES.MONTHLY,
    currency: "usd",
    currencySymbol: CURRENCY_SYMBOLS["usd"],
    upgradeableTo: SUBSCRIPTION_PRICE_IDS.USD_YEARLY,
    lineItemDescription: "1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.USD_YEARLY]: {
    billingFrequency: "annually",
    type: SUBSCRIPTION_TYPES.YEARLY,
    currency: "usd",
    currencySymbol: CURRENCY_SYMBOLS["usd"],
    upgradeableTo: SUBSCRIPTION_PRICE_IDS.USD_LIFETIME,
    lineItemDescription: "12 x 1 month FLOWN Membership",
  },
  [SUBSCRIPTION_PRICE_IDS.USD_LIFETIME]: {
    billingFrequency: "once",
    type: SUBSCRIPTION_TYPES.LIFETIME,
    currency: "usd",
    currencySymbol: CURRENCY_SYMBOLS["usd"],
    lineItemDescription: "Lifetime FLOWN Membership",
  },
} as const;

export const isValidSubscriptionType = (subscriptionType: string) =>
  Object.keys(SUBSCRIPTION_TYPES).includes(subscriptionType.toUpperCase());

export const isManageableSubscription = (
  subscriptionType: SubscriptionMembershipType
) =>
  getIsMonthlySubscription(subscriptionType) ||
  getIsQuarterlySubscription(subscriptionType) ||
  getIsYearlySubscription(subscriptionType);

export const getIsYearlySubscription = (plan: SubscriptionMembershipType) =>
  plan === SUBSCRIPTION_TYPES.YEARLY;

const getIsQuarterlySubscription = (plan: SubscriptionMembershipType) =>
  plan === SUBSCRIPTION_TYPES.QUARTERLY;

const getIsMonthlySubscription = (plan: SubscriptionMembershipType) =>
  plan === SUBSCRIPTION_TYPES.MONTHLY;

export const BILLING_PERIOD_DICT = {
  monthly: "month",
  annually: "year",
  once: "once",
} as const;
