import { useUserContext } from "context/user-context";
import { Icon } from "design-system/components";
import React, { useRef } from "react";
import { useProductFruitsApi } from "react-product-fruits";
import { styled } from "styled-system/jsx";

type ProductFruitsInAppCenterProps = {
  className?: string;
};

export const ProductFruitsInAppCenter: React.FC<
  ProductFruitsInAppCenterProps
> = ({ className = "" }) => {
  const { externalId } = useUserContext();
  const containerEl = useRef<HTMLDivElement>(null);

  useProductFruitsApi(
    (api) => {
      if (externalId && containerEl.current) {
        api.inAppCenter.attachToCustomElement(containerEl.current);
      }
    },
    [externalId]
  );

  return (
    <InAppCenterContainer
      className={className}
      ref={containerEl}
      id="pf-life-ring"
      data-cy="pf-life-ring"
    >
      <Icon icon="info" size={20} />
    </InAppCenterContainer>
  );
};

const InAppCenterContainer = styled("div", {
  base: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "16",
    margin: "16",
    borderRadius: "round",
    cursor: "pointer",
  },
});
