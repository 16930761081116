"use client";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getQueryParam } from "utils/url-query-utils";

export type CalendarView = "agenda" | "list" | "only-event-summary";

export const CALENDAR_VIEWS: Record<string, CalendarView> = {
  AGENDA: "agenda",
  LIST: "list",
  ONLY_EVENT_SUMMARY: "only-event-summary",
};

const DEFAULT_VIEW: CalendarView = "agenda";

type UseCalendarViewArgs = {
  forceView?: CalendarView;
};

export const useCalendarView = ({ forceView }: UseCalendarViewArgs) => {
  const [view, setView] = useState<CalendarView>(forceView || DEFAULT_VIEW);
  const router = useRouter();

  useEffect(() => {
    const viewFromUrl: CalendarView = getQueryParam(
      router.query.view
    ) as CalendarView;

    // If a view is forced, we don't want to change it so we simply return that.
    if (forceView) {
      setView(forceView);
      return;
    }

    /**
     * If a view is set in the URL, it will take precedence over
     * local storage.
     */
    if (viewFromUrl) {
      setView(viewFromUrl);
      return;
    }
  }, [router.query.view, forceView]);

  return { view };
};
