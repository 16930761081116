import styled from "@emotion/styled";
import { IllustrationAndContentBlockFragment } from "generated/graphql-typed";
import Link from "next/link";
import { renderer } from "./renderer";
import { ModularContent } from "./modular-content";
import { MediaViewer } from "design-system/molecules/media-viewer";
import { CSS_CLASSES } from "design-system/styles/global-styles";

const IllustrationAndContentView = (
  props: IllustrationAndContentBlockFragment & { className: string }
) => {
  const IllustrationContent = props.image?.url ? (
    <IllustrationAndContentMediaViewer
      alt={props.image.alt || "Illustration content"}
      url={props.image.url}
      showVideoControls={props.showVideoControls}
      thumbnailUrl={props.thumbnail?.url}
    />
  ) : null;

  return (
    <>
      <CardWrapper reverse={props.reverse} className={props.className}>
        {props.illustrationLink ? (
          props.illustrationLink.startsWith("http") ? (
            <a href={props.illustrationLink}>{IllustrationContent}</a>
          ) : (
            <Link href={props.illustrationLink}>{IllustrationContent}</Link>
          )
        ) : (
          IllustrationContent
        )}
        <ModularContent
          data={props.content}
          paragraphSize={props.paragraphSize}
          wrapper={TextContainer}
          wrapperClassName={CSS_CLASSES.NO_MARGIN}
        />
      </CardWrapper>
    </>
  );
};

export const IllustrationAndContentRenderer = renderer({
  typeName: "IllustrationAndContentRecord",
  view: IllustrationAndContentView,
});

const CardWrapper = styled.div<{ reverse: boolean }>`
  margin: 20px auto;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  @media only screen and (min-width: 1000px) {
    flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
    max-width: 100%;
  }
`;
const TextContainer = styled.div`
  max-width: 511px;
  padding: 20px;
  text-align: center;
  @media only screen and (min-width: 1000px) {
    text-align: left;
  }
`;

const IllustrationAndContentMediaViewer = styled(MediaViewer)`
  width: 100%;
  max-width: 500px;
  margin-bottom: 24px;
  @media only screen and (min-width: 1000px) {
    margin-right: 48px;
    margin-bottom: 0;
  }
  object-fit: scale-down;
  background-color: white;
  border: 1px solid #e2dee3;
  border-radius: 8px;
  max-height: 300px;
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.05);
`;
