"use client";
import styled from "@emotion/styled";

type SpinnerProps = {
  color?: string;
  size?: number;
  margin?: number;
  style?: React.CSSProperties;
};

export const Spinner: React.FC<SpinnerProps> = ({
  color = "#fff",
  size = 30,
  margin = 8,
  style = {},
}) => (
  <SpinnerBase
    style={style}
    color={color}
    size={size}
    margin={margin}
    aria-label="loading"
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </SpinnerBase>
);

const SpinnerBase = styled.div<SpinnerProps>`
  box-sizing: border-box;
  display: block;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin: ${(props) => `${props.margin}px`};
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) =>
    `${props.color} transparent transparent transparent`};
  .lds-ring div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
