"use client";
// https://www.radix-ui.com/docs/primitives/components/toast
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Close, Root, Viewport } from "@radix-ui/react-toast";
import { useState, useRef, ReactNode, useCallback, useMemo } from "react";
import { Text } from "./text";

export const useToast = () => {
  const [open, setOpen] = useState(false);
  const [toastVariant, setToastVariant] =
    useState<ToastProps["variant"]>("success");
  const [toastContent, setToastContent] = useState<ReactNode>(null);
  const [toastDuration, setToastDuration] = useState<number>(3500);
  const timerRef = useRef(0);

  const toast = useCallback(
    (toastType: ToastProps["variant"], content: ReactNode, duration = 3500) => {
      setOpen(false);
      setToastVariant(toastType);
      setToastContent(content);
      setToastDuration(duration);
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setOpen(true);
      }, 100);
    },
    []
  );

  return {
    Toast: useMemo(
      () => (
        <Toast
          open={open}
          onOpenChange={setOpen}
          toastContent={toastContent}
          variant={toastVariant}
          duration={toastDuration}
        />
      ),
      [open, toastContent, toastVariant, toastDuration]
    ),
    toast,
  };
};

type ToastVariant = "info" | "success" | "error" | "warning";

type ToastProps = React.ComponentProps<typeof StyledToast> & {
  toastContent: ReactNode;
  /**
   * @prop {boolean} open - This prop should be passed from the `useToast` hook along with `onOpenChange`
   */
  open: boolean;
  /**
   * @prop {boolean} open - This prop should be passed from the `onOpenChange` hook along with `open`
   */
  onOpenChange: (open: boolean) => void;
  showDismiss?: boolean;
  variant?: ToastVariant;
};

export const Toast = ({
  toastContent,
  showDismiss = true,
  variant = "info",
  ...props
}: ToastProps) => {
  return (
    <>
      <StyledToast className={variant} {...props}>
        {toastContent}
        {showDismiss && (
          <Close style={{ color: "inherit" }}>
            <Text fontSize="sm" fontWeight={700}>
              Dismiss
            </Text>{" "}
          </Close>
        )}
      </StyledToast>
    </>
  );
};

const VIEWPORT_PADDING = 25;

export const ToastViewport = styled(Viewport)({
  position: "fixed",
  bottom: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: 2147483647,
  outline: "none",
});

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: "translateX(0)" },
});

const swipeOut = keyframes({
  from: { transform: "translateX(var(--radix-toast-swipe-end-x))" },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const StyledToast = styled(Root)(({ theme }) => ({
  backgroundColor: theme.colors.grey100,
  borderRadius: theme.radii[16],
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  padding: theme.spacing[20],
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing[16],
  alignItems: "center",

  "&.info": {
    backgroundColor: theme.colors.infoLight,
    color: theme.colors.info,
  },

  "&.success": {
    backgroundColor: theme.colors.successLight,
    color: theme.colors.success,
  },

  "&.error": {
    backgroundColor: theme.colors.errorLight,
    color: theme.colors.error,
  },

  "&.warning": {
    backgroundColor: theme.colors.warningLight,
    color: theme.colors.warning,
  },

  "@media (prefers-reduced-motion: no-preference)": {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: "translateX(var(--radix-toast-swipe-move-x))",
    },
    '&[data-swipe="cancel"]': {
      transform: "translateX(0)",
      transition: "transform 200ms ease-out",
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
}));
