import styled from "@emotion/styled";
import { ModularContent } from "features/modular-content";
import { Box, Pill } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { FC, useEffect, useState } from "react";
import {
  BlogIntroContent,
  PillarAndBlogContentProps,
} from "../shared/pillars-and-blog-types";
import { Text } from "design-system/components/text";
import { Avatar } from "design-system/molecules/avatar";
import Link from "next/link";
import { BLOG_TAGS_URL, BLOG_URL } from "utils/urls";
import { useIsMediaDesktop } from "utils/component-utils";
import { SocialMediaShareButtons } from "design-system/molecules/social-media/social-media-share-buttons";
import dayjs from "dayjs";

type MainContentProps = DSComponentPropsProvider & {
  mainPageContent: PillarAndBlogContentProps;
  introContent?: BlogIntroContent;
};

export const MainContent: FC<MainContentProps> = ({
  mainPageContent,
  introContent,
  className,
}) => {
  const [shareUrl, setShareUrl] = useState("");
  useEffect(() => {
    setShareUrl(window.location.href);
  }, []);

  const isDesktop = useIsMediaDesktop();
  return (
    <MainContentWrapper className={className}>
      {introContent && (
        <IntroContent>
          <ModularContent
            data={introContent.content}
            paragraphSize={18}
            customClass="pillar"
          />
          <Box className="flex gap-8 mar-y-16">
            {introContent.tags.map((tag, index) => (
              <Link
                key={`${tag.slug}-${index}`}
                href={`${BLOG_TAGS_URL}/${tag.slug}`}
              >
                <Pill backgroundColor={"blueGrey100"}>{tag.displayName}</Pill>
              </Link>
            ))}
          </Box>
          <Box className="flex gap-12 align-center">
            {introContent.author && (
              <Link
                className="flex gap-12 align-center "
                href={`${BLOG_URL}/authors/${introContent.author.slug}`}
              >
                <Avatar
                  imageUrl={introContent.author.authorImage?.url || ""}
                  userFullName={introContent.author.name || ""}
                />
                <Text fontSize="sm" color="grey300" fontWeight={400}>
                  By {introContent.author.name}
                </Text>
                <Text fontSize="sm" color="grey300" fontWeight={400}>
                  {" • "}
                </Text>
              </Link>
            )}
            <Text fontSize="sm" color="grey300" fontWeight={400}>
              {dayjs(introContent.publishDate).format("MMM DD, YYYY")}
            </Text>
          </Box>
          {shareUrl && !isDesktop && (
            <SocialMediaShareSection>
              <SocialMediaShareButtons shareUrl={shareUrl} />
            </SocialMediaShareSection>
          )}
        </IntroContent>
      )}
      <ModularContent
        data={mainPageContent}
        paragraphSize={18}
        customClass="pillar"
      />
    </MainContentWrapper>
  );
};

const SocialMediaShareSection = styled.div`
  margin: 1rem 0.2rem;
`;

const MainContentWrapper = styled.main(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 2,
  padding: theme.spacing[16],
  [theme.media["bp-desktop-md"]]: {
    p: {
      maxWidth: "800px",
    },
  },
  /**
   * Needed to override typography in modular content styles for now
   */
  h1: {
    fontWeight: theme.fontWeights["bold"],
  },
  h2: {
    fontWeight: theme.fontWeights["bold"],
  },
  h3: {
    fontWeight: theme.fontWeights["bold"],
  },
  em: {
    fontWeight: theme.fontWeights["light"],
  },
  // For further ideas: https://www.abeautifulsite.net/posts/a-clever-way-to-style-the-mark-element/
  mark: {
    backgroundColor: theme.colors.focusMid,
    padding: theme.spacing[4],
  },
  p: {
    lineHeight: 1.5,
    maxWidth: "800px",
  },
  iframe: {},
  div: {
    maxWidth: "800px",
  },
}));

const IntroContent = styled(Box)(({ theme }) => ({
  [theme.media["bp-desktop-xs"]]: {
    marginBottom: theme.spacing[64],
  },
}));
