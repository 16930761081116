import styled from "@emotion/styled";
import { FlockTypeListBlockFragment } from "generated/graphql-typed";
import { getImageUrl } from "utils/component-utils";
import { ContentContainer } from "legacy-design-system";
import { renderer } from "./renderer";

const FlockTypeListView = (
  props: FlockTypeListBlockFragment & { className: string }
) => (
  <>
    {props.flockTypes && (
      <FlocksList className={props.className}>
        {props.flockTypes.map((flockType, index) => (
          <FlockType key={index} {...flockType} />
        ))}
      </FlocksList>
    )}
  </>
);

export const FlockTypeListRenderer = renderer({
  typeName: "FlockTypeListRecord",
  view: FlockTypeListView,
});

const FlockType = (props: FlockTypeListBlockFragment["flockTypes"][number]) => (
  <FlockTypeContainer>
    {props.icon?.url && (
      <Icon src={getImageUrl({ url: props.icon.url, width: 30, height: 30 })} />
    )}
    {props.displayName && <Name>{props.displayName}</Name>}
    {props.description && <Description>{props.description}</Description>}
  </FlockTypeContainer>
);
const FlocksList = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 0 auto;
  grid-gap: 1rem;
`;

const FlockTypeContainer = styled.div`
  margin: 20px;
  max-width: 398px;
  border: 1px solid #e5e1e6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px;
  text-align: left;
`;
const Icon = styled.img`
  width: 100%;
  height: 40px;
  object-fit: contain;
  object-position: left;
  margin-bottom: 24px;
`;

const Name = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  @media only screen and (min-width: 1000px) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  @media only screen and (min-width: 1000px) {
    font-size: 16px;
  }
`;
