export const INTENTION_VISIBILITY_ICON_DICT = {
  public: "globe",
  private: "padlock",
  session: "community",
  organisation: "organisation",
} as const;

export const INTENTION_VISIBILITY_LABEL_DICT = {
  public: "Everyone",
  private: "Only me",
  session: "People in this session",
  organisation: "People from ",
};
