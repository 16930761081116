import Stripe from "stripe";
import {
  PRICE_ID_MAP,
  CURRENCY_SYMBOLS,
  StripeCurrency,
} from "models/subscription";
import { SUBSCRIPTION_TYPES } from "shared/subscription.types";
import { ProductPrice } from ".";
import { QUERY_PARAMS } from "utils/url-query-utils";
import { getSiteBaseUrlNoTrailingSlash } from "services/config";
import { AppError } from "services/normalize-error";
import { SUBSCRIBE_URL } from "utils/urls";

export const getPricePerMonth = (price: number, type: string) =>
  type === SUBSCRIPTION_TYPES.YEARLY
    ? (price / 12).toFixed(0)
    : price.toString();

export const getCurrencySymbol = (currency: string) =>
  CURRENCY_SYMBOLS[currency.toLowerCase() as StripeCurrency];

export const getDisplayPriceWithCents = (
  price: Pick<ProductPrice, "currency" | "amount">
) => (
  <>
    <span className="fs-sm">{getCurrencySymbol(price.currency)}</span>
    {price.amount.toFixed(2)}
  </>
);

const getLifetimePaymentLink = (currency: string) =>
  `${getSiteBaseUrlNoTrailingSlash()}/subscription-lifetime-upgrade?${
    QUERY_PARAMS.CURRENCY
  }=${currency}`;

export const getFormattedPrice = (price: Stripe.Price): ProductPrice => {
  const baseData = PRICE_ID_MAP[price.id];

  if (baseData) {
    return {
      ...baseData,
      paymentLink:
        price.type === "one_time"
          ? getLifetimePaymentLink(price.currency)
          : null,
      amount: (price.unit_amount || 0) / 100,
      amountWithCents: ((price.unit_amount || 0) / 100).toFixed(2),
      pricePerMonth: getPricePerMonth(
        (price.unit_amount || 0) / 100,
        PRICE_ID_MAP[price.id].type
      ),
      priceId: price.id,
      lookupKey: price.lookup_key || "",
    };
  } else {
    throw Error(`Unknown price id: ${price.id}`);
  }
};

type Discount = {
  discountAmount: string;
  finalPrice: string;
};

export const getCouponDiscountState = (
  amount: number,
  coupon?: Stripe.Coupon | null
): Discount | null => {
  if (!coupon) return null;

  if (coupon.amount_off) {
    return {
      discountAmount: (coupon.amount_off / 100).toFixed(2),
      finalPrice: (amount - coupon.amount_off / 100).toFixed(2),
    };
  }
  if (coupon.percent_off) {
    return {
      discountAmount: (amount * (coupon.percent_off / 100)).toFixed(2),
      finalPrice: (amount - amount * (coupon.percent_off / 100)).toFixed(2),
    };
  }
  return null;
};

export const SUBSCRIPTION_ERRORS: Record<string, AppError> = {
  CARD_DECLINED: {
    code: "card-declined",
    message: "Your card was declined. Please try again or try a different one.",
  },
  PAYMENT_NOT_ATTACHED: {
    code: "payment-not-attached",
    message: "The provided payment method could not be processed.",
  },
};

type SubscribePageUtmSource =
  | "price-card"
  | "complete-subscription-failed"
  | "complete-subscription-no-stripe-customer-id"
  | "not-authorized-redirect"
  | "account-side-panel"
  | "community-springboard-page"
  | "springboard-page"
  | "teaser-card"
  | "nav-menu"
  | "event-summary"
  | "credits-modal"
  | "premium-feature-tooltip"
  | "premium-upgrade-progress-bar"
  | "free-trial-remaining-days-indicator"
  | "create-fixed-format-room-page"
  | "create-room-page"
  | "no-valid-credits-redirect";

export const getSubscribePageLinkWithSubscribeSource = (
  utmSource: SubscribePageUtmSource
) => `${SUBSCRIBE_URL}?${QUERY_PARAMS.SUBSCRIBE_SOURCE}=${utmSource}`;
