import styled from "@emotion/styled";
import {
  ContentContainer,
  contentContainerStyles,
  SecondaryButton,
} from "legacy-design-system";
import { HeroContentBlockFragment } from "generated/graphql-typed";
import { CSSProperties } from "react";
import { Curve } from "./curve";
import { getImageUrl } from "utils/component-utils";
import { ModularContent } from "./modular-content";
import { renderer } from "./renderer";
import { notEmpty } from "utils/assertions";
import { useUserContext } from "context/user-context";
import { ChallengeCTAButtonView } from "./challenge-cta-button-view";
import Image from "next/image";
import { isVideo } from "design-system/molecules/media-viewer";

const HeroContentView = (
  props: HeroContentBlockFragment & { className: string }
) => {
  const { loggedIn } = useUserContext();
  const isLoggedInContent = notEmpty(props.ctaButtonLinkLoggedIn) && loggedIn;

  const content = (
    <ContentWrapper
      className={props.textAlign === "center" ? "is-centered" : undefined}
    >
      <TextWrapper
        className={props.textAlign === "center" ? "is-centered" : undefined}
      >
        {notEmpty(props.title) && (
          <Title className={props.description ? "has-description" : undefined}>
            {props.title}
          </Title>
        )}
        <ModularContent
          data={props.description}
          paragraphSize={props.paragraphSize}
          wrapper={Description}
        />
        {!isLoggedInContent && (
          <div>
            {props.challengeCta?.length && props.challengeCta[0] ? (
              <ButtonContainerDiv>
                <ChallengeCTAButtonView
                  className="no-container"
                  {...props.challengeCta[0]}
                />
              </ButtonContainerDiv>
            ) : (
              notEmpty(props.ctaButtonLink) && (
                <CTAButton href={props.ctaButtonLink}>
                  {notEmpty(props.ctaButtonText) ? props.ctaButtonText : "Go!"}
                </CTAButton>
              )
            )}
          </div>
        )}
        {props.ctaButtonLinkLoggedIn && loggedIn && (
          <div>
            <CTAButton href={props.ctaButtonLinkLoggedIn}>
              {notEmpty(props.ctaButtonTextLoggedIn)
                ? props.ctaButtonTextLoggedIn
                : "Go!"}
            </CTAButton>
          </div>
        )}
      </TextWrapper>
      {props.contentImage && (
        <ImageWrapper align={props.contentImageAlign || "left"}>
          <ContentImage
            src={getImageUrl({
              url: props.contentImage.url,
              width: 400,
              height: 400,
            })}
          />
        </ImageWrapper>
      )}
      {props.backgroundImage && isVideo(props.backgroundImage.url) && (
        <BackgroundVideo
          src={props.backgroundImage!.url}
          autoPlay
          playsInline
          loop
          muted
        />
      )}
    </ContentWrapper>
  );

  const customPadding = props.height
    ? Math.round(props.height / 7.5)
    : undefined;
  const wrapperStyle: CSSProperties = {
    backgroundColor: props.backgroundColour?.hex || undefined,
    color: props.textColour?.hex || (props.backgroundImage ? "#FFF" : "#000"),
    minHeight: props.height ? `min(${props.height}px, 50vw)` : undefined,
    paddingTop: customPadding,
    paddingBottom: customPadding,
    textAlign: props.textAlign ? (props.textAlign as any) : "left",
  };
  const className = [
    props.style === "wide" ? "is-wide" : "is-rounded",
    props.className,
    props.textAlign === "center" ? "is-centered" : "left",
    !props.backgroundImage
      ? ""
      : isVideo(props.backgroundImage.url)
      ? "has-video-background"
      : "has-background-image",
    props.backgroundImage || props.backgroundColour ? "has-background" : "",
  ].join(" ");
  return props.style === "wide" ? (
    <Background className={className} style={wrapperStyle}>
      {props.backgroundImage && !isVideo(props.backgroundImage.url) && (
        <Image
          src={props.backgroundImage?.url || ""}
          fill
          priority
          style={{ objectFit: "cover" }}
          alt={props.backgroundImage?.alt || "Hero image"}
        />
      )}
      <ContentContainer>{content}</ContentContainer>
      {props.showCurveOnScroll && <Curve showOnScroll={true} />}
    </Background>
  ) : (
    <ContentContainer className={props.className}>
      {props.backgroundImage && !isVideo(props.backgroundImage.url) && (
        <Image
          src={props.backgroundImage?.url || ""}
          fill
          priority
          style={{ borderRadius: "30px", objectFit: "cover" }}
          alt={props.backgroundImage?.alt || "Hero image"}
        />
      )}
      <Background className={className} style={wrapperStyle}>
        {content}
      </Background>
    </ContentContainer>
  );
};

export const HeroContentRenderer = renderer({
  typeName: "HeroContentRecord",
  view: HeroContentView,
});

const Background = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: contain;
  justify-content: center;
  background-position: 50% 50%;
  &.has-background {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &.has-video-background {
    overflow: hidden;
    background: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.is-rounded {
    ${contentContainerStyles};
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    margin-top: 20px;
  }

  &.is-centered {
    justify-content: center;
  }
  &.is-centered.has-background-image {
    padding: 120px 0;
  }
`;

const ButtonContainerDiv = styled.div`
  margin-top: 40px;
`;

const CTAButton = styled(SecondaryButton)`
  margin-top: 40px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
  &.is-centered {
    justify-content: center;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  &.is-centered {
    align-items: center;
    justify-content: center;
  }
`;

const ImageWrapper = styled.div<{ align: string }>`
  flex: 2;
  margin-top: 20px;
  text-align: ${(props) => props.align};
  @media (min-width: 1000px) {
    align-self: center;
    margin-top: 0;
    margin-left: auto;
  }
`;

const ContentImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const Title = styled.h1`
  white-space: pre-wrap;
  font-size: clamp(36px, 5vw, 60px);
  line-height: clamp(36px, 5vw, 60px);
  margin-bottom: 20px;
  max-width: 800px;
`;

const Description = styled.div`
  white-space: pre-wrap;
  max-width: 800px;
`;
const BackgroundVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: fit-content;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  pointer-events: none;
`;
