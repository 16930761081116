import { EventTagClient } from "features/sessions/shared";
import { getConfig } from "services/config";
import { isProductionMode } from "./environment";
import { UserTag } from "database/collections/users";

export const P2P_TYPE = "p2p";

export const SESSION_TYPES = {
  TAKE_OFF: "take-off",
  DEEP_DIVE: "deep-dive",
  POWER_HOUR: "power-hour",
  DROP_IN: "drop-in",
} as const;

export const FLOCK_TYPES = {
  TAKE_OFF: "take-off",
  TAKE_OFF_2: "take-off-2",
  TO_WEEKLY: "to-weekly",
  DEEP_DIVE: "deep-dive",
  DEEP_DIVE_2: "deep-dive-2",
  DEEP_DIVE_3: "deep-dive-3",
  DEEP_DIVE_9AM: "deep-dive-9am",
  POWER_HOUR: "power-hour",
  DROP_IN: "drop-in",
} as const;

export const NON_REGULAR_FLOCKS: Array<string> = [FLOCK_TYPES.DROP_IN];

export const FLOCK_TYPE_SLUGS = Object.keys(FLOCK_TYPES).map(
  (key: string) => FLOCK_TYPES[key as keyof typeof FLOCK_TYPES]
);

export type FlockType = typeof FLOCK_TYPES[keyof typeof FLOCK_TYPES];

export type SessionType = typeof SESSION_TYPES[keyof typeof SESSION_TYPES];

export const SESSION_SOURCE = {
  ICAL: "ical",
  CALENDAR_INVITE_SINGLE: "calendarInviteSingle",
  CALENDAR_INVITE_RECURRING: "calendarInviteRecurring",
  EVENT_CALENDAR: "eventCalendar",
  SPRINGBOARD: "Springboard",
  SPRINGBOARD_FROM_CALENDAR: "eventCalendarSpringboard",
  MANAGE_MY_WEEK: "manage-my-week",
  UPCOMING_SESSIONS_WIDGET: "upcoming-sessions-widget",
  CALENDAR_PAGE: "calendar-page",
  AGENDA_VIEW_SPRINGBOARD: "agenda-view-springboard",
  COMMUNITY: "community-page",
  P2P_CREATE_PAGE: "p2p-create-page",
  COMMUNITY_EMAIL_INVITE: "community-email-invite",
  COMMUNITY_MEMBER_INVITE: "community-member-invite",
  FIXED_FORMAT_CREATION: "fixed-format-creation",
  P2P_CREATE_COHOST: "p2p-create-cohost",
  EMAIL_USER_CARD: "email-user-card",
  EMAIL_FOLLOWER_ACTIVITY: "email-follower-activity",
  NOTIFICATION: "notification",
} as const;

export type SessionSource = typeof SESSION_SOURCE[keyof typeof SESSION_SOURCE];

export const CACHE_TTL = isProductionMode()
  ? 60
  : Number(getConfig("CACHE_TTL_OVERRIDE")) || 1; // 1 minute in prod

// Date which can be used as a 'start of time' date - for queries and getting data
export const APP_CREATION_DATE = "2020-01-01T00:00:00.000Z";

// Drop-in
export const TAG_SHOW_IN_DROPIN = "show-in-drop-in-upcoming-sessions";
export const DROP_IN_ID = "drop-in";
export const DROP_IN_HOST = "flown";

export const CALENDAR_DESCRIPTION_TAGS = {
  partnership: "partnership",
};

export const FACILITATED_TAG: EventTagClient = {
  displayName: "Facilitated Sessions",
  slug: "facilitated",
  hideForUsers: false,
};

export const CAN_HOST_ROOM_TAG: UserTag = {
  displayName: "Can host room",
  slug: "can-host-room",
  hideForUsers: true,
};

export const FEATURED_TAG: EventTagClient = {
  displayName: "Featured Sessions",
  slug: "featured",
  hideForUsers: false,
};

export const IS_HOST_TAG: EventTagClient = {
  displayName: "Host of Event",
  slug: "host-of-event",
  hideForUsers: true,
};

export const ON_DEMAND_SESSION_TAG: EventTagClient = {
  displayName: "On Demand Session",
  slug: "on-demand-session",
  hideForUsers: false,
};
export const P2P_TAG: EventTagClient = {
  slug: "p2p",
  displayName: "P2P",
  hideForUsers: false,
};

export const FOCUS_ROOM_TAG: EventTagClient = {
  slug: "focus-room",
  displayName: "Focus Room",
  hideForUsers: true,
};

export const FIXED_FORMAT_SESSION_TAG: EventTagClient = {
  slug: "fixed-format-session",
  displayName: "Fixed Format Session",
  hideForUsers: true,
};

export const NEXT_GEN_SESSION_TAG: EventTagClient = {
  slug: "next-gen-session",
  displayName: "Next Gen Session",
  hideForUsers: true,
};

export const SHOW_IN_ORG_CALENDAR_TAG: EventTagClient = {
  slug: "show-in-org-calendar",
  displayName: "Show In Org Calendar",
  hideForUsers: true,
};

export const FLOWN_SESSION_TAG: EventTagClient = {
  slug: "flown-session",
  displayName: "Flown Session",
  hideForUsers: false,
};

export const ANALYTICS_RECORDED_TAG: EventTagClient = {
  slug: "analytics-recorded",
  displayName: "Analytics Recorded",
  hideForUsers: true,
};

export const P2P_HOSTED_TAG: EventTagClient = {
  slug: "p2p-hosted",
  displayName: "P2P Hosted",
  hideForUsers: true,
};

export const P2P_NO_SHOW_TAG: EventTagClient = {
  slug: "p2p-no-show",
  displayName: "P2P No Show",
  hideForUsers: true,
};

export const POPULAR_NEWBIE_SESSION_TAG: EventTagClient = {
  slug: "popular-with-new-members",
  displayName: "Popular with new members",
  hideForUsers: false,
};

export const POPULAR_WITH_ORG_TAG: EventTagClient = {
  slug: "popular-with-org",
  displayName: "Popular with organisation",
  hideForUsers: true,
};

export const FLOWN_FACILITATOR_TAG: EventTagClient = {
  slug: "flown-facilitator",
  displayName: "FLOWN Facilitator",
  hideForUsers: true,
};

// This type of sesison means you don't need a host to launch it
// Basically a drop in
export const OPEN_SESSION_TAG: EventTagClient = {
  slug: "open-session",
  displayName: "Open session",
  hideForUsers: true,
};

export const AVAILABLE_ROOM_TAGS: EventTagClient[] = [
  FEATURED_TAG,
  FLOWN_SESSION_TAG,
  OPEN_SESSION_TAG,
];

export const DEFAULT_ROOM_TAGS: EventTagClient[] = [P2P_TAG];

export const EXTERNAL_ID = "externalId";

export const ANALYTICS_CLIENT_COOKIE_KEY = "ajs_anonymous_id";
export const PWA_INSTALLED_COOKIE_KEY = "pwa-installed";
export const PWA_CONTEXT_COOKIE_KEY = "pwa";

export const CIO_ACTIONS = {
  rateSession: "rate_session",
};

export const OPTIONAL_NAV_MENU_ITEMS = {
  community: "Community",
  flown_plus: "Flown Plus",
  organisation: "Organisation",
} as const;

export const NUMBER_OF_HOURS_BETWEEN_NOTIFICATIONS = 3;
