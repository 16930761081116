"use client";
import { StructuredText } from "react-datocms";
import { HeroContentRenderer } from "./hero-content-view";
import { NewHeroContentRenderer } from "./new-hero-content-view";
import { IframeRenderer } from "./iframe-view";
import { IllustrationHeaderRenderer } from "./illustration-header";
import { TeamGridRenderer } from "./team-grid-view";
import { CardRenderer } from "./card-view";
import { getGroupComponent, Renderer } from "./renderer";
import { CTAButtonRenderer } from "./cta-button-view";
import { TestimonialCarouselRenderer } from "./testimonial-carousel-view";
import { AutoScrollCarouselRenderer } from "./auto-scroll-carousel-view";
import { TextRenderer } from "./text";
import { IllustrationAndContentRenderer } from "./illustration-and-content";
import { FlockTypeListRenderer } from "./flock-type-list";
import React, { useMemo } from "react";
import { HasChildren } from "utils/component-utils";
import { slugify } from "utils/string-utils";
import { HomePageProductRenderer } from "./homepage-product-view";
import { IllustrationRenderer } from "./illustration-view";
import { TimelineRenderer } from "./timeline-view";
import { ColumnLayoutRenderer } from "./column-layout-view";
import { getPageUrlFromSlug } from "utils/urls";
import {
  BackgroundChangeRenderer,
  isRenderedBackgroundChange,
} from "./background-change-view";
import { modularContentStyle } from "./styles";
import styled from "@emotion/styled";
import { ToggleRenderer } from "./toggle-view";
import { ImageLinkRowRenderer } from "./image-link-row-view";
import { AnchorLinkRenderer } from "./anchor-link-view";
import { ArticleResourceRenderer } from "./article-resource";
import { UpdateBannerRenderer } from "./update-banner-view";
import { ModalContentRenderer } from "./modal-content-view";
import { CarouselRenderer } from "./carousel-view";
import { QuoteblockRenderer } from "./quoteblock-view";
import { MetaHeaderRenderer } from "./meta-header-view";
import { ChallengeCTAButtonRenderer } from "./challenge-cta-button-view";
import { CalendarRenderer } from "./calendar-view";
import { PricingRenderer } from "./pricing-view";
import { FlockSessionListRenderer } from "./flock-session-list";
import { ScheduledSessionListRenderer } from "./scheduled-session-list";
import { removeBlocksWithBrokenReferences } from "utils/remove-blocks-with-broken-references";
import { ImageRenderer } from "./image";
import { NewQuoteblockRenderer } from "./new-quoteblock-view";
import { BoxoutRenderer } from "./boxout-view";
import { PostListRenderer } from "./post-list-block-view";
import { NumberedListRenderer } from "./numbered-list-view";
import { PressRenderer } from "./press-view";
import { SpacerRenderer } from "./spacer";
import { CampaignSubscriptionRenderer } from "./campaign-subscription-view";
import { LogicTimezoneRenderer } from "./logic-timezone";
import { ReferralBlockRenderer } from "./referral-block-view";
import { AccordionRenderer } from "./accordion-view";
import { ReusableContentRenderer } from "./reusable-content-view";
import { ShareBlockRenderer } from "./share-block-view";
import { TrustpilotBlockRenderer } from "./trustpilot";
import { WrapperBlockRenderer } from "./wrapper-block-view";
import { StripeBookButtonRenderer } from "./stripe-book-button-view";
import { clientLoggerForFile } from "utils/logger.client";

const logger = clientLoggerForFile(__filename);

const renderers: Renderer[] = [
  HeroContentRenderer,
  IframeRenderer,
  TeamGridRenderer,
  IllustrationHeaderRenderer,
  CardRenderer,
  CTAButtonRenderer,
  TestimonialCarouselRenderer,
  AutoScrollCarouselRenderer,
  TextRenderer,
  IllustrationAndContentRenderer,
  FlockTypeListRenderer,
  FlockSessionListRenderer,
  HomePageProductRenderer,
  IllustrationRenderer,
  TimelineRenderer,
  ColumnLayoutRenderer,
  BackgroundChangeRenderer,
  ToggleRenderer,
  ImageLinkRowRenderer,
  AnchorLinkRenderer,
  ArticleResourceRenderer,
  UpdateBannerRenderer,
  QuoteblockRenderer,
  ModalContentRenderer,
  CarouselRenderer,
  MetaHeaderRenderer,
  ChallengeCTAButtonRenderer,
  CalendarRenderer,
  PricingRenderer,
  ScheduledSessionListRenderer,
  ImageRenderer,
  NewQuoteblockRenderer,
  BoxoutRenderer,
  PostListRenderer,
  NumberedListRenderer,
  PressRenderer,
  SpacerRenderer,
  CampaignSubscriptionRenderer,
  LogicTimezoneRenderer,
  ReferralBlockRenderer,
  NewHeroContentRenderer,
  AccordionRenderer,
  ReusableContentRenderer,
  ShareBlockRenderer,
  TrustpilotBlockRenderer,
  WrapperBlockRenderer,
  StripeBookButtonRenderer,
];

export const renderersByTypeName: Record<string, React.FC<any>> = {};
renderers.forEach((r) => (renderersByTypeName[r.typeName] = r.view));

type ModularContentData =
  | { value: any; blocks?: any; links?: any }
  | null
  | undefined;

type ModularContentProps = {
  customClass?: string;
  data: ModularContentData;
  paragraphSize: number | null | undefined;
  wrapper?: React.FC<HasChildren>;
  contentContainer?: boolean;
  wrapperClassName?: string;
  alignmentOfText?: string | null | undefined;
  findAndReplace?: FindAndReplaceList;
};

export type FindAndReplaceList = Array<{ field: string; value: string }>;

export const ModularContent = ({
  customClass,
  data: untypedData,
  contentContainer,
  wrapper,
  paragraphSize,
  wrapperClassName,
  alignmentOfText,
  findAndReplace = [],
}: ModularContentProps) => {
  const data = useMemo(
    () => removeBlocksWithBrokenReferences(untypedData),
    [untypedData]
  );

  if (!data) return null;
  let indexInGroup = 0;
  let lastRecordType = "";
  return (
    <StructuredText
      data={data}
      renderBlock={({ record }) => {
        /**
         * This is the function that actually renders the blocks
         * using the renderer functions. This converts a GraphQL
         * block node into React
         */
        const thisRecordType = record.__typename;
        const Renderer = renderersByTypeName[thisRecordType];
        if (thisRecordType !== lastRecordType) {
          indexInGroup = 0;
        } else {
          ++indexInGroup;
        }
        lastRecordType = thisRecordType;
        if (Renderer) {
          return (
            <Renderer
              key={record.id}
              className={customClass ? customClass : ""}
              {...record}
              indexInGroup={indexInGroup}
            />
          );
        }
        return null;
      }}
      renderFragment={(children, key) => {
        const uniqueKey = key + "-" + Math.random();
        /**
         * Find consecutive runs of components that have a bound group (`groupComponent`)
         * and wrap them in an instance of the group component.
         */
        if (Array.isArray(children)) {
          /**
           * react-datocms returns plain strings in the latest update and
           * these do no need a wrapper so we ignore these and return them
           */
          if (typeof children[0] === "string")
            return <React.Fragment key={uniqueKey}>{children}</React.Fragment>;
          children = wrapGroupComponents(children, customClass || "");
          children = wrapInBackgroundChangeBlock(children);
          children = applyElementStyles(children);
        }
        const Wrapper: any =
          wrapper || (wrapperClassName ? "div" : React.Fragment);
        return (
          <Wrapper
            {...(wrapperClassName ? { className: wrapperClassName } : {})}
            key={uniqueKey}
          >
            <ModularContentStyle
              className={[
                alignmentOfText,
                contentContainer && customClass !== "pillar"
                  ? "has-content-container"
                  : undefined,
                customClass,
              ]
                .filter(Boolean)
                .join(" ")}
              style={
                paragraphSize
                  ? ({ "--paragraph-size": `${paragraphSize}px` } as any)
                  : undefined
              }
            >
              {children}
            </ModularContentStyle>
          </Wrapper>
        );
      }}
      renderLinkToRecord={({ record, children, transformedMeta }) => (
        <a {...transformedMeta} href={getLinkHref(record)}>
          {children}
        </a>
      )}
      renderInlineRecord={({ record }) => (
        <a href={getLinkHref(record)}>{getLinkText(record)}</a>
      )}
      renderText={(text) => {
        return findAndReplace.reduce(
          (returnText, substitution) =>
            returnText.replace(substitution.field, substitution.value),
          text
        );
      }}
    />
  );
};

const ModularContentStyle = styled.div`
  ${modularContentStyle};
`;

const applyElementStyles = (children: any[]) =>
  children?.map((child, i, all) => {
    // This adds ids to h2s so we can link to them from the blog
    if (child.type === "h2") {
      try {
        child = addIdToElement(child);
      } catch (e) {
        logger.warn(`Issue adding id to element ${JSON.stringify(child)}`, e);
      }
    }

    if (i === 0) {
      child = appendClassToElement(child, "first-element");
    }
    if (i === all.length - 1) {
      child = appendClassToElement(child, "last-element");
    }
    return child;
  });

const appendClassToElement = (
  el: JSX.Element,
  className: string
): JSX.Element => ({
  ...el,
  props: {
    ...el.props,
    className: [el.props.className, className].filter(Boolean).join(" "),
  },
});

const addIdToElement = (el: JSX.Element): JSX.Element => {
  // This should be a string unless the h2 is modified in Dato by bold, italics, etc; in this case we go one level deeper
  const contentChild = el.props?.children[0].props?.children;
  const id =
    typeof contentChild[0] === "string"
      ? contentChild[0]
      : contentChild.props?.children[0]
      ? contentChild.props.children[0]
      : el.toString();
  return {
    ...el,
    props: {
      ...el.props,
      id: slugify(id),
      className: "navigation-element",
    },
  };
};

const wrapGroupComponents = (children: any[], customClass: string) => {
  const groupedChildren: any[] = [];
  for (let i = 0; i < children.length; i++) {
    const GroupComponent = getGroupComponent(children[i]?.type);
    if (GroupComponent) {
      const groupStart = i;
      while (getGroupComponent(children[i + 1]?.type) === GroupComponent) {
        i++;
      }
      groupedChildren.push(
        <GroupComponent key={i} className={customClass}>
          {children.slice(groupStart, i + 1)}
        </GroupComponent>
      );
    } else {
      groupedChildren.push(children[i]);
    }
  }

  return groupedChildren;
};

const wrapInBackgroundChangeBlock = (children: any[]) => {
  const bgChildren: any[] = [];
  for (let i = 0; i < children.length; i++) {
    if (isRenderedBackgroundChange(children[i])) {
      const nextChange = children.find(
        (child, j) => j > i && isRenderedBackgroundChange(child)
      );
      const bgBlock = replacePropInElement(
        children[i],
        "nextColor",
        nextChange?.props?.color?.hex
      );
      const contentStart = i;
      while (children[i + 1] && !isRenderedBackgroundChange(children[i + 1])) {
        i++;
      }
      bgChildren.push(
        replacePropInElement(
          bgBlock,
          "children",
          applyElementStyles(children.slice(contentStart + 1, i + 1))
        )
      );
    } else {
      bgChildren.push(children[i]);
    }
  }
  return bgChildren;
};

const replacePropInElement = (
  el: JSX.Element,
  name: string,
  value: any
): JSX.Element => ({
  ...el,
  props: {
    ...el.props,
    [name]: value,
  },
});

const getLinkHref = (record: { slug?: string; __typename?: string }) => {
  if (!record.slug || !record.__typename) {
    logger.error("Invalid record link", { record });
    return;
  }
  if (record.__typename === "PageRecord") {
    return getPageUrlFromSlug(record.slug);
  }
};

const getLinkText = (record: {
  slug?: string;
  __typename?: string;
  title?: string;
}) => record.title || record.slug;
