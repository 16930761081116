import { ParsedUrlQuery } from "querystring";

export const getQueryParam = (val: string | string[] | undefined | null) =>
  (Array.isArray(val) ? val[0] : val) || undefined;

export const getStringifiedQuery = (query: ParsedUrlQuery) =>
  Object.keys(query).reduce((queryString: string, queryKey: string) => {
    const queryParam = getQueryParam(query[queryKey]);
    if (queryParam) {
      return `${queryString}&${queryKey}=${queryParam.replace(" ", "+")}`;
    }
    return queryString;
  }, "");

/**
 * These query params can be leveraged by the wider team and can have implications upstream if they
 * are changed. DO NOT CHANGE without consulting with the wider team.
 * Document newly added params as appropriate here: https://www.notion.so/flown/URL-Query-Params-e540b9a8719946b4a532f321f31d2ef0?pvs=4
 */
export const QUERY_PARAMS = {
  REDIRECT_AFTER_REGISTER: "redirect-after-register", // Param to redirect to a url after registering
  REDIRECT_AFTER_LOGIN: "redirect-after-login", // Param to redirect to a url after logging in
  REDIRECTED: "redirected", // Param to track if a user was redirected afer register / login
  JOIN_CHALLENGE: "join-challenge", // Join a challenge - equals slug challenge
  RELOAD: "reload", // Set true if need a hard reload
  REFERRAL: "referral", // Add on any page if a user is referred
  PROMO_CODE: "promocode", // Add on any page if a promo code is to be used.
  REFER_PAGE: "refer-page", // Set to track page we came from for account created tracking
  PRICE_TYPE: "price-type", // Set to track the price a user clicked on when navigation from pricing container to subscribe page
  MEMBERSHIP_CHANGED: "membership-changed", // Set to track if a user changed their membership and can add sparkle on memberhship page
  CURRENCY: "currency", // Set to track a current selection across pages so it does not get localized
  SESSION_CREATED: "session-created", // Set to open community springboard with a modal to invite users
  REGISTER_TO_BOOK: "register-to-book", // Used in the springboard pages to show an alternate version of the page to users that land there directly
  RETURN_URL: "return-url", // Used to redirect to a specific url after login
  TEST_JOIN_STATE: "test-join-state", // Used to override the join state of a user in community sprinboard to make it easier to test
  FIXED_FORMAT_TEMPLATE: "ff-template", // Used to open the fixed format session edit modal on any page with a specific template. See available values on Dato https://aviary.flown.com/editor/item_types/2045668/items
  FIXED_FORMAT_RECUR_ID: "ff-recur-id", // Used to open the fixed format session edit modal on any page with a specific session to recreate
  FIXED_FORMAT_RECUR_FREQ: "ff-recur-freq", // Used to open the fixed format session edit modal on any page with a recurrence frequency
  FIXED_FORMAT_RECUR_TYPE: "ff-recur-type", // Used to open the fixed format session edit modal on any page with a recurrence type. Can be: daily, weekly, weekdays
  LIFETIME_UPGRADE: "lifetime-upgrade", // Used to confirm lifetime upgrade on membership page.
  AUTH_SLACK: "auth_slack", // Used to confirm lifetime upgrade on membership page.
  WELCOME_TO_FLOWN_OPTION: "welcometoflownoption", // Used to track the starter question selected by the user
  SESSION_PRIVACY: "private", // Used to set the privacy of the session on creation
  CALENDAR_FILTER: "filter",
  CALENDAR_SUB_FILTER: "sub-filter",
  CALENDAR_DATE: "date",
  CLONE: "clone", // Used when recreating a community session
  FIND_USER_QUERY: "user-id",
  SINCE_MINS: "since-mins", // used when need a query param for time since
  FROM_DROP_IN: "from-drop-in", // Used to track how got to focus room
  FOCUS_ROOM_PROFILE_REDIRECT: "from-focus-room", // Used as a flag when a user is logged out, or it's a preview URL,
  ADD_ON: "add-on", // Used to track add-ons on the subscribe page for bundles and other offers (e.g. Feel Good Focus course)
  MAGIC_LINK_SEND_EMAIL: "magic-link-send-email", // Used to track if the user clicked to send a magic link email or just create it and copy
  EXTEND_FREE_TRIAL: "extend-free-trial", // Used before loading the manage subscription page to active a user free trial extension
  MAGIC_LINK_TOKEN_EXPIRED: "magic-link-token-expired", // Used to track if the magic link token has expired
  SUBSCRIBE_SOURCE: "subscribe-source", // Used to track the source of a user
  SELECTED_FREE_MEMBERSHIP: "selected-free-membership", // Used to track if the user has just selected the free membership
  SHOW_FREE_MODAL: "show-free-modal", // Used to open pages with the free modal by default if this param is present in the URL
} as const;

export const QUERY_VALUES = {
  CALENDAR_ALL: "all",
  CALENDAR_FEATURED: "featured",
  CALENDAR_COMMUNITY: "community",
  CALENDAR_MY_SESSIONS: "my-sessions",
  CALENDAR_ORG_ONLY: "org-only",
  CALENDAR_HOSTING: "hosting",
};

export const DAILY_PREBUILT_QUERY_PARAMS = {
  USER_ROLE: "userRole",
  AUDIO: "audio",
  DEBUG: "debug",
  DISABLE_PREJOIN_UI: "disable-prejoin-ui",
};
