import { ThemeProvider } from "@emotion/react";
import { Category } from "shared/products.types";
import { THEME_BREAKPOINTS } from "./consts.theme";

export const THEME_COLORS = {
  black: "#18181D",
  blueGrey100: "#F1F5F9", // was grey100
  focusDark: "#4CBAB8",
  focusMid: "#9ADEDC",
  rechargeLight: "#F7C9B9",
  rechargeDark: "#F89E81",
  info: "#3F6DE4",
  infoMid: "color-mix(in srgb, #3F6DE4, transparent 50%)",
  infoLight: "#E9EFFF",
  success: "#2F835C",
  successLight: "#D7F9E9",
  warning: "#EE9541",
  warningLight: "#FFF2E2",
  error: "#D71D1D",
  errorLight: "#FFF0F0",
  disabled: "#EBF2F5",
  grey600: "#424242", // was 900
  grey500: "#616161", // was 800
  grey400: "#616B74",
  grey300: "#6E757B", // was 700
  grey200: "#ACB4B8", // was 600
  grey100: "#E9EDF2", // was 300
  grey50: "#F8FAFC",
  grey20: "#F5F5F6",
  grey: "#DEE4E9",
  pinkDark: "#FFA9A9",
  pinkLight: "#FFB8B8",
  orangeDark: "#FFB053",
  orangeLight: "#FFC683",
  pistachioDark: "#B3D171",
  pistachioLight: "#C1DC89",
  grassDark: "#70D19C",
  grassLight: "#8ADFB1",
  skyDark: "#AAD3ED",
  skyLight: "#BBE5FF",
  berryDark: "#698CE7",
  berryLight: "#90A8E8",
  purpleDark: "#A278E7",
  purpleLight: "#B292E7",
  white: "#FFFFFF",
} as const;

export const EMOTION_THEME = {
  colors: THEME_COLORS,
  fontFamilies: {
    facundo: "Facundo, sans-serif",
    biennale: "Biennale, sans-serif",
    centuryOldStyleStd: "Century Old",
  },
  fontSizes: {
    "2xs": "0.625rem", //10px
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "2rem", // 32px
    "4xl": "2.5rem", // 40px
    "5xl": "3rem", // 48px
    "5.5xl": "3.5rem", // 56px
    "6xl": "4rem", // 64px
    "7xl": "4.5rem", // 72px
    "8xl:": "5rem", // 80px
    "9xl": "6rem", // 96px
  },
  fontSizesEm: {
    "2xs": "0.625em", //10px
    xs: "0.75em", // 12px
    sm: "0.875em", // 14px
    md: "1em", // 16px
    lg: "1.125em", //18
    xl: "1.25em", //20
    "2xl": "1.5em", //24
    "3xl": "2em", // 32
    "4xl": "2.5em", // 48
    "5xl": "3em",
    "5.5xl": "3.5em",
    "6xl": "4em",
    "7xl": "4.5em",
    "8xl:": "5em", // 80px
    "9xl": "6em", // 96px
  },
  spacing: {
    "4": "0.25rem",
    "8": "0.5rem",
    "12": "0.75rem",
    "16": "1rem",
    "20": "1.25rem",
    "24": "1.5rem",
    "28": "1.75rem",
    "32": "2rem",
    "36": "2.25rem",
    "40": "2.5rem",
    "44": "2.75rem",
    "48": "3rem",
    "52": "3.25rem",
    "56": "3.5rem",
    "60": "3.75rem",
    "64": "4rem",
    "80": "5rem",
    "84": "5.25rem",
    "128": "8rem",
  },
  radii: {
    2: "2px",
    4: "4px",
    6: "6px",
    8: "8px",
    12: "12px",
    16: "16px",
    20: "20px",
    24: "24px",
    32: "32px",
    46: "46px",
    80: "80px",
    round: "9999px",
  },
  media: {
    "bp-mobile-only": `@media (max-width: ${THEME_BREAKPOINTS.tablet}px)`,
    "bp-tablet-or-lower": `@media (max-width: ${THEME_BREAKPOINTS["desktop-xs"]}px)`,
    "bp-desktop-xs-or-lower": `@media (max-width: ${THEME_BREAKPOINTS["desktop-sm"]}px)`,
    "bp-desktop-xs-height": `(min-height: ${THEME_BREAKPOINTS["desktop-xs-height"]}px)`,
    "bp-mobile": `@media (min-width: ${THEME_BREAKPOINTS.mobile}px)`,
    "bp-mobile-md": `@media (min-width: ${THEME_BREAKPOINTS["mobile-md"]}px)`,
    "bp-tablet": `@media (min-width: ${THEME_BREAKPOINTS.tablet}px)`,
    "bp-desktop-xs": `@media (min-width: ${THEME_BREAKPOINTS["desktop-xs"]}px)`,
    "bp-desktop-sm": `@media (min-width: ${THEME_BREAKPOINTS["desktop-sm"]}px)`,
    "bp-desktop-md": `@media (min-width: ${THEME_BREAKPOINTS["desktop-md"]}px)`,
    "bp-desktop-lg": `@media (min-width: ${THEME_BREAKPOINTS["desktop-lg"]}px)`,
  },
  constants: {
    primaryNavHeight: "60px",
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  maxWidth: {
    content: "1400px",
  },
} as const;

export type EmotionTheme = typeof EMOTION_THEME;

export type ThemeColor = keyof EmotionTheme["colors"];
export type ThemeFontSize = keyof EmotionTheme["fontSizes"];
/**
 * Panda refactor:
 * import { PropertyValue } from "styled-system/types/prop-type";
 * export type ThemeFontSize = PropertyValue<"fontSize">
 */

export type ThemeFontFamily = keyof EmotionTheme["fontFamilies"];

type Colors = typeof THEME_COLORS[ThemeColor];

export const CATEGORY_THEME: Record<Category, Colors> = {
  focus: THEME_COLORS.focusDark,
  recharge: THEME_COLORS.rechargeLight,
  learn: THEME_COLORS.black,
  social: THEME_COLORS.pistachioDark,
  intentions: THEME_COLORS.skyDark,
};

export const DesignSystemThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <ThemeProvider theme={EMOTION_THEME}>{children}</ThemeProvider>
);
