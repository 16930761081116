import { Box, Input, Spinner } from "design-system/components";
import React, { useCallback, useState } from "react";
import { debounce } from "utils/generic-utils";
import { UserProfileFromActivityNoId } from "shared/user.types";
import Fuse from "fuse.js";
import { UserCard } from "design-system/molecules/cards/user-card";
import { getUserDisplayName } from "features/profile/shared";
import { UserCardWithCta } from "design-system/molecules/cards/user-card";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { postToSearchUsers } from "features/profile/client";

type SearchUsersListInputProps = {
  userList?: UserProfileFromActivityNoId[];
  showCta?: boolean;
  ctaText?: string;
  ctaTextClicked?: string;
  onCtaClick?: (user: UserProfileFromActivityNoId) => void;
} & DSComponentPropsProvider;

type UserProfileEnhanced = {
  ctaClicked?: boolean;
} & UserProfileFromActivityNoId;

//**
// Generic search function which returns a list of users.
// @param userList - List of users to search from. If non provided we make a call to the server.
// @param showCta - Show a cta on the user card in resuts.
// @param ctaText - Text to show on the cta.
// @param ctaTextClicked - Text to show on the cta after it is clicked. Can be empty in which case text doesn't change.
// @param onCtaClick - Callback when the cta is clicked.
// **
export const SearchUsersListInput: React.FC<SearchUsersListInputProps> = ({
  userList,
  showCta = false,
  ctaText = "Invite",
  ctaTextClicked,
  onCtaClick,
  style,
}) => {
  const [onSearchFocus, setOnSearchFocus] = useState(false);
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState<UserProfileEnhanced[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setIsLoading(true);
    setQuery(inputValue);
    setOnSearchFocus(true);
    debouncedSearch(inputValue);
  };

  // Dependencies are correct, React can't identify them because they're in the inner function's closure
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (inputValue: string) => {
      if (userList) {
        const fuse = new Fuse(userList, {
          keys: ["firstName", "lastName", "displayName"],
        });
        if (inputValue) {
          const result = fuse.search(inputValue);
          setSearchResults(result.map((r) => r.item));
        }
        setIsLoading(false);
      } else {
        await postToSearchUsers({ query: inputValue }).then((response) => {
          if (!response.worked) {
            setSearchResults([]);
          } else {
            const { users } = response;
            setSearchResults(users);
          }

          void setIsLoading(false);
        });
      }
    }, 500),
    [userList]
  );

  const _onCtaClick = (user: UserProfileFromActivityNoId) => {
    if (onCtaClick) {
      onCtaClick(user);
      setSearchResults((prev) => {
        return prev.map((matchUser) => {
          if (matchUser.externalId === user.externalId) {
            return { ...matchUser, ctaClicked: true };
          }
          return matchUser;
        });
      });
    }
  };

  return (
    <Box
      className="search-users-list-wrapper"
      style={{ position: "relative", ...style }}
    >
      <Input
        className="w-full"
        type="text"
        value={query}
        placeholder="Search by name"
        onChange={handleInputChange}
        onClick={() => {
          setOnSearchFocus(true);
        }}
      />

      <Box className="mar-y-16">
        {onSearchFocus && (
          <>
            {isLoading ? (
              <div className="flex-col align-center gap-8 mar-y-24">
                Searching for members... <Spinner color="black" />
              </div>
            ) : query.length > 0 && searchResults.length === 0 ? (
              <Box className="pad-y-8">{`No members found`}</Box>
            ) : (
              searchResults.map((user, index) => {
                const key = `${user.externalId}-${index}`;
                return showCta ? (
                  <UserCardWithCta
                    ctaText={
                      user.ctaClicked ? ctaTextClicked || ctaText : ctaText
                    }
                    user={user}
                    key={key}
                    onCtaClick={_onCtaClick}
                  />
                ) : (
                  <UserCard
                    key={key}
                    {...user}
                    displayName={getUserDisplayName(user)}
                    oneLiner={user.oneLiner}
                  />
                );
              })
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
