import {
  BoxCol,
  Button,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLabel,
  Icon,
  Row,
  Text,
  useToast,
} from "design-system/components";
import {
  INTENTION_VISIBILITY_ICON_DICT,
  INTENTION_VISIBILITY_LABEL_DICT,
} from "./shared";
import { useUserContext } from "context/user-context";
import { Visibility } from "features/accountability/shared";
import { token } from "styled-system/tokens";
import { Modal } from "design-system/organisms/modals";
import { useState } from "react";
import { postToDeleteIntention } from "../accountability-client";

type VisibilitySelectProps = {
  intentionId?: string;
  options: Visibility[];
  selectedVisibility?: Visibility;
  onDeleteSuccess?: () => void;
  onVisibilityChange: (visibility: Visibility) => void;
};

export const VisibilitySelect = ({
  intentionId,
  onVisibilityChange,
  options,
  onDeleteSuccess,
  selectedVisibility,
}: VisibilitySelectProps) => {
  const { externalId, organisation } = useUserContext();
  const { toast, Toast } = useToast();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeletingIntention, setIsDeletingIntention] = useState(false);

  return (
    <>
      {Toast}
      <Modal
        title=""
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        desktopPopup
        preventDismissOnOverlayClick
      >
        <BoxCol className="pad-16 gap-16">
          <Text as="h2" fontSize="2xl" fontWeight={700} className="text-center">
            Delete intention
          </Text>
          <Text fontWeight={400}>
            Are you sure you want to delete this intention?
            <br />
            This is a permanent deletion. You can set your intention to private
            if you wish to just hide it from other users.
          </Text>
          <Row className="justify-end">
            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              isLoading={isDeletingIntention}
              onClick={async () => {
                // `onDeleteClick` is certain to exist here, but TS doesn't know that
                if (!intentionId || !externalId) return;
                setIsDeletingIntention(true);
                const deleteResult = await postToDeleteIntention({
                  intentionId,
                });
                if (deleteResult.worked) {
                  toast("success", "Intention deleted successfully");
                  onDeleteSuccess?.();
                } else {
                  toast("error", "Failed to delete intention");
                }

                setIsModalOpen(false);
                setIsDeletingIntention(false);
              }}
            >
              Delete
            </Button>
          </Row>
        </BoxCol>
      </Modal>

      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={setIsDropdownOpen}
        triggerAsChild
        trigger={
          <button className="flex align-center gap-8 hov-decor-underline">
            <Icon
              icon={
                INTENTION_VISIBILITY_ICON_DICT[selectedVisibility ?? "public"]
              }
              size={20}
            />
          </button>
        }
        content={
          <>
            <DropdownMenuLabel>Who should see this?</DropdownMenuLabel>
            {options.map((visibilityOption) => (
              <DropdownMenuItem key={visibilityOption}>
                <Row
                  className="pad-y-12 w-full"
                  onClick={() => onVisibilityChange(visibilityOption)}
                >
                  <Icon
                    icon={INTENTION_VISIBILITY_ICON_DICT[visibilityOption]}
                    size={20}
                  />
                  <Text fontSize="sm">
                    {INTENTION_VISIBILITY_LABEL_DICT[visibilityOption]}
                    {visibilityOption === "organisation" &&
                      ` (${organisation?.name})`}
                  </Text>
                </Row>
              </DropdownMenuItem>
            ))}
            {intentionId && (
              <DropdownMenuItem key="delete">
                <Row
                  className="pad-y-12 w-full"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setIsModalOpen(true);
                  }}
                >
                  <Icon
                    icon={"x-circle"}
                    size={20}
                    color={token("colors.error")}
                  />
                  <Text fontSize="sm" fontWeight={600} color="error">
                    Delete
                  </Text>
                </Row>
              </DropdownMenuItem>
            )}
          </>
        }
      />
    </>
  );
};
