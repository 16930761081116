import { ProfileTag } from "shared/user.types";
import { Box, Tag, TagProps, Text } from "../components";

type TagsListProps = {
  limit?: number;
  tags: ProfileTag[];
  tagSize?: TagProps["size"];
};

export const TagsList: React.FC<TagsListProps> = ({ limit, tags, tagSize }) => {
  return (
    <Box className="flex flex-wrap gap-12">
      {tags.slice(0, limit ? limit : tags.length).map((tag, index) => (
        <Tag
          icon={tag.icon}
          isSelected={false}
          key={`${tag.slug}-${index}`}
          size={tagSize}
        >
          {tag.title}
        </Tag>
      ))}
      {limit && tags.length > limit && (
        <Text fontSize="sm">{`+ ${tags.length - limit} more`}</Text>
      )}
    </Box>
  );
};
