import { useConnectionsContext } from "context/connections-context";
import { useUserContext } from "context/user-context";
import { UserListWithFollows } from "features/profile/client";
import { memo, useMemo } from "react";

export const FollowersView = memo(({ orgOnly }: { orgOnly?: boolean }) => {
  const { followers, onFollow, onUnfollow, organisation } = useUserContext();
  const filteredUsers = useMemo(
    () =>
      orgOnly && organisation?.slug
        ? followers.filter(
            (user) => user.organisation?.slug === organisation.slug
          )
        : followers,
    [followers, orgOnly, organisation?.slug]
  );

  return (
    <UserListWithFollows
      users={filteredUsers}
      onFollow={onFollow}
      onUnfollow={onUnfollow}
    />
  );
});

export const FollowingView = memo(({ orgOnly }: { orgOnly?: boolean }) => {
  const { following, onFollow, onUnfollow, organisation } = useUserContext();
  const filteredUsers = useMemo(
    () =>
      orgOnly && organisation?.slug
        ? following.filter(
            (user) => user.organisation?.slug === organisation.slug
          )
        : following,
    [following, orgOnly, organisation?.slug]
  );

  return (
    <UserListWithFollows
      users={filteredUsers}
      onFollow={onFollow}
      onUnfollow={onUnfollow}
    />
  );
});

export const UserFollowersView = () => {
  const { followers, onFollow, onUnfollow } = useConnectionsContext();
  return (
    <UserListWithFollows
      users={followers}
      onFollow={onFollow}
      onUnfollow={onUnfollow}
    />
  );
};

export const UserFollowingView = () => {
  const { following, onFollow, onUnfollow } = useConnectionsContext();
  return (
    <UserListWithFollows
      users={following}
      onFollow={onFollow}
      onUnfollow={onUnfollow}
    />
  );
};
