"use client";
import { useEffect, useState } from "react";
import { normalizeError } from "services/normalize-error";
import { RequestStatus } from "shared/response.types";
import { getHostedSessions } from "../sessions-client";
import { FlownEvents } from "shared/event.types";

type HostedSessionsState = {
  hostedSessions: FlownEvents;
  isHost: boolean;
  status: RequestStatus;
  error: string;
};

type UseGetHostedSessionsProps = {
  externalId: string;
  hostedSessionsFromServer?: FlownEvents;
  isHostFromServer?: boolean;
};

export const useGetHostedSessions = ({
  externalId,
  hostedSessionsFromServer,
  isHostFromServer,
}: UseGetHostedSessionsProps) => {
  const [hostedSessionsState, setHostedSessionsState] =
    useState<HostedSessionsState>({
      hostedSessions: [],
      isHost: false,
      status: "idle",
      error: "",
    });
  useEffect(() => {
    const fetchRooms = async () => {
      if (hostedSessionsFromServer && isHostFromServer) {
        setHostedSessionsState({
          hostedSessions: hostedSessionsFromServer,
          status: "resolved",
          isHost: true,
          error: "",
        });
        return;
      }
      try {
        setHostedSessionsState((prev) => ({ ...prev, status: "loading" }));
        const response = await getHostedSessions(externalId)();
        if (!response.worked) {
          setHostedSessionsState({
            hostedSessions: [],
            status: "rejected",
            isHost: false,
            error: response.error,
          });
          return;
        }
        const { hostedSessions, isHost } = response;
        setHostedSessionsState({
          hostedSessions,
          status: "resolved",
          isHost,
          error: "",
        });
      } catch (error) {
        const normalizedError = normalizeError(error);
        setHostedSessionsState({
          hostedSessions: [],
          status: "rejected",
          isHost: false,
          error: normalizedError.message,
        });
      }
    };
    if (hostedSessionsState.status === "idle") {
      void fetchRooms();
    }
  }, [
    hostedSessionsState.status,
    externalId,
    hostedSessionsFromServer,
    isHostFromServer,
  ]);

  return [hostedSessionsState, setHostedSessionsState] as const;
};
