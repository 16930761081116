import { Box } from "design-system/components";
import { THEME_COLORS } from "design-system/emotion.theme";
import React from "react";
import { Avatar, AvatarProps } from "./avatar";

type AvatarsProps = {
  avatars: AvatarProps[];
  dimensions?: number;
};

export const MAX_AVATARS_TO_SHOW = 3;

export const Avatars: React.FC<AvatarsProps> = ({ avatars, dimensions }) => {
  return (
    <Box className="flex mar-right-16">
      {avatars.map((avatar, index) => {
        return (
          <Box
            style={{
              marginRight: "-12px",
            }}
            key={`${avatar.userFullName}-${index}`}
          >
            <Avatar
              {...avatar}
              style={{
                border: `2px solid ${THEME_COLORS.white}`,
              }}
              dimensions={dimensions}
            />
          </Box>
        );
      })}
    </Box>
  );
};
