import styled from "@emotion/styled";
import { ContentContainer } from "legacy-design-system";
import { CardBlockFragment } from "generated/graphql-typed";
import { getImageUrl, HasChildren } from "utils/component-utils";
import Link from "next/link";
import { useMemo } from "react";
import { renderer } from "./renderer";
import { ModularContent } from "./modular-content";
import Image from "next/image";

const CardViewGroupWrapper = (props: HasChildren & { className: string }) => (
  <CardGroup
    className={[
      props.className,
      !Array.isArray(props.children) || props.children.length === 1
        ? "is-single"
        : undefined,
    ]
      .filter(Boolean)
      .join(" ")}
  >
    {props.children}
  </CardGroup>
);

const CardView = (props: CardBlockFragment & { className: string }) => {
  const content = useMemo(
    () => (
      <>
        {props.style && props.contentAlignment && (
          <CardWrapper
            className={
              props.style +
              " " +
              props.contentAlignment +
              " " +
              (props.className ? props.className : "")
            }
          >
            {props.image?.url && props.mediaSize && (
              <CardImage
                height={250}
                width={287}
                alt={props.image.alt || ""}
                src={getImageUrl({
                  url: props.image.url,
                  width: 287,
                  height: 250,
                })}
                className={
                  (!props.image.url.includes(".svg") ? "rounded" : "") +
                  " " +
                  (props.mediaSize === "defined-by-svg-size" &&
                  !props.image.url.includes(".svg")
                    ? `medium`
                    : props.mediaSize) +
                  " " +
                  (props.headingAlignment || props.contentAlignment || "left")
                }
              />
            )}
            {props.title && (
              <Title
                className={
                  props.headingAlignment || props.contentAlignment || "left"
                }
                fontSize={props.paragraphSize}
              >
                {props.title}
              </Title>
            )}
            <ModularContent
              data={props.content}
              paragraphSize={props.paragraphSize}
              wrapper={ModularContentWrapper}
              wrapperClassName={props.contentAlignment || "left"}
            />
          </CardWrapper>
        )}
      </>
    ),
    [props]
  );

  if (props.link) {
    return <Link href={props.link}>{content}</Link>;
  }

  return content;
};
export const CardRenderer = renderer({
  typeName: "CardRecord",
  view: CardView,
  groupComponent: CardViewGroupWrapper,
});

const CardWrapper = styled.div`
  margin: 20px;
  width: 300px;
  &.border {
    border: 1px solid #e5e1e6;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 40px 0px;
  }

  @media only screen and (min-width: 1170px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const CardGroup = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  margin: 0 auto;
  @media only screen and (min-width: 1170px) {
    &:not(.is-single) {
      justify-content: center;
      gap: ${({ theme }) => theme.spacing[48]};
    }
  }
`;

const CardImage = styled(Image)`
  position: relative;
  object-fit: contain;
  margin-bottom: 24px;
  &.rounded {
    border-radius: 6px;
    object-fit: cover;
  }

  &.small {
    display: block;
    margin: 0 auto;
    height: 50px;
  }
  &.medium {
    width: 100%;
    height: 150px;
  }
  &.large {
    width: 100%;
    height: 250px;
  }
  &.left {
    object-position: left;
  }
  &.center {
    object-position: center;
  }
  &.right {
    object-position: right;
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 18px;
  font-size: ${(props: { fontSize: number }) => `${props.fontSize}px`};
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
`;

const ModularContentWrapper = styled.div`
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
`;
