import { Database } from "database";
import { GetAllProfileTagsDocument } from "generated/graphql-typed";
import { graphqlQuery } from "services/graphql";
import { FollowUser, ProfileTag } from "shared/user.types";
import { assertFieldsNotNull } from "utils/assertions";
import {
  DatoProfileTag,
  UserProfile,
  UserProfileParams,
} from "./profile.types";

export const getUserDisplayName = (
  user: Pick<
    UserProfileParams,
    "displayName" | "lastName" | "firstName" | "organisationLogoUrl"
  > | null
) => {
  if (user?.displayName) {
    return `${user.displayName}`;
  }
  if (user?.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user?.firstName) {
    return `${user.firstName}`;
  }
  return "";
};

export const PRONOUNS_MAP: Record<string, string> = {
  she: "She/Her",
  he: "He/Him",
  they: "They/Them",
};

export const transformProfileTag = (tag: DatoProfileTag): ProfileTag => {
  const { title, slug } = assertFieldsNotNull(tag, ["title", "slug"]);
  const icon = tag.icon
    ? {
        icon: {
          url: tag.icon?.url || "",
          alt: tag.icon?.alt || `${title} tag`,
        },
      }
    : {};
  return {
    title,
    slug,
    backgroundColor: tag.backgroundColor?.hex,
    ...icon,
  };
};

export async function getAllTransformedTags() {
  const { allProfileTags } = await graphqlQuery(GetAllProfileTagsDocument);
  const formattedAllTags = allProfileTags.map(transformProfileTag);
  return formattedAllTags;
}

export const PROFILE_FIELDS: Array<keyof UserProfileParams> = [
  "firstName",
  "lastName",
  "email",
  "headline",
  "avatarUrl",
  "isPublic",
  "location",
  "pronouns",
  "pronounciation",
  "oneLiner",
  "displayName",
  "company",
  "profileTags",
  "twitter",
  "linkedin",
  "isHost",
  "vanityUrl",
  "orgProfileTags",
  "timezone",
  "isPrivateProfile",
];

type ProfileData = {
  user: UserProfile & { _id: string; email: string };
  isFollowing: boolean;
  followers: FollowUser[];
  following: FollowUser[];
};

export const fetchProfileData = async (
  db: Database,
  profileUserExternalId: string,
  viewerExternalId?: string
): Promise<ProfileData | null> => {
  const rawUser = await db.users.findOneByExternalId(profileUserExternalId, [
    ...PROFILE_FIELDS,
    "externalId",
    "organisation",
    "onlineStatus",
    "_id",
    "referralCode",
  ]);

  if (!rawUser) {
    return null;
  }

  const { organisation, onlineStatus, ...user } = rawUser;

  const additionalFields: UserProfileParams = {
    ...(onlineStatus?.timezone ? { timezone: onlineStatus?.timezone } : {}),
  };

  let isFollowing = false;
  // User is logged in
  if (viewerExternalId) {
    isFollowing = !!(await db.follows.findOne({
      followerId: viewerExternalId,
      followingId: profileUserExternalId,
    }));

    const viewerOrganisation = await db.users.findOneByExternalId(
      viewerExternalId,
      ["organisation"]
    );

    // If the viewer and the person in the organisation are the same, we add the organisation to the user
    if (
      organisation &&
      organisation.slug === viewerOrganisation?.organisation?.slug
    ) {
      const viewerOrganisation = await db.organisations.findBySlug(
        organisation.slug
      );

      if (viewerOrganisation) {
        additionalFields.organisationLogoUrl = viewerOrganisation.logoUrl;
      }
    }
  }

  const [followers, following] = await Promise.all([
    db.follows.findFollowers(profileUserExternalId),
    db.follows.findFollowing(profileUserExternalId),
  ]);

  return {
    user: { ...user, _id: user._id.toHexString(), ...additionalFields },
    isFollowing,
    followers,
    following,
  };
};
