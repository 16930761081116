"use client";
import styled from "@emotion/styled";
import { ModularContent } from "features/modular-content";
import { Box, Text } from "design-system/components";
import Image from "next/image";
import { useState } from "react";

type EventCardProps = {
  icon: {
    url: string;
  };
  displayName: string;
  description: string;
  longDescription: string;
  timeDescription?: string;
  richDescription?: {
    value: any;
  } | null;
};

export const EventCard: React.FC<EventCardProps> = ({
  icon,
  displayName,
  description,
  longDescription,
  richDescription,
  timeDescription,
}) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <EventContainer>
      <Box className="flex align-center gap-12">
        {icon.url && (
          <Image
            src={icon.url}
            height="40"
            width="40"
            alt={`${displayName} icon`}
          />
        )}

        <NameWrapper>
          <Text fontSize="lg" fontWeight={700}>
            {displayName}
          </Text>
          {timeDescription && (
            <EventTimeDescription>{timeDescription}</EventTimeDescription>
          )}
        </NameWrapper>
      </Box>
      <EventShortDescription>{description}</EventShortDescription>
      <ToggleContainer>
        <ToggleButton
          onClick={() => {
            setShowContent(!showContent);
          }}
          css={(theme) => ({ color: theme.colors.black })}
        >
          <ToggleIcon
            src={"/assets/toggle/arrow-icon.svg"}
            className={showContent ? "show" : undefined}
          />
          Learn more
        </ToggleButton>
        <ToggleContent className={showContent ? "show" : undefined}>
          {richDescription ? (
            <ModularContent paragraphSize={16} data={richDescription} />
          ) : (
            <EventLongDescription>{longDescription}</EventLongDescription>
          )}
        </ToggleContent>
      </ToggleContainer>
    </EventContainer>
  );
};

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.spacing[20]};
  max-width: 400px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e1e6;
  box-shadow: -8px 9px 44px rgba(17, 20, 49, 0.12);
  border-radius: 8px;
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
  margin: 1rem 0 0;
  justify-content: space-between;
`;

const EventShortDescription = styled.div`
  margin-top: 8px;
`;

const EventLongDescription = styled.div`
  white-space: pre-wrap;
`;

const ToggleButton = styled.button`
  outline: none;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 10px;
`;

const ToggleIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 20px;
  transition: transform 0.4s;
  &.show {
    transform: rotateZ(90deg);
  }
`;

const ToggleContent = styled.div`
  display: none;
  &.show {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
`;

const ToggleContainer = styled.div`
  ${({ theme }) => theme.media["bp-tablet"]} {
    margin-bottom: 1.25rem;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const EventTimeDescription = styled.div`
  font-size: 14px;
  color: #727073;
  margin-top: 0.5rem;
`;
