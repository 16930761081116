import { Box, Icon, Text } from "design-system/components";
import { token } from "styled-system/tokens";
import { cn } from "design-system/styles/utility-classes";
import { styled } from "styled-system/jsx";
import { MusicStatus } from "shared/user.types";
import { useMusic } from "features/music/client";

type IntentionMusicStatusProps = {
  musicStatus: MusicStatus;
};

export const IntentionMusicStatus: React.FC<IntentionMusicStatusProps> = ({
  musicStatus,
}) => {
  const { loadUrl, play } = useMusic();
  const isYoutubeTrack = musicStatus?.platform === "youtube";

  return (
    <Box className={cn(".flex", ".align-center", ".gap-4", ".mar-top-4")}>
      <Icon
        color={token("colors.grey300")}
        icon={isYoutubeTrack ? "music" : "spotify"}
        size={12}
      />
      <MusicStatusCTA
        title={isYoutubeTrack ? "Listen to now" : "Open spotify playlist"}
        onClick={() => {
          if (isYoutubeTrack) {
            loadUrl(musicStatus.url);
            setTimeout(() => play(), 1000);
          } else {
            window.open(musicStatus.url, "_blank");
          }
        }}
      >
        <Text
          className="text-overflow-ellipsis"
          fontSize="xs"
          fontWeight={300}
          charsPerLine={32}
        >
          {musicStatus.title}
        </Text>
        <Icon
          className="play-icon"
          icon={"play"}
          size={14}
          fill={token("colors.grey300")}
        />
      </MusicStatusCTA>
    </Box>
  );
};

const MusicStatusCTA = styled("button", {
  base: {
    alignItems: "center",
    color: "grey300",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "&:hover": {
      textDecoration: "underline",
      "& .play-icon": { opacity: 1 },
    },
    "& .play-icon": {
      opacity: 0,
      transition: "opacity 0.2s ease",
    },
  },
});
