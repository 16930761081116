import styled from "@emotion/styled";
import { ReactNode } from "react";
import { DSComponentPropsProvider } from "../design-system.types";
import Image from "next/image";

export type TagProps = DSComponentPropsProvider & {
  children?: ReactNode;
  isSelected: boolean;
  size?: "small" | "medium";
  icon?: {
    url: string;
    alt: string;
  };
};

export const Tag = ({
  children,
  isSelected,
  onClick,
  size = "medium",
  icon,
  ...props
}: TagProps) => (
  <StyledTag
    type="button"
    {...props}
    className={[
      isSelected ? "selected" : "",
      onClick ? "cursor-pointer clickable" : "",
      size,
    ]
      .filter(Boolean)
      .join(" ")}
    onClick={onClick}
  >
    {icon ? (
      <Image
        className="mar-right-8"
        width={16}
        height={16}
        src={icon.url}
        alt={icon.alt}
      />
    ) : null}
    {children}
  </StyledTag>
);

const StyledTag = styled.button(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.radii.round,
  display: "flex",
  flexDirection: "row",
  fontSize: theme.fontSizes.xs,
  fontWeight: 600,
  padding: `${theme.spacing[8]} ${theme.spacing[12]}`,
  textAlign: "center",
  transition: "all 0.2s ease-in-out",
  background: theme.colors.white,
  cursor: "default",
  border: `1px solid ${theme.colors.grey}`,

  "&:focus": {
    boxShadow: "none",
    outline: "none",
  },

  "&.clickable": {
    "&:focus": {
      backgroundColor: theme.colors.blueGrey100,
      outline: `1px solid ${theme.colors.focusDark}`,
    },
    "&:active": {
      backgroundColor: theme.colors.focusMid,
      outline: `1px solid ${theme.colors.grey}`,
    },
    "&:hover": {
      backgroundColor: theme.colors.blueGrey100,
      outline: `1px solid ${theme.colors.grey}`,
    },

    "&.selected": {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
  },
  "&.small": {
    fontSize: theme.fontSizes["2xs"],
  },
}));
