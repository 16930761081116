import styled from "@emotion/styled";
import { ContentContainer, UnderlineButton } from "legacy-design-system";
import { ArticleResourceBlockFragment } from "generated/graphql-typed";
import { HasChildren } from "utils/component-utils";
import { renderer } from "./renderer";
import Link from "next/link";

const ArticleResourceViewGroupWrapper = (
  props: HasChildren & { className: string }
) => (
  <ResourceGroup
    className={[
      props.className,
      !Array.isArray(props.children) || props.children.length === 1
        ? "is-single"
        : undefined,
    ]
      .filter(Boolean)
      .join(" ")}
  >
    <ResourceTitle>Rabbit hole exploration</ResourceTitle>
    {props.children}
  </ResourceGroup>
);

const ArticleResourceView = ({
  image,
  className,
  resourceUrl,
  articleDescription,
  typeOfResource,
  title,
}: ArticleResourceBlockFragment & { className: string }) => (
  <>
    {
      <CardWrapper className={className}>
        {image && resourceUrl && (
          <Link href={resourceUrl}>
            <Image src={image.url} alt="" />
          </Link>
        )}
        <TextContainer>
          <TitleContainer>
            <ArticleTypeIcon src={`/assets/almanac/${typeOfResource}.svg`} />
            {resourceUrl && <Title href={resourceUrl}>{title}</Title>}
          </TitleContainer>
          <Description>{articleDescription}</Description>
        </TextContainer>
      </CardWrapper>
    }
  </>
);

export const ArticleResourceRenderer = renderer({
  typeName: "ArticleResourceRecord",
  view: ArticleResourceView,
  groupComponent: ArticleResourceViewGroupWrapper,
});

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  max-width: 770px;
  border: 1px solid #e5e1e6;
  box-sizing: border-box;
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  @media only screen and (min-width: 570px) {
    align-items: center;
    flex-direction: row;
  }
`;

const ResourceGroup = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  @media only screen and (min-width: 1170px) {
    &:not(.is-single) {
      justify-content: space-between;
    }
  }
`;
const ResourceTitle = styled.h4`
  font-size: inherit;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 174px;
  min-height: 98px;
  margin-right: 22px;
  margin-bottom: 10px;
  cursor: pointer;
`;
const TextContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const Title = styled(UnderlineButton)`
  font-weight: bold;
`;

const ArticleTypeIcon = styled.img`
  height: 22px;
  width: 22px;
  object-fit: contain;
  margin-right: 14px;
`;

const Description = styled.div``;
