import styled from "@emotion/styled";

const iconSizeDict = {
  small: 24,
  medium: 32,
  large: 40,
};

export const getStyleProps = (
  size: SocialMediaStyleProps["size"],
  color: SocialMediaStyleProps["color"]
) => ({
  buttonSizeStyles: {
    style: {
      height: `${iconSizeDict[size]}px`,
      width: `${iconSizeDict[size]}px`,
    },
  },
  iconStyles: {
    bgStyle: { fill: color },
    iconFillColor: color === "black" ? "white" : "black",
    size: iconSizeDict[size],
  },
});

export type SocialMediaCommonProps = {
  color?: "white" | "black";
  size?: keyof typeof iconSizeDict;
};

type SocialMediaStyleProps = Required<SocialMediaCommonProps>;

export const SocialMediaButton = styled.a<Pick<SocialMediaStyleProps, "color">>(
  ({ color, theme }) => ({
    backgroundColor: theme.colors[color],
    borderRadius: "9999px",
    cursor: "pointer",
    transition: "opacity 0.2s ease",
    "&:hover": {
      opacity: 0.9,
    },
  })
);
