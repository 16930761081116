import styled from "@emotion/styled";

import { ButtonProps, RoundedButton } from "./button";

const SecondaryButtonStyled = styled(RoundedButton)`
  background: white;
  border: 1px solid black;
  color: black;
  &:hover:not(:disabled) {
    border-color: var(--sea-green-strong);
    color: var(--sea-green-strong);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

// TODO if anyone wants a fun task, we can refactor Spinner to use css variables and pass down the colour that way, so we don't need this wrapper component with `spinnerColor="#000"`
export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  ...otherProps
}) => (
  <SecondaryButtonStyled {...otherProps} spinnerColor="#000">
    {children}
  </SecondaryButtonStyled>
);

export const SecondaryButtonFullWidth = styled(SecondaryButton)`
  width: 100%;
`;
