"use client";
import { useEffect, useState } from "react";
import { normalizeError } from "services/normalize-error";
import { FlownEvents } from "shared/event.types";
import { getPopulatedSessionsRegistered } from "../calendar-client";

type PopulatedSessionsRegisteredState = {
  sessionsRegistered: FlownEvents;
  status: "idle" | "loading" | "rejected" | "resolved";
  error: string;
};

export const useGetPopulatedSessionsRegistered = (userExternalId?: string) => {
  const [eventsRegisteredState, setEventsRegisteredState] =
    useState<PopulatedSessionsRegisteredState>({
      sessionsRegistered: [],
      status: "idle",
      error: "",
    });

  useEffect(() => {
    const fetchPopulatedRegisteredEvents = async () => {
      try {
        setEventsRegisteredState((prev) => ({ ...prev, status: "loading" }));
        const response = await getPopulatedSessionsRegistered(userExternalId)();
        if (!response.worked) {
          setEventsRegisteredState({
            sessionsRegistered: [],
            status: "rejected",
            error: response.error,
          });
          return;
        }
        const { sessionsRegistered } = response;
        setEventsRegisteredState({
          sessionsRegistered,
          status: "resolved",
          error: "",
        });
      } catch (error) {
        const normalizedError = normalizeError(error);
        setEventsRegisteredState({
          sessionsRegistered: [],
          status: "rejected",
          error: normalizedError.message,
        });
      }
    };
    if (eventsRegisteredState.status === "idle") {
      void fetchPopulatedRegisteredEvents();
    }
  }, [eventsRegisteredState.status, userExternalId]);

  return eventsRegisteredState;
};
