import styled from "@emotion/styled";
import dayjs from "dayjs";
import { Box, Button, Text } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { getFormattedTime, getFormattedWeekDay } from "utils/date";

import { SkeletonLoadingSessionCard } from "./skeleton-loading-session-card";

type ActivitySessionCardProps = DSComponentPropsProvider & {
  eventName: string;
  eventIcon: string;
  startTime: string;
  eventEmoji?: string;
  facilitatorImage?: string;
  facilitatorName?: string;
  springBoardUrl?: string;
  hrefTarget?: "_self" | "_blank";
};

type SkeletonLoadindingActivitySessionCardListProps = {
  listTitle: string;
};

export const SkeletonLoadindingActivitySessionCardList: React.FC<
  SkeletonLoadindingActivitySessionCardListProps
> = ({ listTitle }) => (
  <div className="flex-col gap-16">
    <Text fontWeight={700}>{listTitle}</Text>
    <SkeletonLoadingSessionCard />
    <SkeletonLoadingSessionCard />
    <SkeletonLoadingSessionCard />
  </div>
);

export const ActivitySessionCard: React.FC<ActivitySessionCardProps> = ({
  className,
  eventIcon,
  eventName,
  facilitatorName,
  startTime,
  eventEmoji,
  springBoardUrl,
  hrefTarget = "_self",
}) => {
  const isToday = dayjs(startTime).isToday();
  return (
    <CardWrapper className={`flex-col pad-16 ${className}`}>
      <Box className="flex align-center">
        <Box className="flex-col gap-8 flex-1">
          <Box className="flex gap-8 align-center">
            <Image src={eventIcon} alt="Session icon" width={16} height={16} />
            <Text fontWeight={600} fontSize={"sm"}>
              {eventEmoji} {eventName}
              {facilitatorName ? ` with ${facilitatorName}` : ""}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={400} fontSize={"xs"}>
              {getFormattedWeekDay(startTime)} at {getFormattedTime(startTime)}
            </Text>
          </Box>
        </Box>
        {springBoardUrl && (
          <Link href={springBoardUrl} target={hrefTarget}>
            <Button size="small" variant={isToday ? "primary" : "secondary"}>
              {isToday ? "Join" : "View session"}
            </Button>
          </Link>
        )}
      </Box>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.radii[12]};
`;
