"use client";
import React from "react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { UrlObject } from "url";
import { getUserTimezone } from "./date";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";

export type HasChildren = {
  children: React.ReactNode | React.ReactNode[];
};

export type NoSSRProps = {
  children: React.ReactNode | React.ReactNode[];
  onSSR?: React.ReactNode;
};

export const NoSSR = ({ children, onSSR }: NoSSRProps) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return <>{isClient ? children : onSSR}</>;
};

export type ClassNameProvider = { className?: string };

type NavigateOptions = {
  shallow?: boolean;
  scroll?: boolean;
};

type NavigateFunction = (
  url: UrlObject | string,
  options?: NavigateOptions
) => void;

export const useNavigate = (): NavigateFunction => {
  const router = useRouter();
  const routerRef = useRef(router);
  routerRef.current = router;

  // return a stable callback reference so that the navigate function can be
  // used inside a useEffect dependency array without causing re-rendering
  return useCallback<NavigateFunction>((url, options) => {
    void routerRef.current.push(url, undefined, { scroll: true, ...options });
  }, []);
};

// TODO: remove this hook and use the media query matching one below
// We also have userAgent/platform info, when does this get used
export const useIsDesktop = (
  desktopThreshold: number,
  defaultValue?: boolean
) => {
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(defaultValue);

  useEffect(() => {
    const handleResize = () => {
      const shouldBeDesktop = window.innerWidth >= desktopThreshold;
      setIsDesktop(shouldBeDesktop);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [desktopThreshold]);

  return isDesktop;
};

export const getDeviceBucket = (width: number) => {
  if (width < THEME_BREAKPOINTS["mobile-md"]) {
    return "mobile";
  } else if (width < THEME_BREAKPOINTS["desktop-xs"]) {
    return "tablet";
  } else {
    return "desktop";
  }
};

/**
 * https://medium.com/@ttennant/react-inline-styles-and-media-queries-using-a-custom-react-hook-e76fa9ec89f6
 */
export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaMatch = window?.matchMedia(query);
    if (mediaMatch.matches !== matches) {
      setMatches(mediaMatch.matches);
    }
    const handler = () => setMatches(mediaMatch.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  }, [query, matches]);
  return matches;
};

export const useIsMediaDesktop = () =>
  useMediaQuery(`(min-width: ${THEME_BREAKPOINTS["desktop-xs"]}px)`);

export const useIsMediaTablet = () =>
  useMediaQuery(`(min-width: ${THEME_BREAKPOINTS.tablet}px)`);

export const useIsMediaTabletOrLower = () =>
  useMediaQuery(`(max-width: ${THEME_BREAKPOINTS.tablet}px)`);

export const useIsMediaAboveMobileMd = () =>
  useMediaQuery(`(min-width: ${THEME_BREAKPOINTS["mobile-md"]}px)`);

export const useIsMediaAboveMobile = () =>
  useMediaQuery(`(min-width: ${THEME_BREAKPOINTS["mobile"]}px)`);

export const useUserTimezone = () => {
  const [userTimezone, setUserTimezone] = useState<string>("Europe/London");

  const formattedUserTimezone = userTimezone
    .replace("_", " ")
    .replace("Kiev", "Kyiv");

  useEffect(() => {
    setUserTimezone(getUserTimezone());
  }, []);
  return { userTimezone, formattedUserTimezone };
};

type ImageParams = {
  url: string;
  width: number;
  height: number;
  cover?: boolean;
};

export const formatCount = (count: number, item: string) => {
  if (item === "person") {
    return count === 1 ? "1 person" : `${count} people`;
  }
  return `${count} ${item}${count === 1 ? "" : "s"}`;
};

export const isPositive = (number: number | null | undefined) =>
  number != null && number > 0;

export const getImageUrl = ({ url, width, height, cover }: ImageParams) => {
  if (url.includes(".svg") || url.includes(".gif")) return url;
  const scale = url.endsWith(".png") ? 2 : 1.5;
  const fit = cover ? "min" : "max";
  return (
    url +
    (url.includes("?") ? "&" : "?") +
    `w=${width * scale}&h=${height * scale}&fit=${fit}&q=50`
  );
};

export const sortBy = function <T>(arr: T[], key: keyof T) {
  return arr
    .slice()
    .sort((a: T, b: T) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
};

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
