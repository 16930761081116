import styled from "@emotion/styled";
import { BackgroundChangeBlockFragment } from "generated/graphql-typed";
import Image from "next/image";
import React, {
  useEffect,
  useState,
  useRef,
  Children,
  cloneElement,
} from "react";
import { getImageUrl } from "utils/component-utils";
import { renderer } from "./renderer";
import { modularContentStyle } from "./styles";
import { CSS_CLASSES } from "design-system/styles/global-styles";
import { SPACING } from "../shared/consts";

type BackgroundChangeViewProps = BackgroundChangeBlockFragment & {
  className: string;
  nextColor?: string;
  children?: any;
};

const defaultBackgroundColor = "#fbfafb";

const BackgroundChangeView = (props: BackgroundChangeViewProps) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [backgroundImgUrl, setBackgroundImgUrl] = useState("");
  useEffect(() => {
    if (!props.backgroundImage || !backgroundRef.current) return;
    const backgroundWidth = backgroundRef.current.offsetWidth;
    const backgroundHeight = backgroundRef.current.offsetHeight;
    setBackgroundImgUrl(
      getImageUrl({
        url: props.backgroundImage.url,
        width: backgroundWidth,
        height: backgroundHeight,
        cover: true,
      })
    );
  }, [props.backgroundImage]);

  const spacingClass =
    SPACING[(props.spacing as keyof typeof SPACING) || "standard"];

  const orientationClass = props.displayAsRow ? CSS_CLASSES.DISPLAY_ROW : "";

  return (
    <BackgroundChange
      className={[
        props.className,
        "has-content-container",
        props.showCurve ? "has-curve" : undefined,
        "background-change",
        props.hasRoundedCorners ? "rounded-corners" : undefined,
        spacingClass,
        orientationClass,
      ]
        .filter(Boolean)
        .join(" ")}
      ref={backgroundRef}
      style={{
        backgroundColor: props.color?.hex || undefined,
      }}
    >
      {backgroundImgUrl && (
        <Image
          src={backgroundImgUrl}
          fill
          alt={props.backgroundImage?.alt || ""}
          style={{ zIndex: -1 }}
        />
      )}
      {Children.map(props.children, (child) =>
        cloneElement(child, {
          className: `${spacingClass} ${orientationClass} ${
            child.props.className || ""
          }`,
        })
      )}
      {props.showCurve ? (
        <Curve
          viewBox="0 0 1366 300"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
        >
          <path
            d="M1368 0l-144.62 33.555c-170.91 39.931-329.034 113.742-461.847 217.471l-35.794 27.984c-23.874 18.665-59.604 18.665-83.478 0l-35.794-27.984C448.489 115.09 245.667 32.066 0 0v300h1368V0z"
            fill={props.nextColor || defaultBackgroundColor}
            fillRule="nonzero"
          />
        </Curve>
      ) : null}
    </BackgroundChange>
  );
};

export const BackgroundChangeRenderer = renderer({
  typeName: "BackgroundChangeRecord",
  view: BackgroundChangeView,
});

const BackgroundChange = styled.div`
  ${modularContentStyle};
  position: relative;
  margin: 0 !important;
  padding: 20px 0;

  @media (min-width: 500px) {
    padding: 50px 0;
    &.${CSS_CLASSES.SMALL_SPACING} {
      padding: 20px 0;
    }
    &.${CSS_CLASSES.MEDIUM_SPACING} {
      padding: 40px 0;
    }

    &.${CSS_CLASSES.DISPLAY_ROW} {
      text-align: center;
    }
  }
  &.has-curve {
    padding-bottom: 0px;
    @media (min-width: 500px) {
      padding: 50px 0 0;
    }
  }

  // For rounded corners remove the margin override and add some space top and bottom
  &.rounded-corners {
    max-width: ${({ theme }) => theme.maxWidth.content};
    margin: 2% ${({ theme }) => theme.spacing[16]} !important;
    border-radius: ${({ theme }) => theme.radii[16]};
    padding: ${({ theme }) => theme.spacing[24]};
    ${({ theme }) => theme.media["bp-desktop-md"]} {
      margin: 2% auto !important;
    }
    overflow: hidden;
  }
`;

const Curve = styled.svg`
  display: block;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
`;
export const isRenderedBackgroundChange = (fragment: any) =>
  fragment?.type === BackgroundChangeView;
