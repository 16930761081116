import fetchBuilder from "fetch-retry-ts";

const options = {
  retries: 3,
  retryDelay: 1000,
  retryOn: [419, 500, 502, 503, 504],
};

/**
 * ! Only use within a browser (client-side) context
 */
export const fetchRetryClient = fetchBuilder(fetch, options);
