import { Text, Box, Button, TextError } from "design-system/components";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "design-system/organisms/modals";
import dayjs from "dayjs";

import { DatePicker } from "design-system/molecules/date-picker";
import { getFormattedDay, roundToNearestMinutes } from "utils/date";
import { TimePicker } from "design-system/molecules/time-picker";

type TimePickerModalProps = {
  open: boolean;
  initialStartTime?: string;
  onClose: () => void;
  onTimeSubmit: (timeState: TimePickerFormState) => Promise<void>;
  onJoinClicked: () => void;
};

export type TimePickerFormState = {
  startTime: string;
  endTime: string;
};

export const TimePickerModal: React.FC<TimePickerModalProps> = ({
  open,
  initialStartTime,
  onClose,
  onTimeSubmit,
  onJoinClicked,
}) => {
  const [hasChosenPreset, setHasChosenPreset] = useState(false);
  const [hasChosenCustom, setHasChosenCustom] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  const initialFormState = useMemo(() => {
    const now = roundToNearestMinutes().toISOString();
    const isInitialStartTimeInThePast = initialStartTime
      ? roundToNearestMinutes(initialStartTime).toISOString() <= now
      : false;
    if (isInitialStartTimeInThePast) {
      return {
        startTime: now,
        endTime: dayjs(now).add(1, "hour").toISOString(),
      };
    }

    if (initialStartTime) {
      const initialStartTimeRounded =
        roundToNearestMinutes(initialStartTime).toISOString();
      return {
        startTime: initialStartTimeRounded,
        endTime: dayjs(initialStartTimeRounded).add(1, "hour").toISOString(),
      };
    } else {
      return {
        startTime: now,
        endTime: dayjs(now).add(1, "hour").toISOString(),
      };
    }
  }, [initialStartTime]);

  const [formState, setFormState] =
    useState<TimePickerFormState>(initialFormState);

  useEffect(() => {
    setFormState(initialFormState);
  }, [initialFormState]);

  const resetForm = () => {
    setFormState(initialFormState);
  };

  /**
   * By default, the time picker will be set to the current day. This allows
   * the user to select a time on another day in the future.
   */
  const [isAnotherDay, setIsAnotherDay] = useState(false);

  const { startTime, endTime } = formState;

  const onSubmit = async () => {
    setIsBooking(true);
    await onTimeSubmit(formState);
    setIsBooking(false);
    onModalClose();
  };

  const onModalClose = () => {
    onClose();
    resetForm();
    setHasChosenCustom(false);
    setHasChosenPreset(false);
    setIsAnotherDay(false);
  };

  const hasError = () => {
    const start = dayjs(startTime);
    const end = dayjs(endTime);
    return end.isBefore(start);
  };

  const addTime = (time: number, unit: "hour" | "minute") => {
    setFormState((prev) => ({
      ...prev,
      endTime: dayjs(prev.startTime).add(time, unit).toISOString(),
    }));
    setHasChosenPreset(true);
    setHasChosenCustom(false);
  };

  return (
    <Modal
      allowContentOverflow
      preventDismissOnOverlayClick
      open={open}
      title={
        <Text className="pad-24" fontSize="xl" fontWeight={700}>
          Pick a time to book the Drop-In
        </Text>
      }
      desktopPopup
      onClose={onModalClose}
    >
      <Box className="flex-col gap-16 w-full pad-x-24 pad-bot-24">
        <Box>
          <Text fontSize="sm">
            The Drop-In runs all day, every day and you can join and leave
            anytime. Use the form below to book a slot to timebox your focus
            session and get an invite in your calendar.<br></br>
            <span
              className="decor-underline cursor-pointer"
              onClick={onJoinClicked}
            >
              Click here to join the Drop-In now.
            </span>
          </Text>
        </Box>
        <div className="flex align-center gap-16">
          <Text>Booking length:</Text>
          <Button size="small" onClick={() => addTime(30, "minute")}>
            30 minutes
          </Button>
          <Button size="small" onClick={() => addTime(1, "hour")}>
            1 hour
          </Button>
          <Button size="small" onClick={() => addTime(2, "hour")}>
            2 hours
          </Button>
          <Button
            size="small"
            onClick={() => {
              setHasChosenCustom(true);
              setIsAnotherDay(true);
            }}
          >
            Other
          </Button>
        </div>
        {(hasChosenPreset || hasChosenCustom) && (
          <>
            <Text fontSize={"lg"} fontWeight={700}>
              Your booking:
            </Text>
            <form className="flex gap-16 align-center">
              <Text fontSize="sm">{getFormattedDay(startTime)} from </Text>
              <Box className="flex-col gap-4">
                <Box className="flex gap-32">
                  <TimePicker
                    id="pick-time-start-time"
                    value={dayjs(startTime)}
                    onChange={(date) => {
                      if (date) {
                        setFormState((prev) => ({
                          ...prev,
                          startTime: date.toISOString(),
                          endTime: dayjs(date).add(1, "hour").toISOString(),
                        }));
                      }
                    }}
                  />
                </Box>
              </Box>
              <Text fontSize="sm">to</Text>
              <Box className="flex-col gap-4">
                <TimePicker
                  id="time-pick-end-time"
                  value={dayjs(endTime)}
                  disabled={hasChosenPreset}
                  onChange={(date) => {
                    if (date) {
                      setFormState((prev) => ({
                        ...prev,
                        endTime: date.toISOString(),
                      }));
                    }
                  }}
                />
              </Box>
              {hasError() && (
                <TextError>End time must be after start time</TextError>
              )}
            </form>
          </>
        )}
        {(hasChosenPreset || hasChosenCustom) && (
          <Box>
            <Text
              className="cursor-pointer mar-bot-16"
              onClick={() => setIsAnotherDay((prev) => !prev)}
              fontSize="xs"
            >
              Book for another day?
            </Text>
            {isAnotherDay && (
              <DatePicker
                id="pick-time-another-start-time"
                value={dayjs(startTime)}
                onChange={(date) => {
                  if (date) {
                    setFormState((prev) => ({
                      ...prev,
                      startTime: date.toISOString(),
                      endTime: dayjs(date).add(1, "hour").toISOString(),
                    }));
                  }
                }}
              />
            )}
          </Box>
        )}

        <Box className="flex align-center justify-end gap-8">
          <Button
            type="button"
            className="min-w-100"
            variant="ghost"
            onClick={(e) => {
              e.preventDefault();
              onModalClose();
            }}
            dataCy="time-pick-cancel-button"
          >
            Cancel
          </Button>
          <Button
            disabled={hasError()}
            type="button"
            onClick={() => onSubmit()}
            isLoading={isBooking}
          >
            Book
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
