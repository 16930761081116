import styled from "@emotion/styled";
import { TestimonialCarouselBlockFragment } from "generated/graphql-typed";

import { ModularContent } from "./modular-content";
import { renderer } from "./renderer";
import { notEmpty } from "utils/assertions";

import Image from "next/image";
import { Box } from "design-system/components";
import { Carousel } from "design-system/molecules/carousel";

const TestimonialCarouselView = (
  props: TestimonialCarouselBlockFragment & { className: string }
) => (
  <CarouselContainer className={props.className}>
    <Carousel
      id={props.id}
      autoplay={{ delay: 10000 }}
      pagination={{ clickable: true }}
      style={{ width: "100%" }}
    >
      {props.testimonials &&
        props.testimonials.map((testimonial, index) => (
          <OutsideDotsContainer key={index}>
            <Testimonial {...testimonial} />
          </OutsideDotsContainer>
        ))}
    </Carousel>
  </CarouselContainer>
);

export const TestimonialCarouselRenderer = renderer({
  typeName: "TestimonialCarouselRecord",
  view: TestimonialCarouselView,
});

const OutsideDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  padding-bottom: 3rem;
`;

const Testimonial = (
  props: TestimonialCarouselBlockFragment["testimonials"][number]
) => (
  <TestimonialContainer className="testimonial-container">
    {props.testimonialTitle && (
      <TestimonialTitle>{props.testimonialTitle}</TestimonialTitle>
    )}
    {props.image?.url && (
      <Box
        className="pos-relative"
        style={{ gridArea: "image", height: "12.5rem" }}
      >
        <TestimonialImg
          src={props.image?.url}
          fill
          alt={props.image.alt || `Headshot of ${props.name}`}
        />
      </Box>
    )}
    <TestimonialText>
      <ModularContent
        data={props.content}
        paragraphSize={props.paragraphSize}
        wrapper={TestimonialDescription}
      />
      <div className="testimonial-name-job-wrapper">
        {notEmpty(props.name) && (
          <TestimonialName className="testimonial-name">
            {props.name}
          </TestimonialName>
        )}
        {notEmpty(props.jobTitle) && (
          <TestimonialJobTitle className="testimonial-job">
            {props.jobTitle}
          </TestimonialJobTitle>
        )}
      </div>
    </TestimonialText>
  </TestimonialContainer>
);

const TestimonialContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 807px;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e1e6;
  border-radius: 8px;
  padding: 15px 20px 21px 20px;
  user-select: none;
  @media only screen and (min-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title title" "image content";
    padding: 1.5rem 2rem 1.5rem 1rem;
  }
  @media only screen and (min-width: 999.9px) {
    flex-direction: row;
  }
`;

const TestimonialTitle = styled.h3`
  font-size: 1.25rem;
  grid-area: title;
`;

const TestimonialImg = styled(Image)`
  object-fit: contain;
  margin: 0 auto 1rem;
  @media only screen and (min-width: 999.9px) {
    align-self: center;

    margin-bottom: 0.5rem;
  }
`;

const TestimonialText = styled.div`
  text-align: left;
  @media only screen and (min-width: 999.9px) {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    grid-area: content;
  }
`;
const TestimonialDescription = styled.div`
  margin-bottom: 25px;
  max-width: 370px;
`;

const TestimonialName = styled.p`
  font-weight: bold;
  margin-bottom: 2px;
  @media only screen and (min-width: 999.9px) {
    margin-top: auto;
  }
`;

const TestimonialJobTitle = styled.p``;

const CarouselContainer = styled.div`
  margin: 0 auto;
  max-width: 807px;

  &.register-testimonial {
    .testimonial-container {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }

    .testimonial-name-job-wrapper {
      display: flex;
    }

    .testimonial-name,
    .testimonial-job {
      font-size: 0.875rem;
    }

    .testimonial-name:after {
      content: " - ";
    }
  }
`;
