import styled from "@emotion/styled";
import ArrowSVG from "/public/assets/adornments/arrow-looping.svg";
import CurvedLineSVG from "/public/assets/adornments/curved-line.svg";
import TwirlAdornmentSVG from "/public/assets/adornments/twirl.svg";

export const ArrowAdornment = styled(ArrowSVG)(() => ({
  position: "absolute",
  top: "-2.875rem",
  left: "-2.875rem",
  transform: "scale(0.6)",
}));

export const CurvedAdornment = styled(CurvedLineSVG)(() => ({
  position: "absolute",
  top: "0.75rem",
  right: "2.5rem",
  transform: "scale(0.6)",
}));

export const TwirlAdornment = styled(TwirlAdornmentSVG)(({ theme }) => ({
  display: "none",
  [theme.media["bp-tablet"]]: {
    display: "block",
    position: "absolute",
    top: "-1.25rem",
    right: "2rem",
  },
}));
