import { createGetEndpoint } from "utils/create-get-endpoint";
import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  GetEventsRegisteredResponse,
  GetPopulatedSessionsRegisteredResponse,
  GetPopulatedSessionsAttendedResponse,
  GetUsersRegisteredForSessionRequest,
  GetUsersRegisteredForSessionResponse,
  GetPopulatedUsersInSessionRequest,
  GetPopulatedUsersInSessionResponse,
  GetSessionsAttendedResponse,
  GetCreatedRoomsResponse,
  GetAdditionalEventTagsResponse,
} from "../shared/event-types";

export const getEventsRegistered =
  createGetEndpoint<GetEventsRegisteredResponse>(
    "/api/events/getEventsRegistered"
  );

export const getPopulatedSessionsRegistered = (userExternalId?: string) =>
  createGetEndpoint<GetPopulatedSessionsRegisteredResponse>(
    `/api/events/get-populated-sessions-registered${
      userExternalId ? `/${userExternalId}` : ""
    }`
  );

export const getPopulatedSessionsJoined = (userExternalId?: string) =>
  createGetEndpoint<GetPopulatedSessionsAttendedResponse>(
    `/api/events/populated-sessions-joined${
      userExternalId ? `/${userExternalId}` : ""
    }`
  );
export const getSessionsAttendedEndpoint =
  createGetEndpoint<GetSessionsAttendedResponse>(
    "/api/events/get-sessions-attended"
  );

export const getPopulatedCreatedRooms =
  createGetEndpoint<GetCreatedRoomsResponse>(
    "/api/events/populated-created-rooms"
  );

export const postToGetUsersRegisteredForSession = createPostEndpoint<
  GetUsersRegisteredForSessionRequest,
  GetUsersRegisteredForSessionResponse
>("/api/events/get-users-registered-for-session");

export const postToGetPopulatedUsersInSession = createPostEndpoint<
  GetPopulatedUsersInSessionRequest,
  GetPopulatedUsersInSessionResponse
>("/api/events/get-populated-users-in-session");

export const getAdditionalEventTags =
  createGetEndpoint<GetAdditionalEventTagsResponse>(
    "/api/events/get-additional-tags"
  );
