import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  SendSingleEventEmailRequest,
  SendSingleEventEmailResponse,
} from "./send-email-invite-handler";

export const postToSendSingleEventEmail = createPostEndpoint<
  SendSingleEventEmailRequest,
  SendSingleEventEmailResponse
>("/api/flocks/send-single-event-email");

export const postToSendSingleEventCancelEmail = createPostEndpoint<
  SendSingleEventEmailRequest,
  SendSingleEventEmailResponse
>("/api/flocks/send-single-event-cancel-email");
