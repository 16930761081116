import dayjs, { Dayjs } from "dayjs";
import { ValidDate } from "utils/date";
import { lowercaseTrim } from "utils/string-utils";
import { FlownEvents } from "shared/event.types";
import { EventFilters } from "./event-types";

export type EventsSliceArgs = {
  numberOfDays: number;
  skipSorting?: boolean;
  startDate: ValidDate;
  timezone?: string;
  filter: Partial<EventFilters>;
};

/**
 * Apply a first layer of filtering to the events as they are populated from the API.
 * This includes a time interval, tags and categories.
 *
 * @param events The calendar events from the API. This method will generally receive all the events we have.
 * @param filters The filters to apply to the events.
 * @returns
 */
export const getFilteredEvents = (
  events: FlownEvents,
  filters: Partial<EventFilters>
) => {
  const { categories, tags, facilitatorName } = filters;
  const filteredEvents = events.filter((event) => {
    if (
      categories &&
      categories.length > 0 &&
      !categories.includes(event.category.slug)
    ) {
      return false;
    }
    if (
      tags &&
      tags.length > 0 &&
      !event.tags?.some((tag) => tag.slug && tags.includes(tag.slug))
    ) {
      return false;
    }

    if (facilitatorName) {
      if (!event.facilitator?.name) return false;
      const matchesFacilitatorName =
        lowercaseTrim(event.facilitator?.name) ===
        lowercaseTrim(facilitatorName);
      if (!matchesFacilitatorName) return false;
    }

    // The event passed all the filters.
    return true;
  });
  return filteredEvents;
};

/**
 * This method returns the days to show for a given calendar, based on
 * a set on the start date and number of days.
 */
export const getBoundedCalendar = (
  allEvents: FlownEvents,
  { startDate, numberOfDays }: { startDate: Dayjs; numberOfDays: number }
) => {
  // Bound calendar from the calculated start date and that same date plus
  // the number of days to show.
  const boundedEvents = allEvents.filter(
    (event) =>
      dayjs(event.endTime) > startDate &&
      dayjs(event.endTime) < startDate.add(numberOfDays, "day")
  );
  return boundedEvents;
};
