import { DSComponentPropsProvider } from "design-system/design-system.types";
import { useIntentions } from "../hooks/use-intentions";
import { AccountabilityWall } from "./accountability-wall";

type AccountabilityWallContainerProps = {
  onUserIntentionCardClick?: (userExternalId: string) => void;
  sessionId?: string;
  userExternalId?: string;
  limitIntentions?: number;
  onlyTodaysIntentions?: boolean;
  organisationId?: string;
  withPrivateIntentions?: boolean;
} & Pick<DSComponentPropsProvider, "style" | "className">;

export const AccountabilityWallContainer = ({
  sessionId,
  userExternalId,
  limitIntentions,
  onlyTodaysIntentions,
  organisationId,
  withPrivateIntentions,
  ...props
}: AccountabilityWallContainerProps) => {
  const intentionsData = useIntentions({
    sessionId,
    userExternalId,
    limitIntentions,
    onlyTodaysIntentions,
    organisationId,
    withPrivateIntentions,
  });

  return (
    <AccountabilityWall
      {...intentionsData}
      hideUsernameAndAvatarOnIntentionCard={!!userExternalId}
      {...props}
    />
  );
};
