import { ShareBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { SocialMediaShareButtons } from "design-system/molecules/social-media/social-media-share-buttons";
import { assertFieldsNotNull } from "utils/assertions";
import { useUserContext } from "context/user-context";
import { Box, Text } from "design-system/components";
import styled from "@emotion/styled";

export const ShareBlockView = (
  props: ShareBlockFragment & { className: string }
) => {
  const { referralCode, loggedIn } = useUserContext();
  const { title, shareUrl } = assertFieldsNotNull(props, ["title", "shareUrl"]);

  const shareUrlWithReferral = `${shareUrl}${
    props.includeReferral && loggedIn ? `?referral=${referralCode}` : ""
  }`;
  return (
    <ShareBlockBox
      className={`flex-col align-center gap-16 border-black pad-16 ${
        props.className ? props.className : ""
      }`}
    >
      <Text fontWeight={700}>{title}</Text>
      <SocialMediaShareButtons title={title} shareUrl={shareUrlWithReferral} />
    </ShareBlockBox>
  );
};

const ShareBlockBox = styled(Box)`
  max-width: 450px;
  margin: 0 auto;
`;

export const ShareBlockRenderer = renderer({
  typeName: "ShareBlockRecord",
  view: ShareBlockView,
});
