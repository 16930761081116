"use client";
import { useEffect, useState } from "react";
import { normalizeError } from "services/normalize-error";
import { RequestStatus } from "shared/response.types";
import { getInvitesToSession } from "../sessions-client";
import { UserActivity } from "shared/user-activities.types";

type SessionInvitesState = {
  sessionInvites: UserActivity[];
  status: RequestStatus;
  error: string;
};

export const useSessionInvites = (eventId: string) => {
  const [sessionInvitesState, setSessionInvitesState] =
    useState<SessionInvitesState>({
      sessionInvites: [],
      status: "idle",
      error: "",
    });
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        setSessionInvitesState((prev) => ({ ...prev, status: "loading" }));
        const response = await getInvitesToSession(eventId)();
        if (!response.worked) {
          setSessionInvitesState({
            sessionInvites: [],
            status: "rejected",
            error: response.error,
          });
          return;
        }
        const { sessionInvites } = response;
        setSessionInvitesState({
          sessionInvites,
          status: "resolved",
          error: "",
        });
      } catch (error) {
        const normalizedError = normalizeError(error);
        setSessionInvitesState({
          sessionInvites: [],
          status: "rejected",
          error: normalizedError.message,
        });
      }
    };
    if (sessionInvitesState.status === "idle" && eventId) {
      void fetchRooms();
    }
  }, [sessionInvitesState.status, eventId]);

  return [sessionInvitesState, setSessionInvitesState] as const;
};
