import Link from "next/link";
import { styled } from "styled-system/jsx";
import { Spinner } from "./spinner";
import { css } from "styled-system/css";
import { ComponentProps, useEffect, useState } from "react";

type ButtonProps = React.ComponentProps<typeof StyledButton> & {
  full?: boolean;
  minWidth?: number;
  isLoading?: boolean;
  as?: "a" | "button";
  dataCy?: string;
  /**
   * @param {string} href - The href to navigate to when the button is clicked. To be used in conjunction with `as="a"`.
   */
  href?: React.AnchorHTMLAttributes<HTMLElement>["href"];
  target?: React.AnchorHTMLAttributes<HTMLElement>["target"];
  /**
   * @param {boolean} isLoadingOnClick - Whether the button should show a loading spinner when clicked.
   * This can be used for buttons that redirect the user to a different page.
   */
  isLoadingOnClick?: boolean;
};

/**
 * @component
 */
export const Button = ({
  children,
  className = "",
  full,
  href,
  minWidth,
  isLoading,
  size = "medium",
  dataCy,
  as = "button",
  type,
  variant,
  isLoadingOnClick,
  ...props
}: Omit<ButtonProps, "css">) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const buttonClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (props.onClick) {
      props.onClick(event);
    }

    if (isLoadingOnClick) {
      setHasBeenClicked(true);
    }
  };

  useEffect(() => {
    let clickTimeout: NodeJS.Timeout;
    if (hasBeenClicked) {
      /**
       * Reset the button 5s after the click
       */
      clickTimeout = setTimeout(() => {
        setHasBeenClicked(false);
      }, 5000);
    }
    return () => {
      clearTimeout(clickTimeout);
    };
  }, [hasBeenClicked]);

  const showLoading = isLoading || (isLoadingOnClick && hasBeenClicked);

  const ButtonImpl = () => (
    <StyledButton
      data-cy={dataCy}
      className={`${full ? "full" : ""} ${
        minWidth ? css({ minWidth }) : ""
      } ${className}`}
      type={type || "button"}
      variant={variant}
      size={size}
      {...props}
      onClick={buttonClicked}
    >
      {showLoading ? (
        <Spinner
          color={
            !variant || variant === "primary" || variant === "primary-green"
              ? "white"
              : "black"
          }
          size={size === "medium" ? 16 : size === "large" ? 24 : 12}
          margin={0}
        />
      ) : (
        children
      )}
    </StyledButton>
  );
  if (as === "a" && href) {
    return (
      <Link
        className={full ? "" : "w-fit"}
        href={href}
        target={props.target || "_self"}
      >
        <ButtonImpl />
      </Link>
    );
  } else {
    return <ButtonImpl />;
  }
};

export type ButtonVariant = ComponentProps<typeof Button>["variant"];

export const ButtonLink = ({
  full,
  href,
  setLoadingOnClick = false,
  target,
  ...props
}: Omit<ButtonProps, "css"> & {
  href: string;
  setLoadingOnClick?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Link className={full ? "w-full" : ""} href={href} target={target}>
      <Button
        {...props}
        full={full}
        isLoading={isLoading}
        onClick={() => setIsLoading(setLoadingOnClick)}
      />
    </Link>
  );
};

export const ButtonRound = ({
  className,
  ...props
}: Omit<ButtonProps, "css">) => (
  <Button
    className={`${className ? className : ""}`}
    shape="round"
    {...props}
  />
);

const BUTTON_COLORS = {
  secondary200: "#D0E5EE",
  secondary100: "#F8F8F9", // was grey200
};

const StyledButton = styled("button", {
  base: {
    alignItems: "center",
    border: "solid 1px transparent",
    borderRadius: "round",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    height: "fit-content",
    justifyContent: "center",
    transition: "all 0.2s ease",
    width: "fit-content",
    "&.full": {
      width: "100%",
    },
    _disabled: {
      cursor: "not-allowed",
    },
  },
  variants: {
    shape: {
      round: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "round",
        minWidth: "unset",
      },
      default: {},
    },
    variant: {
      primary: {
        backgroundColor: "black",
        color: "white",
        "&:hover": {
          backgroundColor: "grey600",
        },
        "&:focus": {
          backgroundColor: "grey500",
        },
        "&:disabled": {
          backgroundColor: "grey200",
          "&:hover": {
            backgroundColor: "grey200",
          },
        },
      },
      "primary-green": {
        backgroundColor: "black",
        color: "white",
        "&:hover": {
          backgroundColor: "success",
          opacity: "0.8",
        },
        "&:focus": {
          backgroundColor: "success",
        },
        "&:disabled": {
          backgroundColor: "grey200",
        },
      },
      "primary-over-dark": {
        backgroundColor: "grey700",
        color: "white",
        "&:hover": {
          backgroundColor: "grey600",
        },
        "&:focus": {
          backgroundColor: "grey500",
        },
        "&:disabled": {
          backgroundColor: "grey200",
        },
      },

      secondary: {
        backgroundColor: BUTTON_COLORS.secondary100,
        border: `solid 1px {colors.grey100}`,
        color: "black",
        "&:hover": {
          backgroundColor: BUTTON_COLORS.secondary200,
        },
        "&:focus": {
          backgroundColor: "focusMid",
        },
        "&:disabled": {
          backgroundColor: "disabled",
          _hover: {
            backgroundColor: "disabled",
          },
          opacity: "0.7",
        },
        "&.no-focus-outline&:focus": {
          backgroundColor: BUTTON_COLORS.secondary100,
        },
      },
      ghost: {
        backgroundColor: "transparent",
        border: "solid 1px black",
        color: "black",
        "&:hover": {
          opacity: "0.5",
        },
        "&:focus": {
          backgroundColor: BUTTON_COLORS.secondary100,
          color: "black",
        },
        "&:disabled, &:disabled:hover": {
          color: "grey200",
          border: "solid 1px grey200",
          opacity: "1",
        },
      },
      danger: {
        color: "error",
        backgroundColor: "errorLight",
        borderColor: "error",
      },
      "danger-block": {
        color: "white",
        backgroundColor: "error",
        borderColor: "error",
      },
      ghostNegative: {
        backgroundColor: "transparent",
        border: "solid 1px white",
        color: "white",
        "&:hover": {
          opacity: "0.5",
        },
        "&:focus": {
          backgroundColor: BUTTON_COLORS.secondary100,
          color: "white",
        },
        "&:disabled": {
          backgroundColor: "disabled",
        },
      },
      success: {
        backgroundColor: "successLight",
        border: "solid 1px success",
        color: "success",
        "&:hover": {
          border: "solid 1px grey300",
          backgroundColor: BUTTON_COLORS.secondary200,
          color: "black",
        },
        "&:focus": {
          backgroundColor: "successLight",
        },
        "&:disabled": {
          cursor: "not-allowed",
          backgroundColor: "disabled",
          "&:hover": {
            backgroundColor: "disabled",
            color: "success",
          },
        },
      },
      gold: {
        color: "white",
        fontWeight: "bold",
        border: "solid 1px gold",
        background:
          "linear-gradient(0deg, #FFDB5E 0%, #FFDB5E 100%), linear-gradient(339deg, rgba(255, 255, 255, 0.00) 52.79%, #FFF 95.95%), linear-gradient(77deg, #576265 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #576265 55.72%, #576265 77.23%, #757A7B 85.34%, #576265 91.31%)",
        backgroundBlendMode: "color, overlay, normal",
        backgroundSize: "100% 100%",
        transition: "background-size 0.3s ease",

        "&:hover": {
          backgroundSize: "150% 100%",
        },
      },
      "success-inverted": {
        backgroundColor: "success",
        color: "white",
        "&:hover": {
          opacity: "0.7",
        },
        "&:focus": {
          backgroundColor: "successLight",
        },
        "&:disabled": {
          backgroundColor: "disabled",
        },
      },
      recharge: {
        backgroundColor: "rechargeDark",
        color: "white",
        "&:hover": {
          backgroundColor: "rechargeLight",
        },
        "&:focus": {
          backgroundColor: "rechargeLight",
        },
        "&:disabled": {
          opacity: "0.7",
        },
      },
      wrapped: {
        backgroundColor: "wrapped",
        color: "white",
        textShadow: "1px 1px 0 black",
        "&:hover": {
          backgroundColor: "wrapped",
          opacity: "0.8",
        },
        "&:focus": {
          backgroundColor: "wrapped",
          opacity: "0.8",
        },
        "&:disabled": {
          opacity: "0.7",
        },
      },
    },
    size: {
      mini: {
        padding: "{spacing.4} {spacing.8}",
        fontSize: "2xs",
        lineHeight: "{fontSizes.xs}",
        minWidth: "40px",
      },
      small: {
        padding: "{spacing.8} {spacing.12}",
        fontSize: "xs",
        lineHeight: "{fontSizes.sm}",
        minWidth: "60px",
      },
      medium: {
        padding: "{spacing.12} {spacing.16}",
        fontSize: "sm",
      },
      large: {
        padding: "{spacing.16} {spacing.20}",
        fontSize: "md",
      },
    },
  },
  defaultVariants: {
    shape: "default",
    size: "medium",
    variant: "primary",
  },
  compoundVariants: [
    {
      shape: "round",
      size: "small",
      css: {
        padding: "0px",
        height: "24px",
        minWidth: "unset",
        width: "24px",
      },
    },
    {
      shape: "round",
      size: "medium",
      css: {
        padding: "0px",
        height: "32px",
        width: "32px",
      },
    },
    {
      shape: "round",
      size: "large",
      css: {
        padding: "0px",
        height: "48px",
        width: "48px",
      },
    },
  ],
});
