import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { ContentContainer } from "legacy-design-system";
import { TextBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";
import { FindAndReplaceList, ModularContent } from "./modular-content";
import { useUserContext } from "context/user-context";
import { useIsDesktop } from "utils/component-utils";
import { useReferredBy } from "design-system/hooks/use-referred-by";
import { THEME_BREAKPOINTS } from "design-system/consts.theme";

const FIND_AND_REPLACE_VARS: Record<string, string> = {
  EMAIL: "$email",
  FIRST_NAME: "$firstName",
  LAST_NAME: "$lastName",
  REFERRED_BY: "$referredBy",
};

const TextView = (props: TextBlockFragment & { className: string }) => {
  const {
    isQuarterlyRole,
    isYearlyRole,
    isFreeTrialRole,
    isFreeRole,
    isMonthlyRole,
    email,
    firstName,
    lastName,
    loggedIn,
  } = useUserContext();
  const isDesktop = useIsDesktop(THEME_BREAKPOINTS.tablet, true);

  const [findAndReplace, setFindAndReplace] = useState<FindAndReplaceList>([]);

  const referredBy = useReferredBy();

  useEffect(() => {
    const findAndReplaceConfig: FindAndReplaceList = [];
    if (email)
      findAndReplaceConfig.push({
        field: FIND_AND_REPLACE_VARS.EMAIL,
        value: email || "",
      });

    if (firstName)
      findAndReplaceConfig.push({
        field: FIND_AND_REPLACE_VARS.FIRST_NAME,
        value: firstName || "",
      });

    if (lastName)
      findAndReplaceConfig.push({
        field: FIND_AND_REPLACE_VARS.LAST_NAME,
        value: lastName || "",
      });

    if (referredBy) {
      findAndReplaceConfig.push({
        field: FIND_AND_REPLACE_VARS.REFERRED_BY,
        value: referredBy || "",
      });
    }

    if (findAndReplaceConfig.length > 0) {
      setFindAndReplace(findAndReplaceConfig);
    }
  }, [email, firstName, lastName, referredBy]);

  const alignmentOfText =
    props.alignmentOfTextMobile && !isDesktop
      ? props.alignmentOfTextMobile
      : props.alignmentOfText;

  const paragraphSize =
    props.paragraphSizeMobile && !isDesktop
      ? props.paragraphSizeMobile
      : props.paragraphSize;

  const content = useMemo(
    () => (
      <ContentContainer
        className={props.className}
        style={props.color?.hex ? { color: props.color.hex } : undefined}
      >
        {
          <AlignedContainer
            className={[
              props.alignment || "left",
              props.width || "full",
              props.bulletListStyle || "",
            ].join(" ")}
          >
            <ModularContent
              data={props.content}
              paragraphSize={paragraphSize}
              alignmentOfText={alignmentOfText}
              findAndReplace={findAndReplace}
            />
          </AlignedContainer>
        }
      </ContentContainer>
    ),
    [props, findAndReplace, alignmentOfText, paragraphSize]
  );

  // We show this block if either all is selected or if the
  // user role matches the role of the block
  if (
    props.roleToViewBlock === "all" ||
    (props.roleToViewBlock === "yearly" && isYearlyRole) ||
    (props.roleToViewBlock === "freetrial" && isFreeTrialRole) ||
    (props.roleToViewBlock === "quarterly" && isQuarterlyRole) ||
    (props.roleToViewBlock === "monthly" && isMonthlyRole) ||
    (props.roleToViewBlock === "free" && isFreeRole) ||
    (props.roleToViewBlock === "logged in" && loggedIn) ||
    (props.roleToViewBlock === "desktop" && isDesktop) ||
    (props.roleToViewBlock === "mobile" && !isDesktop)
  ) {
    return content;
  }

  return null;
};

export const TextRenderer = renderer({
  typeName: "TextRecord",
  view: TextView,
});

export const textMediumMaxWidth = 800;

const AlignedContainer = styled.div`
  max-width: 684px;
  p {
    margin-top: 10px;
  }

  ul,
  ol {
    text-align: left;
  }
  li {
    margin-bottom: 18px;
    padding-left: 18px;
  }
  &.full {
    max-width: 100%;
  }
  &.medium {
    max-width: ${textMediumMaxWidth}px;
  }
  &.small {
    max-width: 400px;
  }
  &.left {
    margin-right: auto;
    text-align: left;
  }
  &.right {
    margin-left: auto;
    text-align: right;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  ul {
    list-style: disc;
  }

  &.check ul {
    list-style-image: url("/assets/shared/check.svg");
  }

  &.spark ul {
    list-style-image: url("/assets/shared/spark.svg");
  }
`;
