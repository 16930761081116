import { Agenda } from "features/sessions/shared";

export const getCurrentActivityIndex = (
  agenda: Agenda,
  elapsedMinutes: number
): number | null => {
  let currentActivityDuration = 0;

  for (let i = 0; i < agenda.length; i++) {
    const activity = agenda[i];
    if (currentActivityDuration + activity.duration > elapsedMinutes) {
      return i;
    }
    currentActivityDuration += activity.duration;
  }
  // If we've reached this point, it means we've passed the last activity
  // And no activity is currently active (e.g. start or end of session)
  return null;
};
