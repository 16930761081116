import styled from "@emotion/styled";

export const SessionCardCTA = styled.div(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.grey100,
  borderRadius: theme.radii.round,
  display: "flex",
  height: "32px",
  justifyContent: "center",
  transition: "all 0.2s ease",
  width: "32px",

  [theme.media["bp-desktop-xs"]]: {
    height: "26px",
    width: "26px",
  },

  "&:hover": {
    backgroundColor: theme.colors.grey50,
    cursor: "pointer",
    svg: {
      color: theme.colors.focusDark,
    },
  },
}));
