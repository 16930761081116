import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { BreadcrumbProps, Breadcrumbs } from "./breadcrumbs";

/**
 * This component automatically generates breadcrumbs from the current route.
 */
export const BreadcrumbsFromRoute = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([]);
  const router = useRouter();
  useEffect(() => {
    const cleanPath = window.location.pathname;
    const pathArray = cleanPath.split("/").filter((path) => path !== "");

    const breadcrumbs = pathArray.map((path, index) => {
      const href = "/" + pathArray.slice(0, index + 1).join("/");
      return {
        href,
        name: `${path.toUpperCase()}`.replace(/-/g, " "),
      };
    });

    // If there is just one item, we do not return breadcrumbs
    if (breadcrumbs.length <= 1) {
      setBreadcrumbs([]);
      return;
    }

    setBreadcrumbs(breadcrumbs);
  }, [router.asPath]);
  return <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>;
};
