import styled from "@emotion/styled";
import Image from "next/image";
import { MilestoneBadge } from "../../shared/badge-types";
import { Box, Tooltip } from "design-system/components";
import { Icon } from "design-system/components";
import { useMemo } from "react";

type BadgeProps = {
  dimensions?: number;
  badge: MilestoneBadge;
  showMetadata?: boolean;
};

export const Badge: React.FC<BadgeProps> = ({
  badge,
  dimensions = 50,
  showMetadata = false,
}) => {
  const BadgeImage = useMemo(
    () => (
      <Box
        style={{
          width: dimensions,
          height: dimensions,
        }}
        className="flex align-center justify-center pos-relative b-radius-round"
      >
        {!badge.completed ? (
          <Icon size={24} icon="badge-padlock" />
        ) : (
          <Image
            className="pos-absolute"
            src={badge.illustrationUrl}
            alt={badge.illustrationAlt}
            fill
          />
        )}
      </Box>
    ),
    [badge.illustrationUrl, badge.illustrationAlt, dimensions, badge.completed]
  );

  return showMetadata ? (
    <Tooltip
      triggerElement={
        <BadgeContainer className={!badge.completed ? "locked" : ""}>
          {BadgeImage}
        </BadgeContainer>
      }
    >
      {badge.description}
    </Tooltip>
  ) : (
    <BadgeContainer className={!badge.completed ? "locked" : ""}>
      {BadgeImage}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.round};
  gap: ${({ theme }) => theme.spacing[16]};
  outline: none;
  pointer-events: none;
  z-index: 10;
  &.locked {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;
