import { styled } from "styled-system/jsx";
import { useUserContext } from "context/user-context";
import {
  Box,
  Button,
  ButtonLink,
  Icon,
  Row,
  Text,
} from "design-system/components";
import { EventSummary } from "design-system/molecules/event-summary";
import Link from "next/link";
import { useMemo, useState } from "react";
import { FlownEvent } from "shared/event.types";
import {
  getEventHasStarted,
  getHasHostPrivileges,
  getIsDropInEvent,
  getIsEventFullyBooked,
  getIsEventJoinable,
  getIsPublicFixedFormatSession,
} from "utils/event-utils";
import {
  CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL,
  CREATE_COMMUNITY_ROOM_URL,
  EAGLE_SESSIONS_URL,
  getOrganisationEditSessionUrl,
  getURLWithReferralCode,
  ROOMS_URL,
} from "utils/urls";
import { useGetRegisteredUsersForSession } from "../hooks/use-get-users-registered-for-session";
import { SessionRecord } from "shared/session.types";
import { useSidePanel } from "context/side-panel-context";
import { SocialMediaShareButtons } from "design-system/molecules/social-media/social-media-share-buttons";
import { QUERY_PARAMS } from "utils/url-query-utils";
import { CancelSessionModal } from "features/sessions/client";
import { useRouter } from "next/router";
import { FullSessionCloneTooltip } from "./ctas/full-session-clone-tooltip";
import { useGetUsersInSession } from "features/springboard";
import { getSubscribePageLinkWithSubscribeSource } from "features/subscription/shared";

type EventSummaryWrapperProps = {
  selectedEvent: FlownEvent;
  isSelectedEventBooked: boolean;
  showPreviewOnlySidePanel: boolean;
  openHowItWorks: boolean;
  sessionsAttended?: SessionRecord[];
  onCancelBooking: () => Promise<void>;
  onEventJoined: (event: FlownEvent) => void;
  onSendEmailInvite: () => Promise<void>;
  onEventCancelled?: (event: FlownEvent) => void;
};

export const EventSummaryWrapper: React.FC<EventSummaryWrapperProps> = ({
  selectedEvent,
  isSelectedEventBooked,
  showPreviewOnlySidePanel,
  sessionsAttended,
  openHowItWorks,
  onCancelBooking,
  onSendEmailInvite,
  onEventJoined,
  onEventCancelled,
}) => {
  const router = useRouter();
  const {
    canHostSessions,
    isFlownRole,
    isFacilitatorRole,
    isFreeRole,
    isFreeCreditsRole,
    externalId,
    referralCode,
    loggedIn,
  } = useUserContext();
  const {
    usersRegisteredForSession,
    connectionsRegisteredForSession,
    commonTags,
  } = useGetRegisteredUsersForSession({
    selectedEvent,
  });

  const { usersInSession } = useGetUsersInSession({
    loggedIn: !!loggedIn,
    event: selectedEvent,
    heartbeatInterval: 60000,
  });
  const { closeSidePanel, showSendInvites } = useSidePanel();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const EDIT_SESSION_URL = selectedEvent.organisation
    ? getOrganisationEditSessionUrl(
        selectedEvent.organisation.slug,
        selectedEvent.id
      )
    : `${ROOMS_URL}/${selectedEvent.id}/edit`;

  const toggleBooking = async () => {
    setIsLoading(true);
    if (
      isSelectedEventBooked &&
      selectedEvent &&
      !getIsDropInEvent(selectedEvent)
    ) {
      await onCancelBooking();
    } else {
      await onSendEmailInvite();
    }
    setIsLoading(false);
  };
  const hasHostPrivileges =
    !!selectedEvent.host &&
    !!externalId &&
    getHasHostPrivileges(
      {
        host: selectedEvent.host,
        // The two properties below are only used for P2P events
        // hence the check
        claimedHost: selectedEvent.isP2P ? selectedEvent.claimedHost : "",
        cohosts: selectedEvent.isP2P ? selectedEvent.cohosts : [],
      },
      externalId
    );

  const canJoinSelectedEvent = useMemo(
    () =>
      selectedEvent
        ? getIsEventJoinable(selectedEvent, false, hasHostPrivileges)
        : false,
    [selectedEvent, hasHostPrivileges]
  );

  const [showShareButtons, setShowShareButtons] = useState(false);

  const isFixedFormat = getIsPublicFixedFormatSession(selectedEvent);
  const isPastEvent =
    getEventHasStarted(selectedEvent.startTime) &&
    !getIsEventJoinable(selectedEvent);

  return (
    <EventSummaryBox data-cy="events-summary-box">
      <EventSummary
        event={selectedEvent}
        openHowItWorks={openHowItWorks}
        showCategoryPillAndIcon={showPreviewOnlySidePanel}
        usersRegistered={usersRegisteredForSession}
        usersInSession={usersInSession}
        connections={connectionsRegisteredForSession}
        commonTags={commonTags}
        sessionsAttended={sessionsAttended}
        showHowItWorks={true}
        showStartTime
      />

      <Box className="flex-col gap-16">
        {isFreeRole && !selectedEvent.isFreeSession ? (
          <Button
            as="a"
            full
            href={getSubscribePageLinkWithSubscribeSource("event-summary")}
          >
            Upgrade for unlimited access
          </Button>
        ) : canJoinSelectedEvent ? (
          <Button
            dataCy="event-summary-session-join"
            full
            isLoadingOnClick
            onClick={() => {
              onEventJoined(selectedEvent);
              /**
               * For free-credits users we cannot close the side panel
               * or it will prevent the credit check modal from showing
               */
              if (!isFreeCreditsRole) {
                closeSidePanel();
              }
            }}
          >
            {hasHostPrivileges ? "Launch" : "Join now"}
          </Button>
        ) : (
          hasHostPrivileges &&
          !isPastEvent && (
            <Button
              className="flex align-center gap-4"
              variant="primary"
              full
              onClick={() => {
                showSendInvites(selectedEvent);
              }}
            >
              Invite
            </Button>
          )
        )}
        {!selectedEvent.isCancelled && (
          <Box className="flex gap-16">
            {!isSelectedEventBooked && getIsEventFullyBooked(selectedEvent) ? (
              <Button className="gap-4" variant={"ghost"} full disabled>
                Fully booked{" "}
                {isFixedFormat && canHostSessions && (
                  <FullSessionCloneTooltip event={selectedEvent} />
                )}
              </Button>
            ) : (
              <>
                {isFreeRole && !selectedEvent.isFreeSession ? (
                  <></>
                ) : hasHostPrivileges ? (
                  <>
                    {!isPastEvent && (
                      <>
                        {" "}
                        <Button
                          full
                          className="flex align-center gap-4"
                          variant="danger"
                          dataCy="edit-room-btn"
                          onClick={() => setShowCancelModal(true)}
                        >
                          Cancel
                        </Button>
                        <CancelSessionModal
                          event={selectedEvent}
                          open={showCancelModal}
                          onClose={() => setShowCancelModal(false)}
                          onDeleteConfirmed={() => {
                            // Only enters here on success
                            setShowCancelModal(false);
                            onEventCancelled?.(selectedEvent);
                          }}
                        />
                      </>
                    )}
                    {!isFixedFormat && !isPastEvent && (
                      <ButtonLink
                        full
                        className="flex align-center gap-4"
                        variant="ghost"
                        dataCy="edit-room-btn"
                        href={`${EDIT_SESSION_URL}${
                          selectedEvent.privacy === "private" ? "/private" : ""
                        }`}
                      >
                        Edit
                      </ButtonLink>
                    )}

                    {canJoinSelectedEvent && !isPastEvent && (
                      <Button
                        className="flex align-center gap-4"
                        variant="ghost"
                        full
                        onClick={() => {
                          showSendInvites(selectedEvent);
                        }}
                      >
                        Invite
                      </Button>
                    )}
                    <Button
                      full
                      variant={isPastEvent ? "primary" : "ghost"}
                      onClick={() => {
                        if (isFixedFormat) {
                          const urlParams = new URLSearchParams();
                          urlParams.append(
                            QUERY_PARAMS.FIXED_FORMAT_RECUR_ID,
                            selectedEvent.id
                          );
                          urlParams.append(
                            QUERY_PARAMS.SESSION_PRIVACY,
                            `${selectedEvent.privacy === "private"}`
                          );

                          void router.push(
                            `${CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL}?${urlParams.toString()}`
                          );
                        } else {
                          void router.push(
                            `${CREATE_COMMUNITY_ROOM_URL}?${QUERY_PARAMS.CLONE}=${selectedEvent.id}`
                          );
                        }
                      }}
                    >
                      Recreate
                    </Button>
                  </>
                ) : (
                  !hasHostPrivileges &&
                  !isPastEvent && (
                    <Button
                      className="flex align-center gap-4"
                      variant={
                        isSelectedEventBooked
                          ? !getIsDropInEvent(selectedEvent)
                            ? "danger"
                            : "primary-green"
                          : canJoinSelectedEvent
                          ? "ghost"
                          : "primary"
                      }
                      full
                      dataCy="event-summary-email-invite"
                      onClick={toggleBooking}
                      minWidth={170}
                      isLoading={loading}
                    >
                      {isSelectedEventBooked ? (
                        !getIsDropInEvent(selectedEvent) ? (
                          <>
                            <Icon color="inherit" icon="close" size={12} />{" "}
                            Cancel booking
                          </>
                        ) : (
                          <>
                            <Icon
                              icon="calendar"
                              style={{
                                marginBottom: "3px",
                              }}
                              size={16}
                            />{" "}
                            Book another slot
                          </>
                        )
                      ) : (
                        <>
                          <Icon
                            icon="calendar"
                            style={{
                              marginBottom: "3px",
                            }}
                            size={16}
                          />{" "}
                          Book this session
                        </>
                      )}
                    </Button>
                  )
                )}
              </>
            )}
            {!showPreviewOnlySidePanel && !hasHostPrivileges && (
              <div className="flex align-center gap-4 w-full">
                {!showShareButtons ? (
                  <Button
                    className="flex align-center gap-4"
                    as="a"
                    variant="ghost"
                    full
                    onClick={() => {
                      setShowShareButtons(true);
                    }}
                  >
                    <Icon icon="share" size={14} />
                    {"Share"}
                  </Button>
                ) : (
                  <SocialMediaShareButtons
                    shareUrl={getURLWithReferralCode(
                      selectedEvent.springBoardFullUrl,
                      referralCode
                    )}
                  />
                )}
              </div>
            )}
          </Box>
        )}
        <Row className="justify-between">
          {hasHostPrivileges && (
            <Text fontSize="sm" color="grey500" dataCy="session-format">
              {isFixedFormat ? "FLOWN format session" : "Custom format session"}
            </Text>
          )}
          {(isFlownRole || isFacilitatorRole) && (
            <Link
              target="_blank"
              rel="noreferrer"
              href={`${EAGLE_SESSIONS_URL}/${
                selectedEvent.isP2P
                  ? selectedEvent.detailedName
                  : selectedEvent.id
              }`}
              className="decor-underline"
              title="Opens stats in new tab"
            >
              Internal stats dashboard
            </Link>
          )}
        </Row>
      </Box>
    </EventSummaryBox>
  );
};

const EventSummaryBox = styled("section", {
  base: {
    display: "flex",
    flexDirection: "column",
    gap: "{spacing.32}",
    height: "fit-content",
  },
});
