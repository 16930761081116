import { getCachedUserCreatedSessions } from "features/sessions/client";
import { useUserContext } from "context/user-context";
import {
  CreatedSessionCard,
  CreatedSessionCardSkeleton,
  CancelledSessionsToggle,
} from "features/sessions/client";
import { Box, EmptyState, Row } from "design-system/components";
import { TabSwitcher } from "design-system/molecules/tab-switcher";
import { cn } from "design-system/styles/utility-classes";
import { useEffect, useState } from "react";
import { P2PEvent } from "shared/rooms.types";
import { slugify } from "utils/string-utils";
import dayjs from "dayjs";
import { sortByMostRecent } from "utils/event-utils";

type UserCreatedSessionsSidePanelProps = {
  roomsFromServer?: P2PEvent[];
};

export const UserCreatedSessionsSidePanel = ({
  roomsFromServer,
}: UserCreatedSessionsSidePanelProps) => {
  const { externalId } = useUserContext();
  const [isLoading, setIsLoading] = useState(!roomsFromServer);
  const [rooms, setRooms] = useState<P2PEvent[]>([]);
  const [showCancelledSessions, setShowCancelledSessions] = useState(false);

  useEffect(() => {
    if (roomsFromServer) {
      setRooms(roomsFromServer);
      setIsLoading(false);
      return;
    }

    const fetchRooms = async () => {
      if (!externalId) {
        setRooms([]);
        setIsLoading(false);
        return;
      }
      const rooms = await getCachedUserCreatedSessions(externalId);
      setRooms(rooms);
      setIsLoading(false);
    };

    void fetchRooms();
  }, [externalId, roomsFromServer]);

  const upcomingSessions = rooms
    .filter((room) => {
      if (room.startTime > dayjs().toISOString()) {
        return showCancelledSessions ? true : !room.deleted;
      }
      return false;
    })
    .sort(sortByMostRecent);

  // Past sessions will also include sessions that have started but not ended yet and that
  // Have been cancelled
  const pastSessions = rooms.filter((room) => {
    const isSessionInProgress =
      room.startTime > dayjs().toISOString() &&
      room.endTime < dayjs().toISOString();

    if (room.startTime < dayjs().toISOString()) {
      return showCancelledSessions ? true : !room.deleted;
    } else if (isSessionInProgress) {
      return showCancelledSessions ? true : !room.deleted;
    }

    return false;
  });

  return isLoading ? (
    <Box className="flex-col gap-16">
      <Row>
        <div
          className={cn(".skeleton")}
          style={{ height: "12px", width: "120px" }}
        />
        <div
          className={cn(".skeleton")}
          style={{ height: "12px", width: "120px" }}
        />
      </Row>
      <CreatedSessionCardSkeleton />
      <CreatedSessionCardSkeleton />
    </Box>
  ) : (
    <Box className="flex justify-between">
      <TabSwitcher
        style={{ width: "100%" }}
        initialActiveIndex={0}
        tabListLabel="Past and future session history"
        tabs={[
          {
            value: "upcoming",
            label: `Upcoming sessions`,
            content: (
              <Box className="flex-col gap-16">
                <CancelledSessionsToggle
                  id="cancelled-sessions-toggle-all-sessions-side-panel"
                  showCancelledSessions={showCancelledSessions}
                  onToggleCancelledSessions={(checked) =>
                    setShowCancelledSessions(checked)
                  }
                />
                {upcomingSessions.length > 0 ? (
                  upcomingSessions.map((room, index) => (
                    <CreatedSessionCard
                      key={`${room.id}-${index}`}
                      room={room}
                      dataCy={`created-session-card-${slugify(
                        room.displayName
                      )}-${index}`}
                    />
                  ))
                ) : (
                  <EmptyState text="No upcoming sessions" />
                )}
              </Box>
            ),
          },
          {
            value: "past-and-cancelled",
            label: `Past sessions`,
            content: (
              <Box className="flex-col gap-16">
                <CancelledSessionsToggle
                  id="cancelled-sessions-toggle-all-sessions-side-panel"
                  showCancelledSessions={showCancelledSessions}
                  onToggleCancelledSessions={(checked) =>
                    setShowCancelledSessions(checked)
                  }
                />
                {pastSessions.length > 0 ? (
                  pastSessions.map((room, index) => (
                    <CreatedSessionCard
                      key={`${room.id}-${index}`}
                      dataCy={`created-session-card-${slugify(
                        room.displayName
                      )}-${index}`}
                      room={room}
                    />
                  ))
                ) : (
                  <EmptyState text="No past sessions" />
                )}
              </Box>
            ),
          },
        ]}
      />
    </Box>
  );
};
