import { ThemeColor } from "design-system/emotion.theme";
import {
  ACADEMY_URL,
  CONTACT_URL,
  GREEN_SCENE_URL_PREFIX,
  LAUNCHPAD_URL,
  CALENDAR_PREFIX,
  MANAGE_MY_WEEK_URL,
  PORTHOLES_URL,
  RECHARGE_PREVIEW_URL_PREFIX,
  RECHARGE_BROWSE_URL,
  REFERRAL_URL,
  ACCOUNT_SETTINGS_URL_PREFIX,
  REGISTER_URL,
  EAGLE_URL,
  MANAGE_SUBSCRIPTION_URL,
  PROFILE_URL,
  USER_MANAGEMENT_URL,
  SPRINGBOARD_BY_ID_URL_PREFIX,
  SPRINGBOARD_BY_SLUG_URL_PREFIX,
  SESSIONS_LIVE_URL,
  ANYTIME_URL_PREFIX,
  LOGIN_URL,
  SUBSCRIBE_URL,
  SUBSCRIPTION_UPGRADE_URL,
  ROOMS_URL,
  CREATE_COMMUNITY_ROOM_URL,
  COMMUNITY_SPRINGBOARD_SHORT_URL,
  DATO_URL_PATHNAME,
  FLEETS_URL,
  COPILOT_URL,
  FLEETS_BOOKING_URL,
  COPILOT_PRODUCTIVITY_URL,
  COPILOT_ADHD_URL,
  COPILOT_MORE_URL,
  ORGANISATION_URL_PREFIX,
  WRAPPED_URL_PREFIX,
  FLOWN_PLUS_SESSIONS_URL,
  WAITING_ROOM_SUFFIX,
  ROBOT_ROOM_URL,
  COMMUNITY_URL,
  CALENDAR_FEATURED_URL,
  CALENDAR_COMMUNITY_URL,
  DELETE_USER_URL,
  FOCUS_ROOM_URL_PREFIX,
  CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL,
} from "utils/urls";

const mobileNoNavigationTemplate = `
  grid-template-areas:
      "page"
      "page"
      "page";
`;

const mobileFullNavigationTemplate = `
  grid-template-areas:
      "banner"
      "page"
      "primary";
`;

const mobilePrimaryOnlyNavigationTemplate = `
  grid-template-areas:
      "page"
      "primary";
`;

const desktopFullNavigationTemplate = `
grid-template-areas:
"primary banner"
"primary page";
`;

const desktopPrimaryOnlyNavigationTemplate = `
grid-template-areas:
"primary page"
"primary page";
`;

const desktopNoNavigationTemplate = `
  grid-template-areas:
  "page"
  "page";
`;
// TODO probably possible to clean this even more, there's `no-nav`, `empty-nav` and `register-empty-nav`
type NavigationOption =
  | "full-nav"
  | "primary-nav-only"
  | "no-nav"
  | "marketing-nav"
  | "empty-nav"
  | "register-empty-nav"
  | "login-nav";

export type NavigationConfigOptions = {
  logoColor: "white" | "black";
  logoAlignment: "left" | "center";
  isLogoClickable: boolean;
  backgroundColor?: ThemeColor;
};

type NavigationTemplate = {
  web: string;
  mobile: string;
  wrapperClassName: NavigationOption;
  configOptions?: NavigationConfigOptions;
};

const FULL_NAVIGATION_TEMPLATE: NavigationTemplate = {
  mobile: mobileFullNavigationTemplate,
  web: desktopFullNavigationTemplate,
  wrapperClassName: "full-nav",
};

const PRIMARY_ONLY_NAVIGATION_TEMPLATE: NavigationTemplate = {
  mobile: mobilePrimaryOnlyNavigationTemplate,
  web: desktopPrimaryOnlyNavigationTemplate,
  wrapperClassName: "primary-nav-only",
};

const NO_NAVIGATION_TEMPLATE: NavigationTemplate = {
  mobile: mobileNoNavigationTemplate,
  web: desktopNoNavigationTemplate,
  wrapperClassName: "no-nav",
};

const MARKETING_SITE_NAVIGATION_TEMPLATE: NavigationTemplate = {
  mobile: mobileNoNavigationTemplate,
  web: desktopNoNavigationTemplate,
  wrapperClassName: "marketing-nav",
};

const REGISTERING_NAVIGATION_TEMPLATE: NavigationTemplate = {
  mobile: mobileNoNavigationTemplate,
  web: desktopNoNavigationTemplate,
  wrapperClassName: "register-empty-nav",
};

const LOGIN_NAVIGATION_TEMPLATE: NavigationTemplate = {
  mobile: mobileNoNavigationTemplate,
  web: desktopNoNavigationTemplate,
  wrapperClassName: "login-nav",
};

/**
 * These will be matched using includes in the order in which they are declared.
 * See designs here: https://www.figma.com/file/2OYRj68bHZYq4wDioaQtm0/FLOWN---Designs?node-id=0%3A1
 */
export const MEMBER_PAGE_LAYOUTS = {
  [`${LAUNCHPAD_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [CALENDAR_PREFIX]: PRIMARY_ONLY_NAVIGATION_TEMPLATE,
  [CALENDAR_FEATURED_URL]: FULL_NAVIGATION_TEMPLATE,
  [CALENDAR_COMMUNITY_URL]: FULL_NAVIGATION_TEMPLATE,
  [`${MANAGE_MY_WEEK_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${GREEN_SCENE_URL_PREFIX}/[greenSceneSlug]`]: NO_NAVIGATION_TEMPLATE,
  [`${ACCOUNT_SETTINGS_URL_PREFIX}`]: FULL_NAVIGATION_TEMPLATE,
  [`${DELETE_USER_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${MANAGE_SUBSCRIPTION_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${PROFILE_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${USER_MANAGEMENT_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${CONTACT_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${REFERRAL_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${ANYTIME_URL_PREFIX}`]: FULL_NAVIGATION_TEMPLATE,
  [`${RECHARGE_PREVIEW_URL_PREFIX}/[slug]`]: FULL_NAVIGATION_TEMPLATE,
  [`${RECHARGE_BROWSE_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${PORTHOLES_URL}/[portholeSlug]`]: NO_NAVIGATION_TEMPLATE,
  [`${PORTHOLES_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${ACADEMY_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${ACADEMY_URL}/[courseSlug]/[lessonSlug]`]: FULL_NAVIGATION_TEMPLATE,
  [`${REGISTER_URL}`]: REGISTERING_NAVIGATION_TEMPLATE,
  [`${LOGIN_URL}`]: LOGIN_NAVIGATION_TEMPLATE,
  [`${SPRINGBOARD_BY_ID_URL_PREFIX}/[eventId]`]: FULL_NAVIGATION_TEMPLATE,
  [`${SPRINGBOARD_BY_SLUG_URL_PREFIX}/[slug]`]: FULL_NAVIGATION_TEMPLATE,
  [`${SUBSCRIBE_URL}`]: NO_NAVIGATION_TEMPLATE,
  [`${SUBSCRIPTION_UPGRADE_URL}`]: NO_NAVIGATION_TEMPLATE,
  [`${SESSIONS_LIVE_URL}/[sessionId]`]: NO_NAVIGATION_TEMPLATE,
  [`${COMMUNITY_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${FOCUS_ROOM_URL_PREFIX}/[userRoomName]`]: NO_NAVIGATION_TEMPLATE,
  [`${ROOMS_URL}/[roomId]`]: NO_NAVIGATION_TEMPLATE,
  [`${ROOMS_URL}/[roomId]${WAITING_ROOM_SUFFIX}`]: NO_NAVIGATION_TEMPLATE,
  [`${ROOMS_URL}/[roomId]/edit`]: FULL_NAVIGATION_TEMPLATE,
  [`${ROOMS_URL}/[roomId]/edit/private`]: FULL_NAVIGATION_TEMPLATE,
  [`${ROOMS_URL}/[roomId]/host-panel`]: NO_NAVIGATION_TEMPLATE,
  [`${ROBOT_ROOM_URL}/[roomName]`]: NO_NAVIGATION_TEMPLATE,
  [`${CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${CREATE_COMMUNITY_ROOM_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${CREATE_COMMUNITY_ROOM_URL}/private`]: FULL_NAVIGATION_TEMPLATE,
  [`${COMMUNITY_SPRINGBOARD_SHORT_URL}/[dailyRoomName]`]:
    FULL_NAVIGATION_TEMPLATE,
  [`${FLOWN_PLUS_SESSIONS_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${FLOWN_PLUS_SESSIONS_URL}/[categorySlug]`]: FULL_NAVIGATION_TEMPLATE,
  [`${FLOWN_PLUS_SESSIONS_URL}/[categorySlug]/[...pathParts]`]:
    FULL_NAVIGATION_TEMPLATE,
  [`${ORGANISATION_URL_PREFIX}/[orgSlug]`]: FULL_NAVIGATION_TEMPLATE,
  [`${ORGANISATION_URL_PREFIX}/[orgSlug]/create-session`]:
    FULL_NAVIGATION_TEMPLATE,
  [`${ORGANISATION_URL_PREFIX}/[orgSlug]/edit-session`]:
    FULL_NAVIGATION_TEMPLATE,
  [`${ORGANISATION_URL_PREFIX}/[orgSlug]/edit-session/[roomId]`]:
    FULL_NAVIGATION_TEMPLATE,
  [`${ORGANISATION_URL_PREFIX}/[orgSlug]/springboard/[eventId]`]:
    FULL_NAVIGATION_TEMPLATE,
  [`${FLEETS_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${FLEETS_BOOKING_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${COPILOT_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${COPILOT_PRODUCTIVITY_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${COPILOT_ADHD_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${COPILOT_MORE_URL}`]: FULL_NAVIGATION_TEMPLATE,
  [`${EAGLE_URL}`]: NO_NAVIGATION_TEMPLATE,
  [`${EAGLE_URL}/organisations`]: NO_NAVIGATION_TEMPLATE,
  [`${EAGLE_URL}/organisations/new`]: NO_NAVIGATION_TEMPLATE,
  [`${EAGLE_URL}/organisations/[slug]`]: NO_NAVIGATION_TEMPLATE,
  [`${EAGLE_URL}/organisations/[slug]/users`]: NO_NAVIGATION_TEMPLATE,
  [`${WRAPPED_URL_PREFIX}`]: NO_NAVIGATION_TEMPLATE,
  [`${WRAPPED_URL_PREFIX}/[year]`]: NO_NAVIGATION_TEMPLATE,
  [`${WRAPPED_URL_PREFIX}/[year]/[vanityUrl]`]: NO_NAVIGATION_TEMPLATE,
};

type GetPageLayoutProps = {
  pathname: string;
  asPath: string;
};

export const getPageLayout = ({ pathname, asPath }: GetPageLayoutProps) => {
  /**
   * We check pathname first, because it does not have query params on it. However, for
   * Dato driven pages, it comes through as [...pathParts] so we cannot tell which page it is.
   * In that case we also check the asPath value
   */
  const routes = Object.keys(MEMBER_PAGE_LAYOUTS);
  const matchingKey = routes.find((route) =>
    pathname === DATO_URL_PATHNAME ? asPath === route : pathname === route
  );

  return matchingKey
    ? MEMBER_PAGE_LAYOUTS[matchingKey]
    : MARKETING_SITE_NAVIGATION_TEMPLATE;
};
