import Link from "next/link";
import { useUserContext } from "context/user-context";
import {
  BoxCol,
  CommunityHostPill,
  Icon,
  Pill,
} from "design-system/components";
import { MilestoneBadgesContainer } from "features/badges/client";
import { getUserDisplayName } from "../../shared";
import { Box, Button, Row, Text } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { Avatar } from "design-system/molecules/avatar";
import { PROFILE_URL, USER_URL } from "utils/urls";
import { useFollowUnfollow } from "../hooks/useFollowUnfollow";
import { TagsList } from "design-system/molecules/tags-list";
import { PRONOUNS_MAP } from "../../shared/profile-utils";
import { useGetUserProfileById } from "../hooks/useGetUserProfileById";
import { OrgProfileTags } from "./org-profile-tags";
import { UserDisplayNameWithLogo } from "./user-with-logo";
import { getIsPremiumOrganisation } from "services/roles/user-roles";

type ProfileSummaryProps = {
  profileExternalId?: string;
  hideTags?: boolean;
  showProfileLink: boolean;
  /**
   * Used to add an additional button to the profile summary.
   * An example is when we pass the button for the PWA installation
   * prompt
   */
  additionalButton?: React.ReactNode;
  /**
   * @param hrefTarget
   * Dependending on where we are rendering this component, we might want to
   * open the profile in a separate tab. This will be the case in the drop-in
   * as we do not want users to navigate away. Defaults to _target, pass _self
   * to open in the same page
   */
  hrefTarget?: string;
} & DSComponentPropsProvider;

export const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  className = "",
  hideTags = false,
  profileExternalId,
  hrefTarget = "_blank",
  showProfileLink = true,
  additionalButton = null,
}) => {
  const { externalId: viewerExternalId, organisation } = useUserContext();
  const id = profileExternalId || viewerExternalId;
  const isOwnProfile = viewerExternalId === id;
  const { profileData, viewerData, errorMessage, isLoading } =
    useGetUserProfileById(id);
  const user = profileData?.user;
  const { onFollow, onUnfollow, isFollowingState, followersState, Toast } =
    useFollowUnfollow({
      displayName: user ? getUserDisplayName(user) : "",
      isFollowing: profileData?.isFollowing,
      followers: profileData?.followers,
      userExternalId: user?.externalId,
    });

  return isLoading ? (
    <SkeletonProfileSummary />
  ) : errorMessage || !user || !profileData ? (
    <Box>{errorMessage}</Box>
  ) : (
    <>
      {Toast}
      <Box className={`flex-col gap-24 ${className}`}>
        <Row className="justify-between align-end">
          <Box
            className="pos-relative"
            style={{
              width: "fit-content",
            }}
          >
            <Avatar
              imageUrl={user.avatarUrl}
              dimensions={100}
              userFullName={getUserDisplayName(user)}
            />
            <Box
              className="pos-absolute"
              style={{
                bottom: 0,
                right: 0,
              }}
            >
              <MilestoneBadgesContainer
                userId={user._id.toString()}
                latestCompletedOnly
                badgeDimensions={40}
              />
            </Box>
          </Box>
          <Box className="flex gap-8">
            {!isOwnProfile &&
              (isFollowingState ? (
                <Button variant="secondary" size="small" onClick={onUnfollow}>
                  Unfollow
                </Button>
              ) : (
                <>
                  {!user.isPrivateProfile && (
                    <Button variant="secondary" size="small" onClick={onFollow}>
                      Follow
                    </Button>
                  )}
                </>
              ))}

            <BoxCol>
              {/* {isOwnProfile && hideTags && (
                <Button
                  className="flex gap-8"
                  variant="wrapped"
                  size="small"
                  onClick={() => navigate(WRAPPED_URL_PREFIX)}
                >
                  <Icon icon="sparkle" size={12} /> Your 2024 Wrapped!
                </Button>
              )} */}
              {additionalButton}
              {showProfileLink && (
                <Link
                  href={
                    isOwnProfile
                      ? PROFILE_URL
                      : `${USER_URL}/${user.externalId}`
                  }
                  target={hrefTarget}
                >
                  <Button
                    dataCy="go-to-profile-nav"
                    className="flex gap-4 align-center"
                    variant="secondary"
                    size="small"
                    full
                  >
                    Go to profile{" "}
                    <Icon
                      icon={
                        hrefTarget === "_blank"
                          ? "external-link"
                          : "arrow-right"
                      }
                      size={14}
                    />
                  </Button>
                </Link>
              )}
            </BoxCol>
          </Box>
        </Row>
        <Box className="flex-col gap-4">
          <Row className="justify-between">
            <UserDisplayNameWithLogo user={user} />
            {profileData.isFacilitator ? (
              <Pill className="flex gap-8" backgroundColor="grey100">
                <Icon icon="sparkle-filled" size={14} /> FLOWN facilitator
              </Pill>
            ) : (
              user.isHost &&
              !getIsPremiumOrganisation(organisation) && <CommunityHostPill />
            )}
          </Row>
          {getIsPremiumOrganisation(organisation) ? (
            <OrgProfileTags
              className="fw-600"
              orgProfileTags={user.orgProfileTags || []}
              timezone={user.timezone}
            />
          ) : (
            [user.oneLiner, user.company].filter(Boolean).join(" • ")
          )}{" "}
          {organisation &&
            getIsPremiumOrganisation(organisation) &&
            (!user.orgProfileTags || user.orgProfileTags.length === 0) &&
            isOwnProfile && (
              <Box className="mar-y-8">
                <Text color="grey400" className="mar-y-8" fontSize="xs">
                  Help {organisation.name} members find and connect with you.
                </Text>

                <Link
                  href={
                    isOwnProfile
                      ? PROFILE_URL
                      : `${USER_URL}/${user.externalId}`
                  }
                  target={hrefTarget}
                >
                  <Button
                    dataCy="go-to-profile-nav"
                    className="flex gap-4"
                    variant="secondary"
                    size="small"
                  >
                    Complete your profile
                  </Button>
                </Link>
              </Box>
            )}
          <Text color="grey400" fontSize="xs">
            {[PRONOUNS_MAP[user.pronouns || ""], user.location]
              .filter(Boolean)
              .join(" • ")}
          </Text>
          <Text color="grey400" fontSize="xs" fontWeight={600}>
            {followersState.length} followers • {profileData.following.length}{" "}
            following
          </Text>
        </Box>
        <Box className="flex-col gap-8">
          {!hideTags &&
          profileData?.formattedTags &&
          profileData?.formattedTags.length ? (
            <>
              <Text fontSize="xs" fontWeight={700}>
                {isOwnProfile ? "" : "Interests"}
              </Text>
              <TagsList
                limit={6}
                tags={profileData?.formattedTags}
                tagSize={"small"}
              />
            </>
          ) : (
            !viewerData?.tags.length && (
              <>
                <Text color="grey400" fontSize="xs">
                  Discover what you have in common with other members.
                </Text>
                <Link href={PROFILE_URL} target={hrefTarget}>
                  <Button variant="secondary" size="small">
                    Add your first tag.
                  </Button>
                </Link>
              </>
            )
          )}
        </Box>
      </Box>
    </>
  );
};

const SkeletonProfileSummary = () => (
  <Box>
    <Box
      className="gap-12"
      style={{ display: "grid", gridTemplateRows: "1fr 1fr" }}
    >
      <div
        className="skeleton b-radius-round"
        style={{ height: "100px", width: "100px", borderRadius: "9999px" }}
      />
      <Box className="flex-col gap-8 w-full">
        <div className="skeleton" style={{ height: "16px", width: "100px" }} />
        <div className="skeleton" style={{ height: "16px", width: "100px" }} />
        <div className="skeleton" style={{ height: "16px", width: "150px" }} />
        <div className="skeleton" style={{ height: "16px", width: "200px" }} />
      </Box>
    </Box>
  </Box>
);
