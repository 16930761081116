import styled from "@emotion/styled";
import { ImageBlockFragment } from "generated/graphql-typed";
import { EMOTION_THEME } from "design-system/emotion.theme";
import { renderer } from "./renderer";

const ImageView = (props: ImageBlockFragment & { className: string }) => {
  return (
    <ImageWrapper
      float={props.wrap && props.alignment ? props.alignment : "none"}
      alignment={props.alignment || ""}
      maxWidth={props.wrap && props.maxWidth ? props.maxWidth : "100%"}
      className={props.showOn || ""}
    >
      <Img
        src={props.image?.url}
        maxWidth={`calc( ${props.wrap ? "100%" : props.maxWidth || "100%"} ${
          props.alignment == "center" ? `- ${EMOTION_THEME.spacing[32]}` : "" // we need to add in the margin either side of the image
        })`}
        alignment={props.wrap ? props.alignment || "" : ""}
        data-testid={props.alignment}
      />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.section<{
  float: string;
  maxWidth: string;
  alignment: string;
}>`
  max-width: min(800px, 100%);
  float: ${({ float }) => `${float}`};
  margin-left: ${({ theme, alignment }) =>
    alignment === "left"
      ? "0px"
      : alignment === "center"
      ? "auto"
      : theme.spacing[16]};
  margin-right: ${({ theme, alignment }) =>
    alignment === "right"
      ? "0px"
      : alignment === "center"
      ? "auto"
      : theme.spacing[16]};
  text-align: ${({ alignment }) => `${alignment}`};
  ${({ theme }) => theme.media["bp-tablet"]} {
    max-width: ${({ maxWidth }) => `min(800px, ${maxWidth})`};
  }

  &.mobile {
    ${({ theme }) => theme.media["bp-tablet"]} {
      display: none;
    }
  }
  &.desktop {
    display: none;
    ${({ theme }) => theme.media["bp-tablet"]} {
      display: block;
    }
  }
`;

const Img = styled.img<{ maxWidth: string; alignment: string }>`
  display: inline-block;
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  margin-left: ${({ theme, alignment }) =>
    alignment === "left"
      ? "0px"
      : alignment === "center"
      ? "auto"
      : theme.spacing[16]};
  margin-right: ${({ theme, alignment }) =>
    alignment === "right"
      ? "0px"
      : alignment === "center"
      ? "auto"
      : theme.spacing[16]};
`;

export const ImageRenderer = renderer({
  typeName: "ImageRecord",
  view: ImageView,
});
