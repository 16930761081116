import { Tag } from "utils/tag-utils";
import { getAdditionalEventTags } from "../calendar-client";
import { useEffect, useState } from "react";
import { useUserContext } from "context/user-context";
export const useGetAdditionalEventTagsMap = () => {
  const { loggedIn } = useUserContext();
  const [additionalEventTagsMap, setAdditionalEventTagsMap] = useState<Record<
    string,
    Tag[]
  > | null>(null);
  useEffect(() => {
    if (!loggedIn) return;
    const getTags = async () => {
      const additionalEventTagsRes = await getAdditionalEventTags();

      if (additionalEventTagsRes.worked) {
        setAdditionalEventTagsMap(additionalEventTagsRes.eventTagMap);
      }
    };
    void getTags();
  }, [loggedIn]);

  return additionalEventTagsMap;
};
