import styled from "@emotion/styled";
import dayjs from "dayjs";
import { ContentContainer, desktopQuery } from "legacy-design-system";
import {
  FlockSessionListBlockFragment,
  FlockSessionRecord,
  FlockTypeRecord,
} from "generated/graphql-typed";
import React from "react";
import { assertFieldsNotNull } from "utils/assertions";
import { ClassNameProvider, useUserTimezone } from "utils/component-utils";
import { formatToShortISO } from "utils/date";
import { getUserTimezoneFlockTime } from "utils/flocks-utils";
import { EventCard } from "design-system/molecules/cards/event-card";

type FlocksSessionListProps = (
  | {
      flockSessions: FlockSessionRecord[];
    }
  | FlockSessionListBlockFragment
) &
  ClassNameProvider;

export const FlockSessionList: React.FC<FlocksSessionListProps> = ({
  flockSessions,
  className,
}) => {
  const getFlockTimesFromFlockTypes = (flockTypes: FlockTypeRecord[]) =>
    flockTypes.map((flockType) => {
      const localFlockTime = getUserTimezoneFlockTime({
        isoDay: formatToShortISO(dayjs()),
        flockStartTime: flockType.startTime as string,
        userTimezone,
      });
      return {
        time: localFlockTime.format("h:mma"),
        timeDescription: flockType.timeDescription,
      };
    });
  const { userTimezone } = useUserTimezone();
  return (
    <>
      {flockSessions && (
        <FlocksList className={className}>
          {flockSessions.map((flockSession, index) => {
            const { flockTypes, name } = assertFieldsNotNull(flockSession, [
              "flockTypes",
              "name",
            ]);
            const flockTimes = getFlockTimesFromFlockTypes(
              flockTypes as FlockTypeRecord[]
            );
            //Now we're using the written time description as the flock types
            //now don't fit with the new events model (and we had too many events).
            const timeDescription = flockTimes[0]?.timeDescription
              ? flockTimes[0]?.timeDescription
              : `${flockTimes.map((t) => t.time).join(", ")}, Mon-Fri`;

            // All flocks in a session are the same so we just grab the first one for the metadata
            // The alternative would be to move this to the overall session
            const flockType = assertFieldsNotNull(flockTypes[0], [
              "description",
              "longDescription",
              "icon",
            ]);
            return (
              <EventCard
                key={index}
                displayName={name}
                description={flockType.description}
                longDescription={flockType.longDescription}
                richDescription={flockSession.richDescription}
                timeDescription={timeDescription}
                icon={flockType.icon}
              />
            );
          })}
        </FlocksList>
      )}
    </>
  );
};

const FlocksList = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 0 auto;
  @media ${desktopQuery} {
    flex-wrap: nowrap;
  }
`;
