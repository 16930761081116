"use client";
import { useUserContext } from "context/user-context";
import { Text } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import { getIsOnline } from "design-system/hooks/use-online-connections";
import Image from "next/image";
import { useState } from "react";
import { UserOnlineStatus } from "shared/user.types";
import { styled } from "styled-system/jsx";
import { getUserInitials } from "utils/string-utils";
import { LiveNowButton } from "./live-now-button";

export type AvatarProps = {
  imageUrl?: string;
  dimensions?: number;
  onlineStatus?: UserOnlineStatus;
  userFullName?: string;
} & DSComponentPropsProvider;

const AVATAR_DEFAULT_IMG_URL = "/assets/fallbacks/avatar.svg";

export const Avatar: React.FC<AvatarProps> = ({
  className = "",
  dimensions = 40,
  imageUrl = AVATAR_DEFAULT_IMG_URL,
  userFullName,
  onClick,
  onlineStatus,
  style,
  ...props
}) => {
  const { isPremiumOrganisation } = useUserContext();
  const [imageSrc, setImageSrc] = useState(imageUrl);

  const handleError = () => {
    setImageSrc(AVATAR_DEFAULT_IMG_URL);
  };

  return (
    <div className="pos-relative">
      <AvatarContainer
        className={`${onClick ? "animate-on-hover" : ""} ${className}`}
        style={{
          ...style,
          width: dimensions,
          height: dimensions,
        }}
        onClick={onClick}
        {...props}
      >
        {(!imageUrl || imageUrl === AVATAR_DEFAULT_IMG_URL) && userFullName ? (
          <Text fontWeight={700} style={{ fontSize: dimensions / 2.5 }}>
            {getUserInitials(userFullName)}
          </Text>
        ) : (
          <Image
            src={imageSrc || AVATAR_DEFAULT_IMG_URL}
            alt="Avatar image"
            onError={handleError}
            fill
            sizes={`${dimensions}px`}
          />
        )}
      </AvatarContainer>
      {isPremiumOrganisation && getIsOnline(onlineStatus) ? (
        <LiveNowButton />
      ) : null}
    </div>
  );
};

const AvatarContainer = styled("div", {
  base: {
    backgroundColor: "grey100",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: "round",
    transition: "opacity 0.2s ease-in-out",
    "&.animate-on-hover:hover": {
      cursor: "pointer",
      opacity: "0.6",
    },
  },
});
