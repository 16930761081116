import { Icon, TextFlex } from "design-system/components";

export const CALENDAR_TAB_FILTERS = {
  ATTENDING: {
    label: (
      <TextFlex className="gap-8 justify-center" as={"span"}>
        <Icon size={14} icon="added-to-calendar" /> Attending
      </TextFlex>
    ),
    value: "attending",
  },
  HOSTING: {
    label: (
      <TextFlex className="gap-8 justify-center" as={"span"}>
        <Icon size={14} icon="users" /> Hosting
      </TextFlex>
    ),
    value: "hosting",
  },
  PAST: {
    label: (
      <TextFlex className="gap-8 justify-center" as={"span"}>
        <Icon size={14} icon="arrow-ccw" /> Past
      </TextFlex>
    ),
    value: "past",
  },
} as const;

export type CalendarTabFilter =
  typeof CALENDAR_TAB_FILTERS[keyof typeof CALENDAR_TAB_FILTERS];

export type CalendarTabFilterValue = CalendarTabFilter["value"];
