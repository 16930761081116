import { useCallback, useEffect, useState } from "react";
import {
  getAllPublicIntentions,
  getSessionIntentions,
  getUserIntentions,
  getOrganisationPublicIntentions,
} from "../accountability-client";
import {
  GetPublicIntentionsResponse,
  PublicIntentionEntry,
} from "features/accountability/shared";

type UseIntentionProps = {
  /**
   * @param {string} [sessionId] - If provided, fetches intentions for a specific session.
   **/
  sessionId?: string;
  /**
   * @param {string} [userExternalId] - If provided, fetches intentions for a specific user.
   **/
  userExternalId?: string;
  /**
   * @param {number} [limitIntentions] - If provided, limits the number of intentions returned.
   **/
  limitIntentions?: number;
  /**
   * @param {boolean} [onlyTodaysIntentions=false] - If true, only returns intentions created today.
   **/
  onlyTodaysIntentions?: boolean;
  organisationId?: string;
  /**
   * @param {boolean} [shouldUpdate=true] - If true, keeps updating the feed every 20 seconds
   **/
  shouldUpdate?: boolean;
  withPrivateIntentions?: boolean;
};

/**
 * @returns {Object} Returns an object with the following properties:
 *   @property {boolean} isLoading - Whether or not the intentions are still loading.
 *   @property {Array} intentions - An array of intentions.
 *   @property {string} errorMessage - An error message if the intentions failed to load.
 **/
export const useIntentions = ({
  sessionId,
  userExternalId,
  limitIntentions,
  onlyTodaysIntentions,
  organisationId,
  shouldUpdate = true,
  withPrivateIntentions,
}: UseIntentionProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [intentions, setIntentions] = useState<PublicIntentionEntry[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const fetchPublicIntentions = useCallback(async () => {
    try {
      if (userExternalId) {
        if (withPrivateIntentions) {
          const result = await getUserIntentions({
            userExternalId,
            includePrivate: true,
          })();
          if (result.worked && result.intentions) {
            return setIntentions(
              limitIntentions
                ? result.intentions.slice(0, limitIntentions)
                : result.intentions
            );
          }
        } else {
          const result = await getUserIntentions({ userExternalId })();
          if (result.worked && result.intentions) {
            return setIntentions(
              limitIntentions
                ? result.intentions.slice(0, limitIntentions)
                : result.intentions
            );
          }
        }
      }
      if (organisationId) {
        const result = await getOrganisationPublicIntentions(organisationId);
        if (result.worked && result.intentions) {
          return setIntentions(
            limitIntentions
              ? result.intentions.slice(0, limitIntentions)
              : result.intentions
          );
        }
      }

      let result: GetPublicIntentionsResponse;
      if (sessionId) {
        result = await getSessionIntentions(
          sessionId,
          !!withPrivateIntentions,
          onlyTodaysIntentions
        )();
      } else {
        // Retrieve all the public intentions for any session/user
        result = await getAllPublicIntentions(onlyTodaysIntentions);
      }

      if (!result.worked) {
        return setErrorMessage("No intentions found. Will try again soon");
      }

      if (result.intentions) {
        setIntentions(
          limitIntentions
            ? result.intentions.slice(0, limitIntentions)
            : result.intentions
        );
      }
    } catch (error) {
      setErrorMessage("Failed to fetch public intentions");
    } finally {
      setIsLoading(false);
    }
  }, [
    limitIntentions,
    onlyTodaysIntentions,
    organisationId,
    sessionId,
    userExternalId,
    withPrivateIntentions,
  ]);

  useEffect(() => {
    void fetchPublicIntentions();
    const fetchIntentionsInterval = setInterval(() => {
      if (shouldUpdate) void fetchPublicIntentions();
    }, 20 * 1000);
    return () => clearInterval(fetchIntentionsInterval);
  }, [
    sessionId,
    userExternalId,
    limitIntentions,
    onlyTodaysIntentions,
    withPrivateIntentions,
    organisationId,
    shouldUpdate,
    fetchPublicIntentions,
  ]);

  return {
    isLoading,
    intentions,
    errorMessage,
  };
};
