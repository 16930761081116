import styled from "@emotion/styled";
import { Icon } from "design-system/components";
import { Box, Text } from "design-system/components";
import { DSComponentPropsProvider } from "design-system/design-system.types";
import Link from "next/link";
import { memo } from "react";

type LinkBannerProps = DSComponentPropsProvider & {
  backgroundColor?: string;
  href?: string;
  text: string;
  /**
   * @property Useful for analytics, or to handle additional onClick
   * action alongside the href.
   */
  onLinkClick?: () => void;
  /**
   * @property To be used in conjunction with onCloseBannerClick.
   */
  closeable?: boolean;
  onCloseBannerClick?: () => void;
};

export const Banner = ({
  closeable = true,
  href,
  text,
  onCloseBannerClick,
  onLinkClick,
  ...props
}: LinkBannerProps) => {
  const BannerElement = memo(() => (
    <BannerContainer {...props}>
      <Box className="flex gap-8 align-center">
        <Icon icon="eagle" size={32} />
        <Text fontSize="sm" fontWeight={600}>
          {text}
        </Text>
      </Box>
      {closeable && onCloseBannerClick && (
        <Box className="banner-close-icon b-radius-round flex-centralized">
          <Icon
            icon="close"
            onClick={(e) => {
              e.preventDefault();
              onCloseBannerClick();
            }}
            size={10}
          />
        </Box>
      )}
    </BannerContainer>
  ));

  return href ? (
    <Link href={href} onClick={() => (onLinkClick ? onLinkClick() : null)}>
      <BannerElement />
    </Link>
  ) : (
    <BannerElement />
  );
};

const BannerContainer = styled.div(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.blueGrey100,
  borderRadius: theme.radii[12],
  color: theme.colors.black,
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing[32],
  padding: theme.spacing[20],
  transition: "box-shadow 0.2s ease-in-out",

  "&:hover": {
    boxShadow: `0 0 12px 2px ${theme.colors.focusMid}`,
  },

  "&:focus": {
    boxShadow: `0 0 12px 6px ${theme.colors.focusMid}`,
  },

  ".banner-close-icon": {
    height: "20px",
    width: "20px",
    "&:hover": {
      backgroundColor: theme.colors.focusMid,
    },
  },
}));
