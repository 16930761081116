import dayjs from "dayjs";
import { getSiteBaseUrl } from "services/config";
import { DayOfTheWeek, DAYS_OF_THE_WEEK } from "utils/date";
import { DatoSessionRecord } from "features/calendar/shared";
import { SessionRecord } from "shared/session.types";
import { SESSIONS_GO_BASE_URL } from "./urls";
import { FlownEvent } from "shared/event.types";
import { getIsDropInEvent } from "./event-utils";

type GetUserTimezoneFlockTime = {
  isoDay: string;
  flockStartTime: string;
  userTimezone: string;
};

export const getUserTimezoneFlockTime = ({
  isoDay,
  flockStartTime,
  userTimezone,
}: GetUserTimezoneFlockTime) => {
  const [hours, minutes] = flockStartTime.split(":").map(parseFloat);
  const flockTimeInLondon = dayjs(isoDay)
    .tz("Europe/London")
    .set("hour", hours)
    .set("minutes", minutes);

  const localFlockTime = dayjs(flockTimeInLondon.format()).tz(
    userTimezone,
    true
  );
  return localFlockTime;
};

export const getSessionUrl = (sessionId: string): string =>
  `${getSiteBaseUrl()}sessions/go/${sessionId}`;

export const isScheduledSessionFreeOnDay = (
  eventRecord: DatoSessionRecord,
  day: dayjs.Dayjs
): boolean => {
  if (!eventRecord) return false;

  const freeDates =
    eventRecord.freeDates.map((freeDate) => freeDate?.date) || [];

  const freeDays = eventRecord.freeOnRecurringDay.map((dayType) => {
    if (dayType?.day) {
      return DAYS_OF_THE_WEEK.indexOf(dayType?.day as DayOfTheWeek);
    }
  });

  return (
    freeDates.includes(day.format("YYYY-MM-DD")) ||
    freeDays?.includes(day.subtract(4, "hours").day()) ||
    freeDays?.includes(day.add(4, "hours").day()) // This is to account for fridays in different timezones spanning longer
    //than friday in the UK.  We make the day window longer to account for this.
  );
};

export const getAllTypesOfSessionsAttended = (
  allSessionsAttended: SessionRecord[] = []
) => {
  const sessionsAttended = allSessionsAttended.length;
  const p2pSessionsAttended = allSessionsAttended.filter(
    (flock) => flock.isP2P
  ).length;
  const flocksAttended = allSessionsAttended.filter(
    (flock) => !flock.isP2P && flock.category === "focus"
  ).length;
  const rechargesAttended = allSessionsAttended.filter(
    (flock) => !flock.isP2P && flock.category === "recharge"
  ).length;

  return {
    flocksAttended,
    sessionsAttended,
    rechargesAttended,
    p2pSessionsAttended,
  };
};

export const getIsZoomFlock = (
  event: Pick<FlownEvent, "slug" | "sessionUrl">
) =>
  event.sessionUrl.includes(SESSIONS_GO_BASE_URL) && !getIsDropInEvent(event);
