import { NewQuoteblock } from "features/pillars-and-blog";
import { NewQuoteblockBlockFragment } from "generated/graphql-typed";
import { renderer } from "./renderer";

export const NewQuoteblockView = (
  props: NewQuoteblockBlockFragment & { className: string }
) => {
  return (
    <NewQuoteblock
      quote={props.newQuoteblockQuote}
      quoteSize={props.newQuoteblockQuoteSize}
      author={props.newQuoteblockAuthor}
      backgroundColor={props.newQuoteblockBackgroundColor}
      squiggles={props.newQuoteblockSquiggles}
    />
  );
};

export const NewQuoteblockRenderer = renderer({
  typeName: "NewQuoteblockRecord",
  view: NewQuoteblockView,
});
