import { useUserContext } from "context/user-context";
import {
  Box,
  Button,
  DropdownMenu,
  Icon,
  Text,
} from "design-system/components";
import { useRouter } from "next/router";
import { NoSSR } from "utils/component-utils";
import Image from "next/image";
import {
  CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL,
  CREATE_PRIVATE_FIXED_FORMAT_ROOM_URL,
  FOCUS_ROOM_URL_PREFIX,
  getOrganisationCreateSessionUrl,
} from "utils/urls";
import { css } from "styled-system/css";

type CreateSessionButtonProps = {
  variant?: "primary" | "success-inverted";
};

export const CreateSessionButton: React.FC<CreateSessionButtonProps> = ({
  variant = "success-inverted",
}) => {
  const { canHostSessions, organisation } = useUserContext();

  const hideCommunityEvents =
    organisation &&
    organisation.menuItems &&
    !organisation?.menuItems?.includes("Community");

  const router = useRouter();

  return (
    <DropdownMenu
      triggerAsChild
      trigger={
        <span>
          <Button
            className="flex gap-4"
            id="calendar-page-create-session-btn"
            dataCy="calendar-page-create-session-btn"
            size="small"
            variant={variant}
          >
            Create session
            <Icon icon="add-to-calendar" size={14} />
          </Button>
        </span>
      }
    >
      <Box
        onClick={() => {
          void router.push(FOCUS_ROOM_URL_PREFIX);
        }}
        id="calendar-page-launch-focus-room-dropdown-option"
        data-cy="calendar-page-launch-focus-room-dropdown-option"
        className={css({
          padding: "4px",
          paddingBottom: "10px",
          borderBottom: "1px solid #e5e1e6",
        })}
      >
        <Text className="flex w-full align-center gap-8">
          <Icon icon="padlock" size={14} />
          Launch focus room
        </Text>
        <Text fontSize="xs" color="grey400" className="mar-left-24">
          Private focus session
        </Text>
      </Box>
      <Box
        className="pad-4 w-full"
        onClick={() => {
          void router.push(CREATE_PRIVATE_FIXED_FORMAT_ROOM_URL);
        }}
        id="calendar-page-create-private-session-dropdown-option"
        data-cy="calendar-page-create-private-session-dropdown-option"
      >
        <Text className="flex w-full align-center gap-8">
          <Icon icon="padlock" size={14} /> Private session
        </Text>
        <Text fontSize="xs" color="grey400" className="mar-left-24">
          Unlisted session
        </Text>
      </Box>
      {hideCommunityEvents ? (
        <></>
      ) : (
        <Box
          className={`pad-4 w-full ${
            canHostSessions ? "" : "cursor-not-allowed"
          }`}
          onClick={() => {
            if (canHostSessions) {
              void router.push(CREATE_COMMUNITY_FIXED_FORMAT_ROOM_URL);
            }
          }}
          id="calendar-page-create-public-session-dropdown-option"
          data-cy="calendar-page-create-public-session-dropdown-option"
        >
          <Text
            className="flex w-full align-center gap-8"
            color={canHostSessions ? undefined : "grey200"}
          >
            <Icon icon="people" size={14} /> Public session
          </Text>
          <Text
            fontSize="xs"
            color={canHostSessions ? "grey400" : "grey200"}
            className="mar-left-24"
          >
            Listed for all users
          </Text>
        </Box>
      )}
      <NoSSR>
        {!!organisation && (
          <Box
            className="pad-4 w-full"
            onClick={() => {
              void router.push(
                getOrganisationCreateSessionUrl(organisation.slug)
              );
            }}
            id="calendar-page-create-org-session-dropdown-option"
            data-cy="calendar-page-create-org-session-dropdown-option"
          >
            <Text className="flex w-full align-center gap-8">
              {organisation.logoUrl ? (
                <Image
                  className="b-radius-round"
                  src={organisation.logoUrl}
                  height={16}
                  width={16}
                  alt="FLOWN logo"
                />
              ) : (
                <span />
              )}
              Organization session
            </Text>
            <Text fontSize="xs" color="grey400" className="mar-left-24">
              Listed for members of {organisation.name}
            </Text>
          </Box>
        )}
      </NoSSR>
    </DropdownMenu>
  );
};
