import { useEffect, useState } from "react";
import { normalizeError } from "services/normalize-error";
import { RequestStatus } from "shared/response.types";
import { MilestoneBadge } from "../../shared/badge-types";
import {
  postToComputeBadges,
  postToComputeMilestoneBadgesByUserId,
} from "../badges-client";

type MilestoneBadgesState = {
  badges: MilestoneBadge[];
  status: RequestStatus;
  error: string;
  nextBadge: MilestoneBadge | null;
  latestCompletedBadge: MilestoneBadge | null;
  totalSessions: number;
  timeBoundBadges: MilestoneBadge[];
};

export const useMilestoneBadges = (userId?: string) => {
  const [milestoneBadgesState, setMilestoneBadgesState] =
    useState<MilestoneBadgesState>({
      badges: [],
      status: "idle",
      error: "",
      nextBadge: null,
      latestCompletedBadge: null,
      totalSessions: 0,
      timeBoundBadges: [],
    });

  useEffect(() => {
    const fetchMilestoneBadges = async () => {
      try {
        setMilestoneBadgesState((prev) => ({ ...prev, status: "loading" }));
        const response = userId
          ? await postToComputeMilestoneBadgesByUserId({
              userId,
            })
          : await postToComputeBadges();
        if (!response.worked) {
          setMilestoneBadgesState({
            badges: [],
            status: "rejected",
            error: response.error,
            nextBadge: null,
            latestCompletedBadge: null,
            totalSessions: 0,
            timeBoundBadges: [],
          });
          return;
        }
        const { milestoneBadges } = response;
        setMilestoneBadgesState({
          badges: milestoneBadges.allBadges,
          status: "resolved",
          error: "",
          nextBadge: milestoneBadges.nextBadge,
          latestCompletedBadge: milestoneBadges.latestCompletedBadge,
          totalSessions: milestoneBadges.totalActivities || 0,
          timeBoundBadges: milestoneBadges.timeBoundBadges || [],
        });
      } catch (error) {
        const normalizedError = normalizeError(error);
        setMilestoneBadgesState({
          badges: [],
          status: "rejected",
          error: normalizedError.message,
          nextBadge: null,
          latestCompletedBadge: null,
          totalSessions: 0,
          timeBoundBadges: [],
        });
      }
    };
    if (milestoneBadgesState.status === "idle") {
      void fetchMilestoneBadges();
    }
  }, [milestoneBadgesState.status, userId]);

  return milestoneBadgesState;
};
