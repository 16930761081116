"use client";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { ClassNameProvider } from "utils/component-utils";
import { Box } from "./box";

type CollapsableWrapperProps = {
  isMobileOnly?: boolean;
  isShowing?: boolean;
  children: React.ReactNode;
  collapseCTA?: React.ReactNode;
};

export const CollapsableWrapper = ({
  children,
  className,
  collapseCTA,
  isShowing,
}: CollapsableWrapperProps & ClassNameProvider) => {
  const [controlledIsShowing, setControlledIsShowing] = useState(false);

  useEffect(() => {
    if (typeof isShowing === "boolean") {
      setControlledIsShowing(isShowing);
    }
  }, [isShowing]);

  return (
    <Box>
      {collapseCTA && (
        <div onClick={() => setControlledIsShowing(!controlledIsShowing)}>
          {collapseCTA}
        </div>
      )}
      <ContentWrappeDiv
        className={`${controlledIsShowing ? "showing" : "hiding"} ${
          className || ""
        }`}
      >
        {children}
      </ContentWrappeDiv>
    </Box>
  );
};

const ContentWrappeDiv = styled.div`
  transition: all 0.3s linear;
  overflow: hidden;

  &.showing {
    max-height: 1000px;
  }

  &.hiding {
    max-height: 1px;
  }
`;
