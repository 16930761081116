import { getUserDisplayName } from "features/profile/shared";
import { Box, BoxCol, Text } from "design-system/components";
import { UserCard } from "design-system/molecules/cards/user-card";
import { UserFollowCard } from "./user-follow-card";
import { FollowUser } from "shared/user.types";
import { WorkedOrError } from "shared/response.types";
import { useUserContext } from "context/user-context";
import { useMemo } from "react";

type UserListProps = {
  users: FollowUser[];
  onFollow?: (user: FollowUser) => Promise<WorkedOrError>;
  onUnfollow?: (user: FollowUser) => Promise<WorkedOrError>;
  withFollows?: boolean;
};

export const UserListWithFollows: React.FC<UserListProps> = ({
  users,
  withFollows = true,
  onFollow,
  onUnfollow,
}) => {
  const { organisation } = useUserContext();
  const organisationUsers = useMemo(
    () =>
      organisation
        ? users.filter((user) => user.organisation?.slug === organisation.slug)
        : [],
    [organisation, users]
  );

  const filteredUsers = useMemo(
    () => users.filter((user) => !organisationUsers.includes(user)),
    [users, organisationUsers]
  );

  return (
    <>
      {organisation && organisationUsers.length > 0 && (
        <BoxCol>
          <Text fontSize="xs" fontWeight={600} color="grey400">
            From {organisation.name}
          </Text>
          {organisationUsers.map((user, index) =>
            withFollows && onFollow && onUnfollow ? (
              <UserFollowCard
                key={`${user.externalId}-${index}`}
                user={user}
                onFollow={onFollow}
                onUnfollow={onUnfollow}
              />
            ) : (
              <Box
                key={`${user.externalId}-${index}`}
                className="flex align-center border-bot-grey100 pad-y-8"
              >
                <UserCard
                  className="flex-1"
                  displayName={getUserDisplayName(user)}
                  oneLiner={user.oneLiner}
                  {...user}
                />
              </Box>
            )
          )}
        </BoxCol>
      )}
      {filteredUsers.length > 0 && (
        <BoxCol>
          <Text fontSize="xs" fontWeight={600} color="grey400">
            Connections
          </Text>
          {filteredUsers.map((user, index) =>
            withFollows && onFollow && onUnfollow ? (
              <UserFollowCard
                key={`${user.externalId}-${index}`}
                user={user}
                onFollow={onFollow}
                onUnfollow={onUnfollow}
              />
            ) : (
              <Box
                key={`${user.externalId}-${index}`}
                className="flex align-center border-bot-grey100 pad-y-8"
              >
                <UserCard
                  className="flex-1"
                  displayName={getUserDisplayName(user)}
                  oneLiner={user.oneLiner}
                  {...user}
                />
              </Box>
            )
          )}
        </BoxCol>
      )}
    </>
  );
};
