export const THEME_BREAKPOINTS = {
  mobile: 375,
  "mobile-md": 480,
  tablet: 768,
  "tablet-md": 810,
  "desktop-xs-height": 768,
  "desktop-xs": 1024,
  "desktop-sm": 1280,
  "desktop-md": 1440,
  "desktop-lg": 1920,
} as const;
