import { useEffect, useState } from "react";
import { Credit } from "shared/credits.types";
import { postToCheckValidCreditsForUser } from "../credits-client";
import { clientLoggerForFile } from "utils/logger.client";

const logger = clientLoggerForFile(__filename);

type UseCheckValidCreditsForUserProps = {
  externalId?: string;
  isFreeCreditsRole: boolean;
  shouldTriggerCheck?: boolean;
};

export const useCheckValidCreditsForUser = ({
  externalId,
  isFreeCreditsRole,
  shouldTriggerCheck = true,
}: UseCheckValidCreditsForUserProps) => {
  const [userCredits, setUserCredits] = useState<Credit[]>([]);
  const [hasLoadedCredits, setHasLoadedCredits] = useState(false);
  /**
   * Check credits for user only when the side panel opens
   */
  useEffect(() => {
    if (!isFreeCreditsRole && !shouldTriggerCheck) return;
    const checkCredits = async () => {
      if (!externalId) {
        setUserCredits([]);
        return;
      }
      try {
        const response = await postToCheckValidCreditsForUser({
          userExternalId: externalId,
        });
        if (!response.worked) {
          logger.error(
            `Failed to check user credits. Error: ${response.error}`
          );
          setUserCredits([]);
          return;
        }
        const { credits } = response;

        setUserCredits(credits);
      } catch (error) {
        logger.error("Failed to check user credits");
        setUserCredits([]);
      } finally {
        setHasLoadedCredits(true);
      }
    };
    void checkCredits();
  }, [isFreeCreditsRole, externalId, shouldTriggerCheck]);

  const currentCredit = userCredits[0];

  return {
    userCredits,
    hasLoadedCredits,
    currentCredit,
  };
};
