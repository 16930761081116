import styled from "@emotion/styled";
import { ContentContainer } from "legacy-design-system";
import {
  TeamGridBlockFlownieProfileFragment,
  TeamGridBlockFragment,
} from "generated/graphql-typed";
import React, { useState } from "react";
import { getImageUrl } from "utils/component-utils";
import { renderer } from "./renderer";

const TeamGridView = (props: TeamGridBlockFragment & { className: string }) => (
  <ContentContainer className={props.className}>
    <BigBossCard {...props.boss} />
    <TeamContainer>
      {props.flownies.map((member, index) => (
        <FlownieCard {...member} key={index} />
      ))}
    </TeamContainer>
  </ContentContainer>
);

export const TeamGridRenderer = renderer({
  typeName: "TeamGridRecord",
  view: TeamGridView,
});

const TeamContainer = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, 284px);
  margin-bottom: 75px;
  justify-content: center;
`;

const BigBossCard: React.FC<TeamGridBlockFlownieProfileFragment> = ({
  name,
  jobTitle,
  description,
  likes,
  directPicture,
  lookAwayPicture,
}) => {
  return (
    <BigBossCardContainer>
      <BigBossPhotoHolder>
        {lookAwayPicture && directPicture && (
          <Photo photoUrl={lookAwayPicture.url} photo2Url={directPicture.url} />
        )}
      </BigBossPhotoHolder>
      <BigBossDescription>
        <Name>{name}</Name>
        <JobTitle>{jobTitle}</JobTitle>
        <Bio>{description}</Bio>
        <Likes>Likes: {likes}</Likes>
      </BigBossDescription>
    </BigBossCardContainer>
  );
};
const BigBossCardContainer = styled.div`
  max-width: 916px;
  border: 1px solid #e5e1e6;
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  margin: 88px auto 64px;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 999.9px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const BigBossPhotoHolder = styled.div`
  width: 284px;
  height: 328px;

  @media only screen and (min-width: 1000px) {
    img {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const BigBossDescription = styled.div`
  padding: 0 40px 20px 25px;

  @media only screen and (min-width: 1000px) {
    padding-bottom: 0;
  }
`;

const FlownieCard: React.FC<TeamGridBlockFlownieProfileFragment> = ({
  name,
  jobTitle,
  description,
  likes,
  directPicture,
  lookAwayPicture,
}) => {
  return (
    <Container>
      <TeamPhotoHolder>
        <Photo photoUrl={lookAwayPicture!.url} photo2Url={directPicture!.url} />
      </TeamPhotoHolder>
      <Name>{name}</Name>
      <JobTitle>{jobTitle}</JobTitle>
      <Bio>{description}</Bio>
      <Likes>Likes: {likes}</Likes>
    </Container>
  );
};

const BacksideContainer = styled.div`
  display: none;
  position: absolute;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  background: #4e8988;
  padding: 27px 20px;
  color: white;
  z-index: 10;
`;

const Container = styled.div`
  position: relative;
  &:hover ${BacksideContainer} {
    display: block;
  }
  &:focus ${BacksideContainer} {
    display: block;
  }
`;

const TeamPhotoHolder = styled.div`
  width: 100%;
  height: 330px;
`;

const Likes = styled.div`
  font-style: italic;
`;

const Name = styled.div`
  font-size: 24px;
  margin-bottom: 2px;
  margin-top: 20px;
`;
const JobTitle = styled.div`
  font-size: 16px;
  margin-bottom: 18px;
`;
const Bio = styled.div`
  max-width: 563px;
  margin-bottom: 26px;
  line-height: 26px;
`;

type PhotoProps = {
  photoUrl: string;
  photo2Url: string;
};

const Photo = ({ photoUrl, photo2Url }: PhotoProps) => {
  const [flipped, setFlipped] = useState(false);
  return (
    <PhotoScene
      className={flipped ? "is-flipped" : undefined}
      onMouseEnter={() => setFlipped(true)}
      onMouseLeave={() => setFlipped(false)}
      onTouchStart={() => setFlipped(!flipped)}
      onTouchEnd={() => setFlipped(!flipped)}
    >
      <PhotoCard
        className="is-face"
        src={getImageUrl({
          url: photoUrl,
          width: 284,
          height: 328,
          cover: true,
        })}
      />
      <PhotoCard
        className="is-back"
        src={getImageUrl({
          url: photo2Url,
          width: 284,
          height: 328,
          cover: true,
        })}
      />
    </PhotoScene>
  );
};

const PhotoScene = styled.div`
  width: 100%;
  height: 100%;
  perspective: 800px;
  outline: none;
  box-shadow: none;

  .is-back {
    display: none;
  }

  &.is-flipped {
    .is-back {
      display: block;
    }
    .is-face {
      display: none;
    }
  }
`;

const PhotoCard = styled.img`
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
  object-fit: cover;
`;
