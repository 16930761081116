import { Icon } from "design-system/components";

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Box } from "../../components";
import {
  getStyleProps,
  SocialMediaButton,
  SocialMediaCommonProps,
} from "./social-media-commons";
import { css } from "styled-system/css";

type SocialMediaLinksProps = SocialMediaCommonProps & {
  links: {
    twitter?: string;
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    whatsapp?: string;
  };
};

export const SocialMediaLinks = ({
  color = "black",
  links,
  size = "medium",
}: SocialMediaLinksProps) => {
  const { buttonSizeStyles, iconStyles } = getStyleProps(size, color);

  return (
    <Box className={`flex gap-8`}>
      {links.facebook && (
        <SocialMediaButton
          color={color}
          href={links.facebook}
          target="_blank"
          {...buttonSizeStyles}
        >
          <FacebookIcon round {...iconStyles} />
        </SocialMediaButton>
      )}
      {links.instagram && (
        <SocialMediaButton
          color={color}
          href={links.instagram}
          target="_blank"
          {...buttonSizeStyles}
        >
          <Icon
            icon="instagram"
            className={css({
              padding: "6px",
            })}
            color={iconStyles.iconFillColor}
            size={iconStyles.size}
          />
        </SocialMediaButton>
      )}
      {links.twitter && (
        <SocialMediaButton
          color={color}
          href={links.twitter}
          target="_blank"
          data-cy="twitter-link"
          {...buttonSizeStyles}
        >
          <TwitterIcon round {...iconStyles} />
        </SocialMediaButton>
      )}
      {links.linkedin && (
        <SocialMediaButton
          color={color}
          href={links.linkedin}
          target="_blank"
          {...buttonSizeStyles}
        >
          <LinkedinIcon round {...iconStyles} />
        </SocialMediaButton>
      )}
      {links.whatsapp && (
        <SocialMediaButton
          color={color}
          href={links.whatsapp}
          target="_blank"
          {...buttonSizeStyles}
        >
          <WhatsappIcon round {...iconStyles} />
        </SocialMediaButton>
      )}
    </Box>
  );
};
