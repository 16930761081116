"use client";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { analytics, catchAnalyticsError } from "features/analytics";
import dayjs from "dayjs";
import { clientLoggerForFile } from "utils/logger.client";
import {
  useDaily,
  useLocalSessionId,
  useParticipantProperty,
} from "@daily-co/daily-react";
import { DROP_IN_ID } from "utils/constants";
import { DailyCall } from "@daily-co/daily-js";
import { PublicIntentionEntry } from "features/accountability/shared";

const logger = clientLoggerForFile(__filename);

// Time if users turns off their camera
const CAMERA_OFF_TIME_LIMIT = 5000;

// Time if users joins without their camera
const CAMERA_OFF_TIME_LIMIT_JOIN = 10 * 60 * 1000; // 10 minutes

type VideoCallContextData = {
  isCameraOff: boolean;
  /**
   * @prop {number} cameraOffFor Amount of seconds the user has been inactive and after turning their camera off for
   */
  cameraOffFor?: number;
  forcedToBreakRoomReason?: string;
  setForcedToBreakRoomReason: Dispatch<SetStateAction<string>>;
  isOnBreak: boolean;
  setIsOnBreak: (value: boolean) => void;
  isHost: boolean;
  setIsCameraOff: (value: boolean) => void;
  roomId: string;
  hasJoinedCall: boolean;
  userDailyId: string;
  setPropagatedIntentions: Dispatch<SetStateAction<PublicIntentionEntry[]>>;
  propagatedIntentions: PublicIntentionEntry[];
  callFrame: DailyCall | null;
  localSessionId: string;
};

export type Tabs = "people" | "intentions" | "chat";

export const VideoCallContext = createContext({} as VideoCallContextData);

type VideoCallContextProviderProps = {
  children: ReactNode;
  isHost: boolean;
  roomId: string;
};

export const VideoCallContextProvider = ({
  children,
  isHost,
  roomId,
}: VideoCallContextProviderProps) => {
  const callFrame = useDaily();
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [cameraOffFor, setCameraOffFor] = useState<number>();
  const [cameraOffTimer, setCameraOffTimer] = useState<NodeJS.Timeout>();

  const [isOnBreak, setIsOnBreak] = useState(false);
  const [forcedToBreakRoomReason, setForcedToBreakRoomReason] = useState("");
  const [propagatedIntentions, setPropagatedIntentions] = useState<
    PublicIntentionEntry[]
  >([]);

  /**
   * We check joined_at here because localSessionId is defined as soon as the user loads the call
   * but joined_at only has a value when the user has joined. This is more precise as admins can
   * only make changes to calls when they have joined
   */
  const localSessionId = useLocalSessionId();
  const hasJoinedCall = !!useParticipantProperty(localSessionId, "joined_at");
  const participantTracks = useParticipantProperty(localSessionId, "tracks");

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!roomId.includes(DROP_IN_ID)) return;

    if (participantTracks?.video?.state === "off") {
      // If there is an inactive timer, we don't want to set a new one
      if (cameraOffTimer) return;

      timer = setTimeout(
        () => setIsCameraOff(true),
        hasJoinedCall ? CAMERA_OFF_TIME_LIMIT : CAMERA_OFF_TIME_LIMIT_JOIN
      );
      setCameraOffTimer(timer);
      analytics
        .track("Camera Event", {
          action: "Camera off",
        })
        .catch(catchAnalyticsError);
    } else {
      if (cameraOffTimer) {
        clearTimeout(cameraOffTimer);
        setCameraOffTimer(undefined);
      }
      setIsCameraOff(false);
      analytics
        .track("Camera Event", {
          action: "Camera on",
        })
        .catch(catchAnalyticsError);
    }
    return () => {
      clearTimeout(cameraOffTimer);
    };
  }, [hasJoinedCall, cameraOffTimer, participantTracks?.video?.state, roomId]);

  useEffect(() => {
    let cameraOffInterval: NodeJS.Timeout;
    if (isCameraOff) {
      const cameraOffSince = dayjs();
      cameraOffInterval = setInterval(
        () => setCameraOffFor(dayjs().diff(cameraOffSince, "seconds")),
        1000
      );
      analytics
        .track("Inactivity Modal Event", {
          action: "Showed",
        })
        .catch(catchAnalyticsError);
    } else {
      setCameraOffFor(undefined);
    }
    return () => {
      clearInterval(cameraOffInterval);
    };
  }, [isCameraOff]);

  const safeCallframe = useMemo(() => {
    if (!callFrame || callFrame.isDestroyed()) return null;
    return callFrame;
  }, [callFrame]);
  return (
    <VideoCallContext.Provider
      value={{
        isCameraOff,
        cameraOffFor,
        isOnBreak,
        setIsOnBreak,
        forcedToBreakRoomReason,
        setForcedToBreakRoomReason,
        isHost,
        setIsCameraOff,
        roomId,
        hasJoinedCall,
        userDailyId: localSessionId,
        setPropagatedIntentions,
        propagatedIntentions,
        callFrame: safeCallframe,
        localSessionId,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};

export const useVideoCall = () => {
  const providerValue = useContext(VideoCallContext);
  if (Object.keys(providerValue).length === 0) {
    logger.warn("useVideoCall must be used within a VideoCallContextProvider");
  }
  return providerValue;
};
