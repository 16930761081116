import styled from "@emotion/styled";
import { Box, Text } from "design-system/components";
import Image from "next/image";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { css } from "styled-system/css";
import { useNavigate } from "utils/component-utils";

type PressProps = {
  pressTitle?: string | null | undefined;
  pressLogos: {
    url: string;
    customData: {
      link: string;
    };
  }[];
  pressCtaLabel?: string | null | undefined;
  pressCtaLink?: string | null | undefined;
};

export const Press: FC<PressProps> = ({
  pressTitle,
  pressLogos,
  pressCtaLabel,
  pressCtaLink,
}) => {
  const navigate = useNavigate();

  const clickHandler = (url?: string) => {
    if (url) navigate(url);
  };

  return (
    <Box
      className={css({
        maxWidth: "maxWidth.content",
        margin: "0 auto",
        paddingX: "32",
      })}
    >
      <PressSection>
        {pressTitle && (
          <PressContent>
            <Text
              as="h2"
              fontFamily="centuryOldStyleStd"
              className={css({
                fontSize: "4xl",
                margin: "auto",
                "bp-tablet": {
                  fontSize: "7xl",
                },
              })}
            >
              <ReactMarkdown>{pressTitle}</ReactMarkdown>
            </Text>
            {pressCtaLabel && pressCtaLink && (
              <a className="decor-underline" href={pressCtaLink}>
                {pressCtaLabel}
              </a>
            )}
          </PressContent>
        )}
        <PressLogos>
          {pressLogos.map((logo) => (
            <Image
              key={logo.url}
              height={121}
              width={242}
              src={logo.url}
              onClick={() => clickHandler(logo.customData.link)}
              alt="Press Logo"
              className={css({
                height: "auto",
                cursor: logo.customData.link ? "pointer" : "default",
                "bp-mobile-only": {
                  maxWidth: "148px",
                },
              })}
            />
          ))}
        </PressLogos>
      </PressSection>
    </Box>
  );
};

const PressSection = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grey100,
  borderRadius: theme.radii[24],
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing[64],
  [theme.media["bp-tablet"]]: {
    flexDirection: "row",
  },
}));

const PressContent = styled.div`
  flex: 1;
  align-self: center;
`;

const PressLogos = styled.div(({ theme }) => ({
  flex: "1",
  marginTop: "0",
  textAlign: "center",
  justifyContent: "center",
  [theme.media["bp-tablet"]]: {
    textAlign: "center",
    margin: theme.spacing[64],
    fontSize: theme.fontSizes["7xl"],
  },
}));
