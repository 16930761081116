import { styled } from "styled-system/jsx";
import { Box as PandaBox } from "styled-system/jsx";

import { DSComponentPropsProvider } from "design-system/design-system.types";
import { ReactNode } from "react";

export const Box = PandaBox;
type BoxProps = React.ComponentProps<typeof Box>;

export const Row = ({
  className,
  ...props
}: Omit<BoxProps, "css"> &
  DSComponentPropsProvider & { children: ReactNode }) => (
  <Box
    display={"flex"}
    alignItems={"center"}
    gap={8}
    className={className ?? ""}
    {...props}
  />
);

/**
 * @component BoxFlex - A preset Box with display flex, align center and gap 8.
 */
export const BoxFlex = Row;

export const BoxCol = ({
  className,
  ...props
}: Omit<BoxProps, "css"> &
  DSComponentPropsProvider & { children: ReactNode }) => (
  <Box
    display={"flex"}
    flexDirection={"column"}
    gap="4"
    className={className || ""}
    {...props}
  />
);

export const Widget = styled(Box, {
  base: {
    boxShadow:
      "0px 1px 20px rgba(16, 24, 40, 0.06),\n    0px 1px 20px rgba(16, 24, 40, 0.1)",
    borderRadius: "{radii.12}",
  },
});
