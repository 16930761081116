import styled from "@emotion/styled";
import { DSComponentPropsProvider, ThemeColor } from "../design-system.types";
import * as Progress from "@radix-ui/react-progress";
import { THEME_COLORS } from "../emotion.theme";

type ProgressBarType = DSComponentPropsProvider & {
  currentProgress: number;
  totalProgress?: number;
  indicatorColor?: ThemeColor;
  wrapperBackgroundColor?: ThemeColor;
};

export const ProgressBar = ({
  currentProgress,
  totalProgress = 100,
  indicatorColor,
  wrapperBackgroundColor,
  className = "",
}: ProgressBarType) => {
  const translatePosition = (currentProgress * 100) / totalProgress;
  return (
    <ProgressBarWrapper
      className={className}
      value={currentProgress}
      max={totalProgress}
      style={{
        backgroundColor: THEME_COLORS[wrapperBackgroundColor || "blueGrey100"],
      }}
    >
      <ProgressMarker
        style={{
          transform: `translateX(-${100 - translatePosition}%)`,
          backgroundColor: THEME_COLORS[indicatorColor || "black"],
        }}
      />
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled(Progress.Root)`
  border-radius: ${({ theme }) => theme.radii[20]};
  position: relative;
  overflow: hidden;
  height: 4px;
  width: 100%;
  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
`;

const ProgressMarker = styled(Progress.Indicator)(({ theme }) => ({
  backgroundColor: theme.colors.black,
  borderRadius: theme.radii.round,
  width: "100%",
  height: "100%",
  transition: "transform 660ms cubic-bezier(0.65, 0, 0.35, 1)",
}));
