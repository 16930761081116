import { useUserContext } from "context/user-context";
import dayjs from "dayjs";
import { memo, useCallback, useMemo } from "react";
import { useNavigate } from "utils/component-utils";
import { FlownEvent } from "shared/event.types";
import {
  getEventHasEnded,
  getIsEventFullyBooked,
  getIsEventJoinable,
  getIsDropInEvent,
  isPopularWithNewbies,
  isPopularWithOrganisation,
  getHasEventJustStarted,
  getEventHasStarted,
} from "utils/event-utils";
import { FacilitatorSessionCard } from "design-system/molecules/cards";
import {
  getRegisterUrlWithEmbeddedRedirects,
  getURLWithReferralCode,
} from "utils/urls";
import {
  EmailInviteButton,
  JoinSessionButton,
  JoinSessionButtonDirect,
} from "./ctas";
import {
  Tooltip,
  Text,
  Box,
  Icon,
  GreatForNewbiesPill,
} from "design-system/components";
import { BringAFriendButton } from "./ctas/bring-a-friend-button";
import { CalendarView } from "../hooks/use-view-calendar";
import Image from "next/image";
import { useSidePanel } from "context/side-panel-context";
import { cn } from "design-system/styles/utility-classes";
import { getIsNewbie } from "services/roles/user-roles";
import { TimeDisplayMode } from "./sessions-wrapper";

type SessionWrapperType = {
  event: FlownEvent;
  isRegistered: boolean;
  isAttended?: boolean;
  isHighlighted?: boolean;
  onEventBooked?: (event: FlownEvent) => Promise<void>;
  onEventBookingCancelled?: (event: FlownEvent) => Promise<void>;
  onEventCancelled?: (canceledEvent: FlownEvent) => void;
  refreshDay: number;
  onEventClick?: () => void;
  view: CalendarView;
  previewInSidePanel?: boolean;
  timeDisplayMode?: TimeDisplayMode;
};

export const SessionWrapper = memo(
  ({
    event,
    isRegistered = false,
    isAttended = false,
    onEventClick,
    onEventBooked,
    onEventBookingCancelled,
    onEventCancelled,
    isHighlighted = false,
    view,
    previewInSidePanel = false,
    timeDisplayMode = "time+duration",
  }: SessionWrapperType) => {
    const {
      isAuthorizedRole,
      isFreeRole,
      referralCode,
      loggedIn,
      externalId,
      organisation,
      analyticsData,
    } = useUserContext();
    const navigate = useNavigate();
    const { showEventCardSidePanel } = useSidePanel();
    const isNewbie = getIsNewbie({ analyticsData });

    const isPopularWithOrg = isPopularWithOrganisation(event);

    // We only allow users to join a flock xxmins before the start of the event up until the
    // end of the event.
    const canJoinFlock = getIsEventJoinable(event);

    // TODO: rename this to right bottom corner content or smth better and make it independent of imageContent
    const partnershipContent = useMemo(() => {
      if (view !== "list" && view !== "agenda") return null;
      let displayName;
      let imageContent;

      if (event.partnership) {
        displayName = event.partnership.displayName;
        imageContent = event.partnership.typographyLogo?.url ? (
          <Box
            className="pos-relative"
            style={{
              minWidth: "80px",
              height: "20px",
            }}
          >
            <Image
              fill
              src={event.partnership.typographyLogo.url}
              alt={`${displayName} logo`}
            />
          </Box>
        ) : null;
      } else if (event.organisation) {
        displayName = event.organisation.name;
        imageContent = event.organisation.logoUrl ? (
          <Image
            className="b-radius-round mar-right-4"
            height={20}
            width={20}
            src={event.organisation.logoUrl}
            alt={`${displayName} logo`}
          />
        ) : null;
      } else if (isPopularWithOrg && organisation) {
        displayName = organisation.name;
        imageContent = organisation.logoUrl ? (
          <Image
            className="b-radius-round mar-right-4"
            height={20}
            width={20}
            src={organisation.logoUrl}
            alt={`${displayName} logo`}
          />
        ) : (
          <></>
        );
      }

      return (
        <Box className="flex">
          {imageContent || isPopularWithNewbies({ tags: event.tags }) ? (
            <Box className={cn(".flex", ".align-center")}>
              {event.partnership ? (
                <Text
                  className={`session-card__in-partnership ${cn(
                    ".hide-on-mobile"
                  )}`}
                  fontSize="xs"
                >
                  with
                </Text>
              ) : isPopularWithOrg ? (
                <Text
                  className="mar-right-8 session-card__only-for"
                  fontSize="xs"
                >
                  Popular with{" "}
                </Text>
              ) : isNewbie && isPopularWithNewbies({ tags: event.tags }) ? (
                <GreatForNewbiesPill />
              ) : null}
              {imageContent}
              {(event.organisation || isPopularWithOrg) && !event.partnership && (
                <Text fontSize="xs" fontFamily="facundo" fontWeight={600}>
                  {displayName}
                </Text>
              )}
            </Box>
          ) : (
            displayName
          )}
        </Box>
      );
    }, [
      event.organisation,
      event.partnership,
      organisation,
      isPopularWithOrg,
      event.tags,
      view,
      isNewbie,
    ]);

    const linkContentRegister = useMemo(
      () => (
        <FacilitatorSessionCard
          grayOutPastEvents
          onClick={() => {
            if (view === "agenda") {
              onEventClick?.();
              if (previewInSidePanel) {
                showEventCardSidePanel(event, onEventCancelled);
              }
            } else {
              navigate(
                getRegisterUrlWithEmbeddedRedirects(event.springBoardUrl)
              );
            }
          }}
          isCancelled={event.deleted || event.isCancelled}
          timeDisplayMode={timeDisplayMode}
          showFullEventTitle={view === "agenda"}
          event={event}
          isHighlighted={isHighlighted}
          partnershipContent={partnershipContent}
        />
      ),
      [
        event,
        navigate,
        timeDisplayMode,
        isHighlighted,
        view,
        partnershipContent,
        onEventClick,
        previewInSidePanel,
        showEventCardSidePanel,
        onEventCancelled,
      ]
    );

    /**
     * We only want to show the event capacity for running events if it is 5 mins into the
     * event. After that we should not show it anymore
     */
    const showEventCapacityForRunningEvent =
      !getEventHasStarted(event.startTime) || getHasEventJustStarted(event);

    const linkContentRunningEvent = useMemo(
      () => (
        <FacilitatorSessionCard
          className="list-card"
          grayOutPastEvents={!isAttended}
          isHighlighted={isHighlighted}
          isAttended={isAttended}
          event={event}
          timeDisplayMode={timeDisplayMode}
          isCancelled={event.deleted || event.isCancelled}
          showEventCapacity={showEventCapacityForRunningEvent}
          ctas={
            loggedIn ? (
              <>
                {view === "list" ? (
                  <JoinSessionButtonDirect event={event} />
                ) : (
                  <JoinSessionButton />
                )}
              </>
            ) : null
          }
          showInProgress
          showFullEventTitle={view === "agenda"}
          onClick={() => {
            onEventClick?.();
            if (previewInSidePanel) {
              showEventCardSidePanel(event, onEventCancelled);
            }
          }}
          id={`${view}-session-${event.id}`}
          data-cy={`${view}-session-${event.detailedName}`}
          partnershipContent={partnershipContent}
        />
      ),
      [
        view,
        isAttended,
        isHighlighted,
        event,
        timeDisplayMode,
        loggedIn,
        partnershipContent,
        onEventClick,
        previewInSidePanel,
        showEventCardSidePanel,
        onEventCancelled,
        showEventCapacityForRunningEvent,
      ]
    );
    const linkContentLocked = useMemo(() => {
      const eventClick = () => {
        if (previewInSidePanel) {
          showEventCardSidePanel(event, onEventCancelled);
        } else {
          onEventClick?.();
        }
      };

      return (
        <FacilitatorSessionCard
          timeDisplayMode={timeDisplayMode}
          grayOutPastEvents
          onClick={eventClick}
          showFullEventTitle={view === "agenda"}
          partnershipContent={partnershipContent}
          ctas={<Icon icon="padlock" size={14} color={"black"} />}
          event={event}
          isHighlighted={isHighlighted}
        />
      );
    }, [
      event,
      isHighlighted,
      view,
      partnershipContent,
      showEventCardSidePanel,
      previewInSidePanel,
      timeDisplayMode,
      onEventClick,
      onEventCancelled,
    ]);

    const futureBookableEventContent = useMemo(() => {
      let eventClick;

      if (onEventClick || previewInSidePanel) {
        eventClick = () => {
          onEventClick?.();
          if (previewInSidePanel) {
            showEventCardSidePanel(event, onEventCancelled);
          }
        };
      }

      return (
        <FacilitatorSessionCard
          timeDisplayMode={timeDisplayMode}
          grayOutPastEvents={!isAttended}
          isHighlighted={isHighlighted}
          isAttended={isAttended}
          onClick={eventClick}
          showFullEventTitle={view === "agenda"}
          partnershipContent={partnershipContent}
          isCancelled={event.deleted || event.isCancelled}
          ctas={
            loggedIn ? (
              <>
                {isFreeRole && !event.isFreeSession ? (
                  <Tooltip
                    triggerElement={
                      <span className="w-full">
                        <Icon icon="padlock" size={14} color={"black"} />
                      </span>
                    }
                  >
                    Subscribe to FLOWN for access;
                  </Tooltip>
                ) : (
                  <>
                    {isRegistered && getIsDropInEvent(event)
                      ? null
                      : !getIsEventFullyBooked(event) &&
                        event.host !== externalId && (
                          <EmailInviteButton
                            spinnerColor={"black"}
                            onInviteSent={async () =>
                              await onEventBooked?.(event)
                            }
                            onInviteCancelled={async () =>
                              await onEventBookingCancelled?.(event)
                            }
                            inviteSent={isRegistered}
                          />
                        )}
                    {isRegistered && !previewInSidePanel && !event.isP2P && (
                      <BringAFriendButton
                        title={`Join me on a virtual co-working session with FLOWN | ${
                          event.displayName
                        } - ${dayjs(event.startTime).format(
                          "dddd D MMM YYYY"
                        )}`}
                        shareUrl={getURLWithReferralCode(
                          event.springBoardFullUrl,
                          referralCode
                        )}
                      />
                    )}
                  </>
                )}
              </>
            ) : null
          }
          id={`${view}-session-${event.id}`}
          data-cy={`${view}-session-${event.detailedName}`}
          event={event}
        />
      );
    }, [
      event,
      isRegistered,
      loggedIn,
      onEventClick,
      onEventBooked,
      onEventBookingCancelled,
      referralCode,
      isHighlighted,
      view,
      partnershipContent,
      showEventCardSidePanel,
      previewInSidePanel,
      timeDisplayMode,
      isAttended,
      isFreeRole,
      externalId,
      onEventCancelled,
    ]);

    const getContent = useCallback(() => {
      if (isFreeRole && !event.isFreeSession) {
        return {
          dayContent: linkContentLocked,
          popUpContent:
            !getEventHasEnded(event.endTime) && "Upgrade for unlimited access",
        };
      }

      if (isAuthorizedRole) {
        if (canJoinFlock) {
          return {
            dayContent: linkContentRunningEvent,
          };
        } else {
          return {
            dayContent: futureBookableEventContent,
            popUpContent: "",
          };
        }
      }

      return {
        dayContent: linkContentRegister,
        popUpContent: "Register or login to access",
      };
    }, [
      canJoinFlock,
      event.isFreeSession,
      futureBookableEventContent,
      isAuthorizedRole,
      isFreeRole,
      linkContentRegister,
      linkContentRunningEvent,
      linkContentLocked,
      event.endTime,
    ]);

    const { dayContent, popUpContent } = getContent();

    return popUpContent ? (
      <Tooltip triggerElement={<span className="w-full">{dayContent}</span>}>
        <Text fontWeight={700}>{popUpContent}</Text>
      </Tooltip>
    ) : (
      dayContent
    );
  }
);
