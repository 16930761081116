import styled from "@emotion/styled";
import { ContentContainer } from "legacy-design-system";
import { MediaViewer } from "design-system/molecules/media-viewer";
import { HomePageProductBlockFragment } from "generated/graphql-typed";
import Link from "next/link";
import { HasChildren } from "utils/component-utils";
import { ModularContent } from "./modular-content";
import { renderer } from "./renderer";

export const HomePageProductGroupWrapper = (
  props: HasChildren & { className: string }
) => (
  <HomePageProductGroup className={props.className}>
    {props.children}
  </HomePageProductGroup>
);

const HomePageProductGroup = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
  margin: 0 auto;
`;

const HomePageProductView = (
  props: HomePageProductBlockFragment & { className: string }
) => {
  if (!props.productType) return null;

  const content = (
    <>
      <TitleContainer className={props.productType}>
        <TitleSpark
          src={
            props.productType === "work"
              ? "/assets/shared/work-spark.svg"
              : "/assets/shared/rest-spark.svg"
          }
        />
        {props.title}
      </TitleContainer>
      <ModularContent
        data={props.content}
        paragraphSize={props.paragraphSize}
        wrapper={StructuredTextContainer}
      />

      {props.image?.url && (
        <HomepageProductLinkMediaViewer
          url={props.image.url}
          alt={props.image.alt || "Product link"}
          showVideoControls={props.showVideoControls}
        />
      )}
    </>
  );

  return props.link ? (
    <Link href={props.link} passHref>
      <HomePageProductContainer className={props.className}>
        {content}
      </HomePageProductContainer>
    </Link>
  ) : (
    <HomePageProductContainer className={props.className}>
      {content}
    </HomePageProductContainer>
  );
};

export const HomePageProductRenderer = renderer({
  typeName: "HomePageProductRecord",
  view: HomePageProductView,
  groupComponent: HomePageProductGroupWrapper,
});

const HomePageProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;
  max-width: 542px;
  max-height: 616px;
  border: 1px solid #e5e1e6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 38px 49px 49px 46px;
  text-align: center;
  box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.05);
  :hover {
    background-color: #f4f4f4;
    border-color: #ccc;
  }
`;

const StructuredTextContainer = styled.div`
  max-width: 383px;
  margin: 0 auto 32px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  &.work {
    color: #f09373;
  }
  &.rest {
    color: #6cb9b7;
  }
`;
const TitleSpark = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const HomepageProductLinkMediaViewer = styled(MediaViewer)`
  width: 100%;
  margin-top: auto;
  max-width: 447px;
  max-height: 262px;
  object-fit: cover;
  height: auto;
`;
