import { createGetEndpoint } from "utils/create-get-endpoint";
import { createPostEndpoint } from "utils/create-post-endpoint";
import {
  FetchMilestoneBadgesByUserIdRequest,
  FetchUserMilestoneBadgesResponse,
  FetchUserStreakBadgesResponse,
  FetchStreakBadgesByUserIdRequest,
  RecordBadgeCompletionRequest,
  RecordBadgeCompletionResponse,
} from "../shared/badge-types";

export const postToComputeBadges =
  createGetEndpoint<FetchUserMilestoneBadgesResponse>(
    "/api/badges/milestone-badges"
  );

export const postToComputeMilestoneBadgesByUserId = createPostEndpoint<
  FetchMilestoneBadgesByUserIdRequest,
  FetchUserMilestoneBadgesResponse
>("/api/badges/user");

export const postToComputeStreakBadges =
  createGetEndpoint<FetchUserStreakBadgesResponse>("/api/badges/streak-badges");

export const postToComputeStreakBadgesByUserId = createPostEndpoint<
  FetchStreakBadgesByUserIdRequest,
  FetchUserStreakBadgesResponse
>("/api/badges/user-streak-badges");

export const postToRecordBadgeCompletion = createPostEndpoint<
  RecordBadgeCompletionRequest,
  RecordBadgeCompletionResponse
>(`/api/badges/record-badge-completion`);
