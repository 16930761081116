import { clientLoggerForFile } from "./logger.client";

const logger = clientLoggerForFile(__filename);

export const getLocalStorageWithExpiry = (key: string) => {
  try {
    const storedItem = localStorage.getItem(key);
    if (!storedItem) {
      return null;
    }
    const data = JSON.parse(storedItem);
    const hasExpired = Date.now() > data.expiry;
    if (hasExpired) {
      localStorage.removeItem(key);
      return null;
    }
    return data.value;
  } catch (error) {
    logger.error(
      `Failed to get item with key: "${key}" from localStorage`,
      error
    );
    setLocalStorageWithExpiry(key, null);
    return null;
  }
};
const expiryTime = 600000; // 10minutes

export const setLocalStorageWithExpiry = (
  key: string,
  value: any,
  ttl: number = expiryTime
) => {
  const item = {
    value,
    expiry: Date.now() + ttl,
  };
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    logger.error(
      `Failed to write item ${JSON.stringify(item)} to localstorage`
    );
  }
};

export const LS_PREF_VOLUME = "pref-volume";
