import styled from "@emotion/styled";
import {
  CalendarWrapper,
  useGetPopulatedSessionsAttended,
} from "features/calendar/client";
import { Text, Button, Icon } from "design-system/components";
import { SkeletonLoadindingActivitySessionCardList } from "design-system/molecules/cards/session-cards/activity-session-card";
import { TabSwitcher } from "design-system/molecules/tab-switcher";
import Link from "next/link";
import React, { useMemo } from "react";
import { getEventHasStarted } from "utils/event-utils";
import { CALENDAR_FEATURED_URL } from "utils/urls";
import { useGetPopulatedSessionsRegistered } from "../../../calendar/client/hooks/use-get-populated-sessions-registered";

type SessionTabsProps = {
  userExternalId?: string;
};

export const SessionTabs: React.FC<SessionTabsProps> = ({ userExternalId }) => {
  const populatedSessionsRegisteredState =
    useGetPopulatedSessionsRegistered(userExternalId);
  const populatedSessionsAttendedState =
    useGetPopulatedSessionsAttended(userExternalId);

  const SessionCardsWithSeparators = useMemo(
    () => () => {
      return (
        <CalendarWrapper
          id="session-tabs-booked"
          events={populatedSessionsRegisteredState.sessionsRegistered.filter(
            (event) => !getEventHasStarted(event.startTime)
          )}
          calendarSliceArgs={{}}
          forceView="list"
          analyticsContext="booked-sessions-tabs"
          previewInSidePanel={true}
          showEventFilters={false}
        />
      );
    },
    [populatedSessionsRegisteredState.sessionsRegistered]
  );

  const SESSION_TABS = useMemo(
    () => [
      {
        label: "Booked",
        value: "my-day-sessions",
        content: (
          <TabWrapper className="flex-col gap-16">
            {populatedSessionsRegisteredState.status === "loading" ? (
              <SkeletonLoadindingActivitySessionCardList
                listTitle={"Loading booked sessions..."}
              />
            ) : populatedSessionsRegisteredState.sessionsRegistered.length >
              0 ? (
              <SessionCardsWithSeparators />
            ) : (
              <div className="flex-col gap-16">
                {userExternalId ? (
                  <Text>No sessions registered.</Text>
                ) : (
                  <>
                    <Text>You have not registered for any sessions.</Text>
                    <Link href={CALENDAR_FEATURED_URL}>
                      <Button variant="secondary" size="small">
                        Go to calendar
                        <Icon icon="arrow-right" size={14} />
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </TabWrapper>
        ),
      },
      {
        label: `Past sessions`,
        value: "past-sessions",
        content: (
          <TabWrapper className="flex-col gap-16">
            {populatedSessionsAttendedState.status === "loading" ? (
              <SkeletonLoadindingActivitySessionCardList
                listTitle={"Loading past sessions..."}
              />
            ) : populatedSessionsAttendedState.sessionsAttended.length > 0 ? (
              <CalendarWrapper
                id="past-sessions-tab-page"
                events={populatedSessionsAttendedState.sessionsAttended}
                attendedEventsIds={populatedSessionsAttendedState.sessionsAttended.map(
                  (event) => event.id
                )}
                calendarSliceArgs={{}}
                forceView="list"
                showEventFilters={false}
                sortOrderForList="desc"
                analyticsContext="my-day"
                previewInSidePanel={true}
              />
            ) : (
              <div className="flex-col gap-16">
                {userExternalId ? (
                  <Text>No recently attended sessions.</Text>
                ) : (
                  <>
                    <Text>You have not attended any sessions recently.</Text>
                    <Link href={CALENDAR_FEATURED_URL}>
                      <Button variant="secondary" size="small">
                        Go to calendar
                        <Icon icon="arrow-right" size={14} />
                      </Button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </TabWrapper>
        ),
      },
    ],
    [
      populatedSessionsAttendedState.sessionsAttended,
      populatedSessionsAttendedState.status,
      populatedSessionsRegisteredState.sessionsRegistered.length,
      populatedSessionsRegisteredState.status,
      SessionCardsWithSeparators,
      userExternalId,
    ]
  );
  return <TabSwitcher tabs={SESSION_TABS} tabListLabel="Profile sessions" />;
};

const TabWrapper = styled.div`
  ${({ theme }) => theme.media["bp-tablet"]} {
    overflow-y: auto;
  }
`;
