import ReactMarkdown from "react-markdown";
import {
  Box,
  BoxFlex,
  Button,
  CommunityHostPill,
  FlownPill,
  GreatForNewbiesPill,
  Pill,
  PillFromCategory,
  Row,
  Tooltip,
} from "design-system/components";
import { DSComponentPropsProvider } from "../design-system.types";
import { Text } from "design-system/components/text";
import { NoSSR, useUserTimezone } from "utils/component-utils";
import { getFormattedDayTime } from "utils/date";
import { Icon } from "design-system/components";
import styled from "@emotion/styled";
import Image from "next/image";
import { FlownEvent, PopulatedHost } from "shared/event.types";
import { FACILITATORS_URL } from "utils/urls";
import { UserProfileFromActivity } from "shared/user.types";
import { SessionAttendance, postToClaimHost } from "features/sessions/client";
import { ProfileTag } from "shared/user.types";
import { CollapseToggle } from "design-system/components";
import { SessionRecord } from "shared/session.types";
import { HostContent } from "./host-content";
import {
  getIsEventJoinable,
  getIsCommunityEvent,
  getIsDropInEvent,
  getEventCapacityStatus,
  getSessionDisplayName,
  isPopularWithNewbies,
  getSessionHost,
  checkIsHostless,
  getEventHasStarted,
  getHasEventJustStarted,
} from "utils/event-utils";
import { TagsList } from "./tags-list";
import {
  WrappedFlownogram as Flownogram,
  AgendaDetail,
} from "features/sessions/client";
import { ModularContent } from "features/modular-content";
import { cn } from "design-system/styles/utility-classes";
import Link from "next/link";
import { useSidePanel } from "context/side-panel-context";
import { useUserContext } from "context/user-context";
import { pluralise } from "utils/string-utils";
import {
  getIsFreeOrFreeCreditsRole,
  getIsInternalHost,
  getIsNewbie,
} from "services/roles/user-roles";
import { getUserDisplayName } from "features/profile/shared";
import { P2PEvent } from "shared/rooms.types";
import { useEffect, useState } from "react";

export type EventSummaryFlownEvent = Pick<
  FlownEvent,
  | "id"
  | "startTime"
  | "endTime"
  | "isP2P"
  | "facilitator"
  | "populatedHost"
  | "populatedClaimedHost"
  | "slug"
  | "deleted"
  | "isCancelled"
  | "category"
  | "tags"
  | "displayName"
  | "shortDescription"
  | "longDescription"
  | "richDescription"
  | "partnership"
  | "privacy"
  | "config"
  | "agenda"
  | "eventProfileTags"
  | "details"
  | "registeredUsersCount"
  | "organisation"
  | "host"
  | "claimedHost"
> &
  Pick<P2PEvent, "hostMessage" | "emoji">;

type EventSummaryProps = DSComponentPropsProvider & {
  event: EventSummaryFlownEvent;
  showCategoryPillAndIcon?: boolean;
  usersRegistered?: UserProfileFromActivity[];
  usersInSession?: UserProfileFromActivity[];
  connections?: UserProfileFromActivity[];
  commonTags?: ProfileTag[];
  openHowItWorks?: boolean;
  sessionsAttended?: SessionRecord[];
  showHowItWorks?: boolean;
  showStartTime?: boolean;
  // This is true if we are previewing an event summary which can occur in creation / editing
  isPreview?: boolean;
};

export function EventSummary({
  event,
  showCategoryPillAndIcon = true,
  usersRegistered = [],
  usersInSession = [],
  connections = [],
  commonTags = [],
  openHowItWorks = true,
  showHowItWorks = true,
  showStartTime = false,
  isPreview = false,
}: EventSummaryProps) {
  const { formattedUserTimezone } = useUserTimezone();
  const [populatedClaimedHost, setPopulatedClaimedHost] =
    useState<PopulatedHost>();
  const [host, setHost] = useState<PopulatedHost>();

  const [isClaimingHost, setIsClaimingHost] = useState(false);

  useEffect(() => {
    setPopulatedClaimedHost(event.populatedClaimedHost);
  }, [event.populatedClaimedHost]);

  useEffect(() => {
    setHost(
      getSessionHost({ ...event, populatedClaimedHost: populatedClaimedHost })
    );
  }, [event, populatedClaimedHost]);

  const { showProfileForUserId } = useSidePanel();
  const { organisation, loggedIn, analyticsData, canHostSessions, userRole } =
    useUserContext();

  const isDropIn = getIsDropInEvent(event);

  const hasFacilitatorOrUsers =
    !!event.facilitator || !!event.populatedHost || usersRegistered.length > 0;

  const showFacilitatorAndUsersSection = showHowItWorks
    ? hasFacilitatorOrUsers
    : true;

  const showSecondSeparator = showHowItWorks ? hasFacilitatorOrUsers : false;

  const isCommunityEvent = getIsCommunityEvent(event);

  const isFlownHosted =
    !!event.facilitator || getIsInternalHost(event.populatedHost?.userRole);

  const isEventJoinable = getIsEventJoinable(event);

  const hasEventStarted = getEventHasStarted(event?.startTime);

  const usersToShow = hasEventStarted
    ? usersInSession
    : usersRegistered?.length
    ? usersRegistered
    : [];

  const orgUsers = organisation
    ? usersToShow.filter((user) => user.organisation?.id === organisation?.id)
    : [];

  const eventDisplayName = getSessionDisplayName(event);

  const newbiesRegistered = usersRegistered.filter((user) => getIsNewbie(user));
  const canClaimHostlessSession =
    event.isP2P &&
    checkIsHostless({
      host: event.host || "",
      claimedHost: populatedClaimedHost?.externalId,
    }) &&
    !getIsFreeOrFreeCreditsRole(userRole) &&
    canHostSessions;

  return (
    <Box className="flex-col gap-16">
      {showCategoryPillAndIcon && (
        <Box
          className="flex gap-8 align-center"
          bp-desktop-xs={{ display: "none" }}
        >
          <PillFromCategory category={event.category.slug} />
          {isCommunityEvent &&
          !getIsInternalHost(event.populatedHost?.userRole) ? (
            <>
              <Pill className="gap-4" backgroundColor="grey20">
                <Icon icon="friends" /> Community
              </Pill>
            </>
          ) : (
            <Pill backgroundColor="grey600" fontColor="white" className="gap-4">
              <Icon icon="home" color="white" size={12} /> FLOWN
            </Pill>
          )}
        </Box>
      )}
      <EventNameBox>
        <EventSummaryTitle
          em={true}
          fontSize="5xl"
          fontWeight={700}
          dataCy="event-summary-display-name"
        >
          {event.isP2P && event.emoji
            ? `${event.emoji} ${eventDisplayName}`
            : eventDisplayName}
        </EventSummaryTitle>

        <BoxFlex>
          {getIsNewbie({ analyticsData }) && isPopularWithNewbies(event) && (
            <BoxFlex>
              {newbiesRegistered.length > 0 ? (
                <Tooltip
                  side="right"
                  triggerElement={
                    <>
                      <GreatForNewbiesPill />
                    </>
                  }
                >
                  <Text fontSize="xs">
                    {newbiesRegistered.length} new{" "}
                    {pluralise(newbiesRegistered.length, "member")} attending
                  </Text>
                </Tooltip>
              ) : (
                <GreatForNewbiesPill />
              )}
            </BoxFlex>
          )}
          {event.organisation ? (
            <Text
              className="flex align-center gap-4"
              as="span"
              fontSize="sm"
              fontWeight={500}
            >
              Exclusively for{" "}
              {event.organisation.logoUrl && (
                <Image
                  className="b-radius-round mar-left-4"
                  height={20}
                  width={20}
                  src={event.organisation.logoUrl}
                  alt={`${event.organisation.name} logo`}
                />
              )}
              <span className="ff-facundo fw-600">
                {event.organisation.name}
              </span>
            </Text>
          ) : (
            organisation &&
            orgUsers.length >= 2 && (
              <Text
                className="flex align-center gap-4 mar-top-8"
                as="span"
                fontSize="sm"
                fontWeight={500}
              >
                Popular with{" "}
                {organisation.logoUrl && (
                  <Image
                    className="b-radius-round mar-left-4"
                    height={20}
                    width={20}
                    src={organisation.logoUrl}
                    alt={`${organisation.name} logo`}
                  />
                )}
                <span className="ff-facundo fw-600">{organisation?.name}</span>
              </Text>
            )
          )}
        </BoxFlex>
      </EventNameBox>
      {(showStartTime || event.deleted || isCommunityEvent) && (
        <Row>
          {(showStartTime || isCommunityEvent) && (
            <Text
              as="time"
              fontSize={isCommunityEvent ? "md" : "lg"}
              fontWeight={600}
              className={`flex gap-8 ${event.deleted ? "decor-line" : ""}`}
              color="grey400"
            >
              {isDropIn ? (
                "All day"
              ) : !loggedIn ? (
                `${getFormattedDayTime(
                  event.startTime
                )} - ${formattedUserTimezone}`
              ) : (
                <>
                  <NoSSR>{getFormattedDayTime(event.startTime)}</NoSSR>
                  <Tooltip
                    side="right"
                    triggerElement={
                      <>
                        <Icon icon="info" className={cn(".mar-left-4")} />
                      </>
                    }
                  >
                    <Text>Timezone: {formattedUserTimezone}</Text>
                  </Tooltip>
                </>
              )}
            </Text>
          )}
          {(event.deleted || event.isCancelled) && (
            <Pill backgroundColor="errorLight" fontColor="error" fontSize="md">
              Cancelled
            </Pill>
          )}
        </Row>
      )}
      <Text em={true} color="grey400">
        {event.shortDescription}
      </Text>
      {event.partnership && (
        <>
          <Box className="flex">
            {event.partnership.typographyLogo?.url ? (
              <Box className={cn(".flex", ".align-center")}>
                <Text className={cn(".hide-on-mobile")} fontSize="xs">
                  In partnership with:
                </Text>
                <Box
                  className="pos-relative"
                  style={{
                    minWidth: "80px",
                    height: "100%",
                  }}
                >
                  <Image
                    fill
                    src={event.partnership.typographyLogo.url}
                    alt={
                      event.partnership.typographyLogo.alt ||
                      "Partnership typography logo"
                    }
                  />
                </Box>
              </Box>
            ) : (
              event.partnership.displayName
            )}
          </Box>
          {event.partnership.richDescription?.value && (
            <Box>
              <ModularContent
                paragraphSize={16}
                data={event.partnership.richDescription}
              />
            </Box>
          )}
        </>
      )}
      {canClaimHostlessSession && (
        <ClaimHostButton>
          <Box className="flex items-center gap-8">
            <Box className="hide-on-mobile mar">🙋‍♀️ </Box>
            <Box>
              This session doesn't have a host! Fancy stepping in to help
              everyone stay focused?
            </Box>
          </Box>
          <Button
            size="small"
            isLoading={isClaimingHost}
            onClick={async () => {
              setIsClaimingHost(true);
              const response = await postToClaimHost({
                roomId: event.id,
              });

              if (response.worked) {
                setPopulatedClaimedHost(response.room.populatedClaimedHost);
              }
              setIsClaimingHost(false);
            }}
            style={{ minWidth: "150px", alignSelf: "end" }}
          >
            Claim Host
          </Button>
        </ClaimHostButton>
      )}
      {event.isP2P && event.hostMessage ? (
        <HostMessageCallout>
          <Text>
            {event.emoji || "🪧"} {event.hostMessage}
          </Text>
        </HostMessageCallout>
      ) : null}
      {/* Invisible button to grab focus when summary is opened on a modal (like in the sidepanel) */}
      <button type="button"></button>
      {event.agenda && (
        <Flownogram agenda={event.agenda} startTime={event.startTime} />
      )}
      {showHowItWorks && (
        <CollapseToggle
          title={
            <Text className="decor-underline" fontWeight={700}>
              How it works
            </Text>
          }
          isOpen={openHowItWorks}
          className="border-bot-grey100 pad-bot-8"
        >
          <Box
            className="flex-wrap overflow-auto"
            style={{
              maxWidth: "75ch",
              maxHeight: "35vh",
            }}
          >
            {event.agenda?.length ? (
              <AgendaContainer className="grid-1-1 gap-16">
                <AgendaDetail agenda={event.agenda} />
                {event.privacy && event.config ? (
                  <Box className="mar-top-4">
                    <Text style={{ lineHeight: "1.75rem" }}>
                      {event.privacy === "private" && (
                        <>
                          🔒 Invite only session <br />
                        </>
                      )}
                      🗣{" "}
                      {event.config.max_participants === 2
                        ? "1-1 Session"
                        : "Group session"}{" "}
                      <br />
                      🎤 Microphones{" "}
                      {event.config.allowMic ? "enabled" : "disabled"} <br />
                      📷 Camera recommended <br />
                      💻 Runs in your browser
                    </Text>
                  </Box>
                ) : (
                  event.details && (
                    <Box
                      style={{ lineHeight: "1.75rem", whiteSpace: "normal" }}
                    >
                      <ReactMarkdown>{event.details}</ReactMarkdown>
                    </Box>
                  )
                )}
              </AgendaContainer>
            ) : event.richDescription ? (
              <ModularContent paragraphSize={16} data={event.richDescription} />
            ) : (
              event.longDescription && (
                <BoxFlex>
                  <ul className="mar-left-16" style={{ listStyle: "disc" }}>
                    {event.longDescription.split("\n").map((line, index) => (
                      <li key={index}>{line}</li>
                    ))}
                  </ul>
                </BoxFlex>
              )
            )}
          </Box>
        </CollapseToggle>
      )}
      {isDropIn && usersInSession.length > 0 && (
        <Box className="flex-col gap-8">
          <Text className="mar-x-8" fontSize="sm" fontWeight={700}>
            {"Currently in this session"}
          </Text>
          <SessionAttendance
            commonTags={[]}
            connections={[]}
            users={usersInSession}
            isInProgress={true} // Drop-in is always happening
          />
        </Box>
      )}
      {event.eventProfileTags && event.eventProfileTags.length > 0 && (
        <Box className="flex-col gap-8">
          <Text color="grey400">This session is specially for:</Text>
          <TagsList tags={event.eventProfileTags} />
        </Box>
      )}
      {showFacilitatorAndUsersSection && (
        <Box className="flex-col justify-center gap-8">
          <Box
            className={`align-center grid gap-8 pad-bot-24 ${
              showSecondSeparator ? "border-bot-grey100 pad-bot-8" : ""
            }`}
            style={{ gridTemplateColumns: "1fr 1fr" }}
          >
            {event.facilitator ? (
              <Link
                target="_blank"
                href={`${FACILITATORS_URL}/${event.facilitator.slug}`}
              >
                <HostContent
                  key={event.slug}
                  name={event.facilitator.name}
                  photoUrl={
                    event.facilitator.photoUrl ||
                    "/assets/fallbacks/facilitator-team.png"
                  }
                  oneLinerDescription={event.facilitator.oneLinerDescription}
                  pill={isFlownHosted ? <FlownPill /> : <CommunityHostPill />}
                />
              </Link>
            ) : host?.externalId ? (
              <Box className="flex-col gap-16 justify-center">
                <HostContent
                  onClick={() => {
                    if (host.externalId) {
                      showProfileForUserId(host.externalId, "large");
                    }
                  }}
                  name={getUserDisplayName(host)}
                  photoUrl={host.avatarUrl}
                  oneLinerDescription={host.oneLiner}
                />
              </Box>
            ) : null}
            {isPreview && event.config ? (
              <Text
                as="div"
                className="flex align-center justify-center mar-y-4"
                fontSize="sm"
                id="preview-max-participants"
              >
                <Pill className="gap-4" backgroundColor="grey20">
                  <Icon icon="friends" />
                  Max. participants:{" "}
                  <span className="fw-600">
                    {event.config.max_participants}
                  </span>{" "}
                </Pill>
              </Text>
            ) : null}
            {!(hasEventStarted || getHasEventJustStarted(event)) &&
              usersRegistered.filter(
                (user) => user.externalId !== event.populatedHost?.externalId
              ).length >= 1 &&
              !isDropIn && (
                <Box className="flex-col gap-8">
                  <Text
                    className="mar-x-8 flex justify-between"
                    fontSize="sm"
                    fontWeight={700}
                  >
                    <span>{"Who's going? "}</span>
                    <span className={cn(".fs-xs")} style={{ fontWeight: 300 }}>
                      {" "}
                      {getEventCapacityStatus(event)}
                    </span>
                  </Text>
                  <SessionAttendance
                    users={usersRegistered}
                    connections={connections}
                    commonTags={commonTags}
                    hasEventStarted={hasEventStarted}
                    host={host}
                  />
                  <Box style={{ textAlign: "center", width: "50%" }}></Box>
                </Box>
              )}
            {/* Do not show if only the host is in the session and do not show for 
            drop-in as we have a block for this above */}
            {hasEventStarted &&
            usersInSession.filter(
              (user) => user.externalId !== host?.externalId
            ).length > 0 &&
            !isDropIn ? (
              <Box className="flex-col gap-8">
                <Text className="mar-x-8" fontSize="sm" fontWeight={700}>
                  {"Who's in the session?"}
                </Text>
                <SessionAttendance
                  users={usersInSession}
                  connections={connections}
                  commonTags={commonTags}
                  hasEventStarted={true}
                  isInProgress={isEventJoinable}
                  host={host}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export const EventNameBox = styled.div({
  display: "flex",
  flexDirection: "column",
});

const AgendaContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.media["bp-mobile-md"]]: {
    display: "grid",
  },
}));

const EventSummaryTitle = styled(Text)(({ theme }) => ({
  hyphens: "auto",
  wordBreak: "break-word",
  overflowWrap: "anywhere",
  fontSize: theme.fontSizes["3xl"],
  [theme.media["bp-desktop-xs"]]: {
    fontSize: theme.fontSizes["5xl"],
  },
}));

const ClaimHostButton = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.grey100,
  borderRadius: theme.radii[12],
  padding: theme.spacing[12],
  wordBreak: "break-word",
  display: "flex",
  justifyContent: "end",
  flexDirection: "column",
  gap: theme.spacing[16],
}));

const HostMessageCallout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.grey100,
  borderRadius: theme.radii[12],
  padding: theme.spacing[12],
  wordBreak: "break-word",
}));
